<template>
  <div class="quotationContainer">
    <div class="machineTypeBox"><span class="moneyIcon"></span><span class="moneyWords">报价查询</span><span>{{}}</span>
    </div>
    <div class="chooseBox">
      <div class="machineType">
        <div class="machineTypeIcon" @click="getBrandList(10)" :class="{ 'activeTitle': ChooseObj.machineClass == 10 }">
          
        </div>
        <div class="machineTypeWord">手机</div>
      </div>
      <div class="machineType">
        <div class="machineTypeIcon" @click="getBrandList(11)" :class="{ 'activeTitle': ChooseObj.machineClass == 11 }">
          
        </div>
        <div class="machineTypeWord">平板电脑</div>
      </div>
      <div class="machineType">
        <div class="machineTypeIcon" @click="getBrandList(12)" :class="{ 'activeTitle': ChooseObj.machineClass == 12 }">
          
        </div>
        <div class="machineTypeWord">笔记本</div>
      </div>
      <div class="machineType">
        <div class="machineTypeIcon" @click="getBrandList(14)" :class="{ 'activeTitle': ChooseObj.machineClass == 14 }">
          
        </div>
        <div class="machineTypeWord">台式电脑</div>
      </div>
      <div class="machineType">
        <div class="machineTypeIcon" @click="getBrandList(13)" :class="{ 'activeTitle': ChooseObj.machineClass == 13 }">
          
        </div>
        <div class="machineTypeWord">电子手表</div>
      </div>
      <div class="machineType">
        <div class="machineTypeIcon" @click="getBrandList(22)" :class="{ 'activeTitle': ChooseObj.machineClass == 22 }">
          
        </div>
        <div class="machineTypeWord">配件</div>
      </div>
      <div class="machineType">
        <div class="machineTypeIcon" @click="getBrandList(21)" :class="{ 'activeTitle': ChooseObj.machineClass == 21 }">
          
        </div>
        <div class="machineTypeWord">其他</div>
      </div>
      <div class="machineType searchBox">
        <el-input class="searchInput" v-model="inputValue" @input="insertSearch" placeholder="请输入搜索内容" />
        <div class="CommodityContainer" v-show="searchResult.length != 0">
          <div class="searchContentTr" v-for="(searchitem, searchIndex) in searchResult"
            :key="searchitem.commodityId + 'priceManage'" @mousedown.prevent="goSearch(searchIndex)"><input
              class="comminputDown" v-model="searchitem.commodityName" type="text"
              :style="[{ height: '1.625rem' }]"><input type="text" class="searchIndication" v-model="searchitem.show">
          </div>
          <div class="searchContentTrLast" :style="[{ height: '1.625rem' }, { lineHeight: '1.625rem' }]"
            @mousedown.prevent="clearSearchResult()">关闭搜索</div>
        </div>
      </div>
    </div>
    <div class="contentChooseBox">
      <div class="contentContainer1">
        <div class="contentChooseHead"><span class="contentChooseIcon"></span><span class="contentChooseWord">品牌</span>
        </div>
        <div class="contentChooseBody">
          <el-scrollbar height="22rem">
            <div class="chooseContent scrollbar-demo-item" :class="{ 'activeBox': item.brandId == ChooseObj.brandId }"
              v-for="item in brandList" :key="item.brandId + 'QuoBrandShow'" @click="brandClick(item.brandId)">
              {{ item.brandName }}<span class="rightIcon" v-show="item.brandId == ChooseObj.brandId"></span></div>
          </el-scrollbar>
        </div>
      </div>
      <div class="contentContainer2">
        <div class="contentChooseHead"><span class="contentChooseIcon"></span><span class="contentChooseWord">系列</span>
        </div>
        <div class="contentChooseBody">
          <el-scrollbar height="22rem">
            <div class="chooseContent scrollbar-demo-item" v-for="item in seriesList" :key="item + 'QuoSeriesShow'"
              :class="{ 'activeBox': item == ChooseObj.seriesName }" @click="seriesClick(item)">{{ item }}<span
                class="rightIcon" v-show="item == ChooseObj.seriesName"></span></div>
          </el-scrollbar>
        </div>
      </div>
      <div class="contentContainer3">
        <div class="contentChooseHead"><span class="contentChooseIcon"></span><span class="contentChooseWord">机型</span>
        </div>
        <div class="contentChooseBody">
          <el-scrollbar height="22rem">
            <div class="chooseContent scrollbar-demo-item" v-for="item in MachineTypeList"
              :key="item.machineTypeId + 'QuoMachineShow'"
              :class="{ 'activeBox': item.machineTypeId == ChooseObj.machineTypeId }"
              @click="machineClick(item.machineTypeId)">{{ item.machineTypeName }}<span class="rightIcon"
                v-show="item.machineTypeId == ChooseObj.machineTypeId"></span></div>
          </el-scrollbar>
        </div>
      </div>
      <div class="contentContainer4">
        <div class="contentChooseHead"><span class="contentChooseIcon"></span><span class="contentChooseWord">故障</span>
        </div>
        <div class="contentChooseBody">
          <el-scrollbar height="22rem">
            <div class="chooseContent scrollbar-demo-item" v-for="item in breakdownList"
              :key="item.breakId + 'QuoBreakShow'" :class="{ 'activeBox': item.breakId == ChooseObj.breakdownId }"
              @click="breakClick(item.breakId)">{{ item.breakName }}<span class="rightIcon"
                v-show="item.breakId == ChooseObj.breakdownId && otherFactor1List.length > 0"></span></div>
          </el-scrollbar>
        </div>
      </div>
      <div class="contentContainer5">
        <div class="contentChooseHead"><span class="contentChooseIcon"></span><span
            class="contentChooseWord">参数1</span></div>
        <div class="contentChooseBody">
          <el-scrollbar height="22rem">
            <div class="chooseContent scrollbar-demo-item" v-for="item in otherFactor1List" :key="item + 'QuoFac1Show'"
              :class="{ 'activeBox': item == ChooseObj.factor1 }" @click="factor1Click(item)">{{ item }}<span
                class="rightIcon" v-show="item == ChooseObj.factor1 && otherFactor2List.length > 0"></span></div>
          </el-scrollbar>
        </div>
      </div>
      <div class="contentContainer6">
        <div class="contentChooseHead"><span class="contentChooseIcon"></span><span
            class="contentChooseWord">参数2</span></div>
        <div class="contentChooseBody">
          <el-scrollbar height="22rem">
            <div class="chooseContent scrollbar-demo-item" v-for="item in otherFactor2List" :key="item + 'QuoFac2Show'"
              :class="{ 'activeBox': item == ChooseObj.factor2 }" @click="factor2Click(item)">{{ item }}</div>
          </el-scrollbar>
        </div>
      </div>
    </div>

    <div class="quotationDescriptionBox">
      <span class="quoDesBox">报价说明:</span> <span class="quoBox">{{ quotationDes }}</span>
      <!-- <span class="quoDesBox">推荐报价:</span><span class="quoPriceBox" :class="{highLight:quotationPrice}">{{quotationPrice}}</span> -->
      <span class="quoDesBox">推荐报价:</span>
      <div class="quoPriceBox" :class="{ highLight: quotationPrice }">
        <el-tooltip class="box-item" effect="dark" :hide-after="0"
          :content="arrCost1.length > 0 ? `预估成本:${arrCost1[0]}元` : '未选中唯一商品'" placement="top-start">
          <el-button>{{ quotationPrice }}</el-button>
        </el-tooltip>
      </div>

    </div>
    <div class="quotationPriceBox">
      <span class="quoDesBox">使用商品:</span><span class="commNameBox">{{ storageQty.stoCommName }}</span>
      <span class="quoDesBox">可销库存:</span><span class="quoPriceBox" :class="{ highLight: storageQty.stoQty }">{{
      storageQty.stoQty }}</span>
    </div>

    <div class="funcButtonBox">
      <el-button type="primary" @click="showOtherStock()">查看友商库存</el-button>
      <el-button type="primary" @click="goObInputFromQuo()">一键生成预订单</el-button>
      <el-button type="warning" @click="goRoListFromQuo()">一键生成零售单</el-button>
      <el-button type="success" class="mrButton" @click="goFrInputFromQuo()">一键生成维修登记</el-button>
    </div>
    <BigPopModel ref="friendShowRef">
      <div class="popBigDiv">
        <div class="friendItemBox" v-for="item in friendStockList" :key="item.companyId + 'friendShowList'">
          <div class="nameBox">{{ item.companyName }}</div>
          <div class="qtyBox">{{ item.inventoryBalance }}</div>
          <div class="butBox"><span class="spanfunc" @click="goObInputFromQuo2(item)">预定</span></div>
        </div>
      </div>
    </BigPopModel>
  </div>
</template>

<script>
import { onMounted, onUnmounted, provide, reactive, ref, watch } from 'vue-demi'
import { getSeriesList, getMachineList, getBreakList } from '@/hooks/getCache'
import { useStore } from "vuex";
import requestData from "@/hooks/requestData.js";
import { getFriendStockList, transferToName, getComNameById, getVmiCostByAttr, getQuoList, getMachineTypeList } from '@/hooks/OpenOrder'
import BigPopModel from '@/components/Common/BigPopModel.vue'
import { insertSearchOrigin } from '@/hooks/tools';
import { ElMessage } from 'element-plus'
export default {
  components: {
    BigPopModel
  },
  setup(props, { emit }) {
    const store = useStore();
    const filterList = store.state.loginInitializeData.filterList
    const brandFilter = filterList.BrandId
    const classToBrandList = store.state.loginInitializeData.authList.classToBrandLists
    const breakDownCauseList = store.state.loginInitializeData.authList.breakdownCauseLists

    let ChooseObj = reactive({
      machineClass: '',
      brandId: '',
      seriesName: '',
      machineTypeId: '',
      breakdownId: '',
      factor1: '',
      factor2: ''
    })

    provide('tableTitle', '友商库存清单')

    let brandList = reactive([])

    let seriesList = reactive([])

    let MachineTypeList = reactive([])

    let breakdownList = reactive([])

    let otherFactor1List = reactive([])

    let otherFactor2List = reactive([])

    let breakBigList = reactive([])

    let quotationDes = ref('')

    let quotationPrice = ref('')

    let storageQty = reactive({
      stoCommodityId: '',
      stoQty: '',
      stoCommName: ''
    })


    const getBrandList = (value) => {

      brandList.splice(0)
      seriesList.splice(0)
      MachineTypeList.splice(0)
      breakdownList.splice(0)
      otherFactor1List.splice(0)
      otherFactor2List.splice(0)

      ChooseObj.machineClass = ''
      ChooseObj.brandId = ''
      ChooseObj.seriesName = ''
      ChooseObj.machineTypeId = ''
      ChooseObj.breakdownId = ''
      ChooseObj.factor1 = ''
      ChooseObj.factor2 = ''


      ChooseObj.machineClass = value
      const arr = classToBrandList.filter(x => x.commodityFirstClassNo == value)

      for (let item of arr) {
        item.brandName = brandFilter.find(x => x.beforeTransfer == item.brandId)?.afterTransfer
        brandList.push(item)
      }
    }

    const brandClick = (value) => {


      seriesList.splice(0)
      MachineTypeList.splice(0)
      breakdownList.splice(0)
      otherFactor1List.splice(0)
      otherFactor2List.splice(0)

      ChooseObj.brandId = ''
      ChooseObj.seriesName = ''
      ChooseObj.machineTypeId = ''
      ChooseObj.breakdownId = ''
      ChooseObj.factor1 = ''
      ChooseObj.factor2 = ''


      ChooseObj.brandId = value
      getSeriesList(ChooseObj.machineClass, value).then(res => {
        for (let item of res) {
          seriesList.push(item)
        }
      })
    }

    const seriesClick = (value) => {

      MachineTypeList.splice(0)
      breakdownList.splice(0)
      otherFactor1List.splice(0)
      otherFactor2List.splice(0)

      ChooseObj.seriesName = ''
      ChooseObj.machineTypeId = ''
      ChooseObj.breakdownId = ''
      ChooseObj.factor1 = ''
      ChooseObj.factor2 = ''

      ChooseObj.seriesName = value
      getMachineList(ChooseObj.machineClass, ChooseObj.brandId, value).then(res => {
        for (let item of res) {
          MachineTypeList.push(item)
        }
      })
    }

    const giveBreakWeight = (res) => {
      if (_.isArray(res) && res.length > 0) {
        for (let item of res) {
          switch (item.breakId) {
            case 100064:
              item.weightForSort = 1
              break;
            case 100065:
              item.weightForSort = 2
              break;
            case 100066:
              item.weightForSort = 3
              break;
            case 100068:
              item.weightForSort = 4
              break;
            case 100069:
              item.weightForSort = 5
              break;
            case 100070:
              item.weightForSort = 6
              break;
            case 100071:
              item.weightForSort = 7
              break;
            case 100067:
              item.weightForSort = 8
              break;
            case 100072:
              item.weightForSort = 9
              break;
            case 100073:
              item.weightForSort = 10
              break;
            case 100004:
              item.weightForSort = 2
              break;
            default:
              item.weightForSort = 999
              break;
          }
        }
      }
    }

    const machineClick = (value) => {
      breakdownList.splice(0)
      otherFactor1List.splice(0)
      otherFactor2List.splice(0)
      breakBigList.splice(0)

      ChooseObj.machineTypeId = ''
      ChooseObj.breakdownId = ''
      ChooseObj.factor1 = ''
      ChooseObj.factor2 = ''

      ChooseObj.machineTypeId = value

      getBreakList(ChooseObj.machineClass, ChooseObj.machineTypeId).then(res => {



        for (let item of res) {
          breakBigList.push(item)
        }
        let arrA = []
        for (let item of breakBigList) {
          arrA.push(item.breakdownCauseId)
        }
        let arrB = [...new Set(arrA)]
        for (let item1 of arrB) {
          let obj = {
            breakId: item1,
            breakName: breakDownCauseList.find(x => x.breakdownCauseId == item1)?.breakdownCauseName
          }
          breakdownList.push(obj)
        }
        giveBreakWeight(breakdownList)
        breakdownList.sort(function (a, b) { return parseInt(a.weightForSort) - parseInt(b.weightForSort) })
      })
    }

    const breakClick = (breakId) => {
      otherFactor1List.splice(0)
      otherFactor2List.splice(0)
      ChooseObj.breakdownId = ''
      ChooseObj.factor1 = ''
      ChooseObj.factor2 = ''
      ChooseObj.breakdownId = breakId
      let arrA = breakBigList.filter(x => x.breakdownCauseId == breakId)
      let arrB = []
      for (let item of arrA) {
        if (item.parameter1) {
          arrB.push(item.parameter1)
        }
      }
      let arrC = [...new Set(arrB)]
      for (let item2 of arrC) {
        otherFactor1List.push(item2)
      }
    }

    const factor1Click = (value) => {
      otherFactor2List.splice(0)
      ChooseObj.factor1 = ''
      ChooseObj.factor2 = ''
      ChooseObj.factor1 = value
      let arrA = breakBigList.filter(x => x.breakdownCauseId == ChooseObj.breakdownId && x.parameter1 == value)
      let arrB = []
      for (let item of arrA) {
        arrB.push(item.parameter2)
      }
      let arrC = [...new Set(arrB)]
      for (let item2 of arrC) {
        if (item2) {
          otherFactor2List.push(item2)
        }
      }
    }
    const factor2Click = (value) => {
      ChooseObj.factor2 = value
    }
    let arrCost1 = reactive([])

    watch(() => ChooseObj, (oldValue, newValue) => {
      quotationDes.value = ''
      quotationPrice.value = ''
      storageQty.stoCommodityId = ''
      storageQty.stoQty = ''
      storageQty.stoCommName = ''
      arrCost1.splice(0)
      if (newValue.breakdownId) {
        let arrA = breakBigList.filter(x => x.breakdownCauseId == ChooseObj.breakdownId)
        quotationDes.value = ''
        quotationDes.value = MachineTypeList.find(x => x.machineTypeId == newValue.machineTypeId)?.machineTypeName + ' ' + breakdownList.find(x => x.breakId == newValue.breakdownId).breakName + ' ' + newValue.factor1 + ' ' + newValue.factor2
        let arrPrice1 = []
        for (let item of breakBigList) {
          if (item.breakdownCauseId == newValue.breakdownId &&
            (item.parameter1?.indexOf(newValue.factor1) > -1 || newValue.factor1 == '') &&
            (item.parameter2?.indexOf(newValue.factor2) > -1 || newValue.factor2 == '')
          ) {
            arrPrice1.push(item.quotationForCustomer)
          }
        }
        let arrPrice2 = [...new Set(arrPrice1)]
        if (arrPrice2.length == 1) {
          const chooseBreakList = breakBigList.filter(x => x.breakdownCauseId == ChooseObj.breakdownId && x.parameter1 == ChooseObj.factor1 && x.parameter2 == ChooseObj.factor2)
          if (chooseBreakList.length == 1) {
            storageQty.stoCommodityId = chooseBreakList[0].commodityId
            let config1 = {
              method: "get",
              url: "/InventoryBalanceList/GetInvenQty",
              params: {
                id: storageQty.stoCommodityId
              }
            };
            requestData.GetCache(config1).then(res => {
              storageQty.stoQty = res
            })
            getVmiCostByAttr(store.state.loginInitializeData.companyId, storageQty.stoCommodityId, chooseBreakList[0].salesAttributeId).then(res => {
              arrCost1.push(res)
            })

            getComNameById(storageQty.stoCommodityId).then(res => {
              storageQty.stoCommName = res
            })
          }
          quotationPrice.value = arrPrice2[0] + '元'
        }
        if (arrPrice2.length > 1) {
          arrPrice2 = arrPrice2.sort((a, b) => a - b)
          if (arrPrice2[0] == arrPrice2[arrPrice2.length - 1]) {
            quotationPrice.value = arrPrice2[0] + '元'
          }
          else {
            quotationPrice.value = arrPrice2[0] + '元' + '~' + arrPrice2[arrPrice2.length - 1] + '元'
          }
        }
      }
    }, {
      deep: true
    })

    let friendStockList = reactive([])

    const friendShowRef = ref(null)

    const showOtherStock = () => {
      friendStockList.splice(0)
      if (!storageQty.stoCommodityId) {
        ElMessage('未选定商品')
        return
      }
      else {
        const partACompanyId = store.state.loginInitializeData.companyId
        getFriendStockList(storageQty.stoCommodityId, partACompanyId).then(res => {
          for (let item of res) {
            item.companyName = transferToName(item.companyId, 'PartBCompanyId')
            friendStockList.push(item)
          }
          friendShowRef.value.openPopComm()
        })
      }
    }


    const goRoListFromQuo = () => {
      if (!ChooseObj.breakdownId) {
        ElMessage('未选择故障')
        return;
      }
      let itemList = breakBigList.filter(x => x.breakdownCauseId == ChooseObj.breakdownId)
      if (ChooseObj.factor1) {
        itemList = itemList.filter(x => x.parameter1 == ChooseObj.factor1)
        if (ChooseObj.factor2) {
          itemList = itemList.filter(x => x.parameter2 == ChooseObj.factor2)
        }
      }
      if (itemList.length > 1) {
        ElMessage('跳转前请选择参数')
        return;
      }
      emit('goRoListFromQuotation', itemList)
    }

    const goFrInputFromQuo = () => {
      if (!ChooseObj.breakdownId) {
        ElMessage('未选择故障')
        return;
      }
      let itemList = breakBigList.filter(x => x.breakdownCauseId == ChooseObj.breakdownId)
      if (ChooseObj.factor1) {
        itemList = itemList.filter(x => x.parameter1 == ChooseObj.factor1)
        if (ChooseObj.factor2) {
          itemList = itemList.filter(x => x.parameter2 == ChooseObj.factor2)
        }
      }
      if (itemList.length > 1) {
        ElMessage('跳转前请选择参数')
        return;
      }
      if (itemList.length == 0) {
        ElMessage('无内容可跳转')
        return;
      }
      itemList[0].machineType = MachineTypeList.find(x => x.machineTypeId == ChooseObj.machineTypeId)?.machineTypeName
      itemList[0].breakCause = breakdownList.find(x => x.breakId == ChooseObj.breakdownId)?.breakName
      emit('goFrInputFromQuotation', itemList[0])
    }

    const goObInputFromQuo = () => {
      if (!ChooseObj.breakdownId) {
        ElMessage('未选择故障')
        return;
      }
      let itemList = breakBigList.filter(x => x.breakdownCauseId == ChooseObj.breakdownId)
      if (ChooseObj.factor1) {
        itemList = itemList.filter(x => x.parameter1 == ChooseObj.factor1)
        if (ChooseObj.factor2) {
          itemList = itemList.filter(x => x.parameter2 == ChooseObj.factor2)
        }
      }
      if (itemList.length > 1) {
        ElMessage('跳转前请选择参数')
        return;
      }
      if (itemList.length == 0) {
        ElMessage('无内容可跳转')
        return;
      }

      emit('goObInputFromQuo', itemList[0])
    }

    const goObInputFromQuo2 = (friend) => {
      if (!storageQty.stoCommodityId) {
        ElMessage('未选定商品')
        return
      }

      storageQty.friendName = friend.companyName

      emit('goObInputFromQuo2', storageQty)
    }


    onUnmounted(() => {

    })

    let inputValue = ref('')



    const insertSearch = _.debounce((e) => {
      let b = {}
      b.target = {
        value: e
      }
      insertSearchOrigin(b, searchResult)
    }, 500)

    let searchResult = reactive([]);

    const clearSearchResult = () => {
      searchResult.splice(0)
    }

    const goSearch = (index) => {
      const firstTwo = searchResult[index].commodityId.substring(0, 2)
      getBrandList(firstTwo)
      getQuoList(searchResult[index].commodityId).then(res => {
        clearSearchResult()
        inputValue.value = ''
        if (res.length > 0) {
          let quotationList1 = res[0]
          getMachineTypeList(quotationList1.commodityFirstClassNo, quotationList1.machineTypeId).then(res => {
            const machineTpyeList1 = res[0]
            if (res.length > 0) {
              seriesList.splice(0)
              MachineTypeList.splice(0)
              breakdownList.splice(0)
              otherFactor1List.splice(0)
              otherFactor2List.splice(0)

              ChooseObj.brandId = ''
              ChooseObj.seriesName = ''
              ChooseObj.machineTypeId = ''
              ChooseObj.breakdownId = ''
              ChooseObj.factor1 = ''
              ChooseObj.factor2 = ''
              ChooseObj.brandId = machineTpyeList1.brandId
              getSeriesList(ChooseObj.machineClass, machineTpyeList1.brandId).then(res => {
                for (let item of res) {
                  seriesList.push(item)
                }

                MachineTypeList.splice(0)
                breakdownList.splice(0)
                otherFactor1List.splice(0)
                otherFactor2List.splice(0)

                ChooseObj.seriesName = ''
                ChooseObj.machineTypeId = ''
                ChooseObj.breakdownId = ''
                ChooseObj.factor1 = ''
                ChooseObj.factor2 = ''

                ChooseObj.seriesName = machineTpyeList1.series
                getMachineList(ChooseObj.machineClass, ChooseObj.brandId, machineTpyeList1.series).then(res => {
                  for (let item of res) {
                    MachineTypeList.push(item)
                  }

                  breakdownList.splice(0)
                  otherFactor1List.splice(0)
                  otherFactor2List.splice(0)
                  breakBigList.splice(0)

                  ChooseObj.machineTypeId = ''
                  ChooseObj.breakdownId = ''
                  ChooseObj.factor1 = ''
                  ChooseObj.factor2 = ''

                  ChooseObj.machineTypeId = machineTpyeList1.machineTypeId

                  getBreakList(ChooseObj.machineClass, ChooseObj.machineTypeId).then(res => {
                    for (let item of res) {
                      breakBigList.push(item)
                    }
                    let arrA = []
                    for (let item of breakBigList) {
                      arrA.push(item.breakdownCauseId)
                    }
                    let arrB = [...new Set(arrA)]
                    for (let item1 of arrB) {
                      let obj = {
                        breakId: item1,
                        breakName: breakDownCauseList.find(x => x.breakdownCauseId == item1)?.breakdownCauseName
                      }
                      breakdownList.push(obj)
                    }
                    breakClick(quotationList1.breakdownCauseId)
                    if (quotationList1.parameter1) {
                      factor1Click(quotationList1.parameter1)
                      if (quotationList1.parameter2) {
                        factor2Click(quotationList1.parameter2)
                      }
                    }

                  })
                })
              })

            }
            else {
              ElMessage('未找到品牌')
              return;
            }
          })
        }
        else {
          ElMessage('未找到故障')
          return;
        }
      })

    }
    //#endregion

    return {
      getBrandList, brandList, ChooseObj, brandClick, seriesList,
      seriesClick, MachineTypeList, machineClick, breakdownList, breakClick, otherFactor1List, factor1Click,
      otherFactor2List, factor2Click, quotationDes, quotationPrice, goRoListFromQuo, goFrInputFromQuo,
      goObInputFromQuo, storageQty, showOtherStock, friendStockList, friendShowRef, goObInputFromQuo2, arrCost1,
      inputValue, insertSearch, searchResult, clearSearchResult, goSearch
    }
  }
}
</script>

<style scoped lang="less">
@chooseContentHeight: 25rem;

.quotationContainer {
  width: 100%;
  height: 100%;
}

.chooseBox {
  display: flex;
}

.moneyIcon {
  font-family: "icomoon";
  margin-right: 0.7rem;
  font-size: 1.5rem;
  color: #409eff;
}

.moneyWords {
  font-size: 1.5rem;
  color: #409eff;
}

.machineTypeBox {
  width: 100%;
  height: 3.5rem;
  padding-left: 2rem;
  padding-top: 0.7rem;
}

.machineType {
  margin-left: 3rem;
}

.machineTypeIcon,
.machineTypeWord {
  margin: auto;
  text-align: center;
}

.machineTypeIcon {
  font-family: "icomoon";
  border: 1px solid #d2d2d2;
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  line-height: 3rem;
  font-size: 1.5rem;
  color: #d2d2d2;
}

.machineTypeIcon:hover {
  border: transparent;
  background-color: #b0d5fe;
  color: #ffffff;
  cursor: pointer;
}

.activeTitle {
  border: transparent;
  background-color: #b0d5fe;
  color: #ffffff;
}

.machineTypeWord {
  margin-top: 0.3rem;
}

.contentChooseBox {
  display: flex;
  justify-content: space-around;
}

.contentContainer1,
.contentContainer2,
.contentContainer3,
.contentContainer4,
.contentContainer5,
.contentContainer6 {
  height: @chooseContentHeight;
  margin-top: 1.3rem;
}

.contentContainer1 {
  width: 7%;
  margin-left: 2rem;
}

.contentContainer2 {
  width: 10%;

}

.contentContainer3 {
  width: 25%;

  white-space: nowrap;
}

.contentContainer4 {
  width: 30%;
  height: @chooseContentHeight;

}

.contentContainer5 {
  width: 10%;

}

.contentContainer6 {
  width: 14%;

}

.contentChooseIcon {
  font-family: "icomoon";
  color: #409eff;
  line-height: 2.5rem;
  font-size: 1.3rem;
  padding-right: 0.4rem;
}

.rightIcon {
  font-family: "icomoon";
  right: 0.1rem;
  position: absolute;
}

.contentChooseWord {
  line-height: 2.5rem;
  font-size: 1.3rem;
}

.contentChooseHead {
  background-color: #e4e4e4;
  border-radius: 1rem;
  height: 2.5rem;
  text-align: center;
}

.contentChooseBody {
  height: 22rem;
  overflow: hidden;
}

.chooseContent {
  position: relative;
  text-align: center;
  margin-top: 1rem;
  font-size: 1rem;
  color: #949494;
  cursor: pointer;
}

.scrollbar-demo-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  margin: 10px;
  text-align: center;
  border-radius: 4px;
  background: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
}

.activeBox {
  background-color: #409eff !important;
  color: #fff;
}

.quotationDescriptionBox,
.quotationPriceBox {
  height: 3rem;
  display: flex;
  justify-content: flex-end;
}

.quotationPriceBox {
  margin-top: 1rem;
}

.quoDesBox {
  color: #545454;
  font-size: 1rem;
  margin-right: 1rem;
  line-height: 3rem;
}

.quoBox,
.quoPriceBox,
.commNameBox {
  background-color: #f9f9f9;
  display: block;
  margin-right: 2rem;
  line-height: 3rem;
  padding-left: 0.5rem;
  border: none !important;
}

.mrButton {
  margin-right: 10rem;
}

.quoBox {
  width: 40rem;
  font-size: 0.9rem;
  text-align: center;
}

.quoPriceBox {
  width: 10rem;
  font-size: 0.9rem;
  text-align: center;
}

.commNameBox {
  width: 40rem;
  font-size: 0.9rem;
  text-align: center;
}

.funcButtonBox {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.popBigDiv {
  display: flex;
  flex-flow: wrap;
  width: 90rem;
}

.friendItemBox {
  display: flex;
  width: 30rem;
  height: 2.6rem;
  line-height: 2.6rem;
  text-align: center;
}

.nameBox {
  width: 20rem;
}

.qtyBox {
  width: 4rem;
}

.butBox {
  width: 6rem;
}

.highLight {
  background-color: #ffd966;
  border-radius: 10px;
}

.box-item {
  border: none;
  background-color: transparent;
}

.searchInput {
  margin-top: 0.5rem;
  width: 30rem;
}

.CommodityContainer {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  border: 1px solid #cccccc;
  top: 4.6rem;
  left: 5.5rem;
  width: 40rem;
}

.searchContentTr {
  display: flex;
  margin-top: 0.2rem;
  width: 100%;
}

.searchContentTr:hover {
  background-color: #f5f5f6;
}

.searchContentTr input {
  border: none;
  outline: none;
  text-align: left;
  background-color: transparent;
  border-radius: 5px;
}

.searchContentTr input:hover {
  cursor: pointer;
}

.searchContentTrLast:hover {
  background-color: #fa2c2c;
  color: #fff;
  cursor: pointer;
}

.searchContentTrLast {
  padding-left: 0.3rem;
}

.searchIndication {
  text-align: center !important;
  color: red;
}

.comminputDown {
  width: 80%;
}

.searchIndication {
  width: 20%;
}

.commNameSearchBox {
  position: relative;
}

.CommodityContainer {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  border: 1px solid #cccccc;
  top: 4.1rem;
  left: 0rem;
  width: 40rem;
}

.commodityMangeLayer {
  margin-top: 1rem;
}

.searchBox {
  display: flex;
  position: relative;
}
</style>