<template>
  <div class="tableLoaderContainer">
    <div class="BodyTitle">
      <div class="BodyTitleText">机器询价处理</div>
    </div>
    <div class="searchContentContainer">
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="CustomerToBeSolveFSForMachineQueryListManage">处理情况:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput.activeFields == 'CustomerToBeSolveFS'}"><select v-model="SearchContent.CustomerToBeSolveFS" class="searchinput" id="CustomerToBeSolveFSForMachineQueryListManage" @focus="setActiveId('CustomerToBeSolveFS')" @blur="setActiveId('')" @change="reSearch()">
            <option v-for="item in CustomerToBeSolveFSFilter" :key="item.beforeTransfer + 'MachineQueryListManage'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
          </select></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="PartACompanyIdForMachineQueryListManage">供方:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput.activeFields == 'PartACompanyId'}"><select v-model="SearchContent.PartACompanyId" class="searchinput" id="PartACompanyIdForMachineQueryListManage" @focus="setActiveId('PartACompanyId')" @blur="setActiveId('')" @change="reSearch()">
            <option value="" selected="selected">所有</option>
            <option v-for="item in partBFilterCopy" :key="item.beforeTransfer + 'MachineQueryListManage'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
          </select></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="MachineNameMachineQueryListManage">机器名称:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput.activeFields == 'MachineName'}"><input v-model="SearchContent.MachineName" class="searchinput long" id="MachineNameMachineQueryListManage" type="text" @focus="setActiveId('MachineName')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DocumentDateLMachineQueryListManage">发起日期</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput.activeFields == 'DocumentDateL'}"><input v-model="SearchContent.DocumentDateL" class="searchinput" id="DocumentDateLMachineQueryListManage" type="date" @focus="setActiveId('DocumentDateL')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DocumentDateHMachineQueryListManage" v-text="'---'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput.activeFields == 'DocumentDateH'}"><input v-model="SearchContent.DocumentDateH" class="searchinput" id="DocumentDateHMachineQueryListManage" type="date" @focus="setActiveId('DocumentDateH')" @blur="setActiveId('')"></div>
      </div>
      <!-- 搜索模块 -->
      <div class="FuncButton" @click="searchList()">搜索</div>
      <div class="tipBox">
        <div class="tipColortodo"></div>
        <div class="tipWords">待处理</div>
      </div>
      <div class="tipBox">
        <div class="tipColorfinish"></div>
        <div class="tipWords">无需处理</div>
      </div>
    </div>
    <div class="BigTable">
      <div class="TableContentArea">
        <table class="tablehead">
          <tr class="headtr" :style="{ height : trHeight }">
            <th class="thSN">
              <div :style="{ width: SnWidth }"></div>
            </th>
            <th class="thselectall">
              <div :style="{ width: selectAllWidth }"><input class="selectAllCheckBox" type="checkbox" v-model="selectAll" /></div>
            </th>
            <th>
              <div class="thoper" :style="{ width: operationWidth }">操作</div>
            </th>
            <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'head'" :class="{  ShowUpArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == 1, ShowDownArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == -1,}" @click.stop="ChangeSort(value1.fieldsNameForVali)">
              <div :style="{ width: value1.showLen  + 'rem' }">{{ value1.showName }}</div>
            </th>
          </tr>
        </table>
        <div class="tablebodyarea" :style="{ width: tablewidth  + 'rem',height:tbodyHeight}">
          <table class="tablebody">
            <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'td' + index2" :class="[{todoLine:value2.customerToBeSolve},{nottodoLine:!value2.customerToBeSolve}]">
              <td>
                <div class="tdSN" :style="{ width: SnWidth }">{{+index2 + 1}}</div>
              </td>
              <td class="tableselect">
                <div :style="{ width: selectAllWidth }"><input type="checkbox" :value="value2['machineQueryCode']" v-model="selectedItems" /></div>
              </td>
              <td>
                <div class="tdoper" :style="{ width: operationWidth }">
                  <span class="spantt" v-show="tableBody[index2]['responseStatus'] == '同意' || tableBody[index2]['responseStatus'] == 103" @click="acceptMachine(index2)">确认收货</span>
                  <span class="spancancel" v-show="tableBody[index2]['responseStatus'] == '未处理' 
                  || tableBody[index2]['responseStatus'] == 101
                  || tableBody[index2]['responseStatus'] == 102
                  || tableBody[index2]['responseStatus'] == '拒绝'" @click="cancelQuery(index2)">取消</span>
                </div>
              </td>
              <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
                <div :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
              </td>
            </tr>
          </table>
        </div>
        <table class="tablefoot">
          <tr class="foottr" :style="{ height : trHeight }">
            <td>
              <div class="footsum" :style="{ width: SnWidth }">合计</div>
            </td>
            <td>
              <div :style="{ width: selectAllWidth }"></div>
            </td>
            <td>
              <div :style="{ width: operationWidth }"></div>
            </td>
            <td v-for="value4 in tableHead" :key="value4.fieldsNameForVali + 'foot'">
              <div :style="{ width: value4.showLen  + 'rem' }"></div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="moreFunctionBox">

    </div>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue-demi';
import { useStore } from "vuex";
import {
  ChangeFirToLo, ChangeFirToUp, ChangeYMD, changeArrContentToDate, copyArray
} from '@/hooks/tools';
import _ from "lodash";
import requestData from '@/hooks/requestData';
import {
  fillDefaultValue, changeToViewCode, sendRejectApply, transferToCode, deleteDocCenter, deleteBookApply,
  openMbList, getDocNo, deleteMachineQuery
} from '@/hooks/OpenOrder'
import { lockMachineQuery, unlockMachineQuery } from '@/hooks/LockAffair';
import { ElMessage } from 'element-plus'
export default {
  setup(props, { emit }) {
    //#region 定义区
    const store = useStore();

    const filterList = store.state.loginInitializeData.filterList

    const partBFilter = filterList.PartBCompanyId

    const partBFilterCopy = copyArray(partBFilter)

    const CustomerToBeSolveFSFilter = filterList.CustomerToBeSolveFS

    //初始化输入表名称
    const TableName = 'MachineQueryList';

    const PageName = 'MachineQueryListManage'

    const code = changeToViewCode(PageName)

    //#endregion

    //#region 全选
    let selectAll = ref(false);

    let selectedItems = ref([]);

    watch(selectAll, (newValue, oldValue) => {
      clearClick()
      if (code == store.state.loginInitializeData.ShowWindow[0]) {
        if (newValue == true) {
          selectedItems.value.splice(0);
          for (let item of tableBody) {
            selectedItems.value.push(item['machineQueryCode']);
          }
        } else {
          selectedItems.value.splice(0);
        }
      }
    });
    //#endregion

    //#region 输入模块
    //f存入活跃单元格数据
    let activeInput = reactive({
      activeIndex: -1,
      activeFields: ''
    })
    //#endregion

    //#endregion
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          searchList()
        }
      }
    )

    const setActiveId = (id) => {
      activeInput.activeFields = id
    }
    let selectAllWidth = ref('2.5rem')
    let trHeight = ref('1.875rem')
    let SnWidth = ref('3.75rem')
    let operationWidth = ref('8rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')

    let SearchContent = reactive({
      MachineQueryCode: '',
      PartACompanyId: '',
      PartBCompanyId: '',
      MachineStorageId: '',
      MachineName: '',
      UniqueCode: '',
      UserId: '',
      UnitPrice: '',
      PostedPrice: '',
      NotifyStatus: '',
      ResponseStatus: '',
      SupplierToBeSolveFS: '',
      CustomerToBeSolveFS: '',
      Remark: '',
      DocumentDateSaleH: '',
      DocumentDateSaleL: '',
      Staff: ''
    })
    fillDefaultValue(SearchContent, TableName)

    const initializeSearch = () => {
      SearchContent.CustomerToBeSolveFS = 1
    }

    initializeSearch()


    //f计算表格内容总宽度
    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) +
        parseFloat(operationWidth.value) +
        parseFloat(selectAllWidth.value) + 1.25 + tableHead.length * 1 + 0.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });

    let tableHead = reactive([
      { fieldsNameForVali: 'documentDate', showLen: 7.5, showName: '询价日期' },
      { fieldsNameForVali: 'partACompanyId', showLen: 12, showName: '供方' },
      { fieldsNameForVali: 'machineName', showLen: 25, showName: '机器名称' },
      { fieldsNameForVali: 'postedPrice', showLen: 5, showName: '发布价格' },
      { fieldsNameForVali: 'unitPrice', showLen: 7.5, showName: '成交价格' },
      { fieldsNameForVali: 'uniqueCode', showLen: 12, showName: '串码' },
      { fieldsNameForVali: 'responseStatus', showLen: 5, showName: '处理状态' },
      { fieldsNameForVali: 'remark', showLen: 31.25, showName: '备注' },
    ]);

    const searchElement = reactive({
      SortFieldsName: 'DocumentDate',
      SortDirection: 1
    })

    let tableBody = reactive([]);

    let ChangeSort = (fildsname) => {
      searchElement.SortFieldsName = ChangeFirToUp(fildsname)
      searchElement.SortDirection = -searchElement.SortDirection
      searchList()
    };

    const reSearch = () => {
      searchList()
    }

    const searchList = () => {

      let config = {
        method: "post",
        url: '/MachineQueryList/GetListByMulSearchForQueryManage',
        data: Object.assign(SearchContent, searchElement),
      };
      requestData
        .GetData(config)
        .then((res) => {
          selectAll.value = false
          selectedItems.value.splice(0)
          tableBody.splice(0);
          tableBody.push.apply(tableBody, res['machineQueryListMultiple']);
          changeArrContentToDate(tableBody, PageName)
          const filterList = store.state.loginInitializeData.filterList
          const companyFilter = filterList.OwedCompanyId
          const ResponseStatusFilter = filterList.ResponseStatus
          for (let item of tableBody) {
            for (let key in item) {
              if (key == 'partACompanyId') {
                item[key] = companyFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'responseStatus') {
                item[key] = ResponseStatusFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
            }
          }

        })
        .catch((err) => {

          ElMessage(err)
        });
    };
    searchList()


    const cancelQuery = (index) => {
      let b = confirm('拒绝后无法退回，确认请继续')
      if (!b) {
        return;
      }
      if (tableBody[index].responseStatus == '同意' || tableBody[index].responseStatus == 103) {
        ElMessage('对方已经确认，无法取消')
        return;
      }
      let codes = []
      codes.push(tableBody[index].machineQueryCode)
      lockMachineQuery(codes).then(res => {
        try {
          if (res != 'pass') {
            ElMessage(res)
            searchList()
            return;
          }
          deleteBookApply(tableBody[index].machineStorageId).then(res => {
            if (res != 'pass') {
              searchList()
              ElMessage('拒绝失败')
              return
            }
            searchList()
            emit('refreshMsg')
          })


        } catch (error) {



        }

        finally {
          unlockMachineQuery(codes)


        }





      }).catch(err => {

        searchList()
      })

    }

    const acceptMachine = (index) => {
      let b = confirm('确认账单并入库')
      if (!b) {
        return;
      }

      let codes = []
      codes.push(tableBody[index].machineQueryCode)

      lockMachineQuery(codes).then(res => {
        try {
          if (res != 'pass') {
            ElMessage(res)
            searchList()
            return;
          }
          const body = tableBody[index]
          const partACom = store.state.loginInitializeData.companyId
          const staffAId = tableBody[index].staffId
          const partBCom = transferToCode(body.partACompanyId, 'OwedCompanyId')
          const user = store.state.loginInitializeData.userName
          const userId = store.state.loginInitializeData.userId
          getDocNo('MB').then(res => {
            let mbHeadCode = res
            let mbBody = [{
              DocumentId: mbHeadCode,
              Sn: 1,
              PartACompanyId: partACom,
              PartBCompanyId: partBCom,
              DocumentDateTime: '',
              CommodityName: body.machineName,
              CommodityId: body.machineStorageId,
              OrderQty: 1,
              UnitPrice: body.unitPrice,
              Amount: body.unitPrice,
              Remark: `${user}快捷采购入库`,
              NotifyStatus: '',
              RefusedQty: '',
              ResponsedQty: '',
              ResponseStatus: '',
              InStockQty: 1,
              InStockStatus: 103,
              InspectedQty: '',
              InspectionStatus: '',
              InvoiceReceiveAmount: '',
              InvoiceReceivedStatus: '',
              MoneyPayedAmount: '',
              MoneyPayedStatus: '',
              SourceDocumentId: '',
              RootSourceDocumentId: '',
              ModifiedTimes: '',
            }]
            let mbHead = {
              DocumentId: mbHeadCode,
              DocumentTypeName: '',
              DocumentDate: '',
              DocumentDateTime: '',
              Online: '',
              PartACompanyId: partACom,
              PartBCompanyId: partBCom,
              UserId: userId,
              StaffId: staffAId,
              SourceDocumentId: '',
              Amount: body.unitPrice,
              Qty: 1,
              OutWareHouseId: '',
              InWareHouseId: '',
              SystemStatusId: 104,
              CapitalAccountId: '',
              Remark: '',
              RootSourceDocumentId: mbHeadCode,
            }
            openMbList(mbBody, mbHead, mbHeadCode).then(res => {
              if (res != 'pass') {
                const errMessage = res
                deleteDocCenter(mbHeadCode).then(res => {
                  searchList()
                  ElMessage(errMessage)
                  return;
                })
              }
              else {
                deleteMachineQuery(body.machineQueryCode).then(res => {
                  searchList()
                  emit('refreshMsg')
                })
              }
            })
          })


        } catch (error) {



        }

        finally {
          unlockMachineQuery(codes)


        }



      }).catch(err => {

        searchList()
      })
    }


    return {
      partBFilterCopy, CustomerToBeSolveFSFilter, setActiveId,
      trHeight, SnWidth, operationWidth, tableHead, searchList, ChangeSort, tablewidth, tableBody,
      reSearch, searchElement, tbodyHeight, SearchContent, selectAllWidth,
      selectAll, selectedItems, activeInput, cancelQuery, acceptMachine
    }
  },
  components: {
  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;
@lableMarginLeft: 0.625rem;
@lableHeight: 2rem;
@inputHeight: 1.5rem;
@mainColor: #4285f4;

.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.searchinputLabel {
  margin-left: @lableMarginLeft;
  font-size: 0.8rem;
  height: @lableHeight;
  line-height: @lableHeight;
}
.searchinput {
  margin-top: calc((@lableHeight - @inputHeight) / 2);
  margin-bottom: calc((@lableHeight - @inputHeight) / 2);
  font-size: 0.9rem;
  border: none;
  outline: none;
  height: @inputHeight;
  line-height: @inputHeight;
}
.ActiveBox {
  border: 1px solid #ffa004 !important;
}

.searchInputBox {
  height: @lableHeight;
  border: 1px solid #c9c9c9;
  margin-left: @lableMarginLeft;
  border-radius: 0.31rem;
}

.searchItemContainer {
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
  display: flex;
}

.searchSetting {
  margin-left: calc(@lableMarginLeft*3);
  height: @lableHeight;
  display: flex;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}
.searchSetting:hover {
  cursor: pointer;
  color: @mainColor;
}
.settingIcon {
  font-family: "icomoon";
  font-size: 1.1rem;
  line-height: @lableHeight;
}

.settingText {
  margin-left: 0.3rem;
  font-size: 0.8rem;
  line-height: @lableHeight;
}
.dateInputContainer {
  display: flex;
}
.BodyTitle {
  width: 98%;
  margin-top: 0.5rem;
  margin-left: 1%;
}
.BodyTitleText {
  font-size: 1.2rem;
  font-weight: 200;
}
.long {
  width: 20rem;
}

.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 60%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.thSN {
  font-family: "icomoon";
  color: #fff;
  font-size: 16px;
}
.headtd {
  cursor: pointer;
}
.ShowUpArrow {
  position: relative;
}
.ShowUpArrow::after {
  content: "\ea32";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
  color: #fff;
}
.ShowDownArrow {
  position: relative;
}
.ShowDownArrow::after {
  content: "\ea36";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.tdSN,
.tableselect,
.tdoper,
.footsum,
.bodytr {
  text-align: center;
}
.bodytr {
  height: 2rem;
}

.spancancel,
.spansale,
.spantt {
  display: inline-block;
  height: 1.25rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  line-height: 1.25rem;
  font-size: 0.5rem;
  margin-left: 0.5rem;
}
.spantt {
  background-color: #409eff;
}
.spansale {
  background-color: #ffb30f;
}
.spancancel {
  background-color: #fa2c2c;
}
.tablefoot {
  margin-top: -1px;
}

.moreFunctionBox {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.selectAllCheckBox {
  cursor: pointer;
}
.commodityTr {
  position: relative;
}
.tableinputContainer {
  display: flex;
  position: relative;
}
.bodytr input {
  border: none;
  outline: none;
  height: 1.875rem;
  background-color: transparent;
  text-align: center;
}
.bodytr input:focus {
  border: 1px solid #ffa004;
}
.CommodityContainer {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  margin-top: 1px;
  border: 1px solid #cccccc;

  // border-left: 2px solid #4e71f2;
  // border-right: 2px solid #4e71f2;
  // border-bottom: 2px solid #4e71f2;
}
.searchContentTr {
  display: flex;
}
.searchContentTr:hover {
  background-color: #fff;
  background-color: #f5f5f6;
}
.searchContentTr input {
  text-align: left;
}

.searchContentTr input:hover {
  cursor: pointer;
}
.searchContentTrLast:hover {
  background-color: #fa2c2c;
  color: #fff;
  cursor: pointer;
}
.searchContentTrLast {
  padding-left: 0.3rem;
  text-align: left;
}
.checkbo {
  margin-left: 0.2rem;
  cursor: pointer;
}
.headtdEdit {
  cursor: pointer;
}
.highInput {
  background-color: #d9e1f2 !important;
}
.firstButton {
  margin-left: 1rem;
}

.todoLine {
  background-color: #fce4d6 !important;
}

.nottodoLine {
  background-color: #a6a6a6 !important;
}

.tipColortodo,
.tipColorfinish {
  height: 1.2rem;
  width: 1.2rem;
  margin-top: 0.3rem;
}
.tipColortodo {
  background-color: #fce4d6;
}
.tipColorfinish {
  background-color: #a6a6a6;
}
.tipWords {
  height: 1.2rem;
  line-height: 1.2rem;
  margin-top: 0.3rem;
  margin-left: 0.5rem;
}
.tipBox {
  display: flex;
  height: 1.75rem;
  width: 8rem;
  margin-top: 0.625rem;
  margin-left: 0.8rem;
}
</style>