<template>
  <div class="tableLoaderContainer">
    <BodyTitle></BodyTitle>
    <SearchContentForCapitalAccountsList @deliverSearchItems="deliverSearchItems" ref="searchRef"></SearchContentForCapitalAccountsList>
    <TableShowForDiy @saveActiveItem="saveActiveItem" @clearActive="clearActive" ref="tableShowRef" @perfectTableBody="perfectTableBody" @perfectTableHead="perfectTableHead"></TableShowForDiy>
    <div class="moreFunctionBox">
      <div class="FuncButton" @click="goCapitalChange()">资金变动查询</div>
    </div>
  </div>
</template>

<script>
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import SearchContentForCapitalAccountsList from '@/components/Content/MainBodyContent/SearchContentForCapitalAccountsList.vue'
import TableShowForDiy from '@/components/Content/MainBodyContent/TableShowForDiy.vue';
import { useStore } from "vuex";
import DiyShowTable from '@/hooks/DiyShowTable'
import { toDecimal2 } from '@/hooks/tools'
import requestData from '@/hooks/requestData';
import { ElMessage } from 'element-plus'
export default {
  setup(props, { emit }) {
    //#region 定义区
    const store = useStore();
    //初始化输入表名称
    const TableName = 'CapitalAccountList';

    const PageName = 'CapitalAccountList'

    let { tableShowRef, searchRef, deliverSearchItems, activeItem, saveActiveItem, clearActive } = DiyShowTable(TableName, PageName)
    //根据表名给searchItemList赋值


    const goCapitalChange = () => {
      if (activeItem.index <= -1) {
        ElMessage('未选择行')
        return;
      }
      emit('goCapitalAccountBlanceChangeListFromCap', activeItem)
    }

    const perfectTableHead = (head) => {
      let b = {
        fieldsNameForVali: 'AccountsBalance',
        showLen: 12,
        showName: '余额'
      }
      head.push(b)
    }

    const perfectTableBody = (body) => {
      if (!body.length) {
        return
      }
      for (let item of body) {
        let config = {
          method: "get",
          url: '/CapitalAccountBlanceList/GetBalanceById',
          params: {
            id: store.state.loginInitializeData.filterList.CapitalAccountId.filter(x => x.afterTransfer == item?.capitalAccountId)[0].beforeTransfer
          }
        };
        requestData.GetCache(config).then(res => {
          item.AccountsBalance = res
        })
      }
    }

    //#endregion


    return {
      deliverSearchItems,
      tableShowRef,
      saveActiveItem,
      activeItem, clearActive, goCapitalChange, searchRef,
      perfectTableHead, perfectTableBody
    }
  },
  components: {
    BodyTitle,
    SearchContentForCapitalAccountsList,
    TableShowForDiy
  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}
</style>