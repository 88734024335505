<template>
  <div class="TotalContainer">
    <div class="TopContainer">
      <Top></Top>
    </div>
    <div class="DownContainer">
      <div class="MessageBarContainer" :style="[{ width: MessageWidth }]">
        <MsgBar ref="openInfo"></MsgBar>
        <div class="ShrinkIn" :style="[{ left: MessageWidth }]" @click="moveMessageBox()">
          <el-icon>
            <DArrowLeft v-show="MessageWidth == '18rem'" class="ShrinkInIcon" style="width: 1.2em; height: 1.2em" />
            <DArrowRight v-show="MessageWidth != '18rem'" class="ExpandOutIcon" style="width: 1.2em; height: 1.2em" />
          </el-icon>
        </div>
      </div>
      <div class="MainPageContainer" :style="[{ width: ContentWith }]">
        <div class="ActiveBarContainer">
          <ActiveBar></ActiveBar>
        </div>
        <div class="MainContainer">
          <MainBody @refreshMessageBar="refreshMessageBar()"></MainBody>
        </div>
      </div>
    </div>
    <div class="popWindowcloak" v-show="$store.state.netloading">
      <img class="loadingimg" src="../../src/assets/image/Padding/loading1.gif" alt="加载中">
    </div>


  </div>
</template>

<script>
import { DArrowLeft } from "@element-plus/icons";
import { DArrowRight } from "@element-plus/icons";
import { computed, ref } from "vue-demi";
import MsgBar from "@/components/Content/MainPageContent/MsgBar.vue";
import Top from "@/components/Content/MainPageContent/Top.vue";
import ActiveBar from "@/components/Content/MainPageContent/ActiveBar.vue";
import MainBody from "@/components/Content/MainPageContent/MainBody.vue";
import { useStore } from "vuex";
export default {
  setup() {
    let MessageWidth = ref("18rem");
    setTimeout(() => {
      MessageWidth.value = '18rem'
    }, 0);

    let ContentWith = ref("calc(100% - 18rem)");
    const widthA = "18rem";
    const openInfo = ref(null);

    const refreshMessageBar = () => {
      openInfo.value.refreshMsgBar()
    }

    let moveMessageBox = () => {
      let a = MessageWidth.value;
      MessageWidth.value = a == widthA ? "1rem" : widthA;
      ContentWith.value = a == widthA ? "calc(100% - 3rem)" : "calc(100% - 20rem)";
      let b = a == widthA ? false : true
      openInfo.value.changeOpenInfo(b)
    };
    return {
      MessageWidth,
      ContentWith,
      moveMessageBox,
      openInfo,
      refreshMessageBar

    };
  },
  components: {
    DArrowLeft,
    DArrowRight,
    MsgBar,
    Top,
    ActiveBar,
    MainBody
  },
};
</script>

<style scoped lang="less">
@TopHeight: 5.63rem;
@MessageWidth: 18rem;

.TotalContainer {
  width: 100%;
  height: 100%;
}

.TopContainer {
  width: 100%;
  height: @TopHeight;
  background-color: #409eff;
}

.DownContainer {
  width: 100%;
  height: calc(100% - @TopHeight);
  background-color: #f2f2f2;
  display: flex;
}

.MessageBarContainer {
  height: 100%;
  position: relative;
  margin-right: 2rem;
  background-color: #fff;
  transition: all 0.5s;
}

.ShrinkIn {
  position: absolute;
  top: 50%;
  color: #409eff;
  transition: all 0.5s;
}

.ShrinkIn:hover {
  cursor: pointer;
}

.ShrinkInIcon:hover {
  transform: translateX(-1px);
}

.ExpandOutIcon:hover {
  transform: translateX(1px);
}

.MainPageContainer {
  height: 100%;
  overflow: hidden;
}
.ActiveBarContainer {
  height: 2.5rem;
  width: 100%;
}

.MainContainer {
  height: calc(100% - 2rem);
  width: 100%;
}
</style>