<template>
  <BigPopModel ref="popModelRef" @clearData="clearData()">
    <div class="tableContainer">
      <div class="searchContentContainer">
        <div class="searchItemContainer">
          <label for="PartBCompanyAbbre" class="searchinputLabel">往来单位名称:</label>
          <div class="searchInputBox"><input type="text" class="searchinput" id="PartBCompanyAbbre" v-model="searchItemList2.PartBCompanyAbbre.defaultValue" @blur="unbindBpEnter()" @mousedown="bindBpEnter"></div>
        </div>
        <div class="searchItemContainer">
          <label for="Tag" class="searchinputLabel">分类:</label>
          <div class="searchInputBox"><input type="text" class="searchinput" id="Tag" v-model="searchItemList2.Tag.defaultValue"></div>
        </div>
        <div class="FuncButton" @click="searchList()">搜索</div>
      </div>
      <TableShowForPop :tableTile="tableTile1" :sendBackFildsName="sendBackFildsName" ref="tableShowForPopRef" @getPopValue="getPopValue" @clearData="clearData"></TableShowForPop>
    </div>
  </BigPopModel>
</template>

<script>
import { inject, onMounted, onUnmounted, provide, reactive, ref } from 'vue-demi'
import BigPopModel from '@/components/Common/BigPopModel.vue';
import TableShowForPop from '@/components/Content/MainBodyContent/TableShowForPop.vue';
import { useStore } from "vuex";
import InputPop from '@/hooks/InputPop';
export default {
  components: {
    BigPopModel,
    TableShowForPop
  },
  props:['tableTile'],
  setup(props) {

    let { store, searchItemList, popModelRef, tableShowForPopRef, listForValidation, filterList, openPopComm, closePopComm, searchList, bindBpEnter, unbindBpEnter } = InputPop()

    const tableTitle = ref('选择往来单位')

    const tableTile1 = props.tableTile
    const sendBackFildsName = reactive({
      sendBackName: 'partBCompanyAbbre',
    })
    const TableName = 'BusinessPartnerList';
    let searchItemList2 = store.state.loginInitializeData.pageNameManage.filter(x => x.tableName == TableName)[0]

    const getPopValue = (value) => {
      searchItemList.addBodyContent.PartBCompanyId = value
      closePopComm()
    }

    searchItemList2.typeJudge = 'Bp'

    provide('searchItemList2', searchItemList2)

    provide('tableTitle', tableTitle)

    //#region 公式定义区
    //将表格相关数据连接进数据主线
    const fillSearchItemList = () => {
      const list = listForValidation.filter(x => x.tableNameForVali == searchItemList2.tableName && x.validateMethod == 'add')
      for (let item of list) {
        searchItemList2[item.fieldsNameForVali] = item
        for (let fkey in filterList) {
          if (item.fieldsNameForVali == fkey) {
            searchItemList2[item.fieldsNameForVali].Filter = filterList[fkey]
          }
        }
      }
    }

    const sendMsgSolve = (msg) => {
      searchItemList2.PartBCompanyAbbre.defaultValue = msg
    }

    const clearData = () => {
      searchItemList2.PartBCompanyAbbre.defaultValue = ''
      searchItemList2.Tag.defaultValue = ''
    }

    //#endregion

    //#region 数据提供区

    //#endregion

    //#region 挂载前加载
    fillSearchItemList()
    //#endregion

    //#region 监控区

    //#endregion

    //#region 失去活跃处理

    //#endregion

    //#region 活跃时处理

    //#endregion

    //#region 离开时处理
    //#endregion

    return {
      popModelRef,
      openPopComm,
      closePopComm,
      tableShowForPopRef,
      searchList,
      sendBackFildsName,
      getPopValue,
      searchItemList,
      bindBpEnter,
      unbindBpEnter,
      sendMsgSolve,
      searchItemList2,
      fillSearchItemList,
      clearData,tableTile1

    }
  }
}
</script>

<style scoped lang="less">
</style>