<template>
  <div class="tableLoaderContainer">

    <div class="BodyTitle">
      <div class="BodyTitleText">账单确认</div>
    </div>

    <div class="searchContentContainer">
      <div class="searchItemContainer">
        <label class="searchinputLabel">账单类型:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentType'}"><select v-model="SearchContent.DocumentType" class="searchinput" @focus="setActiveId('DocumentType')" @blur="setActiveId('')" @change="reSearch()">
            <option value="">所有</option>
            <option value="扣款单">扣款单</option>
            <option value="奖励单">奖励单</option>
          </select></div>

        <label class="searchinputLabel">往来单位:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'PartBCompanyId'}"><select v-model="SearchContent.PartBCompanyId" class="searchinput" @focus="setActiveId('PartBCompanyId')" @blur="setActiveId('')" @change="reSearch()">
            <option value="" selected="selected">所有</option>
            <option v-for="item in partBFilterCopy" :key="item.beforeTransfer + 'AccountsChangeOnTheWaySearch'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
          </select></div>

        <label class="searchinputLabel">发起日期:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateL'}"><input v-model="SearchContent.DocumentDateL" class="searchinput" type="date" @focus="setActiveId('DocumentDateL')" @blur="setActiveId('')"></div>
        <label class="searchinputLabel" v-text="'---'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateH'}"><input v-model="SearchContent.DocumentDateH" class="searchinput" type="date" @focus="setActiveId('DocumentDateH')" @blur="setActiveId('')"></div>
      </div>
      <!-- 搜索模块 -->
      <div class="FuncButton" @click="searchList()">搜索</div>
    </div>

    <div class="BigTable">
      <div class="TableContentArea">
        <table class="tablehead">
          <tr class="headtr" :style="{ height : trHeight }">
            <th class="thSN">
              <div :style="{ width: SnWidth }"></div>
            </th>
            <th class="thselectall">
              <div :style="{ width: selectAllWidth }"><input class="selectAllCheckBox" type="checkbox" v-model="selectAll" /></div>
            </th>
            <th>
              <div class="thoper" :style="{ width: operationWidth }">操作</div>
            </th>
            <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'head'" :class="{  ShowUpArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == 1, ShowDownArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == -1,}" @click.stop="ChangeSort(value1.fieldsNameForVali)">
              <div :style="{ width: value1.showLen  + 'rem' }">{{ value1.showName }}</div>
            </th>
          </tr>
        </table>
        <div class="tablebodyarea" :style="{ width: tablewidth  + 'rem',height:tbodyHeight}">
          <table class="tablebody">
            <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'td' + index2">
              <td>
                <div class="tdSN" :style="{ width: SnWidth }">{{+index2 + 1}}</div>
              </td>
              <td class="tableselect">
                <div :style="{ width: selectAllWidth }"><input type="checkbox" :value="value2['accountsChangeOnTheWayCode']" v-model="selectedItems" /></div>
              </td>
              <td>
                <div class="tdoper" :style="{ width: operationWidth }">
                  <span class="spanedit" @click="confirmAccounts(value2)" v-show="$store.state.loginInitializeData.authList.buttonVisibleManages.find(x=>x.buttonId == 'AccountsChangeOnTheWayListConfirm')?.visibleOrNot">确认</span>
                  <span class="spandele" v-show="$store.state.loginInitializeData.authList.buttonVisibleManages.find(x=>x.buttonId == 'AccountsChangeOnTheWayListReject')?.visibleOrNot">拒绝</span>
                </div>
              </td>
              <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
                <div :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
              </td>
            </tr>
          </table>
        </div>
        <table class="tablefoot">
          <tr class="foottr" :style="{ height : trHeight }">
            <td>
              <div class="footsum" :style="{ width: SnWidth }">合计</div>
            </td>
            <td>
              <div :style="{ width: selectAllWidth }"></div>
            </td>
            <td>
              <div :style="{ width: operationWidth }"></div>
            </td>
            <td v-for="value4 in tableHead" :key="value4.fieldsNameForVali + 'foot'">
              <div :style="{ width: value4.showLen  + 'rem' }"></div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="moreFunctionBox">
      <!-- <div class="FuncButton" @click="goInvenChange()">库存变动查询</div> -->
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue-demi';
import { useStore } from "vuex";
import {
  ChangeFirToLo, ChangeFirToUp, ChangeYMD, changeArrContentToDate, copyArray, getUUId
} from '@/hooks/tools';
import requestData from '@/hooks/requestData';
import { fillDefaultValue, changeToViewCode, addRemindTime, closeObChasing, getDocType, openErList, getDocNo, transferToCode, addMoneyToBePaid, transferToName, getBodyInstance, openEdList, openGdList, deleteAccountsChangeOnTheWayList } from '@/hooks/OpenOrder'
import { lockAccoutsChange, lockObTracing, unlockAccoutsChange, unLockObTracing } from '@/hooks/LockAffair'
import { ElMessage } from 'element-plus'
export default {
  setup(props, { emit }) {
    //#region 定义区
    const store = useStore();

    const filterList = store.state.loginInitializeData.filterList

    const partBFilter = filterList.PartBCompanyId

    const partBFilterCopy = copyArray(partBFilter)


    //初始化输入表名称
    const TableName = 'AccountsChangeOnTheWayList';

    const PageName = 'AccountsChangeOnTheWayList'

    const code = changeToViewCode(PageName)

    //#endregion

    //#region 全选
    let selectAll = ref(false);

    let selectedItems = ref([]);

    watch(selectAll, (newValue, oldValue) => {

      if (code == store.state.loginInitializeData.ShowWindow[0]) {
        if (newValue == true) {
          selectedItems.value.splice(0);
          for (let item of tableBody) {
            selectedItems.value.push(item['accountsChangeOnTheWayCode']);
          }
        } else {
          selectedItems.value.splice(0);
        }
      }
    });
    //#endregion

    let activeInput = ref('');

    const setActiveId = (id) => {
      activeInput.value = id
    }
    let selectAllWidth = ref('4rem')
    let trHeight = ref('1.875rem')
    let SnWidth = ref('3.75rem')
    let operationWidth = ref('8rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')

    let SearchContent = reactive({
      AccountsChangeOnTheWayCode: '',
      DocumentId: '',
      Sn: '',
      DocumentDateL: '',
      DocumentDateH: '',
      PartACompanyId: '',
      PartBCompanyId: '',
      DocumentType: '',
      UnitPrice: '',
      Remark: '',

    })
    fillDefaultValue(SearchContent, TableName)

    //f计算表格内容总宽度
    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) + parseFloat(operationWidth.value) + parseFloat(selectAllWidth.value) + 1.25 + tableHead.length * 1 + 0.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });

    let tableHead = reactive([
      // { fieldsNameForVali: 'accountsChangeOnTheWayCode', showLen: 80, showName: '账单调整码' },
      { fieldsNameForVali: 'partBCompanyId', showLen: 16, showName: '来源公司' },
      { fieldsNameForVali: 'documentId', showLen: 16, showName: '单据Id' },
      { fieldsNameForVali: 'sn', showLen: 6, showName: '序' },
      { fieldsNameForVali: 'documentDate', showLen: 14, showName: '单据日期' },
      // { fieldsNameForVali: 'partACompanyId', showLen: 16, showName: '待处理公司' },
      { fieldsNameForVali: 'documentType', showLen: 6, showName: '单据类型' },
      { fieldsNameForVali: 'unitPrice', showLen: 6, showName: '金额' },
      { fieldsNameForVali: 'remark', showLen: 20, showName: '备注' },

    ]);

    const searchElement = reactive({
      SortFieldsName: 'DocumentDate',
      SortDirection: 1
    })

    let tableBody = reactive([]);

    let ChangeSort = (fildsname) => {
      searchElement.SortFieldsName = ChangeFirToUp(fildsname)
      searchElement.SortDirection = -searchElement.SortDirection
      searchList()
    };


    const reSearch = () => {
      searchList()
    }

    let searchList = () => {
      let config = {
        method: "post",
        url: '/AccountsChangeOnTheWayList/GetListByMulSearch',
        data: Object.assign(SearchContent, searchElement),
      };
      requestData
        .GetData(config)
        .then((res) => {
          selectAll.value = false
          selectedItems.value.splice(0)
          tableBody.splice(0);
          tableBody.push.apply(tableBody, res['accountsChangeOnTheWayListMultiple']);
          changeArrContentToDate(tableBody, PageName)
          const filterList = store.state.loginInitializeData.filterList
          for (let item of tableBody) {
            for (let key in item) {
              if (key == 'partBCompanyId') {
                item[key] = partBFilterCopy.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
            }
          }
        })
        .catch((err) => {
        });
    };
    searchList()

    const confirmAccounts = (body) => {
      const codes = []
      codes.push(body.accountsChangeOnTheWayCode)
      lockAccoutsChange(codes).then(res => {
        try {
          if (res != 'pass') {
            ElMessage(res)
            searchList()
            return;
          }
          let docuHead = getBodyInstance('DocumentCenter')
          const partAId = body.partACompanyId
          const partBId = transferToCode(body.partBCompanyId, 'BusinessPartnerId')
          const userId = store.state.loginInitializeData.userId
          const uPrice = body.unitPrice
          docuHead.PartACompanyId = partAId
          docuHead.partBCompanyId = partBId
          docuHead.UserId = userId
          if (body.documentType == '扣款单') {
            getDocNo('ED').then(res => {
              const edHeadCode = res
              docuHead.DocumentId = edHeadCode
              let edBody = getBodyInstance('EdList')
              edBody.DocumentId = edHeadCode
              edBody.Sn = 1
              edBody.PartACompanyId = partAId
              edBody.PartBCompanyId = partBId
              edBody.OrderQty = 1
              edBody.InStockQty = 1
              edBody.OutStockQty = 1
              edBody.SourceDocumentId = edHeadCode
              edBody.RootSourceDocumentId = edHeadCode
              edBody.UnitPrice = uPrice
              edBody.Remark = `${transferToName(partBId, 'BusinessPartnerId')}扣款${uPrice}元`
              let edBodys = []
              edBodys.push(edBody)
              openEdList(edBodys, docuHead, edHeadCode).then(res => {
                if (res != 'pass') {
                  ElMessage(res)
                  return false;
                }
                else {
                  ElMessage('确认成功')
                  deleteAccountsChangeOnTheWayList(codes[0]).then(res => {
                    searchList()
                    emit('refreshMsg')
                  })
                }
              }).catch(err => {
                ElMessage(err)
                searchList()
              })
            })
          }
          else if (body.documentType == '奖励单') {
            getDocNo('GD').then(res => {
              const gdHeadCode = res
              docuHead.DocumentId = gdHeadCode
              let gdBody = getBodyInstance('GdList')
              gdBody.DocumentId = gdHeadCode
              gdBody.Sn = 1
              gdBody.PartACompanyId = partAId
              gdBody.PartBCompanyId = partBId
              gdBody.OrderQty = 1
              gdBody.InStockQty = 1
              gdBody.OutStockQty = 1
              gdBody.SourceDocumentId = gdHeadCode
              gdBody.RootSourceDocumentId = gdHeadCode
              gdBody.UnitPrice = uPrice
              gdBody.Remark = `${transferToName(partBId, 'BusinessPartnerId')}奖励${uPrice}元`
              let gdBodys = []
              gdBodys.push(gdBody)
              openGdList(gdBodys, docuHead, gdHeadCode).then(res => {
                if (res != 'pass') {
                  ElMessage(res)
                  return false;
                }
                else {
                  ElMessage('确认成功')
                  deleteAccountsChangeOnTheWayList(codes[0]).then(res => {
                    emit('refreshMsg')
                    searchList()
                  })
                }
              }).catch(err => {
                ElMessage(err)
                searchList()
              })
            })
          }
          else {
            searchList()
          }


        } catch (error) {



        }

        finally {
          unlockAccoutsChange(codes)


        }




      }).catch(err => {

        searchList()
      })
    }


    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          searchList()
        }
      }
    )
    //#endregion

    return {
      setActiveId, activeInput,
      trHeight, SnWidth, operationWidth, tableHead, searchList, ChangeSort, tablewidth, tableBody,
      reSearch, searchElement, tbodyHeight, SearchContent, selectAllWidth, partBFilterCopy,
      selectAll, selectedItems, confirmAccounts
    }
  },
  components: {
  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;
@lableMarginLeft: 0.625rem;
@lableHeight: 2rem;
@inputHeight: 1.5rem;
@mainColor: #4285f4;

.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.searchinputLabel {
  margin-left: @lableMarginLeft;
  font-size: 0.8rem;
  height: @lableHeight;
  line-height: @lableHeight;
}
.searchinput {
  margin-top: calc((@lableHeight - @inputHeight) / 2);
  margin-bottom: calc((@lableHeight - @inputHeight) / 2);
  font-size: 0.9rem;
  border: none;
  outline: none;
  height: @inputHeight;
  line-height: @inputHeight;
}
.ActiveBox {
  border: 1px solid #ffa004 !important;
}

.searchInputBox {
  height: @lableHeight;
  border: 1px solid #c9c9c9;
  margin-left: @lableMarginLeft;
  border-radius: 0.31rem;
}

.searchItemContainer {
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
  display: flex;
}

.searchSetting {
  margin-left: calc(@lableMarginLeft*3);
  height: @lableHeight;
  display: flex;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}
.searchSetting:hover {
  cursor: pointer;
  color: @mainColor;
}
.settingIcon {
  font-family: "icomoon";
  font-size: 1.1rem;
  line-height: @lableHeight;
}

.settingText {
  margin-left: 0.3rem;
  font-size: 0.8rem;
  line-height: @lableHeight;
}
.dateInputContainer {
  display: flex;
}
.BodyTitle {
  width: 98%;
  margin-top: 0.5rem;
  margin-left: 1%;
}
.BodyTitleText {
  font-size: 1.2rem;
  font-weight: 200;
}
.long {
  width: 20rem;
}

.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 80%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.thSN {
  font-family: "icomoon";
  color: #fff;
  font-size: 16px;
}
.headtd {
  cursor: pointer;
}
.ShowUpArrow {
  position: relative;
}
.ShowUpArrow::after {
  content: "\ea32";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
  color: #fff;
}
.ShowDownArrow {
  position: relative;
}
.ShowDownArrow::after {
  content: "\ea36";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.tdSN,
.tableselect,
.tdoper,
.footsum,
.bodytr {
  text-align: center;
}
.bodytr {
  height: 2rem;
}

.tablefoot {
  margin-top: -1px;
}

.moreFunctionBox {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.selectAllCheckBox {
  cursor: pointer;
}

.todoTr {
  background-color: #fce4d6 !important;
}
.finishTr {
  background-color: #b1d580 !important;
}
</style>