<template>
  <svg :id="codeid"></svg>
</template>

<script>
import JsBarcode from 'jsbarcode'
import { ref } from 'vue-demi'
export default {
  props:['codevalue','idvalue'],
  setup(props) {

    const codeValue = props.codevalue

    const codeid = props.idvalue

    const codeid1 = '#' + codeid

    const newBarCode = () => {
      JsBarcode(codeid1, codeValue, {
        format: 'CODE128',
        lineColor: '#000',
        width: 1.7,
        height: 50,
        displayValue: true
      })
    }

    setTimeout(() => {
      newBarCode()
    }, 0);

    return {
      codeid
    }
  },

}
</script>

<style scoped lang="less">
</style>