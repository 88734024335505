<template>
  <div class="inputLoaderContainer">
    <BodyTitle></BodyTitle>
    <InputHead ref="poInputHeadRef"></InputHead>
    <InputBody ref="inputBodyRef" @countValueAfterDiscount="countValueAfterDiscount" @clearScanIn="clearScanIn"></InputBody>
    <div class="EditTableLine">
      <ComScanIn :scanInValue="scanInValue" @scanIn="scanIn" ref="scanRef"></ComScanIn>
      <div class="InputForChooseContainer far"><label class="InputForChooseLabel" for="discountAmount">供应商优惠金额:</label><input class="InputForChooseshort" id="discountAmount" type="number" v-model.lazy="dicountValue" @blur="checkDiscount"></div>
      <span class="ForthLineText">优惠后金额:<b> {{valueAfterDiscount}}</b></span>
    </div>
    <div class="EditTableLastLine">
      <!-- 维护第七层的内容 -->
      <div class="EditTableFuncButton" :style="{background:'#73a6a3'}" @click="openPopUp()">导入商品明细</div>
      <div class="EditTableFuncButton EditTableFuncButtonAjust" @click="openList('POPI')">采购并入库</div>
    </div>
    <UpPop ref="upPopRef" @uploadData="uploadData"></UpPop>
  </div>
</template>
<script>
import { computed, provide, ref, watch } from 'vue-demi'
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import InputHead from '@/components/Content/MainBodyContent/InputHead.vue'
import InputBody from '@/components/Content/MainBodyContent/InputBody.vue'
import UpPop from '@/components/Common/GlobalPop/UpPop.vue'
import { getDocNo, transferToCode, washDocHead, getLoginData, updateWarning, washBodyCopy, deleteDocCenter, openDocCenter, openPoList, checkDocCondition, clearTableContent, openErList, copyOrder, getDiyBarcodeById } from '@/hooks/OpenOrder'
import { transferLoginDataOnly } from '@/hooks/loginAffair';
import ComScanIn from '@/components/Content/FunctionUnit/ComScanIn.vue'
import InputTable from '@/hooks/InputTable'
import { copyObj, isRealNum } from '@/hooks/tools'
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
export default {
  components: {
    BodyTitle,
    InputHead,
    InputBody,
    UpPop,
    ComScanIn
  },
  setup() {
    const store = useStore();
    const RootTableName = 'PoList';
    let { itemListHead, itemListBody, searchItemList } = InputTable(RootTableName)
    searchItemList.docTitleTwo = 'PO'
    searchItemList.TableName = 'PoInput'
    provide('searchItemList', searchItemList)

    //#region 采购优惠组件
    let inputBodyRef = ref(null)
    const countValueAfterDiscount = (value) => {
      valueReceived.value = value.value.Amount
    }
    let dicountValue = ref(0)

    let valueReceived = ref(0)

    let valueAfterDiscount = computed(() => {
      return valueReceived.value - dicountValue.value + '元'
    })
    //#endregion

    const poInputHeadRef = ref(null)

    setTimeout(() => {
      poInputHeadRef.value.searchItemList.documentContent.InWareHouseId = '主仓库'
    }, 0);

    //#region 扫描组件
    let scanInValue = ref('');

    const scanIn = (res) => {
      inputBodyRef.value.scanInData(res)
    }

    const clearScanIn = () => {
      scanInValue.value = '';
    }


    const checkDiscount = () => {
      if (dicountValue.value > valueReceived.value || dicountValue.value < 0) {
        ElMessage('优惠金额不能大于订单金额或为负数')
        dicountValue.value = 0
      }
      else {
        valueAfterDiscount = valueReceived.value - dicountValue.value + '元'
      }
    }
    //#endregion

    const scanRef = ref(null)

    const getDataFromOnTheWayForSale = (value) => {
      setTimeout(() => {
        clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
        for (let item of value) {

          if (item.commodityId.substring(0, 3) != "999") {
            const count1 = item.qty
            for (let i = 0; i < count1; i++) {
              const scanObj = {
                target: {
                  value: item.commodityId + '11'
                }
              }
              if (scanObj.target.value.trim() != '11') {
                scanRef.value.scanIn(scanObj)
              }
            }
          }
          else {

            getDiyBarcodeById(item.commodityId).then(res => {
              const barcodeDiy = res
              if (barcodeDiy != '') {
                const count1 = item.qty
                for (let i = 0; i < count1; i++) {
                  const scanObj = {
                    target: {
                      value: barcodeDiy
                    }
                  }
                  scanRef.value.scanIn(scanObj)
                }
              }
            })
          }
        }
      }, 0)
    }

    //#region 上传模块
    let upPopRef = ref(null)
    const openPopUp = () => {
      upPopRef.value.openPopUp()
    }

    const uploadData = (dataUpload) => {
      inputBodyRef.value.fillUploadData(dataUpload)
    }
    //#endregion

    const copyOrderFromDocCenter = (value) => {
      clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
      copyOrder(value, searchItemList.documentContent, inputBodyRef.value.AddContentBody, searchItemList.addBodyContent).then(res = {

      })

    }

    //#region 开单功能模块

    const openList = (typeName) => {

      let discount = dicountValue.value
      let errCheck = checkDocCondition(searchItemList.documentContent, itemListHead, inputBodyRef.value.AddContentBody, itemListBody)
      if (errCheck.headErr) {

        ElMessage(errCheck.headErr)
        return
      }
      if (errCheck.bodyErr) {

        ElMessage(errCheck.bodyErr)
        return
      }
      for (let item of inputBodyRef.value.AddContentBody) {
        if (item.CommodityId && !item.UnitPrice) {
          let b = confirm(`${item.CommodityName}采购价格为0,继续请确认,重填请取消`)
          if (!b) {
            return;
          }
        }
      }
      getLoginData().then(res => {
        if (res != 0) {
          transferLoginDataOnly(res)
        }
        getDocNo(searchItemList.docTitleTwo).then(res => {
          const poHead = res
          const partBCom = transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId')
          const partACom = store.state.loginInitializeData.companyId
          const InWareHouseName = isRealNum(searchItemList.documentContent.InWareHouseId) ? transferToName(searchItemList.documentContent.InWareHouseId, 'WareHouseId') : searchItemList.documentContent.InWareHouseId
          let CommodityNameArr = []
          for (let item of inputBodyRef.value.AddContentBody) {
            if (item.CommodityName) {
              item.PartACompanyId = partACom
              item.PartBCompanyId = partBCom
              item.InStockQty = item.OrderQty
              item.DocumentId = poHead
              item.RootSourceDocumentId = poHead
              item.Remark += `采购了${item.OrderQty}个${item.CommodityName},金额为${item.OrderQty * item.UnitPrice}元`
            }
            if (!item.VirtualGoods && item.CommodityId) {
              CommodityNameArr.push(item.CommodityId)
            }
          }
          openPoList(inputBodyRef.value.AddContentBody, searchItemList.documentContent, poHead).then(res => {
            if (res != 'pass') {
              ElMessage(res)
              deleteDocCenter(poHead).then(res => {
                return false;
              })
            }
            else {
              if (InWareHouseName == '主仓库') {
                setTimeout(() => {
                  updateWarning(partACom, CommodityNameArr)
                }, 0);
              }
              if (discount > 0) {
                getDocNo('ER').then(res => {
                  const erHead = res
                  let docHeadCopy2 = copyObj(searchItemList.documentContent)
                  docHeadCopy2.DocumentId = erHead
                  docHeadCopy2.DocumentTypeName = '应付减少单'
                  docHeadCopy2.PartACompanyId = partACom
                  docHeadCopy2.RootSourceDocumentId = poHead
                  docHeadCopy2.SourceDocumentId = poHead
                  docHeadCopy2.Remark = `采购优惠了${discount}元`
                  docHeadCopy2.InWareHouseId = ''
                  docHeadCopy2.SystemStatusId = 105
                  let erBody = [{
                    DocumentId: erHead,
                    Sn: 1,
                    PartACompanyId: partACom,
                    PartBCompanyId: partBCom,
                    OrderQty: 1,
                    InStockQty: 1,
                    OutStockQty: 1,
                    UnitPrice: discount,
                    Remark: `采购优惠了${discount}元`,
                    SourceDocumentId: poHead,
                    RootSourceDocumentId: poHead
                  }]
                  openErList(erBody, docHeadCopy2, res).then(res => {

                    ElMessage({
                message:'开单成功',
                duration:2000,
                showClose:true
              })
                    clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                    dicountValue.value = 0
                  })
                })
              }
              else {
                ElMessage({
                message:'开单成功',
                duration:2000,
                showClose:true
              })
                searchItemList.documentContent.Remark = ''
                clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                dicountValue.value = 0
              }
            }
          })
        })
      }).catch(err => {

        ElMessage(err.message)
      })
    }
    //#endregion

    //#endregion

    return {
      searchItemList,
      dicountValue,
      valueAfterDiscount,
      inputBodyRef,
      countValueAfterDiscount,
      scanInValue,
      scanIn,
      clearScanIn,
      openPopUp,
      upPopRef,
      uploadData,
      openList, checkDiscount, getDataFromOnTheWayForSale, scanRef, poInputHeadRef,
      copyOrderFromDocCenter
    }
  }
}
</script>
<style scoped lang="less">
b {
  color: red;
}
</style>