<template>
  <div class="tableLoaderContainer">
    <BodyTitle></BodyTitle>
    <SearchContentForCapitalAccBlaChange @deliverSearchItems="deliverSearchItems" ref="searchRef"></SearchContentForCapitalAccBlaChange>
    <TableShowForDiy @saveActiveItem="saveActiveItem" @clearActive="clearActive" ref="tableShowRef"></TableShowForDiy>
    <div class="moreFunctionBox">

      <!-- <div class="FuncButton" @click="goInvenChange()">库存变动查询</div> -->
    </div>
  </div>
</template>

<script>
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import SearchContentForCapitalAccBlaChange from '@/components/Content/MainBodyContent/SearchContentForCapitalAccBlaChange.vue'
import TableShowForDiy from '@/components/Content/MainBodyContent/TableShowForDiy.vue';
import { useStore } from "vuex";
import DiyShowTable from '@/hooks/DiyShowTable'
import { changeToViewCode, transferToCode } from '@/hooks/OpenOrder'
import { onUnmounted, watch } from 'vue-demi';
export default {
  setup() {
    //#region 定义区
    const store = useStore();
    //初始化输入表名称
    const TableName = 'CapitalAccountBlanceChangeList';

    const PageName = 'CapitalAccountBlanceChangeList'

    let { tableShowRef, searchRef, deliverSearchItems, activeItem, saveActiveItem, clearActive } = DiyShowTable(TableName, PageName)
    //根据表名给searchItemList赋值

    //#endregion

    const getDataFromCap = (value) => {
      searchRef.value.searchItems['CapitalAccountId'] = transferToCode(value.value.capitalAccountId, 'CapitalAccountId')
      setTimeout(() => {
        searchRef.value.searchList()
      }, 100);
    }

    const code = changeToViewCode('CapitalAccountBlanceChangeList')
    

    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          store.commit('modifysearchEle', {
            keyName: 'LinesPerPage',
            valueName: 100
          })
          store.commit('modifysearchEle', {
            keyName: 'Page',
            valueName: 1
          })
          store.commit('modifysearchEle', {
            keyName: 'SortFieldsName',
            valueName: ''
          })
          store.commit('modifysearchEle', {
            keyName: 'SortDirection',
            valueName: -1
          })
        }
      }
    )


    return {
      deliverSearchItems,
      tableShowRef,
      saveActiveItem,
      activeItem, clearActive, searchRef, getDataFromCap
    }
  },
  components: {
    BodyTitle,
    SearchContentForCapitalAccBlaChange,
    TableShowForDiy
  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}
</style>