<template>
  <div class="InputHeadContainer">
    <div class="InputForChooseContainer" v-if="searchItemList.documentCenterItems.indexOf('PartBCompanyId') > -1"><span class="importantIndi" v-show="itemList.filter(x=>x.fieldsNameForManage == 'PartBCompanyId')[0].mustMaintain">*</span><label class="InputForChooseLabel" :for="'PartBCompanyId' + searchItemList.tableName" v-text="itemList.filter(x=>x.fieldsNameForManage == 'PartBCompanyId')[0].fieldsShowName + ':'"></label><input class="InputForChooseLong" :id="'PartBCompanyId' + searchItemList.tableName" type="text" v-model="searchItemList.documentContent.PartBCompanyId" @blur="checkBpName($event),unbindBpEnter()" @mousedown="bindBpEnter" :disabled="searchItemList.tableName == 'ObList'">
      <div class="InputForChooseFunc" @click="openBpPop"></div>
      <BpPop v-show="alivePop == 'BP'" :tableTile="searchItemList.docTitleTwo" ref="bpPopRef"></BpPop>
    </div>
    <div class="InputForChooseContainer" v-if="searchItemList.documentCenterItems.indexOf('OutWareHouseId') > -1"><span class="importantIndi" v-show="itemList.filter(x=>x.fieldsNameForManage == 'OutWareHouseId')[0].mustMaintain">*</span><label class="InputForChooseLabel" :for="'OutWareHouseId' + searchItemList.tableName" v-text="itemList.filter(x=>x.fieldsNameForManage == 'OutWareHouseId')[0].fieldsShowName + ':'"></label><input class="InputForChoose" :id="'OutWareHouseId' + searchItemList.tableName" type="text" v-model="searchItemList.documentContent.OutWareHouseId" @blur="checkOwName($event),unbindOwEnter()" @mousedown="bindOwEnter">
      <div class="InputForChooseFunc" @click="openOwPop"></div>
      <OwPop v-show="alivePop == 'OW'" :tableTile="searchItemList.docTitleTwo" ref="owPopRef"></OwPop>
    </div>
    <div class="InputForChooseContainer" v-if="searchItemList.documentCenterItems.indexOf('InWareHouseId') > -1"><span class="importantIndi" v-show="itemList.filter(x=>x.fieldsNameForManage == 'InWareHouseId')[0].mustMaintain">*</span><label class="InputForChooseLabel" :for="'InWareHouseId' + searchItemList.tableName" v-text="itemList.filter(x=>x.fieldsNameForManage == 'InWareHouseId')[0].fieldsShowName + ':'"></label><input class="InputForChoose" :id="'InWareHouseId' + searchItemList.tableName" type="text" v-model="searchItemList.documentContent.InWareHouseId" @blur="checkIwName($event),unbindIwEnter()" @mousedown="bindIwEnter">
      <div class="InputForChooseFunc" @click="openIwPop"></div>
      <IwPop v-show="alivePop == 'IW'" :tableTile="searchItemList.docTitleTwo" ref="iwPopRef"></IwPop>
    </div>
    <div class="InputForChooseContainer" v-if="searchItemList.documentCenterItems.indexOf('CapitalAccountId') > -1"><span class="importantIndi" v-show="itemList.filter(x=>x.fieldsNameForManage == 'CapitalAccountId')[0].mustMaintain">*</span><label class="InputForChooseLabel" :for="'CapitalAccountId' + searchItemList.tableName" v-text="itemList.filter(x=>x.fieldsNameForManage == 'CapitalAccountId')[0].fieldsShowName + ':'"></label><input class="InputForChoose" :id="'CapitalAccountId' + searchItemList.tableName" type="text" v-model="searchItemList.documentContent.CapitalAccountId" @blur="checkCaName($event),unbindCaEnter()" @mousedown="bindCaEnter">
      <div class="InputForChooseFunc" @click="openCaPop"></div>
      <CaPop v-show="alivePop == 'CA'" :tableTile="searchItemList.docTitleTwo" ref="caPopRef"></CaPop>
    </div>
    <div class="InputForChooseContainer" v-if="searchItemList.documentCenterItems.indexOf('StaffId') > -1"><span class="importantIndi" v-show="itemList.filter(x=>x.fieldsNameForManage == 'StaffId')[0].mustMaintain">*</span><label class="InputForChooseLabel" :for="'StaffId' + searchItemList.tableName" v-text="itemList.filter(x=>x.fieldsNameForManage == 'StaffId')[0].fieldsShowName + ':'"></label><input class="InputForChoose" :id="'StaffId' + searchItemList.tableName" type="text" v-model="searchItemList.documentContent.StaffId" @blur="checkStName($event),unbindStEnter()" @mousedown="bindStEnter">
      <div class="InputForChooseFunc" @click="openStPop"></div>
      <StPop v-show="alivePop == 'ST'" :tableTile="searchItemList.docTitleTwo" ref="stPopRef"></StPop>
    </div>
    <div class="InputForChooseContainer" v-if="searchItemList.docTitleTwo == 'OB'"><label class="InputForChooseLabel" :for="'CustomerNameForOb' + searchItemList.tableName">客户姓名:</label><input class="InputForSpecial" :id="'CustomerNameForOb' + searchItemList.tableName" type="text" v-model="searchItemList.CustomerList.customerName"></div>

    <div class="InputForChooseContainer" v-if="searchItemList.docTitleTwo == 'OB'"><label class="InputForChooseLabel" :for="'CustomerContactForOb' + searchItemList.tableName">联系方式:</label><input class="InputForSpecial" :id="'CustomerContactForOb' + searchItemList.tableName" type="text" v-model="searchItemList.CustomerList.customerContact"></div>

    <div class="InputForChooseContainer" v-if="searchItemList.docTitleTwo == 'OB'"><label class="InputForChooseLabel" :for="'QuotationForOb' + searchItemList.tableName">报价:</label><input class="InputForSpecial" :id="'QuotationForOb' + searchItemList.tableName" type="number" v-model="searchItemList.CustomerList.customerQuotation"></div>

    <div class="InputForChooseContainer" v-if="searchItemList.docTitleTwo == 'OB'"><label class="InputForChooseLabel" :for="'DepositForOb' + searchItemList.tableName">订金:</label><input class="InputForSpecial" :id="'DepositForOb' + searchItemList.tableName" type="number" v-model="searchItemList.CustomerList.customerDeposit" @blur="checkDeposit()"></div>

    <div class="InputForChooseContainer" v-if="searchItemList.documentCenterItems.indexOf('Remark') > -1"><span class="importantIndi" v-show="itemList.filter(x=>x.fieldsNameForManage == 'Remark')[0].mustMaintain">*</span><label class="InputForChooseLabel" :for="'Remark' + searchItemList.tableName" v-text="itemList.filter(x=>x.fieldsNameForManage == 'Remark')[0].fieldsShowName + ':'"></label><input class="InputForChooseLong" :id="'Remark' + searchItemList.tableName" type="text" v-model="searchItemList.documentContent.Remark"></div>
  </div>
</template>
<script>
import { inject, ref } from 'vue-demi'
import BpPop from '@/components/Common/GlobalPop/BpPop.vue'
import IwPop from '@/components/Common/GlobalPop/IwPop.vue'
import StPop from '@/components/Common/GlobalPop/StPop.vue'
import OwPop from '@/components/Common/GlobalPop/OwPop.vue'
import CaPop from '@/components/Common/GlobalPop/CaPop.vue'
import requestData from "@/hooks/requestData.js";
export default {
  components: {
    BpPop,
    IwPop,
    StPop,
    OwPop,
    CaPop
  },
  setup() {
    //#region 数据定义区

    let searchItemList = inject('searchItemList')

    const checkDeposit = () => {
      if (searchItemList.CustomerList.customerDeposit < 0) {
        searchItemList.CustomerList.customerDeposit = 0
      }
    }
    const itemList = searchItemList.itemList
    //#endregion

    //#region 供应商模块
    const bpPopRef = ref(null)
    const openBpPop = () => {
      alivePop.value = 'BP'

      setTimeout(() => {
        bpPopRef.value.openPopComm()
      }, 0)
    }

    //绑定Enter事件
    const enterBp = (e) => {
      if (e.keyCode == 13) {
        checkBpName(e)
      }
    }
    const bindBpEnter = () => {
      window.addEventListener('keydown', enterBp)
    }

    const unbindBpEnter = () => {
      window.removeEventListener('keydown', enterBp)
    }

    //f确认往来单位输入的方法
    const checkBpName = (e) => {
      if (e.target.value == '') { return false; }
      let config = {
        method: "get",
        url: "/BusinessPartnerList/GetSingleAuth",
        params: {
          name: e.target.value
        }
      }
      requestData.GetCache(config).then(res => {
        if (res == 'notfound') {
          let sendMsg = searchItemList.documentContent.PartBCompanyId
          searchItemList.documentContent.PartBCompanyId = ''
          openBpPop()
          bpPopRef.value.sendMsgSolve(sendMsg)
          bpPopRef.value.searchList()
          return 'notfound'
        }
        else {
          return 'found'
        }
      })
    }
    //#endregion

    //#region 员工模块
    const stPopRef = ref(null)

    const openStPop = () => {
      alivePop.value = 'ST'

      setTimeout(() => {
        stPopRef.value.openPopComm()
      }, 0)
    }

    const enterSt = (e) => {
      if (e.keyCode == 13) {
        checkStName(e)
      }
    }
    const bindStEnter = () => {
      window.addEventListener('keydown', enterSt)
    }

    const unbindStEnter = () => {
      window.removeEventListener('keydown', enterSt)
    }


    //f确认往来单位输入的方法
    const checkStName = (e) => {
      if (e.target.value == '') { return false; }
      let config = {
        method: "get",
        url: "/StaffList/GetSingleAuth",
        params: {
          name: e.target.value
        }
      }
      requestData.GetCache(config).then(res => {
        if (res == 'notfound') {
          let sendMsg = searchItemList.documentContent.StaffId
          searchItemList.documentContent.StaffId = ''
          openStPop()
          stPopRef.value.sendMsgSolve(sendMsg)
          stPopRef.value.searchList()
          return 'notfound'
        }
        else {
          return 'found'
        }
      })
    }

    let alivePop = ref('')

    //#endregion

    //#region 出库仓库
    const owPopRef = ref(null)

    const openOwPop = () => {
      alivePop.value = 'OW'

      setTimeout(() => {
        owPopRef.value.openPopComm()
      }, 0)
    }

    const enterOw = (e) => {
      if (e.keyCode == 13) {
        checkOwName(e)
      }
    }
    const bindOwEnter = () => {
      window.addEventListener('keydown', enterOw)
    }

    const unbindOwEnter = () => {
      window.removeEventListener('keydown', enterOw)
    }


    //f确认往来单位输入的方法
    const checkOwName = (e) => {
      if (e.target.value == '') { return false; }
      let config = {
        method: "get",
        url: "/WareHouseList/GetSingleOutAuth",
        params: {
          name: e.target.value
        }
      }
      requestData.GetCache(config).then(res => {
        if (res == 'notfound') {
          let sendMsg = searchItemList.documentContent.OutWareHouseId
          searchItemList.documentContent.OutWareHouseId = ''
          openOwPop()
          owPopRef.value.sendMsgSolve(sendMsg)
          owPopRef.value.searchList()
          return 'notfound'
        }
        else {
          return 'found'
        }
      })
    }
    //#endregion

    //#region 入库仓库
    const iwPopRef = ref(null)

    const openIwPop = () => {
      alivePop.value = 'IW'
      setTimeout(() => {
        iwPopRef.value.openPopComm()
      }, 0)
    }

    const enterIw = (e) => {
      if (e.keyCode == 13) {
        checkIwName(e)
      }
    }
    const bindIwEnter = () => {
      window.addEventListener('keydown', enterIw)
    }

    const unbindIwEnter = () => {
      window.removeEventListener('keydown', enterIw)
    }


    //f确认往来单位输入的方法
    const checkIwName = (e) => {
      if (e.target.value == '') { return false; }
      let config = {
        method: "get",
        url: "/WareHouseList/GetSingleInAuth",
        params: {
          name: e.target.value
        }
      }
      requestData.GetCache(config).then(res => {
        if (res == 'notfound') {
          let sendMsg = searchItemList.documentContent.InWareHouseId
          searchItemList.documentContent.InWareHouseId = ''
          openIwPop()
          iwPopRef.value.sendMsgSolve(sendMsg)
          iwPopRef.value.searchList()
          return 'notfound'
        }
        else {
          return 'found'
        }
      })
    }
    //#endregion

    //#region 入库仓库
    const caPopRef = ref(null)

    const openCaPop = () => {
      alivePop.value = 'CA'

      setTimeout(() => {
        caPopRef.value.openPopComm()
      }, 0)
    }

    const enterCa = (e) => {
      if (e.keyCode == 13) {
        checkCaName(e)
      }
    }
    const bindCaEnter = () => {
      window.addEventListener('keydown', enterCa)
    }

    const unbindCaEnter = () => {
      window.removeEventListener('keydown', enterCa)
    }


    //f确认往来单位输入的方法
    const checkCaName = (e) => {
      if (e.target.value == '') { return false; }
      let config = {
        method: "get",
        url: "/CapitalAccountList/GetSingleInAuth",
        params: {
          name: e.target.value
        }
      }
      requestData.GetCache(config).then(res => {
        if (res == 'notfound') {
          let sendMsg = searchItemList.documentContent.CapitalAccountId
          searchItemList.documentContent.CapitalAccountId = ''
          openIwPop()
          iwPopRef.value.sendMsgSolve(sendMsg)
          iwPopRef.value.searchList()
          return 'notfound'
        }
        else {
          return 'found'
        }
      })
    }
    //#endregion

    return {
      searchItemList,
      itemList,

      bpPopRef,
      openBpPop,
      bindBpEnter,
      unbindBpEnter,
      checkBpName,

      iwPopRef,
      openIwPop,
      bindIwEnter,
      unbindIwEnter,
      checkIwName,

      stPopRef,
      openStPop,
      bindStEnter,
      unbindStEnter,
      checkStName,

      owPopRef,
      openOwPop,
      bindOwEnter,
      unbindOwEnter,
      checkOwName,

      caPopRef,
      openCaPop,
      bindCaEnter,
      unbindCaEnter,
      checkCaName,
      checkDeposit,

      alivePop
    }
  }
}
</script>

<style scoped lang="less">
.InputHeadContainer {
  display: flex;
  margin-top: 0.5rem;
  flex-wrap: wrap;
}
.InputForChooseContainer {
  display: flex;
  margin-right: 0.625rem;
}
.InputForChooseContainer label {
  padding-right: 0.3125rem;
  line-height: 1.75rem;
}
.importantIndi {
  color: #e83a65;
  font-size: 1rem;
}
.InputForChoose {
  height: 1.75rem;
  width: 9rem;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  border-left: 1px solid #c9c9c9;
  border-right: none;
}
.InputForSpecial {
  height: 1.75rem;
  width: 9rem;
  border: 1px solid #c9c9c9;
}
.InputForChooseFunc {
  font-family: "icomoon";
  text-align: center;
  line-height: 1.75rem;
  font-size: 1.125rem;
  width: 1.75rem;
  height: 1.75rem;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  border-right: 1px solid #c9c9c9;
}

.InputForChooseFunc:hover {
  cursor: pointer;
}
.InputForChooseLong {
  height: 1.75rem;
  width: 15.625rem;
  border: 1px solid #c9c9c9;
}
.InputForChoose:focus,
.InputForChooseLong:focus,
.InputForSpecial:focus {
  border: 1px solid #ffa004;
}
</style>