<template>
  <div class="tableLoaderContainer">
    <BodyTitle></BodyTitle>
    <SearchContentForDocCenter @deliverSearchItems="deliverSearchItems" ref="searchRef"></SearchContentForDocCenter>
    <TableShowForDiy @saveActiveItem="saveActiveItem" @clearActive="clearActive" ref="tableShowRef" @goOpenOrderFromDocCenter="goOpenOrderFromDocCenter" @goEditOrderFromDocCenter="goEditOrderFromDocCenter"></TableShowForDiy>
    <div class="moreFunctionBox">
    </div>
  </div>
</template>

<script>
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import SearchContentForDocCenter from '@/components/Content/MainBodyContent/SearchContentForDocCenter.vue'
import TableShowForDiy from '@/components/Content/MainBodyContent/TableShowForDiy.vue';
import { useStore } from "vuex";
import DiyShowTable from '@/hooks/DiyShowTable'
import { toDecimal2 } from '@/hooks/tools'
import { onUnmounted, watch } from 'vue-demi';
import { changeToViewCode } from '@/hooks/OpenOrder';
export default {
  setup(props, { emit }) {
    //#region 定义区
    const store = useStore();
    //初始化输入表名称
    const TableName = 'DocumentCenter';

    const PageName = 'DocumentCenter'

    let { tableShowRef, searchRef, deliverSearchItems, activeItem, saveActiveItem, clearActive } = DiyShowTable(TableName, PageName)
    //根据表名给searchItemList赋值

    const goOpenOrderFromDocCenter = (value) => {
      emit('goOpenOrderFromDoc', value)
    }

    const goEditOrderFromDocCenter = (value) => {
      emit('goEditOrderFromDoc', value)
    }


    const code = changeToViewCode('DocumentCenter')

    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          store.commit('modifysearchEle', {
            keyName: 'LinesPerPage',
            valueName: 100
          })
          store.commit('modifysearchEle', {
            keyName: 'Page',
            valueName: 1
          })
          store.commit('modifysearchEle', {
            keyName: 'SortFieldsName',
            valueName: ''
          })
          store.commit('modifysearchEle', {
            keyName: 'SortDirection',
            valueName: -1
          })
        }
      }
    )



    //#endregion


    return {
      deliverSearchItems,
      tableShowRef,
      saveActiveItem,
      activeItem, clearActive, searchRef,
      goOpenOrderFromDocCenter,
      goEditOrderFromDocCenter
    }
  },
  components: {
    BodyTitle,
    SearchContentForDocCenter,
    TableShowForDiy
  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}
</style>