<template>
  <div class="tableLoaderContainer">

    <div class="BodyTitle">
      <div class="BodyTitleText">采购在途管理</div>
    </div>

    <div class="searchContentContainer">
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="StartTypeForPurchaseOnTheWay">发起类型:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'StartType'}"><select v-model="SearchContent.StartType" class="searchinput" id="StartTypeForPurchaseOnTheWay" @focus="setActiveId('StartType')" @blur="setActiveId('')" @change="reSearch()">
            <option value="">所有</option>
            <option v-for="item in OnTheWayTypeList" :key="item.beforeTransfer + 'OnTheWay'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
          </select></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="CustomerToBeSolveFSForPurchaseOnTheWay">处理情况:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'CustomerToBeSolveFS'}"><select v-model="SearchContent.CustomerToBeSolveFS" class="searchinput" id="CustomerToBeSolveFSForPurchaseOnTheWay" @focus="setActiveId('CustomerToBeSolveFS')" @blur="setActiveId('')" @change="reSearch()">
            <option v-for="item in CustomerToBeSolveFSFilter" :key="item.beforeTransfer + 'PurchaseOnTheWay'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
          </select></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DistributedOrNotFSForPurchaseOnTheWay">是否分配供应商:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DistributedOrNotFS'}"><select v-model="SearchContent.DistributedOrNotFS" class="searchinput" id="DistributedOrNotFSForPurchaseOnTheWay" @focus="setActiveId('DistributedOrNotFS')" @blur="setActiveId('')" @change="reSearch()">
            <option v-for="item in DistributedOrNotFSFilter" :key="item.beforeTransfer + 'PurchaseOnTheWay'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
          </select></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="PartACompanyIdForPurchaseOnTheWay">供方:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'PartACompanyId'}"><select v-model="SearchContent.PartACompanyId" class="searchinput" id="PartACompanyIdForPurchaseOnTheWay" @focus="setActiveId('PartACompanyId')" @blur="setActiveId('')" @change="reSearch()">
            <option value="" selected="selected">所有</option>
            <option v-for="item in partBFilterCopy" :key="item.beforeTransfer + 'PurchaseOnTheWay1'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
          </select></div>
      </div>

      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DocumentDateLPurchaseOnTheWay">发起日期</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateL'}"><input v-model="SearchContent.DocumentDateL" class="searchinput" id="DocumentDateLPurchaseOnTheWay" type="date" @focus="setActiveId('DocumentDateL')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DocumentDateHPurchaseOnTheWay" v-text="'---'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateH'}"><input v-model="SearchContent.DocumentDateH" class="searchinput" id="DocumentDateHPurchaseOnTheWay" type="date" @focus="setActiveId('DocumentDateH')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="CommodityNamePurchaseOnTheWay">商品名称:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'CommodityName'}"><input v-model="SearchContent.CommodityName" class="searchinput long" id="CommodityNamePurchaseOnTheWay" type="text" @focus="setActiveId('CommodityName')" @blur="setActiveId('')"></div>
      </div>

      <div class="searchItemContainer">
        <label class="searchinputLabel" for="CommodityIdPurchaseOnTheWay">商品编码:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'CommodityId'}"><input v-model="SearchContent.CommodityId" class="searchinput" id="CommodityIdPurchaseOnTheWay" type="text" @focus="setActiveId('CommodityId')" @blur="setActiveId('')"></div>
      </div>

      <!-- 搜索模块 -->
      <div class="FuncButton" @click="searchList()">搜索</div>
      <div class="tipBox">
        <div class="tipColortodo"></div>
        <div class="tipWords">待处理</div>
      </div>
      <div class="tipBox">
        <div class="tipColorfinish"></div>
        <div class="tipWords">无需处理</div>
      </div>
    </div>

    <div class="BigTable">
      <div class="TableContentArea">
        <table class="tablehead">
          <tr class="headtr" :style="{ height : trHeight }">
            <th class="thSN">
              <div :style="{ width: SnWidth }"></div>
            </th>
            <th class="thselectall">
              <div :style="{ width: selectAllWidth }"><input class="selectAllCheckBox" type="checkbox" v-model="selectAll" /></div>
            </th>
            <th>
              <div class="thoper" :style="{ width: operationWidth }">操作</div>
            </th>
            <th>
              <div class="thoper" :style="{ width: buyQtyWidth}">数量</div>
            </th>

            <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'head'" :class="{  ShowUpArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == 1, ShowDownArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == -1,}" @click.stop="ChangeSort(value1.fieldsNameForVali)">
              <div :style="{ width: value1.showLen  + 'rem' }">{{ value1.showName }}</div>
            </th>
          </tr>
        </table>
        <div class="tablebodyarea" :style="{ width: tablewidth  + 'rem',height:tbodyHeight}">
          <table class="tablebody">
            <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'td' + index2" :class="[{todoLine:value2.customerToBeSolve},{nottodoLine:!value2.customerToBeSolve}]">
              <td>
                <div class="tdSN" :style="{ width: SnWidth }">{{+index2 + 1}}</div>
              </td>
              <td class="tableselect">
                <div :style="{ width: selectAllWidth }"><input type="checkbox" :value="value2['onTheWayCode']" v-model="selectedItems" /></div>
              </td>
              <td>
                <div class="tdoper" :style="{ width: operationWidth }">
                  <span class="spandele" v-show="value2.responsedQty" @click="confirmOne(index2)">确认</span>
                  <span class="spanedit" v-show="!value2.responsedQty" @click="deleteOne(index2)">取消</span>
                </div>
              </td>
              <td>
                <div><input class="tableInput" type="number" :style="{ width: buyQtyWidth}" v-model="tableBody[index2]['buyQty']" :disabled="tableBody[index2]['commodityNameSale'] == '' || tableBody[index2]['customerToBeSolve']" @blur="checkQty(index2,$event,'buyQty')"></div>
              </td>
              <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
                <div :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
              </td>
            </tr>
          </table>
        </div>
        <table class="tablefoot">
          <tr class="foottr" :style="{ height : trHeight }">
            <td>
              <div class="footsum" :style="{ width: SnWidth }">合计</div>
            </td>
            <td>
              <div :style="{ width: selectAllWidth }"></div>
            </td>
            <td>
              <div :style="{ width: operationWidth }"></div>
            </td>
            <td>
              <div :style="{ width: buyQtyWidth }"></div>
            </td>
            <td v-for="value4 in tableHead" :key="value4.fieldsNameForVali + 'foot'">
              <div :style="{ width: value4.showLen  + 'rem' }"></div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="moreFunctionBox">
      <div class="selectBoxModel"><label for="inwarehouseForOntheWayPurchase">默认入库仓库:</label><select id="inwarehouseForOntheWayPurchase" v-model="defaultInWarehouse">
          <option v-for="item in warehouseFilter" :key="item.beforeTransfer + 'OnthewayPurchase'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
        </select></div>
      <el-button class="firstButton" type="success" @click="selectAllTt()">勾选可调入</el-button>
      <el-button type="success" @click="selectAllSo()">勾选可采购</el-button>
      <el-button type="primary" @click="confirmMany()">批量确认</el-button>
      <el-button type="success" @click="selectAllDe()">勾选可取消</el-button>
      <el-button type="danger" @click="deleteMany()">批量取消</el-button>
      <el-button type="primary" @click="goHistory()">查看历史</el-button>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue-demi';
import { useStore } from "vuex";
import {
  ChangeFirToLo, ChangeFirToUp, ChangeYMD, changeArrContentToDate, copyArray, GetNumberOfDays
} from '@/hooks/tools';
import requestData from '@/hooks/requestData';
import {
  fillDefaultValue, changeToViewCode, getDocNo, ttInstock, deleteAcceptOnTheWay, updateWarning, transferToName,
  checkTtInWarehouse, checkSoInWarehouse, updateInwarehouseId, deleteOnTheWay, transferToCode, openPoList, deleteDocCenter
} from '@/hooks/OpenOrder'
import { lockOneTheWay, unLockOneTheWay } from '@/hooks/LockAffair';
import { ElMessage } from 'element-plus'
export default {
  setup(props, { emit }) {
    //#region 定义区
    const store = useStore();

    const filterList = store.state.loginInitializeData.filterList

    const OnTheWayTypeList = filterList.StartType

    const partBFilter = filterList.PartBCompanyId

    const partBFilterCopy = copyArray(partBFilter)

    const DistributedOrNotFSFilter = filterList.DistributedOrNotFS

    const CustomerToBeSolveFSFilter = filterList.CustomerToBeSolveFS

    let warehouseFilter = copyArray(filterList.WareHouseIdIn)

    let defaultInWarehouse = ref('')

    const setDefaultOutWarehouse = () => {
      const warehouseList = store.state.loginInitializeData.authList.wareHouseLists
      if (warehouseList?.length > 0 && warehouseFilter?.length > 0) {
        defaultInWarehouse.value = warehouseFilter[0].beforeTransfer
        for (let item of warehouseFilter) {
          if (warehouseList.find(x => x.wareHouseId == item.beforeTransfer).defaultWarehouse) {
            defaultInWarehouse.value = item.beforeTransfer
          }
        }
      }
    }
    setDefaultOutWarehouse()

    //初始化输入表名称
    const TableName = 'OnTheWayList';

    const PageName = 'OnTheWayListForPurchase'

    const code = changeToViewCode(PageName)

    //#endregion

    //#region 全选
    let selectAll = ref(false);

    let selectedItems = ref([]);

    watch(selectAll, (newValue, oldValue) => {
      clearClick()
      if (code == store.state.loginInitializeData.ShowWindow[0]) {
        if (newValue == true) {
          selectedItems.value.splice(0);

          for (let item of tableBody) {
            selectedItems.value.push(item['onTheWayCode']);
          }
        } else {
          selectedItems.value.splice(0);
        }
      }
    });

    let selectAllTtClick = ref(false)

    let selectAllSoClick = ref(false)

    let selectAllDeClick = ref(false)

    const clearClick = () => {
      selectAllTtClick.value = false
      selectAllSoClick.value = false
      selectAllDeClick.value = false
    }



    const selectAllTt = () => {

      selectedItems.value.splice(0);
      if (selectAllTtClick.value == true) {

        clearClick()
        return;
      }
      else {
        clearClick()
        selectAllTtClick.value = true
      }
      for (let item of tableBody) {
        if (item.customerToBeSolve && item.responsedQty > 0 && item.documentIdSale.substring(0, 2) == 'TT') {
          selectedItems.value.push(item['onTheWayCode']);
        }
      }

    }

    const selectAllSo = () => {

      selectedItems.value.splice(0);
      if (selectAllSoClick.value == true) {

        clearClick()
        return;
      }
      else {
        clearClick()
        selectAllSoClick.value = true
      }
      for (let item of tableBody) {
        if (item.customerToBeSolve && item.responsedQty > 0 && item.documentIdSale.substring(0, 2) == 'SO') {
          selectedItems.value.push(item['onTheWayCode']);
        }
      }

    }

    const selectAllDe = () => {

      selectedItems.value.splice(0);
      if (selectAllDeClick.value == true) {

        clearClick()
        return;
      }
      else {
        clearClick()
        selectAllDeClick.value = true
      }
      for (let item of tableBody) {
        if (!item.responsedQty) {
          selectedItems.value.push(item['onTheWayCode']);
        }
      }

    }
    //#endregion

    let activeInput = ref('');

    const setActiveId = (id) => {
      activeInput.value = id
    }
    let selectAllWidth = ref('2.5rem')
    let trHeight = ref('1.875rem')
    let SnWidth = ref('3.75rem')
    let operationWidth = ref('12rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')
    let buyQtyWidth = ref('4rem')

    let SearchContent = reactive({
      OnTheWayCode: '',
      DocumentId: '',
      Sn: '',
      PartACompanyId: '',
      PartBCompanyId: '',
      StaffId: '',
      DocumentDate: '',
      DocumentType: '',
      CommodityName: '',
      CommodityId: '',
      Remark: '',
      OrderQty: '',
      UnitPrice: '',
      NotifyStatus: '',
      RefusedQty: '',
      ResponsedQty: '',
      CommodityNameSale: '',
      CommodityIdSale: '',
      ResponseStatus: '',
      DistributedOrNot: '',
      SupplierToBeSolve: '',
      CustomerToBeSolve: '',
      DocumentTypeSale: '',
      StartType: '',
      DistributedOrNotFS: '',
      SupplierToBeSolveFS: '',
      CustomerToBeSolveFS: '',
      DocumentIdSale: '',
      SnSale: '',
      DocumentDateSaleH: '',
      DocumentDateSaleL: '',
      VirtualGoodsFS: ''
    })
    fillDefaultValue(SearchContent, TableName)

    const initializeSearchContent = () => {
      SearchContent.CustomerToBeSolveFS = 1
    }

    initializeSearchContent()

    //f计算表格内容总宽度
    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) + parseFloat(buyQtyWidth.value) + parseFloat(operationWidth.value) * 2 + parseFloat(selectAllWidth.value) + 1.25 + tableHead.length * 1 + 0.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });

    let tableHead = reactive([
      { fieldsNameForVali: 'startType', showLen: 7, showName: '发起类型' },
      { fieldsNameForVali: 'commodityName', showLen: 32, showName: '采购商品名称' },
      { fieldsNameForVali: 'commodityNameSale', showLen: 32, showName: '销售商品名称' },
      { fieldsNameForVali: 'responsedQty', showLen: 4, showName: '数量' },
      { fieldsNameForVali: 'partACompanyId', showLen: 12, showName: '供应商' },
      { fieldsNameForVali: 'documentTypeSale', showLen: 7.5, showName: '处理方式' },
      { fieldsNameForVali: 'unitPrice', showLen: 6.25, showName: '销售价格' },
      { fieldsNameForVali: 'refusedQty', showLen: 8, showName: '供方拒绝数量' },
      { fieldsNameForVali: 'documentId', showLen: 16, showName: '单据编码' },
      { fieldsNameForVali: 'sn', showLen: 3, showName: '序' },
      { fieldsNameForVali: 'documentDate', showLen: 8, showName: '发起日期' },
      { fieldsNameForVali: 'documentDateSale', showLen: 8, showName: '销售日期' },
      { fieldsNameForVali: 'orderQty', showLen: 4.5, showName: '数量' },
      { fieldsNameForVali: 'remark', showLen: 50, showName: '备注' },
    ]);

    const searchElement = reactive({
      SortFieldsName: 'OnTheWayCode',
      SortDirection: -1
    })

    let tableBody = reactive([]);

    let ChangeSort = (fildsname) => {
      searchElement.SortFieldsName = ChangeFirToUp(fildsname)
      searchElement.SortDirection = -searchElement.SortDirection
      searchList()
    };

    const reSearch = () => {
      searchList()
    }

    const comName = store.state.loginInitializeData.companyNameAbbre

    let autoTimes = 0;

    let searchList = () => {
      let config = {
        method: "post",
        url: '/OnTheWayList/GetListByMulSearchPurchase',
        data: Object.assign(SearchContent, searchElement),
      };
      requestData
        .GetData(config)
        .then((res) => {
          selectAll.value = false
          selectedItems.value.splice(0)
          tableBody.splice(0);
          tableBody.push.apply(tableBody, res['onTheWayListMultiple']);
          changeArrContentToDate(tableBody, PageName)
          const filterList = store.state.loginInitializeData.filterList
          const companyFilter = filterList.PartBCompanyId
          const startTypFilter = filterList.StartType
          for (let item of tableBody) {
            for (let key in item) {
              if (key == 'partACompanyId') {
                item[key] = companyFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'startType') {
                item[key] = startTypFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'unitPrice') {
                if (item.documentTypeSale == '调货单') {
                  item.unitPrice = ''
                }
              }
              if (item[key] == 0) {
                item[key] = ''
              }
            }
            if (item.refusedQty != '' && item.documentTypeSale == '') {
              item.documentTypeSale = '已拒绝'
            }
            if (item.documentTypeSale == '') {
              item.documentTypeSale = '未处理'
            }
            item.buyQty = item.responsedQty
          }
          if (autoTimes == 0) {
            if (comName.indexOf('小懒-') > -1) {
              const date1 = new Date();
              const length222 = tableBody.length
              for (let index = 0; index < length222; index++) {
                const days1 = GetNumberOfDays(tableBody[index].documentDateSale, date1)
                if (days1 > 5 && tableBody[index].customerToBeSolve && tableBody[index].responsedQty > 0 && tableBody[index].partACompanyId == '青屠科技' && !(tableBody[index].documentTypeSale == '销售单' && !tableBody[index].virtualGoods)) {
                  selectedItems.value.push(tableBody[index].onTheWayCode)
                }
              }
              if (selectedItems.value.length > 0) {
                let comNames = ''
                for (let item5 of selectedItems.value) {
                  const body1 = tableBody.find(x => x.onTheWayCode == item5)
                  comNames += `${body1.commodityNameSale} * ${body1.responsedQty} 金额为${body1.unitPrice}元 \n`
                }
                ElMessage(`系统将自动处理5天以上的采购在途,明细如下\n${comNames}`)
                confirmMany()
                autoTimes++
              }
            }
          }
        })
        .catch((err) => {

        });
    };

    searchList()






    const saleAccept = (indexs) => {
      return new Promise(function (resolve, reject) {
        const partACom = store.state.loginInitializeData.companyId
        const partBCom = transferToCode(tableBody[indexs[0]].partACompanyId, 'PartBCompanyId')
        const user = store.state.loginInitializeData.userName
        const userId = store.state.loginInitializeData.userId
        let inWareHouseId = defaultInWarehouse.value;
        const inWareHouseName = transferToName(inWareHouseId, 'WareHouseId')
        let CommodityNameArr = []
        getDocNo('PO').then(res => {
          let poHeadCode = res
          let poBody = []
          const indexLength = indexs.length
          for (let i = 0; i < indexLength; i++) {
            let remarkForPo = `采购了${tableBody[indexs[i]].responsedQty}个${tableBody[indexs[i]].commodityName},金额是${tableBody[indexs[i]].buyQty * tableBody[indexs[i]].unitPrice} 其他备注:${tableBody[indexs[i]].remark}`
            if (remarkForPo.length > 80) {
              remarkForPo = remarkForPo.substring(0, 79)
            }
            let b = {
              DocumentId: poHeadCode,
              Sn: +i + 1,
              PartACompanyId: partACom,
              PartBCompanyId: partBCom,
              DocumentDateTime: '',
              CommodityName: tableBody[indexs[i]].commodityNameSale,
              CommodityId: tableBody[indexs[i]].commodityIdSale,
              VirtualGoods: tableBody[indexs[i]].virtualGoods == true ? true : (tableBody[indexs[i]].virtualGoods == '' ? false : true),
              OrderQty: tableBody[indexs[i]].buyQty,
              Unit: '',
              UnitPrice: tableBody[indexs[i]].unitPrice,
              Amount: tableBody[indexs[i]].buyQty * tableBody[indexs[i]].unitPrice,
              Remark: remarkForPo,
              // Remark: `采购了${tableBody[indexs[i]].responsedQty}个${tableBody[indexs[i]].commodityName},金额是${tableBody[indexs[i]].buyQty * tableBody[indexs[i]].unitPrice}`,
              NotifyStatus: '',
              RefusedQty: '',
              ResponsedQty: '',
              ResponseStatus: '',
              InStockQty: tableBody[indexs[i]].buyQty,
              InStockStatus: 103,
              InspectedQty: '',
              InspectionStatus: '',
              InvoiceReceiveAmount: '',
              InvoiceReceivedStatus: '',
              MoneyPayedAmount: '',
              MoneyPayedStatus: '',
              SourceDocumentId: '',
              RootSourceDocumentId: tableBody[indexs[i]].documentId,
              ModifiedTimes: 0,
            }
            if (b.CommodityId && !b.VirtualGoods && tableBody[indexs[i]].startType != '下限报警' && CommodityNameArr.indexOf(b.CommodityId) <= -1) {
              CommodityNameArr.push(b.CommodityId)
            }
            poBody.push(b)
          }
          let poHead = {
            DocumentId: poHeadCode,
            DocumentTypeName: '',
            DocumentDate: '',
            DocumentDateTime: '',
            Online: '',
            PartACompanyId: partACom,
            PartBCompanyId: partBCom,
            UserId: userId,
            StaffId: '',
            SourceDocumentId: '',
            Amount: '',
            Qty: '',
            OutWareHouseId: '',
            InWareHouseId: inWareHouseId,
            SystemStatusId: 104,
            CapitalAccountId: '',
            Remark: '',
            RootSourceDocumentId: poHeadCode,
          }
          openPoList(poBody, poHead, poHeadCode).then(res => {
            if (res != 'pass') {
              const errmessage = res
              deleteDocCenter(poHeadCode).then(res => {
                resolve(errmessage)
              })
            }
            else {

              let deleteArr = []
              for (let i = 0; i < indexLength; i++) {
                let a = deleteAcceptOnTheWay(tableBody[indexs[i]].onTheWayCode, tableBody[indexs[i]].buyQty)
                deleteArr.push(a)
              }
              Promise.all(deleteArr).then(resArr => {
                if (inWareHouseName == '主仓库') {
                  setTimeout(() => {
                    updateWarning(partACom, CommodityNameArr)
                  }, 0);
                }
                resolve(resArr)
              })
            }
          })
        })
      })
    }

    const goHistory = () => {
      emit('goOnTheWayHistory', 'buy')
    }

    const TtAccept = (index) => {
      return new Promise(function (resolve, reject) {
        const partACom = store.state.loginInitializeData.companyId
        const inWareHouseName = transferToName(defaultInWarehouse.value, 'WareHouseId')
        let CommodityNameArr = []
        let onObj = tableBody[index];
        updateInwarehouseId(onObj.documentIdSale, defaultInWarehouse.value).then(res => {
          let ttObj = {
            TtCode: '',
            DocumentId: onObj.documentIdSale,
            CommodityName: onObj.commodityNameSale,
            CommodityId: onObj.commodityIdSale,
            Sn: onObj.snSale,
            PartACompanyId: partACom,
            PartBCompanyId: transferToCode(onObj.partACompanyId, 'PartBCompanyId'),
            OrderQty: onObj.buyQty,
            InWareHouseId: defaultInWarehouse.value,
            UnitPrice: onObj.UnitPrice,
            InStockQty: onObj.buyQty,
            InStockKeeping: true,
          }
          if (ttObj.CommodityId && onObj.startType != '下限报警') {
            CommodityNameArr.push(ttObj.CommodityId)
          }

          ttInstock(ttObj).then(res => {

            if (res != 'pass') {
              resolve(res)
            }
            else {
              deleteAcceptOnTheWay(onObj.onTheWayCode, onObj.buyQty).then(res => {
                if (inWareHouseName == '主仓库') {
                  setTimeout(() => {
                    updateWarning(partACom, CommodityNameArr)
                  }, 0);
                }
                resolve(res)
              })
            }
          })
        })
      })
    }

    const cancelOnTheWay = (index) => {
      return new Promise(function (resolve, reject) {
        let onObj = tableBody[index];
        deleteOnTheWay(onObj.onTheWayCode).then(res => {
          if (onObj.CommodityId) {
            let CommodityNameArr = []
            CommodityNameArr.push(onObj.CommodityId)
            setTimeout(() => {
              updateWarning(store.state.loginInitializeData.companyId, CommodityNameArr)
            }, 0);
          }
          resolve(res)
        })
      })
    }


    const confirmOne = (index) => {

      if (!tableBody[index].customerToBeSolve || tableBody[index].responsedQty == '') {
        ElMessage(`第${+index + 1}行无需处理`)
        return;
      }
      if (tableBody[index].documentTypeSale == '调货单') {
        let b = confirm('确认并调货入库')
        if (!b) {
          return;
        }
      }
      if (tableBody[index].documentTypeSale == '销售单') {
        let b = confirm('确认并采购入库')
        if (!b) {
          return;
        }
      }

      let codes = []
      codes.push(tableBody[index].onTheWayCode)

      lockOneTheWay(codes).then(res => {
        try {
          if (res != 'pass') {
            ElMessage(res)
            searchList()
            return;
          }
          let stockPromiseCheck = []
          if (tableBody[index].responsedQty > 0 && tableBody[index].documentTypeSale == '调货单') {
            let b = checkTtInWarehouse(transferToCode(tableBody[index].partACompanyId,'PartBCompanyId'),tableBody[index].documentIdSale, defaultInWarehouse.value)
            stockPromiseCheck.push(b)
          }
          if (tableBody[index].responsedQty > 0 && tableBody[index].documentTypeSale == '销售单' && !tableBody[index].virtualGoods) {
            let a = checkSoInWarehouse(defaultInWarehouse.value)
            stockPromiseCheck.push(a)
          }
          Promise.all(stockPromiseCheck).then(resArr => {
            for (let item of resArr) {
              if (item != 'pass') {
                searchList()
                ElMessage('入库仓库不符合条件')
                return false;
              }
            }
            let promiseArr = []
            if (tableBody[index].responsedQty > 0 && tableBody[index].documentTypeSale == '调货单') {
              let a = TtAccept(index)
              promiseArr.push(a)
            }
            if (tableBody[index].responsedQty > 0 && (tableBody[index].documentTypeSale == '销售单' || tableBody[index].documentTypeSale == '扣款单')) {
              let indexs = []
              indexs.push(index)
              let a = saleAccept(indexs)
              promiseArr.push(a)
            }
            Promise.all(promiseArr).then((res) => {

              searchList()
              emit('refreshMsg')
            })
          })


        } catch (error) {



        }

        finally {
          unLockOneTheWay(codes)


        }



      }).catch(err => {

        searchList()
      })
    }

    const confirmMany = () => {
      if (selectedItems.value.length == 0) {
        // ElMessage('未选择行')
        return;
      }
      lockOneTheWay(selectedItems.value).then(res => {
        try {
          if (res != 'pass') {
            ElMessage(res)
            searchList()
            return;
          }

          let arrLength = tableBody.length
          let indexArr = []
          for (let i = 0; i < arrLength; i++) {
            if (selectedItems.value.indexOf(tableBody[i].onTheWayCode) > -1) {
              indexArr.push(i)
            }
          }

          let stockPromiseCheck = []

          for (let index of indexArr) {
            if (tableBody[index].responsedQty > 0 && tableBody[index].documentTypeSale == '调货单' && !tableBody[index].virtualGoods) {
              let b = checkTtInWarehouse(transferToCode(tableBody[index].partACompanyId,'PartBCompanyId'),tableBody[index].documentIdSale, defaultInWarehouse.value)
              stockPromiseCheck.push(b)
            }
            if (tableBody[index].responsedQty > 0 && tableBody[index].documentTypeSale == '销售单' && !tableBody[index].virtualGoods) {
              let a = checkSoInWarehouse(defaultInWarehouse.value)
              stockPromiseCheck.push(a)
            }
          }
          Promise.all(stockPromiseCheck).then(resArr => {
            let resLength = resArr.length
            for (let i = 0; i < resLength; i++) {
              if (resArr[i] != 'pass') {
                searchList()
                ElMessage(`第${+indexArr[i] + 1}行入库仓库不符合要求`)
                return false;
              }
            }

            let errMsg = ''
            let promiseArr = []
            let partBArr = []

            for (let index of indexArr) {
              if (tableBody[index].responsedQty > 0 && tableBody[index].documentTypeSale == '销售单') {
                if (partBArr.indexOf(transferToCode(tableBody[index].partACompanyId, 'PartBCompanyId')) <= -1) {
                  partBArr.push(transferToCode(tableBody[index].partACompanyId, 'PartBCompanyId'))
                }
              }
            }

            let partBArrObj = {}
            if (partBArr.length > 0) {
              for (let item of partBArr) {
                partBArrObj[item] = []
              }
            }

            for (let index of indexArr) {
              if (tableBody[index].responsedQty <= 0) {
                errMsg += `第${+index + 1}行无需操作`
              }
              if (tableBody[index].responsedQty > 0 && tableBody[index].documentIdSale.substring(0, 2) == 'TT') {
                let a = TtAccept(index)
                promiseArr.push(a)
              }
              if (tableBody[index].responsedQty > 0 && tableBody[index].documentIdSale.substring(0, 2) == 'SO' || tableBody[index].responsedQty > 0 && tableBody[index].documentIdSale.substring(0, 2) == 'RO') {
                for (let key in partBArrObj) {
                  if (transferToCode(tableBody[index].partACompanyId, 'PartBCompanyId') == key) {
                    partBArrObj[key].push(index)
                  }
                }
              }
            }

            for (let key in partBArrObj) {
              if (partBArrObj[key].length > 0) {
                let c = saleAccept(partBArrObj[key])
                promiseArr.push(c)
              }
            }

            Promise.all(promiseArr).then((res) => {
              if (errMsg != '') {
                ElMessage(errMsg)
              }
              searchList()
              emit('refreshMsg')
            }).catch(err => {
              searchList()
            })
          })


        } catch (error) {

        }

        finally {
          unLockOneTheWay(selectedItems.value)
        }



      }).catch(err => {

        searchList()
      })


    }

    const deleteOne = (index) => {
      if (tableBody[index].responsedQty > 0 && tableBody[index].refusedQty) {
        ElMessage('该行无法删除')
        return
      }
      else {
        let b = confirm('确认取消，取消后无法恢复')
        if (!b) {
          return false;
        }
        let codes = []
        codes.push(tableBody[index].onTheWayCode)
        lockOneTheWay(codes).then(res => {
          try {
            if (res != 'pass') {
              ElMessage(res)
              searchList()
              return;
            }
            cancelOnTheWay(index).then(res => {
              searchList()
              emit('refreshMsg')
            })


          } catch (error) {

          }

          finally {
            unLockOneTheWay(codes)

          }



        }).catch(err => {

          searchList()
        })
      }
    }

    const deleteMany = () => {
      if (selectedItems.value.length == 0) {
        ElMessage('未选择行')
        return;
      }
      let b = confirm('确认取消，取消后无法恢复')
      if (!b) {
        return false;
      }
      lockOneTheWay(selectedItems.value).then(res => {
        try {
          if (res != 'pass') {
            ElMessage(res)
            searchList()
            return;
          }
          let arrLength = tableBody.length
          let indexArr = []
          for (let i = 0; i < arrLength; i++) {
            if (selectedItems.value.indexOf(tableBody[i].onTheWayCode) > -1) {
              indexArr.push(i)
            }
          }
          for (let index of indexArr) {
            if (tableBody[index].responsedQty > 0) {
              searchList()
              ElMessage(`第${+index + 1}行无法取消`)
              return false;
            }
          }
          let promiseArr = []
          for (let index of indexArr) {
            let b = cancelOnTheWay(index)
            promiseArr.push(b)
          }
          Promise.all(promiseArr).then(resArr => {
            searchList()
            emit('refreshMsg')
          })


        } catch (error) {



        }

        finally {
          unLockOneTheWay(selectedItems.value)


        }


      }).catch(err => {
        
        searchList()
      })
    }

    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          searchList()
        }
      }
    )
    //#endregion

    return {
      partBFilterCopy, DistributedOrNotFSFilter, CustomerToBeSolveFSFilter, setActiveId, activeInput,
      trHeight, SnWidth, operationWidth, tableHead, searchList, ChangeSort, tablewidth, tableBody,
      reSearch, searchElement, tbodyHeight, OnTheWayTypeList, SearchContent, selectAllWidth,
      selectAll, selectedItems, confirmMany, confirmOne, defaultInWarehouse, warehouseFilter,
      buyQtyWidth, selectAllTt, selectAllSo, selectAllDe, deleteOne, deleteMany, goHistory

    }
  },
  components: {
  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;
@lableMarginLeft: 0.625rem;
@lableHeight: 2rem;
@inputHeight: 1.5rem;
@mainColor: #4285f4;

.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.searchinputLabel {
  margin-left: @lableMarginLeft;
  font-size: 0.8rem;
  height: @lableHeight;
  line-height: @lableHeight;
}
.searchinput {
  margin-top: calc((@lableHeight - @inputHeight) / 2);
  margin-bottom: calc((@lableHeight - @inputHeight) / 2);
  font-size: 0.9rem;
  border: none;
  outline: none;
  height: @inputHeight;
  line-height: @inputHeight;
}
.ActiveBox {
  border: 1px solid #ffa004 !important;
}

.searchInputBox {
  height: @lableHeight;
  border: 1px solid #c9c9c9;
  margin-left: @lableMarginLeft;
  border-radius: 0.31rem;
}

.searchItemContainer {
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
  display: flex;
}

.searchSetting {
  margin-left: calc(@lableMarginLeft*3);
  height: @lableHeight;
  display: flex;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}
.searchSetting:hover {
  cursor: pointer;
  color: @mainColor;
}
.settingIcon {
  font-family: "icomoon";
  font-size: 1.1rem;
  line-height: @lableHeight;
}

.settingText {
  margin-left: 0.3rem;
  font-size: 0.8rem;
  line-height: @lableHeight;
}
.dateInputContainer {
  display: flex;
}
.BodyTitle {
  width: 98%;
  margin-top: 0.5rem;
  margin-left: 1%;
}
.BodyTitleText {
  font-size: 1.2rem;
  font-weight: 200;
}
.long {
  width: 20rem;
}

.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 60%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.thSN {
  font-family: "icomoon";
  color: #fff;
  font-size: 16px;
}
.headtd {
  cursor: pointer;
}
.ShowUpArrow {
  position: relative;
}
.ShowUpArrow::after {
  content: "\ea32";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
  color: #fff;
}
.ShowDownArrow {
  position: relative;
}
.ShowDownArrow::after {
  content: "\ea36";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.tdSN,
.tableselect,
.tdoper,
.footsum,
.bodytr {
  text-align: center;
}
.bodytr {
  height: 2rem;
}

.tablefoot {
  margin-top: -1px;
}

.moreFunctionBox {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.selectAllCheckBox {
  cursor: pointer;
}
.todoLine {
  background-color: #fce4d6 !important;
}

.nottodoLine {
  background-color: #a6a6a6 !important;
}

.tipColortodo,
.tipColorfinish {
  height: 1.2rem;
  width: 1.2rem;
  margin-top: 0.3rem;
}
.tipColortodo {
  background-color: #fce4d6;
}
.tipColorfinish {
  background-color: #a6a6a6;
}
.tipWords {
  height: 1.2rem;
  line-height: 1.2rem;
  margin-top: 0.3rem;
  margin-left: 0.5rem;
}
.tipBox {
  display: flex;
  height: 1.75rem;
  width: 8rem;
  margin-top: 0.625rem;
  margin-left: 0.8rem;
}
.firstButton {
  margin-left: 1rem;
}
.tableInput {
  border: none;
  outline: none;
  height: 1.875rem;
  background-color: transparent;
  text-align: center;
}
.tableInput:focus {
  border: 1px solid #ffa004;
}
</style>