import { ref } from "@vue/reactivity";
import { watch } from '@vue/runtime-core';

export default function(emit = null){
        //弹窗位置变量
        let popWindowTopComm = ref('150px');
        let popWindowLeftComm = ref('300px')
        let x1 = ref(0);
        let y1 = ref(0);
        let CommPop = ref(false);
    
        const escClosePop = (e) => {
          if(e.keyCode == 27){CommPop.value = false;}
        }
            //添加控制弹窗开关的方法
        let closePopComm = () => {
          if(emit){
            emit('closeCheckPay')
          }
          CommPop.value = false;
        }
        let openPopComm = () => {
          CommPop.value = true;
        }
    
      let movePopWindow = (event) => {
          popWindowTopComm.value = event.clientY - y1.value + 'px'
          popWindowLeftComm.value = event.clientX - x1.value + 'px'
        }
    
        let startMoveComm = (e) =>{
          x1.value = e.clientX -parseInt(popWindowLeftComm.value);
          y1.value = e.clientY -parseInt(popWindowTopComm.value);
          
          window.addEventListener('mousemove',movePopWindow)
        }
        let stopMoveComm = () =>{
          window.removeEventListener('mousemove',movePopWindow)
          x1.value = 0;
          y1.value = 0;
        }
    
        watch(CommPop,(newValue,oldValue) => {
          if(newValue == true){
            window.addEventListener('keyup',escClosePop);
          }
          else{
            window.removeEventListener('keyup',escClosePop)
          }
        })

        return {popWindowTopComm,popWindowLeftComm,CommPop,closePopComm,openPopComm,startMoveComm,stopMoveComm}
}