<template>
  <div class="inputLoaderContainer">
    <BodyTitle></BodyTitle>
    <InputHead></InputHead>
    <InputBody ref="inputBodyRef" @clearScanIn="clearScanIn"></InputBody>
    <div class="EditTableLine">
    </div>
    <div class="EditTableLastLine">
      <!-- 维护第七层的内容 -->
      <!-- <div class="EditTableFuncButton" :style="{background:'#73a6a3'}" @click="openPopUp()">导入订单明细</div> -->
      <div class="EditTableFuncButton" @click="openSpreadRef()">成本分摊支出并通知付款</div>
      <div class="EditTableFuncButton EditTableFuncButtonAjust" @click="openList('EO')">支出并通知付款</div>
    </div>
    <PopModel ref="spreadRef">
      <div class="inputBox">
        <label class="popLable" for="popTotalAmount">支出金额:</label>
        <el-input class="popInput" id="popTotalAmount" v-model="costSpreadData.totalAmount" type="number" :disabled="true" />
      </div>

      <div class="inputBox">
        <label class="popLable" for="popStartDate">开始日期:</label>
        <el-date-picker :style="[{width:'28rem'},{marginTop:'0.5rem'}]" v-model="costSpreadData.startDate" type="date" placeholder="请输入开始日期"></el-date-picker>
      </div>

      <div class="inputBox">
        <label class="popLable" for="popFinishDate">结束日期:</label>
        <el-date-picker :style="[{width:'28rem'},{marginTop:'0.5rem'}]" v-model="costSpreadData.finishDate" type="date" placeholder="请输入结束日期" @blur="checkFinishDate()"></el-date-picker>
      </div>

      <div class="inputBox">
        <label class="popLable" for="popTotalDays">待摊天数:</label>
        <el-input class="popInput" id="popTotalDays" v-model="spreadDays" type="text" :disabled="true" />
      </div>

      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight" @click="closeSpreadPop()">取消</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="openList('EOCS')">确认支出并通知付款</el-button>
      </div>
    </PopModel>
  </div>
</template>
<script>
import { computed, provide, reactive, ref, watch } from 'vue-demi'
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import InputHead from '@/components/Content/MainBodyContent/InputHead.vue'
import InputBody from '@/components/Content/MainBodyContent/InputBody.vue'
import { getDocNo, deleteDocCenter, getLoginData, openEoList, checkDocCondition, transferToCode, getDocType, clearTableContent, addMoneyToBePaid, transferToName } from '@/hooks/OpenOrder'
import { GetNumberOfDays } from '@/hooks/tools'
import { transferLoginDataOnly } from '@/hooks/loginAffair';
import PopModel from '@/components/Common/PopModel.vue'
import ComScanIn from '@/components/Content/FunctionUnit/ComScanIn.vue'
import InputTable from '@/hooks/InputTable'
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
export default {
  components: {
    BodyTitle,
    InputHead,
    InputBody,
    ComScanIn,
    PopModel
  },
  setup(props, { emit }) {
    const store = useStore();
    const RootTableName = 'EoList';
    let { itemListHead, itemListBody, searchItemList } = InputTable(RootTableName)
    searchItemList.docTitleTwo = 'EO'
    searchItemList.TableName = 'EoInput'
    provide('searchItemList', searchItemList)

    provide('tableTitle', '成本待摊数据')

    let inputBodyRef = ref(null)
    //#endregion

    //#region 扫描组件
    let scanInValue = ref('');

    const scanIn = (res) => {
      inputBodyRef.value.scanInData(res)
    }

    const clearScanIn = () => {
      scanInValue.value = '';
    }

    let costSpreadData = reactive({
      totalAmount: '',
      startDate: '',
      finishDate: '',
    })

    let spreadDays = computed(() => {
      if (GetNumberOfDays(costSpreadData.startDate, costSpreadData.finishDate)) {
        return GetNumberOfDays(costSpreadData.startDate, costSpreadData.finishDate) + 1 + '天'
      }
      else {
        return ''
      }
    })

    const spreadRef = ref(null)

    const openSpreadRef = () => {
      let b = 0
      for (let item of inputBodyRef.value.AddContentBody) {
        if (item.UnitPrice) {
          b += item.UnitPrice
        }
        if (item.UnitPrice && !item.AccountingSubjectId) {
          ElMessage('支出类型缺失')
          return;
        }
      }
      if (b == 0) {
        ElMessage('无单据内容')
        return;
      }
      if (!searchItemList.documentContent.StaffId) {
        ElMessage('未填写操作人')
      }
      costSpreadData.totalAmount = b
      spreadRef.value.openPopComm()
    }

    const closeSpreadPop = () => {
      spreadRef.value.closePopComm()
    }

    const checkFinishDate = () => {
      if (costSpreadData.finishDate - costSpreadData.startDate < 1) {
        ElMessage('待摊时间不足一天')
        costSpreadData.finishDate = ''
      }
    }

    //#endregion

    //#region 开单功能模块


    const openList = (typeName) => {

      if (typeName == 'EOCS') {
        if (!costSpreadData.startDate || !costSpreadData.finishDate) {

          ElMessage('待摊时间未填写')
          return
        }
      }

      let errCheck = checkDocCondition(searchItemList.documentContent, itemListHead, inputBodyRef.value.AddContentBody, itemListBody)
      if (errCheck.headErr) {

        ElMessage(errCheck.headErr)
        return
      }
      if (errCheck.bodyErr) {

        ElMessage(errCheck.bodyErr)
        return
      }
      getLoginData().then(res => {
        //刷新初始化数据
        if(res != 0){
          transferLoginDataOnly(res)
        }
        getDocNo(searchItemList.docTitleTwo).then(res => {
          const eoHeadCode = res
          const partBId = transferToCode('其他', 'PartBCompanyId')
          let totalAmount = 0
          for (let item of inputBodyRef.value.AddContentBody) {
            if (item.UnitPrice) {
              totalAmount += item.UnitPrice
            }
          }
          const staffIdCopy = transferToCode(searchItemList.documentContent.StaffId, 'StaffId')
          const user = store.state.loginInitializeData.userName
          for (let item of inputBodyRef.value.AddContentBody) {
            if (item.UnitPrice) {
              item.SourceDocumentId = ''
              item.RootSourceDocumentId = eoHeadCode
              item.PartACompanyId = store.state.loginInitializeData.companyId
              item.PartBCompanyId = partBId
              item.OutStockQty = 1
              item.OrderQty = 1
              item.InStockQty = 1
              item.DocumentId = eoHeadCode
              item.CostSpread = false
              item.Remark = ((item.Remark ? item.Remark + '-' : '') + `,支出类型为${transferToName(item.AccountingSubjectId, 'AccountingSubjectId')}`)
            }
            if (typeName == 'EOCS') {
              item.CostSpread = true
              item.StartDate = costSpreadData.startDate
              item.FinishDate = costSpreadData.finishDate
            }
          }
          openEoList(inputBodyRef.value.AddContentBody, searchItemList.documentContent, eoHeadCode).then(res => {
            if (res != 'pass') {
              const errMessage = res
              deleteDocCenter(eoHeadCode).then(res => {
                ElMessage(errMessage)
                return false;
              })

            }
            else {
              const body = {
                DocumentId: eoHeadCode,
                PartACompanyId: store.state.loginInitializeData.companyId,
                PartBCompanyId: partBId,
                StaffId: staffIdCopy,
                DocumentType: getDocType(eoHeadCode),
                Amount: totalAmount,
                Remark: `操作人:${transferToName(staffIdCopy, 'StaffId')}开立了一张支出单，单号为${eoHeadCode}，需要支付${totalAmount}元,操作账号:${user}`
              }
              addMoneyToBePaid(body).then(res => {
                ElMessage({
                message:'开单成功',
                duration:2000,
                showClose:true
              })
                clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                closeSpreadPop()
                emit('refreshMsg')
              })
            }
          })
        })
      }).catch(err => {

        ElMessage(err.message)
      })
    }
    //#endregion

    //#endregion

    return {
      searchItemList,
      inputBodyRef,
      scanInValue,
      scanIn,
      clearScanIn,
      openList,
      spreadDays,
      costSpreadData,
      spreadRef,
      openSpreadRef,
      closeSpreadPop,
      checkFinishDate
    }
  }
}
</script>
<style scoped lang="less">
.inputBox {
  display: flex;
}
.popLable {
  margin-top: 0.5rem;
  line-height: 2.5rem;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 0.5rem;
}
.popInput {
  margin-top: 0.5rem;
  width: 28rem;
}
.buttonBox {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  justify-content: flex-end;
}
.mRight {
  margin-top: 0.1rem;
}
.lastButton {
  margin-right: 0.2rem;
}
</style>