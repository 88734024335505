<template>
  <div class="inputLoaderContainer">
    <BodyTitle></BodyTitle>
    <InputHead></InputHead>
    <InputBody ref="inputBodyRef" @clearScanIn="clearScanIn"></InputBody>
    <div class="EditTableLine">
      <ComScanIn :scanInValue="scanInValue" @scanIn="scanIn"></ComScanIn>
    </div>
    <div class="EditTableLastLine">
      <!-- 维护第七层的内容 -->
      <div class="EditTableFuncButton" :style="{background:'#73a6a3'}" @click="openPopUp()">导入订单明细</div>
      <!-- <div class="EditTableFuncButton EditTableFuncButtonAjust" @click="openList('origin')">期初入库</div> -->
      <div class="EditTableFuncButton EditTableFuncButtonAjust" @click="openList('')">保存并入库</div>
    </div>
    <UpPop ref="upPopRef" @uploadData="uploadData"></UpPop>
  </div>
</template>
<script>
import { computed, provide, ref, watch } from 'vue-demi'
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import InputHead from '@/components/Content/MainBodyContent/InputHead.vue'
import InputBody from '@/components/Content/MainBodyContent/InputBody.vue'
import UpPop from '@/components/Common/GlobalPop/UpPop.vue'
import { getDocNo, washDocHead, getLoginData, updateWarning, transferToCode, deleteDocCenter, openDocCenter, openIsList, checkDocCondition, clearTableContent } from '@/hooks/OpenOrder'
import { transferLoginDataOnly } from '@/hooks/loginAffair';
import ComScanIn from '@/components/Content/FunctionUnit/ComScanIn.vue'
import InputTable from '@/hooks/InputTable'
import { useStore } from "vuex";
import { isRealNum } from '@/hooks/tools'
import { ElMessage } from 'element-plus'
export default {
  components: {
    BodyTitle,
    InputHead,
    InputBody,
    UpPop,
    ComScanIn
  },
  setup() {
    const store = useStore();
    const RootTableName = 'IsList';
    let { itemListHead, itemListBody, searchItemList } = InputTable(RootTableName)
    searchItemList.docTitleTwo = 'IS'
    searchItemList.TableName = 'IsInput'
    provide('searchItemList', searchItemList)

    let inputBodyRef = ref(null)
    //#endregion

    //#region 扫描组件
    let scanInValue = ref('');

    const scanIn = (res) => {
      inputBodyRef.value.scanInData(res)
    }

    const clearScanIn = () => {
      scanInValue.value = '';
    }

    //#endregion

    //#region 上传模块
    let upPopRef = ref(null)
    const openPopUp = () => {
      upPopRef.value.openPopUp()
    }

    const uploadData = (dataUpload) => {
      inputBodyRef.value.fillUploadData(dataUpload)
    }
    //#endregion

    //#region 开单功能模块

    const openList = (typeName) => {

      let errCheck = checkDocCondition(searchItemList.documentContent, itemListHead, inputBodyRef.value.AddContentBody, itemListBody)

      if (errCheck.headErr) {

        ElMessage(errCheck.headErr)
        return
      }
      if (errCheck.bodyErr) {

        ElMessage(errCheck.bodyErr)
        return
      }

      getLoginData().then(res => {
        //刷新初始化数据
        if(res != 0){
          transferLoginDataOnly(res)
        }
        getDocNo(searchItemList.docTitleTwo).then(res => {
          const isHeadcode = res
          const InWareHouseName = isRealNum(searchItemList.documentContent.InWareHouseId) ? transferToName(searchItemList.documentContent.InWareHouseId, 'WareHouseId') : searchItemList.documentContent.InWareHouseId
          let CommodityNameArr = []
          for (let item of inputBodyRef.value.AddContentBody) {
            if (item.CommodityName) {
              item.PartACompanyId = store.state.loginInitializeData.companyId
              item.PartBCompanyId = transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId')
              item.OutStockQty = item.OrderQty
              item.InStockQty = item.OrderQty
              item.DocumentId = isHeadcode
              item.RootSourceDocumentId = isHeadcode
              item.Remark = ((item.Remark ? item.Remark + '-' : '') + `报溢了${item.OrderQty}个${item.CommodityName}`)
            }
            if (!item.VirtualGoods && item.CommodityId) {
              CommodityNameArr.push(item.CommodityId)
            }
          }
          const originJudege = (typeName == 'origin')
          openIsList(inputBodyRef.value.AddContentBody, searchItemList.documentContent, isHeadcode,originJudege).then(res => {
            if (res != 'pass') {
              ElMessage(res)
              deleteDocCenter(isHeadcode).then(res => {
                return;
              })
            }
            else {
              if (InWareHouseName == '主仓库') {
                setTimeout(() => {
                  updateWarning(store.state.loginInitializeData.companyId, CommodityNameArr)
                }, 0);
              }
              ElMessage({
                message:'开单成功',
                duration:2000,
                showClose:true
              })
              clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
            }

          })
        })
      }).catch(err => {

        ElMessage(err.message)
      })



      // getLoginData().then(res => {
      //   //刷新初始化数据
      //   transferLoginDataOnly(res)
      //   let contentBodyCopy = copyArrayDoc(inputBodyRef.value.AddContentBody, 'CommodityName')
      //   washBodyCopy(contentBodyCopy)
      //   let docHeadCopy = {}
      //   washDocHead(searchItemList.documentContent, docHeadCopy)
      //   openDocCenter(docHeadCopy, contentBodyCopy, docHeadCode).then(res => {
      //     if (res != 'pass') {
      //       ElMessage(res)
      //       return false;
      //     }
      //     for (let item of contentBodyCopy) {
      //       item.InStockKeeping = true
      //       item.OutStockKeeping = false
      //       item.InAccountsKeeping = false
      //       item.OutAccountsKeeping = false
      //       item.InMoneyKeeping = false
      //       item.OutMoneyKeeping = false
      //       item.GoodsProfitKeeping = true
      //       item.AccountsProfitKeeping = false
      //       item.InPriceRecordKeeping = false
      //       item.OutPriceRecordKeeping = false
      //       item.InStockQty = item.OrderQty
      //     }
      //     openIsList(contentBodyCopy, docHeadCode).then(res => {
      //       if (res != 'pass') {
      //         ElMessage(res)
      //         deleteDocCenter(docHeadCode)
      //         return false;
      //       }
      //       clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
      //       refreshDocNo()
      //     })
      //   }).catch(err => {
      //     ElMessage(err)
      //   })
      // })
    }
    //#endregion

    //#endregion

    return {
      searchItemList,
      inputBodyRef,
      scanInValue,
      scanIn,
      clearScanIn,
      openPopUp,
      upPopRef,
      uploadData,
      openList
    }
  }
}
</script>
<style scoped lang="less">
</style>