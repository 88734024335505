<template>
  <div>
      <div class="searchContentContainer">

    <div class="searchItemContainer">
      <label class="searchinputLabel" for="DocumentTypeNameDocCenter">单据类型:</label>
      <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentTypeName'}"><select v-model="searchItems['DocumentTypeName']" class="searchinput" id="DocumentTypeNameDocCenter" @focus="setActiveId('DocumentTypeName')" @blur="setActiveId('')" @change="reSearch()">
          <option value="">所有类型</option>
          <option v-for="item in DocumentTypeNameFilterCopy" :key="item.documentTypeId + 'DocCenter'" :value="item.documentTypeId">{{item.documentName}}</option>
        </select></div>
    </div>

    <div class="searchItemContainer">
      <label class="searchinputLabel" for="PartBCompanyIdForDocCenter">往来单位名称:</label>
      <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'PartBCompanyId'}"><select v-model="searchItems['PartBCompanyId']" class="searchinput" id="BussinessParterForDocCenter" @focus="setActiveId('PartBCompanyId')" @blur="setActiveId('')" @change="reSearch()">
          <option value="">所有</option>
          <option v-for="item in searchItemList.PartBCompanyId.Filter" :key="item.beforeTransfer + 'DocCenter'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
        </select></div>
    </div>

    <div class="searchItemContainer">
      <label class="searchinputLabel" for="StaffIdForDocCenter">经手人:</label>
      <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'StaffId'}"><select v-model="searchItems['StaffId']" class="searchinput" id="StaffIdForDocCenter" @focus="setActiveId('StaffId')" @blur="setActiveId('')" @change="reSearch()">
          <option value="">所有</option>
          <option v-for="item in searchItemList.StaffId.Filter" :key="item.beforeTransfer + 'InvenBala'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
        </select></div>
    </div>

    <div class="searchItemContainer">
      <label class="searchinputLabel" for="DocumentDateLDocCenter">查询日期</label>
      <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateL'}"><input v-model="searchItems['DocumentDateL']" class="searchinput" id="DocumentDateLDocCenter" type="date" @focus="setActiveId('DocumentDateL')" @blur="setActiveId('')"></div>
    </div>
    <div class="searchItemContainer">
      <label class="searchinputLabel" for="DocumentDateHDocCenter" v-text="'---'"></label>
      <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateH'}"><input v-model="searchItems['DocumentDateH']" class="searchinput" id="DocumentDateHDocCenter" type="date" @focus="setActiveId('DocumentDateH')" @blur="setActiveId('')"></div>
    </div>

    <!-- 搜索模块 -->
    <div class="FuncButton" @click="searchList()">搜索</div>
  </div>
  <div class="searchContentContainer">

    <!-- 搜索模块 -->
    <div class="quickWordsBox">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item><span :class="{heightLight:docTypeChoose == '1009'}" @click="setDocType('1009'),searchList()" class="quickWords">零售单</span></el-breadcrumb-item>
        <el-breadcrumb-item><span :class="{heightLight:docTypeChoose == '1003'}" @click="setDocType('1003'),searchList()" class="quickWords">销售单</span></el-breadcrumb-item>
        <el-breadcrumb-item><span :class="{heightLight:docTypeChoose == '1001'}" @click="setDocType('1001'),searchList()" class="quickWords">采购单</span></el-breadcrumb-item>
        <el-breadcrumb-item><span :class="{heightLight:docTypeChoose == '1020'}" @click="setDocType('1020'),searchList()" class="quickWords">报销单</span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="quickWordsBox">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item><span :class="{heightLight:docTimeChoose == 'one'}" @click="setDocTime('one'),searchList()" class="quickWords">当天</span></el-breadcrumb-item>
        <el-breadcrumb-item><span :class="{heightLight:docTimeChoose == 'three'}" @click="setDocTime('three'),searchList()" class="quickWords">近3天</span></el-breadcrumb-item>
        <el-breadcrumb-item><span :class="{heightLight:docTimeChoose == 'seven'}" @click="setDocTime('seven'),searchList()" class="quickWords">近7天</span></el-breadcrumb-item>
        <el-breadcrumb-item><span :class="{heightLight:docTimeChoose == 'thirty'}" @click="setDocTime('thirty'),searchList()" class="quickWords">近30天</span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, inject, provide, onUnmounted } from 'vue-demi'
import SelectPopModel from '@/components/ComPop/SelectPopModel.vue';
import { addDate, ChangeYMD, copyArray, fillSonContent, uniqueArrByKey } from '@/hooks/tools'
import { useStore } from "vuex";
import DataSpan from "@/components/Common/ElementUI/DateSpan.vue";
export default {
  setup(props, { emit }) {
    const store = useStore();

    //#region 数据定义区
    let searchItemList = inject('searchItemList')
    let headName = ref(searchItemList.fileModelName)
    let activeInput = ref('');
    let searchItems = reactive({})
    const searchRef = ref(null)
    //#endregion
    //#region 公式定义区
    const setActiveId = (id) => {
      activeInput.value = id
    }
    const openSearchSetting = () => {
      searchRef.value.openPopComm()
    }

    let DocumentTypeNameFilterCopy = copyArray(store.state.loginInitializeData.documentTypeManage)

    const length3 = DocumentTypeNameFilterCopy.length

    for (let i = length3 - 1; i >= 0; i--) {
      if (DocumentTypeNameFilterCopy[i].remark == '0') {
        DocumentTypeNameFilterCopy.splice(i, 1)
      }
    }
    DocumentTypeNameFilterCopy = DocumentTypeNameFilterCopy.sort(function (a, b) { return parseInt(b.remark) - parseInt(a.remark) })



    fillSonContent(searchItems, searchItemList, 'search', 'defaultValue')
    const searchList = () => {
      emit('deliverSearchItems', searchItems)
    }

    const reSearch = () => {
      searchList()
    }
    //#endregion

    //#region 数据提供区
    provide('tableTitle', headName)
    //#endregion

    let docTypeChoose = ref('')

    let docTimeChoose = ref('')

    const setDocType = (type) => {
      docTypeChoose.value = type
      searchItems['DocumentTypeName'] = type
    }

    const setDocTime = (type) => {
      docTimeChoose.value = type
      if(type == 'one'){
        searchItems['DocumentDateH'] = ChangeYMD(new Date())
        searchItems['DocumentDateL'] = ChangeYMD(new Date())
      }
      if(type == 'three'){
        searchItems['DocumentDateH'] = ChangeYMD(new Date())
        searchItems['DocumentDateL'] = ChangeYMD(addDate(new Date(),-2))
      }
      if(type == 'seven'){
        searchItems['DocumentDateH'] = ChangeYMD(new Date())
        searchItems['DocumentDateL'] = ChangeYMD(addDate(new Date(),-6))
      }
      if(type == 'thirty'){
        searchItems['DocumentDateH'] = ChangeYMD(new Date())
        searchItems['DocumentDateL'] = ChangeYMD(addDate(new Date(),-30))
      }
    }

    setDocTime('one')



    return {
      activeInput,
      setActiveId,
      searchItems,
      searchItemList,
      searchRef,
      openSearchSetting,
      searchList,
      reSearch,
      DocumentTypeNameFilterCopy,
      docTypeChoose,
      docTimeChoose,
      setDocType,
      setDocTime,
    }
  },
  components: {
    SelectPopModel,
    DataSpan
  }
}
</script>

<style scoped lang="less">
@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;
@lableMarginLeft: 0.625rem;
@lableHeight: 2rem;
@inputHeight: 1.5rem;
@mainColor: #4285f4;

.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.searchinputLabel {
  margin-left: @lableMarginLeft;
  font-size: 0.8rem;
  height: @lableHeight;
  line-height: @lableHeight;
}
.searchinput {
  margin-top: calc((@lableHeight - @inputHeight) / 2);
  margin-bottom: calc((@lableHeight - @inputHeight) / 2);
  font-size: 0.9rem;
  border: none;
  outline: none;
  height: @inputHeight;
  line-height: @inputHeight;
}
.ActiveBox {
  border: 1px solid #ffa004 !important;
}

.searchInputBox {
  height: @lableHeight;
  border: 1px solid #c9c9c9;
  margin-left: @lableMarginLeft;
  border-radius: 0.31rem;
}

.searchItemContainer {
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
  display: flex;
}

.FuncButton {
  height: @lableHeight;
  line-height: @lableHeight;
  margin-left: calc(@lableMarginLeft*2);
  width: 4rem;
  background-color: #f6f6f6;
  text-align: center;
  border: 1px solid #c9c9c9;
  cursor: pointer;
  font-weight: 700;
  border-radius: 4px;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}
.FuncButton:hover {
  background-color: #f08300;
  color: #fff;
}

.searchSetting {
  margin-left: calc(@lableMarginLeft*3);
  height: @lableHeight;
  display: flex;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}
.searchSetting:hover {
  cursor: pointer;
  color: @mainColor;
}
.settingIcon {
  font-family: "icomoon";
  font-size: 1.1rem;
  line-height: @lableHeight;
}

.settingText {
  margin-left: 0.3rem;
  font-size: 0.8rem;
  line-height: @lableHeight;
}
.dateInputContainer {
  display: flex;
}
.quickWordsBox {
  height: 2rem;
  margin-right: 5rem;
  margin-left: 1rem;
}

.quickWords {
  line-height: 2rem;
  font-size: 0.8rem;
  text-decoration: underline;
}
.heightLight {
  color: #4285f4;
  font-weight: 700;
}

.quickWords:hover {
  cursor: pointer;
}
</style>