<template>
  <div class="bigContainer">
    <div class="titleLayer">
      <div class="titleBox">利润报表</div>
    </div>
    <div class="searchLayer">
      <div class="block">
        <span class="demonstration" v-show="managedCompanysFilter.length > 1">选择公司:</span>
        <el-select v-model="companyChoosed" class="m-2" placeholder="Select" size="small" v-show="managedCompanysFilter.length > 1" @change="refreshProfit()">
          <el-option v-for="item in managedCompanysFilter" :key="item.beforeTransfer + 'UnCheckAmount'" :label="item.afterTransfer" :value="item.beforeTransfer" />
        </el-select>

        <span class="demonstration">选择月份:</span>
        <el-date-picker v-model="monthChoose" type="month" placeholder="选择查看月份" @change="confirmTimeSpan()">
        </el-date-picker>
      </div>
    </div>
    <div class="searchLayer">
      <div class="block2">
        <div class="profitBox" :class="{ActiveChoose:!staffChoose}" @click="changeToTotal()">
          <span class="totalWords">{{monthChoose.getMonth() + 1}}月总计:</span><span class="totalProfitNumber">{{profitBoysCopy.reduce((x,y)=>x + +y.amount,0)}}</span>
        </div>
        <div class="profitBox" v-for="item in StaffList" :class="{ActiveChoose:staffChoose == item.beforeTransfer}" :key="item.beforeTransfer + 'staffProfitShow'" @click="saveActiveStaff(item.beforeTransfer)" v-show="$store.state.loginInitializeData.companyId == companyChoosed">
          <span class="totalWords">{{item.afterTransfer}}:</span><span class="totalProfitNumber">{{profitBoysCopy.filter(z=>z.StaffId == item.beforeTransfer).reduce((x,y)=>x + +y.amount,0)}}</span>
        </div>
        <div class="profitBox">
          <span class="refreshIcon" @click="refreshProfit()"></span>
        </div>
      </div>
    </div>
    <div class="detailShowLayer">
      <div class="BigTable" v-show="timeSpan">
        <div class="TableContentArea">
          <table class="tablehead">
            <tr class="headtr" :style="{ height : trHeight }">
              <th :style="{width:'8rem'}" class="noWarp">利润类型</th>
              <th class="numberContainer" v-for="item in timeSpan" :key="item + 'profitDateTr'">{{item}}日</th>
            </tr>
            <tr class="bodytr" v-for="item2 in ProfitTypeList" :key="item2.afterTransfer + 'profitDateTd'" v-show="docArrForShow.indexOf(item2.afterTransfer) > -1">
              <td class="firstTd noWarp" :style="{width:'8rem'}">{{item2.afterTransfer}}</td>
              <td class="contentTd" v-for="item3 in timeSpan" :key="item3 + 'profitDateTd'" @click="openDetail(item2,item3)">{{profitBoys.filter(x=>x.date == item3)?.filter(y=>y.profitType == item2.afterTransfer)?.reduce((x,y)=>x + +y.amount,0) || ''}}</td>
            </tr>
            <tr class="bodytr">
              <td :style="{width:'8rem'}" class="noWarp">合计:</td>
              <td v-for="item4 in timeSpan" :key="item4 + 'profitDateTd'">{{profitBoys.filter(x=>x.date == item4)?.reduce((x,y)=>x + +y.amount,0) || ''}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <BigPopModel ref="detailRef">
      <div class="DetailTable">
        <div>
          <table>
            <tr class="headtr">
              <th>
                <div :style="{width:popTableWidth.DocumentId}">单据编号</div>
              </th>
              <th>
                <div :style="{width:popTableWidth.CommodityName}">商品名称</div>
              </th>
              <th>
                <div :style="{width:popTableWidth.Digital}">主营收入</div>
              </th>
              <th>
                <div :style="{width:popTableWidth.Digital}">主营成本</div>
              </th>
              <th>
                <div :style="{width:popTableWidth.Digital}">其他收益</div>
              </th>
              <th>
                <div :style="{width:popTableWidth.Digital}">其他成本</div>
              </th>
              <th>
                <div :style="{width:popTableWidth.Digital}">利润</div>
              </th>
              <th>
                <div :style="{width:popTableWidth.StaffName}">工程师</div>
              </th>
              <th>
                <div :style="{width:popTableWidth.Operation}">操作</div>
              </th>
            </tr>
          </table>
        </div>
        <div class="tablebodyarea">
          <table class="tablebody">
            <tr class="bodytr" v-for="item in profitDetailList" :key="item">
              <td>
                <div :style="{width:popTableWidth.DocumentId}">{{item.DocumentId}}</div>
              </td>
              <td>
                <div :style="{width:popTableWidth.CommodityName}">{{item.CommodityName}}</div>
              </td>
              <td>
                <el-tooltip :style="[{width:popTableWidth.Digital}]" class="box-item" effect="dark" :hide-after="0" :disabled="!item?.A1001?.Remark" :content="item?.A1001?.Remark || ''" placement="top-start">
                  <el-button>{{item?.A1001?.value.toFixed(0) || 0}}</el-button>
                </el-tooltip>
              </td>
              <td>
                <el-tooltip :style="[{width:popTableWidth.Digital}]" class="box-item" effect="dark" :hide-after="0" :disabled="!item?.A1014?.Remark" :content="item?.A1014?.Remark || ''" placement="top-start">
                  <el-button>{{-item?.A1014?.value.toFixed(0) || 0}}</el-button>
                </el-tooltip>
              </td>
              <td>
                <el-tooltip :style="[{width:popTableWidth.Digital}]" class="box-item" effect="dark" :hide-after="0" :disabled="!item?.OtherIncome?.Remark" :content="item?.OtherIncome?.Remark || ''" placement="top-start">
                  <el-button>{{item?.OtherIncome?.value.toFixed(0) || 0}}</el-button>
                </el-tooltip>
              </td>
              <td>
                <el-tooltip :style="[{width:popTableWidth.Digital}]" class="box-item" effect="dark" :hide-after="0" :disabled="!item?.OtherSpend?.Remark" :content="item?.OtherSpend?.Remark || ''" placement="top-start">
                  <el-button>{{-item?.OtherSpend?.value.toFixed(0) || 0}}</el-button>
                </el-tooltip>
              </td>
              <td>
                <div :style="{width:popTableWidth.Digital}">{{item.Sum.toFixed(0)}}</div>
              </td>
              <td>
                <div :style="{width:popTableWidth.StaffName}">{{item.StaffName}}</div>
              </td>
              <td>
                <div :style="{width:popTableWidth.Operation}"><span class="spanedit" @click="changePerson(item)">修改人员</span>
                  <span class="spanedit" @click="openSharePop(item)">分享利润</span>
                </div>
              </td>
            </tr>
            <tr class="footTr">
              <td>
                <div :style="{width:popTableWidth.DocumentId}">合计</div>
              </td>
              <td>
                <div :style="{width:popTableWidth.CommodityName}"></div>
              </td>
              <td>
                <div :style="{width:popTableWidth.Digital}"></div>
              </td>
              <td>
                <div :style="{width:popTableWidth.Digital}"></div>
              </td>
              <td>
                <div :style="{width:popTableWidth.Digital}"></div>
              </td>
              <td>
                <div :style="{width:popTableWidth.Digital}"></div>
              </td>
              <td>
                <div :style="{width:popTableWidth.Digital}">{{profitDetailList.reduce((x,y)=>x + +y.Sum,0).toFixed(0) || ''}}</div>
              </td>
              <td>
                <div :style="{width:popTableWidth.StaffName}"></div>
              </td>
              <td>
                <div :style="{width:popTableWidth.Operation}"></div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </BigPopModel>
    <PopModel ref="changeStaffRef">
      <div class="inputBox">
        <label class="popLable">原工程师:</label>
        <el-input type="text" class="popInput" v-model="oldPerson" disabled="disabled" />
      </div>
      <div class="inputBox">
        <label class="popLable" for="bookPersonMachineMarket">修改对象:</label>
        <el-select class="m-2 popInput" placeholder="请选择修改对象" v-model="staffTobeChange">
          <el-option v-for="item in StaffList" :key="item.beforeTransfer + 'ProfitChange'" :label="item.afterTransfer" :value="item.beforeTransfer">
          </el-option>
        </el-select>
      </div>
      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight" @click="closechangeStaffRef">取消</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="sendChangeApply()">确认变更</el-button>
      </div>
    </PopModel>

    <PopModel ref="shareProfitRef">
      <div class="inputBox">
        <label class="popLable">原工程师:</label>
        <el-input type="text" class="popInput" disabled="disabled" v-model="shareProfitObj.oldPerson" />
      </div>
      <div class="inputBox">
        <label class="popLable">利润总额:</label>
        <el-input type="number" class="popInput" disabled="disabled" v-model="shareProfitObj.totalAmount" />
      </div>
      <div class="inputBox">
        <label class="popLable">分享对象:</label>
        <el-select class="m-2 popInput" placeholder="请选择修改对象" v-model="shareProfitObj.shareTarget">
          <el-option v-for="item in StaffList" :key="item.beforeTransfer + 'ProfitShare'" :label="item.afterTransfer" :value="item.beforeTransfer">
          </el-option>
        </el-select>
      </div>
      <div class="inputBox">
        <label class="popLable">分享金额:</label>
        <el-input type="number" class="popInput" v-model="shareProfitObj.shareAmount" />
      </div>
      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight" @click="closeSharePop()">取消</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="shareProfit()">确认分享</el-button>
      </div>
    </PopModel>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, provide, reactive, ref, watch } from 'vue-demi'
import { ChangeYMD, copyArray, copyObj, getDateTime } from '@/hooks/tools'
import { getProfitList, getProfitListByStaff } from '@/hooks/getCache'
import { changeToViewCode, getDetailProfitList, transferToCode, transferToName, updateProfitPerByDocId, updateProfitPerBySourDocId, checkSalarySettle, getBodyInstance, addProfit } from '@/hooks/OpenOrder'
import BigPopModel from '@/components/Common/BigPopModel.vue'
import PopModel from '@/components/Common/PopModel.vue'
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
export default {
  components: {
    BigPopModel,
    PopModel
  },
  setup() {
    const store = useStore();
    const ProfitTypeList = copyArray(store.state.loginInitializeData.filterList.ProfitType)

    const StaffList = copyArray(store.state.loginInitializeData.filterList.StaffId)

    const managedCompanysFilter = copyArray(store.state.loginInitializeData.filterList.ManagedCompanys)

    const length1 = StaffList.length
    const userName = store.state.loginInitializeData.userName
    if (userName != '管理员') {
      for (let i = length1 - 1; i >= 0; i--) {
        if (StaffList[i].afterTransfer == '管理员') {
          StaffList.splice(i)
        }
      }
    }

    const buttonAutoList = store.state.loginInitializeData.authList.buttonVisibleManages.filter(x => x.viewCode == 100102)

    for (let item of buttonAutoList) {
      item.profitFilter = item.buttonId.substring(10, item.buttonId.length)
    }

    const cutUnAuthorized = () => {
      for (let i = ProfitTypeList.length - 1; i >= 0; i--) {
        if (!buttonAutoList.find(x => x.profitFilter == ProfitTypeList[i].beforeTransfer)) {
          continue
        }
        else {
          if (!buttonAutoList.find(x => x.profitFilter == ProfitTypeList[i].beforeTransfer).visibleOrNot) {
            ProfitTypeList.splice(i, 1)
            continue
          }
        }
      }
    }

    cutUnAuthorized()

    let profitBoys = reactive([])

    provide('tableTitle', '详细利润列表')

    let profitBoysCopy = reactive([])

    let staffChoose = ref('')

    let docArrForShow = reactive([])

    let companyChoosed = ref('')

    companyChoosed.value = parseInt(store.state.loginInitializeData.companyId)

    const getAndSortProfit = (dateL, dateH) => {
      const ProfitTypeListCopy = copyArray(store.state.loginInitializeData.filterList.ProfitType)
      getProfitListByStaff(dateL, dateH, companyChoosed.value).then(res => {
        let profitBodyForStaff = res
        for (let item of profitBodyForStaff) {

          let docTpye1 = ''
          if (item.sortTarget.length > 22) {
            docTpye1 = item.sortTarget.substring(27)?.toUpperCase()
          }
          else {
            docTpye1 = item.sortTarget.substring(18)?.toUpperCase()
          }
          if (!docTpye1) {
            item.profitType = '等待分配'
          }
          else {
            item.profitType = ProfitTypeListCopy.find(x => x.beforeTransfer == docTpye1)?.afterTransfer || '等待分配'
          }
          if (docArrForShow.indexOf(item.profitType) <= -1) {
            docArrForShow.push(item.profitType)
          }

          let staffId = ''
          if (item.sortTarget.length > 22) {
            staffId = item.sortTarget.substring(18, 27)
          }
          item.StaffId = staffId

          item.date = new Date(item.documentDate).getDate()
          item.amount = item.amount.toFixed(0)
        }

        profitBoysCopy.splice(0)
        profitBoys.splice(0)

        const len3 = profitBodyForStaff.length

        for(let i = len3 -1;i>=0;i--){
          if(!ProfitTypeList.find(x=>x.afterTransfer == profitBodyForStaff[i].profitType)){
            profitBodyForStaff.splice(i,1)
          }
        }



        for (let item of profitBodyForStaff) {
          let objNew = {};
          for (let vl in item) {
            objNew[vl] = item[vl]
          }
          profitBoysCopy.push(objNew)
          profitBoys.push(objNew)
        }

      }).catch(err => {

        ElMessage(err)
      })
    }

    const monthChoose = ref('')

    let timeSpan = ref(0)

    const confirmTimeSpan = () => {
      const dateL = ChangeYMD(monthChoose.value)
      const monthL = monthChoose.value?.getMonth() + 1
      const yearL = monthChoose.value?.getFullYear()
      const dateH = ChangeYMD(new Date(yearL, monthL, 0))
      timeSpan.value = new Date(yearL, monthL, 0).getDate()
      
      getAndSortProfit(dateL, dateH)
    }
    let trHeight = ref('1.875rem')
    const saveActiveStaff = (staffId) => {
      staffChoose.value = staffId
      profitBoys.splice(0)
      for (let item of profitBoysCopy) {
        if (item.StaffId == staffId) {
          profitBoys.push(item)
        }
      }
    }

    const changeToTotal = () => {
      staffChoose.value = ''
      profitBoys.splice(0)
      for (let item of profitBoysCopy) {
        profitBoys.push(item)
      }
    }

    let activeDate = ''

    const code = changeToViewCode('ProfitShow')

    const profitDetailList = reactive([

    ])

    const profitTypeChoose = ref('')

    let activeLine = ref('')

    const openDetail = (value1, value2) => {
      if (companyChoosed.value != store.state.loginInitializeData.companyId) {
        ElMessage('母公司暂时无法查看细节,请进入子公司账号中查看')
        return
      }

      activeDate = ''

      activeLine.value = value1.afterTransfer

      if (!profitBoys.filter(x => x.date == value2)?.filter(y => y.profitType == value1.afterTransfer)?.reduce((x, y) => x + +y.amount, 0)) {
        return;
      }
      profitTypeChoose.value = value1.beforeTransfer

      let dateChoose = ChangeYMD(new Date(monthChoose.value.getFullYear(), monthChoose.value.getMonth(), value2))
      activeDate = dateChoose

      detailRef.value.openPopComm()

      getDetailProfitList(dateChoose, profitTypeChoose.value, staffChoose.value).then(res => {
        profitDetailList.splice(0)
        if (value1.afterTransfer == '等待分配') {
          for (let item of res) {
            let b = {}
            b['DocumentId'] = item.documentId
            b['CommodityName'] = item.commodityName
            b['StaffName'] = transferToName(item.staffId, 'StaffId')
            if (item.unitPrice * item.qty > 0) {
              b['A1001'] = {}
              b['A1001'].value = item.unitPrice * item.qty
              b['A1001'].Remark = item.remark
            }
            else {
              b['A1014'] = {}
              b['A1014'].value = item.unitPrice * item.qty
              b['A1014'].Remark = item.remark
            }
            profitDetailList.push(b)
          }
        }
        else {
          for (let item of res) {
            const staffName = transferToName(item.staffId, 'StaffId')
            if (profitDetailList.find(x => x.DocumentId == item.sourceDocumentId && x.StaffName == staffName)) {
              if (profitDetailList.find(x => x.DocumentId == item.sourceDocumentId && x.StaffName == staffName)['A' + item.accountingSubjectId]) {
                profitDetailList.find(x => x.DocumentId == item.sourceDocumentId && x.StaffName == staffName)['A' + item.accountingSubjectId].value += (item.unitPrice * item.qty)
                profitDetailList.find(x => x.DocumentId == item.sourceDocumentId && x.StaffName == staffName)['A' + item.accountingSubjectId].Remark += ('\n' + item.remark)
              }
              else {
                profitDetailList.find(x => x.DocumentId == item.sourceDocumentId && x.StaffName == staffName)['A' + item.accountingSubjectId] = {}
                profitDetailList.find(x => x.DocumentId == item.sourceDocumentId && x.StaffName == staffName)['A' + item.accountingSubjectId].value = (item.unitPrice * item.qty)
                profitDetailList.find(x => x.DocumentId == item.sourceDocumentId && x.StaffName == staffName)['A' + item.accountingSubjectId].Remark = item.remark
              }
              // if (!profitDetailList.find(x => x.DocumentId == item.sourceDocumentId)['CommodityName']) {
              if (profitDetailList.find(x => x.DocumentId == item.sourceDocumentId && x.StaffName == staffName)['CommodityName'].indexOf(item.commodityName) <= -1) {
                profitDetailList.find(x => x.DocumentId == item.sourceDocumentId && x.StaffName == staffName)['CommodityName'] += ('\n' + item.commodityName)
              }

              // }
              if (!profitDetailList.find(x => x.DocumentId == item.sourceDocumentId && x.StaffName == staffName)['StaffName']) {
                profitDetailList.find(x => x.DocumentId == item.sourceDocumentId && x.StaffName == staffName)['StaffName'] = transferToName(item.staffId, 'StaffId')
              }
            }
            else {
              let b = {}
              b['DocumentId'] = item.sourceDocumentId
              b['CommodityName'] = item.commodityName
              b['StaffName'] = transferToName(item.staffId, 'StaffId')
              b['A' + item.accountingSubjectId] = {}
              b['A' + item.accountingSubjectId].value = item.unitPrice * item.qty
              b['A' + item.accountingSubjectId].Remark = item.remark
              profitDetailList.push(b)
            }
          }

          for (let item of profitDetailList) {
            item.Sum = 0
            item.OtherSpend = {}
            item.OtherIncome = {}
            item.OtherSpend.value = 0
            item.OtherIncome.value = 0
            for (let key in item) {
              if (item[key].value && key != 'OtherSpend' && key != 'OtherIncome') {
                item.Sum += item[key].value

              }
              if (item[key]?.value) {
                if (key != 'A1001' && key != 'A1014' && item[key]?.value > 0 && key != 'OtherSpend' && key != 'OtherIncome') {
                  item.OtherIncome.value += item[key]?.value
                  if (!item.OtherIncome.Remark) {
                    item.OtherIncome.Remark = item[key].Remark
                  }
                  else {
                    item.OtherIncome.Remark += ('\n' + item[key].Remark)
                  }
                }
                if (key != 'A1001' && key != 'A1014' && item[key]?.value < 0 && key != 'OtherSpend' && key != 'OtherIncome') {
                  item.OtherSpend.value += item[key]?.value
                  if (!item.OtherSpend.Remark) {
                    item.OtherSpend.Remark = item[key].Remark
                  }
                  else {
                    item.OtherSpend.Remark += ('\n' + item[key].Remark)
                  }
                }
              }
            }
          }
        }
      })
    }

    const detailRef = ref(null)

    const changeStaffRef = ref(null)

    const shareProfitRef = ref(null)

    let oldPerson = ref('')

    let staffTobeChange = ref('')

    let activeToBeChangeLineData = reactive({})

    const changePerson = (value) => {
      oldPerson.value = value.StaffName
      activeToBeChangeLineData = {}
      activeToBeChangeLineData = copyObj(value)
      changeStaffRef.value.openPopComm()
    }

    const closechangeStaffRef = () => {
      changeStaffRef.value.closePopComm()
    }

    let shareProfitObj = reactive({
      documentId: '',
      oldPerson: '',
      totalAmount: 0,
      shareTarget: '',
      shareAmount: 0
    })

    const openSharePop = (value) => {
      shareProfitObj.documentId = value.DocumentId
      shareProfitObj.oldPerson = value.StaffName
      shareProfitObj.totalAmount = value.Sum
      shareProfitObj.shareTarget = ''
      shareProfitObj.shareAmount = 0
      shareProfitRef.value.openPopComm()
    }

    const shareProfit = () => {
      if (shareProfitObj.shareTarget == '') {
        ElMessage('未选定分享人员')
        return;
      }
      if (shareProfitObj.shareAmount == 0) {
        ElMessage('未填写分享金额')
        return;
      }
      if (transferToCode(shareProfitObj.oldPerson, 'StaffId') == shareProfitObj.shareTarget) {
        ElMessage('分享人员与原人员一样')
        return;
      }
      checkSalarySettle(shareProfitObj.documentId).then(res => {
        if (res != 'pass') {
          ElMessage(res)
          return;
        }
        else {
          let profitBody1 = getBodyInstance('AccountsProfitTable')
          let profitBody2 = getBodyInstance('AccountsProfitTable')
          profitBody1.DocumentId = shareProfitObj.documentId
          profitBody2.DocumentId = shareProfitObj.documentId
          profitBody1.Sn = 1
          profitBody2.Sn = 1
          profitBody1.CompanyId = store.state.loginInitializeData.companyId
          profitBody2.CompanyId = store.state.loginInitializeData.companyId
          profitBody1.AccountingSubjectId = shareProfitObj.shareAmount > 0 ? 1014 : 1001
          profitBody2.AccountingSubjectId = shareProfitObj.shareAmount > 0 ? 1001 : 1014
          profitBody1.Qty = shareProfitObj.shareAmount > 0 ? -1 : 1
          profitBody2.Qty = shareProfitObj.shareAmount > 0 ? 1 : -1
          profitBody1.UnitPrice = shareProfitObj.shareAmount > 0 ? shareProfitObj.shareAmount : -shareProfitObj.shareAmount
          profitBody2.UnitPrice = shareProfitObj.shareAmount > 0 ? shareProfitObj.shareAmount : -shareProfitObj.shareAmount
          profitBody1.Remark = shareProfitObj.shareAmount > 0 ? `利润分享给${transferToName(shareProfitObj.shareTarget, 'StaffId')},金额为${shareProfitObj.shareAmount > 0 ? shareProfitObj.shareAmount : -shareProfitObj.shareAmount}` : `向${transferToName(shareProfitObj.shareTarget, 'StaffId')}索取利润,金额为${shareProfitObj.shareAmount > 0 ? shareProfitObj.shareAmount : -shareProfitObj.shareAmount}`
          profitBody2.Remark = shareProfitObj.shareAmount > 0 ? `向${shareProfitObj.oldPerson}索取利润,金额为${shareProfitObj.shareAmount > 0 ? shareProfitObj.shareAmount : -shareProfitObj.shareAmount}` : `利润分享给${shareProfitObj.oldPerson},金额为${shareProfitObj.shareAmount > 0 ? shareProfitObj.shareAmount : -shareProfitObj.shareAmount}`
          profitBody1.SourceDocumentId = shareProfitObj.documentId
          profitBody2.SourceDocumentId = shareProfitObj.documentId
          profitBody1.StaffId = transferToCode(shareProfitObj.oldPerson, 'StaffId')
          profitBody2.StaffId = shareProfitObj.shareTarget
          profitBody1.SalarySettled = false
          profitBody2.SalarySettled = false
          profitBody1.DocumentDate = activeDate
          profitBody2.DocumentDate = activeDate
          let job1 = addProfit(profitBody1)
          let job2 = addProfit(profitBody2)
          let jobArr = []
          jobArr.push(job1)
          jobArr.push(job2)
          Promise.all(jobArr).then(res => {
            ElMessage('分享成功')
            shareProfitRef.value.closePopComm()
            detailRef.value.closePopComm()
            refreshProfit()
            return;
          })
        }
      })
    }

    const closeSharePop = () => {
      shareProfitObj.documentId = ''
      shareProfitObj.oldPerson = ''
      shareProfitObj.totalAmount = 0
      shareProfitObj.shareTarget = ''
      shareProfitObj.shareAmount = 0
      shareProfitRef.value.closePopComm()
    }

    const sendChangeApply = () => {
      if (oldPerson.value == transferToName(staffTobeChange.value, 'StaffId')) {
        ElMessage('人员未变更')
        return;
      }
      if (activeLine.value == '等待分配') {
        updateProfitPerByDocId(activeToBeChangeLineData.DocumentId, staffTobeChange.value).then(res => {
          if (res == "pass") {
            ElMessage('修改成功')
            changeStaffRef.value.closePopComm()
            detailRef.value.closePopComm()
            refreshProfit()
            return;
          }
          else {
            ElMessage(res)
            return;
          }
        })
      }
      else {
        updateProfitPerBySourDocId(activeToBeChangeLineData.DocumentId, staffTobeChange.value).then(res => {
          if (res == "pass") {
            ElMessage('修改成功')
            changeStaffRef.value.closePopComm()
            detailRef.value.closePopComm()
            refreshProfit()
            return;
          }
          else {
            ElMessage(res)
            return;
          }
        })
      }
    }

    const refreshProfit = () => {
      if (!monthChoose.value) {
        const now = new Date();
        const nowMonth = now.getMonth(); //当前月 
        const nowYear = now.getFullYear(); //当前年 
        //本月的开始时间
        const monthStartDate = new Date(nowYear, nowMonth, 1);
        //本月的结束时间
        const monthEndDate = new Date(nowYear, nowMonth + 1, 0);
        timeSpan.value = new Date(nowYear, nowMonth + 1, 0).getDate()
        monthChoose.value = monthStartDate
        getAndSortProfit(monthStartDate, monthEndDate)
      }
      else {
        const dateL = ChangeYMD(monthChoose.value)
        const monthL = monthChoose.value.getMonth() + 1
        const yearL = monthChoose.value.getFullYear()
        const dateH = ChangeYMD(new Date(yearL, monthL, 0))
        timeSpan.value = new Date(yearL, monthL, 0).getDate()
        getAndSortProfit(dateL, dateH)
      }
    }

    refreshProfit()

    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          refreshProfit()
        }
      }
    )

    let popTableWidth = {
      DocumentId: '16rem',
      CommodityName: '30rem',
      Digital: '6rem',
      StaffName: '6rem',
      Operation: '6rem'
    }


    onUnmounted(() => {

    })
    //#endregion

    return {
      monthChoose, closechangeStaffRef,
      confirmTimeSpan, shareProfitRef,
      trHeight, openSharePop, shareProfitObj,
      timeSpan, closeSharePop, shareProfit,
      profitBoys,
      StaffList,
      staffChoose,
      saveActiveStaff,
      changeToTotal,
      profitBoysCopy,
      refreshProfit,
      openDetail,
      detailRef,
      ProfitTypeList,
      profitDetailList,
      profitTypeChoose, companyChoosed,
      popTableWidth, changeStaffRef, docArrForShow, changePerson,
      staffTobeChange, oldPerson, sendChangeApply, managedCompanysFilter

    }
  }
}
</script>

<style scoped lang="less">
@lineHeight: 1.875rem;
.bigContainer {
  width: 98%;
  margin-left: 1%;
  height: 100%;
}
.searchLayer {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.demonstration {
  margin-right: 0.5rem;
  margin-left: 2rem;
}
.block {
  margin-top: 1rem;
  border: 1px solid #cccccc;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.block2 {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #cccccc;
  padding-left: 2rem;
  // height: 4rem;
  line-height: 4rem;
}

.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 60%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
  font-size: 0.8rem;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
  height: 1.875rem;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
  height: 30rem;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.bodytr {
  text-align: center;
}
// .bodytr {
//   height: 1.5rem;
// }
.bodytr input {
  border: none;
  outline: none;
  height: @lineHeight;
  background-color: transparent;
  text-align: center;
}
.bodytr input:focus {
  border: 1px solid #ffa004;
}
.tablefoot {
  margin-top: -1px;
}
.tablefoot {
  text-align: center;
}
.numberContainer {
  width: 4rem;
}
.tablehead tr:last-child {
  background: #ffe699;
}

.titleBox {
  height: 3rem;
  line-height: 3rem;
  padding-left: 2rem;
  font-weight: 700;
  font-size: 1.2rem;
}
.profitBox {
  width: 10rem;
}

.totalWords {
  font-size: 1rem;
  font-weight: 400;
  padding-right: 0.3rem;
}

.totalProfitNumber {
  font-size: 0.9rem;
  font-weight: 700;
}

.ActiveChoose {
  font-weight: 700 !important;
  color: #1e87f0 !important;
}
.profitBox:hover {
  cursor: pointer;
  transform: translateY(-1px);
}

.refreshIcon {
  font-family: "icomoon";
  font-size: 1.6rem;
  font-weight: 700;
  padding-left: 2rem;
  color: #1e87f0;
  transform: translateY(-1px);
}
.refreshIcon:hover {
  cursor: pointer;
}

.contentTd:hover {
  cursor: pointer;
  color: #409eff;
  font-weight: 700;
}
.DetailTable {
  text-align: center;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.bodytr {
  text-align: center;
}
.bodytr {
  height: @lineHeight;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablehead tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.footTr {
  height: @lineHeight;
  background-color: #fcf7b6;
}
.remarkCur:hover {
  cursor: pointer;
  color: #409eff;
  font-weight: 700;
}
.box-item {
  border: none;
  background-color: transparent;
}
.firstTd {
  font-weight: 700;
}
.popLable {
  margin-top: 0.5rem;
  line-height: 2.5rem;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 0.5rem;
}
.popInput {
  margin-top: 0.5rem;
  width: 28rem;
}
.buttonBox {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  justify-content: flex-end;
}
.mRight {
  margin-top: 0.1rem;
}
.lastButton {
  margin-right: 0.2rem;
}
.noWarp {
  word-break: keep-all;
}
</style>