<template>
  <div class="tableLoaderContainer">
    <div class="BodyTitle">
      <div class="BodyTitleText">采购在途管理</div>
    </div>
    <div class="searchContentContainer">
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="StartTypeForSalesOnTheWay">发起类型:</label>
        <div class="searchInputBox" :class="{ 'ActiveBox': activeInput == 'StartType' }"><select
            v-model="SearchContent.StartType" class="searchinput" id="StartTypeForSalesOnTheWay"
            @focus="setActiveId('StartType')" @blur="setActiveId('')" @change="reSearch()">
            <option value="">所有</option>
            <option v-for="item in OnTheWayTypeList" :key="item.beforeTransfer + 'OnTheWay'" :value="item.beforeTransfer">
              {{ item.afterTransfer }}</option>
          </select></div>
      </div>

      <div class="searchItemContainer">
        <label class="searchinputLabel" for="SupplierToBeSolveFSForSalesOnTheWay">处理情况:</label>
        <div class="searchInputBox" :class="{ 'ActiveBox': activeInput == 'SupplierToBeSolveFS' }"><select
            v-model="SearchContent.SupplierToBeSolveFS" class="searchinput" id="SupplierToBeSolveFSForSalesOnTheWay"
            @focus="setActiveId('SupplierToBeSolveFS')" @blur="setActiveId('')" @change="reSearch()">
            <option v-for="item in SupplierToBeSolveFSFilter" :key="item.beforeTransfer + 'SalesOnTheWay'"
              :value="item.beforeTransfer">{{ item.afterTransfer }}</option>
          </select></div>
      </div>

      <div class="searchItemContainer">
        <label class="searchinputLabel" for="PartBCompanyIdForSalesOnTheWay">客户:</label>
        <div class="searchInputBox" :class="{ 'ActiveBox': activeInput == 'PartBCompanyId' }"><select
            v-model="SearchContent.PartBCompanyId" class="searchinput" id="PartBCompanyIdForSalesOnTheWay"
            @focus="setActiveId('PartBCompanyId')" @blur="setActiveId('')" @change="reSearch()">
            <option value="" selected="selected">所有</option>
            <option v-for="item in partBFilterCopy" :key="item.beforeTransfer + 'SalesOnTheWay1'"
              :value="item.beforeTransfer">{{ item.afterTransfer }}</option>
          </select></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DocumentDateLSalesOnTheWay">发起日期</label>
        <div class="searchInputBox" :class="{ 'ActiveBox': activeInput == 'DocumentDateL' }"><input
            v-model="SearchContent.DocumentDateL" class="searchinput" id="DocumentDateLSalesOnTheWay" type="date"
            @focus="setActiveId('DocumentDateL')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DocumentDateHSalesOnTheWay" v-text="'---'"></label>
        <div class="searchInputBox" :class="{ 'ActiveBox': activeInput == 'DocumentDateH' }"><input
            v-model="SearchContent.DocumentDateH" class="searchinput" id="DocumentDateHSalesOnTheWay" type="date"
            @focus="setActiveId('DocumentDateH')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="CommodityNameSalesOnTheWay">商品名称:</label>
        <div class="searchInputBox" :class="{ 'ActiveBox': activeInput == 'CommodityName' }"><input
            v-model="SearchContent.CommodityName" class="searchinput long" id="CommodityNameSalesOnTheWay" type="text"
            @focus="setActiveId('CommodityName')" @blur="setActiveId('')"></div>
      </div>

      <div class="searchItemContainer">
        <label class="searchinputLabel" for="CommodityIdSalesOnTheWay">商品编码:</label>
        <div class="searchInputBox" :class="{ 'ActiveBox': activeInput == 'CommodityId' }"><input
            v-model="SearchContent.CommodityId" class="searchinput" id="CommodityIdSalesOnTheWay" type="text"
            @focus="setActiveId('CommodityId')" @blur="setActiveId('')"></div>
      </div>

      <!-- 搜索模块 -->
      <div class="FuncButton" @click="searchList()">搜索</div>
      <div class="tipBox">
        <div class="tipColortodo"></div>
        <div class="tipWords">客户预定</div>
      </div>
      <div class="tipBox">
        <div class="tipColorAlert"></div>
        <div class="tipWords">下限报警</div>
      </div>
      <div class="tipBox">
        <div class="tipColorfinish"></div>
        <div class="tipWords">已处理</div>
      </div>
      <div class="tipBox">
        <div class="tipColorReject"></div>
        <div class="tipWords">已拒绝</div>
      </div>
      <div class="tipBox forClick" @click="searchList()">
        <div class="icon blueIcon"></div>
        <div class="refreshWords">刷新</div>
      </div>
    </div>

    <div class="BigTable">
      <div class="TableContentArea">
        <table class="tablehead">
          <tr class="headtr" :style="{ height: trHeight }">
            <th class="thSN">
              <div :style="{ width: SnWidth }"></div>
            </th>
            <th class="thselectall">
              <div :style="{ width: selectAllWidth }"><input class="selectAllCheckBox" type="checkbox"
                  v-model="selectAll" /></div>
            </th>
            <th>
              <div class="thoper" :style="{ width: commodityWidth }">销售商品</div>
            </th>
            <th class="headtdEdit">
              <div :style="{ width: stockWidth }">可销库存</div>
            </th>

            <th class="headtdEdit">
              <div :style="{ width: virtualWidth }">虚拟物品</div>
            </th>

            <th class="headtdEdit">
              <div :style="{ width: saleQtyWidth }">数量</div>
            </th>

            <th class="headtdEdit">
              <div :style="{ width: priceWidth }">销售价格</div>
            </th>

            <th>
              <div class="thoper" :style="{ width: operationWidth }">操作</div>
            </th>
            <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'head'"
              :class="{ ShowUpArrow: searchElement.SortFieldsName.toLowerCase() == value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == 1, ShowDownArrow: searchElement.SortFieldsName.toLowerCase() == value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == -1, }"
              @click.stop="ChangeSort(value1.fieldsNameForVali)">
              <div :style="{ width: value1.showLen + 'rem' }">{{ value1.showName }}</div>
            </th>
          </tr>
        </table>
        <div class="tablebodyarea" :style="{ width: tablewidth + 'rem', height: tbodyHeight }">
          <table class="tablebody">
            <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'td' + index2"
              :class="[{ todoLine: value2.supplierToBeSolve && value2.startType != '下限报警' }, { todoLineAlert: value2.supplierToBeSolve && value2.startType == '下限报警' }, { nottodoLine: !value2.supplierToBeSolve && value2.responsedQty > 0 }, { rejectLine: !value2.supplierToBeSolve && value2.refusedQty > 0 }]">
              <td>
                <div class="tdSN" :style="{ width: SnWidth }">{{+index2 + 1 }}</div>
              </td>
              <td class="tableselect">
                <div :style="{ width: selectAllWidth }"><input type="checkbox" :value="value2['onTheWayCode']"
                    v-model="selectedItems" /></div>
              </td>
              <td class="commodityTr">
                <div class="tableinputContainer" @click="setActiveInput(index2, 'commodityNameSale')"
                  @mousedown="saveEnterInValue(tableBody[index2]['commodityNameSale'])"><input type="text"
                    :class="{ 'highInput': tableBody[index2]['commodityId'].trim() == '' && tableBody[index2]['supplierToBeSolve'] }"
                    :disabled="!tableBody[index2]['supplierToBeSolve'] || tableBody[index2].startType == '下限报警' || tableBody[index2].responsedQty > 0"
                    :style="{ width: commodityWidth }" v-model="tableBody[index2]['commodityNameSale']"
                    @click="confirmOffset($event), bindEnter" @input="insertSearch"
                    @blur="confirmComName(index2, 'commodityNameSale'), unbindEnter()" placeholder="请输入物料名称"></div>
                <div class="CommodityContainer" v-show="index2 == activeInput.activeIndex && searchResult.length != 0"
                  :style="[{ top: commTrOffset }, { left: '0rem' }]">
                  <div class="searchContentTr" v-for="(searchitem, searchIndex) in searchResult"
                    @mousedown.prevent="solveSearchResult(searchIndex)" :key="searchitem + 'OnTheWayForSale'"><input
                      v-model="searchitem.commodityName" type="text"
                      :style="[{ width: commodityWidth }, { height: '1.625rem' }]"><input type="text" class="searchIndication"
                      v-model="searchitem.show"><input type="text" class="searchIndication"
                      v-model="searchitem.inventoryBalance"></div>
                  <div class="searchContentTrLast" :style="[{ height: '1.625rem' }, { lineHeight: '1.625rem' }]"
                    @mousedown.prevent="clearSearchResult()">关闭搜索</div>
                </div>
              </td>
              <td>
                <div><input type="number" step="0.01" :style="{ width: stockWidth }"
                    v-model="tableBody[index2]['stockQty']" :disabled="true"></div>
              </td>
              <td>
                <div class="checkContainer" :style="{ width: virtualWidth }"><input class="checkbo" type="checkbox"
                    :style="{ width: '1.25rem' }" v-model="tableBody[index2]['virtualGoods']" disabled="disabled"
                    @input="alertVirtual(index2, $event)"></div>
              </td>
              <td>
                <div><input type="number" :style="{ width: saleQtyWidth }" v-model="tableBody[index2]['saleQty']"
                    :class="{ 'highInput': tableBody[index2]['commodityNameSale'].trim() != '' && tableBody[index2]['supplierToBeSolve'] }"
                    :disabled="tableBody[index2]['commodityNameSale'] == '' || !tableBody[index2]['supplierToBeSolve']"
                    @blur="checkQty(index2, $event, 'saleQty')"></div>
              </td>
              <td>
                <div><input type="number" step="0.01" :style="{ width: priceWidth }"
                    v-model="tableBody[index2]['unitPrice']"
                    :class="{ 'highInput': tableBody[index2]['commodityNameSale'].trim() != '' && tableBody[index2]['supplierToBeSolve'] }"
                    :disabled="tableBody[index2]['commodityNameSale'] == '' || !tableBody[index2]['supplierToBeSolve'] || priceLock[value2.onTheWayCode]"
                    @blur="checkPrice(index2, $event)"></div>
              </td>
              <td>
                <div class="tdoper" :style="{ width: operationWidth }">
                  <span class="spantt" @click="actionTtOne(index2)"
                    v-show="tableBody[index2]['supplierToBeSolve'] && tableBody[index2]['ttJudge'] && tableBody[index2]['partBCompanyId']">调货</span>
                  <span class="spansale" @click="actionSaleOne(index2)"
                    v-show="tableBody[index2]['supplierToBeSolve'] && tableBody[index2]['partBCompanyId']">销售</span>
                  <span class="spancancel"
                    v-show="tableBody[index2]['supplierToBeSolve'] && tableBody[index2]['partBCompanyId']"
                    @click="openRejectPop(index2)">拒绝</span>
                  <span v-show="!tableBody[index2]['supplierToBeSolve']">已处理</span>
                </div>
              </td>
              <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
                <div :style="{ width: value3.showLen + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
              </td>
            </tr>
          </table>
        </div>
        <table class="tablefoot">
          <tr class="foottr" :style="{ height: trHeight }">
            <td>
              <div class="footsum" :style="{ width: SnWidth }">合计</div>
            </td>
            <td>
              <div :style="{ width: selectAllWidth }"></div>
            </td>
            <td>
              <div :style="{ width: commodityWidth }"></div>
            </td>
            <td>
              <div :style="{ width: stockWidth }"></div>
            </td>
            <td>
              <div :style="{ width: virtualWidth }"></div>
            </td>
            <td>
              <div :style="{ width: saleQtyWidth }"></div>
            </td>
            <td>
              <div :style="{ width: priceWidth }"></div>
            </td>
            <td>
              <div :style="{ width: operationWidth }"></div>
            </td>
            <td v-for="value4 in tableHead" :key="value4.fieldsNameForVali + 'foot'">
              <div :style="{ width: value4.showLen + 'rem' }"></div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="moreFunctionBox">
      <div class="selectBoxModel"><label for="outwarehouseForOntheWaySale">默认出库仓库:</label><select
          id="outwarehouseForOntheWaySale" v-model="defaultOutWarehouse" @change="updateDefault()">
          <option v-for="item in warehouseFilter" :key="item.beforeTransfer + 'OnthewaySale'"
            :value="item.beforeTransfer">{{ item.afterTransfer }}</option>
        </select></div>
      <el-button class="firstButton" type="primary" @click="fillSaleQty()">刷新库存并智能分配数量</el-button>
      <el-button type="warning" @click="chooseTtItems()" v-show="totalTtCondition">智能勾选可调货</el-button>
      <el-button type="success" @click="massTt()" v-show="totalTtCondition">批量调货</el-button>
      <el-button type="primary" @click="massSo()">批量销售</el-button>
      <el-button type="danger" @click="massReject()">批量拒绝</el-button>
      <el-button type="success" @click="intellPurchase()">智能采购</el-button>
      <el-button type="primary" @click="goHistory()">查看历史</el-button>


      <el-tooltip class="box-item" effect="dark" content="消耗较大,每天用一次足以" placement="top">
        <el-button type="danger" @click="updateWarningAll(),updateAllIfAction()" :disabled="updateAllIf">整体下限刷新</el-button>
      </el-tooltip>
    </div>

    <PopModel ref="rejectRemarkRef">
      <div class="inputBox">
        <label class="popLable" for="rejectRemarkInOnTheWayForSale">拒绝理由:</label>
        <el-input v-model="activeRejectRemark" class="popInput" id="rejectRemarkInOnTheWayForSale"
          placeholder="请输入拒绝理由" />
      </div>
      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight" @click="closeRefusePop()">取消</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="actionRefuseOne()">确认拒绝</el-button>
      </div>
    </PopModel>

  </div>
</template>

<script>
import { computed, provide, reactive, ref, watch } from 'vue-demi';
import { useStore } from "vuex";
import {
  tickOutVirtualCompany, ChangeFirToUp, ChangeYMD, changeArrContentToDate, insertSearchOrigin, getInventory, getSalePrice, copyMap, copyArray, copyObj
} from '@/hooks/tools';
import _ from "lodash";
import requestData from '@/hooks/requestData';
import PopModel from '@/components/Common/PopModel.vue'
import {
  fillDefaultValue, changeToViewCode, transferToCode, checkTtCompany, rejectPurchaseApply, getDocNo,
  openTtList, openSoList, deleteDocCenter, updateTtOut, updateSoOut, transferToName, updateWarning,updateWarningAll,
  getCounterPartInven, getToBeInStockQty, alterOnTheWayForSaleCommodityId
} from '@/hooks/OpenOrder'
import { lockOneTheWay, unLockOneTheWay } from '@/hooks/LockAffair';
import { ElMessage } from 'element-plus'
export default {
  components: {
    PopModel
  },
  setup(props, { emit }) {
    //#region 定义区
    const store = useStore();

    const filterList = store.state.loginInitializeData.filterList

    const warehouseFilter = copyArray(filterList.WareHouseIdOut)

    const OnTheWayTypeList = filterList.StartType

    const partBFilter = filterList.PartBCompanyId

    const partBFilterCopy = copyArray(partBFilter)

    let totalTtCondition = ref(false)

    provide('tableTitle', '')

    //将虚拟客户剔除
    tickOutVirtualCompany(partBFilterCopy)

    const SupplierToBeSolveFSFilter = filterList.SupplierToBeSolveFS

    let defaultOutWarehouse = ref('')

    const setDefaultOutWarehouse = () => {
      const warehouseList = store.state.loginInitializeData.authList.wareHouseLists
      defaultOutWarehouse.value = warehouseFilter[0].beforeTransfer
      for (let item of warehouseFilter) {
        if (warehouseList.find(x => x.wareHouseId == item.beforeTransfer).defaultWarehouse) {
          defaultOutWarehouse.value = item.beforeTransfer
        }
      }
    }
    setDefaultOutWarehouse()
    //初始化输入表名称
    const TableName = 'OnTheWayList';

    const PageName = 'OnTheWayListForSale'

    const code = changeToViewCode(PageName)



    //#endregion

    //#region 全选
    let selectAll = ref(false);

    let selectedItems = ref([]);

    watch(selectAll, (newValue, oldValue) => {
      clearClick()
      if (code == store.state.loginInitializeData.ShowWindow[0]) {
        if (newValue == true) {
          selectedItems.value.splice(0);
          for (let item of tableBody) {
            selectedItems.value.push(item['onTheWayCode']);
          }
        } else {
          selectedItems.value.splice(0);
        }
      }
    });

    let chooseTtItemsClick = ref(false)

    const clearClick = () => {
      chooseTtItemsClick.value = false
    }


    const chooseTtItems = () => {

      selectedItems.value.splice(0);
      if (chooseTtItemsClick.value == true) {

        clearClick()
        return;
      }
      else {
        clearClick()
        chooseTtItemsClick.value = true
      }
      for (let item of tableBody) {
        if (!item.supplierToBeSolve) {
          if (selectedItems.value.indexOf(item.onTheWayCode) > -1) {
            selectedItems.value.splice(selectedItems.value.indexOf(item.onTheWayCode), 1)
          }
          continue
        }
        if (!(item.saleQty > 0) || item.virtualGoods) {
          if (selectedItems.value.indexOf(item.onTheWayCode) > -1) {
            selectedItems.value.splice(selectedItems.value.indexOf(item.onTheWayCode), 1)
          }
          continue
        }
        if (companyArr.get(transferToCode(item.partBCompanyId, 'PartBCompanyId'))) {
          selectedItems.value.push(item.onTheWayCode)
        }
      }

    }
    //#endregion

    //#region 输入模块
    //f存入活跃单元格数据
    let activeInput = reactive({
      activeIndex: -1,
      activeFields: ''
    })
    let enterInValue = ref('');

    const saveEnterInValue = (value) => {
      setTimeout(() => {
        enterInValue.value = value
      }, 200)
    }
    //#endregion

    watch(() => activeInput.activeIndex, () => {
      searchResult.splice(0)
    })
    const setActiveInput = (index, fields) => {
      activeInput.activeIndex = index;
      activeInput.activeFields = fields;
    }
    let searchResult = reactive([]);
    let offsetDistance = ref(0)
    const confirmOffset = (e) => {
      offsetDistance.value = e.clientY
    }
    const insertSearch = _.debounce((e) => {
      insertSearchOrigin(e, searchResult)
    }, 500)

    let commTrOffset = computed(() => {
      return '2rem'
    })

    const clearSearchResult = () => {
      searchResult.splice(0)
    }

    const clearVailCommodityContent = (index) => {
      tableBody[index].saleQty = ''
      tableBody[index].commodityNameSale = ''
      tableBody[index].commodityIdSale = ''
      tableBody[index].virtualGoods = ''
      tableBody[index].unitPrice = ''
    }

    const solveSearchResult = (index) => {
      const filterA = store.state.loginInitializeData.filterList.BusinessPartnerId;
      let bpId = ''
      if (tableBody[activeInput.activeIndex].partBCompanyId != '') {
        bpId = filterA.filter(x => x.afterTransfer == tableBody[activeInput.activeIndex].partBCompanyId)[0]?.beforeTransfer
      }
      let config = {
        method: "get",
        url: "/CommodityMangeTable/GetSingleById",
        params: {
          id: searchResult[index].commodityId,
          bpId: bpId
        }
      }
      clearVailCommodityContent(activeInput.activeIndex)

      requestData.GetCache(config).then(res => {
        if (res == 'notfound') {
          // const acIndex = activeInput.activeIndex
          // if(!tableBody[acIndex].commodityId.trim()){
          //   tableBody[acIndex].counterPartInventory = 0
          //   tableBody[acIndex].counterPartToBeInStock = 0
          // }
        }
        else {

          const acIndex = activeInput.activeIndex
          // let stockValue = stockBalanceCache.get(res.split("|")[0]) || 0
          let stockValue = parseInt(searchResult[index].inventoryBalance.split(":")[1])
          tableBody[acIndex].stockQty = stockValue
          tableBody[acIndex].saleQty = tableBody[acIndex].orderQty <= +stockValue ? tableBody[activeInput.activeIndex].orderQty : +stockValue
          tableBody[acIndex].commodityNameSale = res.split("|")[1]
          tableBody[acIndex].commodityIdSale = res.split("|")[0]
          tableBody[acIndex].virtualGoods = res.split("|")[3] != 'True' ? true : false
          tableBody[acIndex].unitPrice = res.split("|")[5]
          tableBody[acIndex].saleQty = tableBody[acIndex].virtualGoods ? tableBody[activeInput.activeIndex].orderQty : tableBody[activeInput.activeIndex].saleQty

          setTimeout(() => {
            alterOnTheWayForSaleCommodityId(tableBody[acIndex].onTheWayCode, tableBody[acIndex].commodityIdSale)
          }, 0);
          if (!tableBody[acIndex].commodityId.trim()) {
            const partBCode = transferToCode(tableBody[acIndex].partBCompanyId, 'PartBCompanyId')
            getCounterPartInven(partBCode, tableBody[acIndex].commodityIdSale).then(res => {
              tableBody[acIndex].counterPartInventory = res
            })
            getToBeInStockQty(partBCode, tableBody[acIndex].commodityIdSale).then(res => {
              tableBody[acIndex].counterPartToBeInStock = res
            })
          }
          enterInValue.value = res.split("|")[1]
          activeInput.activeIndex = -1
          searchResult.splice(0)
        }
      })
    }

    const enterCheckComName = (e) => {
      if (e.keyCode == 13) {
        confirmComName(activeInput.activeIndex, activeInput.activeFields)
      }
    }
    const bindEnter = () => {
      window.addEventListener('keydown', enterCheckComName)
    }
    const unbindEnter = () => {
      window.removeEventListener('keydown', enterCheckComName)
    }

    const confirmComName = (index, fieldsName) => {
      searchResult.splice(0)
      if (tableBody[index][fieldsName] == enterInValue.value && tableBody[index].saleQty != '') { return false; }
      if (tableBody[index][fieldsName] == '') {
        clearVailCommodityContent(index)
        setTimeout(() => {

          alterOnTheWayForSaleCommodityId(tableBody[index].onTheWayCode, '')
        }, 0);
        if (!tableBody[index].commodityId.trim()) {
          tableBody[index].counterPartInventory = 0
          tableBody[index].counterPartToBeInStock = 0
        }
        return false;
      }
      const filterA = store.state.loginInitializeData.filterList.BusinessPartnerId;
      let bpId = ''
      if (tableBody[index].partBCompanyId != '') {
        bpId = filterA.filter(x => x.afterTransfer == tableBody[index].partBCompanyId)[0]?.beforeTransfer
      }
      let config = {
        method: "get",
        url: "/CommodityMangeTable/GetSingle",
        params: {
          name: tableBody[index][fieldsName],
          bpId: bpId
        }
      };
      requestData.GetCache(config).then(res => {
        if (res == 'notfound') {
          clearVailCommodityContent(index)
          alterOnTheWayForSaleCommodityId(tableBody[index].onTheWayCode, '')
          if (!tableBody[index].commodityId.trim()) {
            tableBody[index].counterPartInventory = 0
            tableBody[index].counterPartToBeInStock = 0
          }

        }
        else {
          tableBody[index].saleQty = 0
          tableBody[index].commodityNameSale = res.split("|")[1]
          tableBody[index].commodityIdSale = res.split("|")[0]
          tableBody[index].virtualGoods = res.split("|")[3] != 'True' ? true : false
          tableBody[index].unitPrice = res.split("|")[5]
          alterOnTheWayForSaleCommodityId(tableBody[index].onTheWayCode, tableBody[index].commodityIdSale)

          if (!tableBody[index].commodityId.trim()) {
            const partBCode = transferToCode(tableBody[index].partBCompanyId, 'PartBCompanyId')
            getCounterPartInven(partBCode, tableBody[index].commodityIdSale).then(res => {
              tableBody[index].counterPartInventory = res
            })
            getToBeInStockQty(partBCode, tableBody[index].commodityIdSale).then(res => {
              tableBody[index].counterPartToBeInStock = res
            })
          }

          activeInput.activeIndex = -1
          searchResult.splice(0)
        }
      })
    }

    const alertVirtual = (index, e) => {
      if (e.target.checked) {
        if (confirm('勾选虚拟物品后将不做出入库处理')) {
          return
        } else {
          setTimeout(() => {
            tableBody[index].virtualGoods = false
          }, 0);

        }
      }
    }
    const checkQty = (index, e, fieName) => {
      if (e.target.value < 0) {
        ElMessage('销售数量不能小于0')
        tableBody[index].saleQty = 0
        return;
      }
      if (e.target.value > tableBody[index].orderQty) {
        ElMessage('销售数量不能大于预定数量')
        tableBody[index].saleQty = tableBody[index].orderQty
      }
      let totalQty = 0
      let len = tableBody.length
      for (let i = 0; i < len; i++) {
        if (tableBody[i].commodityIdSale == tableBody[index].commodityIdSale && index != i) {
          totalQty += tableBody[i].saleQty
        }
      }
      const stocka = stockArrCopy.get(tableBody[index].commodityIdSale)
      if (totalQty + tableBody[index].saleQty > stocka && !tableBody[index].virtualGoods) {
        ElMessage(`销售/调货总数不允许超过库存总数，最多为${stocka - totalQty < 0 ? 0 : stocka - totalQty}个`)
        tableBody[index].saleQty = stocka - totalQty < 0 ? 0 : stocka - totalQty
      }
    }

    const checkPrice = (index, e) => {
      if (e.target.value <= 0) {
        tableBody[index].unitPrice = ''
      }
    }

    //#endregion
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          searchList()
        }
      }
    )


    const setActiveId = (id) => {
      activeInput.value = id
    }
    let selectAllWidth = ref('2.5rem')
    let trHeight = ref('1.875rem')
    let SnWidth = ref('3.75rem')
    let operationWidth = ref('12rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')
    let commodityWidth = ref('26rem')
    let virtualWidth = ref('5rem')
    let saleQtyWidth = ref('5rem')
    let priceWidth = ref('5rem')
    let stockWidth = ref('5rem')

    let SearchContent = reactive({
      OnTheWayCode: '',
      DocumentId: '',
      Sn: '',
      PartACompanyId: '',
      PartBCompanyId: '',
      StaffId: '',
      DocumentDate: '',
      DocumentType: '',
      CommodityName: '',
      CommodityId: '',
      Remark: '',
      OrderQty: '',
      UnitPrice: '',
      NotifyStatus: '',
      RefusedQty: '',
      ResponsedQty: '',
      CommodityNameSale: '',
      CommodityIdSale: '',
      ResponseStatus: '',
      DocumentTypeSale: '',
      StartType: '',
      DistributedOrNotFS: '',
      SupplierToBeSolveFS: '',
      CustomerToBeSolveFS: '',
      VirtualGoodsFS: '',
      DocumentDateH: '',
      DocumentDateL: '',
      DocumentIdSale: '',
      SnSale: '',
      DocumentDateSaleH: '',
      DocumentDateSaleL: '',
    })
    fillDefaultValue(SearchContent, TableName)

    const initializeSearch = () => {
      SearchContent.SupplierToBeSolveFS = 1
    }

    initializeSearch()


    //f计算表格内容总宽度
    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) +
        parseFloat(commodityWidth.value) +
        parseFloat(virtualWidth.value) +
        parseFloat(stockWidth.value) +
        parseFloat(saleQtyWidth.value) +
        parseFloat(priceWidth.value) +
        parseFloat(operationWidth.value) +
        parseFloat(selectAllWidth.value) + 1.25 + tableHead.length * 1 + 0.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });

    let tableHead = reactive([
      { fieldsNameForVali: 'commodityName', showLen: 30, showName: '采购商品名称' },
      { fieldsNameForVali: 'orderQty', showLen: 4, showName: '数量' },
      { fieldsNameForVali: 'partBCompanyId', showLen: 12, showName: '客户' },
      { fieldsNameForVali: 'counterPartInventory', showLen: 6, showName: '对方库存' },
      { fieldsNameForVali: 'counterPartToBeInStock', showLen: 6, showName: '对方待入' },
      { fieldsNameForVali: 'documentDate', showLen: 16, showName: '发起日期' },
      { fieldsNameForVali: 'remark', showLen: 18.75, showName: '备注' },
      { fieldsNameForVali: 'startType', showLen: 7, showName: '发起类型' },
      // { fieldsNameForVali: 'documentId', showLen: 150, showName: '单据编码' },
      // { fieldsNameForVali: 'sn', showLen: 40, showName: '序' },
    ]);

    const searchElement = reactive({
      SortFieldsName: 'OnTheWayCode',
      SortDirection: -1
    })

    let tableBody = reactive([]);

    let ChangeSort = (fildsname) => {
      searchElement.SortFieldsName = ChangeFirToUp(fildsname)
      searchElement.SortDirection = -searchElement.SortDirection
      searchList()
    };

    const reSearch = () => {
      searchList()
    }

    let stockArr = new Map()

    let stockArrCopy = new Map()

    let companyArr = new Map()

    let selfCommodityIdArr = []

    const updateTtSituation = () => {
      for (let item of tableBody) {
        if (companyArr.get(transferToCode(item.partBCompanyId, 'PartBCompanyId'))) {
          item.ttJudge = true
        }
        else {
          item.ttJudge = false
        }
      }
    }

    const fillSaleQty = () => {
      let fillArr = []
      for (let [key, value] of stockArr) {
        let b = new Promise((resolve, reject) => {
          getInventory(key).then(res => {
            stockArr.set(key, res)
            resolve('finish')
          })
        })
        fillArr.push(b)
      }
      Promise.all(fillArr).then(resArr => {
        stockArrCopy = copyMap(stockArr)
        for (let item of tableBody) {
          item.stockQty = stockArr.get(item.commodityIdSale)
        }
        for (let item of tableBody) {

          item.saleQty = item.orderQty <= stockArr.get(item.commodityIdSale) ? item.orderQty : stockArr.get(item.commodityIdSale)
          stockArr.set(item.commodityIdSale, stockArr.get(item.commodityIdSale) - item.saleQty)
        }
      }).catch(err => {
        ElMessage(err.message)
      })
    }

    let priceLock = reactive({})

    const searchList = () => {
      let config = {
        method: "post",
        url: '/OnTheWayList/GetListByMulSearchSale',
        data: Object.assign(SearchContent, searchElement),
      };
      requestData
        .GetData(config)
        .then((res) => {
          selectAll.value = false
          selectedItems.value.splice(0)
          tableBody.splice(0);
          selfCommodityIdArr.splice(0)
          tableBody.push.apply(tableBody, res['onTheWayListMultiple']);
          changeArrContentToDate(tableBody, PageName)
          const filterList = store.state.loginInitializeData.filterList
          const companyFilter = filterList.PartBCompanyId
          const startTypFilter = filterList.StartType
          stockArr.clear()
          for (let item of tableBody) {
            const partBCode = transferToCode(item.partBCompanyId, 'PartBCompanyId')
            for (let key in item) {
              if (key == 'partBCompanyId') {
                item[key] = companyFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'startType') {
                item[key] = startTypFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
            }
            item.saleQty = ''
            item.stockQty = ''
            if (!item.unitPrice) {
              item.unitPrice = ''
            }
            if (item.supplierToBeSolve) {
              item.orderQty = item.orderQty - item.responsedQty - item.refusedQty
            }
            if (item.commodityIdSale && !stockArr.has(item.commodityIdSale)) {
              stockArr.set(item.commodityIdSale, 0)
            }
            if (item.partBCompanyId && !companyArr.has(partBCode)) {
              companyArr.set(partBCode, 0)
            }
            if (item.responsedQty > 0) {
              priceLock[item.onTheWayCode] = true
            }
            setTimeout(() => {
              let CommodityIdSaleNew = item.commodityIdSale.trim()

              if (!CommodityIdSaleNew) {
                CommodityIdSaleNew = item.commodityId
              }

              if (CommodityIdSaleNew && item.supplierToBeSolve) {
                getCounterPartInven(partBCode, CommodityIdSaleNew).then(res => {
                  item.counterPartInventory = res
                })
                getToBeInStockQty(partBCode, CommodityIdSaleNew).then(res => {
                  item.counterPartToBeInStock = res
                })
              }
            }, 0);
            // if (item.commodityId) {
            //   getCounterPartInven(partBCode, item.commodityId).then(res => {
            //     item.counterPartInventory = res
            //   })
            //   getToBeInStockQty(partBCode, item.commodityId).then(res => {
            //     item.counterPartToBeInStock = res
            //   })
            // }
          }
          let fillArr = []
          for (let [key, value] of stockArr) {
            let b = new Promise((resolve, reject) => {
              getInventory(key).then(res => {
                stockArr.set(key, res)
                resolve('finish')
              })
            })
            fillArr.push(b)
          }
          for (let [key, value] of companyArr) {
            let c = new Promise((resolve, reject) => {
              checkTtCompany(key, defaultOutWarehouse.value).then(res => {
                if (res == 'pass') {
                  totalTtCondition.value = true
                  companyArr.set(key, true)
                }
                resolve('finish')
              })
            })
            fillArr.push(c)
          }
          Promise.all(fillArr).then(resArr => {
            stockArrCopy = copyMap(stockArr)
            for (let item of tableBody) {
              item.stockQty = stockArr.get(item.commodityIdSale)
              if (!companyArr.get(transferToCode(item.partBCompanyId, 'PartBCompanyId'))) {
                getSalePrice(item.commodityIdSale, transferToCode(item.partBCompanyId, 'PartBCompanyId')).then(res => {
                  if (!item.unitPrice) {
                    item.unitPrice = res
                  }

                })
              }
            }
            for (let item of tableBody) {
              item.saleQty = 0
              if (item.partBCompanyId) {
                item.saleQty = item.orderQty <= stockArr.get(item.commodityIdSale) ? item.orderQty : stockArr.get(item.commodityIdSale)
                stockArr.set(item.commodityIdSale, stockArr.get(item.commodityIdSale) - item.saleQty)
              }
              else {
                item.remark = '自身报警'
                if (selfCommodityIdArr.indexOf(item.commodityIdSale) <= -1) {
                  selfCommodityIdArr.push(item.commodityIdSale)
                }
              }
            }
            updateTtSituation()
          })
        })
        .catch((err) => {
        });
    };
    searchList()

    const intellPurchase = () => {
      if (!tableBody.length) {
        ElMessage('无商品可以采购')
        return;
      }
      let b = []
      for (let item of tableBody) {
        if (item.virtualGoods) {
          continue
        }
        if (item.commodityIdSale) {
          if (b.find(x => x.commodityId == item.commodityIdSale)) {
            b.find(x => x.commodityId == item.commodityIdSale).qty += item.orderQty
          } else {
            let c = {
              commodityId: item.commodityIdSale,
              qty: item.orderQty
            }
            b.push(c)
          }
        }
        else {
          continue;
        }
      }
      const length1 = b.length
      for (let i = length1 - 1; i >= 0; i--) {
        let storeQty = stockArrCopy.get(b[i].commodityId)
        if (selfCommodityIdArr.indexOf(b[i].commodityId) > -1) {
          storeQty = 0
        }
        if (b[i].qty <= storeQty) {
          b.splice(i, 1)
        }
        else {
          b[i].qty -= storeQty
        }
      }
      if (!b.length) {
        ElMessage('无商品可以采购')
        return;
      }
      emit('goPoInputFromOnTheWayForSale', b)
    }



    const ttMany = (indexs) => {
      return new Promise(function (resolve, reject) {
        const partACom = store.state.loginInitializeData.companyId
        const partBCom = transferToCode(tableBody[indexs[0]].partBCompanyId, "PartBCompanyId")
        const user = store.state.loginInitializeData.userName
        const userId = store.state.loginInitializeData.userId
        let outWareHouseId = defaultOutWarehouse.value;
        const outWareHouseName = transferToName(outWareHouseId, 'WareHouseId')
        let CommodityNameArr = []
        getDocNo('TT').then(res => {
          const ttHeadCode = res
          let ttBody = []
          const indexLength = indexs.length
          for (let i = 0; i < indexLength; i++) {
            if (ttBody.filter(x => x.CommodityId == tableBody[indexs[i]].commodityIdSale)?.length > 0) {
              ttBody.find(x => x.CommodityId == tableBody[indexs[i]].commodityIdSale).OrderQty += tableBody[indexs[i]].saleQty
              ttBody.find(x => x.CommodityId == tableBody[indexs[i]].commodityIdSale).OutStockQty += tableBody[indexs[i]].saleQty
              continue;
            }
            let b = {
              DocumentId: ttHeadCode,
              Sn: +i + 1,
              PartACompanyId: partACom,
              PartBCompanyId: partBCom,
              DocumentDateTime: '',
              CommodityName: tableBody[indexs[i]].commodityNameSale,
              CommodityId: tableBody[indexs[i]].commodityIdSale,
              OrderQty: tableBody[indexs[i]].saleQty,
              OutStockQty: tableBody[indexs[i]].saleQty,
              UnitPrice: '',
              Amount: '',
              Unit: '',
              Remark: '',
              SourceDocumentId: tableBody[indexs[i]].documentId,
              RootSourceDocumentId: tableBody[indexs[i]].documentId,
              ModifiedTimes: '',
            }
            if (b.CommodityId && CommodityNameArr.indexOf(b.CommodityId) <= -1) {
              CommodityNameArr.push(b.CommodityId)
            }
            ttBody.push(b)
          }
          let ttHead = {
            DocumentId: ttHeadCode,
            DocumentTypeName: '',
            DocumentDate: '',
            DocumentDateTime: '',
            Online: '',
            PartACompanyId: partACom,
            PartBCompanyId: partBCom,
            UserId: userId,
            StaffId: '',
            SourceDocumentId: '',
            Amount: '',
            Qty: '',
            OutWareHouseId: outWareHouseId,
            InWareHouseId: '',
            SystemStatusId: 104,
            CapitalAccountId: '',
            Remark: '',
            RootSourceDocumentId: '',
          }
          openTtList(ttBody, ttHead, ttHeadCode, true, false).then(res => {
            if (res != 'pass') {
              const errmessage = res;
              deleteDocCenter(ttHeadCode).then(res => {
                resolve(errmessage)
              })
            }
            else {
              let updateArr = []
              for (let i = 0; i < indexLength; i++) {
                let c = updateTtOut(tableBody[indexs[i]].onTheWayCode, tableBody[indexs[i]].saleQty, ttHeadCode, tableBody[indexs[i]].commodityIdSale)
                updateArr.push(c)
              }
              Promise.all(updateArr).then(resArr => {
                if (outWareHouseName == '主仓库') {
                  setTimeout(() => {
                    updateWarning(partACom, CommodityNameArr)
                  }, 0);
                }
                resolve(resArr)
              }).catch(err => {
                reject(err)
              })
            }
          })
        })
      })
    }

    const saleMany = (indexs) => {
      return new Promise(function (resolve, reject) {
        const partACom = store.state.loginInitializeData.companyId
        const partBCom = transferToCode(tableBody[indexs[0]].partBCompanyId, "PartBCompanyId")
        const user = store.state.loginInitializeData.userName
        const userId = store.state.loginInitializeData.userId
        let outWareHouseId = defaultOutWarehouse.value;
        const outWareHouseName = transferToName(outWareHouseId, 'WareHouseId')
        let CommodityNameArr = []
        getDocNo('SO').then(res => {
          const soHeadCode = res
          let soBody = []
          const indexLength = indexs.length
          for (let i = 0; i < indexLength; i++) {
            if (soBody.filter(x => x.CommodityId == tableBody[indexs[i]].commodityIdSale)?.length > 0) {
              soBody.find(x => x.CommodityId == tableBody[indexs[i]].commodityIdSale).OrderQty += tableBody[indexs[i]].saleQty
              soBody.find(x => x.CommodityId == tableBody[indexs[i]].commodityIdSale).OutStockQty += tableBody[indexs[i]].saleQty
              continue;
            }
            let b = {
              DocumentId: soHeadCode,
              Sn: +i + 1,
              PartACompanyId: partACom,
              PartBCompanyId: partBCom,
              CommodityName: tableBody[indexs[i]].commodityNameSale,
              CommodityId: tableBody[indexs[i]].commodityIdSale,
              VirtualGoods: tableBody[indexs[i]].virtualGoods,
              OrderQty: tableBody[indexs[i]].saleQty,
              Unit: '',
              UnitPrice: tableBody[indexs[i]].unitPrice,
              Amount: tableBody[indexs[i]].saleQty * tableBody[indexs[i]].unitPrice,
              Remark: tableBody[indexs[i]].virtualGoods ? tableBody[indexs[i]].commodityName : '',
              ShipmentQty: '',
              ShipmentStatus: '',
              OutStockQty: tableBody[indexs[i]].saleQty,
              OutStockStatus: 103,
              ResponsedQty: '',
              RefusedQty: '',
              ResponseStatus: '',
              InvoiceOpenAmount: '',
              InvoiceOpenStatus: '',
              MoneyReceivedAmount: '',
              MoneyReceivedStatus: '',
              SourceDocumentId: '',
              RootSourceDocumentId: soHeadCode,
              ModifiedTimes: '',
            }
            if (b.CommodityId && !b.VirtualGoods) {
              CommodityNameArr.push(b.CommodityId)
            }
            soBody.push(b)
          }
          let soHead = {
            DocumentId: soHeadCode,
            DocumentTypeName: '',
            DocumentDate: '',
            DocumentDateTime: '',
            Online: '',
            PartACompanyId: partACom,
            PartBCompanyId: partBCom,
            UserId: userId,
            StaffId: '',
            SourceDocumentId: '',
            Amount: '',
            Qty: '',
            OutWareHouseId: outWareHouseId,
            InWareHouseId: '',
            SystemStatusId: 104,
            CapitalAccountId: '',
            Remark: tableBody[indexs[0]].virtualGoods ? tableBody[indexs[0]].commodityName : '',
            RootSourceDocumentId: '',
          }
          openSoList(soBody, soHead, soHeadCode, false).then(res => {
            if (res != 'pass') {
              const errmessage = res;
              deleteDocCenter(soHeadCode).then(res => {
                resolve(errmessage)
              })
            }
            else {
              if (outWareHouseName == '主仓库') {
                setTimeout(() => {
                  updateWarning(partACom, CommodityNameArr)
                }, 0);
              }
              let updateArr = []
              for (let i = 0; i < indexLength; i++) {
                let c = updateSoOut(tableBody[indexs[i]].onTheWayCode, tableBody[indexs[i]].saleQty, soHeadCode, tableBody[indexs[i]].commodityIdSale)
                updateArr.push(c)
              }
              Promise.all(updateArr).then(resArr => {
                resolve(resArr)
              }).catch(err => {
                reject(err)
              })
            }
          })
        })
      })
    }

    const refuseOne = (index, remark) => {
      return new Promise(function (resolve, reject) {
        let onObj = tableBody[index];
        let qty = onObj.saleQty || onObj.orderQty

        rejectPurchaseApply(onObj.onTheWayCode, qty, remark).then(res => {
          if (onObj.commodityId) {
            setTimeout(() => {
              updateWarning(onObj.partBCompanyId, [onObj.commodityId])
            }, 200);
          }

          resolve(res)
        })
      })
    }

    const actionTtOne = (index) => {
      let onObj = tableBody[index];
      if (!onObj.supplierToBeSolve) {
        ElMessage('此条无需处理')
        return false;
      }
      if (!onObj.saleQty) {
        ElMessage('请输入调货数量')
        return false;
      }
      if (onObj.saleQty > onObj.orderQty) {

        ElMessage('调货数量不允许超过客户预定数量')
        return false;
      }
      let codes = []
      codes.push(tableBody[index].onTheWayCode)
      lockOneTheWay(codes).then(res => {
        try {
          if (res != 'pass') {
            ElMessage(res)
            searchList()
            return;
          }
          const partBCom = onObj.partBCompanyId
          checkTtCompany(transferToCode(partBCom, 'PartBCompanyId'), defaultOutWarehouse.value).then(res => {
            if (res != 'pass') {
              searchList()
              ElMessage('该客户无法调货')
              return false;
            }
            else {
              let indexs = []
              indexs.push(index)
              ttMany(indexs).then(res => {
                if (res != 'pass') {
                  searchList()
                  ElMessage(`调货失败,原因是${res}`)
                  return false;
                }
                else {
                  emit('refreshMsg')
                  searchList()
                }
              })
            }
          })
        } catch (error) {

        }
        finally {
          unLockOneTheWay(codes)
        }

      }).catch(err => {

        searchList()
      })


    }

    const actionSaleOne = (index) => {

      let onObj = tableBody[index];
      if (!onObj.supplierToBeSolve) {

        ElMessage('此条无需处理')
        return false;
      }
      if (!onObj.saleQty) {

        ElMessage('请输入销售数量')
        return false;
      }
      if (onObj.saleQty > onObj.orderQty) {

        ElMessage('销售数量不允许超过客户预定数量')
        return false;
      }
      if (onObj.unitPrice == '') {
        ElMessage('价格未填写')
        return false;
      }

      let codes = []
      codes.push(tableBody[index].onTheWayCode)
      lockOneTheWay(codes).then(res => {
        try {
          if (res != 'pass') {
            ElMessage(res)
            searchList()
            return;
          }
          let indexs = []
          indexs.push(index)
          saleMany(indexs).then(res => {
            if (res != 'pass') {
              searchList()
              ElMessage(`销售开单失败,原因是${res}`)
              return false;
            }
            else {
              searchList()
              emit('refreshMsg')
            }
          })
        } catch (error) {

        }
        finally {
          unLockOneTheWay(codes)
        }
      }).catch(err => {
        searchList()
      })
    }

    let activeRejectIndex = ref(-1)

    let activeRejectRemark = ref('')

    const rejectRemarkRef = ref(null)

    const closeRefusePop = () => {
      activeRejectRemark.value = ''
      rejectRemarkRef.value.closePopComm()
    }

    const openRejectPop = (index) => {
      activeRejectIndex.value = index
      rejectRemarkRef.value.openPopComm()
    }


    const actionRefuseOne = () => {
      let b = confirm('确认拒绝，拒绝后无法恢复')
      if (!b) {
        return false;
      }
      let codes = []
      codes.push(tableBody[activeRejectIndex.value].onTheWayCode)
      lockOneTheWay(codes).then(res => {
        try {
          if (res != 'pass') {
            ElMessage(res)
            activeRejectRemark.value = ''
            rejectRemarkRef.value.closePopComm()
            searchList()
            return;
          }
          refuseOne(activeRejectIndex.value, activeRejectRemark.value).then(res => {
            searchList()
            activeRejectRemark.value = ''
            rejectRemarkRef.value.closePopComm()
            emit('refreshMsg')
          })
        } catch (error) {

        }
        finally {
          unLockOneTheWay(codes)

        }

      }).catch(err => {

        searchList()
      })

    }

    const massTt = () => {

      if (selectedItems.value.length == 0) {

        ElMessage('未选择行')
        return;
      }

      lockOneTheWay(selectedItems.value).then(res => {
        try {
          if (res != 'pass') {
            ElMessage(res)
            searchList()
            return;
          }
          let arrLength = tableBody.length
          let indexArr = []
          for (let i = 0; i < arrLength; i++) {
            if (selectedItems.value.indexOf(tableBody[i].onTheWayCode) > -1) {
              indexArr.push(i)
            }
          }
          let orderCheckArr = new Map()

          let stockCheckArr = new Map()
          let errMsg = ''
          for (let index of indexArr) {
            let body = tableBody[index]
            if (!body.supplierToBeSolve) {
              errMsg += `第${+index + 1}行无需处理`
              continue
            }
            if (body.saleQty > body.orderQty) {
              errMsg += `第${+index + 1}行调货数量大于预定数量`
            }
            if (body.saleQty > body.stockQty) {
              errMsg += `第${+index + 1}行调货数量大于库存数量`
            }
            if (!body.saleQty) {
              errMsg += `第${+index + 1}行数量缺失\n`
            }
            if (!body.commodityIdSale) {
              errMsg += `第${+index + 1}行没有物料信息\n`
            }
            if (!body.partBCompanyId) {
              errMsg += `第${+index + 1}行是自身报警，无法处理\n`
            }
            else {
              orderCheckArr.set(body.commodityIdSale, orderCheckArr.has(body.commodityIdSale) ? (body.saleQty + orderCheckArr.get(body.commodityIdSale)) : body.saleQty)
            }
          }
          if (errMsg != '') {
            searchList()
            ElMessage(errMsg)
            return;
          }
          let stockCheckPromisArr = []
          for (let [key, value] of orderCheckArr) {
            let b = new Promise((resolve, reject) => {
              getInventory(key).then(res => {
                stockCheckArr.set(key, res)
                resolve('finish')
              })
            })
            stockCheckPromisArr.push(b)
          }
          Promise.all(stockCheckPromisArr).then(resArr => {
            let stockErr = ''
            for (let [key, value] of orderCheckArr) {
              if (value > stockCheckArr.get(key)) {
                stockErr += `${tableBody.find(x => x.commodityIdSale == key)?.commodityNameSale}库存不足\n`
              }
            }
            if (stockErr != '') {
              searchList()
              ElMessage(stockErr)
              return;
            }

            let partBArr = []
            for (let index of indexArr) {
              if (partBArr.indexOf(transferToCode(tableBody[index].partBCompanyId, 'PartBCompanyId')) <= -1) {
                partBArr.push(transferToCode(tableBody[index].partBCompanyId, 'PartBCompanyId'))
              }
            }
            let ttCheckArr = []
            for (let partB of partBArr) {
              let b = checkTtCompany(partB, defaultOutWarehouse.value)
              ttCheckArr.push(b)
            }
            Promise.all(ttCheckArr).then(resArr => {
              for (let i = 0; i < resArr.length; i++) {
                if (resArr[i] != 'pass') {
                  searchList()
                  ElMessage(`${transferToName(partBArr[i], 'PartBCompanyId')}不具备调货条件`)
                  return;
                }
              }
              let partBObjArr = {}
              for (let partB of partBArr) {
                partBObjArr[partB] = []
              }
              for (let index of indexArr) {
                for (let key in partBObjArr) {
                  if (transferToCode(tableBody[index].partBCompanyId, 'PartBCompanyId') == key) {
                    partBObjArr[key].push(index)
                  }
                }
              }
              let promiseArr = []
              for (let key in partBObjArr) {
                let b = ttMany(partBObjArr[key])
                promiseArr.push(b)
              }
              Promise.all(promiseArr).then(res => {
                searchList()
                emit('refreshMsg')
              })
            })
          })

        } catch (error) {

        }

        finally {
          unLockOneTheWay(selectedItems.value)
        }
      }).catch(err => {

        searchList()
      })
    }

    const updateDefault = () => {
      searchList()
    }

    const massSo = () => {

      if (selectedItems.value.length == 0) {

        ElMessage('未选择行')
        return;
      }

      lockOneTheWay(selectedItems.value).then(res => {
        try {
          if (res != 'pass') {
            ElMessage(res)
            searchList()
            return;
          }

          let arrLength = tableBody.length
          let indexArr = []
          for (let i = 0; i < arrLength; i++) {
            if (selectedItems.value.indexOf(tableBody[i].onTheWayCode) > -1) {
              indexArr.push(i)
            }
          }
          let orderCheckArr = new Map()
          let stockCheckArr = new Map()
          let errMsg = ''
          for (let index of indexArr) {
            let body = tableBody[index]
            if (!body.supplierToBeSolve) {
              errMsg += `第${+index + 1}行无需处理\n`
              continue
            }
            if (body.saleQty > body.orderQty) {
              errMsg += `第${+index + 1}行调货数量大于预定数量\n`
            }
            if (body.saleQty > body.stockQty && !body.virtualGoods) {
              errMsg += `第${+index + 1}行调货数量大于库存数量\n`
            }
            if (!body.saleQty) {
              errMsg += `第${+index + 1}行数量缺失\n`
            }
            if (!body.commodityIdSale) {
              errMsg += `第${+index + 1}行没有物料信息\n`
            }
            if (!body.partBCompanyId) {
              errMsg += `第${+index + 1}行是自身报警，无法处理\n`
            }
            else {
              if (!body.virtualGoods) {
                orderCheckArr.set(body.commodityIdSale, orderCheckArr.has(body.commodityIdSale) ? (body.saleQty + orderCheckArr.get(body.commodityIdSale)) : body.saleQty)
              }
            }
          }
          if (errMsg != '') {
            searchList()
            ElMessage(errMsg)
            return false;
          }
          let stockCheckPromisArr = []
          for (let [key, value] of orderCheckArr) {
            let b = new Promise((resolve, reject) => {
              getInventory(key).then(res => {
                stockCheckArr.set(key, res)
                resolve('finish')
              })
            })
            stockCheckPromisArr.push(b)
          }
          Promise.all(stockCheckPromisArr).then(resArr => {

            let stockErr = ''
            for (let [key, value] of orderCheckArr) {
              if (value > stockCheckArr.get(key)) {
                stockErr += `${tableBody.find(x => x.commodityIdSale == key)?.commodityNameSale}库存不足\n`
              }
            }
            if (stockErr != '') {
              searchList()
              ElMessage(stockErr)
              return;
            }
            let priceCheck = ''

            for (let index of indexArr) {
              if (tableBody[index].unitPrice <= 0) {
                priceCheck += `第${+index + 1}行销售价格为0\n`
              }
            }
            if (priceCheck != '') {
              let b = confirm(priceCheck + '继续请确认，退回修改请取消')
              if (!b) {
                searchList()
                return
              }
            }

            let partBArr = []
            for (let index of indexArr) {
              if (partBArr.indexOf(transferToCode(tableBody[index].partBCompanyId, 'PartBCompanyId')) <= -1) {
                partBArr.push(transferToCode(tableBody[index].partBCompanyId, 'PartBCompanyId'))
              }
            }
            let partBObjArr = {}
            for (let partB of partBArr) {
              partBObjArr[partB] = []
            }
            for (let index of indexArr) {
              for (let key in partBObjArr) {
                if (transferToCode(tableBody[index].partBCompanyId, 'PartBCompanyId') == key) {
                  partBObjArr[key].push(index)
                }
              }
            }
            let promiseArr = []
            for (let key in partBObjArr) {
              let b = saleMany(partBObjArr[key])
              promiseArr.push(b)
            }
            Promise.all(promiseArr).then(res => {
              searchList()
              emit('refreshMsg')
            })
          })


        } catch (error) {

        }

        finally {
          unLockOneTheWay(selectedItems.value)

        }

      }).catch(err => {

        searchList()
      })
    }

    const massReject = () => {
      let b = confirm('批量拒绝后无法恢复，请确认并继续')
      if (!b) {

        return;
      }
      if (selectedItems.value.length == 0) {

        ElMessage('未选择行')
        return;
      }
      lockOneTheWay(selectedItems.value).then(res => {
        try {
          if (res != 'pass') {
            ElMessage(res)
            searchList()
            return;
          }
          let arrLength = tableBody.length
          let indexArr = []
          for (let i = 0; i < arrLength; i++) {
            if (selectedItems.value.indexOf(tableBody[i].onTheWayCode) > -1) {
              indexArr.push(i)
            }
          }
          let errMsg = ''
          for (let index of indexArr) {
            if (!tableBody[index].supplierToBeSolve) {
              errMsg += `第${+index + 1}行已经处理，无法取消\n`
            }
            if (!tableBody[index].partBCompanyId) {
              errMsg += `第${+index + 1}行是自身报警，无法处理\n`
            }
          }
          if (errMsg != '') {
            searchList()
            ElMessage(errMsg)
            return
          }
          let rejectArr = []
          for (let index of indexArr) {
            let b = refuseOne(index, '')
            rejectArr.push(b)
          }
          Promise.all(rejectArr).then(res => {
            searchList()
            emit('refreshMsg')
          })


        } catch (error) {

        }

        finally {
          unLockOneTheWay(selectedItems.value)
        }

      }).catch(err => {

        searchList()
      })
    }

    const goHistory = () => {
      emit('goOnTheWayHistory', 'sell')
    }

    const updateAllIf = ref(false)

    const updateAllIfAction = () => {
      updateAllIf.value = true
    }



    return {
      partBFilterCopy, SupplierToBeSolveFSFilter, setActiveId, intellPurchase,
      trHeight, SnWidth, operationWidth, tableHead, searchList, ChangeSort, tablewidth, tableBody,
      reSearch, searchElement, tbodyHeight, OnTheWayTypeList, SearchContent, selectAllWidth,
      selectAll, selectedItems, commodityWidth, goHistory,
      insertSearch, searchResult, activeInput, setActiveInput, confirmOffset, offsetDistance,
      clearSearchResult, commTrOffset, solveSearchResult, saveEnterInValue, bindEnter, unbindEnter, confirmComName,
      virtualWidth, alertVirtual, checkQty, saleQtyWidth, priceWidth, checkPrice, stockWidth,
      actionTtOne, actionSaleOne, actionRefuseOne, chooseTtItems, massTt, massSo, massReject, warehouseFilter,
      defaultOutWarehouse, fillSaleQty, priceLock, intellPurchase, rejectRemarkRef, openRejectPop, activeRejectRemark,
      closeRefusePop, updateDefault, totalTtCondition,updateWarningAll,updateAllIf,updateAllIfAction


    }
  }
}
</script>

<style scoped lang="less">
@todoAlert: #e2efda;

.tableLoaderContainer {
  width: 100%;
  height: 100%;
}

.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;
@lableMarginLeft: 0.625rem;
@lableHeight: 2rem;
@inputHeight: 1.5rem;
@mainColor: #4285f4;

.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}

.searchinputLabel {
  margin-left: @lableMarginLeft;
  font-size: 0.8rem;
  height: @lableHeight;
  line-height: @lableHeight;
}

.searchinput {
  margin-top: calc((@lableHeight - @inputHeight) / 2);
  margin-bottom: calc((@lableHeight - @inputHeight) / 2);
  font-size: 0.9rem;
  border: none;
  outline: none;
  height: @inputHeight;
  line-height: @inputHeight;
}

.ActiveBox {
  border: 1px solid #ffa004 !important;
}

.searchInputBox {
  height: @lableHeight;
  border: 1px solid #c9c9c9;
  margin-left: @lableMarginLeft;
  border-radius: 0.31rem;
}

.searchItemContainer {
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
  display: flex;
}

.searchSetting {
  margin-left: calc(@lableMarginLeft*3);
  height: @lableHeight;
  display: flex;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}

.searchSetting:hover {
  cursor: pointer;
  color: @mainColor;
}

.settingIcon {
  font-family: "icomoon";
  font-size: 1.1rem;
  line-height: @lableHeight;
}

.settingText {
  margin-left: 0.3rem;
  font-size: 0.8rem;
  line-height: @lableHeight;
}

.dateInputContainer {
  display: flex;
}

.BodyTitle {
  width: 98%;
  margin-top: 0.5rem;
  margin-left: 1%;
}

.BodyTitleText {
  font-size: 1.2rem;
  font-weight: 200;
}

.long {
  width: 20rem;
}

.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 60%;
  margin-top: 1rem;
}

.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}

.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}

.thSN {
  font-family: "icomoon";
  color: #fff;
  font-size: 16px;
}

.headtd {
  cursor: pointer;
}

.ShowUpArrow {
  position: relative;
}

.ShowUpArrow::after {
  content: "\ea32";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
  color: #fff;
}

.ShowDownArrow {
  position: relative;
}

.ShowDownArrow::after {
  content: "\ea36";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
}

.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}

.tablebody {
  margin-top: -1px;
}

.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}

.tablebody tr:hover {
  background-color: #edf4fe;
}

.tdSN,
.tableselect,
.tdoper,
.footsum,
.bodytr {
  text-align: center;
}

.bodytr {
  height: 2rem;
}

.spancancel,
.spansale,
.spantt {
  display: inline-block;
  height: 1.25rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  line-height: 1.25rem;
  font-size: 0.5rem;
  margin-left: 0.5rem;
}

.spantt {
  background-color: #409eff;
}

.spansale {
  background-color: #ffb30f;
}

.spancancel {
  background-color: #fa2c2c;
}

.tablefoot {
  margin-top: -1px;
}

.selectAllCheckBox {
  cursor: pointer;
}

.commodityTr {
  position: relative;
}

.tableinputContainer {
  display: flex;
  position: relative;
}

.bodytr input {
  border: none;
  outline: none;
  height: 1.875rem;
  background-color: transparent;
  text-align: center;
}

.bodytr input:focus {
  border: 1px solid #ffa004;
}

.CommodityContainer {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  margin-top: 1px;
  border: 1px solid #cccccc;

  // border-left: 2px solid #4e71f2;
  // border-right: 2px solid #4e71f2;
  // border-bottom: 2px solid #4e71f2;
}

.searchContentTr {
  display: flex;
}

.searchContentTr:hover {
  background-color: #fff;
  background-color: #f5f5f6;
}

.searchContentTr input {
  text-align: left;
}

.searchContentTr input:hover {
  cursor: pointer;
}

.searchContentTrLast:hover {
  background-color: #fa2c2c;
  color: #fff;
  cursor: pointer;
}

.searchContentTrLast {
  padding-left: 0.3rem;
  text-align: left;
}

.checkbo {
  margin-left: 0.2rem;
  cursor: pointer;
}

.headtdEdit {
  cursor: pointer;
}

.highInput {
  background-color: #d9e1f2 !important;
}

.firstButton {
  margin-left: 1rem;
}

.todoLine {
  background-color: #fce4d6 !important;
}

.todoLineAlert {
  background-color: @todoAlert !important;
}

.nottodoLine {
  background-color: #a6a6a6 !important;
}

.rejectLine {
  background-color: #ff0000 !important;
}

.tipColortodo,
.tipColorfinish,
.tipColorAlert,
.tipColorReject {
  height: 1.2rem;
  width: 1.2rem;
  margin-top: 0.3rem;
}

.tipColortodo {
  background-color: #fce4d6;
}

.tipColorfinish {
  background-color: #a6a6a6;
}

.tipColorAlert {
  background-color: @todoAlert;
}

.tipColorReject {
  background-color: #ff0000;
}

.tipWords {
  height: 1.2rem;
  line-height: 1.2rem;
  margin-top: 0.3rem;
  margin-left: 0.5rem;
}

.tipBox {
  display: flex;
  height: 1.75rem;
  width: 8rem;
  margin-top: 0.625rem;
  margin-left: 0.8rem;
}

.refreshWords {
  margin-left: 0.5rem;
}

.blueIcon {
  color: #1e87f0;
}

.forClick {
  cursor: pointer;
}

.forClick:hover {
  transform: translateY(-1px);
}

.inputBox {
  display: flex;
}

.popLable {
  margin-top: 0.5rem;
  line-height: 2.5rem;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 0.5rem;
}

.popInput {
  margin-top: 0.5rem;
  width: 28rem;
}

.mRight {
  margin-top: 0.1rem;
}

.lastButton {
  margin-right: 0.2rem;
}

.buttonBox {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  justify-content: flex-end;
}</style>