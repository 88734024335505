<template>
  <div class="inputLoaderContainer">
    <div class="BodyTitle">
      <div class="BodyTitleText">{{tableTitle}}---{{documentContent.DocumentId}}</div>
    </div>
    <div class="InputHeadContainer">
      <div class="InputForChooseContainer" v-if="documentCenterItems.indexOf('PartBCompanyId') > -1"><span class="importantIndi" v-show="itemList.filter(x=>x.fieldsNameForManage == 'PartBCompanyId')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="PartBCompanyIdEditOrder" v-text="itemList.filter(x=>x.fieldsNameForManage == 'PartBCompanyId')[0].fieldsShowName + ':'"></label>
        <select class="InputForChooseLong" id="PartBCompanyIdEditOrder" v-model="documentContent.PartBCompanyId" :disabled="disableMap.PartBCompanyId" @change="checkUpdateForHead('PartBCompanyId')" :class="[{ModifyShow:updateList.find(x=>x.position == 'Head' && x.fieldsName == 'PartBCompanyId')},{DisableShow:disableMap.PartBCompanyId}]">
          <option v-for="item in partBFilter" :key="item.beforeTransfer + 'EditOrderPartB'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
        </select>
      </div>
      <div class="InputForChooseContainer" v-if="documentCenterItems.indexOf('OutWareHouseId') > -1"><span class="importantIndi" v-show="itemList.filter(x=>x.fieldsNameForManage == 'OutWareHouseId')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="OutWareHouseIdEditOrder" v-text="itemList.filter(x=>x.fieldsNameForManage == 'OutWareHouseId')[0].fieldsShowName + ':'"></label>
        <select class="InputForChoose" id="OutWareHouseIdEditOrder" v-model="documentContent.OutWareHouseId" :disabled="disableMap.OutWareHouseId" @change="checkUpdateForHead('OutWareHouseId')" :class="[{ModifyShow:updateList.find(x=>x.position == 'Head' && x.fieldsName == 'OutWareHouseId')},{DisableShow:disableMap.OutWareHouseId}]">
          <option v-for="item in OutWarehouseFilter" :key="item.beforeTransfer + 'EditOrderOutWarehouse'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
        </select>
      </div>
      <div class="InputForChooseContainer" v-if="documentCenterItems.indexOf('InWareHouseId') > -1"><span class="importantIndi" v-show="itemList.filter(x=>x.fieldsNameForManage == 'InWareHouseId')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="InWareHouseIdEditOrder" v-text="itemList.filter(x=>x.fieldsNameForManage == 'InWareHouseId')[0].fieldsShowName + ':'"></label>
        <select class="InputForChoose" id="InWareHouseIdEditOrder" v-model="documentContent.InWareHouseId" :disabled="disableMap.InWareHouseId" @change="checkUpdateForHead('InWareHouseId')" :class="[{ModifyShow:updateList.find(x=>x.position == 'Head' && x.fieldsName == 'InWareHouseId')},{DisableShow:disableMap.InWareHouseId}]">
          <option v-for="item in InWarehouseFilter" :key="item.beforeTransfer + 'EditOrderInWarehouse'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
        </select>
      </div>
      <div class="InputForChooseContainer" v-if="documentCenterItems.indexOf('CapitalAccountId') > -1"><span class="importantIndi" v-show="itemList.filter(x=>x.fieldsNameForManage == 'CapitalAccountId')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="CapitalAccountIdEditOrder" v-text="itemList.filter(x=>x.fieldsNameForManage == 'CapitalAccountId')[0].fieldsShowName + ':'"></label>
        <select class="InputForChoose" id="CapitalAccountIdEditOrder" v-model="documentContent.CapitalAccountId" :disabled="disableMap.CapitalAccountId" @change="checkUpdateForHead('CapitalAccountId')" :class="[{ModifyShow:updateList.find(x=>x.position == 'Head' && x.fieldsName == 'CapitalAccountId')},{DisableShow:disableMap.CapitalAccountId}]">
          <option v-for="item in CapitalAccountIdFilter" :key="item.beforeTransfer + 'EditOrderCapitalAccountId'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
        </select>
      </div>
      <div class="InputForChooseContainer" v-if="documentCenterItems.indexOf('StaffId') > -1"><span class="importantIndi" v-show="itemList.filter(x=>x.fieldsNameForManage == 'StaffId')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="StaffIdEditOrder" v-text="itemList.filter(x=>x.fieldsNameForManage == 'StaffId')[0].fieldsShowName + ':'"></label>
        <select class="InputForChoose" id="StaffIdEditOrder" v-model="documentContent.StaffId" :disabled="disableMap.StaffId" @change="checkUpdateForHead('StaffId')" :class="[{ModifyShow:updateList.find(x=>x.position == 'Head' && x.fieldsName == 'StaffId')},{DisableShow:disableMap.StaffId}]">
          <option v-for="item in StaffFilter" :key="item.beforeTransfer + 'EditOrderStaff'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
        </select>
      </div>
      <div class="InputForChooseContainer" v-if="docTitleTwo == 'OB'"><label class="InputForChooseLabel" for="CustomerNameForObEditOrder">客户姓名:</label><input class="InputForSpecial" id="CustomerNameForObEditOrder" type="text" v-model="CustomerList.CustomerName" :disabled="!CustomerList.hasOrNot" @change="checkUpdateForObInfo('CustomerName')" :class="[{ModifyShow:updateList.find(x=>x.position == 'CustomerList' && x.fieldsName == 'CustomerName')},{DisableShow:!CustomerList.hasOrNot}]"></div>

      <div class="InputForChooseContainer" v-if="docTitleTwo == 'OB'"><label class="InputForChooseLabel" for="CustomerContactForObEditOrder">联系方式:</label><input class="InputForSpecial" id="CustomerContactForObEditOrder" type="text" v-model="CustomerList.CustomerContacts" :disabled="!CustomerList.hasOrNot" @change="checkUpdateForObInfo('CustomerContacts')" :class="[{ModifyShow:updateList.find(x=>x.position == 'CustomerList' && x.fieldsName == 'CustomerContacts')},{DisableShow:!CustomerList.hasOrNot}]"></div>

      <div class="InputForChooseContainer" v-if="docTitleTwo == 'OB'"><label class="InputForChooseLabel" for="DepositForObEditOrder">订金:</label><input class="InputForSpecial" id="DepositForObEditOrder" type="number" v-model="CustomerList.CustomerDeposit" @blur="checkDeposit()" :disabled="!CustomerList.hasOrNot" @change="checkUpdateForObInfo('CustomerDeposit')" :class="[{ModifyShow:updateList.find(x=>x.position == 'CustomerList' && x.fieldsName == 'CustomerDeposit')},{DisableShow:!CustomerList.hasOrNot}]"></div>

      <div class="InputForChooseContainer" v-if="documentCenterItems.indexOf('Remark') > -1"><span class="importantIndi" v-show="itemList.filter(x=>x.fieldsNameForManage == 'Remark')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="RemarkEditOrder" v-text="itemList.filter(x=>x.fieldsNameForManage == 'Remark')[0].fieldsShowName + ':'"></label>
        <input class="InputForChooseLong" id="RemarkEditOrder" v-model="documentContent.Remark" :disabled="disableMap.Remark" @change="checkUpdateForHead('Remark')" :class="[{ModifyShow:updateList.find(x=>x.position == 'Head' && x.fieldsName == 'Remark')},{DisableShow:disableMap.Remark}]">
      </div>
    </div>

    <div class="TableContentAreaEdit" :style="{height:tableHeight}">
      <table class="AddContentHead">
        <tr class="headtrEdit">
          <th class="thSNEdit">
            <div :style="{ width: widthForAll.snWidth }"></div>
          </th>
          <th>
            <div class="thoper" :style="{ width: widthForAll.operationWidth }">操作</div>
          </th>
          <th class="headtdEdit" v-if="TableListItems.indexOf('CommodityId') > -1">
            <div :style="{ width: widthForAll.CommodityIdWidth}">商品编码</div>
          </th>
          <th class="headtdEdit" v-if="TableListItems.indexOf('CommodityName') > -1">
            <div :style="{ width: widthForAll.CommodityNameWidth}">商品名称</div>
          </th>
          <th class="headtdEdit" v-if="TableListItems.indexOf('SalesAttributeNo') > -1">
            <div :style="{ width: widthForAll.SalesAttributeNoWidth}">销售属性</div>
          </th>
          <th class="headtdEdit" v-if="TableListItems.indexOf('VirtualGoods') > -1">
            <div :style="{ width: widthForAll.VirtualGoodsWidth}">虚拟物品</div>
          </th>
          <th class="headtdEdit" v-if="TableListItems.indexOf('OrderQty') > -1">
            <div :style="{ width: widthForAll.OrderQtyWidth}">数量</div>
          </th>
          <th class="headtdEdit" v-if="TableListItems.indexOf('OutStockQty') > -1">
            <div :style="{ width: widthForAll.OutStockQtyWidth}">出库数量</div>
          </th>
          <th class="headtdEdit" v-if="TableListItems.indexOf('InStockQty') > -1">
            <div :style="{ width: widthForAll.InStockQtyWidth}">入库数量</div>
          </th>
          <th class="headtdEdit" v-if="TableListItems.indexOf('Unit') > -1">
            <div :style="{ width: widthForAll.UnitWidth}">单位</div>
          </th>
          <th class="headtdEdit" v-if="TableListItems.indexOf('UnitPrice') > -1">
            <div :style="{ width: widthForAll.UnitPriceWidth}">单价</div>
          </th>
          <th class="headtdEdit" v-if="TableListItems.indexOf('AccountingSubjectId') > -1">
            <div :style="{ width: widthForAll.AccountingSubjectIdWidth}">会计科目</div>
          </th>
          <th class="headtdEdit" v-if="TableListItems.indexOf('Amount') > -1">
            <div :style="{ width: widthForAll.AmountWidth}">总金额</div>
          </th>
          <th class="headtdEdit" v-if="TableListItems.indexOf('Remark') > -1">
            <div :style="{ width: widthForAll.RemarkWidth}">备注</div>
          </th>

        </tr>
      </table>
      <div class="tablebodyareaEdit" :style="{ width: tablewidth + 'rem' }">
        <table class="tablebodyEdit">
          <tr :class="[{activeTr:index2 == activeIndex},{DisableShow:!value2.DocumentId}]" class="bodytrEdit" v-for="(value2, index2) in AddContentBody" :key="'tdEditOrder' + index2" @mousedown="alterActive(index2)">
            <td class="DisableShow">
              <div class="tdSN" :style="{ width: widthForAll.snWidth }">{{ index2 + 1 }}</div>
            </td>
            <td>
              <div class="tdoper" :style="{ width: widthForAll.operationWidth }"><span class="spandele" v-show="disableMap.deleteOne && AddContentBody[index2].DocumentId" @click="deleteOneLine(index2)">删除</span></div>
            </td>
            <td v-if="TableListItems.indexOf('CommodityId') > -1">
              <div><input type="number" :style="{ width: widthForAll.CommodityIdWidth}" v-model="AddContentBody[index2]['CommodityId']" disabled="disabled" :class="[{ModifyShow:updateList.find(x=>x.position == 'Body' && x.fieldsName == 'CommodityId' && x.sn == value2.Sn)}]"></div>
            </td>
            <td class="commodityTr" v-if="TableListItems.indexOf('CommodityName') > -1">
              <div class="tableinputContainer"><input type="text" :style="{ width: widthForAll.CommodityNameWidth }" v-model="AddContentBody[index2]['CommodityName']" :disabled="disableMap.CommodityName" @change="checkUpdateForBody('CommodityName',AddContentBody[index2]['Sn'],index2)" :class="[{ModifyShow:updateList.find(x=>x.position == 'Body' && x.fieldsName == 'CommodityName' && x.sn == value2.Sn)},{DisableShow:disableMap.CommodityName}]"></div>
            </td>
            <td v-if="TableListItems.indexOf('SalesAttributeNo') > -1">
              <div><select class="selectInput" :style="{ width: widthForAll.SalesAttributeNoWidth}" v-model="AddContentBody[index2]['SalesAttributeNo']" :disabled="disableMap.SalesAttributeNo || !AddContentBody[index2].DocumentId" @change="checkUpdateForBody('SalesAttributeNo',AddContentBody[index2]['Sn'],index2)" :class="[{ModifyShow:updateList.find(x=>x.position == 'Body' && x.fieldsName == 'SalesAttributeNo' && x.sn == value2.Sn)},{DisableShow:disableMap.SalesAttributeNo}]">
                  <option v-for="item3 in AddContentBody[index2].SalesAttributeNoArr" :key="item3.salesAttributeId + 'RoInputEditOrder'" :value="item3.salesAttributeId">{{item3.salesAttributeName}}-{{item3.recycleOrNot?'回收':'不回收'}}</option>
                </select></div>
            </td>
            <td v-if="TableListItems.indexOf('VirtualGoods') > -1">
              <div class="checkContainer DisableShow" :style="{ width: widthForAll.VirtualGoodsWidth}"><input class="checkbo DisableShow" type="checkbox" :style="{ width:'1.25rem' }" v-model="AddContentBody[index2]['VirtualGoods']" :disabled="disableMap.VirtualGoods || !AddContentBody[index2].DocumentId"></div>
            </td>
            <td v-if="TableListItems.indexOf('OrderQty') > -1">
              <div><input type="number" @input="refreashAmount()" :style="{ width: widthForAll.OrderQtyWidth}" v-model="AddContentBody[index2]['OrderQty']" :disabled="disableMap.OrderQty || !AddContentBody[index2].DocumentId" @blur="checkQty(index2,$event,'OrderQty')" @change="checkUpdateForBody('OrderQty',AddContentBody[index2]['Sn'],index2)" :class="[{ModifyShow:updateList.find(x=>x.position == 'Body' && x.fieldsName == 'OrderQty' && x.sn == value2.Sn)},{DisableShow:disableMap.OrderQty}]"></div>
            </td>
            <td v-if="TableListItems.indexOf('OutStockQty') > -1">
              <div><input type="number" @input="refreashAmount()" :style="{ width: widthForAll.OutStockQtyWidth}" v-model="AddContentBody[index2]['OutStockQty']" :disabled="disableMap.OutStockQty || !AddContentBody[index2].DocumentId" @blur="checkQty(index2,$event,'OutStockQty')" @change="checkUpdateForBody('OutStockQty',AddContentBody[index2]['Sn'],index2)" :class="[{ModifyShow:updateList.find(x=>x.position == 'Body' && x.fieldsName == 'OutStockQty' && x.sn == value2.Sn)},{DisableShow:disableMap.OutStockQty}]"></div>
            </td>
            <td v-if="TableListItems.indexOf('InStockQty') > -1">
              <div><input type="number" @input="refreashAmount()" :style="{ width: widthForAll.InStockQtyWidth}" v-model="AddContentBody[index2]['InStockQty']" :disabled="disableMap.InStockQty || !AddContentBody[index2].DocumentId" @blur="checkQty(index2,$event,'InStockQty')" @change="checkUpdateForBody('InStockQty',AddContentBody[index2]['Sn'],index2)" :class="[{ModifyShow:updateList.find(x=>x.position == 'Body' && x.fieldsName == 'InStockQty' && x.sn == value2.Sn)},{DisableShow:disableMap.InStockQty}]"></div>
            </td>
            <td v-if="TableListItems.indexOf('Unit') > -1">
              <div><input class="DisableShow" type="text" :style="{ width: widthForAll.UnitWidth}" v-model="AddContentBody[index2]['Unit']" :disabled="true"></div>
            </td>
            <td v-if="TableListItems.indexOf('UnitPrice') > -1">
              <div><input @input="refreashAmount()" type="number" step="0.01" :style="{ width: widthForAll.UnitPriceWidth}" v-model.lazy="AddContentBody[index2]['UnitPrice']" :disabled="disableMap.UnitPrice || !AddContentBody[index2].DocumentId" @blur="checkPrice(index2,$event)" @change="checkUpdateForBody('UnitPrice',AddContentBody[index2]['Sn'],index2)" :class="[{ModifyShow:updateList.find(x=>x.position == 'Body' && x.fieldsName == 'UnitPrice' && x.sn == value2.Sn)},{DisableShow:disableMap.UnitPrice}]"></div>
            </td>
            <td v-if="TableListItems.indexOf('AccountingSubjectId') > -1">
              <el-select :style="[{ width: widthForAll.AccountingSubjectIdWidth},{height:trHeight},{border:'none'}]" v-model="AddContentBody[index2]['AccountingSubjectId']" class="m-2" :disabled="disableMap.AccountingSubjectId || !AddContentBody[index2].DocumentId" placeholder=" " @change="checkUpdateForBody('AccountingSubjectId',AddContentBody[index2]['Sn'],index2)" :class="[{ModifyShow:updateList.find(x=>x.position == 'Body' && x.fieldsName == 'AccountingSubjectId' && x.sn == value2.Sn)},{DisableShow:disableMap.AccountingSubjectId}]">
                <el-option v-for="item in AccountingSubjectIdFilter" :key="'EditOrder' + item.beforeTransfer + 'Account'" :label="item.afterTransfer" :value="item.beforeTransfer">
                </el-option>
              </el-select>
            </td>
            <td v-if="TableListItems.indexOf('Amount') > -1">
              <div><input class="DisableShow" type="number" step="0.01" :style="{ width: widthForAll.AmountWidth}" disabled="disabled" v-model="AddContentBody[index2]['Amount']"></div>
            </td>
            <td v-if="TableListItems.indexOf('Remark') > -1">
              <div><input type="text" :style="{ width: widthForAll.RemarkWidth}" v-model="AddContentBody[index2]['Remark']" :disabled="disableMap.bodyRemark || !AddContentBody[index2].DocumentId" @change="checkUpdateForBody('Remark',AddContentBody[index2]['Sn'],index2)" :class="[{ModifyShow:updateList.find(x=>x.position == 'Body' && x.fieldsName == 'Remark' && x.sn == value2.Sn)},{DisableShow:disableMap.bodyRemark}]"></div>
            </td>
          </tr>
        </table>
      </div>
      <table class="tablefoot">
        <tr class="foottr">
          <td>
            <div class="footsum" :style="{ width: widthForAll.snWidth }">合计</div>
          </td>
          <td>
            <div :style="{ width: widthForAll.operationWidth}"></div>
          </td>
          <td v-if="TableListItems.indexOf('CommodityId') > -1">
            <div :style="{ width: widthForAll.CommodityIdWidth}"></div>
          </td>
          <td v-if="TableListItems.indexOf('CommodityName') > -1">
            <div :style="{ width: widthForAll.CommodityNameWidth}"></div>
          </td>
          <td v-if="TableListItems.indexOf('SalesAttributeNo') > -1">
            <div :style="{ width: widthForAll.SalesAttributeNoWidth}"></div>
          </td>
          <td v-if="TableListItems.indexOf('VirtualGoods') > -1">
            <div :style="{ width: widthForAll.VirtualGoodsWidth}"></div>
          </td>
          <td v-if="TableListItems.indexOf('OrderQty') > -1">
            <div :style="{ width: widthForAll.OrderQtyWidth}">{{footDatas.OrderQty}}</div>
          </td>
          <td v-if="TableListItems.indexOf('OutStockQty') > -1">
            <div :style="{ width: widthForAll.OutStockQtyWidth}">{{footDatas.OutStockQty}}</div>
          </td>
          <td v-if="TableListItems.indexOf('InStockQty') > -1">
            <div :style="{ width: widthForAll.InStockQtyWidth}">{{footDatas.InStockQty}}</div>
          </td>
          <td v-if="TableListItems.indexOf('Unit') > -1">
            <div :style="{ width: widthForAll.UnitWidth}"></div>
          </td>
          <td v-if="TableListItems.indexOf('UnitPrice') > -1">
            <div :style="{ width: widthForAll.UnitPriceWidth}">{{footDatas.UnitPrice== 0 ? '':footDatas.UnitPrice}}</div>
          </td>
          <td v-if="TableListItems.indexOf('AccountingSubjectId') > -1">
            <div :style="{ width: widthForAll.AccountingSubjectIdWidth}"></div>
          </td>
          <td v-if="TableListItems.indexOf('Amount') > -1">
            <div :style="{ width: widthForAll.AmountWidth}">{{footDatas.Amount}}</div>
          </td>
          <td v-if="TableListItems.indexOf('Remark') > -1">
            <div :style="{ width: widthForAll.RemarkWidth}"></div>
          </td>
        </tr>
      </table>
    </div>

    <div class="EditTableLine">
      <div class="InputForChooseContainer far" v-if="docTitleTwo == 'RO' || docTitleTwo == 'PO' || docTitleTwo == 'SO'"><label class="InputForChooseLabel" for="discountAmountEditOrder">优惠金额/订金:</label><input class="InputForChooseshort" id="discountAmountEditOrder" type="number" v-model.lazy="dicountValue" @blur="checkDiscount" :disabled="disableMap.dicountValue" @change="checkUpdateForDiscount()" :class="[{ModifyShow:updateList.find(x=>x.position == 'Discount')},{DisableShow:disableMap.dicountValue}]"></div>

      <div v-if="platFormFilter.length > 0 && docTitleTwo == 'RO'" class="InputForChooseContainer"><label class="InputForChooseLabel" for="platformChooseEo">平台选择:</label><select class="InputForChoose RightBorder" id="platformChooseEo" v-model="platformData.platformChoose" :disabled="true">
          <option v-for="itema3 in platFormFilter" :key="itema3.beforeTransfer + 'EoPlatformChoose'" :value="itema3.beforeTransfer">{{itema3.afterTransfer}}</option>
        </select></div>
      <div v-if="platFormFilter.length > 0 && docTitleTwo == 'RO'" class="InputForChooseContainer"><label class="InputForChooseLabel" for="platformMiEo">平台收款:</label><input class="InputForChooseshort" id="platformMiEo" type="number" v-model.lazy="platformData.platformMiAmount" :disabled="true"></div>
      <div v-if="platFormFilter.length > 0 && docTitleTwo == 'RO'" class="InputForChooseContainer"><label class="InputForChooseLabel" for="platformVoucherEo">券码:</label><input class="InputForChooselong" id="platformVoucherEo" type="text" v-model="platformData.voucherCode" :disabled="true"></div>

      <span v-if="docTitleTwo == 'RO' || docTitleTwo == 'PO' || docTitleTwo == 'SO'" class="ForthLineText">实付金额:<b> {{valueAfterDiscount}}</b></span>

      <div class="InputForChooseContainer far" v-if="customerSourceArr && docTitleTwo == 'RO'"><label class="InputForChooseLabel" for="customerSourceEditOrder">客户来源:</label><select v-model="customerSource" class="InputForChoose" id="customerSourceEditOrder" :disabled="disableMap.customerSource" @change="checkUpdateForCustomerSource()" :class="[{ModifyShow:updateList.find(x=>x.position == 'customerSource')},{DisableShow:disableMap.customerSource}]">
          <option v-for="item in customerSourceArr" :key="item.customerSourceId + 'RoInputForCuSourceEditOrder'" :value="item.customerSourceId">{{item.customerSourceName}}</option>
        </select>
      </div>
    </div>

    <div>
      <div class="moreFunctionBox">
        <el-button v-show="disableMap.deleteAll" type="danger" class="firstButton" @click="deleteAll">整单删除</el-button>
        <el-button v-show="disableMap.updateAll && updateList.length > 0" type="primary" @click="updateAllChange()">更新修改部分</el-button>
        <el-button v-show="disableMap.recoverBack && updateList.length > 0" type="success" @click="recoverToOrigin()">复原</el-button>
        <el-button v-show="docTitleTwo == 'RO'" type="primary" @click="reSendWarrenty()">重新发送质保单</el-button>
        <el-button v-show="docTitleTwo == 'RO'" type="primary" @click="reSendRecycle()">重新发送回收单</el-button>
        <el-button v-show="docTitleTwo == 'OB'" type="primary">重新发送订货单</el-button>
      </div>
    </div>

    <PopModelDiy ref="soMoneyRef">
      <div class="AlertMessage">{{alertMessage}}</div>
      <div class="buttonLayer">
        <el-button type="primary" v-show="alertMoney < 0 && !xlCheck" @click="returnMoney()">直接退款</el-button>
        <el-button type="primary" v-show="alertMoney > 0 && !xlCheck" @click="receiveMoney()">直接收款</el-button>
        <el-button type="success" v-show="alertMoney != 0 && partBCompanyName != '散客'" @click="closePop()">记账</el-button>
        <el-button type="info" v-show="alertMoney == 0" @click="closePop()">确认</el-button>
      </div>
    </PopModelDiy>

    <div class="onLineRemark" v-if="documentContent.SystemStatusId == 104">
      线上交互订单无法修改!!!
    </div>

  </div>

</template>

<script>
import { computed, onMounted, onUnmounted, provide, reactive, ref, watch } from 'vue-demi'
import { copyArray, copyObj, fillSonContent, checkIsXlTranction, getUUId, MiReverseMeiTuan } from '@/hooks/tools'
import { useStore } from "vuex";
import {
  deleteOnePoLineTrue, copyOrder, getNameList, getObTracingInfo, getDiscountValue, getPlatReceive, getCustomerSource, checkPoLineBeforeEdit,
  fakeDeleteDocumentCenter, updateDocCenter, changeToViewCode, getDocNo, openErList, getBodyInstance, transferToCode, openEdList,
  updateStaffIdCheck, updateStaffId, updateHeadRemarkReal, updatePoBodyRemarkReal, updatePoLineUnitPriceCheckReal,
  updatePoLineUnitPriceReal, checkSoLineDelete, deleteOneSoLineTrue, updateDiscoutCheckReal, openGrList, openGdList,
  updateSoBodyRemarkReal, updateSoLineUnitPriceCheckReal, updateSoLineUnitPriceReal, openMiList, openMoList, deleteOneRbLineTrue,
  dbCheckMiDefinite, checkRoLineDelete, deleteOneRoLineTrue, updateRoBodyRemarkReal, updateRoLineUnitPriceCheckReal, checkRbLineDelete,
  transferToName, updateRoLineUnitPriceReal, updateRoSaleAttrCheckReal, updateRoSaleAttrReal, updateWarning, updateCustomerSourceReal, checkIsXl
} from '@/hooks/OpenOrder';
import PopModelDiy from '@/components/Common/PopModelDiy.vue'
import { getAllRootMaterialList } from '@/hooks/getCache';
import requestData from '@/hooks/requestData';
import { ElMessage } from 'element-plus'
export default {
  setup() {
    const store = useStore();

    let partBFilter = copyArray(store.state.loginInitializeData.filterList.PartBCompanyId)

    let OutWarehouseFilter = copyArray(store.state.loginInitializeData.filterList.WareHouseId)

    const platFormFilter = copyArray(store.state.loginInitializeData.filterList.PlatFormList)

    let lengthForPlatFormList = platFormFilter.length

    if (lengthForPlatFormList > 0) {
      for (let k = lengthForPlatFormList - 1; k >= 0; k--) {
        if (!partBFilter.find(x => x.beforeTransfer == platFormFilter[k].beforeTransfer)) {
          platFormFilter.splice(k, 1)
        }
      }
    }

    let InWarehouseFilter = copyArray(store.state.loginInitializeData.filterList.WareHouseId)

    let CapitalAccountIdFilter = copyArray(store.state.loginInitializeData.filterList.CapitalAccountId)

    let StaffFilter = copyArray(store.state.loginInitializeData.filterList.StaffId)

    const length1 = StaffFilter.length
    const userName = store.state.loginInitializeData.userName
    if (userName != '管理员') {
      for (let i = length1 - 1; i >= 0; i--) {
        if (StaffFilter[i].afterTransfer == '管理员') {
          StaffFilter.splice(i)
        }
      }
    }

    let AccountingSubjectIdFilter = copyArray(store.state.loginInitializeData.filterList.AccountingSubjectId)

    const customerSourceArr = copyArray(store.state.loginInitializeData.authList.customerSourceLists)

    let itemList = store.state.loginInitializeData.docTableElementManage

    let documentCenterItems = reactive(['PartBCompanyId', 'OutWareHouseId', 'InWareHouseId', 'CapitalAccountId', 'StaffId', 'Remark'])

    let TableListItems = reactive(['CommodityId', 'CommodityName', 'SalesAttributeNo', 'VirtualGoods', 'OrderQty', 'OutStockQty', 'InStockQty', 'Unit', 'UnitPrice', 'AccountingSubjectId', 'Amount', 'Remark'])

    let documentContent = reactive({
      DocumentId: '',
      DocumentTypeName: '',
      DocumentDate: '',
      DocumentDateTime: '',
      Online: '',
      PartACompanyId: '',
      PartBCompanyId: '',
      UserId: '',
      StaffId: '',
      SourceDocumentId: '',
      Amount: '',
      Qty: '',
      OutWareHouseId: '',
      InWareHouseId: '',
      SystemStatusId: '',
      CapitalAccountId: '',
      Remark: '',
      RootSourceDocumentId: '',
    })

    let addBodyContent = reactive({});

    let docTitleTwo = ref('')

    let tableTitle = ref('单据修改/删除')

    let CustomerList = reactive({
      CustomerName: '',
      CustomerContacts: '',
      CustomerDeposit: '',
      hasOrNot: false
    })

    let CustomerListCopy = reactive({
      CustomerName: '',
      CustomerContacts: '',
      CustomerDeposit: '',
      hasOrNot: false
    })

    let disableMap = reactive({
      PartBCompanyId: true,
      OutWareHouseId: true,
      InWareHouseId: true,
      CapitalAccountId: true,
      StaffId: true,
      Remark: true,
      CommodityName: true,
      SalesAttributeNo: true,
      VirtualGoods: true,
      OrderQty: true,
      OutStockQty: true,
      InStockQty: true,
      UnitPrice: true,
      AccountingSubjectId: true,
      bodyRemark: true,
      dicountValue: true,
      customerSource: true,
      deleteOne: false,
      deleteAll: false,
      updateAll: false,
      recoverBack: false

    })

    const checkDeposit = () => {
      if (CustomerList.CustomerDeposit < 0) {
        CustomerList.CustomerDeposit = 0
      }
    }

    let tableHeight = ref('60%')

    const trHeight = ref('2rem')

    const soMoneyRef = ref(null)

    provide('tableTitle', '收款/退款')

    const openPop = () => {
      soMoneyRef.value.openPopComm()
    }

    let widthForAll = reactive({
      snWidth: '3.75rem',
      operationWidth: '5rem',
      CommodityIdWidth: '15rem',
      CommodityNameWidth: '40rem',
      SalesAttributeNoWidth: '15rem',
      VirtualGoodsWidth: '6rem',
      OrderQtyWidth: '6rem',
      OutStockQtyWidth: '6rem',
      InStockQtyWidth: '6rem',
      UnitWidth: '4rem',
      UnitPriceWidth: '6rem',
      AccountingSubjectIdWidth: '8rem',
      AmountWidth: '8rem',
      RemarkWidth: '40rem'
    })

    let AddContentBody = reactive([{
      CommodityId: '',
      CommodityName: '',
      SalesAttributeNo: '',
      VirtualGoods: '',
      OrderQty: '',
      OutStockQty: '',
      InStockQty: '',
      Unit: '',
      UnitPrice: '',
      AccountingSubjectId: '',
      Amount: '',
      Remark: ''
    }])

    let partBCompanyName = ref('')

    let xlCheck = ref(false)

    let footDatas = computed(() => {
      let OrderQtySum = 0;
      let OutStockQtySum = 0;
      let InStockQtySum = 0;
      let AmountSum = 0;
      let UnitPriceSum = 0;
      let footDataPre = {};
      for (let item of AddContentBody) {
        for (let key in item) {
          if (key == 'OrderQty') {
            OrderQtySum += +item.OrderQty
          }
          if (key == 'OutStockQty') {
            OutStockQtySum += +item.OutStockQty
          }
          if (key == 'InStockQty') {
            InStockQtySum += +item.InStockQty
          }
          if (key == 'Amount') {
            AmountSum += +item.Amount
          }
          if (key == 'UnitPrice' && (docTitleTwo.value == 'MO' || docTitleTwo.value == 'MI')) {
            UnitPriceSum += +item.UnitPrice
          }
        }
      }
      footDataPre.OrderQty = OrderQtySum
      footDataPre.OutStockQty = OutStockQtySum
      footDataPre.InStockQty = InStockQtySum
      footDataPre.Amount = AmountSum.toFixed(2)
      footDataPre.UnitPrice = UnitPriceSum.toFixed(2)
      // if(footDataPre.Amount < dicountValue.value){
      //   dicountValue.value = footDataPre.Amount
      // }
      return (
        reactive(footDataPre)
      );
    });

    const AddContentHead = {}

    let AddContentSample = {};

    let tablewidth = computed(() => {
      let widthAll = 0;
      for (let item of TableListItems) {
        let key2 = item + 'Width'
        widthAll += parseFloat(widthForAll[key2])
      }
      return (
        widthAll + parseFloat(widthForAll.snWidth) + parseFloat(widthForAll.operationWidth) + 2
      );
    });

    let activeIndex = ref(-1);

    let activeInput = reactive({
      activeIndex: -1,
      activeFields: ''
    })

    const alterActive = (num) => {
      activeIndex.value = num;
    }

    const setActiveInput = (index, fields) => {
      activeInput.activeIndex = index;
      activeInput.activeFields = fields;
    }

    const refreashAmount = _.debounce(() => {
      refreashAmountAction()
    }, 0)

    const refreashAmountAction = () => {
      let qty = 0;
      let price = 0;
      if (!AddContentBody[activeIndex.value].OrderQty || AddContentBody[activeIndex.value].OrderQty == '') {
      } else {
        qty = AddContentBody[activeIndex.value].OrderQty
      }
      if (!AddContentBody[activeIndex.value].OutStockQty || AddContentBody[activeIndex.value].OutStockQty == '') {
      } else {
        qty = AddContentBody[activeIndex.value].OutStockQty
      }
      if (!AddContentBody[activeIndex.value].InStockQty || AddContentBody[activeIndex.value].InStockQty == '') {
      } else {
        qty = AddContentBody[activeIndex.value].InStockQty
      }
      if (!AddContentBody[activeIndex.value].UnitPrice || AddContentBody[activeIndex.value].UnitPrice == '') {
      } else {
        price = AddContentBody[activeIndex.value].UnitPrice
      }
      if (AddContentBody[activeIndex.value].CommodityName) {
        AddContentBody[activeIndex.value].Amount = (+qty * +price).toFixed(2)
      }
    }

    const checkQty = (index, e, fieName) => {
      if (!parseInt(e.target.value)) {
        AddContentBody[index][fieName] = 1
      }
    }

    let dicountValue = ref(0)

    let dicountValueCopy = ref(0)

    let valueReceived = ref(0)

    let platformData = reactive({
      platformChoose: '',
      platformMiAmount: '',
      voucherCode: ''
    })

    let valueAfterDiscount = computed(() => {
      return (valueReceived.value - dicountValue.value - (platformData.platformMiAmount || 0)).toFixed(2) + '元'
    })

    const countValueAfterDiscount = (value) => {
      if (value.value.Amount < (dicountValue.value + (platformData.platformMiAmount || 0))) {
        dicountValue.value = (value.value.Amount - (platformData.platformMiAmount || 0)) < 0 ? 0 : (value.value.Amount - (platformData.platformMiAmount || 0))
        checkUpdateForDiscount()
      }
      valueReceived.value = value.value.Amount
    }

    watch(footDatas, () => {
      countValueAfterDiscount(footDatas)
    })

    const checkDiscount = () => {
      if (dicountValue.value + (platformData.platformMiAmount || 0) > valueReceived.value || dicountValue.value < 0) {
        ElMessage('优惠金额不能大于订单金额或为负数')
        dicountValue.value = 0
      }
    }

    let customerSource = ref('')

    let customerSourceCopy = ref('')

    let documentContentCopy = {}

    let AddContentBodyCopy = []

    const openEditArea = (docJude) => {
      if (documentContent.SystemStatusId == 104 || documentContent.SystemStatusId == 103) {
        disableMap.StaffId = false
        disableMap.Remark = false
        disableMap.UnitPrice = false
        disableMap.bodyRemark = false
        disableMap.dicountValue = false
        disableMap.deleteOne = false
        disableMap.deleteAll = false
        disableMap.updateAll = false
        disableMap.recoverBack = true
        return
      }
      switch (docJude) {
        case 'Po':
          disableMap.StaffId = false
          disableMap.Remark = false
          disableMap.UnitPrice = false
          disableMap.bodyRemark = false
          disableMap.dicountValue = false
          disableMap.deleteOne = true
          disableMap.deleteAll = true
          disableMap.updateAll = true
          disableMap.recoverBack = true
          break;
        case 'Ro':
          disableMap.StaffId = false
          disableMap.Remark = false
          disableMap.UnitPrice = false
          disableMap.bodyRemark = false
          disableMap.dicountValue = false
          disableMap.deleteOne = true
          disableMap.SalesAttributeNo = false
          disableMap.customerSource = false
          disableMap.deleteAll = true
          disableMap.updateAll = true
          disableMap.recoverBack = true
          break;
        case 'So':
          disableMap.StaffId = false
          disableMap.Remark = false
          disableMap.UnitPrice = false
          disableMap.bodyRemark = false
          disableMap.dicountValue = false
          disableMap.deleteOne = true
          disableMap.deleteAll = true
          disableMap.updateAll = true
          disableMap.recoverBack = true
          break;
        case 'Rb':
          disableMap.StaffId = false
          disableMap.Remark = false
          disableMap.UnitPrice = false
          disableMap.bodyRemark = false
          disableMap.dicountValue = false
          disableMap.deleteOne = false
          disableMap.deleteAll = true
          disableMap.updateAll = false
          disableMap.recoverBack = true
          break;

        default:

          break;
      }
    }

    const checkPrice = (index, e) => {

      if (docTitleTwo == 'MI' ||
        docTitleTwo == 'MO' ||
        docTitleTwo == 'ER' ||
        docTitleTwo == 'ED' ||
        docTitleTwo == 'GR' ||
        docTitleTwo == 'GR' ||
        docTitleTwo == 'EO' ||
        docTitleTwo == 'IO' ||
        docTitleTwo == 'RB') {
        return
      }
      if (!e.target.value) {
        AddContentBody[index].UnitPrice = 0.01
        AddContentBody[index].Amount = AddContentBody[index].UnitPrice * AddContentBody[index].OrderQty
        return
      }
      if (parseFloat(e.target.value) < 0) {
        AddContentBody[index].UnitPrice = 0.01
        AddContentBody[index].Amount = AddContentBody[index].UnitPrice * AddContentBody[index].OrderQty
        return
      }
      AddContentBody[index].Amount = AddContentBody[index].UnitPrice * AddContentBody[index].OrderQty
    }

    let alertMessage = ref('')

    let alertMoney = ref(0)

    let closeOrUPdate = ref(true)

    const addAlertMessage = (value) => {
      alertMoney.value = value
      if (value == 0) {
        alertMessage.value = '款项无变化'
      }
      if (value > 0) {
        alertMessage.value = `新增收款${value}元,请确认处理方式!`
      }
      else {
        alertMessage.value = `新增退款${-value}元,请确认处理方式!`
      }

    }

    const returnMoney = () => {
      if (alertMoney.value >= 0) {
        return;
      }
      else {
        const partACom = documentContentCopy.PartACompanyId
        const partBCom = documentContentCopy.PartBCompanyId
        const docId = documentContentCopy.DocumentId
        const userId = store.state.loginInitializeData.userId
        const staffId = documentContentCopy.StaffId
        const unitPrice = -alertMoney.value
        let moHead = getBodyInstance('DocumentCenter')
        getDocNo('MO').then(res => {
          let moHeadCode = res
          moHead.DocumentId = moHeadCode
          moHead.DocumentTypeName = transferToCode('付款单', 'DocumentTypeName')
          moHead.PartACompanyId = partACom
          moHead.PartBCompanyId = partBCom
          moHead.RootSourceDocumentId = docId
          moHead.SourceDocumentId = docId
          moHead.UserId = userId
          moHead.StaffId = staffId
          moHead.Qty = 1
          moHead.Remark = '修改或删除订单带来得退款'
          moHead.CapitalAccountId = '主账户'
          moHead.Amount = unitPrice
          moHead.SystemStatusId = 104
          let moBody = [{
            DocumentId: moHeadCode,
            Sn: 1,
            PartACompanyId: partACom,
            PartBCompanyId: partBCom,
            OrderQty: 1,
            InStockQty: 1,
            OutStockQty: 1,
            UnitPrice: unitPrice,
            Amount: unitPrice,
            Remark: '修改或删除订单带来得退款',
            SourceDocumentId: docId,
            RootSourceDocumentId: docId
          }]
          openMoList(moBody, moHead, res).then(res => {
            dbCheckMiDefinite(docId, unitPrice).then(res => {
              soMoneyRef.value.closePopComm()
              if (closeOrUPdate.value) {
                quitThisPage()
              }
              else {
                refreshThisPage(docId)
              }
            })
          })
        })
      }
    }

    const receiveMoney = () => {
      if (alertMoney.value <= 0) {
        return;
      }
      else {
        const partACom = documentContentCopy.PartACompanyId
        const partBCom = documentContentCopy.PartBCompanyId
        const docId = documentContentCopy.DocumentId
        const userId = store.state.loginInitializeData.userId
        const staffId = documentContentCopy.StaffId
        const unitPrice = alertMoney.value
        let miHead = getBodyInstance('DocumentCenter')
        getDocNo('MI').then(res => {
          let miHeadCode = res
          miHead.DocumentId = miHeadCode
          miHead.DocumentTypeName = transferToCode('收款单', 'DocumentTypeName')
          miHead.PartACompanyId = partACom
          miHead.PartBCompanyId = partBCom
          miHead.RootSourceDocumentId = docId
          miHead.SourceDocumentId = docId
          miHead.UserId = userId
          miHead.StaffId = staffId
          miHead.Qty = 1
          miHead.Remark = '修改订单带来得收款'
          miHead.CapitalAccountId = '主账户'
          miHead.Amount = unitPrice
          miHead.SystemStatusId = 104
          let miBody = [{
            DocumentId: miHeadCode,
            Sn: 1,
            PartACompanyId: partACom,
            PartBCompanyId: partBCom,
            OrderQty: 1,
            InStockQty: 1,
            OutStockQty: 1,
            UnitPrice: unitPrice,
            Amount: unitPrice,
            Remark: '修改订单带来得收款',
            SourceDocumentId: docId,
            RootSourceDocumentId: docId
          }]
          openMiList(miBody, miHead, res).then(res => {
            soMoneyRef.value.closePopComm()
            if (closeOrUPdate.value) {
              quitThisPage()
            }
            else {
              refreshThisPage(docId)
            }
          })
        })
      }
    }

    const closePop = () => {
      soMoneyRef.value.closePopComm()
      const docId = documentContentCopy.DocumentId
      if (closeOrUPdate.value) {
        quitThisPage()
      }
      else {
        refreshThisPage(docId)
      }

    }

    let updateList = reactive([])

    const checkUpdateForHead = (fieldsName) => {
      if (documentContent[fieldsName] != documentContentCopy[fieldsName]) {
        if (updateList.length > 0) {
          for (let i = updateList.length - 1; i >= 0; i--) {
            if (updateList[i].position == 'Head' && updateList[i].fieldsName == fieldsName) {
              updateList.splice(i, 1)
            }
          }
        }
        let b = {
          documentId: documentContent.DocumentId,
          sn: 0,
          position: 'Head',
          fieldsName: fieldsName,
          direction: '',
          updateValue: documentContent[fieldsName]
        }
        updateList.push(b)
      }
      else {
        if (updateList.length > 0) {
          for (let i = updateList.length - 1; i >= 0; i--) {
            if (updateList[i].position == 'Head' && updateList[i].fieldsName == fieldsName) {
              updateList.splice(i, 1)
            }
          }
        }
      }
    }

    const checkUpdateForDiscount = () => {
      setTimeout(() => {
        if (dicountValue.value != dicountValueCopy.value) {
          if (updateList.length > 0) {
            for (let i = updateList.length - 1; i >= 0; i--) {
              if (updateList[i].position == 'Discount') {
                updateList.splice(i, 1)
              }
            }
          }
          let b = {
            documentId: documentContent.DocumentId,
            sn: 0,
            position: 'Discount',
            fieldsName: 'Discount',
            direction: dicountValue.value > dicountValueCopy.value ? 'up' : 'down',
            updateValue: dicountValue.value
          }
          updateList.push(b)
        }
        else {
          if (updateList.length > 0) {
            for (let i = updateList.length - 1; i >= 0; i--) {
              if (updateList[i].position == 'Discount') {
                updateList.splice(i, 1)
              }
            }
          }
        }
      }, 0);
    }
    const checkUpdateForCustomerSource = () => {
      if (customerSource.value != customerSourceCopy.value) {
        if (updateList.length > 0) {
          for (let i = updateList.length - 1; i >= 0; i--) {
            if (updateList[i].position == 'customerSource') {
              updateList.splice(i, 1)
            }
          }
        }
        let b = {
          documentId: documentContent.DocumentId,
          sn: 0,
          position: 'customerSource',
          fieldsName: 'customerSource',
          direction: '',
          updateValue: customerSource.value
        }
        updateList.push(b)
      }
      else {
        if (updateList.length > 0) {
          for (let i = updateList.length - 1; i >= 0; i--) {
            if (updateList[i].position == 'customerSource') {
              updateList.splice(i, 1)
            }
          }
        }
      }
    }

    const checkUpdateForObInfo = (fieldsName) => {
      setTimeout(() => {
        if (!CustomerList.hasOrNot) {
          return;
        }
        else {
          if (CustomerList[fieldsName] != CustomerList[fieldsName]) {
            if (updateList.length > 0) {
              for (let i = updateList.length - 1; i >= 0; i--) {
                if (updateList[i].position == 'CustomerList' && updateList[i].fieldsName == fieldsName) {
                  updateList.splice(i, 1)
                }
              }
            }

            let direc = ''
            if (fieldsName == 'CustomerDeposit') {
              CustomerList.CustomerDeposit > CustomerListCopy.CustomerDeposit
              direc = 'up'
            }
            else {
              direc = 'down'
            }

            let b = {
              documentId: documentContent.DocumentId,
              sn: 0,
              position: 'CustomerList',
              fieldsName: fieldsName,
              direction: direc,
              updateValue: CustomerList[fieldsName]
            }
            updateList.push(b)
          }
          else {
            if (updateList.length > 0) {
              for (let i = updateList.length - 1; i >= 0; i--) {
                if (updateList[i].position == 'CustomerList' && updateList[i].fieldsName == fieldsName) {
                  updateList.splice(i, 1)
                }
              }
            }
          }
        }
      }, 0);
    }

    const checkUpdateForBody = (fieldsName, Sn, index) => {

      setTimeout(() => {
        if (AddContentBody[index][fieldsName] != AddContentBodyCopy[index][fieldsName]) {
          if (updateList.length > 0) {
            for (let i = updateList.length - 1; i >= 0; i--) {
              if (updateList[i].position == 'Body' && updateList[i].fieldsName == fieldsName && updateList[i].sn == Sn) {
                updateList.splice(i, 1)
              }
            }
          }
          let direc = ''
          if (fieldsName == 'OrderQty' || fieldsName == 'OutStockQty' || fieldsName == 'InStockQty' || fieldsName == 'UnitPrice') {
            if (AddContentBody[index][fieldsName] > AddContentBodyCopy[index][fieldsName]) {
              direc = 'up'
            }
            else {
              direc = 'down'
            }
          }
          let b = {
            documentId: documentContent.DocumentId,
            sn: Sn,
            position: 'Body',
            fieldsName: fieldsName,
            direction: direc,
            updateValue: AddContentBody[index][fieldsName]
          }
          updateList.push(b)

        }
        else {
          if (updateList.length > 0) {
            for (let i = updateList.length - 1; i >= 0; i--) {
              if (updateList[i].position == 'Body' && updateList[i].fieldsName == fieldsName && updateList[i].sn == Sn) {
                updateList.splice(i, 1)
              }
            }
          }
        }
      }, 0);
    }

    const recoverToOrigin = () => {
      AddContentBody.splice(0)
      for (let item of AddContentBodyCopy) {
        let objNew = {};
        for (let vl in item) {
          if (vl == 'SalesAttributeNoArr') {
            objNew[vl] = copyArray(item[vl])
          }
          else {
            objNew[vl] = item[vl]
          }
        }
        AddContentBody.push(objNew)
      }

      for (let b in documentContentCopy) {
        documentContent[b] = documentContentCopy[b]
      }

      for (let b in CustomerListCopy) {
        CustomerList[b] = CustomerListCopy[b]
      }

      dicountValue.value = dicountValueCopy.value
      customerSource.value = customerSourceCopy.value
      updateList.splice(0)
    }

    const checkPoLine = (docId, sn, stockBalanceDecrease) => {
      return new Promise(function (resolve, reject) {
        if (parseFloat(valueAfterDiscount.value) < 0) {
          resolve('优惠后金额不可小于0')
        }
        else {
          checkPoLineBeforeEdit(docId, sn, stockBalanceDecrease).then(res => {
            resolve(res)
          })
        }
      })
    }

    const checkSoLine = (docId, loseValue) => {
      return new Promise(function (resolve, reject) {
        if (parseFloat(valueAfterDiscount.value) < 0) {
          resolve('优惠后金额不可小于0')
        }
        else {
          checkSoLineDelete(docId, loseValue).then(res => {
            resolve(res)
          })
        }
      })
    }

    const checkRoLine = (docId, sn) => {
      return new Promise(function (resolve, reject) {
        if (parseFloat(valueAfterDiscount.value) < 0) {
          resolve('优惠后金额不可小于0')
        }
        else {
          checkRoLineDelete(docId, sn).then(res => {
            resolve(res)
          })
        }
      })
    }

    const checkRbLine = (docId) => {
      return new Promise(function (resolve, reject) {
        checkRbLineDelete(docId).then(res => {
          resolve(res)
        })
      })
    }

    const deleteOnePoLine = (docId, sn) => {
      return new Promise(function (resolve, reject) {
        deleteOnePoLineTrue(docId, sn).then(res => {
          resolve(res)
        })
      })
    }

    const deleteOneSoLine = (docId, sn) => {
      return new Promise(function (resolve, reject) {
        deleteOneSoLineTrue(docId, sn).then(res => {
          resolve(res)
        })
      })
    }

    const deleteOneRoLine = (docId, sn) => {
      return new Promise(function (resolve, reject) {
        if (platformData.platformChoose != '') {
          resolve('该订单涉及平台收款，无法删除')
        }
        else {
          deleteOneRoLineTrue(docId, sn).then(res => {
            resolve(res)
          })
        }
      })
    }

    const deleteOneRbLine = (docId, sn) => {
      return new Promise(function (resolve, reject) {
        deleteOneRbLineTrue(docId, sn).then(res => {
          resolve(res)
        })
      })
    }

    const updateDocumentCenter = (docId, addQty, addAmount) => {
      return new Promise(function (resolve, reject) {
        updateDocCenter(docId, addQty, addAmount).then(res => {
          resolve(res)
        })
      })
    }

    const deleteDocumentCenter = (docId) => {
      return new Promise(function (resolve, reject) {
        fakeDeleteDocumentCenter(docId).then(res => {
          resolve(res)
        })
      })
    }

    const updatePoDiscoutCheck = () => {
      return new Promise(function (resolve, reject) {
        resolve('pass')
      })
    }

    const updateDiscoutCheck = (docId, changeValue) => {
      return new Promise(function (resolve, reject) {
        updateDiscoutCheckReal(docId, changeValue).then(res => {
          resolve(res)
        })
      })
    }

    const updatePoDiscout = (changeValue) => {
      const partACom = documentContentCopy.PartACompanyId
      const partBCom = documentContentCopy.PartBCompanyId
      const docId = documentContentCopy.DocumentId
      const userId = store.state.loginInitializeData.userId
      const staffId = documentContentCopy.StaffId
      let docHeadCopy2 = getBodyInstance('DocumentCenter')
      return new Promise(function (resolve, reject) {
        if (changeValue == 0) {
          resolve('优惠无修改')
        }
        else {
          if (changeValue > 0) {
            getDocNo('ER').then(res => {
              const erHead = res
              docHeadCopy2.DocumentId = erHead
              docHeadCopy2.DocumentTypeName = transferToCode('应付减少单', 'DocumentTypeName')
              docHeadCopy2.PartACompanyId = partACom
              docHeadCopy2.PartBCompanyId = partBCom
              docHeadCopy2.UserId = userId
              docHeadCopy2.StaffId = staffId
              docHeadCopy2.Amount = changeValue
              docHeadCopy2.Qty = 1
              docHeadCopy2.RootSourceDocumentId = docId
              docHeadCopy2.SourceDocumentId = docId
              docHeadCopy2.Remark = `采购优惠增加，金额为${changeValue}`
              docHeadCopy2.SystemStatusId = 104
              let erBody = [{
                DocumentId: erHead,
                Sn: 1,
                PartACompanyId: partACom,
                PartBCompanyId: partBCom,
                OrderQty: 1,
                InStockQty: 1,
                OutStockQty: 1,
                UnitPrice: changeValue,
                Remark: `采购优惠增加，金额为${changeValue}`,
                SourceDocumentId: docId,
                RootSourceDocumentId: docId
              }]
              openErList(erBody, docHeadCopy2, erHead).then(res => {
                resolve(res)
              })
            })
          }
          else {
            getDocNo('ED').then(res => {
              const edHead = res
              docHeadCopy2.DocumentId = edHead
              docHeadCopy2.DocumentTypeName = transferToCode('应付增加单', 'DocumentTypeName')
              docHeadCopy2.PartACompanyId = partACom
              docHeadCopy2.PartBCompanyId = partBCom
              docHeadCopy2.UserId = userId
              docHeadCopy2.StaffId = staffId
              docHeadCopy2.Amount = -changeValue
              docHeadCopy2.Qty = 1
              docHeadCopy2.RootSourceDocumentId = docId
              docHeadCopy2.SourceDocumentId = docId
              docHeadCopy2.Remark = `采购优惠减少，金额为${changeValue}`,
                docHeadCopy2.SystemStatusId = 104
              let edBody = [{
                DocumentId: edHead,
                Sn: 1,
                PartACompanyId: partACom,
                PartBCompanyId: partBCom,
                OrderQty: 1,
                InStockQty: 1,
                OutStockQty: 1,
                UnitPrice: -changeValue,
                Remark: `采购优惠减少，金额为${changeValue}`,
                SourceDocumentId: docId,
                RootSourceDocumentId: docId
              }]
              openEdList(edBody, docHeadCopy2, edHead).then(res => {
                resolve(res)
              })
            })
          }
        }
      })
    }

    const updateSoDiscout = (changeValue) => {
      const partACom = documentContentCopy.PartACompanyId
      const partBCom = documentContentCopy.PartBCompanyId
      const docId = documentContentCopy.DocumentId
      const userId = store.state.loginInitializeData.userId
      const staffId = documentContentCopy.StaffId
      let docHeadCopy2 = getBodyInstance('DocumentCenter')
      return new Promise(function (resolve, reject) {
        if (changeValue == 0) {
          resolve('优惠无修改')
        }
        else {
          if (changeValue > 0) {
            getDocNo('GR').then(res => {
              const grHead = res
              docHeadCopy2.DocumentId = grHead
              docHeadCopy2.DocumentTypeName = transferToCode('应收减少单', 'DocumentTypeName')
              docHeadCopy2.PartACompanyId = partACom
              docHeadCopy2.PartBCompanyId = partBCom
              docHeadCopy2.UserId = userId
              docHeadCopy2.StaffId = staffId
              docHeadCopy2.Amount = changeValue
              docHeadCopy2.Qty = 1
              docHeadCopy2.RootSourceDocumentId = docId
              docHeadCopy2.SourceDocumentId = docId
              docHeadCopy2.Remark = '销售折扣'
              docHeadCopy2.SystemStatusId = 104
              let grBody = [{
                DocumentId: grHead,
                Sn: 1,
                PartACompanyId: partACom,
                PartBCompanyId: partBCom,
                OrderQty: 1,
                InStockQty: 1,
                OutStockQty: 1,
                UnitPrice: changeValue,
                Remark: `销售折扣增加,金额为${changeValue}`,
                SourceDocumentId: docId,
                RootSourceDocumentId: docId
              }]
              openGrList(grBody, docHeadCopy2, grHead).then(res => {
                resolve(res)
              })
            })
          }
          else {
            getDocNo('GD').then(res => {
              const gdHead = res
              docHeadCopy2.DocumentId = gdHead
              docHeadCopy2.DocumentTypeName = transferToCode('应收增加单', 'DocumentTypeName')
              docHeadCopy2.PartACompanyId = partACom
              docHeadCopy2.PartBCompanyId = partBCom
              docHeadCopy2.UserId = userId
              docHeadCopy2.StaffId = staffId
              docHeadCopy2.Amount = -changeValue
              docHeadCopy2.Qty = 1
              docHeadCopy2.RootSourceDocumentId = docId
              docHeadCopy2.SourceDocumentId = docId
              docHeadCopy2.Remark = '销售折扣'
              docHeadCopy2.SystemStatusId = 104
              let gdBody = [{
                DocumentId: gdHead,
                Sn: 1,
                PartACompanyId: partACom,
                PartBCompanyId: partBCom,
                OrderQty: 1,
                InStockQty: 1,
                OutStockQty: 1,
                UnitPrice: -changeValue,
                Remark: `销售折扣减少,金额为${changeValue}`,
                SourceDocumentId: docId,
                RootSourceDocumentId: docId
              }]
              openGdList(gdBody, docHeadCopy2, gdHead).then(res => {
                resolve(res)
              })
            })
          }
        }
      })
    }

    const updateStaffCheck = (newStaffId) => {
      return new Promise(function (resolve, reject) {
        updateStaffIdCheck(documentContentCopy.DocumentId, newStaffId).then(res => {
          resolve(res)
        })
      })
    }

    const updateStaff = (newStaffId) => {
      return new Promise(function (resolve, reject) {
        updateStaffId(documentContentCopy.DocumentId, newStaffId).then(res => {
          resolve(res)
        })
      })
    }

    const updateHeadRemarkCheck = () => {
      return new Promise(function (resolve, reject) {
        resolve('pass')
      })
    }

    const updateHeadRemark = (newHeadRemark) => {
      return new Promise(function (resolve, reject) {
        updateHeadRemarkReal(documentContentCopy.DocumentId, newHeadRemark).then(res => {
          resolve(res)
        })
      })
    }

    const updatePoBodyRemarkCheck = () => {
      return new Promise(function (resolve, reject) {
        resolve('pass')
      })
    }

    const updateSoBodyRemarkCheck = () => {
      return new Promise(function (resolve, reject) {
        resolve('pass')
      })
    }

    const updateRoBodyRemarkCheck = () => {
      return new Promise(function (resolve, reject) {
        resolve('pass')
      })
    }

    const updatePoBodyRemark = (docId, sn, newRemark) => {
      return new Promise(function (resolve, reject) {
        updatePoBodyRemarkReal(docId, sn, newRemark).then(res => {
          resolve(res)
        })
      })
    }

    const updateSoBodyRemark = (docId, sn, newRemark) => {
      return new Promise(function (resolve, reject) {
        updateSoBodyRemarkReal(docId, sn, newRemark).then(res => {
          resolve(res)
        })
      })
    }

    const updateRoBodyRemark = (docId, sn, newRemark) => {
      return new Promise(function (resolve, reject) {
        updateRoBodyRemarkReal(docId, sn, newRemark).then(res => {
          resolve(res)
        })
      })
    }

    const updatePoLineUnitPriceCheck = (docId, sn, priceChange) => {
      return new Promise(function (resolve, reject) {
        updatePoLineUnitPriceCheckReal(docId, sn, priceChange).then(res => {
          resolve(res)
        })
      })
    }

    const updateSoLineUnitPriceCheck = (docId, sn, priceChange) => {
      return new Promise(function (resolve, reject) {
        updateSoLineUnitPriceCheckReal(docId, sn, priceChange).then(res => {
          resolve(res)
        })
      })
    }

    const updateRoLineUnitPriceCheck = (docId, sn, priceChange) => {
      return new Promise(function (resolve, reject) {
        updateRoLineUnitPriceCheckReal(docId, sn, priceChange).then(res => {
          resolve(res)
        })
      })
    }

    const updatePoLineUnitPrice = (docId, sn, priceChange) => {
      return new Promise(function (resolve, reject) {
        updatePoLineUnitPriceReal(docId, sn, priceChange).then(res => {
          resolve(res)
        })
      })
    }

    const updateSoLineUnitPrice = (docId, sn, priceChange) => {
      return new Promise(function (resolve, reject) {
        updateSoLineUnitPriceReal(docId, sn, priceChange).then(res => {
          resolve(res)
        })
      })
    }

    const updateRoLineUnitPrice = (docId, sn, priceChange) => {
      return new Promise(function (resolve, reject) {
        updateRoLineUnitPriceReal(docId, sn, priceChange).then(res => {
          resolve(res)
        })
      })
    }

    const updateRoSaleAttrCheck = (docId, sn, newAttr) => {
      return new Promise(function (resolve, reject) {
        updateRoSaleAttrCheckReal(docId, sn, newAttr).then(res => {
          resolve(res)
        })
      })
    }

    const updateRoSaleAttr = (docId, sn, newAttr) => {
      return new Promise(function (resolve, reject) {
        updateRoSaleAttrReal(docId, sn, newAttr).then(res => {
          resolve(res)
        })
      })
    }

    const updateCustomerSourceCheck = (docId, newSource) => {
      return new Promise(function (resolve, reject) {
        resolve('pass')
      })
    }

    const updateCustomerSource = (docId, newSource) => {
      return new Promise(function (resolve, reject) {
        updateCustomerSourceReal(docId, newSource).then(res => {
          resolve(res)
        })
      })
    }

    const refreshThisPage = (docId) => {
      let b = {
        documentId: docId
      }
      fillDataFromDocCenter(b)
    }

    const quitThisPage = () => {
      let code = changeToViewCode('DocumentCenter')
      store.commit('GoFunctionWindowWithCode', code)

      let tableCode = changeToViewCode('EditOrder')
      store.commit('CloseActiveWindow', tableCode)
    }




    const deleteOneLine = (index) => {
      const b = confirm('删除后无法恢复，确认删除?')
      if (!b) {
        return
      }
      const body = AddContentBodyCopy[index]
      let comdiArr = []
      if (!body?.VirtualGoods) {
        comdiArr.push(body.CommodityId)
      }
      const docId = body.DocumentId
      const sn = body.Sn
      const unitPrice = body.UnitPrice
      const qty = body.OrderQty
      let totalLine = 0
      let amountChange = 0
      for (let item of AddContentBodyCopy) {
        if (item.DocumentId) {
          totalLine += 1
        }
      }
      const docType = body.DocumentId.substring(0, 2)
      switch (docType) {
        case 'PO':
          checkPoLine(docId, sn, qty).then(res => {
            if (res != 'pass') {
              ElMessage(res)
              return;
            }
            else {
              deleteOnePoLine(docId, sn).then(res => {
                if (res != 'pass') {
                  ElMessage(res)
                  return;
                }
                else {
                  setTimeout(() => {
                    updateWarning(documentContentCopy.PartACompanyId, comdiArr)
                  }, 0);
                  if (totalLine > 1) {
                    updateDocumentCenter(docId, -qty, -qty * unitPrice).then(res => {
                      if (dicountValueCopy.value > 0) {
                        let deleteDiscount = confirm('是否同时清除优惠')
                        if (!deleteDiscount) {
                          ElMessage('删除成功')
                          refreshThisPage(docId)
                        }
                        else {
                          updatePoDiscout(-dicountValueCopy.value).then(res => {
                            ElMessage('删除成功')
                            refreshThisPage(docId)
                          })
                        }
                      }
                      else {
                        ElMessage('删除成功')
                        refreshThisPage(docId)
                      }
                    })
                  }
                  else {
                    deleteDocumentCenter(docId).then(res => {
                      if (dicountValueCopy.value > 0) {
                        updatePoDiscout(-dicountValueCopy.value).then(res => {
                          ElMessage('删除成功,并清除了优惠')
                          quitThisPage()
                        })
                      }
                      else {
                        ElMessage('删除成功')
                        quitThisPage()
                      }
                    })
                  }
                }
              })
            }
          })
          break;
        case 'SO':
          checkSoLine(docId, qty * unitPrice).then(res => {
            if (res != 'pass') {
              ElMessage(res)
              return;
            }
            else {
              deleteOneSoLine(docId, sn).then(res => {
                if (res != 'pass') {
                  ElMessage(res)
                  return;
                }
                else {
                  setTimeout(() => {
                    updateWarning(documentContentCopy.PartACompanyId, comdiArr)
                  }, 0);
                  if (totalLine > 1) {
                    updateDocumentCenter(docId, -qty, -qty * unitPrice).then(res => {
                      if (dicountValueCopy.value > 0) {
                        let deleteDiscount = confirm('是否同时清除优惠')
                        if (!deleteDiscount) {
                          amountChange = -qty * unitPrice
                          closeOrUPdate.value = false
                          addAlertMessage(amountChange)
                          soMoneyRef.value.openPopComm()
                        }
                        else {
                          updateSoDiscout(-dicountValueCopy.value).then(res => {
                            amountChange = -qty * unitPrice + dicountValueCopy.value
                            closeOrUPdate.value = false
                            addAlertMessage(amountChange)
                            soMoneyRef.value.openPopComm()
                          })
                        }
                      }
                      else {
                        amountChange = -qty * unitPrice
                        closeOrUPdate.value = false
                        addAlertMessage(amountChange)
                        soMoneyRef.value.openPopComm()
                      }
                    })
                  }
                  else {
                    deleteDocumentCenter(docId).then(res => {
                      if (dicountValueCopy.value > 0) {
                        updateSoDiscout(-dicountValueCopy.value).then(res => {
                          amountChange = -(documentContentCopy.Amount - dicountValueCopy.value)
                          closeOrUPdate.value = true
                          addAlertMessage(amountChange)
                          soMoneyRef.value.openPopComm()

                        })
                      }
                      else {
                        amountChange = -documentContentCopy.Amount
                        closeOrUPdate.value = true
                        addAlertMessage(amountChange)
                        soMoneyRef.value.openPopComm()
                      }
                    })
                  }
                }
              })
            }
          })
          break;
        case 'RO':
          checkRoLine(docId, sn).then(res => {
            if (res != 'pass') {
              ElMessage(res)
              return;
            }
            else {
              deleteOneRoLine(docId, sn).then(res => {
                if (res != 'pass') {
                  ElMessage(res)
                  return;
                }
                else {
                  setTimeout(() => {
                    updateWarning(documentContentCopy.PartACompanyId, comdiArr)
                  }, 0);
                  if (totalLine > 1) {
                    updateDocumentCenter(docId, -qty, -qty * unitPrice).then(res => {
                      if (dicountValueCopy.value > 0) {
                        let deleteDiscount = confirm('是否同时清除优惠')
                        if (!deleteDiscount) {
                          amountChange = -qty * unitPrice
                          closeOrUPdate.value = false
                          addAlertMessage(amountChange)
                          soMoneyRef.value.openPopComm()
                        }
                        else {
                          updateSoDiscout(-dicountValueCopy.value).then(res => {
                            amountChange = -qty * unitPrice + dicountValueCopy.value
                            closeOrUPdate.value = false
                            addAlertMessage(amountChange)
                            soMoneyRef.value.openPopComm()
                          })
                        }
                      }
                      else {
                        amountChange = -qty * unitPrice
                        closeOrUPdate.value = false
                        addAlertMessage(amountChange)
                        soMoneyRef.value.openPopComm()
                      }
                    })
                  }
                  else {
                    deleteDocumentCenter(docId).then(res => {
                      if (dicountValueCopy.value > 0) {
                        updateSoDiscout(-dicountValueCopy.value).then(res => {
                          amountChange = -(documentContentCopy.Amount - dicountValueCopy.value)
                          closeOrUPdate.value = true
                          addAlertMessage(amountChange)
                          soMoneyRef.value.openPopComm()

                        })
                      }
                      else {
                        amountChange = -documentContentCopy.Amount
                        closeOrUPdate.value = true
                        addAlertMessage(amountChange)
                        soMoneyRef.value.openPopComm()
                      }
                    })
                  }
                }
              })
            }
          })
          break;
        default:
          break;
      }
    }

    const deleteAll = () => {
      const b = confirm('删除后无法恢复，确认删除?')
      if (!b) {
        return
      }
      let totalLine = 0;
      for (let item3 of AddContentBodyCopy) {
        if (item3.DocumentId) {
          totalLine += 1
        }
      }
      const docId = documentContentCopy.DocumentId

      if (docId.substring(0, 2) == 'RO') {
        if (platformData.platformChoose != '') {
          ElMessage('该订单涉及平台收款，无法删除')
          return
        }
      }

      let commodityArr = []
      for (let item of AddContentBodyCopy) {
        if (!item?.VirtualGoods && item.CommodityId) {
          commodityArr.push(item.CommodityId)
        }
      }

      let checkList = []
      let actionList = []
      let checkMsg = ''
      let actionMsg = ''
      let actionArrIndex = []
      let amountChange = 0
      let loseAmount = documentContentCopy.Amount - dicountValueCopy.value
      const docType = docId.substring(0, 2)
      switch (docType) {
        case 'PO':
          for (let item of AddContentBodyCopy) {
            if (item.DocumentId) {
              const b = checkPoLine(docId, item.Sn, item.OrderQty)
              checkList.push(b)
            }
          }
          Promise.all(checkList).then(errArr => {
            if (errArr.length > 0) {
              for (let l = 0; l < errArr.length; l++) {
                if (errArr[l] == 'pass') {
                  continue;
                }
                else {
                  checkMsg += `第${parseInt(l) + 1}行-` + errArr[l] + '\n'
                }
              }
            }
            if (checkMsg != '') {
              ElMessage(checkMsg)
              return;
            }
            else {
              for (let item of AddContentBodyCopy) {
                if (item.DocumentId) {
                  const c = deleteOnePoLine(docId, item.Sn)
                  actionList.push(c)
                }
              }
              Promise.all(actionList).then(feedbackArr => {
                setTimeout(() => {
                  updateWarning(documentContentCopy.PartACompanyId, commodityArr)
                }, 0);

                for (let j = 0; j < feedbackArr.length; j++) {
                  if (feedbackArr[j] != 'pass') {
                    actionMsg += `第${parseInt(j) + 1}行-${feedbackArr[j]}`
                    actionArrIndex.push(j)
                  }
                  else {
                    continue;
                  }
                }
                if (actionArrIndex.length == 0) {
                  deleteDocumentCenter(docId).then(res => {
                    if (dicountValueCopy.value > 0) {
                      updatePoDiscout(-dicountValueCopy.value).then(res => {
                        ElMessage('删除成功,并清除了优惠')
                        quitThisPage()
                      })
                    }
                    else {
                      ElMessage('删除成功')
                      quitThisPage()
                    }
                  })
                }
                else {
                  let decreaseQty = 0;
                  let decreaseAmount = 0;
                  for (let k = 0; k < totalLine; k++) {
                    if (actionArrIndex.indexOf(k) < 0) {
                      decreaseQty += AddContentBodyCopy[k].OrderQty
                      decreaseAmount += AddContentBodyCopy[k].OrderQty * AddContentBodyCopy[k].UnitPrice
                    }
                  }
                  updateDocumentCenter(docId, -decreaseQty, -decreaseAmount).then(res => {
                    ElMessage(actionMsg)
                    refreshThisPage(docId)
                  })
                }
              })
            }
          })
          break;
        case 'SO':
          const b = checkSoLine(docId, loseAmount)
          checkList.push(b)
          Promise.all(checkList).then(errArr => {
            if (errArr.length > 0) {
              for (let l = 0; l < errArr.length; l++) {
                if (errArr[l] == 'pass') {
                  continue;
                }
                else {
                  checkMsg += errArr[l]
                }
              }
            }
            if (checkMsg != '') {
              ElMessage(checkMsg)
              return;
            }
            else {
              for (let item of AddContentBodyCopy) {
                if (item.DocumentId) {
                  const c = deleteOneSoLine(docId, item.Sn)
                  actionList.push(c)
                }
              }
              Promise.all(actionList).then(feedbackArr => {
                setTimeout(() => {
                  updateWarning(documentContentCopy.PartACompanyId, commodityArr)
                }, 0);
                for (let j = 0; j < feedbackArr.length; j++) {
                  if (feedbackArr[j] != 'pass') {
                    actionMsg += `第${parseInt(j) + 1}行-${feedbackArr[j]}`
                    actionArrIndex.push(j)
                  }
                  else {
                    continue;
                  }
                }
                if (actionArrIndex.length == 0) {
                  deleteDocumentCenter(docId).then(res => {
                    if (dicountValueCopy.value > 0) {
                      updateSoDiscout(-dicountValueCopy.value).then(res => {
                        amountChange = -(documentContentCopy.Amount - dicountValueCopy.value)
                        closeOrUPdate.value = true
                        addAlertMessage(amountChange)
                        soMoneyRef.value.openPopComm()
                      })
                    }
                    else {
                      amountChange = -documentContentCopy.Amount
                      closeOrUPdate.value = true
                      addAlertMessage(amountChange)
                      soMoneyRef.value.openPopComm()
                    }
                  })
                }
                else {
                  let decreaseQty = 0;
                  let decreaseAmount = 0;
                  for (let k = 0; k < totalLine; k++) {
                    if (actionArrIndex.indexOf(k) < 0) {
                      decreaseQty += AddContentBodyCopy[k].OrderQty
                      decreaseAmount += AddContentBodyCopy[k].OrderQty * AddContentBodyCopy[k].UnitPrice
                    }
                  }
                  updateDocumentCenter(docId, -decreaseQty, -decreaseAmount).then(res => {
                    ElMessage(actionMsg)
                    amountChange = -decreaseAmount
                    closeOrUPdate.value = false
                    addAlertMessage(amountChange)
                    soMoneyRef.value.openPopComm()
                  })
                }
              })
            }
          })
          break;
        case 'RO':
          for (let item of AddContentBodyCopy) {
            if (item.DocumentId) {
              const b = checkRoLine(docId, item.Sn)
              checkList.push(b)
            }
          }
          Promise.all(checkList).then(errArr => {
            if (errArr.length > 0) {
              for (let l = 0; l < errArr.length; l++) {
                if (errArr[l] == 'pass') {
                  continue;
                }
                else {
                  checkMsg += errArr[l]
                }
              }
            }
            if (checkMsg != '') {
              ElMessage(checkMsg)
              return;
            }
            else {
              for (let item of AddContentBodyCopy) {
                if (item.DocumentId) {
                  const c = deleteOneRoLine(docId, item.Sn)
                  actionList.push(c)
                }
              }
              Promise.all(actionList).then(feedbackArr => {
                setTimeout(() => {
                  updateWarning(documentContentCopy.PartACompanyId, commodityArr)
                }, 0);
                for (let j = 0; j < feedbackArr.length; j++) {
                  if (feedbackArr[j] != 'pass') {
                    actionMsg += `第${parseInt(j) + 1}行-${feedbackArr[j]}`
                    actionArrIndex.push(j)
                  }
                  else {
                    continue;
                  }
                }
                if (actionArrIndex.length == 0) {
                  deleteDocumentCenter(docId).then(res => {
                    if (dicountValueCopy.value > 0) {
                      updateSoDiscout(-dicountValueCopy.value).then(res => {
                        amountChange = -(documentContentCopy.Amount - dicountValueCopy.value)
                        closeOrUPdate.value = true
                        addAlertMessage(amountChange)
                        soMoneyRef.value.openPopComm()
                      })
                    }
                    else {
                      amountChange = -documentContentCopy.Amount
                      closeOrUPdate.value = true
                      addAlertMessage(amountChange)
                      soMoneyRef.value.openPopComm()
                    }
                  })
                }
                else {
                  let decreaseQty = 0;
                  let decreaseAmount = 0;
                  for (let k = 0; k < totalLine; k++) {
                    if (actionArrIndex.indexOf(k) < 0) {
                      decreaseQty += AddContentBodyCopy[k].OrderQty
                      decreaseAmount += AddContentBodyCopy[k].OrderQty * AddContentBodyCopy[k].UnitPrice
                    }
                  }
                  updateDocumentCenter(docId, -decreaseQty, -decreaseAmount).then(res => {
                    ElMessage(actionMsg)
                    amountChange = -decreaseAmount
                    closeOrUPdate.value = false
                    addAlertMessage(amountChange)
                    soMoneyRef.value.openPopComm()
                  })
                }
              })
            }
          })
          break;
        case 'RB':
          for (let item of AddContentBodyCopy) {
            if (item.DocumentId) {
              const b = checkRbLine(docId)
              checkList.push(b)
            }
          }
          Promise.all(checkList).then(errArr => {
            if (errArr.length > 0) {
              for (let l = 0; l < errArr.length; l++) {
                if (errArr[l] == 'pass') {
                  continue;
                }
                else {
                  checkMsg += `第${parseInt(l) + 1}行-` + errArr[l] + '\n'
                }
              }
            }
            if (checkMsg != '') {
              ElMessage(checkMsg)
              return;
            }
            else {
              for (let item of AddContentBodyCopy) {
                if (item.DocumentId) {
                  const c = deleteOneRbLine(docId, item.Sn)
                  actionList.push(c)
                }
              }
              Promise.all(actionList).then(feedbackArr => {
                for (let j = 0; j < feedbackArr.length; j++) {
                  if (feedbackArr[j] != 'pass') {
                    ElMessage(feedbackArr[j])
                    return
                  }
                  else {
                    deleteDocumentCenter(docId).then(res => {
                      const config = {
                        method: "delete",
                        url: "/MoneyToBePayImmediateList/DeleteByDocId",
                        params: {
                          docId: docId
                        }
                      };
                      
                      requestData.GetCache(config).then(res => {
                      })
                      ElMessage('删除成功')
                      quitThisPage()
                    })
                  }
                }
              })
            }
          })
          break;
        default:
          break;
      }
    }
    const updateAllChange = () => {
      if (updateList.length == 0) {
        ElMessage('无更新内容')
        return;
      }
      const b = confirm('确认更新?')
      if (!b) {
        return
      }

      if (parseFloat(valueAfterDiscount.value) < 0) {
        ElMessage('实收金额不能低于0元')
        return
      }
      const docId = documentContentCopy.DocumentId
      const docType = docId.substring(0, 2)
      let checkList = []
      let actionList = []
      let checkMsg = ''
      let actionMsg = ''
      let actionArrIndex = []
      let lostAmount = (documentContentCopy.Amount - dicountValueCopy.value) - (footDatas.value.Amount - dicountValue.value)
      let amountChange1 = 0

      switch (docType) {
        case 'PO':
          for (let item of updateList) {
            if (item.position == 'Head' && item.fieldsName == 'Remark') {
              let b = updateHeadRemarkCheck()
              checkList.push(b)
              continue
            }

            if (item.position == 'Body' && item.fieldsName == 'Remark') {
              let b = updatePoBodyRemarkCheck()
              checkList.push(b)
              continue;
            }
            if (item.position == 'Discount') {
              let b = updatePoDiscoutCheck()
              checkList.push(b)
              continue;
            }
            if (item.position == 'Body' && item.fieldsName == 'UnitPrice') {
              const oldPrice = AddContentBodyCopy.find(x => x.DocumentId == item.documentId && x.Sn == item.sn)?.UnitPrice
              const priceChange = parseFloat(item.updateValue) - parseFloat(oldPrice)
              let b = updatePoLineUnitPriceCheck(item.documentId, item.sn, priceChange)
              checkList.push(b)
              continue;
            }
            if (item.position == 'Head' && item.fieldsName == 'StaffId') {
              let b = updateStaffCheck(item.updateValue)
              checkList.push(b)
              continue
            }
            let b = updatePoDiscoutCheck()
            checkList.push(b)
          }
          Promise.all(checkList).then(errArr => {
            if (errArr.length > 0) {
              for (let l = 0; l < errArr.length; l++) {
                if (errArr[l] == 'pass') {
                  continue;
                }
                else {
                  checkMsg += errArr[l] + '\n'
                }
              }
            }
            if (checkMsg != '') {
              ElMessage(checkMsg)
              return;
            }
            else {
              for (let item of updateList) {
                if (item.position == 'Head' && item.fieldsName == 'Remark') {
                  let b = updateHeadRemark(item.updateValue)
                  actionList.push(b)
                  continue
                }

                if (item.position == 'Body' && item.fieldsName == 'Remark') {
                  let b = updatePoBodyRemark(item.documentId, item.sn, item.updateValue)
                  actionList.push(b)
                  continue;
                }
                if (item.position == 'Discount') {
                  let b = updatePoDiscout(parseFloat(item.updateValue || 0) - parseFloat(dicountValueCopy.value))
                  actionList.push(b)
                  continue;
                }
                if (item.position == 'Body' && item.fieldsName == 'UnitPrice') {
                  const oldPrice = AddContentBodyCopy.find(x => x.DocumentId == item.documentId && x.Sn == item.sn)?.UnitPrice
                  const priceChange = parseFloat(item.updateValue) - parseFloat(oldPrice)
                  let b = updatePoLineUnitPrice(item.documentId, item.sn, priceChange)
                  actionList.push(b)
                  continue;
                }
                if (item.position == 'Head' && item.fieldsName == 'StaffId') {
                  let b = updateStaff(item.updateValue)
                  actionList.push(b)
                  continue
                }
              }
              Promise.all(actionList).then(feedbackArr => {
                for (let j = 0; j < feedbackArr.length; j++) {
                  if (feedbackArr[j] != 'pass') {
                    actionMsg += `${feedbackArr[j]}\n`
                    actionArrIndex.push(j)
                  }
                  else {
                    continue;
                  }
                }
                const amountChange = footDatas.value.Amount - documentContentCopy.Amount

                if (actionMsg != '') {
                  if (amountChange != 0) {
                    updateDocumentCenter(docId, 0, amountChange).then(res => {
                      ElMessage(actionMsg + '\n其余部分已经更新')
                      refreshThisPage(docId)
                    })
                  }
                  else {
                    ElMessage(actionMsg + '\n其余部分已经更新')
                    refreshThisPage(docId)
                  }
                }
                else {
                  if (amountChange != 0) {
                    ElMessage('更新成功')
                    updateDocumentCenter(docId, 0, amountChange).then(res => {
                      refreshThisPage(docId)
                    })
                  }
                  else {
                    ElMessage('更新成功')
                    refreshThisPage(docId)
                  }
                }
              })
            }
          })
          break;
        case 'SO':
          for (let item of updateList) {
            if (item.position == 'Head' && item.fieldsName == 'Remark') {
              let b = updateHeadRemarkCheck()
              checkList.push(b)
              continue
            }

            if (item.position == 'Body' && item.fieldsName == 'Remark') {
              let b = updateSoBodyRemarkCheck()
              checkList.push(b)
              continue;
            }
            if (item.position == 'Discount') {
              let b = updateDiscoutCheck(docId, parseFloat(dicountValue.value) - parseFloat(dicountValueCopy.value))
              checkList.push(b)
              continue;
            }
            if (item.position == 'Body' && item.fieldsName == 'UnitPrice') {
              const oldPrice = AddContentBodyCopy.find(x => x.DocumentId == item.documentId && x.Sn == item.sn)?.UnitPrice
              const priceChange = parseFloat(item.updateValue) - parseFloat(oldPrice)
              let b = updateSoLineUnitPriceCheck(item.documentId, item.sn, priceChange)
              checkList.push(b)
              continue;
            }
            if (item.position == 'Head' && item.fieldsName == 'StaffId') {
              let b = updateStaffCheck(item.updateValue)
              checkList.push(b)
              continue
            }
            let b = checkSoLine(docId, lostAmount)
            checkList.push(b)
          }
          Promise.all(checkList).then(errArr => {
            if (errArr.length > 0) {
              for (let l = 0; l < errArr.length; l++) {
                if (errArr[l] == 'pass') {
                  continue;
                }
                else {
                  checkMsg += errArr[l] + '\n'
                }
              }
            }
            if (checkMsg != '') {
              ElMessage(checkMsg)
              return;
            }
            else {
              for (let item of updateList) {
                if (item.position == 'Head' && item.fieldsName == 'Remark') {
                  let b = updateHeadRemark(item.updateValue)
                  actionList.push(b)
                  continue
                }

                if (item.position == 'Body' && item.fieldsName == 'Remark') {
                  let b = updateSoBodyRemark(item.documentId, item.sn, item.updateValue)
                  actionList.push(b)
                  continue;
                }
                if (item.position == 'Discount') {
                  let b = updateSoDiscout(parseFloat(item.updateValue || 0) - parseFloat(dicountValueCopy.value))
                  actionList.push(b)
                  continue;
                }
                if (item.position == 'Body' && item.fieldsName == 'UnitPrice') {
                  const oldPrice = AddContentBodyCopy.find(x => x.DocumentId == item.documentId && x.Sn == item.sn)?.UnitPrice
                  const priceChange = parseFloat(item.updateValue) - parseFloat(oldPrice)
                  let b = updateSoLineUnitPrice(item.documentId, item.sn, priceChange)
                  actionList.push(b)
                  continue;
                }
                if (item.position == 'Head' && item.fieldsName == 'StaffId') {
                  let b = updateStaff(item.updateValue)
                  actionList.push(b)
                  continue
                }
              }
              Promise.all(actionList).then(feedbackArr => {
                for (let j = 0; j < feedbackArr.length; j++) {
                  if (feedbackArr[j] != 'pass') {
                    actionMsg += `${feedbackArr[j]}\n`
                    actionArrIndex.push(j)
                  }
                  else {
                    continue;
                  }
                }
                const amountChange = footDatas.value.Amount - documentContentCopy.Amount

                if (actionMsg != '') {
                  if (amountChange != 0) {
                    updateDocumentCenter(docId, 0, amountChange).then(res => {
                      ElMessage(actionMsg + '\n其余部分已经更新')
                      if (lostAmount == 0) {
                        refreshThisPage(docId)
                        return
                      }
                      else {
                        amountChange1 = -lostAmount
                        closeOrUPdate.value = false
                        addAlertMessage(amountChange1)
                        soMoneyRef.value.openPopComm()
                        return
                      }
                    })
                  }
                  else {
                    ElMessage(actionMsg + '\n其余部分已经更新')
                    if (lostAmount == 0) {
                      refreshThisPage(docId)
                      return
                    }
                    else {
                      amountChange1 = -lostAmount
                      closeOrUPdate.value = false
                      addAlertMessage(amountChange1)
                      soMoneyRef.value.openPopComm()
                      return
                    }
                  }
                }
                else {
                  if (amountChange != 0) {
                    ElMessage('更新成功')
                    updateDocumentCenter(docId, 0, amountChange).then(res => {
                      if (lostAmount == 0) {
                        refreshThisPage(docId)
                        return
                      }
                      else {
                        amountChange1 = -lostAmount
                        closeOrUPdate.value = false
                        addAlertMessage(amountChange1)
                        soMoneyRef.value.openPopComm()
                        return
                      }
                    })
                  }
                  else {
                    ElMessage('更新成功')
                    if (lostAmount == 0) {
                      refreshThisPage(docId)
                      return
                    }
                    else {
                      amountChange1 = -lostAmount
                      closeOrUPdate.value = false
                      addAlertMessage(amountChange1)
                      soMoneyRef.value.openPopComm()
                      return
                    }
                  }
                }
              })
            }
          })
          break;
        case 'RO':
          for (let item of updateList) {
            if (item.position == 'Head' && item.fieldsName == 'Remark') {
              let b = updateHeadRemarkCheck()
              checkList.push(b)
              continue
            }

            if (item.position == 'Body' && item.fieldsName == 'Remark') {
              let b = updateRoBodyRemarkCheck()
              checkList.push(b)
              continue;
            }
            if (item.position == 'customerSource') {
              let b = updateCustomerSourceCheck(item.documentId, item.updateValue)
              checkList.push(b)
              continue;
            }
            if (item.position == 'Discount') {
              let b = updateDiscoutCheck(docId, parseFloat(dicountValue.value) - parseFloat(dicountValueCopy.value))
              checkList.push(b)
              continue;
            }
            if (item.position == 'Body' && item.fieldsName == 'UnitPrice') {
              const oldPrice = AddContentBodyCopy.find(x => x.DocumentId == item.documentId && x.Sn == item.sn)?.UnitPrice
              const priceChange = parseFloat(item.updateValue) - parseFloat(oldPrice)
              let b = updateRoLineUnitPriceCheck(item.documentId, item.sn, priceChange)
              checkList.push(b)
              continue;
            }
            if (item.position == 'Head' && item.fieldsName == 'StaffId') {
              let b = updateStaffCheck(item.updateValue)
              checkList.push(b)
              continue
            }
            if (item.position == 'Body' && item.fieldsName == 'SalesAttributeNo') {
              let b = updateRoSaleAttrCheck(item.documentId, item.sn, item.updateValue)
              checkList.push(b)
              continue
            }
          }
          Promise.all(checkList).then(errArr => {
            if (errArr.length > 0) {
              for (let l = 0; l < errArr.length; l++) {
                if (errArr[l] == 'pass') {
                  continue;
                }
                else {
                  checkMsg += errArr[l] + '\n'
                }
              }
            }
            if (checkMsg != '') {
              ElMessage(checkMsg)
              return;
            }
            else {
              for (let item of updateList) {
                if (item.position == 'Head' && item.fieldsName == 'Remark') {
                  let b = updateHeadRemark(item.updateValue)
                  actionList.push(b)
                  continue
                }

                if (item.position == 'Body' && item.fieldsName == 'Remark') {
                  let b = updateRoBodyRemark(item.documentId, item.sn, item.updateValue)
                  actionList.push(b)
                  continue;
                }
                if (item.position == 'customerSource') {
                  let b = updateCustomerSource(item.documentId, item.updateValue)
                  actionList.push(b)
                  continue;
                }
                if (item.position == 'Discount') {
                  let b = updateSoDiscout(parseFloat(item.updateValue || 0) - parseFloat(dicountValueCopy.value))
                  actionList.push(b)
                  continue;
                }
                if (item.position == 'Body' && item.fieldsName == 'UnitPrice') {
                  const oldPrice = AddContentBodyCopy.find(x => x.DocumentId == item.documentId && x.Sn == item.sn)?.UnitPrice
                  const priceChange = parseFloat(item.updateValue) - parseFloat(oldPrice)
                  let b = updateRoLineUnitPrice(item.documentId, item.sn, priceChange)
                  actionList.push(b)
                  continue;
                }
                if (item.position == 'Head' && item.fieldsName == 'StaffId') {
                  let b = updateStaff(item.updateValue)
                  actionList.push(b)
                  continue
                }
                if (item.position == 'Body' && item.fieldsName == 'SalesAttributeNo') {
                  let b = updateRoSaleAttr(item.documentId, item.sn, item.updateValue)
                  actionList.push(b)
                  continue
                }
              }
              Promise.all(actionList).then(feedbackArr => {

                for (let j = 0; j < feedbackArr.length; j++) {
                  if (feedbackArr[j] != 'pass') {
                    actionMsg += `${feedbackArr[j]}\n`
                    actionArrIndex.push(j)
                  }
                  else {
                    continue;
                  }
                }
                const amountChange = footDatas.value.Amount - documentContentCopy.Amount

                if (actionMsg != '') {
                  if (amountChange != 0) {
                    updateDocumentCenter(docId, 0, amountChange).then(res => {
                      ElMessage(actionMsg + '\n其余部分已经更新')
                      if (lostAmount == 0) {
                        refreshThisPage(docId)
                        return
                      }
                      else {
                        amountChange1 = -lostAmount
                        closeOrUPdate.value = false
                        addAlertMessage(amountChange1)
                        soMoneyRef.value.openPopComm()
                        return
                      }
                    })
                  }
                  else {
                    ElMessage(actionMsg + '\n其余部分已经更新')
                    if (lostAmount == 0) {
                      refreshThisPage(docId)
                      return
                    }
                    else {
                      amountChange1 = -lostAmount
                      closeOrUPdate.value = false
                      addAlertMessage(amountChange1)
                      soMoneyRef.value.openPopComm()
                      return
                    }
                  }
                }
                else {
                  if (amountChange != 0) {
                    ElMessage('更新成功')
                    updateDocumentCenter(docId, 0, amountChange).then(res => {
                      if (lostAmount == 0) {
                        refreshThisPage(docId)
                        return
                      }
                      else {
                        amountChange1 = -lostAmount
                        closeOrUPdate.value = false
                        addAlertMessage(amountChange1)
                        soMoneyRef.value.openPopComm()
                        return
                      }
                    })
                  }
                  else {
                    ElMessage('更新成功')
                    if (lostAmount == 0) {
                      refreshThisPage(docId)
                      return
                    }
                    else {
                      amountChange1 = -lostAmount
                      closeOrUPdate.value = false
                      addAlertMessage(amountChange1)
                      soMoneyRef.value.openPopComm()
                      return
                    }
                  }
                }
              })
            }
          })
          break;
        default:
          break;
      }
    }

    const fillDataFromDocCenter = (value) => {
      const docId = value.documentId
      const docJude = docId.substring(0, 1) + docId.substring(1, 2).toLowerCase()
      if (docJude == 'Ro') {
        tableHeight.value = '40%'
      }
      const docName = docJude + 'List'

      docTitleTwo.value = docId.substring(0, 2)

      const listForValidationIn = store.state.loginInitializeData.listForValidations
      const filterListIn = store.state.loginInitializeData.filterList
      const itemListIn = store.state.loginInitializeData.docTableElementManage.filter(x => x.docTableName == docName)
      const itemListHeadIn = itemListIn.filter(x => x.fieldsType == 'Head')
      const itemListBodyIn = itemListIn.filter(x => x.fieldsType == 'Body')

      const documentCenterItemsIn = [];
      let documentContentIn = reactive({});
      for (let item of itemListHeadIn) {
        documentCenterItemsIn.push(item.fieldsNameForManage)
      }
      const listForDocumentPreIn = listForValidationIn.filter(x => x.tableNameForVali == 'DocumentCenter' && x.validateMethod == 'add')

      let listForDocumentIn = {};
      const TableListItemsIn = [];
      for (let item of itemListBodyIn) {
        TableListItemsIn.push(item.fieldsNameForManage)
      }
      let addBodyContentIn = reactive({});
      let listForBodyIn = {};

      const listForBodyPreIn = listForValidationIn.filter(x => x.tableNameForVali == docName && x.validateMethod == 'add')

      //根据表名给searchItemList赋值
      let searchItemListIn = getNameList(docName)

      const listIn = listForValidationIn.filter(x => x.tableNameForVali == docName && x.validateMethod == 'add')
      for (let item of listIn) {
        searchItemListIn[item.fieldsNameForVali] = item
        for (let fkey in filterListIn) {
          if (item.fieldsNameForVali == fkey) {
            searchItemListIn[item.fieldsNameForVali].Filter = filterListIn[fkey]
          }
        }
      }
      for (let item of listForDocumentPreIn) {
        listForDocumentIn[item.fieldsNameForVali] = item
      }


      for (let item of listForBodyPreIn) {
        listForBodyIn[item.fieldsNameForVali] = item
      }

      fillSonContent(documentContentIn, listForDocumentIn, 'add', 'defaultValue')
      fillSonContent(addBodyContentIn, listForBodyIn, 'add', 'defaultValue')

      searchItemListIn.documentCenterItems = documentCenterItemsIn;
      searchItemListIn.documentContent = documentContentIn;
      searchItemListIn.addBodyContent = addBodyContentIn;
      searchItemListIn.TableListItems = TableListItemsIn;
      searchItemListIn.itemList = itemListIn;

      dicountValue.value = 0

      dicountValueCopy.value = 0

      platformData.platformMiAmount = ''
      platformData.platformChoose = ''
      platformData.voucherCode = ''

      customerSourceCopy.value = ''

      tableTitle.value = searchItemListIn.editName

      documentContentCopy = {}
      AddContentBodyCopy.splice(0)
      updateList.splice(0)

      CustomerList.CustomerName = ''
      CustomerList.CustomerContacts = ''
      CustomerList.CustomerDeposit = ''
      CustomerList.hasOrNot = false

      CustomerListCopy.CustomerName = ''
      CustomerListCopy.CustomerContacts = ''
      CustomerListCopy.CustomerDeposit = ''
      CustomerListCopy.hasOrNot = false

      itemList = itemListHeadIn
      const lenghtForDocumentCenterItems = documentCenterItems.length
      for (let i = lenghtForDocumentCenterItems - 1; i >= 0; i--) {
        if (searchItemListIn.documentCenterItems.indexOf(documentCenterItems[i]) > -1) {
          continue;
        }
        else {
          documentCenterItems.splice(i, 1)
        }
      }
      if (docJude == 'Ro') {
        documentCenterItems.push('PartBCompanyId')
        documentCenterItems.push('OutWareHouseId')
        documentCenterItems.push('StaffId')
        documentCenterItems.push('Remark')
      }

      const lenghtForTableListItems = TableListItems.length
      for (let i = lenghtForTableListItems - 1; i >= 0; i--) {
        if (searchItemListIn.TableListItems.indexOf(TableListItems[i]) > -1) {
          continue;
        }
        else {
          TableListItems.splice(i, 1)
        }
      }
      for (let item1 of searchItemListIn.TableListItems) {
        for (let key in searchItemListIn) {
          if (item1 == key) {
            AddContentHead[item1] = searchItemListIn[item1]
          }
        }
      }

      AddContentSample = searchItemListIn.addBodyContent;
      AddContentBody.splice(0)
      for (let i = 0; i < 20; i++) {
        let b = copyObj(AddContentSample)
        AddContentBody.push(b)
      }
      copyOrder(value, documentContent, AddContentBody, AddContentSample, 'Edit').then(res => {
        checkIsXl(documentContent.PartACompanyId).then(res => {
          if(res == 'pass'){
            checkIsXl(documentContent.PartBCompanyId).then(res=>{
              if(res == 'pass'){
                documentContent.SystemStatusId = 104
                documentContentCopy.SystemStatusId = 104
                openEditArea(docJude)
              }
            })
          }
        })
        if (docJude == 'Ro' || docJude == 'So' || docJude == 'Po') {
          getDiscountValue(docId).then(res => {
            const discounAmount = res
            getPlatReceive(docId).then(res => {
              if (res?.amount) {
                platformData.platformMiAmount = MiReverseMeiTuan(res.amount)
                platformData.platformChoose = res.partBCompanyId
                platformData.voucherCode = res.remark.split('券码为:')[1]
                dicountValue.value = discounAmount - platformData.platformMiAmount
                dicountValueCopy.value = discounAmount - platformData.platformMiAmount
              }
              else {
                dicountValue.value = discounAmount
                dicountValueCopy.value = discounAmount
              }
            })
          })
        }
        if (docJude == 'Ro') {
          getCustomerSource(docId).then(res => {
            customerSource.value = res

            customerSourceCopy.value = res
          })
        }

        if (docJude == 'Ob') {
          getObTracingInfo(docId).then(res => {
            if (res) {
              CustomerList.CustomerName = res.customerName
              CustomerList.CustomerContacts = res.customerContacts
              CustomerList.CustomerDeposit = res.customerDeposit
              CustomerList.hasOrNot = true

              CustomerListCopy.CustomerName = res.customerName
              CustomerListCopy.CustomerContacts = res.customerContacts
              CustomerListCopy.CustomerDeposit = res.customerDeposit
              CustomerListCopy.hasOrNot = true
            }
          })
        }

        documentContentCopy = copyObj(documentContent)


        setTimeout(() => {
          for (let item of AddContentBody) {
            let objNew = {};
            for (let vl in item) {
              if (vl == 'SalesAttributeNoArr') {
                objNew[vl] = copyArray(item[vl])
              }
              else {
                objNew[vl] = item[vl]
              }
            }
            AddContentBodyCopy.push(objNew)
          }
        }, 500);

        partBCompanyName.value = transferToName(documentContentCopy.PartBCompanyId, 'PartBCompanyId')
        xlCheck.value = checkIsXlTranction(documentContentCopy.PartACompanyId, documentContentCopy.PartBCompanyId)
        openEditArea(docJude)
      })
    }

    const storage = window.localStorage;
    const user = store.state.loginInitializeData.userId

    const reSendWarrenty = () => {
      getAllRootMaterialList().then(res => {
        let storeListFromWin = []
        if (JSON.parse(storage.getItem('BarStoreList' + user))) {
          storeListFromWin = JSON.parse(storage.getItem('BarStoreList' + user));
        }
        for (let item of AddContentBodyCopy) {
          if (item.CommodityName) {
            const newId2 = getUUId()
            const commoId = item.CommodityId
            const rooNumber = commoId.substring(8, 12)
            const rootBody = res?.find(x => x.rootMaterialCode == rooNumber)
            const warrantyRemarkOrgin = rootBody?.defaultWarrentyRemark
            const warrantyDaysOrgin = rootBody?.defaultWarrentyDays
            const partAId = documentContentCopy.PartACompanyId
            const warrantyNumber = 'warranty' + partAId + rooNumber
            const warrantyRemarkNumber = 'warrantyRemark' + partAId + rooNumber
            const warrantyDays = storage.getItem(warrantyNumber) || `${warrantyDaysOrgin}天`
            const warrentyRemarksOrigin = storage.getItem(warrantyRemarkNumber) || `${warrantyRemarkOrgin}`
            let addPrint2 = {
              id: 'store' + newId2,
              partName: `${item.CommodityName}*${item.OrderQty}${item.Unit}`,
              docType: '质保单',
              companyName: transferToName(documentContentCopy.PartACompanyId, 'OwedCompanyId'),
              commodityId: item.CommodityId,
              docDate: documentContentCopy.DocumentDateTime,
              salesPrice: item.UnitPrice,
              remark: `销售属性:${transferToName(item.SalesAttributeNo, 'SalesAttributeId')}`,
              barcode: documentContentCopy.DocumentId,
              warranty: warrantyDays,
              warrantyRemark: warrentyRemarksOrigin
            }
            storeListFromWin.unshift(addPrint2)
          }
        }
        storage.setItem('BarStoreList' + user, JSON.stringify(storeListFromWin))
        store.commit('updateBarcodeCount')
      })
    }

    const reSendRecycle = () => {
      let cout1 = 0;
      for (let item of AddContentBody) {
        if (item.CommodityName) {
          if (item.SalesAttributeNoArr.find(x => x.salesAttributeId == item.SalesAttributeNo).recycleOrNot) {
            cout1++
          }
        }
      }
      if (cout1 == 0) {
        ElMessage('无回收单打印')
        return;
      }
      else {
        let storeListFromWin = []
        if (JSON.parse(storage.getItem('BarStoreList' + user))) {
          storeListFromWin = JSON.parse(storage.getItem('BarStoreList' + user));
        }
        for (let item of AddContentBody) {
          if (item.CommodityName) {
            if (item.SalesAttributeNoArr.find(x => x.salesAttributeId == item.SalesAttributeNo).recycleOrNot) {
              const salesId = item.SalesAttributeNo
              const salesAttrName = transferToName(salesId, 'SalesAttributeId')
              for (let k = item.OrderQty - 1; k >= 0; k--) {
                const newId = getUUId()
                let addPrint = {
                  id: 'store' + newId,
                  partName: item.CommodityName,
                  docType: '回收单',
                  companyName: store.state.loginInitializeData.companyNameAbbre,
                  docDate: documentContentCopy.DocumentDateTime,
                  salesPrice: '',
                  remark: `回收入库 ${item.OrderQty}-${k + 1},回收属性:${salesAttrName}`,
                  barcode: documentContentCopy.DocumentId
                }
                storeListFromWin.unshift(addPrint)
              }
            }
          }
        }
        storage.setItem('BarStoreList' + user, JSON.stringify(storeListFromWin))
        store.commit('updateBarcodeCount')
      }
      // for (let item of AddContentBodyCopy) {
      //   const recycleCost = item.SalesAttributeNoArr.find(x => x.salesAttributeId == item.SalesAttributeNo).recyclePrice
      //   const partAId = documentContentCopy.PartACompanyId
      //   const commoName = item.CommodityName
      //   const commoId = item.CommodityId
      //   const salesId = item.SalesAttributeNo
      //   const salesAttrName = transferToName(salesId, 'SalesAttributeId')
      // }
    }

    // let tableCode = changeToViewCode('EditOrder')

    // watch(() => store.state.loginInitializeData.ShowWindow[0],
    //   (newValue, oldValue) => {
    //     if (tableCode != store.state.loginInitializeData.ShowWindow[0]) {
    //       store.commit('CloseActiveWindow', tableCode)
    //     }
    //   }
    // )
    //#endregion

    return {
      fillDataFromDocCenter, itemList, documentContent, documentCenterItems, addBodyContent,
      docTitleTwo, CustomerList, partBFilter, disableMap, OutWarehouseFilter, InWarehouseFilter,
      CapitalAccountIdFilter, StaffFilter, checkDeposit, tableHeight, tablewidth, widthForAll,
      activeIndex, activeInput, alterActive, setActiveInput, refreashAmount, checkQty, trHeight,
      AccountingSubjectIdFilter, footDatas, TableListItems, AddContentBody, tableTitle, checkDiscount,
      dicountValue, valueAfterDiscount, customerSourceArr, customerSource, checkPrice, updateList,
      checkUpdateForHead, checkUpdateForBody, checkUpdateForDiscount, checkUpdateForCustomerSource, checkUpdateForObInfo,
      recoverToOrigin, deleteOneLine, deleteAll, updateAllChange, soMoneyRef, openPop, alertMessage,
      alertMoney, receiveMoney, returnMoney, closePop, partBCompanyName, xlCheck, reSendWarrenty, reSendRecycle,
      platFormFilter, platformData
    }
  },
  components: {
    PopModelDiy
  }
}
</script>

<style scoped lang="less">
@trHeight: 2rem;
.TableContentAreaEdit {
  position: relative;
  margin-top: 1rem;
  margin-left: 0.5rem;
  overflow: hidden;
  overflow-x: auto;
  width: 98%;
}
.headtrEdit {
  background-color: #1e87f0;
  height: @trHeight;
  top: -1.25rem;
  color: #fff;
}

.thSNEdit {
  font-family: "icomoon";
  color: #fff;
  font-size: 1rem;
}
.headtdEdit {
  cursor: pointer;
}

.tablebodyareaEdit {
  height: calc(100% - 3.75rem);
  overflow: hidden;
  overflow-y: auto;
}
.tablebodyEdit tr:hover {
  background-color: #edf4fe;
}
.tablebodyEdit {
  margin-top: -1px;
}
.bodytrEdit input {
  border: none;
  outline: none;
  height: @trHeight;
  background-color: transparent;
  text-align: center;
}
.bodytrEdit input:focus {
  border: 1px solid #ffa004;
}

.bodytrEdit select {
  border: none;
  outline: none;
  height: @trHeight;
  background-color: transparent;
  text-align: center;
}
.bodytrEdit select:focus {
  border: 1px solid #ffa004;
}
// .bodytrEdit {
//   // height: 1.6rem;
//   // border: 1px solid #ffa004;
// }

.tdSN,
.tableselect,
.tdoper,
.footsum {
  text-align: center;
}
.commodityTr {
  position: relative;
}
.tableinputContainer {
  display: flex;
  position: relative;
}

.CommodityContainer {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  margin-top: 1px;
  border: 1px solid #cccccc;

  // border-left: 2px solid #4e71f2;
  // border-right: 2px solid #4e71f2;
  // border-bottom: 2px solid #4e71f2;
}
.searchContentTr {
  display: flex;
}
.searchContentTr:hover {
  background-color: #fff;
  background-color: #f5f5f6;
}
.searchContentTr input {
  text-align: left;
}

.searchContentTr input:hover {
  cursor: pointer;
}
.searchContentTrLast:hover {
  background-color: #fa2c2c;
  color: #fff;
  cursor: pointer;
}
.searchContentTrLast {
  padding-left: 0.3rem;
}
.searchIndication {
  text-align: center !important;
  color: red;
}
.tablefoot {
  margin-top: -1px;
}
.foottr {
  background-color: #fefbe5;
}
.foottr {
  height: @trHeight;
}
.checkbo {
  cursor: pointer;
}
.foottr div {
  text-align: center;
}

.selectInput {
  border: none;
  // appearance: none;
  height: @trHeight;
  background-color: transparent;
  text-align-last: center;
}
.checkContainer {
  display: flex;
}
.checkbo {
  margin: auto;
}
.InputHeadContainer {
  display: flex;
  margin-top: 0.5rem;
  flex-wrap: wrap;
}
.InputForChooseContainer {
  display: flex;
  margin-right: 0.625rem;
}
.InputForChooseContainer label {
  padding-right: 0.3125rem;
  line-height: 1.75rem;
}
.importantIndi {
  color: #e83a65;
  font-size: 1rem;
}
.InputForChoose {
  height: 1.75rem;
  width: 9rem;
  border: 1px solid #c9c9c9;
}
.InputForSpecial {
  height: 1.75rem;
  width: 9rem;
  border: 1px solid #c9c9c9;
}
.InputForChooseFunc {
  font-family: "icomoon";
  text-align: center;
  line-height: 1.75rem;
  font-size: 1.125rem;
  width: 1.75rem;
  height: 1.75rem;
  border: 1px solid #c9c9c9;
}

.InputForChooseFunc:hover {
  cursor: pointer;
}
.InputForChooseLong {
  height: 1.75rem;
  width: 15.625rem;
  border: 1px solid #c9c9c9;
}
.InputForChoose:focus,
.InputForChooseLong:focus,
.InputForSpecial:focus {
  border: 1px solid #ffa004;
}
.BodyTitle {
  width: 98%;
  margin-top: 0.5rem;
  margin-left: 1%;
}
.BodyTitleText {
  font-size: 1.2rem;
  font-weight: 700;
  color: red;
}
.EditTableLine {
  flex-wrap: wrap;
}
b {
  color: red;
}

.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}
.firstButton {
  margin-left: 1rem;
}
.ModifyShow {
  background-color: #f8cbad !important;
}
.DisableShow {
  background-color: #d9d9d9 !important;
}

.buttonLayer {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.AlertMessage {
  margin-left: 2rem;
  font-size: 1.2rem;
  color: #e83a65;
  margin-top: 1rem;
}
.inputLoaderContainer {
  position: relative;
}
.onLineRemark {
  position: absolute;
  left: 2rem;
  bottom: 2rem;
  color: red;
  font-weight: 700;
  font-size: 1.2rem;
}
</style>