<template>
  <div class="tableLoaderContainer">

    <div class="BodyTitle">
      <div class="BodyTitleText">二手机器库存</div>
    </div>

    <div class="searchContentContainer">

      <div class="searchItemContainer" v-show="managedCompanysFilter.length > 1">
        <label class="searchinputLabel" for="MachineClassForStaffId">公司:</label>
        <div class="searchInputBox" :class="{ 'ActiveBox': activeInput == 'StaffId' }"><select
            v-model="SearchContent.PartACompanyId" class="searchinput" id="MachineClassForStaffId"
            @focus="setActiveId('PartACompanyId')" @blur="setActiveId('')" @change="reSearch()">
            <option v-for="item in managedCompanysFilter" :key="item.beforeTransfer + 'MachineToBeSolve'"
              :value="item.beforeTransfer">{{ item.afterTransfer }}</option>
          </select></div>
      </div>


      <div class="searchItemContainer">
        <label class="searchinputLabel" for="MachineClassForStaffId">回收人:</label>
        <div class="searchInputBox" :class="{ 'ActiveBox': activeInput == 'StaffId' }"><select
            v-model="SearchContent.StaffId" class="searchinput" id="MachineClassForStaffId"
            @focus="setActiveId('StaffId')" @blur="setActiveId('')" @change="reSearch()">
            <option value="">所有</option>
            <option v-for="item in staffFilterCopy" :key="item.beforeTransfer + 'MachineToBeSolve'"
              :value="item.beforeTransfer">{{ item.afterTransfer }}</option>
          </select></div>
      </div>

      <div class="searchItemContainer">
        <label class="searchinputLabel" for="MachineClassForMachineToBeSolve">机器类型:</label>
        <div class="searchInputBox" :class="{ 'ActiveBox': activeInput == 'MachineClass' }"><select
            v-model="SearchContent.MachineClass" class="searchinput" id="MachineClassForMachineToBeSolve"
            @focus="setActiveId('MachineClass')" @blur="setActiveId('')" @change="reSearch(), updateBrands()">
            <option value="">所有</option>
            <option v-for="item in MachineClassFilter" :key="item.beforeTransfer + 'MachineToBeSolve'"
              :value="item.beforeTransfer">{{ item.afterTransfer }}</option>
          </select></div>
      </div>

      <div class="searchItemContainer">
        <label class="searchinputLabel" for="MachineBrandForMachineToBeSolve">品牌:</label>
        <div class="searchInputBox" :class="{ 'ActiveBox': activeInput == 'MachineBrand' }"><select
            v-model="SearchContent.MachineBrand" class="searchinput" id="MachineBrandForMachineToBeSolve"
            @focus="setActiveId('MachineBrand')" @blur="setActiveId('')" @change="reSearch()">
            <option value="" selected="selected">所有</option>
            <option v-for="item in brandList" :key="item.brandId + 'MpInput'" :value="item.brandId">{{ item.brandName }}
            </option>
          </select></div>
      </div>

      <!-- 搜索模块 -->
      <div class="FuncButton" @click="searchList()">搜索</div>

      <div class="tipBox">
        <div class="tipColortodo"></div>
        <div class="tipWords">待发布</div>
      </div>
      <div class="tipBox">
        <div class="tipColorfinish"></div>
        <div class="tipWords">已发布</div>
      </div>
    </div>

    <div class="BigTable">
      <div class="TableContentArea">
        <div class="machineShowBox">
          <div class="machineShowContainer" v-for="(item, index) in tableBody"
            :key="item.machineStorageId + 'machineTobeResole'"
            :class="[{ 'notpostClass': item.postOrNot == '未发布' }, { 'postClass': item.postOrNot != '未发布' }]">
            <div><span>机型：</span><input type="text" class="lInput boldword" v-model="item.machineType"
                :disabled="'disabled'"></div>
            <div><span>品牌:</span><input type="text" class="sInput" v-model="item.machineBrand"
                :disabled="'disabled'"><span>串码:</span><input type="text" class="mInput" v-model="item.uniqueCode"
                :disabled="'disabled'"></div>
            <div><span>外观:</span><input type="text" class="sInput" v-model="item.outLookDiscription"
                :disabled="'disabled'"><span>维修:</span><input type="text" class="mInput"
                v-model="item.repairHistoryAndRemark" :disabled="'disabled'"></div>
            <div><span>属性：</span><input type="text" class="sInput" v-model="item.brandNewOrNot"
                :disabled="'disabled'"><span>颜色:</span><input type="text" class="sInput" v-model="item.color"
                :disabled="'disabled'"><span>版本：</span><input type="text" class="sInput" v-model="item.country"
                :disabled="'disabled'"></div>
            <div><span>内存：</span><input type="text" class="sInput" v-model="item.memory"
                :disabled="'disabled'"><span>硬盘:</span><input type="text" class="sInput" v-model="item.hardDisk"
                :disabled="'disabled'"><span>账号：</span><input type="text" class="sInput" v-model="item.idQuitOrNot"
                :disabled="'disabled'"></div>
            <div><span>CPU:</span><input type="text" class="sInput" v-model="item.cpu"
                :disabled="'disabled'"><span>显卡:</span><input type="text" class="sInput" v-model="item.gpu"
                :disabled="'disabled'"><span>剩保：</span><input type="text" class="sInput" v-model="item.warrantyDaysLeft"
                :disabled="'disabled'"></div>
            <div><span>日期：</span><input type="text" class="sInput" v-model="item.documentDate"
                :disabled="'disabled'"><span>发布:</span><input type="text" class="sInput" v-model="item.postOrNot"
                :disabled="'disabled'"><span>售价：</span><input type="text" class="sInput" v-model="item.postedPrice"
                :disabled="'disabled'"></div>
            <div><span>备注：</span><input type="text" class="lInput" v-model="item.postedRemark" :disabled="'disabled'">
            </div>
            <div><span>回收人:</span><input type="text" class="sInput" v-model="item.staffId"
                :disabled="'disabled'"><span>回收价:</span><input type="text" class="sInput" v-model="item.purchasePrice"
                :disabled="'disabled'"><span>维修费:</span><input type="text" :disabled="'disabled'" class="sInput"
                v-model="item.repairCost"></div>
            <div class="funcBox">

              <div :style="{ marginRight: '10px' }">
                <el-dropdown size="small" split-button type="primary">
                  操作
                  <template #dropdown>
                    <el-dropdown-menu>
                      <div @click="sendBarCode(index)">
                        <el-dropdown-item>生成标签</el-dropdown-item>
                      </div>
                      <div @click="openSalePop(index)">
                        <el-dropdown-item>去销售</el-dropdown-item>
                      </div>
                      <div @click="openCommoPop(index)">
                        <el-dropdown-item>增加维修配件</el-dropdown-item>
                      </div>
                      <div @click="downPost(index)">
                        <el-dropdown-item>取消发布</el-dropdown-item>
                      </div>
                      <div @click="goPostPop(index)">
                        <el-dropdown-item>发布/修改</el-dropdown-item>
                      </div>
                      <div v-show="$store.state.loginInitializeData.companyId == '10000002'" @click="clearStock(index)">
                        <el-dropdown-item>清理库存</el-dropdown-item>
                      </div>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>



              <!-- <button class="machineButton" @click="sendBarCode(index)">标签</button>
              <button class="machineButton" @click="openSalePop(index)">去销售</button>
              <button class="machineButton" @click="openCommoPop(index)">增加维修配件</button>
              <button class="machineButton" @click="downPost(index)">取消发布</button>
              <button class="machineButton" @click="goPostPop(index)">发布/修改</button> -->
              <!-- <el-button type="primary" size="small" class="mRight" @click="openSalePop(index)" round>去销售</el-button>
              <el-button type="success" size="small" class="mRight" round>增加维修配件</el-button>
              <el-button type="danger" size="small" class="mRight" @click="downPost(index)" round>取消发布</el-button>
              <el-button type="primary" size="small" class="mRight lastButton" @click="goPostPop(index)" round>发布/修改</el-button> -->
            </div>
          </div>

        </div>
        <!-- <div class="MainBodyTablePage" :style="{ width: '100%'}">
          <div class="PageCountContainer">
            <div class="PagerSummaryText">({{ totalLines }}条记录,总共{{ totalPage }}页)</div>
            <span class="PagerEveryPageText">每页/条</span>
            <div class="EditBlock">
              <input class="LinesPerPageChooese" type="text" v-model="searchEle.LinesPerPage" />
              <div class="LinesPerPageChooseButton"></div>
            </div>
            <div class="GoFirstPageButton" @click="FirstPage()"></div>
            <div class="GoLastPageButton" @click="DownPage()"></div>
            <span class="pagetextforDi">第</span>
            <div class="EditBlock">
              <input class="PageEdit" type="text" v-model="searchEle.Page" />
              <div class="PageChooseButton"></div>
            </div>
            <span class="pagetextforYe">页</span>
            <div class="GoNextPageButton" @click="UpPage()"></div>
            <div class="GoEndPageButton" @click="EndPage()"></div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="moreFunctionBox">
      <el-tag type="primary" effect="dark" class="totalClass">
        总回收价:{{ totalPurValue }}元
      </el-tag>
    </div>
    <PopModel ref="postRef">
      <div class="inputBox">
        <label class="popLable" for="popPrice">发布价格:</label>
        <el-input class="popInput" id="popPrice" v-model="postInfo.postPrice" placeholder="请输入发布价格" type="number" />
      </div>
      <div class="inputBox">
        <label class="popLable" for="popRemark">发布说明:</label>
        <el-input v-model="postInfo.postRemark" id="popRemark" class="popInput" placeholder="请输入发布说明" />
      </div>
      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight" @click="closePostPop()">取消发布</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="goPostMachine()">确认发布/修改</el-button>
      </div>
    </PopModel>

    <PopModel ref="commodityUseRef">
      <div class="inputBox">
        <el-input class="popInput marginLeftC" v-model="searchCommo.searchText" placeholder="请输入搜索内容"
          @input="insertSearch" />
      </div>
      <div class="CommodityContainer" v-show="searchResult.length != 0">
        <div class="searchContentTr" v-for="(searchitem, searchIndex) in searchResult"
          :key="searchitem.commodityId + 'machineToBe'" @mousedown.prevent="setCommodity(searchIndex)"><input
            class="comminputDown" v-model="searchitem.commodityName" type="text"
            :style="[{ height: '1.625rem' }]"><input type="text" class="searchIndication" v-model="searchitem.show">
        </div>
        <div class="searchContentTrLast" :style="[{ height: '1.625rem' }, { lineHeight: '1.625rem' }]"
          @mousedown.prevent="clearSearchResult()">关闭搜索</div>
      </div>
      <div class="inputBox">
        <label class="popLable">使用商品:</label>
        <el-input class="popInput" v-model="searchCommo.usedText" disabled />
      </div>

      <div class="inputBox">
        <label class="popLable">出库仓库:</label>
        <el-select v-model="searchCommo.outWarehouseId" class="m-2 popInput" placeholder="Select">
          <el-option v-for="item in warehouseFilter" :key="item.beforeTransfer + 'CommUse'" :label="item.afterTransfer"
            :value="item.beforeTransfer" />
        </el-select>

      </div>

      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight">取消</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="useCommodity()">确认使用</el-button>
      </div>
    </PopModel>

    <PopModel ref="saleRef">
      <div class="inputBox">
        <label class="popLable" for="customerSourceMachineToBeSolve">客户渠道:</label>
        <el-select v-model="saleInfo.customerSource" class="m-2 popInput" placeholder="请选择客户渠道"
          id="customerSourceMachineToBeSolve">
          <el-option v-for="item in customerSourceFilter" :key="item.customerSourceId + 'cusSouMacToBeSlove'"
            :label="item.customerSourceName" :value="item.customerSourceId">
          </el-option>
        </el-select>
      </div>
      <div class="inputBox">
        <label class="popLable" for="salesPersonMachineToBeSolve">销售人员:</label>
        <el-select v-model="saleInfo.salesPerson" class="m-2 popInput" placeholder="请选择销售人员"
          id="salesPersonMachineToBeSolve">
          <el-option v-for="item in staffFilter" :key="item.beforeTransfer + 'salesMacToBeSo'"
            :label="item.afterTransfer" :value="item.beforeTransfer">
          </el-option>
        </el-select>
      </div>
      <div class="inputBox">
        <label class="popLable" for="salesPriceMachineToBeSolve">销售价格:</label>
        <el-input type="number" v-model="saleInfo.salesPrice" class="popInput" placeholder="请输入销售价格"
          id="salesPriceMachineToBeSolve" />
      </div>
      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight" @click="closeSalesPop()">取消</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="saleMachine()">确认销售</el-button>
      </div>
    </PopModel>

  </div>
</template>

<script>
import { computed, provide, reactive, ref, watch } from 'vue-demi';
import { useStore } from "vuex";
import { copyArray, getDateTime, getUUId, isRealNum, insertSearchOrigin } from '@/hooks/tools'
import PopModel from '@/components/Common/PopModel.vue'
import {
  ChangeFirToLo, ChangeFirToUp, ChangeYMD, changeArrContentToDate, copyObj
} from '@/hooks/tools';
import requestData from '@/hooks/requestData';
import {
  fillDefaultValue, changeToViewCode, PostMachine, unPostMachine, transferToCode, getDocNo, openMsList, checkModify, getVirtualInfo,
  openMiList, addSourceStatistics, openStaffKpiStatistics, getStaffId, transferToName, rejectAllQuery, deleteDocCenter, getLoginData, openOoList, updateWarning
} from '@/hooks/OpenOrder'
import { ElMessage } from 'element-plus'
export default {
  setup(props, { emit }) {
    const store = useStore();
    //#region 定义区
    const MachineClassFilter = store.state.loginInitializeData.filterList.MachineClass

    const filterList = store.state.loginInitializeData.filterList

    const managedCompanysFilter = copyArray(filterList.ManagedCompanys)

    console.log()

    //初始化输入表名称
    const TableName = 'MachineStorageList';

    provide('tableTitle', '')

    const PageName = 'MachineToBeSolve'

    const code = changeToViewCode(PageName)

    let searchEle = reactive({
      LinesPerPage: 20,
      Page: 1,
      SortFieldsName: 'PostedPrice',
      SortDirection: 1
    })
    let totalLines = ref(0)
    let totalPage = ref(1)

    let UpPage = () => {
      if (searchEle.Page < totalPage.value) {
        searchEle.Page++
        searchList()
      }
    };
    //f跳转最后一页

    let EndPage = () => {
      searchEle.Page = totalPage.value
      searchList()
    };
    //f减少页数
    let DownPage = () => {
      if (searchEle.Page > 1) {
        searchEle.Page--
        searchList()
      }
    };
    //f跳转到第一页
    let FirstPage = () => {
      searchEle.Page = 1
      searchList()
    };

    watch(
      () => [searchEle.Page],
      (newValue, oldValue) => {
        if (totalPage.value > 0) {
          if (parseInt(newValue[0]) > totalPage.value) {
            // ElMessage('不能超过最大页数');
            searchEle.Page = totalPage.value
            return;
          }
        }
        if (parseInt(newValue[0]) < 1) {
          // ElMessage('页数不能小于1');
          searchEle.Page = 1
          return;
        }
      }
    );
    //w用于监控页数，确保客户不乱填
    watch(
      () => searchEle.LinesPerPage,
      (newValue, oldValue) => {
        if (newValue > 99) {
          searchEle.LinesPerPage = 99
          ElMessage("每页数量不能超过99");
        }
        if (newValue < 1) {
          searchEle.LinesPerPage = 1
          ElMessage("每页数量不能低于1");
        }
      }
    );

    //#endregion

    //#region 全选
    let selectAll = ref(false);

    let selectedItems = ref([]);

    watch(selectAll, (newValue, oldValue) => {

      if (code == store.state.loginInitializeData.ShowWindow[0]) {
        if (newValue == true) {
          selectedItems.value.splice(0);
          for (let item of tableBody) {
            selectedItems.value.push(item['machineStorageId']);
          }
        } else {
          selectedItems.value.splice(0);
        }
      }
    });
    //#endregion

    let activeInput = ref('');

    const setActiveId = (id) => {
      activeInput.value = id
    }



    let SearchContent = reactive({
      MachineStorageId: '',
      PartACompanyId: '',
      MachineClass: '',
      MachineType: '',
      MachineBrand: '',
      BrandNewOrNot: '',
      Cpu: '',
      Memory: '',
      HardDisk: '',
      Gpu: '',
      Color: '',
      Country: '',
      UniqueCode: '',
      WarrantyDaysLeft: '',
      IdQuitOrNot: '',
      OutLookDiscription: '',
      MachineName: '',
      RepairHistoryAndRemark: '',
      PurchasePrice: '',
      RepairCost: '',
      PostOrNot: '',
      PostedPrice: '',
      PostedRemark: '',
      MpDocumentId: '',
      Status: '',
      Pictrue: '',
      BrandNewOrNotFS: '',
      IdQuitOrNotFS: '',
      PostOrNotFS: '',
      DocumentDateL: '',
      DocumentDateH: '',
    })

    fillDefaultValue(SearchContent, TableName)

    //f计算表格内容总宽度


    let tableBody = reactive([]);


    const reSearch = () => {
      searchList()
    }

    const totalPurValue = ref(0)

    let searchList = () => {
      let config = {
        method: "post",
        url: '/MachineStorageList/GetListByMulSearchForOwn',
        data: Object.assign(SearchContent, searchEle),
      };
      requestData
        .GetData(config)
        .then((res) => {
          tableBody.splice(0);
          tableBody.push.apply(tableBody, res['machineStorageListMultiple']);

          totalPurValue.value = tableBody.reduce((x, y) => x + (+y.purchasePrice), 0)

          changeArrContentToDate(tableBody, PageName)
          const filterList = store.state.loginInitializeData.filterList
          const MachineClassFilter = filterList.MachineClass
          const BrandNewOrNotFilter = filterList.BrandNewOrNot
          const IdQuitOrNotFilter = filterList.IdQuitOrNot
          const PostOrNotFilter = filterList.PostOrNot
          const partBFilter = filterList.OwedCompanyId
          const brandFilter = filterList.BrandId
          for (let item of tableBody) {
            for (let key in item) {
              if (key == 'machineClass') {
                item[key] = MachineClassFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'brandNewOrNot') {
                item[key] = BrandNewOrNotFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'idQuitOrNot') {
                item[key] = IdQuitOrNotFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'postOrNot') {
                item[key] = PostOrNotFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'partACompanyId') {
                item[key] = partBFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'machineBrand') {
                item[key] = brandFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'machineBrand') {
                item[key] = brandFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
            }
            if (isRealNum(item.hardDisk)) {
              item.hardDisk = item.hardDisk + 'g'
            }
            if (isRealNum(item.memory)) {
              item.memory = item.memory + 'g'
            }
            if (isRealNum(item.warrantyDaysLeft)) {
              item.warrantyDaysLeft = item.warrantyDaysLeft + '天'
            }
            item.postedPrice = item.postedPrice + '元'
            item.staffId = transferToName(item.staffId, 'StaffId')
          }
        })

        .catch((err) => {
        });
    };
    searchList()
    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          searchList()
        }
      }
    )

    const classToBrand = store.state.loginInitializeData.authList.classToBrandLists

    const brands = store.state.loginInitializeData.authList.brandLists

    let brandList = reactive([])

    const sendBarCode = (index) => {
      const body = tableBody[index]
      const storage = window.localStorage;
      const user = store.state.loginInitializeData.userId
      let storeListFromWin = []
      if (JSON.parse(storage.getItem('BarStoreList' + user))) {
        storeListFromWin = JSON.parse(storage.getItem('BarStoreList' + user));
      }
      const newId2 = getUUId()
      let addPrint2 = {
        id: 'store' + newId2,
        machineName: body.machineType,
        docType: '机器标签',
        companyName: store.state.loginInitializeData.companyNameAbbre,
        remark: `内存:${body.memory},回收人:${body.staffId}`,
        barcode: body.machineStorageId,
      }
      storeListFromWin.unshift(addPrint2)
      storage.setItem('BarStoreList' + user, JSON.stringify(storeListFromWin))
      store.commit('updateBarcodeCount')
      ElMessage('已生成，请前往条码打印界面打印')
    }

    const updateBrands = () => {
      brandList.splice(0)

      if (!SearchContent.MachineClass) {
        for (let item of brands) {
          let obj = {
            brandId: item.brandId,
            brandName: item.brandName
          }
          brandList.push(obj)
        }
      }

      const brandsDraft = classToBrand.filter(x => x.commodityFirstClassNo == SearchContent.MachineClass)
      for (let item of brandsDraft) {
        let obj = {
          brandId: item.brandId,
          brandName: brands.find(x => x.brandId == item.brandId).brandName
        }
        brandList.push(obj)
      }
    }

    updateBrands()
    //#endregion

    const postRef = ref(null)

    const goPostPop = (index) => {
      postInfo.machineCode = tableBody[index].machineStorageId
      postInfo.postPrice = parseInt(tableBody[index].postedPrice)
      postInfo.postRemark = tableBody[index].postedRemark
      postRef.value.openPopComm()
    }

    let postInfo = reactive({
      machineCode: '',
      postPrice: '',
      postRemark: ''
    })

    const closePostPop = () => {
      postRef.value.closePopComm()
    }

    const goPostMachine = () => {

      PostMachine(postInfo.machineCode, postInfo.postPrice, postInfo.postRemark).then(res => {
        if (res == 'pass') {

          postRef.value.closePopComm()
          searchList()
        }
        else {
          ElMessage('发布失败')
        }

      }).catch(err => {

        ElMessage(err.message)
      })
    }

    const downPost = (index) => {
      let b = confirm('确认取消发布,取消后会自动拒绝所有机器询价')
      if (!b) {
        return;
      }
      unPostMachine(tableBody[index].machineStorageId).then(res => {
        if (res == 'pass') {
          searchList()
        }
        else {
          ElMessage('下架失败')
        }
      })
    }


    //#region 销售模块
    const saleRef = ref(null)

    let saleInfo = reactive({
      machineInfo: '',
      customerSource: '',
      salesPerson: '',
      salesPrice: ''
    })

    const customerSourceFilter = store.state.loginInitializeData.authList.customerSourceLists

    const staffFilter = store.state.loginInitializeData.filterList.StaffId

    const staffFilterCopy = copyArray(staffFilter)

    const openSalePop = (index) => {
      saleInfo.machineInfo = tableBody[index]
      saleRef.value.openPopComm()
    }


    const clearStock = (index) => {
      let b = confirm('确认清理')
      if (!b) {
        return
      }

      saleInfo.machineInfo = tableBody[index]

      const partACom = SearchContent.PartACompanyId
      const user = store.state.loginInitializeData.userName
      const userId = store.state.loginInitializeData.userId
      const partBCom = transferToCode('散客', "PartBCompanyId")
      const commodityId = saleInfo.machineInfo.machineStorageId

      getDocNo('MS').then(res => {
        const msHeadCode = res
        let msBody = [{
          DocumentId: msHeadCode,
          Sn: 1,
          PartACompanyId: partACom,
          PartBCompanyId: partBCom,
          DocumentDateTime: '',
          CommodityName: saleInfo.machineInfo.machineType,
          CommodityId: commodityId,
          OrderQty: 1,
          UnitPrice: 0,
          Amount: 0,
          Remark: `${saleInfo.machineInfo.machineType}由于呆滞时间过长被清理`,
          NotifyStatus: '',
          ShipmentQty: '',
          ShipmentStatus: '',
          OutStockQty: 1,
          OutStockStatus: 103,
          ResponsedQty: '',
          RefusedQty: '',
          ResponseStatus: '',
          InvoiceOpenAmount: '',
          InvoiceOpenStatus: '',
          MoneyReceivedAmount: '',
          MoneyReceivedStatus: '',
          SourceDocumentId: '',
          RootSourceDocumentId: msHeadCode,
          ModifiedTimes: '',
        }]
        let msHead = {
          DocumentId: msHeadCode,
          DocumentTypeName: '',
          DocumentDate: '',
          DocumentDateTime: '',
          Online: '',
          PartACompanyId: partACom,
          PartBCompanyId: partBCom,
          UserId: userId,
          StaffId: saleInfo.machineInfo.staffId,
          SourceDocumentId: '',
          Amount: 0,
          Qty: 1,
          OutWareHouseId: '',
          InWareHouseId: '',
          SystemStatusId: '',
          CapitalAccountId: '',
          Remark: '',
          RootSourceDocumentId: '',
        }
        openMsList(msBody, msHead, msHeadCode, true).then(res => {
          if (res != 'pass') {

            ElMessage(res)
            deleteDocCenter(msHeadCode).then(res => {
              return;
            })

          }
          else {
            getStaffId(saleInfo.machineInfo.mpDocumentId).then(res => {
              rejectAllQuery(commodityId).then(res => {
                closeSalesPop()
                saleInfo.machineInfo = ''
                saleInfo.customerSource = ''
                saleInfo.salesPerson = ''
                saleInfo.salesPrice = ''
                searchList()
                emit('refreshMsg')
              })
            })
          }
        })
      }).catch(err => {

        ElMessage(err.message)
      })

    }




    const closeSalesPop = () => {
      saleRef.value.closePopComm()
    }

    const saleMachine = () => {

      let b = confirm('确认销售')
      if (!b) {

        return;
      }
      if (!saleInfo.machineInfo) {

        ElMessage('缺失机器信息')
        return
      }
      if (!saleInfo.customerSource) {

        ElMessage('缺失客户来源')
        return
      }
      if (!saleInfo.salesPerson) {

        ElMessage('缺失人员信息')
        return
      }
      if (!saleInfo.salesPrice) {

        ElMessage('缺失销售价格')
        return
      }
      if (saleInfo.salesPrice < 0) {

        ElMessage('价格不可小于0')
        return
      }
      if (saleInfo.salesPrice == 0) {
        let b = confirm('价格为0，是否继续')
        if (!b) {

          return;
        }
      }
      const partACom = store.state.loginInitializeData.companyId
      const user = store.state.loginInitializeData.userName
      const userId = store.state.loginInitializeData.userId
      const partBCom = transferToCode('散客', "PartBCompanyId")
      const commodityId = saleInfo.machineInfo.machineStorageId
      getDocNo('MS').then(res => {
        const msHeadCode = res
        let msBody = [{
          DocumentId: msHeadCode,
          Sn: 1,
          PartACompanyId: partACom,
          PartBCompanyId: partBCom,
          DocumentDateTime: '',
          CommodityName: saleInfo.machineInfo.machineType,
          CommodityId: commodityId,
          OrderQty: 1,
          UnitPrice: saleInfo.salesPrice,
          Amount: saleInfo.salesPrice,
          Remark: `销售了一台${saleInfo.machineInfo.machineType},销售人是${transferToName(saleInfo.salesPerson, 'StaffId')}`,
          NotifyStatus: '',
          ShipmentQty: '',
          ShipmentStatus: '',
          OutStockQty: 1,
          OutStockStatus: 103,
          ResponsedQty: '',
          RefusedQty: '',
          ResponseStatus: '',
          InvoiceOpenAmount: '',
          InvoiceOpenStatus: '',
          MoneyReceivedAmount: '',
          MoneyReceivedStatus: '',
          SourceDocumentId: '',
          RootSourceDocumentId: msHeadCode,
          ModifiedTimes: '',
        }]
        let msHead = {
          DocumentId: msHeadCode,
          DocumentTypeName: '',
          DocumentDate: '',
          DocumentDateTime: '',
          Online: '',
          PartACompanyId: partACom,
          PartBCompanyId: partBCom,
          UserId: userId,
          StaffId: saleInfo.machineInfo.staffId,
          SourceDocumentId: '',
          Amount: saleInfo.salesPrice,
          Qty: 1,
          OutWareHouseId: '',
          InWareHouseId: '',
          SystemStatusId: '',
          CapitalAccountId: '',
          Remark: '',
          RootSourceDocumentId: '',
        }
        openMsList(msBody, msHead, msHeadCode, true).then(res => {
          if (res != 'pass') {

            ElMessage(res)
            deleteDocCenter(msHeadCode).then(res => {
              return;
            })

          }
          else {
            let machineInfoCopy = copyObj(saleInfo.machineInfo)
            let customerSourceCopy = saleInfo.customerSource
            let salesPersonCopy = saleInfo.salesPerson
            let salesPriceCopy = saleInfo.salesPrice
            getDocNo('MI').then(res => {
              let miHeadCode = res
              let miHead = {
                DocumentId: miHeadCode,
                DocumentTypeName: '',
                DocumentDate: '',
                DocumentDateTime: '',
                Online: '',
                PartACompanyId: partACom,
                PartBCompanyId: partBCom,
                UserId: userId,
                StaffId: salesPersonCopy,
                SourceDocumentId: '',
                Amount: salesPriceCopy,
                Qty: 1,
                OutWareHouseId: '',
                InWareHouseId: '',
                SystemStatusId: '',
                CapitalAccountId: '',
                Remark: '',
                RootSourceDocumentId: '',
              }
              let miBody = [{
                DocumentId: miHeadCode,
                Sn: 1,
                PartACompanyId: partACom,
                PartBCompanyId: partBCom,
                OrderQty: 1,
                InStockQty: 1,
                OutStockQty: 1,
                UnitPrice: salesPriceCopy,
                Amount: salesPriceCopy,
                Remark: '自动开单',
                SourceDocumentId: msHeadCode,
                RootSourceDocumentId: msHeadCode
              }]
              openMiList(miBody, miHead, res).then(res => {
                let staObj = {
                  DocumentId: msHeadCode,
                  SettleOrNot: true,
                  SettleDocumentId: msHeadCode,
                  TransferredLines: 1,
                  Amount: salesPriceCopy,
                  CustomerSourceId: customerSourceCopy,
                  PartACompanyId: partACom
                }
                addSourceStatistics(staObj)
                getStaffId(saleInfo.machineInfo.mpDocumentId).then(res => {
                  let kpiObj = [{
                    DocumentId: msHeadCode,
                    PartACompanyId: partACom,
                    CommodityName: machineInfoCopy.machineType,
                    CommodityId: machineInfoCopy.machineStorageId,
                    OrderQty: 1,
                    UnitPrice: salesPriceCopy,
                    SalesAttributeNo: 10001,
                    StaffIdRepair: res,
                    StaffIdSale: salesPersonCopy,
                    Remark: ''
                  }]
                  openStaffKpiStatistics(kpiObj)
                  rejectAllQuery(commodityId).then(res => {
                    closeSalesPop()
                    saleInfo.machineInfo = ''
                    saleInfo.customerSource = ''
                    saleInfo.salesPerson = ''
                    saleInfo.salesPrice = ''
                    searchList()
                    emit('refreshMsg')
                  })
                })
              })
            })
          }
        })
      }).catch(err => {

        ElMessage(err.message)
      })
    }

    const commodityUseRef = ref(null)

    const openCommoPop = (index) => {
      searchCommo.index = index
      searchCommo.searchText = ''
      searchCommo.commodityId = ''
      searchCommo.usedText = ''
      searchCommo.outWarehouseId = ''
      commodityUseRef.value.openPopComm()
    }

    let searchResult = reactive([]);

    const insertSearch = _.debounce((e) => {
      insertSearchOrigin(e, searchResult)
    }, 500)

    const setCommodity = (index) => {
      searchCommo.usedText = searchResult[index].commodityName
      searchCommo.commodityId = searchResult[index].commodityId
      searchCommo.searchText = ''
      searchResult.splice(0)
      getVirtualInfo(searchCommo.commodityId).then(res => {
        if (res == 'True') {
          searchCommo.virturl = false
        }
        else if (res == 'False') {
          searchCommo.virturl = true
        }
        else {
          ElMessage('商品不存在')
          searchCommo.commodityId = ''
          searchCommo.usedText = ''
        }
      })
    }

    const clearSearchResult = () => {
      searchResult.splice(0)
    }

    const searchCommo = reactive({
      index: -1,
      searchText: '',
      commodityId: '',
      usedText: '',
      virturl: '',
      outWarehouseId: ''
    })


    const warehouseFilter = copyArray(filterList.WareHouseIdOut)

    const useCommodity = () => {
      if (searchCommo.usedText == '') {
        ElMessage('未选中商品')
        return
      }
      if (searchCommo.outWarehouseId == '') {
        ElMessage('未选择出库仓库')
        return
      }
      if (searchCommo.virturl != false) {
        ElMessage('不能选择虚拟商品')
        return
      }

      else {
        if (tableBody[searchCommo.index].postOrNot == '已发布') {
          ElMessage('发布状态无法增加维修物品')
          return
        }


        const StaffId = transferToCode(tableBody[searchCommo.index].staffId, 'StaffId')
        const warehouseName = transferToName(searchCommo.outWarehouseId, 'WareHouseId')
        const commArr = [searchCommo.commodityId]

        checkModify(tableBody[searchCommo.index].machineStorageId).then(res => {
          if (res != 'pass') {
            ElMessage(res)
            return
          }
          else {
            const partACom = store.state.loginInitializeData.companyId
            const user = store.state.loginInitializeData.userName
            const userId = store.state.loginInitializeData.userId

            getDocNo('OO').then(res => {
              const ooCode = res
              let ooHead = {
                DocumentId: ooCode,
                DocumentTypeName: '',
                DocumentDate: '',
                DocumentDateTime: '',
                Online: '',
                PartACompanyId: partACom,
                PartBCompanyId: '',
                UserId: userId,
                StaffId: StaffId,
                SourceDocumentId: '',
                Amount: '',
                Qty: 1,
                OutWareHouseId: searchCommo.outWarehouseId,
                InWareHouseId: '',
                SystemStatusId: 104,
                CapitalAccountId: '',
                Remark: '',
                RootSourceDocumentId: ooCode,
              }
              let ooBody = [{
                DocumentId: ooCode,
                Sn: 1,
                PartACompanyId: partACom,
                DocumentDateTime: '',
                CommodityName: searchCommo.usedText,
                CommodityId: searchCommo.commodityId,
                OrderQty: 1,
                OutStockQty: 1,
                InStockQty: 1,
                Unit: '',
                UnitPrice: '',
                Amount: '',
                Remark: `${tableBody[searchCommo.index].machineType}二手机消耗`,
                SourceDocumentId: ooCode,
                RootSourceDocumentId: ooCode,
                ModifiedTimes: '',
                OutStockKeeping: true,
                GoodsProfitKeeping: true,
                TransferCostToMachine: true,
                MachineStorageId: tableBody[searchCommo.index].machineStorageId
              }]
              openOoList(ooBody, ooHead, ooCode).then(res => {
                if (res != 'pass') {

                  ElMessage(res)
                  deleteDocCenter(ooCode).then(res => {
                    return;
                  })
                }
                else {
                  if (warehouseName == '主仓库') {
                    setTimeout(() => {
                      updateWarning(store.state.loginInitializeData.companyId, commArr)
                    }, 0);
                  }
                  ElMessage('物品已消耗')
                  commodityUseRef.value.closePopComm()
                  searchList()
                }
              })
            })

          }
        })
      }
    }

    //#endregion


    return {
      setActiveId, activeInput, searchList, tableBody,
      reSearch, SearchContent,
      searchEle, totalLines, totalPage, UpPage, EndPage, DownPage, FirstPage, brandList, updateBrands,
      MachineClassFilter, postRef, goPostPop, postInfo, closePostPop, goPostMachine, downPost, saleRef,
      openSalePop, saleInfo, customerSourceFilter, staffFilter, closeSalesPop, saleMachine,
      staffFilterCopy, sendBarCode,
      commodityUseRef, openCommoPop, searchResult, insertSearch, setCommodity, clearSearchResult,
      searchCommo, useCommodity, warehouseFilter, managedCompanysFilter, clearStock,totalPurValue

    }
  },
  components: {
    PopModel
  }
}
</script>

<style scoped lang="less">
@spanWidth: 3rem;
@lineHeight: 1.4rem;

.marginLeftC {
  margin-left: 5.8rem;
}

.tableLoaderContainer {
  width: 100%;
  height: 100%;
}

.moreFunctionBox {
  margin-top: 1rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.totalClass {
  margin-left: 20px;
}

@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;
@lableMarginLeft: 0.625rem;
@lableHeight: 2rem;
@inputHeight: 1.5rem;
@mainColor: #4285f4;

.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}

.searchinputLabel {
  margin-left: @lableMarginLeft;
  font-size: 0.8rem;
  height: @lableHeight;
  line-height: @lableHeight;
}

.searchinput {
  margin-top: calc((@lableHeight - @inputHeight) / 2);
  margin-bottom: calc((@lableHeight - @inputHeight) / 2);
  font-size: 0.9rem;
  border: none;
  outline: none;
  height: @inputHeight;
  line-height: @inputHeight;
}

.ActiveBox {
  border: 1px solid #ffa004 !important;
}

.searchInputBox {
  height: @lableHeight;
  border: 1px solid #c9c9c9;
  margin-left: @lableMarginLeft;
  border-radius: 0.31rem;
}

.searchItemContainer {
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
  display: flex;
}

.searchSetting {
  margin-left: calc(@lableMarginLeft*3);
  height: @lableHeight;
  display: flex;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}

.searchSetting:hover {
  cursor: pointer;
  color: @mainColor;
}

.settingIcon {
  font-family: "icomoon";
  font-size: 1.1rem;
  line-height: @lableHeight;
}

.settingText {
  margin-left: 0.3rem;
  font-size: 0.8rem;
  line-height: @lableHeight;
}

.dateInputContainer {
  display: flex;
}

.BodyTitle {
  width: 98%;
  margin-top: 0.5rem;
  margin-left: 1%;
}

.BodyTitleText {
  font-size: 1.2rem;
  font-weight: 200;
}

.long {
  width: 20rem;
}

.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 80%;
  margin-top: 1rem;
}

.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}

.moreFunctionBox {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.MainBodyTablePage {
  box-sizing: border-box;
  border: 1px solid #cccccc;
}

.PageCountContainer {
  display: flex;
  justify-content: flex-end;
  font-size: 0.875rem;
  align-items: center;
}

.PagerSummaryText {
  padding-right: 1.25rem;
}

.PagerEveryPageText {
  padding-right: 0.3125rem;
}

.EditBlock {
  display: flex;
  align-items: center;
}

.PageEdit {
  height: 1.25rem;
  width: 1.875rem;
  border-left: 0.5px solid #c9c9c9;
  border-top: 0.5px solid #c9c9c9;
  border-bottom: 0.5px solid #c9c9c9;
  font-size: 0.625rem;
}

.PageChooseButton {
  position: relative;
  box-sizing: border-box;
  height: 1.25rem;
  width: 1.25rem;
  border-right: 0.5px solid #c9c9c9;
  border-top: 0.5px solid #c9c9c9;
  border-bottom: 0.5px solid #c9c9c9;
  position: relative;
  margin-right: 0.625rem;
}

.PageChooseButton::before {
  content: "\e902";
  font-family: "icomoon";
  position: absolute;
  top: -0.3125rem;
  left: 0px;
  font-size: 1.25rem;
}

.GoFirstPageButton {
  height: 1.875rem;
  width: 1.25rem;
  position: relative;
  margin-right: 0.3125rem;
  cursor: pointer;
  margin-left: 0.3125rem;
}

.GoFirstPageButton::before {
  content: "\f100";
  font-family: "icomoon";
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 1.25rem;
}

.GoLastPageButton {
  height: 1.875rem;
  width: 1.25rem;
  position: relative;
  margin-right: 0.625rem;
  cursor: pointer;
}

.GoLastPageButton::before {
  content: "\f104";
  font-family: "icomoon";
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 1.25rem;
}

.pagetextforDi {
  margin-right: 0.625rem;
}

.EditBlock {
  display: flex;
  align-items: center;
}

.PageEdit {
  height: 1.25rem;
  width: 1.875rem;
  border-left: 0.5px solid #c9c9c9;
  border-top: 0.5px solid #c9c9c9;
  border-bottom: 0.5px solid #c9c9c9;
  font-size: 0.625rem;
}

.PageChooseButton {
  box-sizing: border-box;
  height: 1.25rem;
  width: 1.25rem;
  border-right: 0.5px solid #c9c9c9;
  border-top: 0.5px solid #c9c9c9;
  border-bottom: 0.5px solid #c9c9c9;
  position: relative;
  margin-right: 0.625rem;
}

.PageChooseButton::before {
  content: "\e902";
  font-family: "icomoon";
  position: absolute;
  top: -0.3125rem;
  left: 0px;
  font-size: 1.25rem;
}

.pagetextforYe {
  margin-right: 0.625rem;
}

.GoNextPageButton {
  height: 1.875rem;
  width: 1.25rem;
  position: relative;
  margin-right: 0.3rem;
  cursor: pointer;
}

.GoNextPageButton::before {
  content: "\f105";
  font-family: "icomoon";
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 1.25rem;
}

.GoEndPageButton {
  height: 1.875rem;
  width: 1.25rem;
  position: relative;
  margin-right: 0.1rem;
  cursor: pointer;
}

.GoEndPageButton::before {
  content: "\f101";
  font-family: "icomoon";
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 1.25rem;
}

.LinesPerPageChooese {
  height: 1.25rem;
  width: 1.875rem;
  border-left: 0.5px solid #c9c9c9;
  border-top: 0.5px solid #c9c9c9;
  border-bottom: 0.5px solid #c9c9c9;
  font-size: 0.625rem;
}

.machineShowBox {
  display: flex;
  flex-wrap: wrap;
  height: 90%;
  width: 100%;
  border-top: 1px solid #c9c9c9;
  border-left: 1px solid #c9c9c9;
  border-right: 1px solid #c9c9c9;
  overflow: hidden;
  overflow-y: auto;
}

.machineShowBox input {
  border: none;
}

.machineShowContainer {
  margin-top: 1rem;
  margin-left: 1rem;
  width: 31%;
  height: 18rem;
  border: 1px solid #000;
  box-shadow: 2px 2px 5px #000;
}

.machineShowContainer input {
  background-color: transparent;
}

.machineShowContainer span {
  display: inline-block;
  height: @lineHeight;
  width: 4rem;
  line-height: @lineHeight;
}

.mInput {
  height: @lineHeight;
  line-height: @lineHeight;
  width: calc(66% - 4rem);
  font-size: 0.8rem;
}

.lInput {
  height: @lineHeight;
  line-height: @lineHeight;
  width: calc(99% - 4rem);
  font-size: 0.8rem;
}

.sInput {
  height: @lineHeight;
  line-height: @lineHeight;
  width: calc(33% - 4rem);
  font-size: 0.8rem;
}

.redword {
  color: red;
  font-size: 1rem;
  font-weight: 700;
}

.boldword {
  font-size: 0.8rem;
  font-weight: 700;
}

.postClass {
  background-color: #e2efda;
}

.notpostClass {
  background-color: #d9d9d9;
}

.tipColortodo,
.tipColorfinish {
  height: 1.2rem;
  width: 1.2rem;
  margin-top: 0.3rem;
}

.tipColortodo {
  background-color: #d9d9d9;
}

.tipColorfinish {
  background-color: #e2efda;
}

.tipWords {
  height: 1.2rem;
  line-height: 1.2rem;
  margin-top: 0.3rem;
  margin-left: 0.5rem;
}

.tipBox {
  display: flex;
  height: 1.75rem;
  width: 7rem;
  margin-top: 0.625rem;
  margin-left: 0.5rem;
}

.funcBox {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.lastButton {
  margin-right: 0.2rem;
}

.popInput {
  margin-top: 0.5rem;
  width: 28rem;
}

.inputBox {
  display: flex;
  position: relative;
}

.popLable {
  margin-top: 0.5rem;
  line-height: 2.5rem;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 0.5rem;
}

.buttonBox {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  justify-content: flex-end;
}

.mRight {
  margin-top: 0.1rem;
}

.machineButton {
  background-color: #409eff;
  border-radius: 5px;
  border: none;
  margin-right: 5px;
  padding: 5px;
  color: white;
  margin-top: 5px;
}

.machineButton:hover {
  transform: translateY(-1px);
}

.CommodityContainer {
  background-color: #fff;
  z-index: 2;
  margin-top: 1px;
  border: 1px solid #cccccc;
  width: calc(100%-5.8rem);
  margin-left: 5.8rem;

  // border-left: 2px solid #4e71f2;
  // border-right: 2px solid #4e71f2;
  // border-bottom: 2px solid #4e71f2;
}

.searchContentTr {
  display: flex;
  margin-top: 0.2rem;
}

.searchContentTr:hover {
  background-color: #f5f5f6;
}

.searchContentTr input {
  border: none;
  outline: none;
  text-align: left;
  background-color: transparent;
  border-radius: 5px;
}

.searchContentTr input:hover {
  cursor: pointer;
}

.searchContentTrLast:hover {
  background-color: #fa2c2c;
  color: #fff;
  cursor: pointer;
}

.searchContentTrLast {
  padding-left: 0.3rem;
}

.searchIndication {
  text-align: center !important;
  color: red;
}

.comminputDown {
  width: 75%;
}

.searchIndication {
  width: 25%;
}
</style>