<template>
  <div class="MainbodyContainer">
    <AccountingSubjectsManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100001) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100001"></AccountingSubjectsManage>
    <SystemStatusList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100002) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100002"></SystemStatusList>
    <DocumentTypeManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100003) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100003"></DocumentTypeManage>
    <BrandList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100004) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100004"></BrandList>
    <BenchMarkChangeSubmit v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100122) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100122"></BenchMarkChangeSubmit>
    <SalesAttributesManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100005) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100005"></SalesAttributesManage>
    <SecondLevelClassList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100006) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100006"></SecondLevelClassList>
    <RechargeCodeList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100007) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100007"></RechargeCodeList>
    <CommodityMangeTable v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100008) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100008"></CommodityMangeTable>
    <WareHouseList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100009) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100009"></WareHouseList>
    <ViewList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100030) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100030"></ViewList>
    <ViewVisibleManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100023) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100023"></ViewVisibleManage>
    <FilterManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100043) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100043"></FilterManage>
    <DepartmentList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100010) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100010"></DepartmentList>
    <StaffList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100011) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100011"></StaffList>
    <KpiManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100012) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100012"></KpiManage>
    <CustomerSourceList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100013) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100013"></CustomerSourceList>
    <WarrantyStatementList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100014) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100014"></WarrantyStatementList>
    <VmiPriceMethodList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100015) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100015"></VmiPriceMethodList>
    <ListForValidation v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100016) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100016"></ListForValidation>
    <RdsUsers v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100017) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100017"></RdsUsers>
    <CompanyUserManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100018) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100018"></CompanyUserManage>
    <WarehouseAuthority v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100019) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100019"></WarehouseAuthority>
    <MemberClassList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100020) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100020"></MemberClassList>
    <CommodityWarningList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100021) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100021"></CommodityWarningList>
    <RetailConfigurationManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100022) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100022"></RetailConfigurationManage>
    <ButtonVisibleManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100024) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100024"></ButtonVisibleManage>
    <BusinessPartnerAuthority v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100025) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100025"></BusinessPartnerAuthority>
    <BusinessPartnerList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100026) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100026"></BusinessPartnerList>
    <CostVisibilityMange v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100027) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100027"></CostVisibilityMange>
    <SystemParameterList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100028) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100028"></SystemParameterList>
    <ApiAuthorityManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100029) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100029"></ApiAuthorityManage>
    <ViewButtonList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100031) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100031"></ViewButtonList>
    <ApiList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100032) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100032"></ApiList>
    <MainManuList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100033) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100033"></MainManuList>
    <UserConfigList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100034) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100034"></UserConfigList>
    <MainManuManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100035) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100035"></MainManuManage>
    <RoleList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100036) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100036"></RoleList>
    <UserRoleConfigList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100037) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100037"></UserRoleConfigList>
    <PoList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100039) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100039"></PoList>
    <SystemLogList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100040) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100040"></SystemLogList>
    <EsCommodity v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100041) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100041"></EsCommodity>
    <SystemSwitchManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100042) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100042"></SystemSwitchManage>
    <PoInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100044) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100044" @refreshMsg="refreshMsg" ref="poInputRef"></PoInput>
    <PageNameManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100045) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100045"></PageNameManage>
    <DocTableElementManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100046) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100046"></DocTableElementManage>
    <DocStatusManageList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100047) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100047"></DocStatusManageList>
    <DocumentAlterRecord v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100048) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100048"></DocumentAlterRecord>
    <SoInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100049) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100049" @refreshMsg="refreshMsg" ref="SoInputRef"></SoInput>
    <InventoryBalanceList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100050) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100050" @goInventoryChangeFromInvBal="goInventoryChangeFromInvBal"></InventoryBalanceList>
    <InventoryChangeList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100051) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100051" ref="InventoryChangeListRef"></InventoryChangeList>
    <IsInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100052) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100052"></IsInput>
    <OoInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100053) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100053"></OoInput>
    <StInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100054) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100054" @refreshMsg="refreshMsg"></StInput>
    <MoInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100055) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100055" ref="MoInputRef"></MoInput>
    <MiInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100056) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100056" ref="MiInputRef"></MiInput>
    <AccountsList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100057) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100057" @goAccoutsChangeFromAcc="goAccoutsChangeFromAcc" @goMiInputFromAcc="goMiInputFromAcc" @goMoInputFromAcc="goMoInputFromAcc"></AccountsList>
    <AccountsChangeList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100058) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100058" ref="AccountsChangeListRef"></AccountsChangeList>
    <CapitalAccountList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100038) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100038" @goCapitalAccountBlanceChangeListFromCap="goCapitalAccountBlanceChangeListFromCap"></CapitalAccountList>
    <DocumentCenter v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100059) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100059" @goOpenOrderFromDoc="goOpenOrderFromDoc" @goEditOrderFromDoc="goEditOrderFromDoc"></DocumentCenter>
    <CapitalAccountBlanceChangeList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100060) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100060" ref="CapitalAccountBlanceChangeListRef"></CapitalAccountBlanceChangeList>
    <FrInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100061) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100061" @refreshMsg="refreshMsg" ref="FrInputRef"></FrInput>
    <CommodityFirstClass v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100062) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100062"></CommodityFirstClass>
    <RepairRecordList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100063) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100063" @goRoLissFromRepairList="goRoLissFromRepairList" @goObListFromRepairRecord="goObListFromRepairRecord"></RepairRecordList>
    <RoInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100064) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100064" @refreshMsg="refreshMsg" @goInventoryChangeList="goInventoryChangeList" ref="RoInputRef"></RoInput>
    <ErInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100065) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100065"></ErInput>
    <EdInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100066) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100066"></EdInput>
    <GrInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100067) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100067"></GrInput>
    <GdInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100068) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100068"></GdInput>
    <KpiMatchingTable v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100069) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100069"></KpiMatchingTable>
    <TtInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100070) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100070" @refreshMsg="refreshMsg"></TtInput>
    <ObInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100071) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100071" @refreshMsg="refreshMsg" ref="ObInputRef"></ObInput>
    <MpInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100072) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100072" @refreshMsg="refreshMsg"></MpInput>
    <AsInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100073) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100073" @refreshMsg="refreshMsg"></AsInput>
    <CompanyLayerMange v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100074) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100074"></CompanyLayerMange>
    <MainInfo v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100075) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100075"></MainInfo>
    <OnTheWayListForPurchase v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100076) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100076" @refreshMsg="refreshMsg" @goOnTheWayHistory="goOnTheWayHistory"></OnTheWayListForPurchase>
    <OnTheWayListForSale v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100077) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100077" @refreshMsg="refreshMsg" @goPoInputFromOnTheWayForSale="goPoInputFromOnTheWayForSale" @goOnTheWayHistory="goOnTheWayHistory"></OnTheWayListForSale>
    <OnTheWayTypeList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100078) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100078"></OnTheWayTypeList>
    <MeterialSaleList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100079) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100079" @refreshMsg="refreshMsg" @goSalesOnTheWayHistory="goSalesOnTheWayHistory"></MeterialSaleList>
    <MeterialSaleSolveList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100080) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100080" @refreshMsg="refreshMsg" @goSalesOnTheWayHistory="goSalesOnTheWayHistory"></MeterialSaleSolveList>
    <MoneyToBePayImmediateList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100081) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100081" @refreshMsg="refreshMsg"></MoneyToBePayImmediateList>
    <MachineToBeSolve v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100082) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100082" @refreshMsg="refreshMsg"></MachineToBeSolve>
    <ClassToBrandList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100083) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100083"></ClassToBrandList>
    <ToBeShippedSolve v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100084) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100084" ref="toBeShippedSolveRef" @refreshMsg="refreshMsg" @goPrintForShipFromToBeShipped="goPrintForShipFromToBeShipped"></ToBeShippedSolve>
    <MachineQueryListSolve v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100085) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100085" @refreshMsg="refreshMsg"></MachineQueryListSolve>
    <MachineTypeList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100086) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100086"></MachineTypeList>
    <BreakdownCauseList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100087) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100087"></BreakdownCauseList>
    <QuotationList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100088) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100088"></QuotationList>
    <QuotationForCustomer v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100089) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100089" @goRoListFromQuotation="goRoListFromQuotation" @goFrInputFromQuotation="goFrInputFromQuotation" @goObInputFromQuo="goObInputFromQuo" @goObInputFromQuo2="goObInputFromQuo2"></QuotationForCustomer>
    <MachineMarket v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100090) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100090" @refreshMsg="refreshMsg"></MachineMarket>
    <ObTracingList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100091) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100091" @refreshMsg="refreshMsg" @goRoListFromObTracing="goRoListFromObTracing"></ObTracingList>
    <MachineQueryListManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100092) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100092" @refreshMsg="refreshMsg"></MachineQueryListManage>
    <CommodityCodeMapping v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100093) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100093"></CommodityCodeMapping>
    <RecyleOnTheWayList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100094) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100094" @refreshMsg="refreshMsg" @goRecycleOnTheWayHistory="goRecycleOnTheWayHistory"></RecyleOnTheWayList>
    <EoInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100095) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100095" @refreshMsg="refreshMsg"></EoInput>
    <IoInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100096) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100096" @refreshMsg="refreshMsg"></IoInput>
    <RbInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100097) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100097" @refreshMsg="refreshMsg"></RbInput>
    <PrInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100098) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100098" @refreshMsg="refreshMsg"></PrInput>
    <RrInput v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100099) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100099" @refreshMsg="refreshMsg"></RrInput>
    <StOnTheWayList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100100) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100100" @refreshMsg="refreshMsg"></StOnTheWayList>
    <AuthorityManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100101) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100101"></AuthorityManage>
    <ProfitShow v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100102) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100102"></ProfitShow>
    <PrintForShippment v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100103) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100103" ref="printForShipRef" @goToBeShippedSolveFromPrint="goToBeShippedSolveFromPrint"></PrintForShippment>
    <BanCodePrint v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100104) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100104"></BanCodePrint>
    <ReportShow v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100105) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100105"></ReportShow>
    <EditOrder v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100106) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100106" ref="EditOrderRef"></EditOrder>
    <ShippedList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100107) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100107"></ShippedList>
    <OnTheWayDoneList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100108) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100108"></OnTheWayDoneList>
    <SalesOnTheWayDoneList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100109) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100109"></SalesOnTheWayDoneList>
    <RecyleOnTheWayDoneList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100110) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100110"></RecyleOnTheWayDoneList>
    <PriceManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100111) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100111"></PriceManage>
    <PriceBenchMark v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100112) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100112"></PriceBenchMark>
    <FrList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100113) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100113"></FrList>
    <AccountsChangeOnTheWayList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100114) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100114"></AccountsChangeOnTheWayList>
    <TurnoverAnalysis v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100115) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100115"></TurnoverAnalysis>
    <CommodityAlertManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100116) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100116"></CommodityAlertManage>
    <RootMaterialList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100117) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100117"></RootMaterialList>
    <PipeStatusManage v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100118) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100118"></PipeStatusManage>
    <SsList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100119) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100119"></SsList>
    <CustomerSuggestionList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100120) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100120"></CustomerSuggestionList>
    <InventoryInactiveList v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100121) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100121"></InventoryInactiveList>
    <DiyCommodityMangeTable v-if="$store.state.loginInitializeData.ActiveWindow.indexOf(100122) > -1" v-show="$store.state.loginInitializeData.ShowWindow[0] == 100122"></DiyCommodityMangeTable>

  </div>
</template>

<script>
import AccountingSubjectsManage from '@/components/Content/FunctionPage/AccountingSubjectsManage.vue'
import SystemStatusList from '@/components/Content/FunctionPage/SystemStatusList.vue'
import DocumentTypeManage from '@/components/Content/FunctionPage/DocumentTypeManage.vue'
import BrandList from '@/components/Content/FunctionPage/BrandList.vue'
import BenchMarkChangeSubmit from '@/components/Content/FunctionPage/BenchMarkChangeSubmit.vue'
import SalesAttributesManage from '@/components/Content/FunctionPage/SalesAttributesManage.vue'
import SecondLevelClassList from '@/components/Content/FunctionPage/SecondLevelClassList.vue'
import RechargeCodeList from '@/components/Content/FunctionPage/RechargeCodeList.vue'
import CommodityMangeTable from '@/components/Content/FunctionPage/CommodityMangeTable.vue'
import WareHouseList from '@/components/Content/FunctionPage/WareHouseList.vue'
import ViewList from '@/components/Content/FunctionPage/ViewList.vue'
import ViewVisibleManage from '@/components/Content/FunctionPage/ViewVisibleManage.vue'
import FilterManage from '@/components/Content/FunctionPage/FilterManage.vue'
import DepartmentList from '@/components/Content/FunctionPage/DepartmentList.vue'
import StaffList from '@/components/Content/FunctionPage/StaffList.vue'
import KpiManage from '@/components/Content/FunctionPage/KpiManage.vue'
import CustomerSourceList from '@/components/Content/FunctionPage/CustomerSourceList.vue'
import WarrantyStatementList from '@/components/Content/FunctionPage/WarrantyStatementList.vue'
import VmiPriceMethodList from '@/components/Content/FunctionPage/VmiPriceMethodList.vue'
import ListForValidation from '@/components/Content/FunctionPage/ListForValidation.vue'
import RdsUsers from '@/components/Content/FunctionPage/RdsUsers.vue'
import CompanyUserManage from '@/components/Content/FunctionPage/CompanyUserManage.vue'
import WarehouseAuthority from '@/components/Content/FunctionPage/WarehouseAuthority.vue'
import MemberClassList from '@/components/Content/FunctionPage/MemberClassList.vue'
import CommodityWarningList from '@/components/Content/FunctionPage/CommodityWarningList.vue'
import RetailConfigurationManage from '@/components/Content/FunctionPage/RetailConfigurationManage.vue'
import ButtonVisibleManage from '@/components/Content/FunctionPage/ButtonVisibleManage.vue'
import BusinessPartnerAuthority from '@/components/Content/FunctionPage/BusinessPartnerAuthority.vue'
import BusinessPartnerList from '@/components/Content/FunctionPage/BusinessPartnerList.vue'
import CostVisibilityMange from '@/components/Content/FunctionPage/CostVisibilityMange.vue'
import SystemParameterList from '@/components/Content/FunctionPage/SystemParameterList.vue'
import ApiAuthorityManage from '@/components/Content/FunctionPage/ApiAuthorityManage.vue'
import ViewButtonList from '@/components/Content/FunctionPage/ViewButtonList.vue'
import ApiList from '@/components/Content/FunctionPage/ApiList.vue'
import MainManuList from '@/components/Content/FunctionPage/MainManuList.vue'
import UserConfigList from '@/components/Content/FunctionPage/UserConfigList.vue'
import MainManuManage from '@/components/Content/FunctionPage/MainManuManage.vue'
import RoleList from '@/components/Content/FunctionPage/RoleList.vue'
import UserRoleConfigList from '@/components/Content/FunctionPage/UserRoleConfigList.vue'
import PoList from '@/components/Content/FunctionPage/PoList.vue'
import SystemLogList from '@/components/Content/FunctionPage/SystemLogList.vue'
import EsCommodity from '@/components/Content/FunctionPage/EsCommodity.vue'
import SystemSwitchManage from '@/components/Content/FunctionPage/SystemSwitchManage.vue'
import PoInput from '@/components/Content/InputPage/PoInput.vue'
import PageNameManage from '@/components/Content/FunctionPage/PageNameManage.vue'
import DocTableElementManage from '@/components/Content/FunctionPage/DocTableElementManage.vue'
import DocStatusManageList from '@/components/Content/FunctionPage/DocStatusManageList.vue'
import DocumentAlterRecord from '@/components/Content/FunctionPage/DocumentAlterRecord.vue'
import SoInput from '@/components/Content/InputPage/SoInput.vue'
import InventoryBalanceList from '@/components/Content/FunctionPageDiy/InventoryBalanceList.vue'
import InventoryChangeList from '@/components/Content/FunctionPageDiy/InventoryChangeList.vue'
import IsInput from '@/components/Content/InputPage/IsInput.vue'
import OoInput from '@/components/Content/InputPage/OoInput.vue'
import StInput from '@/components/Content/InputPage/StInput.vue'
import MoInput from '@/components/Content/InputPage/MoInput.vue'
import MiInput from '@/components/Content/InputPage/MiInput.vue'
import AccountsList from '@/components/Content/FunctionPageDiy/AccountsList.vue'
import AccountsChangeList from '@/components/Content/FunctionPageDiy/AccountsChangeList.vue'
import CapitalAccountList from '@/components/Content/FunctionPageDiy/CapitalAccountList.vue'
import DocumentCenter from '@/components/Content/FunctionPageDiy/DocumentCenter.vue'
import CapitalAccountBlanceChangeList from '@/components/Content/FunctionPageDiy/CapitalAccountBlanceChangeList.vue'
import FrInput from '@/components/Content/InputPage/FrInput.vue'
import CommodityFirstClass from '@/components/Content/FunctionPage/CommodityFirstClass.vue'
import RepairRecordList from '@/components/Content/FunctionPageDiy/RepairRecordList.vue'
import RoInput from '@/components/Content/InputPage/RoInput.vue'
import ErInput from '@/components/Content/InputPage/ErInput.vue'
import EdInput from '@/components/Content/InputPage/EdInput.vue'
import GrInput from '@/components/Content/InputPage/GrInput.vue'
import GdInput from '@/components/Content/InputPage/GdInput.vue'
import KpiMatchingTable from '@/components/Content/FunctionPage/KpiMatchingTable.vue'
import TtInput from '@/components/Content/InputPage/TtInput.vue'
import ObInput from '@/components/Content/InputPage/ObInput.vue'
import MpInput from '@/components/Content/InputPage/MpInput.vue'
import AsInput from '@/components/Content/InputPage/AsInput.vue'
import CompanyLayerMange from '@/components/Content/FunctionPage/CompanyLayerMange.vue'
import MainInfo from '@/components/Content/DiyPage/MainInfo.vue'
import OnTheWayListForPurchase from '@/components/Content/FunctionPageDiy/OnTheWayListForPurchase.vue'
import OnTheWayListForSale from '@/components/Content/FunctionPageDiy/OnTheWayListForSale.vue'
import OnTheWayTypeList from '@/components/Content/FunctionPage/OnTheWayTypeList.vue'
import MeterialSaleList from '@/components/Content/FunctionPageDiy/MeterialSaleList.vue'
import MeterialSaleSolveList from '@/components/Content/FunctionPageDiy/MeterialSaleSolveList.vue'
import MoneyToBePayImmediateList from '@/components/Content/FunctionPageDiy/MoneyToBePayImmediateList.vue'
import MachineToBeSolve from '@/components/Content/FunctionPageDiy/MachineToBeSolve.vue'
import ClassToBrandList from '@/components/Content/FunctionPage/ClassToBrandList.vue'
import ToBeShippedSolve from '@/components/Content/FunctionPageDiy/ToBeShippedSolve.vue'
import MachineQueryListSolve from '@/components/Content/FunctionPageDiy/MachineQueryListSolve.vue'
import MachineTypeList from '@/components/Content/FunctionPage/MachineTypeList.vue'
import BreakdownCauseList from '@/components/Content/FunctionPage/BreakdownCauseList.vue'
import QuotationList from '@/components/Content/FunctionPage/QuotationList.vue'
import QuotationForCustomer from '@/components/Content/DiyPage/QuotationForCustomer.vue'
import MachineMarket from '@/components/Content/FunctionPageDiy/MachineMarket.vue'
import ObTracingList from '@/components/Content/FunctionPageDiy/ObTracingList.vue'
import MachineQueryListManage from '@/components/Content/FunctionPageDiy/MachineQueryListManage.vue'
import CommodityCodeMapping from '@/components/Content/FunctionPage/CommodityCodeMapping.vue'
import RecyleOnTheWayList from '@/components/Content/FunctionPageDiy/RecyleOnTheWayList.vue'
import EoInput from '@/components/Content/InputPage/EoInput.vue'
import IoInput from '@/components/Content/InputPage/IoInput.vue'
import RbInput from '@/components/Content/InputPage/RbInput.vue'
import PrInput from '@/components/Content/InputPage/PrInput.vue'
import RrInput from '@/components/Content/InputPage/RrInput.vue'
import StOnTheWayList from '@/components/Content/FunctionPageDiy/StOnTheWayList.vue'
import AuthorityManage from '@/components/Content/DiyPage/AuthorityManage.vue'
import ProfitShow from '@/components/Content/DiyPage/ProfitShow.vue'
import PrintForShippment from '@/components/Content/DiyPage/PrintForShippment.vue'
import BanCodePrint from '@/components/Content/DiyPage/BanCodePrint.vue'
import ReportShow from '@/components/Content/DiyPage/ReportShow.vue'
import EditOrder from '@/components/Content/DiyPage/EditOrder.vue'
import ShippedList from '@/components/Content/FunctionPageDiy/ShippedList.vue'
import OnTheWayDoneList from '@/components/Content/FunctionPageDiy/OnTheWayDoneList.vue'
import SalesOnTheWayDoneList from '@/components/Content/FunctionPageDiy/SalesOnTheWayDoneList.vue'
import RecyleOnTheWayDoneList from '@/components/Content/FunctionPageDiy/RecyleOnTheWayDoneList.vue'
import PriceManage from '@/components/Content/DiyPage/PriceManage.vue'
import PriceBenchMark from '@/components/Content/FunctionPage/PriceBenchMark.vue'
import FrList from '@/components/Content/FunctionPage/FrList.vue'
import AccountsChangeOnTheWayList from '@/components/Content/FunctionPageDiy/AccountsChangeOnTheWayList.vue'
import TurnoverAnalysis from '@/components/Content/DiyPage/TurnoverAnalysis.vue'
import CommodityAlertManage from '@/components/Content/DiyPage/CommodityAlertManage.vue'
import RootMaterialList from '@/components/Content/FunctionPage/RootMaterialList.vue'
import PipeStatusManage from '@/components/Content/DiyPage/PipeStatusManage.vue'
import SsList from '@/components/Content/FunctionPage/SsList.vue'
import CustomerSuggestionList from '@/components/Content/FunctionPage/CustomerSuggestionList.vue'
import InventoryInactiveList from '@/components/Content/DiyPage/InventoryInactiveList.vue'
import DiyCommodityMangeTable from '@/components/Content/DiyPage/DiyCommodityMangeTable.vue'

import { useStore } from "vuex";
import { changeToViewCode } from '@/hooks/OpenOrder'
import { ref } from 'vue-demi'
import { ElMessage } from 'element-plus'

export default {
  setup(props, { emit }) {
    const store = useStore();

    const RoInputRef = ref(null)

    const SoInputRef = ref(null)

    const FrInputRef = ref(null)

    const ObInputRef = ref(null)

    const MiInputRef = ref(null)

    const MoInputRef = ref(null)

    const poInputRef = ref(null)

    const EditOrderRef = ref(null)

    const InventoryChangeListRef = ref(null)

    const AccountsChangeListRef = ref(null)

    const CapitalAccountBlanceChangeListRef = ref(null)

    const printForShipRef = ref(null)

    const toBeShippedSolveRef = ref(null)

    const goRoLissFromRepairList = (value) => {
      let code = changeToViewCode('RoInput')
      store.commit('GoFunctionWindowWithCode', code)
      setTimeout(() => {
        RoInputRef.value.getDataFromRepairList(value)
      }, 0);
    }

    const goRoListFromObTracing = (value) => {
      let code = changeToViewCode('RoInput')
      store.commit('GoFunctionWindowWithCode', code)
      setTimeout(() => {
        RoInputRef.value.getDataFromObTracing(value)
      }, 0);
    }

    const goRoListFromQuotation = (value) => {
      let code = changeToViewCode('RoInput')
      store.commit('GoFunctionWindowWithCode', code)
      setTimeout(() => {
        RoInputRef.value.getDataFromQuotation(value)
      }, 0);
    }

    const goFrInputFromQuotation = (value) => {
      let code = changeToViewCode('FrInput')
      store.commit('GoFunctionWindowWithCode', code)
      setTimeout(() => {
        FrInputRef.value.getDataFromQuotation(value)
      }, 0);
    }

    const goObInputFromQuo = (value) => {
      let code = changeToViewCode('ObInput')
      store.commit('GoFunctionWindowWithCode', code)
      setTimeout(() => {
        ObInputRef.value.getDataFromQuotation(value)
      }, 0);
    }

    const goAccoutsChangeFromAcc = (value) => {
      let code = changeToViewCode('AccountsChangeList')
      store.commit('GoFunctionWindowWithCode', code)
      setTimeout(() => {
        AccountsChangeListRef.value.getDataFromAcc(value)
      }, 0);
    }

    const goCapitalAccountBlanceChangeListFromCap = (value) => {
      let code = changeToViewCode('CapitalAccountBlanceChangeList')
      store.commit('GoFunctionWindowWithCode', code)
      setTimeout(() => {
        CapitalAccountBlanceChangeListRef.value.getDataFromCap(value)
      }, 0);
    }

    const goMiInputFromAcc = (value) => {
      let code = changeToViewCode('MiInput')
      store.commit('GoFunctionWindowWithCode', code)
      setTimeout(() => {
        MiInputRef.value.getDataFromAcc(value)
      }, 0);
    }

    const goMoInputFromAcc = (value) => {
      let code = changeToViewCode('MoInput')
      store.commit('GoFunctionWindowWithCode', code)
      setTimeout(() => {
        MoInputRef.value.getDataFromAcc(value)
      }, 0);
    }

    const goInventoryChangeFromInvBal = (value) => {
      let code = changeToViewCode('InventoryChangeList')
      store.commit('GoFunctionWindowWithCode', code)
      setTimeout(() => {
        InventoryChangeListRef.value.getDataFromInvBal(value)
      }, 0);
    }

    const goPoInputFromOnTheWayForSale = (value) => {
      let code = changeToViewCode('PoInput')
      store.commit('GoFunctionWindowWithCode', code)
      setTimeout(() => {
        poInputRef.value.getDataFromOnTheWayForSale(value)
      }, 0);
    }


    const refreshMsg = () => {
      emit('refreshMessageBar')
    }

    const goPrintForShipFromToBeShipped = (value) => {
      let code = changeToViewCode('PrintForShippment')
      store.commit('GoFunctionWindowWithCode', code)
      setTimeout(() => {
        printForShipRef.value.getDataFromToBeShippedSolve(value)
      }, 0);
    }

    const goToBeShippedSolveFromPrint = () => {
      let code = changeToViewCode('ToBeShippedSolve')
      store.commit('GoFunctionWindowWithCode', code)
      let code2 = changeToViewCode('PrintForShippment')
      store.commit('CloseActiveWindow', code2)
      setTimeout(() => {
        toBeShippedSolveRef.value.comebackFromPrint()
      }, 0);
    }

    const goObListFromRepairRecord = (value) => {
      let code = changeToViewCode('ObInput')
      store.commit('GoFunctionWindowWithCode', code)
      setTimeout(() => {
        ObInputRef.value.getDataFromRepairList(value)
      }, 0);
    }

    const goObInputFromQuo2 = (value) => {
      let code = changeToViewCode('ObInput')
      store.commit('GoFunctionWindowWithCode', code)
      setTimeout(() => {
        ObInputRef.value.getDataFromQuotation2(value)
      }, 0);
    }

    const goOpenOrderFromDoc = (value) => {
      const docTypeJudge = value.documentId.substring(0, 2)
      let codeName = docTypeJudge.substring(0, 1) + docTypeJudge.substring(1, 2).toLowerCase() + 'Input'
      let code = changeToViewCode(codeName)
      switch (docTypeJudge) {
        case 'RO':
          store.commit('GoFunctionWindowWithCode', code)
          setTimeout(() => {
            RoInputRef.value.copyOrderFromDocCenter(value)
          }, 0);
          break;
        case 'SO':
          store.commit('GoFunctionWindowWithCode', code)
          setTimeout(() => {
            SoInputRef.value.copyOrderFromDocCenter(value)
          }, 0);
          break;
        case 'PO':
          store.commit('GoFunctionWindowWithCode', code)
          setTimeout(() => {
            poInputRef.value.copyOrderFromDocCenter(value)
          }, 0);
          break;
        default:
          ElMessage('该单据类型暂不支持复制')
      }
    }

    const goEditOrderFromDoc = (value) => {

      let code = changeToViewCode('EditOrder')
      store.commit('GoFunctionWindowWithCode', code)
      setTimeout(() => {
        EditOrderRef.value.fillDataFromDocCenter(value)
      }, 0);
    }

    const goRecycleOnTheWayHistory = () => {
      let code = changeToViewCode('RecyleOnTheWayDoneList')
      store.commit('GoFunctionWindowWithCode', code)
    }

    const goOnTheWayHistory = (direc) => {
      let code = changeToViewCode('OnTheWayDoneList')
      store.commit('alterOnTheWayDoneSearchType', direc)
      store.commit('GoFunctionWindowWithCode', code)
    }

    const goSalesOnTheWayHistory = (direc) => {
      let code = changeToViewCode('SalesOnTheWayDoneList')
      store.commit('alterSalesOnTheWayDoneSearchType', direc)
      store.commit('GoFunctionWindowWithCode', code)
    }

    const goInventoryChangeList = (materialName) => {
      let code = changeToViewCode('InventoryChangeList')
      store.commit('GoFunctionWindowWithCode', code)
      setTimeout(() => {
        InventoryChangeListRef.value.comebackFromRoList(materialName)
      }, 0);
    }


    return {
      refreshMsg, goRoLissFromRepairList, toBeShippedSolveRef,
      RoInputRef, goRoListFromObTracing, goRoListFromQuotation,
      FrInputRef, goFrInputFromQuotation, goObInputFromQuo,
      ObInputRef, goAccoutsChangeFromAcc, goObInputFromQuo2,
      AccountsChangeListRef, goCapitalAccountBlanceChangeListFromCap,
      CapitalAccountBlanceChangeListRef, MiInputRef, goMiInputFromAcc, goMoInputFromAcc,
      MoInputRef, InventoryChangeListRef, goInventoryChangeFromInvBal, goPoInputFromOnTheWayForSale,
      poInputRef, goPrintForShipFromToBeShipped, printForShipRef, goToBeShippedSolveFromPrint, goObListFromRepairRecord,
      goOpenOrderFromDoc, SoInputRef, goEditOrderFromDoc, EditOrderRef, goRecycleOnTheWayHistory,
      goOnTheWayHistory, goSalesOnTheWayHistory, goInventoryChangeList
    }
  },
  components: {
    AccountingSubjectsManage, EoInput, IoInput, RbInput, PrintForShippment, ShippedList, AccountsChangeOnTheWayList,
    SystemStatusList, PrInput, RrInput, AuthorityManage, BanCodePrint, OnTheWayDoneList,
    DocumentTypeManage, StOnTheWayList, ReportShow, EditOrder, RecyleOnTheWayDoneList,
    BrandList,BenchMarkChangeSubmit, SalesAttributesManage, SecondLevelClassList, RechargeCodeList, PriceManage,
    CommodityMangeTable, WareHouseList, SalesOnTheWayDoneList, PriceBenchMark,DiyCommodityMangeTable,
    ViewList, ViewVisibleManage, FilterManage, DepartmentList, StaffList, SsList,
    KpiManage, RecyleOnTheWayList, ProfitShow, FrList, TurnoverAnalysis,
    CustomerSourceList, CommodityAlertManage, InventoryInactiveList,
    WarrantyStatementList, PipeStatusManage,
    VmiPriceMethodList, CustomerSuggestionList,
    ListForValidation,
    RdsUsers, RootMaterialList,
    CompanyUserManage,
    WarehouseAuthority,
    MemberClassList,
    CommodityWarningList,
    RetailConfigurationManage,
    ButtonVisibleManage,
    BusinessPartnerAuthority,
    BusinessPartnerList,
    CostVisibilityMange,
    SystemParameterList,
    ApiAuthorityManage,
    ViewButtonList,
    ApiList,
    MainManuList,
    UserConfigList,
    MainManuManage,
    RoleList,
    UserRoleConfigList,
    CapitalAccountList,
    PoList,
    SystemLogList,
    EsCommodity,
    SystemSwitchManage,
    PoInput,
    PageNameManage,
    DocTableElementManage,
    DocStatusManageList, DocumentAlterRecord, SoInput, InventoryBalanceList, InventoryChangeList,
    IsInput, OoInput, StInput, MoInput, MiInput, AccountsList,
    AccountsChangeList, DocumentCenter, CapitalAccountBlanceChangeList, FrInput, CommodityFirstClass,
    RepairRecordList, RoInput, ErInput, EdInput, GrInput, GdInput, KpiMatchingTable, TtInput,
    ObInput, MpInput, AsInput, CompanyLayerMange, MainInfo, OnTheWayListForPurchase, OnTheWayListForSale,
    OnTheWayTypeList, MeterialSaleList, MeterialSaleSolveList, MoneyToBePayImmediateList, MachineToBeSolve,
    ClassToBrandList, ToBeShippedSolve, MachineQueryListSolve, MachineTypeList, BreakdownCauseList, QuotationList,
    QuotationForCustomer, ObTracingList, MachineQueryListManage,
    MachineMarket, CommodityCodeMapping
  }
}
</script>

<style scoped lang="less">
.MainbodyContainer {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
</style>