<template>
  <PopModel ref="popModelRef">
    <div class=PopBody>
      <div class="chooseBox" v-for="(item,key) in searchItemListForShow" :key="key + 'searchContent'">
        <div>{{item.showName}}</div>
        <input type="checkbox" v-model="searchItemList[key].searchShow" @click="checkNum(key)">
      </div>
    </div>
    <div class="popfooterforBp">
      <span class="Remind">*最多可以选择3项*</span>
      <el-button type="primary" @click="closePopComm()">关闭</el-button>
    </div>
  </PopModel>
</template>
<script>
import { computed, inject, ref } from 'vue-demi';
import PopModel from '@/components/Common/PopModel.vue';
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
export default {
  components: {
    PopModel
  },
  setup() {
    const store = useStore();

    //#region 数据定义区
    //最大搜索格
    let searchMax = 3;

    let searchItemList = inject('searchItemList')

    const popModelRef = ref(null);

    let searchItemListForShow = computed(() => {
      let b = {}
      for (let key in searchItemList) {
        if (searchItemList[key].searchTrans == true && key[key.length - 1] != 'H') {
          b[key] = searchItemList[key]
        }
      }
      return b;
    })
    //#endregion

    //#region 公式定义区
    const openPopComm = () => {
      popModelRef.value.openPopComm()
    }

    const closePopComm = () => {
      popModelRef.value.closePopComm()
    }

    const checkNum = (key) => {
      setTimeout(() => {
        let num = 0
        for (let key in searchItemList) {
          if (searchItemList[key].searchShow == true && key[key.length - 1] != 'H') {
            num++
          }
        }
        if (num > searchMax) {
          searchItemList[key].searchShow = false
          ElMessage(`搜索配置不能超过${searchMax}个`)
        }
      }, 0)
    }
    //#endregion

    //#region 数据提供区

    //#endregion

    //#region 挂载前加载

    //#endregion

    //#region 失去活跃处理

    //#endregion

    //#region 活跃时处理

    //#endregion

    //#region 离开时处理

    //#endregion





    return {
      searchItemList,
      searchItemListForShow,
      checkNum,
      openPopComm,
      popModelRef,
      closePopComm
    }
  }
}
</script>

<style scoped lang="less">
.PopBody {
  display: flex; 
  flex-wrap: wrap;
  width: 38rem;
}
.chooseBox {
  display: flex;
  margin: 1rem;
  align-items: center;
}
.chooseBox div {
  margin-right: 0.5rem;
}
.chooseBox {
  font-size: 1rem;
}
.popfooterforBp {
  display: flex;
  margin: 1rem;
  align-items: center;
  justify-content: space-between;
}
.Remind {
  color: red;
}

.chooseBox input:hover {
  cursor: pointer;
}
</style>