<template>
  <div class="inputLoaderContainer">
    <BodyTitle></BodyTitle>
    <InputHead></InputHead>
    <InputBody ref="inputBodyRef" @clearScanIn="clearScanIn"></InputBody>
    <div class="EditTableLine">
      <ComScanIn :scanInValue="scanInValue" @scanIn="scanIn"></ComScanIn>
    </div>
    <div class="EditTableLastLine">
      <!-- 维护第七层的内容 -->
      <div class="EditTableFuncButton" :style="{background:'#73a6a3'}" @click="openPopUp()">导入订单明细</div>
      <div class="EditTableFuncButton EditTableFuncButtonAjust" @click="openList('')">保存并调拨</div>
    </div>
    <UpPop ref="upPopRef" @uploadData="uploadData"></UpPop>
  </div>
</template>
<script>
import { computed, provide, ref, watch } from 'vue-demi'
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import InputHead from '@/components/Content/MainBodyContent/InputHead.vue'
import InputBody from '@/components/Content/MainBodyContent/InputBody.vue'
import UpPop from '@/components/Common/GlobalPop/UpPop.vue'
import {
  getDocNo, updateWarning, getLoginData, copyArrayDoc, getDocType,
  deleteDocCenter, openStList, checkDocCondition, checkInStockId,
  clearTableContent, addStOnTheWay, transferToCode
} from '@/hooks/OpenOrder'
import { transferLoginDataOnly } from '@/hooks/loginAffair';
import ComScanIn from '@/components/Content/FunctionUnit/ComScanIn.vue'
import InputTable from '@/hooks/InputTable'
import { useStore } from "vuex";
import { isRealNum } from '@/hooks/tools'
import { ElMessage } from 'element-plus'
export default {
  components: {
    BodyTitle,
    InputHead,
    InputBody,
    UpPop,
    ComScanIn
  },
  setup() {
    const store = useStore();
    const RootTableName = 'StList';
    let { itemListHead, itemListBody, searchItemList } = InputTable(RootTableName)
    searchItemList.docTitleTwo = 'ST'
    searchItemList.TableName = 'StInput'
    provide('searchItemList', searchItemList)

    let inputBodyRef = ref(null)
    //#endregion

    //#region 扫描组件
    let scanInValue = ref('');

    const scanIn = (res) => {
      inputBodyRef.value.scanInData(res)
    }

    const clearScanIn = () => {
      scanInValue.value = '';
    }

    //#endregion

    //#region 上传模块
    let upPopRef = ref(null)
    const openPopUp = () => {
      upPopRef.value.openPopUp()
    }

    const uploadData = (dataUpload) => {
      inputBodyRef.value.fillUploadData(dataUpload)
    }
    //#endregion

    //#region 开单功能模块

    const openList = (typeName) => {

      let instockCheck = '';
      let errCheck = checkDocCondition(searchItemList.documentContent, itemListHead, inputBodyRef.value.AddContentBody, itemListBody)

      if (errCheck.headErr) {

        ElMessage(errCheck.headErr)
        return
      }
      if (errCheck.bodyErr) {

        ElMessage(errCheck.bodyErr)
        return
      }
      if (searchItemList.documentContent.InWareHouseId == searchItemList.documentContent.OutWareHouseId) {

        ElMessage('调出仓库和调入仓库不可相同')
        return
      }
      checkInStockId(searchItemList.documentContent.InWareHouseId).then(res => {
        instockCheck = (res == 'pass')
        if (!instockCheck) {
          ElMessage('系统检测到调入仓库负责人不是您，该调拨单需要负责人确认后才可以入库')
        }
        getLoginData().then(res => {
          //刷新初始化数据
        if(res != 0){
          transferLoginDataOnly(res)
        }
          getDocNo(searchItemList.docTitleTwo).then(res => {
            const stHeadCode = res
            const partBCompany = transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId')
            const outWarehouseId = transferToCode(searchItemList.documentContent.OutWareHouseId, 'WareHouseId')
            const inWarehouseId = transferToCode(searchItemList.documentContent.InWareHouseId, 'WareHouseId')
            const partACompany = store.state.loginInitializeData.companyId
            const OutWareHouseName = isRealNum(searchItemList.documentContent.OutWareHouseId) ? transferToName(searchItemList.documentContent.OutWareHouseId, 'WareHouseId') : searchItemList.documentContent.OutWareHouseId
            let CommodityNameArr = []

            for (let item of inputBodyRef.value.AddContentBody) {
              if (item.CommodityName) {
                item.PartACompanyId = partACompany
                item.DocumentId = stHeadCode
                item.PartBCompanyId = partBCompany
                item.InStockQty = item.OrderQty
                item.OutStockQty = item.OrderQty
                item.InWareHouseId = inWarehouseId
              }
              if (!item.VirtualGoods && item.CommodityId) {
                CommodityNameArr.push(item.CommodityId)
              }
            }
            const bodyCopy = copyArrayDoc(inputBodyRef.value.AddContentBody, 'CommodityName')
            openStList(inputBodyRef.value.AddContentBody, searchItemList.documentContent, stHeadCode, instockCheck).then(res => {
              if (res != 'pass') {

                ElMessage(res)
                deleteDocCenter(stHeadCode).then(res => {
                  return false;
                })

              }
              else {
                if (OutWareHouseName == '主仓库') {
                  setTimeout(() => {
                    updateWarning(partACompany, CommodityNameArr)
                  }, 0);
                }
                if (instockCheck) {

                  clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                }
                else {
                  let bodyLength = bodyCopy.length
                  let promiseArr = []
                  const docType = getDocType(stHeadCode)
                  for (let i = 1; i <= bodyLength; i++) {
                    let stOnBody = {
                      DocumentId: stHeadCode,
                      Sn: i,
                      PartACompanyId: partACompany,
                      OutWareHouseId: outWarehouseId,
                      InWareHouseId: inWarehouseId,
                      DocumentDate: '',
                      DocumentType: docType,
                      CommodityName: bodyCopy[i - 1].CommodityName,
                      CommodityId: bodyCopy[i - 1].CommodityId,
                      Remark: '',
                      OrderQty: bodyCopy[i - 1].OrderQty,
                      UnitPrice: '',
                    }
                    let todo = addStOnTheWay(stOnBody)
                    promiseArr.push(todo)
                  }
                  Promise.all(promiseArr).then(resArr => {
                    ElMessage({
                message:'开单成功',
                duration:2000,
                showClose:true
              })
                    clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                  })
                }
              }

            })
          })
        })
      }).catch(err => {

        ElMessage(err.message)
      })
    }
    //#endregion

    //#endregion

    return {
      searchItemList,
      inputBodyRef,
      scanInValue,
      scanIn,
      clearScanIn,
      openPopUp,
      upPopRef,
      uploadData,
      openList
    }
  }
}
</script>
<style scoped lang="less">
.EditTableFuncButtonAjust {
  margin-right: 40rem;
}
</style>