<template>
  <div class="inputLoaderContainer">
    <BodyTitle></BodyTitle>
    <InputHead></InputHead>
    <InputBody ref="inputBodyRef" @clearScanIn="clearScanIn"></InputBody>
    <div class="EditTableLine">
      <ComScanIn :scanInValue="scanInValue" @scanIn="scanIn"></ComScanIn>
    </div>
    <div class="EditTableLastLine">
      <!-- 维护第七层的内容 -->
      <!-- <div class="EditTableFuncButton" :style="{background:'#73a6a3'}" @click="openPopUp()">导入订单明细</div> -->
      <div class="EditTableFuncButton EditTableFuncButtonAjust" @click="openList('')">出库并记账</div>
    </div>
  </div>
</template>
<script>
import { computed, provide, ref, watch } from 'vue-demi'
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import InputHead from '@/components/Content/MainBodyContent/InputHead.vue'
import InputBody from '@/components/Content/MainBodyContent/InputBody.vue'
import { getDocNo, deleteDocCenter, getLoginData, updateWarning, checkDocCondition, transferToCode, openPrList, clearTableContent, getDocType, transferToName, addMoneyToBePaid } from '@/hooks/OpenOrder'
import { transferLoginDataOnly } from '@/hooks/loginAffair';
import ComScanIn from '@/components/Content/FunctionUnit/ComScanIn.vue'
import InputTable from '@/hooks/InputTable'
import { useStore } from "vuex";
import { isRealNum } from '@/hooks/tools'
import { ElMessage } from 'element-plus'
export default {
  components: {
    BodyTitle,
    InputHead,
    InputBody,
    ComScanIn
  },
  setup(props, { emit }) {
    const store = useStore();
    const RootTableName = 'PrList';
    let { itemListHead, itemListBody, searchItemList } = InputTable(RootTableName)
    searchItemList.docTitleTwo = 'PR'
    searchItemList.TableName = 'PrInput'
    provide('searchItemList', searchItemList)

    let inputBodyRef = ref(null)
    //#endregion

    //#region 扫描组件
    let scanInValue = ref('');

    const scanIn = (res) => {
      inputBodyRef.value.scanInData(res)
    }

    const clearScanIn = () => {
      scanInValue.value = '';
    }

    //#endregion

    //#region 开单功能模块


    const openList = (typeName) => {

      let errCheck = checkDocCondition(searchItemList.documentContent, itemListHead, inputBodyRef.value.AddContentBody, itemListBody)
      if (errCheck.headErr) {

        ElMessage(errCheck.headErr)
        return
      }
      if (errCheck.bodyErr) {

        ElMessage(errCheck.bodyErr)
        return
      }
      getLoginData().then(res => {
        //刷新初始化数据
        if(res != 0){
          transferLoginDataOnly(res)
        }
        getDocNo(searchItemList.docTitleTwo).then(res => {
          const prHeadCode = res
          const partBCom = transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId')
          const partACom = store.state.loginInitializeData.companyId
          const OutWareHouseName = isRealNum(searchItemList.documentContent.OutWareHouseId) ? transferToName(searchItemList.documentContent.OutWareHouseId, 'WareHouseId') : searchItemList.documentContent.OutWareHouseId
          let CommodityNameArr = []
          for (let item of inputBodyRef.value.AddContentBody) {
            if (item.CommodityName) {
              item.PartACompanyId = partACom
              item.PartBCompanyId = partBCom
              item.OutStockQty = item.OrderQty
              item.DocumentId = prHeadCode
              item.RootSourceDocumentId = prHeadCode
              item.Remark += `采购退款了${item.OrderQty}个${item.CommodityName}`
            }
            if (!item.VirtualGoods && item.CommodityId) {
              CommodityNameArr.push(item.CommodityId)
            }
          }
          openPrList(inputBodyRef.value.AddContentBody, searchItemList.documentContent, prHeadCode).then(res => {
            if (res != 'pass') {
              ElMessage(res)
              deleteDocCenter(prHeadCode).then(res => {
                return false;
              })
            }
            else {
              if (OutWareHouseName == '主仓库') {
                setTimeout(() => {
                  updateWarning(partACom, CommodityNameArr)
                }, 0);
              }
              ElMessage({
                message:'开单成功',
                duration:2000,
                showClose:true
              })
              clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
              emit('refreshMsg')
            }
          })
        })
      }).catch(err => {

        ElMessage(err.message)
      })
    }
    //#endregion

    //#endregion

    return {
      searchItemList,
      inputBodyRef,
      scanInValue,
      scanIn,
      clearScanIn,
      openList
    }
  }
}
</script>
<style scoped lang="less">
.EditTableFuncButton {
  margin-right: 15rem;
}
</style>