<template>
  <div class="BigTable">
    <div>

    </div>
    <div class="TableContentArea">
      <div>
        <table class="tablehead">
          <tr class="headtr" :style="{ height : trHeight }">
            <th class="thSN">
              <div :style="{ width: SnWidth }"></div>
            </th>
            <th>
              <div class="thoper" :style="{ width: '100px' }">操作</div>
            </th>
            <th>
              <div :style="{ width: '20rem' }">维修机器</div>
            </th>
            <th>
              <div :style="{ width: StatusWidth }">客户人员</div>
            </th>
            <th>
              <div :style="{ width: '8rem' }">开机密码</div>
            </th>
            <th>
              <div :style="{ width: StatusWidth }">维修人</div>
            </th>
            <th>
              <div :style="{ width: StatusWidth }">报价</div>
            </th>
            <th>
              <div :style="{ width: StatusWidth }">客户意见</div>
            </th>
            <th>
              <div :style="{ width: ourSourceWidth }">外协单位</div>
            </th>
            <th>
              <div :style="{ width: StatusWidth }">确认接单</div>
            </th>
            <th>
              <div :style="{ width: StatusWidth }">外协维修</div>
            </th>
            <th>
              <div :style="{ width: StatusWidth }">外协接收</div>
            </th>
            <th>
              <div :style="{ width: StatusWidth }">外协报价</div>
            </th>
            <th>
              <div :style="{ width: StatusWidth }">确认</div>
            </th>
            <th>
              <div :style="{ width: StatusWidth }">外协完成</div>
            </th>
            <th>
              <div :style="{ width: StatusWidth }">到店确认</div>
            </th>
            <th>
              <div :style="{ width: StatusWidth }">是否修复</div>
            </th>
            <th>
              <div :style="{ width: StatusWidth }">检验完成</div>
            </th>
            <!-- <th>
              <div class="thoper" :style="{ width: operationWidth }">操作</div>
            </th> -->
            <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'head'" :class="{  ShowUpArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == 1, ShowDownArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == -1,}" @click.stop="ChangeSort(value1.fieldsNameForVali)">
              <div :style="{ width: value1.showLen  + 'rem' }">{{ value1.showName }}</div>
            </th>
          </tr>
        </table>
      </div>
      <div class="tablebodyarea" :style="{ width: tablewidth  + 'rem',height:tbodyHeight}">
        <table class="tablebody">
          <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'td' + index2" @mousedown="saveActiveItem(index2)" :class="{'bodytrActive':activeItem.index == index2}">
            <td>
              <div class="tdSN" :style="{ width: SnWidth }">{{ index2 + 1 + searchElement.LinesPerPage * (searchElement.Page - 1) }}</div>
            </td>
            <td>
              <div class="tdoper" :style="{ width: '100px' }">
                <el-dropdown size="small" split-button type="primary">
                  操作
                  <template #dropdown>
                    <el-dropdown-menu>
                      <div @click="GoBook(index2)" v-show="value2.repairFinishStatus == '未修复'">
                        <el-dropdown-item>去预定</el-dropdown-item>
                      </div>
                      <div v-show="value2.repairFinishStatus == '未修复'" @click="reCreateBarcode(index2)">
                        <el-dropdown-item>生成送修单</el-dropdown-item>
                      </div>
                      <div @click="RepairFinish(index2)" v-show="value2.repairFinishStatus == '未修复' && value2.staffId && !(value2.outSourceStatus == '外协维修' && value2.receiveAfterOutSource == '外协完成未到店' && +value2.outSourceIdFor < 20000000)">
                        <el-dropdown-item>确认修复</el-dropdown-item>
                      </div>
                      <div @click="InspectFinish(index2)" v-show="value2.repairFinishStatus != '未修复'&& value2.inspected != '已检验' && !(value2.outSourceStatus == '外协维修' && value2.receiveAfterOutSource == '外协完成未到店' && +value2.outSourceIdFor < 20000000)">
                        <el-dropdown-item>检验完成</el-dropdown-item>
                      </div>
                      <div @click="outSourceBack(index2)" v-show="value2.outSourceFinish != '外协待完成' && value2.receiveAfterOutSource == '外协完成未到店'">
                        <el-dropdown-item>确认到店</el-dropdown-item>
                      </div>
                      <div @click="noticeCustomer(index2)" v-show="value2.repairFinishStatus != '未修复' && !(value2.outSourceStatus == '外协维修' && value2.receiveAfterOutSource == '外协完成未到店' && +value2.outSourceIdFor < 20000000)">
                        <el-dropdown-item>通知客户</el-dropdown-item>
                      </div>
                      <div @click="goStaffRef(index2)" v-show="value2.staffId && value2.repairFinishStatus == '未修复'">
                        <el-dropdown-item>修改维修人</el-dropdown-item>
                      </div>
                      <div v-show="(value2.outSourceCompanyId && +value2.outSourceIdFor >= 20000000) || (value2.outSourceCompanyId && +value2.outSourceIdFor < 20000000 && value2.outSourceFinish == '外协完成')" @click="goOutSourceRef(index2,'change')">
                        <el-dropdown-item>修改外协单位</el-dropdown-item>
                      </div>
                      <div @click="openModifyPop(index2)">
                        <el-dropdown-item>编辑</el-dropdown-item>
                      </div>
                      <div @click="goRoList(value2)" v-show="value2.staffId && value2.inspected != '未检验' && !(value2.outSourceStatus == '外协维修' && value2.receiveAfterOutSource == '外协完成未到店' && +value2.outSourceIdFor < 20000000)">
                        <el-dropdown-item>去开单</el-dropdown-item>
                      </div>
                      <div @click="returnMachine(index2)">
                        <el-dropdown-item>退机</el-dropdown-item>
                      </div>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </td>
            <td>
              <div :style="{ width: '20rem' }" @mouseenter="showAppendRemark(index2)" @mouseleave="hideAppendRemark()">
                <el-tooltip placement="right-start" :show-after=300>
                  <template class="writeLine" #content><span class="writeLine">{{appendRemark.remark}}</span></template>
                  <el-button class="MachineShow" :style="{ width: '20rem' }"><span class="wordBreak">{{value2.partBCompanyId == '散客' ? '' : value2.partBCompanyId + '-'}}{{value2.machineType}}-{{value2.breakdownCause}}</span></el-button>
                </el-tooltip>
              </div>
            </td>
            <td>
              <div :style="{ width: StatusWidth }">
                {{value2.customerResponsePerson}}
              </div>
            </td>
            <td>
              <div :style="{ width: '8rem' }">
                {{value2.loginPassword}}
              </div>
            </td>
            <td>
              <div :style="{ width: StatusWidth }">
                <span v-show="value2.staffId">{{value2.staffId}}</span>
                <span v-show="!value2.staffId" class="spanfunc" @click="goStaffRef(index2)">委派</span>
              </div>
            </td>
            <td>
              <div :style="{ width: StatusWidth }">
                <span class="QuoShow" v-show="value2.draftQuotation">{{value2.draftQuotation}}元</span>
                <span class="spanfunc" v-show="!value2.draftQuotation" @click="openGoQuote(index2)">去报价</span>
              </div>
            </td>
            <td>
              <div :style="{ width: StatusWidth }">
                <span class="QuoShow" v-show="value2?.selfInfo?.documentId && value2.draftQuotation && value2?.selfInfo?.accepted">
                  <el-tooltip class="box-item" effect="dark" :content="value2?.selfInfo?.accRemark" placement="right">
                    <el-button>同意</el-button>
                  </el-tooltip>
                </span>
              </div>
            </td>

            <td>
              <div :style="{ width: ourSourceWidth }">
                <span class="outShow" v-show="value2.outSourceCompanyId">{{value2.outSourceCompanyId}}</span>
                <span v-show="!value2.outSourceCompanyId && value2.repairFinishStatus != '已修复'" class="spanfunc" @click="goOutSourceRef(index2,'add')">去外协</span>
              </div>
            </td>

            <td>
              <div :style="{ width: StatusWidth }">
                <span class="progress" v-show="value2.machineAccepted == '机器已接收'"></span>
                <span v-show="value2.machineAccepted != '机器已接收'" @click="receiveMachine(index2)" class="spanfunc">接单</span>
              </div>
            </td>
            <td>
              <div :style="{ width: StatusWidth }">
                <span class="progress" v-show="value2.outSourceStatus != '店内维修'"></span>
                <span class="progressno" v-show="value2.outSourceStatus == '店内维修'"></span>
              </div>
            </td>
            <td>
              <div :style="{ width: StatusWidth }">
                <span class="progress" v-if="value2.outSourceReceive != '未接收'"></span>
                <span class="progressno" v-if="value2.outSourceReceive == '未接收'"></span>
              </div>
            </td>
            <td>
              <div :style="{ width: StatusWidth }">
                <span v-show="value2.outSourceCompanyId && value2?.outSourceInfo?.quoUnitPrice">
                  <el-tooltip class="box-item" effect="dark" :content="value2?.outSourceInfo?.quoRemark" placement="left">
                    <el-button>{{value2?.outSourceInfo?.quoUnitPrice}}元</el-button>
                  </el-tooltip>

                </span>
              </div>
            </td>
            <td>
              <div :style="{ width: StatusWidth }">
                <span class="progress" v-show="value2.outSourceCompanyId && value2?.outSourceInfo?.quoUnitPrice && value2?.outSourceInfo?.accepted"></span>
                <span class="spanfunc" v-show="value2.outSourceCompanyId && value2?.outSourceInfo?.quoUnitPrice && !value2?.outSourceInfo?.accepted" @click="openConfirmQuotationRef(index2)">确认</span>
                <span v-show="value2.outSourceCompanyId && !value2.quoUnitPrice"></span>
                <span v-show="!value2.outSourceCompanyId"></span>
              </div>
            </td>

            <td>
              <div :style="{ width: StatusWidth }">
                <span class="progress" v-show="value2.outSourceFinish != '外协待完成'"></span>
                <span class="progressno" v-show="value2.outSourceFinish == '外协待完成'"></span>
              </div>
            </td>
            <td>
              <div :style="{ width: StatusWidth }">
                <span class="progress" v-show="value2.receiveAfterOutSource != '外协完成未到店'"></span>
                <span class="progressno" v-show="value2.receiveAfterOutSource == '外协完成未到店'"></span>
              </div>
            </td>
            <td>
              <div :style="{ width: StatusWidth }">
                <span class="progress" v-show="value2.repairFinishStatus != '未修复'"></span>
                <span class="progressno" v-show="value2.repairFinishStatus == '未修复'" :class="{redWord:value2.repairFinishStatus == '未修复' && value2.outSourceFinish == '外协完成'}"></span>
              </div>
            </td>

            <td>
              <div :style="{ width: StatusWidth }">
                <span class="progress" v-show="value2.inspected != '未检验'"></span>
                <span class="progressno" v-show="value2.inspected == '未检验'"></span>
              </div>
            </td>
            <!-- <td>
              <div class="tdoper" :style="{ width: operationWidth }">
                <span class="spanedit" @click="GoBook(index2)" v-show="value2.repairFinishStatus == '未修复'">去预定</span>
                <span class="spanedit" v-show="value2.repairFinishStatus == '未修复'" @click="reCreateBarcode(index2)">生成送修单</span>
                <span class="spanedit" @click="RepairFinish(index2)" v-show="value2.repairFinishStatus == '未修复' && value2.staffId && !(value2.outSourceStatus == '外协维修' && value2.receiveAfterOutSource == '外协完成未到店' && +value2.outSourceIdFor < 20000000)">确认修复</span>
                <span class="spanedit" @click="InspectFinish(index2)" v-show="value2.repairFinishStatus != '未修复'&& value2.inspected != '已检验' && !(value2.outSourceStatus == '外协维修' && value2.receiveAfterOutSource == '外协完成未到店' && +value2.outSourceIdFor < 20000000)">检验完成</span>
                <span class="spanedit" @click="outSourceBack(index2)" v-show="value2.outSourceFinish != '外协待完成' && value2.receiveAfterOutSource == '外协完成未到店'">确认到店</span>
                <span class="spanedit" @click="noticeCustomer(index2)" v-show="value2.repairFinishStatus != '未修复' && !(value2.outSourceStatus == '外协维修' && value2.receiveAfterOutSource == '外协完成未到店' && +value2.outSourceIdFor < 20000000)">通知客户</span>
                <span class="spanedit" @click="goStaffRef(index2)" v-show="value2.staffId && value2.repairFinishStatus == '未修复'">修改维修人</span>
                <span class="spanedit" v-show="(value2.outSourceCompanyId && +value2.outSourceIdFor >= 20000000) || (value2.outSourceCompanyId && +value2.outSourceIdFor < 20000000 && value2.outSourceFinish == '外协完成')" @click="goOutSourceRef(index2,'change')">修改外协单位</span>
                <span class="spanedit" @click="openModifyPop(index2)">编辑</span>
                <span class="spanedit" @click="goRoList(value2)" v-show="value2.staffId && value2.inspected != '未检验' && !(value2.outSourceStatus == '外协维修' && value2.receiveAfterOutSource == '外协完成未到店' && +value2.outSourceIdFor < 20000000)">去开单</span>
                <span class="spandele" @click="returnMachine(index2)">退机</span>
              </div>
            </td> -->
            <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
              <div :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="MainBodyTablePage" :style="{ width: '100%'}">
      <!--页数模块 -->
      <div class="PageCountContainer">
        <div class="PagerSummaryText">({{ totalLines }}条记录,总共{{ totalPage }}页)</div>
        <span class="PagerEveryPageText">每页/条</span>
        <div class="EditBlock">
          <input class="LinesPerPageChooese" type="text" v-model="searchElement.LinesPerPage" />
          <div class="LinesPerPageChooseButton"></div>
        </div>
        <div class="GoFirstPageButton" @click="FirstPage()"></div>
        <div class="GoLastPageButton" @click="DownPage()"></div>
        <span class="pagetextforDi">第</span>
        <div class="EditBlock">
          <input class="PageEdit" type="text" v-model="searchElement.Page" />
          <div class="PageChooseButton"></div>
        </div>
        <span class="pagetextforYe">页</span>
        <div class="GoNextPageButton" @click="UpPage()"></div>
        <div class="GoEndPageButton" @click="EndPage()"></div>
      </div>
    </div>
    <div class="moreFunctionBox">
      <el-button class="firstButton" type="primary" @click="goRepariHistory()">查看历史</el-button>
    </div>
    <PopModel :title="'选择维修人员'" ref="repairPersonRef">
      <div class="inputBox">
        <label class="popLable" for="repairPersonRepairRecordList">维修人员:</label>
        <el-select v-model="repairPerson.staffId" class="m-2 popInput" placeholder="请选择维修人员" id="repairPersonRepairRecordList">
          <el-option v-for="item in staffFilter" :key="item.beforeTransfer + 'salesMacToBeSo'" :label="item.afterTransfer" :value="item.beforeTransfer">
          </el-option>
        </el-select>
      </div>
      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight" @click="closeRepairPersonRef()">取消</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="setRepairPerson()">确认委派</el-button>
      </div>
    </PopModel>

    <PopModel :title="'选择外协单位'" ref="outSourceRef">
      <div class="inputBox">
        <label class="popLable" for="outSourceCompanyIdRepairRecordList">外协单位:</label>
        <el-select v-model="repairPerson.outSourceCompanyId" class="m-2 popInput" placeholder="请选择外协单位" id="outSourceCompanyIdRepairRecordList">
          <el-option key="'-1RepairRecordOutSource'" label="" value="" disabled><input type="text" @input="searchForCompany" class="optionInput" :style="[{width:'10rem'},{height:'1.8rem'}]"></el-option>
          <el-option v-for="item in outSourceFilterCopy" :key="item.beforeTransfer + 'RepairRecordOutSource'" :label="item.afterTransfer" :value="item.beforeTransfer"></el-option>
        </el-select>
      </div>

      <div class="inputBox">
        <label class="popLable" for="remarkForOutSourceRepairRecord">外协备注:</label>
        <el-input type="text" v-model="repairPerson.outSourceRemark" class="popInput" placeholder="请输入备注" id="remarkForOutSourceRepairRecord" />
      </div>

      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight" @click="closeOutSourcePop()">取消</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" v-show="repairPerson.typeChoose == 'add'" @click="setOutSource()">确认外协</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" v-show="repairPerson.typeChoose == 'change'" @click="changeOutSource()">修改外协单位</el-button>
      </div>
    </PopModel>

    <PopModel :title="'填写/修改报价'" ref="quotationRef">
      <div class="inputBox">
        <label class="popLable">报价:</label>
        <el-input type="number" v-model="customer.quotePrice" class="popInput" placeholder="请输入价格" />
      </div>

      <div class="inputBox">
        <label class="popLable">备注:</label>
        <el-input type="text" v-model="customer.quoteRemark" class="popInput" placeholder="请输入备注" />
      </div>

      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight" @click="closeQuoPop()">取消</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="goQuote()">确认报价</el-button>
      </div>
    </PopModel>

    <PopModel :title="'报价确认'" ref="confirmQuotationRef">
      <div class="inputBox">
        <label class="popLable">备注:</label>
        <el-input type="text" v-model="supplier.confirmQuoRemark" class="popInput" placeholder="请输入备注" />
      </div>

      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight" @click="closeConfirmQuotationRef()">取消</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="confirmQuotation()">确认</el-button>
      </div>
    </PopModel>

    <PopModel :title="'单据修改'" ref="modifyRef">
      <div class="inputBox">
        <label class="popLable">机型名称:</label>
        <el-input type="text" class="popInput" v-model="changeData.machineTpye" />
      </div>

      <div class="inputBox">
        <label class="popLable">故障说明:</label>
        <el-input type="text" class="popInput" v-model="changeData.breakCause" />
      </div>

      <div class="inputBox">
        <label class="popLable">开机密码:</label>
        <el-input type="text" class="popInput" v-model="changeData.password" />
      </div>

      <div class="inputBox">
        <label class="popLable">补充说明:</label>
        <el-input type="text" class="popInput" v-model="changeData.appendRemark" placeholder="每次输入都会被记录,用于标记进度" />
      </div>

      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight" @click="closeModifyPop()">取消</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="goModifyRecord()">确认修改</el-button>
      </div>
    </PopModel>

  </div>
</template>

<script>
import { computed, inject, onMounted, onUnmounted, provide, reactive, ref, watch } from 'vue-demi'
import { useStore } from "vuex";
import { copyObj, ChangeFirToUp, dataFilter, changeArrContentToDate, copyArray, getDateTime, getUUId } from '@/hooks/tools';
import requestData from '@/hooks/requestData';
import {
  repairUpdateStaff, transferToCode, updateOutSourceCompany, deleteRepairRecord, confirmQuotationReal, AlterRepairRecordOnTheWay,
  repairOk, inspectOk, outSourceReceive, outSourceBackConfirm, sendNoticeCustomer, changeToViewCode, initializeOutSourceAppendInfo, addQuotationForFrList
} from '@/hooks/OpenOrder'
import PopModel from '@/components/Common/PopModel.vue'
import TableShowHook from '@/hooks/TableShowHook'
import { ElMessage } from 'element-plus'
import _ from "lodash";
export default {
  components: {
    PopModel
  },
  setup(props, { emit }) {
    //#region 数据定义区
    let { store, tableHead, tableBody, SearchContent, tableBodyElement,
      totalLines, totalPage, searchElement, tableCode,
      AddEditRef, UploadRef,
      fillSearchContent, refreshTableHeadandregArr, clearTableHeadandregArr,
      clearVueXPage, initializeVueXPageDate, searchList,
      turnPageToOne, UpPage, EndPage, DownPage, FirstPage, ChangeSort } = TableShowHook(emit)

    let SnWidth = ref('3.75rem')
    let operationWidth = ref('26rem')
    let trHeight = ref('1.875rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')
    let StatusWidth = ref('4.8rem')
    let ourSourceWidth = ref('10rem')

    let tablewidth = computed(() => {
      return (
        parseFloat(StatusWidth.value) * 9 +
        parseFloat(ourSourceWidth.value) +
        parseFloat(SnWidth.value) +
        parseFloat(operationWidth.value) +
        1.875 + tableHead.length * 1 + 28.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });
    //#region 数据定义区

    let activeItem = inject('activeItem')
    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (tableCode == store.state.loginInitializeData.ShowWindow[0]) {
          initializeVueXPageDate()
          fillSearchContent()
          searchList()
        }
      },
      {
        immediate: false
      }
    )


    //#region 离开时处理
    onUnmounted(() => {

    })
    //#endregion
    const saveActiveItem = (index) => {
      emit('saveActiveItem', tableBody[index], index)
    }

    watch(
      () => [searchElement.Page, searchElement.SortFieldsName, searchElement.SortDirection],
      (newValue, oldValue) => {
        if (tableCode == store.state.loginInitializeData.ShowWindow[0]) {
          if (totalPage.value > 0) {
            if (parseInt(newValue[0]) > totalPage.value) {
              // ElMessage('不能超过最大页数');
              store.commit('modifysearchEle', { keyName: 'Page', valueName: totalPage.value })
              return;
            }
          }
          if (parseInt(newValue[0]) < 1) {
            // ElMessage('页数不能小于1');
            store.commit('modifysearchEle', { keyName: 'Page', valueName: 1 })
            return;
          }
        }
      }
    );
    //w用于监控页数，确保客户不乱填
    watch(
      () => searchElement.LinesPerPage,
      (newValue, oldValue) => {
        if (tableCode == store.state.loginInitializeData.ShowWindow[0]) {
          if (newValue > 100) {
            store.commit('modifysearchEle', { keyName: 'LinesPerPage', valueName: 100 })
            ElMessage("每页数量不能超过100");
          }
          if (newValue < 1) {
            store.commit('modifysearchEle', { keyName: 'LinesPerPage', valueName: 1 })
            ElMessage("每页数量不能低于1");
          }
        }
      }
    );

    //#endregion

    const repairPersonRef = ref(null)

    const outSourceRef = ref(null)

    const staffFilter = copyArray(store.state.loginInitializeData.filterList.StaffId)

    const length1 = staffFilter.length
    const userName = store.state.loginInitializeData.userName
    if (userName != '管理员') {
      for (let i = length1 - 1; i >= 0; i--) {
        if (staffFilter[i].afterTransfer == '管理员') {
          staffFilter.splice(i)
        }
      }
    }

    const outSourceFilter = store.state.loginInitializeData.filterList.OutSourceCompanyId


    let outSourceFilterCopy = reactive(copyArray(outSourceFilter))

    const sortOutList = () => {
      let length1 = outSourceFilterCopy.length
      for (let i = length1 - 1; i >= 0; i--) {
        if (outSourceFilterCopy[i]?.afterTransfer == '散客' || outSourceFilterCopy[i]?.afterTransfer == '其他' || outSourceFilterCopy[i]?.afterTransfer == '员工' || outSourceFilterCopy[i]?.afterTransfer.indexOf('平台-') > -1) {
          outSourceFilterCopy.splice(i, 1)
        }
      }
    }
    sortOutList()

    const repairPerson = reactive({
      activeIndex: -1,
      staffId: '',
      outSourceCompanyId: '',
      outSourceRemark: '',
      typeChoose: ''
    })

    const closeRepairPersonRef = () => {
      repairPerson.staffId = ''
      repairPerson.activeIndex = -1
      repairPersonRef.value.closePopComm()
    }
    const closeOutSourcePop = () => {
      repairPerson.outSourceCompanyId = ''
      repairPerson.outSourceRemark = ''
      repairPerson.activeIndex = -1
      outSourceRef.value.closePopComm()
    }


    const goStaffRef = (index) => {
      repairPerson.activeIndex = index
      repairPersonRef.value.openPopComm()
    }



    const goOutSourceRef = (index, typeChoose) => {
      repairPerson.activeIndex = index
      repairPerson.typeChoose = typeChoose
      outSourceRef.value.openPopComm()
    }


    const setRepairPerson = () => {
      if (!repairPerson.staffId) {
        ElMessage('未选择维修人员')
        return;
      }
      const body = tableBody[repairPerson.activeIndex]
      repairUpdateStaff(body.repairRecordCode, repairPerson.staffId).then(res => {
        if (res != 'pass') {
          ElMessage('委派失败')
          return;
        }
        else {
          closeRepairPersonRef()
          outSourceReceive(body.repairRecordCode).then(res => {
            searchList()
          })
        }
      })
    }

    const setOutSource = () => {
      if (!repairPerson.outSourceCompanyId) {
        ElMessage('未选择外协单位')
        return;
      }
      const body = tableBody[repairPerson.activeIndex]

      if (!body.staffId) {
        ElMessage('外协前需要确认维修人员')
        return;
      }
      updateOutSourceCompany(body.documentId, repairPerson.outSourceCompanyId, repairPerson.outSourceRemark).then(res => {
        if (res != 'pass') {
          ElMessage('外协失败')
          return;
        }
        else {
          initializeOutSourceAppendInfo(body.documentId, 1, repairPerson.outSourceCompanyId)

          const storage = window.localStorage;
          const user = store.state.loginInitializeData.userId
          const timeNow = getDateTime()
          let storeListFromWin = []
          if (JSON.parse(storage.getItem('BarStoreList' + user))) {
            storeListFromWin = JSON.parse(storage.getItem('BarStoreList' + user));
          }
          const newId2 = getUUId()
          let addPrint2 = {
            id: 'store' + newId2,
            machineName: body.machineType,
            docType: '机器送修单',
            companyName: store.state.loginInitializeData.companyNameAbbre,
            docDate: timeNow,
            breakdownCause: body.breakdownCause,
            remark: `开机密码:${body.loginPassword}`,
            barcode: body.documentId,
          }
          storeListFromWin.unshift(addPrint2)
          storage.setItem('BarStoreList' + user, JSON.stringify(storeListFromWin))
          closeOutSourcePop()
          searchList()
        }
      })
    }


    const changeOutSource = () => {
      if (!repairPerson.outSourceCompanyId) {
        ElMessage('未选择外协单位')
        return;
      }
      const body = tableBody[repairPerson.activeIndex]

      if (!body.staffId) {
        ElMessage('外协前需要确认维修人员')
        return;
      }
      if (!body.outSourceCompanyId) {
        ElMessage('无外协供应商，无法修改')
        return;
      }
      if (repairPerson.outSourceCompanyId == body.outSourceIdFor) {
        ElMessage('修改外协供应商和原来一样，无需修改')
        return;
      }
      updateOutSourceCompany(body.documentId, repairPerson.outSourceCompanyId, repairPerson.outSourceRemark).then(res => {
        if (res != 'pass') {
          ElMessage('外协失败')
          return;
        }
        else {
          initializeOutSourceAppendInfo(body.documentId, 1, repairPerson.outSourceCompanyId)
          const storage = window.localStorage;
          const user = store.state.loginInitializeData.userId
          const timeNow = getDateTime()
          let storeListFromWin = []
          if (JSON.parse(storage.getItem('BarStoreList' + user))) {
            storeListFromWin = JSON.parse(storage.getItem('BarStoreList' + user));
          }
          const newId2 = getUUId()
          let addPrint2 = {
            id: 'store' + newId2,
            machineName: body.machineType,
            docType: '机器送修单',
            companyName: store.state.loginInitializeData.companyNameAbbre,
            docDate: timeNow,
            breakdownCause: body.breakdownCause,
            remark: `开机密码:${body.loginPassword}`,
            barcode: body.documentId,
          }
          storeListFromWin.unshift(addPrint2)
          storage.setItem('BarStoreList' + user, JSON.stringify(storeListFromWin))
          closeOutSourcePop()
          searchList()
        }
      })
    }

    const reCreateBarcode = (index) => {
      const body = tableBody[index]

      const storage = window.localStorage;
      const user = store.state.loginInitializeData.userId
      let storeListFromWin = []
      if (JSON.parse(storage.getItem('BarStoreList' + user))) {
        storeListFromWin = JSON.parse(storage.getItem('BarStoreList' + user));
      }
      const newId2 = getUUId()

      let addPrint2 = {
        id: 'store' + newId2,
        machineName: body.machineType,
        docType: '机器送修单',
        companyName: store.state.loginInitializeData.companyNameAbbre,
        docDate: body.orderAcceptedDate,
        breakdownCause: body.breakdownCause,
        remark: `开机密码:${body.loginPassword}`,
        barcode: body.documentId,
      }
      storeListFromWin.unshift(addPrint2)
      storage.setItem('BarStoreList' + user, JSON.stringify(storeListFromWin))
      store.commit('updateBarcodeCount')
    }

    const returnMachine = (index) => {

      const body = tableBody[index]

      let b = confirm('确认退机，退机后无法恢复')
      if (!b) {

        return false;
      }

      deleteRepairRecord(body.repairRecordCode).then(res => {
        if (res != 'pass') {
          ElMessage(res)
        }
        else {
          searchList()
          emit('refreshMsg')
        }

      }).catch(err => {

        ElMessage(err.message)
      })
    }

    const RepairFinish = (index) => {

      const body = tableBody[index]
      if (body.outSourceStatus == '外协维修' &&
        body.receiveAfterOutSource == '外协完成未到店' &&
        +body.outSourceIdFor < 20000000) {

        ElMessage('机器正在外协中，无法确认')
        return
      }
      if (!body.staffId) {

        ElMessage('请先确认维修人员')
        return
      }
      let b = confirm('确认修理完成')
      if (!b) {

        return false;
      }
      repairOk(body.repairRecordCode).then(res => {

        searchList()
        emit('refreshMsg')
      }).catch(err => {

        ElMessage(err.message)
      })
    }

    const InspectFinish = (index) => {

      const body = tableBody[index]
      if (body.outSourceStatus == '外协维修' &&
        body.receiveAfterOutSource == '外协完成未到店' &&
        +body.outSourceIdFor < 20000000) {

        ElMessage('机器正在外协中，无法确认')
        return
      }
      if (!body.staffId) {

        ElMessage('请先确认维修人员')
        return
      }
      if (body.repairFinishStatus != '已修复') {

        ElMessage('请先确认维修完成')
        return
      }
      let b = confirm('确认检验完成')
      if (!b) {

        return false;
      }
      inspectOk(body.repairRecordCode).then(res => {

        searchList()
        emit('refreshMsg')
      }).catch(err => {

        ElMessage(err.message)
      })
    }

    const receiveMachine = (index) => {

      const body = tableBody[index]
      let b = confirm('确认收到机器')
      if (!b) {

        return false;
      }
      outSourceReceive(body.repairRecordCode).then(res => {
        searchList()
        emit('refreshMsg')
      }).catch(err => {

        ElMessage(err.message)
      })
    }

    const outSourceBack = (index) => {

      const body = tableBody[index]
      if (body.outSourceFinish != '外协完成') {

        ElMessage('需要对方完成才可以收货')
        return
      }

      let b = confirm('确认收货')
      if (!b) {

        return false;
      }
      outSourceBackConfirm(body.repairRecordCode).then(res => {

        searchList()
        emit('refreshMsg')
      }).catch(err => {

        ElMessage(err.message)
      })
    }

    const noticeCustomer = (index) => {

      const body = tableBody[index]
      let b = confirm('确认通知客户')
      if (!b) {

        return false;
      }
      sendNoticeCustomer(body.repairRecordCode).then(res => {
        if (res != 'pass') {

          ElMessage(res)
        }

        searchList()
        emit('refreshMsg')
      }).catch(err => {

        ElMessage(err.message)
      })
    }

    const goRoList = (value) => {
      emit('goRoList', value)
    }

    const GoBook = (index) => {
      const body = tableBody[index]
      emit('goObListFromRepairRecord', body)
    }

    const goRepariHistory = (value) => {
      let code = changeToViewCode('FrList')
      store.commit('GoFunctionWindowWithCode', code)
    }

    const quotationRef = ref(null)

    let customer = reactive({
      quotePrice: '',
      quoteRemark: '',
      activeQuoIndex: ''
    })

    const openGoQuote = (index) => {
      customer.activeQuoIndex = index
      quotationRef.value.openPopComm()
    }

    const closeQuoPop = () => {
      customer.activeQuoIndex = ''
      customer.quotePrice = ''
      customer.quoteRemark = ''
      quotationRef.value.closePopComm()
    }

    const goQuote = () => {
      if (!customer.quotePrice || customer.quotePrice < 0) {
        ElMessage('报价不存在或者小于0')
        return;
      }
      const body = tableBody[customer.activeQuoIndex]
      addQuotationForFrList(body.repairRecordCode, customer.quotePrice || 0, customer.quoteRemark).then(res => {
        if (res != 'pass') {
          ElMessage(res)
        }
        else {
          closeQuoPop()
          outSourceReceive(body.repairRecordCode).then(res => {
            searchList()
          })
        }
      })
    }

    const confirmQuotationRef = ref(null)

    let supplier = reactive({
      confirmQuoRemark: '',
      activeConfirmQuotationIndex: ''
    })

    const closeConfirmQuotationRef = () => {
      supplier.confirmQuoRemark = ''
      supplier.activeConfirmQuotationIndex = ''
      confirmQuotationRef.value.closePopComm();
    }

    const confirmQuotation = () => {
      // if (!customer.quotePrice || customer.quotePrice < 0) {
      //   ElMessage('报价不存在或者小于0')
      //   return;
      // }
      const body = tableBody[supplier.activeConfirmQuotationIndex]
      if (!body?.outSourceInfo?.quoUnitPrice) {
        ElMessage('无需确认')
        return;
      }

      confirmQuotationReal(body.repairRecordCode, supplier.confirmQuoRemark).then(res => {
        if (res != 'pass') {
          ElMessage(res)
        }
        else {
          closeConfirmQuotationRef()
          searchList()
        }
      })
    }

    const openConfirmQuotationRef = (index) => {
      supplier.activeConfirmQuotationIndex = index
      confirmQuotationRef.value.openPopComm();
    }

    const modifyRef = ref(null)


    const openModifyPop = (index) => {
      changeData.indexRecord = tableBody[index].repairRecordCode
      changeData.machineTpye = tableBody[index].machineType
      changeData.breakCause = tableBody[index].breakdownCause
      changeData.password = tableBody[index].loginPassword
      changeData.appendRemark = ''
      modifyRef.value.openPopComm();
    }

    let changeData = reactive({
      indexRecord: -1,
      machineTpye: '',
      breakCause: '',
      password: '',
      appendRemark: ''
    })

    const closeModifyPop = () => {
      modifyRef.value.closePopComm();
    }

    const goModifyRecord = () => {
      AlterRepairRecordOnTheWay(changeData).then(res => {
        if (res == 'pass') {
          ElMessage('更新成功')
          searchList()
          modifyRef.value.closePopComm();
        }
        else {
          ElMessage(res)
        }
      })
    }

    let appendRemark = reactive({
      index: -1,
      remark: ''
    })

    let checkAppendId;

    const showAppendRemark = (index) => {
      appendRemark.index = -1
      appendRemark.remark = ''
      if (checkAppendId) {
        clearTimeout(checkAppendId)
      }
      checkAppendId = setTimeout(() => {
        const config = {
          method: "get",
          url: "/RepairRecordList/GetAppendRemark",
          params: {
            repairCode: tableBody[index].repairRecordCode
          }
        };
        requestData.GetCache(config).then(res => {
          appendRemark.index = index
          if (res) {
            const arrA = res.split('|')
            for (let i = 0; i < arrA.length; i++) {
              if (i == 0) {
                appendRemark.remark += ('1.' + arrA[i])
              }
              else {
                appendRemark.remark += ('\n' + `${i + 1}.` + arrA[i])
              }
            }
          }
          else {
            appendRemark.remark = ''
          }
        })
      }, 300);
    }

    const hideAppendRemark = () => {
      appendRemark.index = -1
      appendRemark.remark = ''
    }

    const searchForCompany = _.debounce((e) => {
      const outSourceFilterCopy1 = copyArray(outSourceFilter.filter(x => x.afterTransfer.indexOf(e.target.value) > -1))
      let length1 = outSourceFilterCopy1.length
      for (let i = length1 - 1; i >= 0; i--) {
        if (outSourceFilterCopy1[i]?.afterTransfer == '散客' || outSourceFilterCopy1[i]?.afterTransfer == '其他' || outSourceFilterCopy1[i]?.afterTransfer == '员工' || outSourceFilterCopy1[i]?.afterTransfer.indexOf('平台-') > -1) {
          outSourceFilterCopy1.splice(i, 1)
        }
      }
      outSourceFilterCopy.splice(0)
      for (let item of outSourceFilterCopy1) {
        outSourceFilterCopy.push(item)
      }
    }, 500)


    onMounted(() => {
      refreshTableHeadandregArr()
      initializeVueXPageDate()
      fillSearchContent()
    })
    onUnmounted(() => {
      clearTableHeadandregArr()
      clearVueXPage()
    })
    return {
      modifyRef, openModifyPop, changeData, closeModifyPop, goModifyRecord,
      SearchContent, reCreateBarcode,
      repairPerson, staffFilter, closeRepairPersonRef, repairPersonRef, goStaffRef,
      fillSearchContent, setRepairPerson, ourSourceWidth, goOutSourceRef,
      tableHead, outSourceRef, outSourceFilterCopy, closeOutSourcePop, setOutSource,
      tableBody, returnMachine, RepairFinish, InspectFinish, receiveMachine,
      tablewidth, outSourceBack, noticeCustomer, goRoList, GoBook, openGoQuote,
      searchList, changeOutSource, customer, quotationRef, openConfirmQuotationRef,
      totalLines, goRepariHistory, closeQuoPop, goQuote, showAppendRemark, hideAppendRemark,
      totalPage, confirmQuotationRef, supplier, closeConfirmQuotationRef, confirmQuotation,
      ChangeSort, appendRemark, searchForCompany,
      UpPage,
      EndPage,
      FirstPage,
      DownPage,
      turnPageToOne,
      searchElement,
      //尺寸部分
      SnWidth,
      operationWidth,
      trHeight,
      tbodyHeight,
      AddEditRef,
      UploadRef,
      saveActiveItem,
      activeItem,

      StatusWidth
    }
  }
}
</script>

<style scoped lang="less">
.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 60%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.thDiv {
  height: 100%;
}

.thSN {
  font-family: "icomoon";
  color: #fff;
  font-size: 16px;
}
.headtd {
  cursor: pointer;
}
.ShowUpArrow {
  position: relative;
}
.ShowUpArrow::after {
  content: "\ea32";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
  color: #fff;
}
.ShowDownArrow {
  position: relative;
}
.ShowDownArrow::after {
  content: "\ea36";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.tdSN,
.tableselect,
.tdoper,
.footsum,
.bodytr {
  text-align: center;
}
.bodytr {
  height: 2rem;
}

.tablefoot {
  margin-top: -1px;
}

.bodytrActive {
  background-color: #fefae3 !important;
}

.progress {
  font-family: "icomoon";
  color: #5bc648;
  font-size: 16px;
}

.progressno {
  font-family: "icomoon";
  color: #a6a9ad;
  font-size: 16px;
}

.popLable {
  margin-top: 0.5rem;
  line-height: 2.5rem;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 0.5rem;
}
.buttonBox {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  justify-content: flex-end;
}
.mRight {
  margin-top: 0.1rem;
}
.popInput {
  margin-top: 0.5rem;
  width: 28rem;
}
.inputBox {
  display: flex;
}
.redWord {
  color: #ff0000 !important;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}
.firstButton {
  margin-left: 1rem;
}

.box-item {
  background-color: transparent;
  border: none;
  width: 4.7rem;
  text-indent: -1.2rem;
}

.MachineShow {
  border: none;
  background-color: transparent;
}

.wordBreak {
  display: block;
  overflow: hidden;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.writeLine {
  white-space: pre-wrap;
}
.thHeadFlex {
  display: flex;
}
.outShow {
  padding-left: 0.3rem;
}
</style>