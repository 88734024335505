<template>
  <div class="cloak" v-if="CommPop">
    <div class="popWindow" :style="[{ top: popWindowTopComm }, { left: popWindowLeftComm }]">
      <div class="popWindowHead">
        <div class="popWindowHeadleft" @mousedown="startMoveComm" @mouseup="stopMoveComm">{{tableTitle}}
        </div>
        <div class="popWindowHeadright"></div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, onUnmounted } from 'vue-demi'
import Pop from '@/hooks/Pop';
export default {
  props: ['tableTitle'],
  setup(props, { emit }) {
    let tableTitle = inject('tableTitle')
    if (props.title) {
      tableTitle = props.title
    }

    let {
      popWindowTopComm,
      popWindowLeftComm,
      CommPop,
      closePopComm,
      openPopComm,
      startMoveComm,
      stopMoveComm,
    } = Pop();


    onMounted(() => {

    })
    onUnmounted(() => {

    })
    return {
      popWindowTopComm,
      popWindowLeftComm,
      CommPop,
      closePopComm,
      openPopComm,
      startMoveComm,
      stopMoveComm,
      tableTitle,
    }
  }
}
</script>

<style scoped lang="less">
@headHeigth: 3rem;
@headLeft: 35rem;
@headRight: 3.125rem;
.cloak {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
.popWindowHead {
  height: @headHeigth;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
}
.popWindowHeadleft {
  width: @headLeft;
  line-height: @headHeigth;
  text-indent: 2.5rem;
  font-weight: 700;
  font-size: 1rem;
  cursor: move;
}
.popWindowHeadright {
  line-height: @headHeigth;
  width: @headRight;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
}

.popWindow {
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
}
</style>