<template>
  <div class="tableLoaderContainer">

    <div class="BodyTitle">
      <div class="BodyTitleText">零件出售管理</div>
    </div>

    <div class="searchContentContainer">
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="StartTypeForMaterialSaleList">发起类型:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'StartType'}"><select v-model="SearchContent.StartType" class="searchinput" id="StartTypeForMaterialSaleList" @focus="setActiveId('StartType')" @blur="setActiveId('')" @change="reSearch()">
            <option value="">所有</option>
            <option value="101">零件出售</option>
          </select></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="SponserToBeSolveFSForMeterialSaleList">处理情况:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'SponserToBeSolveFS'}"><select v-model="SearchContent.SponserToBeSolveFS" class="searchinput" id="SponserToBeSolveFSForMeterialSaleList" @focus="setActiveId('SponserToBeSolveFS')" @blur="setActiveId('')" @change="reSearch()">
            <option v-for="item in SponserToBeSolveFSFilter" :key="item.beforeTransfer + 'MeterialSaleList'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
          </select></div>
      </div>

      <div class="searchItemContainer">
        <label class="searchinputLabel" for="PartACompanyIdForPurchaseOnTheWay">采购方:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'PartACompanyId'}"><select v-model="SearchContent.PartACompanyId" class="searchinput" id="PartACompanyIdForMeterialSaleList" @focus="setActiveId('PartACompanyId')" @blur="setActiveId('')" @change="reSearch()">
            <option value="" selected="selected">所有</option>
            <option v-for="item in partBFilterCopy" :key="item.beforeTransfer + 'PurchaseOnTheWay1'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
          </select></div>
      </div>

      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DocumentDateLMeterialSaleList">发起日期</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateL'}"><input v-model="SearchContent.DocumentDateL" class="searchinput" id="DocumentDateLMeterialSaleList" type="date" @focus="setActiveId('DocumentDateL')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DocumentDateHMeterialSaleList" v-text="'---'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateH'}"><input v-model="SearchContent.DocumentDateH" class="searchinput" id="DocumentDateHMeterialSaleList" type="date" @focus="setActiveId('DocumentDateH')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="CommodityNameMeterialSaleList">商品名称:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'CommodityName'}"><input v-model="SearchContent.CommodityName" class="searchinput long" id="CommodityNameMeterialSaleList" type="text" @focus="setActiveId('CommodityName')" @blur="setActiveId('')"></div>
      </div>

      <div class="searchItemContainer">
        <label class="searchinputLabel" for="CommodityIdMeterialSaleList">商品编码:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'CommodityId'}"><input v-model="SearchContent.CommodityId" class="searchinput" id="CommodityIdMeterialSaleList" type="text" @focus="setActiveId('CommodityId')" @blur="setActiveId('')"></div>
      </div>

      <!-- 搜索模块 -->
      <div class="FuncButton" @click="searchList()">搜索</div>
      <div class="tipBox">
        <div class="tipColortodo"></div>
        <div class="tipWords">待处理</div>
      </div>
      <div class="tipBox">
        <div class="tipColorfinish"></div>
        <div class="tipWords">无需处理</div>
      </div>
    </div>

    <div class="BigTable">
      <div class="TableContentArea">
        <table class="tablehead">
          <tr class="headtr" :style="{ height : trHeight }">
            <th class="thSN">
              <div :style="{ width: SnWidth }"></div>
            </th>
            <th class="thselectall">
              <div :style="{ width: selectAllWidth }"><input class="selectAllCheckBox" type="checkbox" v-model="selectAll" /></div>
            </th>
            <th>
              <div class="thoper" :style="{ width: operationWidth }">操作</div>
            </th>
            <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'head'" :class="{  ShowUpArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == 1, ShowDownArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == -1,}" @click.stop="ChangeSort(value1.fieldsNameForVali)">
              <div :style="{ width: value1.showLen  + 'rem' }">{{ value1.showName }}</div>
            </th>
          </tr>
        </table>
        <div class="tablebodyarea" :style="{ width: tablewidth  + 'rem',height:tbodyHeight}">
          <table class="tablebody">
            <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'td' + index2" :class="[{todoLine:value2.sponserToBeSolve},{nottodoLine:!value2.sponserToBeSolve}]">
              <td>
                <div class="tdSN" :style="{ width: SnWidth }">{{+index2 + 1}}</div>
              </td>
              <td class="tableselect">
                <div :style="{ width: selectAllWidth }"><input type="checkbox" :value="value2['salesOnTheWayCode']" v-model="selectedItems" /></div>
              </td>
              <td>
                <div class="tdoper" :style="{ width: operationWidth }">
                  <span class="spandele" v-show="value2.responsedQty" @click="confirmOne(index2)">确认</span>
                  <span class="spanedit" v-show="!value2.responsedQty" @click="deleteOne(index2)">取消</span>
                  <span class="spanedit" v-show="!value2.responsedQty && !value2.refusedQty" @click="rebuildBarcode(value2)">标签</span>
                </div>
              </td>
              <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
                <div :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
              </td>
            </tr>
          </table>
        </div>
        <table class="tablefoot">
          <tr class="foottr" :style="{ height : trHeight }">
            <td>
              <div class="footsum" :style="{ width: SnWidth }">合计</div>
            </td>
            <td>
              <div :style="{ width: selectAllWidth }"></div>
            </td>
            <td>
              <div :style="{ width: operationWidth }"></div>
            </td>
            <td v-for="value4 in tableHead" :key="value4.fieldsNameForVali + 'foot'">
              <div :style="{ width: value4.showLen  + 'rem' }"></div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="moreFunctionBox">
      <el-button class="firstButton" type="success" @click="selectAllCan()">勾选可确认</el-button>
      <el-button type="primary" @click="confirmMany()">批量确认账单</el-button>
      <el-button type="primary" @click="goHistory()">查看历史</el-button>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue-demi';
import { useStore } from "vuex";
import {
  ChangeFirToLo, ChangeFirToUp, ChangeYMD, changeArrContentToDate, copyArray, getUUId
} from '@/hooks/tools';
import requestData from '@/hooks/requestData';
import {
  fillDefaultValue, changeToViewCode, transferToCode, deleteSalesOnTheWay, openGdList, deleteSalesAcceptOnTheWay,
  getDocNo,
  deleteDocCenter,
  transferToName
} from '@/hooks/OpenOrder'
import { lockSalesOneTheWay, unLockSalesOneTheWay } from '@/hooks/LockAffair';
import { ElMessage } from 'element-plus'
export default {
  setup(props, { emit }) {
    //#region 定义区
    const store = useStore();

    const filterList = store.state.loginInitializeData.filterList

    const partBFilter = filterList.PartBCompanyId

    const partBFilterCopy = copyArray(partBFilter)

    const SponserToBeSolveFSFilter = filterList.SponserToBeSolveFS

    //初始化输入表名称
    const TableName = 'SalesOnTheWayList';

    const PageName = 'MeterialSaleList'

    const code = changeToViewCode(PageName)

    //#endregion

    //#region 全选
    let selectAll = ref(false);

    let selectedItems = ref([]);

    watch(selectAll, (newValue, oldValue) => {
      clearClick()
      if (code == store.state.loginInitializeData.ShowWindow[0]) {
        if (newValue == true) {
          selectedItems.value.splice(0);
          for (let item of tableBody) {
            selectedItems.value.push(item['salesOnTheWayCode']);
          }
        } else {
          selectedItems.value.splice(0);
        }
      }
    });
    //#endregion

    let selectAllCanClick = ref(false)


    const clearClick = () => {
      selectAllCanClick.value = false

    }

    const selectAllCan = () => {
      selectedItems.value.splice(0);
      if (selectAllCanClick.value == true) {
        clearClick()
        return;
      }
      else {
        clearClick()
        selectAllCanClick.value = true
      }
      for (let item of tableBody) {
        if (item.sponserToBeSolve) {
          selectedItems.value.push(item['salesOnTheWayCode']);
        }
      }
    }

    let activeInput = ref('');

    const setActiveId = (id) => {
      activeInput.value = id
    }
    let selectAllWidth = ref('2.5rem')
    let trHeight = ref('1.875rem')
    let SnWidth = ref('3.75rem')
    let operationWidth = ref('10rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')

    let SearchContent = reactive({
      SalesOnTheWayCode: '',
      DocumentId: '',
      Sn: '',
      PartACompanyId: '',
      PartBCompanyId: '',
      StaffId: '',
      DocumentDateH: '',
      DocumentDateL: '',
      DocumentType: '',
      CommodityName: '',
      CommodityId: '',
      Remark: '',
      CommodityNameBuy: '',
      CommodityIdBuy: '',
      OrderQty: '',
      UnitPrice: '',
      NotifyStatus: '',
      RefusedQty: '',
      ResponsedQty: '',
      DocumentTypeBuy: '',
      DocumentIdBuy: '',
      SnBuy: '',
      DocumentDateBuyL: '',
      DocumentDateBuyH: '',
      VirtualGoodsFS: '',
      ResponseStatus: '',
      DistributedOrNotFS: '',
      BuyerToBeSolveFS: '',
      SponserToBeSolveFS: '',
      StartType: '',
    })
    fillDefaultValue(SearchContent, TableName)

    const initializeSearch = () => {
      SearchContent.SponserToBeSolveFS = 1
    }

    initializeSearch()

    const rebuildBarcode = (value) => {
      const storage = window.localStorage;
      const user = store.state.loginInitializeData.userId
      let storeListFromWin = []
      const timeNow = value.documentDate
      if (JSON.parse(storage.getItem('BarStoreList' + user))) {
        storeListFromWin = JSON.parse(storage.getItem('BarStoreList' + user));
      }
      const newId2 = getUUId()
      let addPrint2 = {
        id: 'store' + newId2,
        partName: value.commodityName,
        docType: '零件出售单',
        companyName: transferToName(value.partBCompanyId, 'OwedCompanyId'),
        docDate: timeNow,
        remark: `${value.remark}`,
        barcode: value.documentId,
      }
      storeListFromWin.unshift(addPrint2)
      storage.setItem('BarStoreList' + user, JSON.stringify(storeListFromWin))
      store.commit('updateBarcodeCount')
    }

    //f计算表格内容总宽度
    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) + parseFloat(operationWidth.value) + parseFloat(selectAllWidth.value) + 1.25 + tableHead.length * 1 + 0.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });

    let tableHead = reactive([
      { fieldsNameForVali: 'startType', showLen: 5, showName: '发起类型' },
      { fieldsNameForVali: 'partACompanyId', showLen: 6.25, showName: '采购方' },
      { fieldsNameForVali: 'documentTypeBuy', showLen: 7.5, showName: '处理方式' },
      { fieldsNameForVali: 'commodityNameBuy', showLen: 28.125, showName: '采购商品名称' },
      { fieldsNameForVali: 'responsedQty', showLen: 6.25, showName: '采购数量' },
      { fieldsNameForVali: 'unitPrice', showLen: 6.25, showName: '采购价格' },
      { fieldsNameForVali: 'refusedQty', showLen: 7, showName: '对方拒绝数量' },
      { fieldsNameForVali: 'documentId', showLen: 12.5, showName: '单据编码' },
      { fieldsNameForVali: 'sn', showLen: 2.5, showName: '序' },
      { fieldsNameForVali: 'documentDate', showLen: 6.25, showName: '发起日期' },
      { fieldsNameForVali: 'commodityName', showLen: 25, showName: '出售商品名称' },
      { fieldsNameForVali: 'orderQty', showLen: 3.75, showName: '数量' },
      { fieldsNameForVali: 'remark', showLen: 20, showName: '备注' },
    ]);

    const searchElement = reactive({
      SortFieldsName: 'DocumentDate',
      SortDirection: 1
    })

    let tableBody = reactive([]);

    let ChangeSort = (fildsname) => {
      searchElement.SortFieldsName = ChangeFirToUp(fildsname)
      searchElement.SortDirection = -searchElement.SortDirection
      searchList()
    };

    const reSearch = () => {
      searchList()
    }

    let searchList = () => {

      let config = {
        method: "post",
        url: '/SalesOnTheWayList/GetListByMulSearchForSponser',
        data: Object.assign(SearchContent, searchElement),
      };
      requestData
        .GetData(config)
        .then((res) => {
          selectAll.value = false
          selectedItems.value.splice(0)
          tableBody.splice(0);
          tableBody.push.apply(tableBody, res['salesOnTheWayListMultiple']);
          changeArrContentToDate(tableBody, PageName)
          const filterList = store.state.loginInitializeData.filterList
          const companyFilter = filterList.PartBCompanyId
          const startTypFilter = filterList.StartType
          for (let item of tableBody) {
            for (let key in item) {
              if (key == 'partACompanyId') {
                item[key] = companyFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'startType') {
                item[key] = startTypFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (item[key] == 0) {
                item[key] = ''
              }
            }
          }

        })
        .catch((err) => {

        });
    };
    searchList()

    const cancelSalesOnTheWay = (index) => {
      return new Promise(function (resolve, reject) {
        let onObj = tableBody[index];
        deleteSalesOnTheWay(onObj.salesOnTheWayCode).then(res => {
          resolve(res)
        })
      })
    }

    const GdAccept = (indexs) => {
      return new Promise(function (resolve, reject) {
        const partACom = store.state.loginInitializeData.companyId
        const partBCom = transferToCode(tableBody[indexs[0]].partACompanyId, 'PartBCompanyId')
        const user = store.state.loginInitializeData.userName
        const userId = store.state.loginInitializeData.userId
        getDocNo('GD').then(res => {

          let gdHeadCode = res
          let gdBody = []
          const indexLength = indexs.length
          for (let i = 0; i < indexLength; i++) {
            let b = {
              DocumentId: gdHeadCode,
              Sn: +i + 1,
              PartACompanyId: partACom,
              PartBCompanyId: partBCom,
              DocumentDateTime: '',
              OrderQty: tableBody[indexs[i]].responsedQty,
              OutStockQty: tableBody[indexs[i]].responsedQty,
              UnitPrice: tableBody[indexs[i]].unitPrice,
              Remark: `零件出售了${tableBody[indexs[i]].orderQty}个${tableBody[indexs[i]].commodityName},收益为:${tableBody[indexs[i]].unitPrice}元`,
              SourceDocumentId: '',
              RootSourceDocumentId: tableBody[indexs[i]].documentId,
              ModifiedTimes: '',
            }
            gdBody.push(b)
          }
          let gdHead = {
            DocumentId: gdHeadCode,
            DocumentTypeName: '',
            DocumentDate: '',
            DocumentDateTime: '',
            Online: '',
            PartACompanyId: partACom,
            PartBCompanyId: partBCom,
            UserId: userId,
            StaffId: '',
            SourceDocumentId: '',
            Amount: '',
            Qty: '',
            OutWareHouseId: '',
            InWareHouseId: '',
            SystemStatusId: 104,
            CapitalAccountId: '',
            Remark: '',
            RootSourceDocumentId: '',
          }
          openGdList(gdBody, gdHead, gdHeadCode).then(res => {
            if (res != 'pass') {
              const errmessage = res
              deleteDocCenter(gdHeadCode).then(res => {

                resolve(errmessage)

              })
            }
            else {
              let deleteArr = []
              for (let i = 0; i < indexLength; i++) {
                let a = deleteSalesAcceptOnTheWay(tableBody[indexs[i]].salesOnTheWayCode, tableBody[indexs[i]].responsedQty)
                deleteArr.push(a)
              }
              Promise.all(deleteArr).then(resArr => {
                resolve(resArr)
              })
            }
          })
        })
      })
    }


    const confirmOne = (index) => {

      if (!tableBody[index].sponserToBeSolve || tableBody[index].responsedQty == '') {

        ElMessage(`第${+index + 1}行无法确认`)
        return;
      }

      let codes = []
      codes.push(tableBody[index].salesOnTheWayCode)
      lockSalesOneTheWay(codes).then(res => {
        try {
          if (res != 'pass') {
            ElMessage(res)
            searchList()
            return;
          }
          let promiseArr = []
          let indexs = [index]
          let a = GdAccept(indexs)
          promiseArr.push(a)
          Promise.all(promiseArr).then((res) => {
            searchList()
            emit('refreshMsg')
          })


        } catch (error) {



        }

        finally {
          unLockSalesOneTheWay(codes)


        }



      }).catch(err => {

        searchList()
      })



    }

    const deleteOne = (index) => {

      if (tableBody[index].responsedQty > 0 && tableBody[index].refusedQty) {
        ElMessage('对方已经操作，该行无法取消')
      }
      else {
        let b = confirm('确认取消，取消后无法恢复')
        if (!b) {
          return false;
        }

        let codes = []
        codes.push(tableBody[index].salesOnTheWayCode)
        lockSalesOneTheWay(codes).then(res => {
          try {

            if (res != 'pass') {
              ElMessage(res)
              searchList()
              return;
            }
            cancelSalesOnTheWay(index).then(res => {
              searchList()
              emit('refreshMsg')
            })

          } catch (error) {



          }

          finally {
            unLockSalesOneTheWay(codes)


          }



        }).catch(err => {

          searchList()
        })


      }
    }

    const confirmMany = () => {
      if (selectedItems.value.length == 0) {

        ElMessage('未选择行')
        return;
      }
      lockSalesOneTheWay(selectedItems.value).then(res => {
        try {
          if (res != 'pass') {
            ElMessage(res)
            searchList()
            return;
          }

          let arrLength = tableBody.length
          let indexArr = []
          for (let i = 0; i < arrLength; i++) {
            if (selectedItems.value.indexOf(tableBody[i].salesOnTheWayCode) > -1) {
              indexArr.push(i)
            }
          }

          let errMsg = ''
          let promiseArr = []
          let partBArr = []

          for (let index of indexArr) {
            if (tableBody[index].responsedQty > 0) {
              if (partBArr.indexOf(transferToCode(tableBody[index].partACompanyId, 'PartBCompanyId')) <= -1) {
                partBArr.push(transferToCode(tableBody[index].partACompanyId, 'PartBCompanyId'))
              }
            }
          }

          let partBArrObj = {}
          if (partBArr.length > 0) {
            for (let item of partBArr) {
              partBArrObj[item] = []
            }
          }

          for (let index of indexArr) {
            if (tableBody[index].responsedQty <= 0) {
              errMsg += `第${+index + 1}行无需操作`
            }
            if (tableBody[index].responsedQty > 0) {
              for (let key in partBArrObj) {
                if (transferToCode(tableBody[index].partACompanyId, 'PartBCompanyId') == key) {
                  partBArrObj[key].push(index)
                }
              }
            }
          }

          for (let key in partBArrObj) {
            let c = GdAccept(partBArrObj[key])
            promiseArr.push(c)
          }

          Promise.all(promiseArr).then((res) => {
            if (errMsg != '') {
              ElMessage(errMsg)
            }
            searchList()
            emit('refreshMsg')
          })


        } catch (error) {

        }
        finally {
          unLockSalesOneTheWay(selectedItems.value)


        }




      }).catch(err => {

        searchList()
      })

    }

    const goHistory = () => {
      emit('goSalesOnTheWayHistory', 'sell')
    }



    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          searchList()
        }
      }
    )
    //#endregion

    return {
      partBFilterCopy, SponserToBeSolveFSFilter, setActiveId, activeInput,
      trHeight, SnWidth, operationWidth, tableHead, searchList, ChangeSort, tablewidth, tableBody,
      reSearch, searchElement, tbodyHeight, SearchContent, selectAllWidth, rebuildBarcode,
      selectAll, selectedItems, confirmOne, deleteOne, confirmMany, selectAllCan, goHistory

    }
  },
  components: {
  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;
@lableMarginLeft: 0.625rem;
@lableHeight: 2rem;
@inputHeight: 1.5rem;
@mainColor: #4285f4;

.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.searchinputLabel {
  margin-left: @lableMarginLeft;
  font-size: 0.8rem;
  height: @lableHeight;
  line-height: @lableHeight;
}
.searchinput {
  margin-top: calc((@lableHeight - @inputHeight) / 2);
  margin-bottom: calc((@lableHeight - @inputHeight) / 2);
  font-size: 0.9rem;
  border: none;
  outline: none;
  height: @inputHeight;
  line-height: @inputHeight;
}
.ActiveBox {
  border: 1px solid #ffa004 !important;
}

.searchInputBox {
  height: @lableHeight;
  border: 1px solid #c9c9c9;
  margin-left: @lableMarginLeft;
  border-radius: 0.31rem;
}

.searchItemContainer {
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
  display: flex;
}

.searchSetting {
  margin-left: calc(@lableMarginLeft*3);
  height: @lableHeight;
  display: flex;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}
.searchSetting:hover {
  cursor: pointer;
  color: @mainColor;
}
.settingIcon {
  font-family: "icomoon";
  font-size: 1.1rem;
  line-height: @lableHeight;
}

.settingText {
  margin-left: 0.3rem;
  font-size: 0.8rem;
  line-height: @lableHeight;
}
.dateInputContainer {
  display: flex;
}
.BodyTitle {
  width: 98%;
  margin-top: 0.5rem;
  margin-left: 1%;
}
.BodyTitleText {
  font-size: 1.2rem;
  font-weight: 200;
}
.long {
  width: 20rem;
}

.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 60%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.thSN {
  font-family: "icomoon";
  color: #fff;
  font-size: 16px;
}
.headtd {
  cursor: pointer;
}
.ShowUpArrow {
  position: relative;
}
.ShowUpArrow::after {
  content: "\ea32";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
  color: #fff;
}
.ShowDownArrow {
  position: relative;
}
.ShowDownArrow::after {
  content: "\ea36";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.tdSN,
.tableselect,
.tdoper,
.footsum,
.bodytr {
  text-align: center;
}
.bodytr {
  height: 2rem;
}

.tablefoot {
  margin-top: -1px;
}

.selectAllCheckBox {
  cursor: pointer;
}

.tipColortodo,
.tipColorfinish {
  height: 1.2rem;
  width: 1.2rem;
  margin-top: 0.3rem;
}
.tipColortodo {
  background-color: #fce4d6;
}
.tipColorfinish {
  background-color: #a6a6a6;
}
.tipWords {
  height: 1.2rem;
  line-height: 1.2rem;
  margin-top: 0.3rem;
  margin-left: 0.5rem;
}
.tipBox {
  display: flex;
  height: 1.75rem;
  width: 8rem;
  margin-top: 0.625rem;
  margin-left: 0.8rem;
}
.todoLine {
  background-color: #fce4d6 !important;
}

.nottodoLine {
  background-color: #a6a6a6 !important;
}
.firstButton {
  margin-left: 1rem;
}
</style>