<template>
  <div class="inputLoaderContainer">
    <BodyTitle></BodyTitle>
    <InputHead></InputHead>
    <InputBody ref="inputBodyRef" @clearScanIn="clearScanIn"></InputBody>
    <div class="EditTableLine">
    </div>
    <div class="EditTableLastLine">
      <!-- 维护第七层的内容 -->
      <!-- <div class="EditTableFuncButton" :style="{background:'#73a6a3'}" @click="openPopUp()">导入订单明细</div> -->
      <div class="EditTableFuncButton EditTableFuncButtonAjust" @click="openList('')">提交申请</div>
    </div>
  </div>
</template>
<script>
import { computed, provide, ref, watch } from 'vue-demi'
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import InputHead from '@/components/Content/MainBodyContent/InputHead.vue'
import InputBody from '@/components/Content/MainBodyContent/InputBody.vue'
import { getDocNo, deleteDocCenter, getLoginData, openAsList, checkDocCondition, transferToCode, openRbList, clearTableContent, getDocType, transferToName, addMoneyToBePaid } from '@/hooks/OpenOrder'
import { transferLoginDataOnly } from '@/hooks/loginAffair';
import ComScanIn from '@/components/Content/FunctionUnit/ComScanIn.vue'
import InputTable from '@/hooks/InputTable'
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
export default {
  components: {
    BodyTitle,
    InputHead,
    InputBody,
    ComScanIn
  },
  setup(props, { emit }) {
    const store = useStore();
    const RootTableName = 'RbList';
    let { itemListHead, itemListBody, searchItemList } = InputTable(RootTableName)
    searchItemList.docTitleTwo = 'RB'
    searchItemList.TableName = 'RbInput'
    provide('searchItemList', searchItemList)

    let inputBodyRef = ref(null)
    //#endregion

    //#region 扫描组件
    let scanInValue = ref('');

    const scanIn = (res) => {
      inputBodyRef.value.scanInData(res)
    }

    const clearScanIn = () => {
      scanInValue.value = '';
    }

    //#endregion

    //#region 开单功能模块


    const openList = (typeName) => {

      let errCheck = checkDocCondition(searchItemList.documentContent, itemListHead, inputBodyRef.value.AddContentBody, itemListBody)
      if (errCheck.headErr) {

        ElMessage(errCheck.headErr)
        return
      }
      if (errCheck.bodyErr) {

        ElMessage(errCheck.bodyErr)
        return
      }

      for (let item of inputBodyRef.value.AddContentBody) {
        if (item.UnitPrice && !item.AccountingSubjectId) {

          ElMessage('报销类型缺失')
          return;
        }
      }
      getLoginData().then(res => {
        //刷新初始化数据
        if(res != 0){
          transferLoginDataOnly(res)
        }
        getDocNo(searchItemList.docTitleTwo).then(res => {
          const rbHeadCode = res
          const partBId = transferToCode('员工', 'PartBCompanyId')
          let totalAmount = 0
          for (let item of inputBodyRef.value.AddContentBody) {
            if (item.UnitPrice) {
              totalAmount += item.UnitPrice
            }
          }
          totalAmount = +totalAmount.toFixed(2)
          const staffIdCopy = transferToCode(searchItemList.documentContent.StaffId, 'StaffId')
          const user = store.state.loginInitializeData.userName
          for (let item of inputBodyRef.value.AddContentBody) {
            if (item.UnitPrice) {
              item.SourceDocumentId = ''
              item.RootSourceDocumentId = rbHeadCode
              item.PartACompanyId = store.state.loginInitializeData.companyId
              item.PartBCompanyId = partBId
              item.StaffId = staffIdCopy
              item.OutStockQty = 1
              item.OrderQty = 1
              item.InStockQty = 1
              item.DocumentId = rbHeadCode
              item.Remark += `,支出类型为${transferToName(item.AccountingSubjectId, 'AccountingSubjectId')}`
            }
          }
          openRbList(inputBodyRef.value.AddContentBody, searchItemList.documentContent, rbHeadCode).then(res => {
            if (res != 'pass') {
              ElMessage(res)
              deleteDocCenter(rbHeadCode).then(res => {
                return false;
              })
            }
            else {
              const body = {
                DocumentId: rbHeadCode,
                PartACompanyId: store.state.loginInitializeData.companyId,
                PartBCompanyId: partBId,
                StaffId: staffIdCopy,
                DocumentType: getDocType(rbHeadCode),
                Amount: totalAmount,
                Remark: `操作人:${transferToName(staffIdCopy, 'StaffId')}开立了一张报销单，单号为${rbHeadCode}，需要支付${totalAmount}元,操作账号:${user}`
              }
              addMoneyToBePaid(body).then(res => {
                ElMessage({
                message:'开单成功',
                duration:2000,
                showClose:true
              })
                clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                emit('refreshMsg')
              })
            }
          })
        })
      }).catch(err => {

        ElMessage(err.message)
      })
    }
    //#endregion

    //#endregion

    return {
      searchItemList,
      inputBodyRef,
      scanInValue,
      scanIn,
      clearScanIn,
      openList
    }
  }
}
</script>
<style scoped lang="less">
.EditTableFuncButtonAjust {
  margin-right: 55rem;
}
</style>