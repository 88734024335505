import {
  computed,
  inject,
  provide,
  reactive,
  ref
} from "vue";
import {
  useStore
} from "vuex";
import {
  ChangeFirToLo,
  ChangeFirToUp,
  dataFilter,
  ChangeYMD,
  changeArrContentToDate,
  sortUpRemark,
  copyObj,
  copyArray
} from '@/hooks/tools';

import {
  transferToCode,
  getCreditValue,
  getDocDetail,
  getFrBody,
  getOutSourceAppendInfo,
  getCompanyName
} from '@/hooks/OpenOrder'

import requestData from '@/hooks/requestData';
import xlsx from "xlsx";
import {
  watch
} from "less";
import {
  tagEmits
} from "element-plus";
export default function (emit) {
  const store = useStore();
  let tableBodyElement = inject('searchItemList')
  let fileCheckData = inject('fileCheckData')

  let tableHead = reactive([]);
  let tableBody = reactive([]);
  let regArr = [];
  let SearchContent = reactive({})
  let totalLines = ref(0)
  let totalPage = ref(1)
  let searchElement = store.state.searchEle;
  let selectAll = ref(false);
  let selectedItems = ref([]);
  let deleteKey = ref('');

  let tableCode = store.state.loginInitializeData.authList.viewLists.filter(x => x.viewId == tableBodyElement.tableName)[0].viewCode
  let headName = ref(tableBodyElement.tableCName)
  const AddEditRef = ref(null);
  const UploadRef = ref(null)

  //尺寸控制

  const openPopAdd = (method) => {
    headName.value = tableBodyElement.addName
    AddEditRef.value.fillAddContent(method)
  }

  const fillSearchContent = () => {
    for (let key in tableBodyElement) {
      SearchContent[key] = tableBodyElement[key].defaultValue
    }
  }

  const fillDeleteKey = () => {
    for (let key in tableBodyElement) {
      if (tableBodyElement[key].deleteId == true) {
        deleteKey.value = ChangeFirToLo(key)
      }
    }
  }
  const openPopEdit = (method, index) => {
    headName.value = tableBodyElement.editName
    AddEditRef.value.fillEditContent(method, tableBody[index])
  }

  const refreshTableHeadandregArr = () => {
    for (let item of store.state.loginInitializeData.listForValidations) {
      if (
        item.tableNameForVali == tableBodyElement.tableName &&
        item.validateMethod == "add" &&
        item.showInTable == true
      ) {
        regArr.push(item.fieldsNameForVali.toLowerCase());
        let tem = {};
        if (tableBodyElement.tableName == 'OnTheWayDoneList' && item.fieldsNameForVali == 'PartBCompanyId' && store.state.onTheWayDoneType == 'buy') {
          continue
        }
        if (tableBodyElement.tableName == 'OnTheWayDoneList' && item.fieldsNameForVali == 'PartACompanyId' && store.state.onTheWayDoneType == 'sell') {
          continue
        }
        if (tableBodyElement.tableName == 'SalesOnTheWayDoneList' && item.fieldsNameForVali == 'PartACompanyId' && store.state.salesOnTheWayDoneType == 'buy') {
          continue
        }
        if (tableBodyElement.tableName == 'SalesOnTheWayDoneList' && item.fieldsNameForVali == 'PartBCompanyId' && store.state.salesOnTheWayDoneType == 'sell') {
          continue
        }
        tem.showName = item.showName;
        tem.fieldsNameForVali = ChangeFirToLo(item.fieldsNameForVali);
        tem.showLen = item.showLen;
        tableHead.push(tem);
      }
    }
    if (tableBodyElement.tableName == 'InventoryBalanceList' && !store.state.loginInitializeData.authList.costVisibilityManges[0].visibleForCost) {
      let length1 = tableHead.length
      for (let i = length1 - 1; i >= 0; i--) {
        if (tableHead[i].fieldsNameForVali == 'inventoryCost') {
          tableHead.splice(i, 1)
        }
      }
    }

    if (tableBodyElement.tableName == 'StaffList') {
      let staffAddHead = {
        fieldsNameForVali: 'staffCredit',
        showLen: 8,
        showName: '信用额度'
      }
      tableHead.push(staffAddHead)
    }

    if (tableBodyElement.tableName == 'DocumentCenter') {
      let staffAddHead = {
        fieldsNameForVali: 'remark2',
        showLen: 30,
        showName: '商品明细'
      }
      tableHead.splice(3, 0, staffAddHead)
    }

    emit('perfectTableHead', tableHead)
  }

  const clearTableHeadandregArr = () => {
    regArr.splice(0)
    tableHead.splice(0)
  }

  //清空vueX中的数据
  const clearVueXPage = () => {
    store.commit('modifysearchEle', {
      keyName: 'LinesPerPage',
      valueName: 100
    })
    store.commit('modifysearchEle', {
      keyName: 'Page',
      valueName: 1
    })
    store.commit('modifysearchEle', {
      keyName: 'SortFieldsName',
      valueName: ''
    })
    store.commit('modifysearchEle', {
      keyName: 'SortDirection',
      valueName: -1
    })
  }
  let SortFieldsName = ''

  const initializeVueXPageDate = () => {
    if (headName.value == '库存异动表') {
      store.commit('modifysearchEle', {
        keyName: 'SortFieldsName',
        valueName: 'InventoryChangeCode'
      })
      SortFieldsName = 'InventoryChangeCode'
      return;
    }
    if (headName.value == '账目异动表') {
      store.commit('modifysearchEle', {
        keyName: 'SortFieldsName',
        valueName: 'AccountsChangeCode'
      })
      SortFieldsName = 'AccountsChangeCode'
      return;
    }
    if (headName.value == '资金异动表') {
      store.commit('modifysearchEle', {
        keyName: 'SortFieldsName',
        valueName: 'MoneyChangeCode'
      })
      SortFieldsName = 'MoneyChangeCode'
      return;
    }
    if (headName.value == '已处理采购在途记录表') {
      store.commit('modifysearchEle', {
        keyName: 'SortFieldsName',
        valueName: 'OnTheWayDoneCode'
      })
      SortFieldsName = 'OnTheWayDoneCode'
      return;
    }

    if (headName.value == '已处理零件出售记录表') {
      store.commit('modifysearchEle', {
        keyName: 'SortFieldsName',
        valueName: 'SalesOnTheWayDoneCode'
      })
      SortFieldsName = 'SalesOnTheWayDoneCode'
      return;
    }

    if (headName.value == '已处理回收记录表') {
      store.commit('modifysearchEle', {
        keyName: 'SortFieldsName',
        valueName: 'RecyleOnTheWayDoneCode'
      })
      SortFieldsName = 'RecyleOnTheWayDoneCode'
      return;
    }

    if (headName.value == '新增维修登记') {
      store.commit('modifysearchEle', {
        keyName: 'SortFieldsName',
        valueName: 'OrderAcceptedDate'
      })
      SortFieldsName = 'OrderAcceptedDate'
      return;
    }

    if (headName.value == '维修中设备') {
      store.commit('modifysearchEle', {
        keyName: 'SortFieldsName',
        valueName: 'RepairRecordCode'
      })
      SortFieldsName = 'RepairRecordCode'
      return;
    }


    // if (headName.value == '新增维修登记') {
    //   store.commit('modifysearchEle', {
    //     keyName: 'SortFieldsName',
    //     valueName: 'RepairRecordCode'
    //   })
    //   SortFieldsName = 'RepairRecordCode'
    //   return;
    // }

    // if (headName.value == '新增维修登记') {
    //   store.commit('modifysearchEle', {
    //     keyName: 'SortFieldsName',
    //     valueName: 'RepairRecordCode'
    //   })
    //   SortFieldsName = 'RepairRecordCode'
    //   return;
    // }

    if (headName.value == '单据中心') {
      store.commit('modifysearchEle', {
        keyName: 'SortFieldsName',
        valueName: 'DocumentDateTime'
      })
      SortFieldsName = 'DocumentDateTime'
      return;
    }

    for (let key in tableBodyElement) {
      if (key.indexOf('Date') > -1 && searchElement.SortFieldsName != '') {
        store.commit('modifysearchEle', {
          keyName: 'SortFieldsName',
          valueName: key
        })
        SortFieldsName = key
        return;
      }
    }

    for (let key in tableBodyElement) {
      if (key == 'tableName' || key == 'editName' || key == 'addName' || key == 'fileModelName' || key == 'tableCName' || key == 'pageNameManageCode' || key == 'typeJudge') {
        continue;
      }
      store.commit('modifysearchEle', {
        keyName: 'SortFieldsName',
        valueName: key
      })
      SortFieldsName = key
      return;
    }
  }


  const searchList = () => {
    let b = false
    for (let key in tableBodyElement) {
      if (store.state.searchEle.SortFieldsName == key) {
        b = true
      }
    }
    // if (tableBodyElement.tableName == 'DocumentCenter') {
    //   store.state.searchEle.SortFieldsName = 'DocumentDateTime'
    // }
    if (!b) {
      clearVueXPage()
      initializeVueXPageDate()
      fillSearchContent()
    }
    let config = {
      method: "post",
      url: '/' + tableBodyElement.tableName + '/GetListByMulSearch',
      data: Object.assign(SearchContent, searchElement),
    };
    if (tableBodyElement.tableName == 'OnTheWayDoneList') {
      config.url += store.state.onTheWayDoneType
    }
    if (tableBodyElement.tableName == 'SalesOnTheWayDoneList') {
      config.url += store.state.salesOnTheWayDoneType
    }
    requestData
      .GetData(config)
      .then((res) => {
        let name = ChangeFirToLo(tableBodyElement.tableName) + 'Multiple';
        tableBody.splice(0);
        tableBody.push.apply(tableBody, res[name]);
        for (let item of tableBody) {
          if (!item.outWareHouseId) {
            item.outWareHouseId = ''
          }
          if (!item.inWareHouseId) {
            item.inWareHouseId = ''
          }
          if (!item.partBCompanyId) {
            item.partBCompanyId = ''
          }
          if (item.documentId ?.substring(0, 2) == 'TT') {
            item.inWareHouseId = ''
          }
          if (!item.capitalAccountId) {
            item.capitalAccountId = ''
          }
          if (!item.staffId) {
            item.staffId = ''
          }
          setTimeout(() => {
            if (tableBodyElement.tableName == 'DocumentCenter') {
              getDocDetail(item.documentId).then(res => {
                item.remark2 = sortUpRemark(res)
              })
            }

            if (tableBodyElement.tableName == 'SsList') {
              getCompanyName(item.partBCompanyId).then(res => {
                item.partBCompanyId = res
              })

            }

            if (tableBodyElement.tableName == 'RepairRecordList') {
              item.customerResponsePerson = ''
              if(item.remark.indexOf('客户负责人:')){
                item.customerResponsePerson = item.remark.split('客户负责人:')[1]
              }
              getFrBody(item.documentId).then(res => {
                if (res ?.sourceDocumentId) {
                  item.sourceDocumentId = res ?.sourceDocumentId
                }
                if ((item.outSourceCompanyId && transferToCode(item.outSourceCompanyId, 'PartBCompanyId') < 20000000) || res ?.sourceDocumentId) {
                  if (item.outSourceCompanyId && transferToCode(item.outSourceCompanyId, 'PartBCompanyId') < 20000000) {
                    item.outSourceInfo = {}
                    getOutSourceAppendInfo(item.documentId).then(res => {
                      if (res) {
                        for (let b in res) {
                          item.outSourceInfo[b] = res[b]
                        }
                      }
                    })
                  }
                  if (res ?.sourceDocumentId) {
                    item.selfInfo = {}
                    getOutSourceAppendInfo(res ?.sourceDocumentId).then(res => {
                      if (res) {
                        for (let b in res) {
                          item.selfInfo[b] = res[b]
                        }
                      }
                    })
                  }
                }
              })
            }
          }, 0);
        }
        dataFilter(tableBody, tableBodyElement, deleteKey.value)
        if (tableBodyElement.tableName == 'RepairRecordList') {
          for (let item of tableBody) {
            item.outSourceIdFor = transferToCode(item.outSourceCompanyId, 'OutSourceCompanyId')
          }
        }
        if (tableBodyElement.tableName == 'FrList') {
          for (let item of tableBody) {
            item.outSourceCompanyId = item.outSourceCompanyId == 0 ? '' : item.outSourceCompanyId
          }
        }
        changeArrContentToDate(tableBody, tableBodyElement.tableName)
        totalLines.value = res.lineCounts;
        totalPage.value = res.totalPage;
        if (tableBodyElement.tableName == 'StaffList') {
          for (let item of tableBody) {
            getCreditValue(item.staffId).then(res => {
              item.staffCredit = res
            })
          }
        }
        emit('perfectTableBody', tableBody)
      })
      .catch((err) => {

      });
  };


  //f将页数调整为1
  const turnPageToOne = () => {
    store.commit('modifysearchEle', {
      keyName: 'Page',
      valueName: 1
    })
    // searchList()
  }
  //f增加页数
  let UpPage = () => {
    if (searchElement.Page < totalPage.value) {
      store.commit('modifysearchEle', {
        keyName: 'Page',
        valueName: searchElement.Page + 1
      })
      searchList()
    }
  };
  //f跳转最后一页

  let EndPage = () => {
    store.commit('modifysearchEle', {
      keyName: 'Page',
      valueName: totalPage.value
    })
    searchList()
  };
  //f减少页数
  let DownPage = () => {
    if (searchElement.Page > 1) {
      store.commit('modifysearchEle', {
        keyName: 'Page',
        valueName: searchElement.Page - 1
      })
      searchList()
    }
  };
  //f跳转到第一页
  let FirstPage = () => {
    store.commit('modifysearchEle', {
      keyName: 'Page',
      valueName: 1
    })
    searchList()
  };
  //f改变排序
  let ChangeSort = (fildsname) => {
    store.commit('modifysearchEle', {
      keyName: 'SortFieldsName',
      valueName: ChangeFirToUp(fildsname)
    })
    store.commit('modifysearchEle', {
      keyName: 'SortDirection',
      valueName: -searchElement.SortDirection
    })
    searchList()

  };

  //单个删除的方法
  let sendDeleteId = (id) => {

    if (!confirm("是否确认删除,删除后无法恢复")) {

      return false;
    }
    let config = {
      method: "delete",
      url: '/' + ChangeFirToLo(tableBodyElement.tableName) + '/Delete',
      params: {
        id: id
      },
    };
    requestData.GetData(config).then((res) => {
      if (res == "pass") {

        alert("删除成功");
        searchList();
      } else {

        alert(res);
      }
    }).catch(err => {

      alert(err.message)
    })
  };

  const sendDeleteIds = () => {

    if (selectedItems.value.length == 0) {

      alert('未选择数据');
      return false;
    }
    if (!confirm("是否确认删除,删除后无法恢复,系统只能尽量保留记录")) {

      return false;
    }
    let config = {
      method: "post",
      url: '/' + ChangeFirToLo(tableBodyElement.tableName) + '/DeleteMany',
      data: selectedItems.value,
    };
    requestData.GetData(config).then((res) => {
      if (res == "pass") {

        selectedItems.value.splice(0);
        searchList();
        selectAll.value = false;
        alert("删除成功");
      } else {

        selectedItems.value.splice(0);
        searchList();
        alert(res);
      }
    }).catch(err => {

      alert(err.message)
    })
  };

  //导出选中数据变成EXCEL文件的方法
  let downLoadtablebody = () => {

    if (selectedItems.value.length < 1) {

      alert("未选择导出内容");
      return false;
    }
    let obj = [];
    for (let item of tableBody) {
      if (selectedItems.value.indexOf(item[deleteKey.value]) >= 0) {
        obj.push(item);
      }
    }
    let target = [];
    for (let oneitem of obj) {
      let obj1 = {};
      for (let singleObj in oneitem) {
        for (let itemtwo of fileCheckData) {
          if (itemtwo.ename.toLowerCase() == singleObj.toLowerCase()) {
            obj1[itemtwo.cname] = oneitem[singleObj];
          }
        }
      }
      target.push(obj1);
    }
    let sheet = xlsx.utils.json_to_sheet(target);
    let book = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(book, sheet, "sheet1");
    xlsx.writeFile(book, `${tableBodyElement.tableCName}${ChangeYMD(Date.now())}.xlsx`);

  };

  const refresh = () => {
    searchList()
  }
  const openPopUp = () => {
    headName.value = tableBodyElement.fileModelName
    UploadRef.value.openPopComm()
  }
  //#endregion

  //#region 数据提供区
  provide('tableTitle', headName)



  return {
    store,
    tableBodyElement,
    fileCheckData,
    tableHead,
    tableBody,
    regArr,
    SearchContent,
    totalLines,
    totalPage,
    searchElement,
    selectAll,
    selectedItems,
    deleteKey,
    tableCode,
    headName,
    AddEditRef,
    UploadRef,
    openPopAdd,
    fillSearchContent,
    fillDeleteKey,
    openPopEdit,
    refreshTableHeadandregArr,
    clearTableHeadandregArr,
    clearVueXPage,
    SortFieldsName,
    initializeVueXPageDate,
    searchList,
    turnPageToOne,
    UpPage,
    EndPage,
    DownPage,
    FirstPage,
    ChangeSort,
    sendDeleteIds,
    sendDeleteId,
    downLoadtablebody,
    refresh,
    openPopUp
  }
}