<template>
  <div class="searchContentContainer">

    <div v-for="item in searchInputListSel" :key="item.fieldsNameForVali +item.tableNameForVali" v-show="!((searchItemList.tableName == 'OnTheWayDoneList' && $store.state.onTheWayDoneType == 'buy' && item.fieldsNameForVali == 'PartBCompanyId') || (searchItemList.tableName == 'OnTheWayDoneList' && $store.state.onTheWayDoneType == 'sell' && item.fieldsNameForVali == 'PartACompanyId') || (searchItemList.tableName == 'SalesOnTheWayDoneList' && $store.state.salesOnTheWayDoneType == 'sell' && item.fieldsNameForVali == 'PartBCompanyId') || (searchItemList.tableName == 'SalesOnTheWayDoneList' && $store.state.salesOnTheWayDoneType == 'sell' && item.fieldsNameForVali == 'PartBCompanyId'))">
      <div class="searchItemContainer" v-if="searchItemList[item.fieldsNameForVali] && searchItemList[item.fieldsNameForVali].searchShow  && item.tableNameForVali == searchItemList.tableName">
        <label class="searchinputLabel" :for="item.fieldsNameForVali" v-text="searchItemList[item.fieldsNameForVali].showName + ':'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == item.fieldsNameForVali}"><select v-model="searchItems[item.fieldsNameForVali]" class="searchinput" :id="item.fieldsNameForVali" @focus="setActiveId(item.fieldsNameForVali)" @blur="setActiveId('')">
            <option :value="''" :key="item.fieldsNameForVali + 'all'">所有</option>
            <option v-for="item1 in searchItemList[item.fieldsNameForVali].Filter" :value="item1.beforeTransfer" :key="item.fieldsNameForVali + item1.beforeTransfer">{{item1.afterTransfer}}</option>
          </select></div>
      </div>
    </div>

    <div v-for="item in searchInputListTex" :key="item.fieldsNameForVali +item.tableNameForVali">
      <div class="searchItemContainer" v-if="searchItemList[item.fieldsNameForVali] && searchItemList[item.fieldsNameForVali].searchShow && item.tableNameForVali == searchItemList.tableName">
        <label class="searchinputLabel" :for="item.fieldsNameForVali" v-text="searchItemList[item.fieldsNameForVali].showName + ':'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == item.fieldsNameForVali}"><input v-model="searchItems[item.fieldsNameForVali]" class="searchinput" :id="item.fieldsNameForVali" type="text" @focus="setActiveId(item.fieldsNameForVali)" @blur="setActiveId('')"></div>
      </div>
    </div>

    <div v-for="item in searchInputListNum" :key="item.fieldsNameForVali +item.tableNameForVali">
      <div class="searchItemContainer" v-if="searchItemList[item.fieldsNameForVali] && searchItemList[item.fieldsNameForVali].searchShow  && item.tableNameForVali == searchItemList.tableName">
        <label class="searchinputLabel" :for="item.fieldsNameForVali" v-text="searchItemList[item.fieldsNameForVali].showName + ':'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == item.fieldsNameForVali}"><input v-model="searchItems[item.fieldsNameForVali]" class="searchinput" :id="item.fieldsNameForVali" type="number" @focus="setActiveId(item.fieldsNameForVali)" @blur="setActiveId('')"></div>
      </div>
    </div>

    <div class="dateInputContainer" v-for="item in searchInputListDatl" :key="item.fieldsNameForVali +item.tableNameForVali">
      <div class="searchItemContainer" v-if="searchItemList[item.fieldsNameForVali] && searchItemList[item.fieldsNameForVali].searchShow  && item.tableNameForVali == searchItemList.tableName">
        <label class="searchinputLabel" :for="item.fieldsNameForVali" v-text="searchItemList[item.fieldsNameForVali].showName + ':'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == item.fieldsNameForVali}"><input v-model="searchItems[item.fieldsNameForVali]" class="searchinput" :id="item.fieldsNameForVali" type="date" @focus="setActiveId(item.fieldsNameForVali)" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer" v-if="searchItemList[item.fieldsNameForVali] && searchItemList[item.fieldsNameForVali].searchShow  && item.tableNameForVali == searchItemList.tableName">
        <label class="searchinputLabel" :for="item.fieldsNameForVali.substring(0, item.fieldsNameForVali.length - 1) + 'H'" v-text="'---'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == item.fieldsNameForVali.substring(0, item.fieldsNameForVali.length - 1) + 'H'}"><input v-model="searchItems[item.fieldsNameForVali.substring(0, item.fieldsNameForVali.length - 1) + 'H']" class="searchinput" :id="item.fieldsNameForVali.substring(0, item.fieldsNameForVali.length - 1) + 'H'" type="date" @focus="setActiveId(item.fieldsNameForVali.substring(0, item.fieldsNameForVali.length - 1) + 'H')" @blur="setActiveId('')"></div>
      </div>
    </div>

    <!-- 搜索模块 -->
    <div class="FuncButton" @click="searchList()">搜索</div>
    <div class="searchSetting" @click="openSearchSetting()">
      <div class="settingIcon"></div>
      <div class="settingText">设置组合查询</div>
    </div>
    <SelectPopModel ref="searchRef"></SelectPopModel>
  </div>
</template>

<script>
import { ref, reactive, toRefs, inject, provide, onUnmounted } from 'vue-demi'
import SelectPopModel from '@/components/ComPop/SelectPopModel.vue';
import { fillSonContent, uniqueArrByKey } from '@/hooks/tools'
import { useStore } from "vuex";
import DataSpan from "@/components/Common/ElementUI/DateSpan.vue";
export default {
  setup(props, { emit }) {
    const store = useStore();

    //#region 数据定义区
    let searchInputListTex = reactive(store.state.loginInitializeData.listForValidations.filter(x => x.validateMethod == 'add' && x.searchTrans == true && x.searchInputType == 'tex'));
    let searchInputListNum = reactive(store.state.loginInitializeData.listForValidations.filter(x => x.validateMethod == 'add' && x.searchTrans == true && x.searchInputType == 'num'));
    let searchInputListSel = reactive(store.state.loginInitializeData.listForValidations.filter(x => x.validateMethod == 'add' && x.searchTrans == true && x.searchInputType == 'sel'));
    let searchInputListDatl = reactive(store.state.loginInitializeData.listForValidations.filter(x => x.validateMethod == 'add' && x.searchTrans == true && x.searchInputType == 'datl'));
    let searchItemList = inject('searchItemList')
    let headName = ref(searchItemList.fileModelName)
    let activeInput = ref('');
    let searchItems = reactive({})
    const searchRef = ref(null)

    //#endregion

    //#region 公式定义区
    const setActiveId = (id) => {
      activeInput.value = id
    }
    const openSearchSetting = () => {
      searchRef.value.openPopComm()
    }
    const searchList = () => {
      emit('deliverSearchItems', searchItems)
    }
    //#endregion

    //#region 数据提供区
    provide('tableTitle', headName)
    //#endregion

    //#region 挂载前加载
    //加载搜索默认值
    fillSonContent(searchItems, searchItemList, 'search', 'defaultValue')




    return {
      activeInput,
      setActiveId,
      searchItems,
      searchItemList,
      searchRef,
      openSearchSetting,
      searchList,
      searchInputListTex,
      searchInputListNum,
      searchInputListSel,
      searchInputListDatl,
    }
  },
  components: {
    SelectPopModel,
    DataSpan
  }
}
</script>

<style scoped lang="less">
@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;
@lableMarginLeft: 0.625rem;
@lableHeight: 2rem;
@inputHeight: 1.5rem;
@mainColor: #4285f4;

.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.searchinputLabel {
  margin-left: @lableMarginLeft;
  font-size: 0.8rem;
  height: @lableHeight;
  line-height: @lableHeight;
}
.searchinput {
  margin-top: calc((@lableHeight - @inputHeight) / 2);
  margin-bottom: calc((@lableHeight - @inputHeight) / 2);
  font-size: 0.9rem;
  border: none;
  outline: none;
  height: @inputHeight;
  line-height: @inputHeight;
}
.ActiveBox {
  border: 1px solid #ffa004 !important;
}

.searchInputBox {
  height: @lableHeight;
  border: 1px solid #c9c9c9;
  margin-left: @lableMarginLeft;
  border-radius: 0.31rem;
}

.searchItemContainer {
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
  display: flex;
}

.FuncButton {
  height: @lableHeight;
  line-height: @lableHeight;
  margin-left: calc(@lableMarginLeft*2);
  width: 4rem;
  background-color: #f6f6f6;
  text-align: center;
  border: 1px solid #c9c9c9;
  cursor: pointer;
  font-weight: 700;
  border-radius: 4px;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}
.FuncButton:hover {
  background-color: #f08300;
  color: #fff;
}

.searchSetting {
  margin-left: calc(@lableMarginLeft*3);
  height: @lableHeight;
  display: flex;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}
.searchSetting:hover {
  cursor: pointer;
  color: @mainColor;
}
.settingIcon {
  font-family: "icomoon";
  font-size: 1.1rem;
  line-height: @lableHeight;
}

.settingText {
  margin-left: 0.3rem;
  font-size: 0.8rem;
  line-height: @lableHeight;
}
.dateInputContainer {
  display: flex;
}
</style>