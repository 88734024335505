<template>
  <div class="tableLoaderContainer">

    <div class="BodyTitle">
      <div class="BodyTitleText">内部二手交易市场</div>
    </div>

    <div class="searchContentContainer">
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="MachineClassForMachineToBeSolve">机器类型:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'MachineClass'}"><select v-model="SearchContent.MachineClass" class="searchinput" id="MachineClassForMachineToBeSolve" @focus="setActiveId('MachineClass')" @blur="setActiveId('')" @change="reSearch(),updateBrands()">
            <option value="">所有</option>
            <option v-for="item in MachineClassFilter" :key="item.beforeTransfer + 'MachineToBeSolve'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
          </select></div>
      </div>

      <div class="searchItemContainer">
        <label class="searchinputLabel" for="MachineBrandForMachineToBeSolve">品牌:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'MachineBrand'}"><select v-model="SearchContent.MachineBrand" class="searchinput" id="MachineBrandForMachineToBeSolve" @focus="setActiveId('MachineBrand')" @blur="setActiveId('')" @change="reSearch()">
            <option value="" selected="selected">所有</option>
            <option v-for="item in brandList" :key="item.brandId + 'MpInput'" value="item.brandId">{{item.brandName}}</option>
          </select></div>
      </div>

      <!-- 搜索模块 -->
      <div class="FuncButton" @click="searchList()">搜索</div>
    </div>

    <div class="BigTable">
      <div class="TableContentArea">
        <div class="machineShowBox">
          <div class="machineShowContainer" v-for="(item,key) in tableBody" :key="item.machineStorageId + 'machineMarket'">
            <div><span>机型：</span><input readonly="readonly" type="text" class="mInput boldword" v-model="item.machineType"><span>售价：</span><input readonly="readonly" type="text" class="sInput redword" v-model="item.postedPrice"></div>
            <div><span>属性：</span><input readonly="readonly" type="text" class="sInput" v-model="item.brandNewOrNot"><span>颜色：</span><input readonly="readonly" type="text" class="sInput" v-model="item.color"><span>版本：</span><input readonly="readonly" type="text" class="sInput" v-model="item.country"></div>
            <div><span>内存：</span><input readonly="readonly" type="text" class="sInput" v-model="item.memory"><span>硬盘：</span><input readonly="readonly" type="text" class="sInput" v-model="item.hardDisk"><span>账号：</span><input readonly="readonly" type="text" class="sInput" v-model="item.idQuitOrNot"></div>
            <div><span>CPU：</span><input readonly="readonly" type="text" class="sInput" v-model="item.cpu"><span>显卡：</span><input readonly="readonly" type="text" class="sInput" v-model="item.gpu"><span>剩保：</span><input readonly="readonly" type="text" class="sInput" v-model="item.warrantyDaysLeft"></div>
            <div><span>备注：</span><input readonly="readonly" type="text" class="lInput" v-model="item.postedRemark"></div>
            <div><span>商家：</span><input readonly="readonly" type="text" class="lInput" v-model="item.partACompanyId"></div>
            <div class="funcBox">
              <el-button type="warning" v-show="item.bookCheck" size="small" class="mRight" @click="cancelBook(key)">取消预定</el-button>
              <el-button type="primary" v-show="item.partACompanyId != $store.state.loginInitializeData.companyNameAbbre" size="small" class="mRight lastButton" @click="openBookRef(key)">预定/修改预定</el-button>
            </div>
          </div>

        </div>
        <div class="MainBodyTablePage" :style="{ width: '100%'}">
          <!--页数模块 -->
          <div class="PageCountContainer">
            <div class="PagerSummaryText">({{ totalLines }}条记录,总共{{ totalPage }}页)</div>
            <span class="PagerEveryPageText">每页/条</span>
            <div class="EditBlock">
              <input class="LinesPerPageChooese" type="text" v-model="searchEle.LinesPerPage" />
              <div class="LinesPerPageChooseButton"></div>
            </div>
            <div class="GoFirstPageButton" @click="FirstPage()"></div>
            <div class="GoLastPageButton" @click="DownPage()"></div>
            <span class="pagetextforDi">第</span>
            <div class="EditBlock">
              <input class="PageEdit" type="text" v-model="searchEle.Page" />
              <div class="PageChooseButton"></div>
            </div>
            <span class="pagetextforYe">页</span>
            <div class="GoNextPageButton" @click="UpPage()"></div>
            <div class="GoEndPageButton" @click="EndPage()"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="moreFunctionBox">
      <!-- <div class="FuncButton" @click="goInvenChange()">库存变动查询</div> -->
    </div>
    <PopModel ref="bookRef">
      <div class="inputBox">
        <label class="popLable" for="bookPersonMachineMarket">预定人员:</label>
        <el-select v-model="bookInfo.bookPerson" class="m-2 popInput" placeholder="请选择预定人员" id="bookPersonMachineMarket">
          <el-option v-for="item in staffFilter" :key="item.beforeTransfer + 'bookMacMark'" :label="item.afterTransfer" :value="item.beforeTransfer">
          </el-option>
        </el-select>
      </div>
      <div class="inputBox">
        <label class="popLable" for="bookPriceMachineMarket">预定价格:</label>
        <el-input type="number" v-model="bookInfo.bookPrice" class="popInput" placeholder="请输入预定价格" id="bookPriceMachineMarket" />
      </div>
      <div class="inputBox">
        <label class="popLable" for="RemarkMachineMarket">预定备注:</label>
        <el-input v-model="bookInfo.remark" id="RemarkMachineMarket" class="popInput" placeholder="请输入预定备注" />
      </div>
      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight" @click="closeBookRef()">取消</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="sendBook()">确认预定</el-button>
      </div>
    </PopModel>
  </div>
</template>

<script>
import { computed, provide, reactive, ref, watch } from 'vue-demi';
import { useStore } from "vuex";
import PopModel from '@/components/Common/PopModel.vue'
import { isRealNum } from '@/hooks/tools'
import {
  ChangeFirToLo, ChangeFirToUp, ChangeYMD, changeArrContentToDate
} from '@/hooks/tools';
import requestData from '@/hooks/requestData';
import { fillDefaultValue, changeToViewCode, transferToCode, transferToName, addMachineQueryList, getBookInfo, checkBookOrNot,deleteBookApply } from '@/hooks/OpenOrder'
import { ElMessage } from 'element-plus'
export default {
  setup(props,{emit}) {
    const store = useStore();
    //#region 定义区
    const MachineClassFilter = store.state.loginInitializeData.filterList.MachineClass

    const filterList = store.state.loginInitializeData.filterList

    //初始化输入表名称
    const TableName = 'MachineStorageList';

    const PageName = 'MachineMarket'

    const code = changeToViewCode(PageName)

    const staffFilter = store.state.loginInitializeData.filterList.StaffId

    provide('tableTitle', '')

    let searchEle = reactive({
      LinesPerPage: 30,
      Page: 1,
      SortFieldsName: 'MachineStorageId',
      SortDirection: -1
    })
    let totalLines = ref(0)
    let totalPage = ref(1)

    let UpPage = () => {
      if (searchEle.Page < totalPage.value) {
        searchEle.Page++
        searchList()
      }
    };
    //f跳转最后一页

    let EndPage = () => {
      searchEle.Page = totalPage.value
      searchList()
    };
    //f减少页数
    let DownPage = () => {
      if (searchEle.Page > 1) {
        searchEle.Page--
        searchList()
      }
    };
    //f跳转到第一页
    let FirstPage = () => {
      searchEle.Page = 1
      searchList()
    };

    watch(
      () => [searchEle.Page],
      (newValue, oldValue) => {
        if (totalPage.value > 0) {
          if (parseInt(newValue[0]) > totalPage.value) {
            // ElMessage('不能超过最大页数');
            searchEle.Page = totalPage.value
            return;
          }
        }
        if (parseInt(newValue[0]) < 1) {
          // ElMessage('页数不能小于1');
          searchEle.Page = 1
          return;
        }
      }
    );
    //w用于监控页数，确保客户不乱填
    watch(
      () => searchEle.LinesPerPage,
      (newValue, oldValue) => {
        if (newValue > 99) {
          searchEle.LinesPerPage = 99
          ElMessage("每页数量不能超过99");
        }
        if (newValue < 1) {
          searchEle.LinesPerPage = 1
          ElMessage("每页数量不能低于1");
        }
      }
    );

    //#endregion

    //#region 全选
    let selectAll = ref(false);

    let selectedItems = ref([]);

    watch(selectAll, (newValue, oldValue) => {

      if (code == store.state.loginInitializeData.ShowWindow[0]) {
        if (newValue == true) {
          selectedItems.value.splice(0);
          for (let item of tableBody) {
            selectedItems.value.push(item['machineStorageId']);
          }
        } else {
          selectedItems.value.splice(0);
        }
      }
    });
    //#endregion

    let activeInput = ref('');

    const setActiveId = (id) => {
      activeInput.value = id
    }

    let SearchContent = reactive({
      MachineStorageId: '',
      PartACompanyId: '',
      MachineClass: '',
      MachineType: '',
      MachineBrand: '',
      BrandNewOrNot: '',
      Cpu: '',
      Memory: '',
      HardDisk: '',
      Gpu: '',
      Color: '',
      Country: '',
      UniqueCode: '',
      WarrantyDaysLeft: '',
      IdQuitOrNot: '',
      OutLookDiscription: '',
      MachineName: '',
      RepairHistoryAndRemark: '',
      PurchasePrice: '',
      RepairCost: '',
      PostOrNot: '',
      PostedPrice: '',
      PostedRemark: '',
      MpDocumentId: '',
      Status: '',
      Pictrue: '',
      BrandNewOrNotFS: '',
      IdQuitOrNotFS: '',
      PostOrNotFS: '',
      DocumentDateL: '',
      DocumentDateH: '',
    })

    fillDefaultValue(SearchContent, TableName)

    //f计算表格内容总宽度


    let tableBody = reactive([]);


    const reSearch = () => {
      searchList()
    }

    let searchList = () => {
      let config = {
        method: "post",
        url: '/MachineStorageList/GetListByMulSearchForMarket',
        data: Object.assign(SearchContent, searchEle),
      };
      requestData
        .GetData(config)
        .then((res) => {
          totalLines.value = res?.lineCounts
          totalPage.value = res?.totalPage
          
          tableBody.splice(0);
          tableBody.push.apply(tableBody, res['machineStorageListMultiple']);
          changeArrContentToDate(tableBody, PageName)
          const filterList = store.state.loginInitializeData.filterList
          const MachineClassFilter = filterList.MachineClass
          const BrandNewOrNotFilter = filterList.BrandNewOrNot
          const IdQuitOrNotFilter = filterList.IdQuitOrNot
          const PostOrNotFilter = filterList.PostOrNot
          const partBFilter = filterList.OwedCompanyId
          for (let item of tableBody) {
            for (let key in item) {
              if (key == 'machineClass') {
                item[key] = MachineClassFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'brandNewOrNot') {
                item[key] = BrandNewOrNotFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'idQuitOrNot') {
                item[key] = IdQuitOrNotFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'postOrNot') {
                item[key] = PostOrNotFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'partACompanyId') {
                item[key] = partBFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
            }
            if (isRealNum(item.hardDisk)) {
              item.hardDisk = item.hardDisk + 'g'
            }
            if (isRealNum(item.memory)) {
              item.memory = item.memory + 'g'
            }
            if (isRealNum(item.warrantyDaysLeft)) {
              item.warrantyDaysLeft = item.warrantyDaysLeft + '天'
            }
            item.postedPrice = item.postedPrice + '元'

            checkBookOrNot(item.machineStorageId).then(res => {
              item.bookCheck = (res == 'pass' ? true : false)
            })
          }
        })

        .catch((err) => {
        });
    };
    searchList()
    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          searchList()
        }
      }
    )

    const classToBrand = store.state.loginInitializeData.authList.classToBrandLists

    const brands = store.state.loginInitializeData.authList.brandLists

    let brandList = reactive([])

    const updateBrands = () => {
      brandList.splice(0)
      const brandsDraft = classToBrand.filter(x => x.commodityFirstClassNo == SearchContent.MachineClass)
      for (let item of brandsDraft) {
        let obj = {
          brandId: item.brandId,
          brandName: brands.find(x => x.brandId == item.brandId).brandName
        }
        brandList.push(obj)
      }
    }
    //#endregion

    const openBookRef = (index) => {
      getBookInfo(tableBody[index].machineStorageId).then(res => {
        bookInfo.machineInfo = tableBody[index]
        if (res) {
          bookInfo.bookPerson = transferToCode(res.remark.substring(0, res.remark.indexOf('下单')), 'StaffId')
          bookInfo.bookPrice = res.unitPrice
          bookInfo.remark = res.remark.substring(res.remark.indexOf('备注:') + 3, res.remark.length)
        }
        else {
          bookInfo.bookPerson = ''
          bookInfo.bookPrice = 0
          bookInfo.remark = ''
        }
        bookInfo.machineInfo = tableBody[index]
        bookRef.value.openPopComm()
      })
    }

    const bookRef = ref(null)

    let bookInfo = reactive({
      machineInfo: '',
      bookPerson: '',
      bookPrice: '',
      remark: '',
    })

    const closeBookRef = () => {
      bookRef.value.closePopComm()
    }

    const sendBook = () => {
      
      if (!bookInfo.machineInfo) {
        
        ElMessage('缺失机器信息')
        return
      }
      if (!bookInfo.bookPerson) {
        
        ElMessage('缺失人员信息')
        return
      }
      if (bookInfo.bookPrice == '') {
        
        ElMessage('缺失预定价格')
        return
      }
      if (bookInfo.bookPrice < 0) {
        
        ElMessage('价格不可小于0')
        return
      }
      if (bookInfo.bookPrice == 0) {
        let b = confirm('价格为0，是否继续')
        if (!b) {
          
          return;
        }
      }
      const partACom = store.state.loginInitializeData.companyId
      const ownCompany = transferToCode(bookInfo.machineInfo.partACompanyId, 'OwedCompanyId')
      if (partACom == ownCompany) {
        
        ElMessage('不能预定本公司发布的机器')
        return;

      }
      const user = store.state.loginInitializeData.userName
      const userId = store.state.loginInitializeData.userId

      let objA = {
        DocumentDate: '',
        PartACompanyId: ownCompany,
        PartBCompanyId: partACom,
        MachineStorageId: bookInfo.machineInfo.machineStorageId,
        MachineName: bookInfo.machineInfo.machineType + '-' + bookInfo.machineInfo.memory + '-' + bookInfo.machineInfo.color,
        UniqueCode: bookInfo.machineInfo.uniqueCode,
        UserId: userId,
        StaffId:bookInfo.bookPerson,
        UnitPrice: bookInfo.bookPrice,
        PostedPrice: parseFloat(bookInfo.machineInfo.postedPrice),
        NotifyStatus: '',
        ResponseStatus: 101,
        SupplierToBeSolve: true,
        CustomerToBeSolve: false,
        Remark: `${transferToName(bookInfo.bookPerson, 'StaffId')}下单,备注:${bookInfo.remark}`,
      }
      addMachineQueryList(objA).then(res => {
        if (res != 'pass') {
          
          ElMessage(res)
          return
        }
        else {
          closeBookRef()
          searchList()
          emit('refreshMsg')
        }
        
      }).catch(err=>{
        
        ElMessage(err.message)
      })
    }

    const cancelBook = (index) => {
      
      let b = confirm('确认取消?')
      if(!b){
        
        return;
      }
      if(tableBody[index].responseStatus == 103 || tableBody[index].responseStatus == '同意'){
        
        ElMessage('对方已经同意并发货，无法取消')
        return
      }
      else{
        deleteBookApply(tableBody[index].machineStorageId).then(res=>{
          if(res != 'pass'){
            
            ElMessage(res)
            return
          }
          else{
            searchList()
            emit('refreshMsg')
          }
          
        }).catch(err=>{
          
          ElMessage(err.message)
        })
      }
    }

    //#region 活跃时处理

    return {
      setActiveId, activeInput, searchList, tableBody,
      reSearch, SearchContent,
      searchEle, totalLines, totalPage, UpPage, EndPage, DownPage, FirstPage, brandList, updateBrands,
      MachineClassFilter, openBookRef, bookRef, bookInfo, staffFilter, closeBookRef, sendBook,cancelBook

    }
  },
  components: {
    PopModel
  }
}
</script>

<style scoped lang="less">
@spanWidth: 3rem;
@lineHeight: 1.4rem;

.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;
@lableMarginLeft: 0.625rem;
@lableHeight: 2rem;
@inputHeight: 1.5rem;
@mainColor: #4285f4;

.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.searchinputLabel {
  margin-left: @lableMarginLeft;
  font-size: 0.8rem;
  height: @lableHeight;
  line-height: @lableHeight;
}
.searchinput {
  margin-top: calc((@lableHeight - @inputHeight) / 2);
  margin-bottom: calc((@lableHeight - @inputHeight) / 2);
  font-size: 0.9rem;
  border: none;
  outline: none;
  height: @inputHeight;
  line-height: @inputHeight;
}
.ActiveBox {
  border: 1px solid #ffa004 !important;
}

.searchInputBox {
  height: @lableHeight;
  border: 1px solid #c9c9c9;
  margin-left: @lableMarginLeft;
  border-radius: 0.31rem;
}

.searchItemContainer {
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
  display: flex;
}


.searchSetting {
  margin-left: calc(@lableMarginLeft*3);
  height: @lableHeight;
  display: flex;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}
.searchSetting:hover {
  cursor: pointer;
  color: @mainColor;
}
.settingIcon {
  font-family: "icomoon";
  font-size: 1.1rem;
  line-height: @lableHeight;
}

.settingText {
  margin-left: 0.3rem;
  font-size: 0.8rem;
  line-height: @lableHeight;
}
.dateInputContainer {
  display: flex;
}
.BodyTitle {
  width: 98%;
  margin-top: 0.5rem;
  margin-left: 1%;
}
.BodyTitleText {
  font-size: 1.2rem;
  font-weight: 200;
}
.long {
  width: 20rem;
}

.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 80%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}

.moreFunctionBox {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}


.MainBodyTablePage {
  box-sizing: border-box;
  border: 1px solid #cccccc;
}
.PageCountContainer {
  display: flex;
  justify-content: flex-end;
  font-size: 0.875rem;
  align-items: center;
}
.PagerSummaryText {
  padding-right: 1.25rem;
}
.PagerEveryPageText {
  padding-right: 0.3125rem;
}
.EditBlock {
  display: flex;
  align-items: center;
}
.PageEdit {
  height: 1.25rem;
  width: 1.875rem;
  border-left: 0.5px solid #c9c9c9;
  border-top: 0.5px solid #c9c9c9;
  border-bottom: 0.5px solid #c9c9c9;
  font-size: 0.625rem;
}
.PageChooseButton {
  position: relative;
  box-sizing: border-box;
  height: 1.25rem;
  width: 1.25rem;
  border-right: 0.5px solid #c9c9c9;
  border-top: 0.5px solid #c9c9c9;
  border-bottom: 0.5px solid #c9c9c9;
  position: relative;
  margin-right: 0.625rem;
}

.PageChooseButton::before {
  content: "\e902";
  font-family: "icomoon";
  position: absolute;
  top: -0.3125rem;
  left: 0px;
  font-size: 1.25rem;
}
.GoFirstPageButton {
  height: 1.875rem;
  width: 1.25rem;
  position: relative;
  margin-right: 0.3125rem;
  cursor: pointer;
  margin-left: 0.3125rem;
}

.GoFirstPageButton::before {
  content: "\f100";
  font-family: "icomoon";
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 1.25rem;
}
.GoLastPageButton {
  height: 1.875rem;
  width: 1.25rem;
  position: relative;
  margin-right: 0.625rem;
  cursor: pointer;
}

.GoLastPageButton::before {
  content: "\f104";
  font-family: "icomoon";
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 1.25rem;
}
.pagetextforDi {
  margin-right: 0.625rem;
}
.EditBlock {
  display: flex;
  align-items: center;
}
.PageEdit {
  height: 1.25rem;
  width: 1.875rem;
  border-left: 0.5px solid #c9c9c9;
  border-top: 0.5px solid #c9c9c9;
  border-bottom: 0.5px solid #c9c9c9;
  font-size: 0.625rem;
}
.PageChooseButton {
  box-sizing: border-box;
  height: 1.25rem;
  width: 1.25rem;
  border-right: 0.5px solid #c9c9c9;
  border-top: 0.5px solid #c9c9c9;
  border-bottom: 0.5px solid #c9c9c9;
  position: relative;
  margin-right: 0.625rem;
}

.PageChooseButton::before {
  content: "\e902";
  font-family: "icomoon";
  position: absolute;
  top: -0.3125rem;
  left: 0px;
  font-size: 1.25rem;
}
.pagetextforYe {
  margin-right: 0.625rem;
}
.GoNextPageButton {
  height: 1.875rem;
  width: 1.25rem;
  position: relative;
  margin-right: 0.3rem;
  cursor: pointer;
}

.GoNextPageButton::before {
  content: "\f105";
  font-family: "icomoon";
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 1.25rem;
}
.GoEndPageButton {
  height: 1.875rem;
  width: 1.25rem;
  position: relative;
  margin-right: 0.1rem;
  cursor: pointer;
}

.GoEndPageButton::before {
  content: "\f101";
  font-family: "icomoon";
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 1.25rem;
}

.LinesPerPageChooese {
  height: 1.25rem;
  width: 1.875rem;
  border-left: 0.5px solid #c9c9c9;
  border-top: 0.5px solid #c9c9c9;
  border-bottom: 0.5px solid #c9c9c9;
  font-size: 0.625rem;
}

.machineShowBox {
  display: flex;
  flex-wrap: wrap;
  height: 90%;
  width: 100%;
  border-top: 1px solid #c9c9c9;
  border-left: 1px solid #c9c9c9;
  border-right: 1px solid #c9c9c9;
  overflow: hidden;
  overflow-y: auto;
}
.machineShowBox input {
  border: none;
}
.machineShowContainer {
  margin-top: 1rem;
  margin-left: 1rem;
  width: 31%;
  height: 12.5rem;
  border: 1px solid #000;
  box-shadow: 2px 2px 5px #000;
}
.machineShowContainer span {
  display: inline-block;
  height: @lineHeight;
  width: 4rem;
  line-height: @lineHeight;
}
.mInput {
  height: @lineHeight;
  line-height: @lineHeight;
  width: calc(66% - 4rem);
  font-size: .8rem;
}
.lInput {
  height: @lineHeight;
  line-height: @lineHeight;
  width: calc(99% - 4rem);
  font-size: .8rem;
}
.sInput {
  height: @lineHeight;
  line-height: @lineHeight;
  width: calc(33% - 4rem);
  font-size: .8rem;
}
.redword {
  color: red;
  font-size: 1rem;
  font-weight: 700;
}
.boldword {
  font-size: 1rem;
  font-weight: 700;
}

.lastButton {
  margin-right: 0.2rem;
}
.mRight {
  margin-top: 0.1rem;
}
.buttonBox {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  justify-content: flex-end;
}
.funcBox {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.popLable {
  margin-top: 0.5rem;
  line-height: 2.5rem;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 0.5rem;
}
.popInput {
  margin-top: 0.5rem;
  width: 28rem;
}
</style>