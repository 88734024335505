<template>
  <div class="tableLoaderContainer">
    <BodyTitle></BodyTitle>
    <SearchContentForIvenCha @deliverSearchItems="deliverSearchItems" ref="searchRef"></SearchContentForIvenCha>
    <TableShowForDiy @saveActiveItem="saveActiveItem" @clearActive="clearActive" ref="tableShowRef"></TableShowForDiy>

  </div>

</template>

<script>
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import SearchContentForIvenCha from '@/components/Content/MainBodyContent/SearchContentForIvenCha.vue'
import { onUnmounted, watch } from 'vue-demi'
import TableShowForDiy from '@/components/Content/MainBodyContent/TableShowForDiy.vue';
import { useStore } from "vuex";
import DiyShowTable from '@/hooks/DiyShowTable'
import { changeToViewCode, transferToCode } from '@/hooks/OpenOrder';
export default {
  setup() {
    //#region 定义区
    const store = useStore();
    //初始化输入表名称
    const TableName = 'InventoryChangeList';

    const PageName = 'InventoryChangeList'

    let { tableShowRef, searchRef, deliverSearchItems, activeItem, saveActiveItem, clearActive } = DiyShowTable(TableName, PageName)
    //根据表名给searchItemList赋值
    //#endregion

    const getDataFromInvBal = (value) => {
      searchRef.value.searchItems['CommodityId'] = value.value.commodityId
      searchRef.value.searchItems['WareHouseId'] = transferToCode(value.value.wareHouseId, 'WareHouseId')
      setTimeout(() => {
        searchRef.value.searchList()
      }, 100);
    }

    const code = changeToViewCode('InventoryChangeList')

    const comebackFromRoList = (materialName) => {
      searchRef.value.searchItems.CommodityId = ''
      searchRef.value.searchItems.CommodityName = materialName
      setTimeout(() => {
        searchRef.value.searchList()
      }, 100);
    }
    
    
    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          store.commit('modifysearchEle', {
            keyName: 'LinesPerPage',
            valueName: 100
          })
          store.commit('modifysearchEle', {
            keyName: 'Page',
            valueName: 1
          })
          store.commit('modifysearchEle', {
            keyName: 'SortFieldsName',
            valueName: ''
          })
          store.commit('modifysearchEle', {
            keyName: 'SortDirection',
            valueName: -1
          })
        }
      }
    )

    return {
      deliverSearchItems,
      tableShowRef,
      saveActiveItem,
      activeItem, clearActive, searchRef, getDataFromInvBal,
      comebackFromRoList
    }
  },
  components: {
    BodyTitle,
    SearchContentForIvenCha,
    TableShowForDiy

  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
</style>