<template>
  <PopModel ref="popModelRef">
    <div class="popBody">
      <div class="popBodyLayer">
        <div class="popBodyCon1">导入说明</div>
      </div>
      <div class="IndiLayer">1、请先下载导入模板并按模板维护信息</div>
      <div class="IndiLayer">
        2、导入文件要求是excel格式,文件大小不能超过4M
      </div>
    </div>

    <div class="popfooterforUp">
      <!-- 注意修改下载文档名称 -->
      文件上传
      <a href="#" class="a-upload"><input type="file" @change="fileJudge" />点击上传文件<span class="FileName">{{ fileName }}</span></a>
      <a class="DownloadFile" :href="hrefAddress" :download="downLoadName">下载模板</a>
      <div class="uploadbtn" @click="sendUpLoadContent()">开始导入</div>
    </div>
    <div v-show="allLines > 0" class="AllShow">合计: {{allLines}} 条</div>
  </PopModel>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue-demi'
import PopModel from '@/components/Common/PopModel.vue';
import { useStore } from "vuex";
import { readFile } from '@/hooks/tools';
import xlsx from "xlsx";
import { ElMessage } from 'element-plus'
export default {
  components: { PopModel },
  props: ['downLoadFileName', 'pageName','fileName'],
  setup(props, { emit }) {
    const store = useStore();
    const listForValidation = store.state.loginInitializeData.listForValidations
    const positiomList = store.state.loginInitializeData.positiomList
    let fileCheckData = [];

    let allLines = ref(0)

    const fillFileCheckData = () => {
      const list = listForValidation.filter(x => x.tableNameForVali == props.pageName && x.validateMethod == 'add')
      for (let item of list) {
        if (item.upLoadShow == true) {
          let objFileCheck = {}
          objFileCheck.cname = item.showName
          objFileCheck.ename = item.fieldsNameForVali
          fileCheckData.push(objFileCheck)
        }
        for (let index in fileCheckData) {
          fileCheckData[index].po = positiomList[index]
        }
      }
    }
    fillFileCheckData()

    let upLoadContent = [];


    const popModelRef = ref(null)

    let fileName = ref('')

    let fileJudge = (e) => {

      if (e.target.files[0].name.split(".")[1].indexOf("xls") < 0) {
        ElMessage("请选择Excel文件");
        return false;
      }
      fileName.value = e.target.files[0].name;
      let file = e.target.files[0];
      if (!file) {
        return;
      }
      upLoadContent.splice(0)
      readFile(file).then((res) => {
        let workbook = xlsx.read(res, { type: "binary", cellDates: true });
        let worksheet = workbook.Sheets[workbook.SheetNames[0]];
        for (let item of fileCheckData) {
          if (worksheet[item.po].w.indexOf('(') > -1) {
            worksheet[item.po].w = worksheet[item.po].w.split('(')[0]
          }
          if (worksheet[item.po].w == item.cname) {
            worksheet[item.po].w = item.ename;
          } else {

            ElMessage(
              `模板出现错误,表头中${worksheet[item.po].w}应该为${item.cname}`
            );
            fileName.value = '';
            return false;
          }
        }
        let data = xlsx.utils.sheet_to_json(worksheet);
        upLoadContent.push.apply(upLoadContent, data);
        allLines.value = upLoadContent.length
      });
    };


    const path = process.env.BASE_URL

    const hrefAddress = ref(path + 'static/' + props.fileName + '.xlsx')

    const downLoadName = ref(props.downLoadFileName + '.xlsx')

    const sendUpLoadContent = () => {
      if (upLoadContent.length < 1) {
        ElMessage('内容为空')
        return
      }
      emit('uploadFile', upLoadContent)
    }

    const openPopComm = () => {
      allLines.value = 0
      fileName.value = ''
      upLoadContent.splice(0)
      popModelRef.value.openPopComm()
    }

    const closePopComm = () => {
      allLines.value = 0
      fileName.value = ''
      upLoadContent.splice(0)
      popModelRef.value.closePopComm()
    }


    //#endregion

    return {
      popModelRef,
      fileName,
      fileJudge,
      hrefAddress,
      downLoadName,
      sendUpLoadContent,
      openPopComm,
      path,
      allLines,
      closePopComm
    }
  }
}
</script>

<style scoped lang="less">
.popBodyCon1 {
  width: 6.875rem;
  height: 3.75rem;
  line-height: 3.75rem;
  text-indent: 0.625rem;
}
.IndiLayer {
  height: 2rem;
  line-height: 2rem;
  text-indent: 0.625rem;
}
.popfooterforUp {
  height: 3.75rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  line-height: 1.75rem;
}
.a-upload {
  margin-left: 0.625rem;
  padding-left: 0.625rem;
  height: 1.75rem;
  width: 18.75rem;
  line-height: 1.5rem;
  position: relative;
  cursor: pointer;
  color: #888;
  background: #fafafa;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
input {
  border: none;
  outline: none;
}

.FileName {
  padding-left: 0.9rem;
}
.DownloadFile {
  margin-left: 0.625rem;
  height: 1.75rem;
  line-height: 1.75rem;
  color: #2881c7;
}
.uploadbtn {
  height: 1.75rem;
  width: 5rem;
  margin-left: 4rem;
  background-color: #f6f6f6;
  cursor: pointer;
  line-height: 1.75rem;
  text-align: center;
  font-size: 0.875rem;
  color: #000;
  border: 1px solid #dddddd;
}
.uploadbtn:hover {
  background-color: #f08300;
  color: #fff;
}
.popBody div[class^="popBodyLayer"] {
  display: flex;
}

.a-upload input {
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: pointer;
}
.AllShow {
  margin-left: 0.8rem;
}
</style>