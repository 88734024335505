<template>
  <div class="searchBox">
    <label class="searchLable">{{inputName}}:</label>
    <el-select class="m-2" :placeholder="placeHolder" v-model="selectedItem" @change="sendSelcted()">
      <el-option :key="'0' + pageName" label="所有" value="" v-if="chooseAll"></el-option>
      <el-option :key="'-1' + pageName" label="" value="输入使用" disabled><input type="text" @input="searchForResult" class="optionInput" :style="[{width:'8rem'},{height:'1.8rem'}]"></el-option>
      <el-option v-for="item in selectListForShow" :key="item.beforeTransfer + pageName" :label="item.afterTransfer" :value="item.beforeTransfer"></el-option>
    </el-select>
  </div>
</template>

<script>
import { copyArray } from '@/hooks/tools'
import _ from "lodash";
import { reactive, ref } from 'vue-demi';
export default {
  props: [
    'pageName',
    'selectList',
    'inputName',
    'placeHolder',
    'chooseAll',
    'defaultValue'
  ],
  setup(props, { emit }) {
    const selectList = props.selectList
    const pageName = props.pageName
    const inputName = props.inputName
    const placeHolder = props.placeHolder || '请选择'
    const chooseAll = props.chooseAll
    
    let selectedItem = ref('')

    selectedItem.value = props.defaultValue

    let selectListForShow = reactive(copyArray(selectList))

    const searchForResult = _.debounce((e) => {
      const selectListCopy1 = selectList.filter(x => x.afterTransfer.indexOf(e.target.value) > -1)

      selectListForShow.splice(0)
      for (let item of selectListCopy1) {
        selectListForShow.push(item)
      }
    }, 500)

    const sendSelcted = () => {
      emit('getChooseResult',selectedItem.value)
    }

    //#endregion

    return {
      pageName,
      inputName,
      selectListForShow,
      searchForResult,
      chooseAll,
      placeHolder,
      selectedItem,
      sendSelcted
    }
  }
}
</script>

<style scoped lang="less">
.searchBox {
  	display: flex;
	align-items: center;    /**子view垂直居中*/
	vertical-align: center; /**垂直居中*/
	justify-content: center; /**内容居中*/
	text-align: center;
}
.searchLable {
  margin-right: .5rem;
}
</style>