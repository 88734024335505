<template>
  <div class="inputLoaderContainer">
    <BodyTitle></BodyTitle>
    <InputHead ref="soInputHeadRef"></InputHead>
    <InputBody ref="inputBodyRef" @countValueAfterDiscount="countValueAfterDiscount" @clearScanIn="clearScanIn">
    </InputBody>
    <div class="EditTableLine">
      <ComScanIn :scanInValue="scanInValue" @scanIn="scanIn"></ComScanIn>
      <div class="InputForChooseContainer far"><label class="InputForChooseLabel"
          for="discountAmount">优惠金额:</label><input class="InputForChooseshort" id="discountAmount" type="number"
          v-model.lazy="dicountValue" @blur="checkDiscount"></div>
      <span class="ForthLineText">优惠后金额:<b> {{ valueAfterDiscount }}</b></span>
    </div>
    <div class="EditTableLastLine">
      <!-- 维护第七层的内容 -->
      <div class="EditTableFuncButton" :style="{ background: '#73a6a3' }" @click="openPopUp()">导入订单明细</div>
      <div class="EditTableFuncButton" :style="{ background: '#fbb80d' }" @click="openList('SOMI')">销售并收款</div>
      <div class="EditTableFuncButton" v-show="searchItemList.documentContent.PartBCompanyId != '散客'"
        @click="openList('SO')">销售并记账</div>
    </div>
    <UpPop ref="upPopRef" @uploadData="uploadData"></UpPop>
  </div>
</template>
<script>
import { computed, provide, ref, watch } from 'vue-demi'
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import InputHead from '@/components/Content/MainBodyContent/InputHead.vue'
import InputBody from '@/components/Content/MainBodyContent/InputBody.vue'
import UpPop from '@/components/Common/GlobalPop/UpPop.vue'
import { getDocNo, transferToCode, getLoginData, copyArrayDoc, updateWarning, deleteDocCenter, openDocCenter, openSoList, checkDocCondition, clearTableContent, openGrList, openMiList, copyOrder } from '@/hooks/OpenOrder'
import { transferLoginDataOnly } from '@/hooks/loginAffair';
import ComScanIn from '@/components/Content/FunctionUnit/ComScanIn.vue'
import InputTable from '@/hooks/InputTable'
import { copyObj, isRealNum } from '@/hooks/tools'
import { useStore } from "vuex";
import { ElMessageBox } from 'element-plus'
import { ElMessage } from 'element-plus'
export default {
  components: {
    BodyTitle,
    InputHead,
    InputBody,
    UpPop,
    ComScanIn
  },
  setup(props, { emit }) {
    const store = useStore();
    const RootTableName = 'SoList';
    let { itemListHead, itemListBody, searchItemList } = InputTable(RootTableName)
    searchItemList.docTitleTwo = 'SO'
    searchItemList.TableName = 'SoInput'
    provide('searchItemList', searchItemList)

    //#region 采购优惠组件
    let inputBodyRef = ref(null)
    const countValueAfterDiscount = (value) => {
      valueReceived.value = value.value.Amount
    }
    let dicountValue = ref(0)

    let valueReceived = ref(0)

    let valueAfterDiscount = computed(() => {
      return valueReceived.value - dicountValue.value + '元'
    })
    //#endregion

    const soInputHeadRef = ref(null)

    setTimeout(() => {
      soInputHeadRef.value.searchItemList.documentContent.PartBCompanyId = '散客'
      soInputHeadRef.value.searchItemList.documentContent.OutWareHouseId = '主仓库'
      // soInputHeadRef.value.searchItemList.documentContent.OutWareHouseId = '主仓库'
    }, 0);



    //#region 扫描组件
    let scanInValue = ref('');

    const scanIn = (res) => {
      inputBodyRef.value.scanInData(res)
    }

    const clearScanIn = () => {
      scanInValue.value = '';
    }

    const checkDiscount = () => {
      if (dicountValue.value > valueReceived.value || dicountValue.value < 0) {
        ElMessage('优惠金额不能大于订单金额或为负数')
        dicountValue.value = 0
      }
      else {
        valueAfterDiscount = valueReceived.value - dicountValue.value + '元'
      }
    }
    //#endregion

    //#region 上传模块
    let upPopRef = ref(null)
    const openPopUp = () => {
      upPopRef.value.openPopUp()
    }

    const uploadData = (dataUpload) => {
      inputBodyRef.value.fillUploadData(dataUpload)
    }
    //#endregion

    const copyOrderFromDocCenter = (value) => {
      clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
      copyOrder(value, searchItemList.documentContent, inputBodyRef.value.AddContentBody, searchItemList.addBodyContent).then(res => {

      })
    }

    //#region 开单功能模块

    const openList = (typeName) => {

      let discount = dicountValue.value;

      let errCheck = checkDocCondition(searchItemList.documentContent, itemListHead, inputBodyRef.value.AddContentBody, itemListBody)

      if (errCheck.headErr) {

        ElMessage(errCheck.headErr)
        return
      }
      if (errCheck.bodyErr) {

        ElMessage(errCheck.bodyErr)
        return
      }

      ElMessageBox.confirm(
        '请选择发货方式',
        '开单选项',
        {
          confirmButtonText: '直接发货',
          cancelButtonText: '放入等待发货清单',
          type: 'warning',
          center: true,
        }
      ).then(() => {
        getLoginData().then(res => {
          //刷新初始化数据
          if (res != 0) {
            transferLoginDataOnly(res)
          }
          getDocNo(searchItemList.docTitleTwo).then(res => {
            const soHead = res
            const partBCom = transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId')
            const OutWareHouseName = isRealNum(searchItemList.documentContent.OutWareHouseId) ? transferToName(searchItemList.documentContent.OutWareHouseId, 'WareHouseId') : searchItemList.documentContent.OutWareHouseId
            const partACom = store.state.loginInitializeData.companyId
            let soAmount = 0
            let CommodityNameArr = []
            for (let item of inputBodyRef.value.AddContentBody) {
              if (item.CommodityName) {
                item.PartACompanyId = partACom
                item.PartBCompanyId = partBCom
                item.InStockQty = item.OrderQty
                item.DocumentId = soHead
                item.RootSourceDocumentId = soHead
                item.Remark += `销售了${item.OrderQty}个${item.CommodityName}`
                soAmount += item.OrderQty * item.UnitPrice
                if (!item.VirtualGoods && item.CommodityId) {
                  CommodityNameArr.push(item.CommodityId)
                }
              }
            }
            openSoList(inputBodyRef.value.AddContentBody, searchItemList.documentContent, soHead, true).then(res => {
              if (res != 'pass') {
                ElMessage(res)
                deleteDocCenter(soHead).then(res => {
                  return false;
                })
              }
              else {
                if (OutWareHouseName == '主仓库') {
                  setTimeout(() => {
                    updateWarning(partACom, CommodityNameArr)
                  }, 0);
                }
                if (discount > 0) {
                  getDocNo('GR').then(res => {
                    const grHead = res
                    let docHeadCopy2 = copyObj(searchItemList.documentContent)
                    docHeadCopy2.DocumentId = grHead
                    docHeadCopy2.DocumentTypeName = '应收减少单'
                    docHeadCopy2.PartACompanyId = partACom
                    docHeadCopy2.RootSourceDocumentId = soHead
                    docHeadCopy2.SourceDocumentId = soHead
                    docHeadCopy2.Remark = '销售折扣'
                    docHeadCopy2.OutWareHouseId = ''
                    docHeadCopy2.Amount = discount
                    docHeadCopy2.SystemStatusId = 104
                    let grBody = [{
                      DocumentId: grHead,
                      Sn: 1,
                      PartACompanyId: partACom,
                      PartBCompanyId: partBCom,
                      OrderQty: 1,
                      InStockQty: 1,
                      OutStockQty: 1,
                      UnitPrice: discount,
                      Remark: '销售折扣',
                      SourceDocumentId: soHead,
                      RootSourceDocumentId: soHead
                    }]
                    openGrList(grBody, docHeadCopy2, grHead).then(res => {
                      if (typeName == 'SOMI') {
                        getDocNo('MI').then(res => {
                          const miHeadCode = res
                          let miHead = copyObj(searchItemList.documentContent)
                          miHead.DocumentId = miHeadCode
                          miHead.DocumentTypeName = '收款单'
                          miHead.PartACompanyId = partACom
                          miHead.RootSourceDocumentId = soHead
                          miHead.SourceDocumentId = soHead
                          miHead.Remark = '自动开单'
                          miHead.OutWareHouseId = ''
                          miHead.CapitalAccountId = '主账户'
                          miHead.SystemStatusId = 105
                          let miBody = [{
                            DocumentId: miHeadCode,
                            Sn: 1,
                            PartACompanyId: partACom,
                            PartBCompanyId: partBCom,
                            OrderQty: 1,
                            InStockQty: 1,
                            OutStockQty: 1,
                            UnitPrice: soAmount - discount,
                            Amount: soAmount - discount,
                            Remark: '自动开单',
                            SourceDocumentId: soHead,
                            RootSourceDocumentId: soHead
                          }]
                          openMiList(miBody, miHead, res).then(res => {
                            ElMessage({
                              message: '开单成功',
                              duration: 2000,
                              showClose: true
                            })
                            clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                            dicountValue.value = 0
                          })
                        })
                      }
                      else {
                        ElMessage({
                          message: '开单成功',
                          duration: 2000,
                          showClose: true
                        })
                        clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                        dicountValue.value = 0
                        emit('refreshMsg')
                      }
                    })
                  })
                }
                else {
                  if (typeName == 'SOMI') {
                    getDocNo('MI').then(res => {
                      let miHeadCode = res
                      let miHead = copyObj(searchItemList.documentContent)
                      miHead.DocumentId = miHeadCode
                      miHead.DocumentTypeName = '收款单'
                      miHead.PartACompanyId = partACom
                      miHead.RootSourceDocumentId = soHead
                      miHead.SourceDocumentId = soHead
                      miHead.Remark = '自动开单'
                      miHead.OutWareHouseId = ''
                      miHead.CapitalAccountId = '主账户'
                      miHead.Amount = soAmount
                      miHead.SystemStatusId = 105
                      let miBody = [{
                        DocumentId: miHeadCode,
                        Sn: 1,
                        PartACompanyId: partACom,
                        PartBCompanyId: partBCom,
                        OrderQty: 1,
                        InStockQty: 1,
                        OutStockQty: 1,
                        UnitPrice: soAmount,
                        Amount: soAmount,
                        Remark: '自动开单',
                        SourceDocumentId: soHead,
                        RootSourceDocumentId: soHead
                      }]
                      openMiList(miBody, miHead, res).then(res => {
                        ElMessage({
                          message: '开单成功',
                          duration: 2000,
                          showClose: true
                        })
                        clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                        dicountValue.value = 0
                        emit('refreshMsg')
                      })
                    })
                  }
                  else {

                    ElMessage({
                      message: '开单成功',
                      duration: 2000,
                      showClose: true
                    })
                    clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                    dicountValue.value = 0
                    emit('refreshMsg')
                  }
                }
              }

            })
          })
        }).catch(err => {

          ElMessage(err.message)
        })
      }).catch(() => {
        getLoginData().then(res => {
          //刷新初始化数据
          if (res != 0) {
            transferLoginDataOnly(res)
          }
          getDocNo(searchItemList.docTitleTwo).then(res => {
            const soHead = res
            const partBCom = transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId')
            const OutWareHouseName = isRealNum(searchItemList.documentContent.OutWareHouseId) ? transferToName(searchItemList.documentContent.OutWareHouseId, 'WareHouseId') : searchItemList.documentContent.OutWareHouseId
            const partACom = store.state.loginInitializeData.companyId
            let soAmount = 0
            let CommodityNameArr = []
            for (let item of inputBodyRef.value.AddContentBody) {
              if (item.CommodityName) {
                item.PartACompanyId = partACom
                item.PartBCompanyId = partBCom
                item.InStockQty = item.OrderQty
                item.DocumentId = soHead
                item.RootSourceDocumentId = soHead
                item.Remark += `销售了${item.OrderQty}个${item.CommodityName}`
                soAmount += item.OrderQty * item.UnitPrice
                if (!item.VirtualGoods && item.CommodityId) {
                  CommodityNameArr.push(item.CommodityId)
                }
              }
            }
            openSoList(inputBodyRef.value.AddContentBody, searchItemList.documentContent, soHead, false).then(res => {
              if (res != 'pass') {
                ElMessage(res)
                deleteDocCenter(soHead).then(res => {
                  return false;
                })
              }
              else {
                if (OutWareHouseName == '主仓库') {
                  setTimeout(() => {
                    updateWarning(partACom, CommodityNameArr)
                  }, 0);
                }
                if (discount > 0) {
                  getDocNo('GR').then(res => {
                    const grHead = res
                    let docHeadCopy2 = copyObj(searchItemList.documentContent)
                    docHeadCopy2.DocumentId = grHead
                    docHeadCopy2.DocumentTypeName = '应收减少单'
                    docHeadCopy2.PartACompanyId = partACom
                    docHeadCopy2.RootSourceDocumentId = soHead
                    docHeadCopy2.SourceDocumentId = soHead
                    docHeadCopy2.Remark = '销售折扣'
                    docHeadCopy2.OutWareHouseId = ''
                    docHeadCopy2.Amount = discount
                    docHeadCopy2.SystemStatusId = 104
                    let grBody = [{
                      DocumentId: grHead,
                      Sn: 1,
                      PartACompanyId: partACom,
                      PartBCompanyId: partBCom,
                      OrderQty: 1,
                      InStockQty: 1,
                      OutStockQty: 1,
                      UnitPrice: discount,
                      Remark: '销售折扣',
                      SourceDocumentId: soHead,
                      RootSourceDocumentId: soHead
                    }]
                    openGrList(grBody, docHeadCopy2, grHead).then(res => {
                      if (typeName == 'SOMI') {
                        getDocNo('MI').then(res => {
                          const miHeadCode = res
                          let miHead = copyObj(searchItemList.documentContent)
                          miHead.DocumentId = miHeadCode
                          miHead.DocumentTypeName = '收款单'
                          miHead.PartACompanyId = partACom
                          miHead.RootSourceDocumentId = soHead
                          miHead.SourceDocumentId = soHead
                          miHead.Remark = '自动开单'
                          miHead.OutWareHouseId = ''
                          miHead.CapitalAccountId = '主账户'
                          miHead.SystemStatusId = 105
                          let miBody = [{
                            DocumentId: miHeadCode,
                            Sn: 1,
                            PartACompanyId: partACom,
                            PartBCompanyId: partBCom,
                            OrderQty: 1,
                            InStockQty: 1,
                            OutStockQty: 1,
                            UnitPrice: soAmount - discount,
                            Amount: soAmount - discount,
                            Remark: '自动开单',
                            SourceDocumentId: soHead,
                            RootSourceDocumentId: soHead
                          }]
                          openMiList(miBody, miHead, res).then(res => {
                            ElMessage({
                              message: '开单成功',
                              duration: 2000,
                              showClose: true
                            })
                            clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                            dicountValue.value = 0
                          })
                        })
                      }
                      else {
                        ElMessage({
                          message: '开单成功',
                          duration: 2000,
                          showClose: true
                        })
                        clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                        dicountValue.value = 0
                        emit('refreshMsg')
                      }
                    })
                  })
                }
                else {
                  if (typeName == 'SOMI') {
                    getDocNo('MI').then(res => {
                      let miHeadCode = res
                      let miHead = copyObj(searchItemList.documentContent)
                      miHead.DocumentId = miHeadCode
                      miHead.DocumentTypeName = '收款单'
                      miHead.PartACompanyId = partACom
                      miHead.RootSourceDocumentId = soHead
                      miHead.SourceDocumentId = soHead
                      miHead.Remark = '自动开单'
                      miHead.OutWareHouseId = ''
                      miHead.CapitalAccountId = '主账户'
                      miHead.Amount = soAmount
                      miHead.SystemStatusId = 105
                      let miBody = [{
                        DocumentId: miHeadCode,
                        Sn: 1,
                        PartACompanyId: partACom,
                        PartBCompanyId: partBCom,
                        OrderQty: 1,
                        InStockQty: 1,
                        OutStockQty: 1,
                        UnitPrice: soAmount,
                        Amount: soAmount,
                        Remark: '自动开单',
                        SourceDocumentId: soHead,
                        RootSourceDocumentId: soHead
                      }]
                      openMiList(miBody, miHead, res).then(res => {
                        ElMessage({
                          message: '开单成功',
                          duration: 2000,
                          showClose: true
                        })
                        clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                        dicountValue.value = 0
                        emit('refreshMsg')
                      })
                    })
                  }
                  else {
                    ElMessage({
                      message: '开单成功',
                      duration: 2000,
                      showClose: true
                    })
                    clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                    dicountValue.value = 0
                    emit('refreshMsg')
                  }
                }
              }

            })
          })
        }).catch(err => {

          ElMessage(err.message)
        })
      })
    }
    //#endregion

    //#endregion

    return {
      searchItemList,
      dicountValue,
      valueAfterDiscount,
      inputBodyRef,
      countValueAfterDiscount,
      scanInValue,
      scanIn,
      clearScanIn,
      openPopUp,
      upPopRef,
      uploadData,
      openList,
      checkDiscount, soInputHeadRef, copyOrderFromDocCenter
    }
  }
}
</script>
<style scoped lang="less">
b {
  color: red;
}
</style>