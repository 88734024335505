<template>
  <div class="totalContainer">
    <div class="firstContainer">
      <div class="AccountChooseBox">
        <label class="bigLable">选择账号:</label>
        <el-select v-model="rdsInfo.UserId" class="m-2" placeholder="Select" @change="changeUser()">
          <el-option v-for="item in UserFilter" :key="item.beforeTransfer + 'Authority'" :label="item.afterTransfer" :value="item.beforeTransfer">
          </el-option>
        </el-select>
        <label class="bigLable2">选取角色:</label>
        <el-select v-model="copyId" class="m-2" placeholder="Select">
          <el-option v-for="item in toBeCopyList" :key="item.roleId + 'CopyAutho'" :label="item.roleName" :value="item.roleId">
          </el-option>
        </el-select>
        <el-button class="firstButton" type="primary" plain @click="copyAutoOnly()">复制功能权限并覆盖</el-button>
        <el-button type="success" plain @click="addAuto()">添加复制对象功能权限</el-button>
        <el-button type="success" plain @click="updateAllXlStoreAuth()" v-show="isXl">更新小懒门店人员权限</el-button>
      </div>
    </div>
    <div class="secondContainer"></div>
    <div class="thirdContainer">
      <div class="funcModel" @click="setFuncModel(101)" :class="{'activeModel':currentShow == 101}">登录权限</div>
      <div class="funcModel" @click="setFuncModel(102)" :class="{'activeModel':currentShow == 102}">仓库权限</div>
      <div class="funcModel" @click="setFuncModel(103)" :class="{'activeModel':currentShow == 103}">往来单位权限</div>
      <div class="funcModel" @click="setFuncModel(104)" :class="{'activeModel':currentShow == 104}">功能权限</div>
      <div class="funcModel" @click="setFuncModel(105)" :class="{'activeModel':currentShow == 105}">其他权限</div>
    </div>
    <div class="forthContainer">
      <div class="LoginFuncBox" v-show="currentShow == 101">
        <div class="loginfuncContainer">
          <label class="loginLabel">允许登录:</label>
          <el-switch :disabled="rdsInfo.IsMainAccount" v-model="rdsInfo.IsAllowRegister" size="large" />
        </div>
        <div class="loginfuncContainer">
          <label class="loginLabel">用户姓名:</label>
          <el-input :style="{width:'20rem'}" v-model="rdsInfo.UserName" placeholder="请输入账号" :disabled="!rdsInfo.IsAllowRegister" />
        </div>
        <div class="loginfuncContainer">
          <label class="loginLabel">登录密码:</label>
          <el-input :style="{width:'20rem'}" v-model="rdsInfo.Password" type="password" placeholder="请输入密码" :disabled="!rdsInfo.IsAllowRegister" show-password />
        </div>
        <el-button class="loginButton" type="primary" plain @click="saveLoginDate()">保存</el-button>
      </div>

      <div class="WarehouseFuncBox" v-show="currentShow == 102">
        <div class="loginfuncContainer">
          <label class="loginLabel">所有仓库可见:</label>
          <el-switch :disabled="rdsInfo.IsMainAccount" v-model="authoList.userConfigLists.seeAllWarehouse" size="large" />
          <label class="selectAllLable" v-show="!authoList.userConfigLists.seeAllWarehouse">全选/全不选:</label>
          <el-switch :disabled="rdsInfo.IsMainAccount" v-show="!authoList.userConfigLists.seeAllWarehouse" v-model="selectAllWareHouse" size="large" />
        </div>
        <div class="titleLayer" v-if="!authoList.userConfigLists.seeAllWarehouse">
          <div class="titleElementBoxFirst"><span class="warehouseIcon"></span><b>仓库名称</b></div>
          <div class="titleElementBox"><span class="warehouseIcon"></span><b>是否可见</b></div>
          <div class="titleElementBox"><span class="warehouseIcon"></span><b>入库权限</b></div>
          <div class="titleElementBox"><span class="warehouseIcon"></span><b>出库权限</b></div>
        </div>
        <div class="conetentLayer" v-if="!authoList.userConfigLists.seeAllWarehouse">
          <el-scrollbar height="30rem">
            <div v-for="(item,index) in authoList.warehouseAuthorities" :key="item.warehouseAuthorityId + 'warehouse'" class="scrollbar-demo-item">
              <div class="contentElementBoxFirst">{{item.wareHouseName}}</div>
              <div class="contentElementBox">
                <el-switch :disabled="rdsInfo.IsMainAccount" v-model="authoList.warehouseAuthorities[index].visibleOrNot" size="large" />
              </div>
              <div class="contentElementBox">
                <el-switch :disabled="rdsInfo.IsMainAccount" v-model="authoList.warehouseAuthorities[index].inAuthority" size="large" />
              </div>
              <div class="contentElementBox">
                <el-switch :disabled="rdsInfo.IsMainAccount" v-model="authoList.warehouseAuthorities[index].outAuthority" size="large" />
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="buttonlayer">
          <el-button class="warehouseButton" type="primary" plain @click="saveWarehouseAutho()">保存</el-button>
        </div>
      </div>
      <div class="BussinessParterFuncBox" v-show="currentShow == 103">
        <div class="loginfuncContainer">
          <label class="loginLabel">所有往来单位可见:</label>
          <el-switch :disabled="rdsInfo.IsMainAccount" v-model="authoList.userConfigLists.seeAllBusinessPatner" size="large" />
          <label class="selectAllLable" v-show="!authoList.userConfigLists.seeAllBusinessPatner">全选/全不选:</label>
          <el-switch :disabled="rdsInfo.IsMainAccount" v-show="!authoList.userConfigLists.seeAllBusinessPatner" v-model="selectAllBp" size="large" />
        </div>
        <div class="titleLayer" v-if="!authoList.userConfigLists.seeAllBusinessPatner">
          <div class="titleElementBoxFirst"><span class="warehouseIcon"></span><b>往来单位</b></div>
          <div class="titleElementBox"><span class="warehouseIcon"></span><b>是否可见</b></div>
        </div>
        <div class="conetentLayer" v-if="!authoList.userConfigLists.seeAllBusinessPatner">
          <el-scrollbar height="30rem">
            <div v-for="(item,index) in authoList.businessPartnerAuthorities" :key="item.partBCompanyId + 'businessPater'" class="scrollbar-demo-item">
              <div class="contentElementBoxFirst">{{item.partBCompanyName}}</div>
              <div class="contentElementBox">
                <el-switch :disabled="rdsInfo.IsMainAccount" v-model="authoList.businessPartnerAuthorities[index].visibleOrNot" size="large" />
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="buttonlayer">
          <el-button class="BpButton" type="primary" plain @click="saveBpAutho()">保存</el-button>
        </div>
      </div>
      <div class="PageFuncBox" v-show="currentShow == 104">
        <div class="funcContentBox">
          <div class="MainLayer" v-for="(item,index) in authoList.mainManuManages" :key="item.mainManuCode + 'mainmanu'">
            <div class="manuTitleLayer" @click="saveActiveMainmanuCode(item.mainManuCode)"><b>{{item.mainManuName}}</b>
              <el-switch @click.stop :disabled="rdsInfo.IsMainAccount" v-model="authoList.mainManuManages[index].visibleOrNot" @change="checkMainManu(index)" size="large" />
              <span v-show="activeMainmanuCode != item.mainManuCode" class="UpDownIcon"></span><span class="UpDownIcon" v-show="activeMainmanuCode == item.mainManuCode"></span>
            </div>
            <transition name="fade-mainMenu" v-for="item1 in authoList.viewVisibleManages.filter(x=>x.mainManuCode == item.mainManuCode)" :key="item1.viewCode + 'view'" v-show="activeMainmanuCode == item.mainManuCode">
              <div class="viewLayerContainer">
                <div class="viewTitleLayer"><b>{{item1.viewName}}</b>
                  <el-switch :disabled="rdsInfo.IsMainAccount || !item.visibleOrNot" @change="checkView(item1)" v-model="authoList.viewVisibleManages.find(x=>x.viewCode == item1.viewCode).visibleOrNot" size="large" />
                </div>
                <div class="buttonBox">
                  <div class="buttonContainer" v-for="item2 in authoList.buttonVisibleManages.filter(x=>x.viewCode == item1.viewCode)" :key="item2.buttonCode + 'button'" v-show="activeMainmanuCode == item.mainManuCode">
                    <b class="butionNameB">{{item2.buttonName}}</b>
                    <el-switch :disabled="rdsInfo.IsMainAccount || !item1.visibleOrNot" v-model="authoList.buttonVisibleManages.find(x=>x.buttonCode == item2.buttonCode).visibleOrNot" size="large" />
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="buttonlayer">
          <el-button class="funcButton" type="primary" plain @click="saveFunc()">保存</el-button>
        </div>
      </div>
      <div class="OtherFuncBox" v-show="currentShow == 105">
        <div class="loginfuncContainer">
          <label class="loginLabel">成本可见:</label>
          <el-switch :disabled="rdsInfo.IsMainAccount" v-model="authoList.costVisibilityManges.visibleForCost" size="large" />
        </div>
        <div class="buttonlayer">
          <el-button class="OtherButton" type="primary" plain @click="saveCostVisibility()">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, reactive, ref, watch } from 'vue-demi'
import { useStore } from "vuex";
import { copyObj } from '@/hooks/tools'
import {
  getUserInfo, updateLoginInfo, getUserAuto, transferToName, updateWarehouseAuth,
  updateBpAuth, updateSeeAllWarehouse, updateSeeAllBp, updateCostVisibility,
  updateMainManuAuth, updateViewAuth, updateButtonAuth, getTobeCopyList
} from "@/hooks/OpenOrder"
import requestData from '@/hooks/requestData';
import { ElMessage } from 'element-plus'
export default {
  setup() {
    const store = useStore();
    const filterList = store.state.loginInitializeData.filterList

    const UserFilter = filterList.UserId

    const manuFilter = store.state.loginInitializeData.authList.mainManuLists

    const viewFilter = store.state.loginInitializeData.authList.viewLists

    const buttonFilter = store.state.loginInitializeData.authList.viewButtonLists

    const partACom = store.state.loginInitializeData.companyId

    let isXl = ref(false)

    let currentShow = ref(101)

    const setFuncModel = (funcNumber) => {
      currentShow.value = funcNumber
    }

    let toBeCopyList = reactive([])

    let copyId = ref('')

    const fillToBeCopyList = () => {
      if (rdsInfo.UserId == '' || rdsInfo.IsMainAccount) {
        toBeCopyList.splice(0)
        return;
      }
      getTobeCopyList(partACom).then(res => {
        toBeCopyList.splice(0)
        if (res.length == 0) {
          return;
        }
        for (let item of res) {
          if (item.roleId == rdsInfo.UserId) {
            continue;
          }
          let b = copyObj(item)
          toBeCopyList.push(b)
        }
      })
    }


    const updateAllXlStoreAuth = () => {
      const config = {
        method: "get",
        url: "/MainManuManage/UpdateAllXlStoreAuth",
      };
      
      return requestData.GetData(config).then(res=>{

      })
    }

    //#region 用户信息修改
    let rdsInfo = reactive({
      UserId: '',
      CompanyId: '',
      IsAllowRegister: false,
      IsMainAccount: false,
      UserName: '',
      Password: '',
    })

    let rdsInfoCopy = null

    let authoList = reactive({
      mainManuManages: [],
      buttonVisibleManages: [],
      viewVisibleManages: [],
      warehouseAuthorities: [],
      businessPartnerAuthorities: [],
      costVisibilityManges: {
        visibleForCost: false
      },
      seeAllSuppliersManages: {
        seeAllSuppliers: false
      },
      userConfigLists: {
        seeAllWarehouse: false,
        seeAllBusinessPatner: false
      }
    })

    let authoListCopy = {
      visibleForCost: false,
      seeAllSuppliers: false,
      seeAllWarehouse: false,
      seeAllBusinessPatner: false
    }

    const changeUser = () => {

      getUserInfo(rdsInfo.UserId).then(res => {
        rdsInfoCopy = res
        rdsInfo.CompanyId = res.companyId
        rdsInfo.IsAllowRegister = res.isAllowRegister
        rdsInfo.IsMainAccount = res.isMainAccount
        rdsInfo.UserName = res.userName
        rdsInfo.Password = res.password
        fillToBeCopyList()
        getUserAuto(rdsInfo.UserId).then(res => {
          authoList.mainManuManages = res.mainManuManages
          authoList.buttonVisibleManages = res.buttonVisibleManages
          authoList.viewVisibleManages = res.viewVisibleManages
          authoList.warehouseAuthorities = res.warehouseAuthorities
          authoList.businessPartnerAuthorities = res.businessPartnerAuthorities
          authoList.costVisibilityManges.visibleForCost = res.costVisibilityManges[0]?.visibleForCost
          authoList.seeAllSuppliersManages.seeAllSuppliers = res.seeAllSuppliersManages[0]?.seeAllSuppliers
          authoList.userConfigLists.seeAllWarehouse = res.userConfigLists[0]?.seeAllWarehouse
          authoList.userConfigLists.seeAllBusinessPatner = res.userConfigLists[0]?.seeAllBusinessPatner
          authoListCopy.visibleForCost = res.costVisibilityManges[0]?.visibleForCost
          authoListCopy.seeAllSuppliers = res.seeAllSuppliersManages[0]?.seeAllSuppliers
          authoListCopy.seeAllWarehouse = res.userConfigLists[0]?.seeAllWarehouse
          authoListCopy.seeAllBusinessPatner = res.userConfigLists[0]?.seeAllBusinessPatner
          for (let item of authoList.warehouseAuthorities) {
            item.wareHouseName = transferToName(item.wareHouseId, 'WareHouseId')
          }
          for (let item of authoList.businessPartnerAuthorities) {
            item.partBCompanyName = transferToName(item.partBCompanyId, 'PartBCompanyId')
          }
          for (let item of authoList.mainManuManages) {
            item.mainManuName = manuFilter.find(x => x.mainManuCode == item.mainManuCode).mainManuName
          }
          for (let item of authoList.viewVisibleManages) {
            item.mainManuCode = viewFilter.find(x => x.viewCode == item.viewCode).mainManuCode
            item.viewName = viewFilter.find(x => x.viewCode == item.viewCode).viewName
          }
          for (let item of authoList.buttonVisibleManages) {
            item.viewCode = buttonFilter.find(x => x.buttonCode == item.buttonCode).viewCode
            item.buttonName = buttonFilter.find(x => x.buttonCode == item.buttonCode).buttonName
          }

        })
      }).catch(err => {
        ElMessage(err)

      })
    }

    const copyAutoOnly = () => {

      if (!copyId.value) {

        ElMessage('请选择复制对象')
        return;
      }
      if (!rdsInfo.UserId) {

        ElMessage('请选择账号')
        return;
      }
      getUserAuto(copyId.value).then(res => {
        for (let item of authoList.mainManuManages) {
          item.visibleOrNot = res.mainManuManages.find(x => x.mainManuCode == item.mainManuCode)?.visibleOrNot || false
        }
        for (let item of authoList.buttonVisibleManages) {
          item.visibleOrNot = res.buttonVisibleManages.find(x => x.buttonCode == item.buttonCode)?.visibleOrNot || false
        }
        for (let item of authoList.viewVisibleManages) {
          item.visibleOrNot = res.viewVisibleManages.find(x => x.viewCode == item.viewCode)?.visibleOrNot || false
        }
        saveFunc()

      }).catch(err => {

        ElMessage(err)
      })
    }

    const addAuto = () => {

      if (!copyId.value) {

        ElMessage('请选择复制对象')
        return;
      }
      if (!rdsInfo.UserId) {

        ElMessage('请选择账号')
        return;
      }
      getUserAuto(copyId.value).then(res => {
        for (let item of authoList.mainManuManages) {
          if (res.mainManuManages.find(x => x.mainManuCode == item.mainManuCode)?.visibleOrNot) {
            item.visibleOrNot = true
          }
        }
        for (let item of authoList.buttonVisibleManages) {
          if (res.buttonVisibleManages.find(x => x.buttonCode == item.buttonCode)?.visibleOrNot) {
            item.visibleOrNot = true
          }
        }
        for (let item of authoList.viewVisibleManages) {
          if (res.viewVisibleManages.find(x => x.viewCode == item.viewCode)?.visibleOrNot) {
            item.visibleOrNot = true
          }
        }
        saveFunc()

      }).catch(err => {

        ElMessage(err)
      })
    }


    const saveLoginDate = () => {

      if (rdsInfo.IsAllowRegister == rdsInfoCopy.isAllowRegister && rdsInfo.UserName == rdsInfoCopy.userName && rdsInfo.Password == rdsInfoCopy.password) {

        ElMessage('无修改内容')
        return;
      }
      let b = confirm('确认修改信息')
      if (!b) {

        return;
      }
      updateLoginInfo(rdsInfo).then(res => {
        if (res != "pass") {

          ElMessage('更新失败')
          return;
        }

        ElMessage('更新成功')
        changeUser()
      }).catch(err => {

        ElMessage(err)
      })
    }

    let activeMainmanuCode = ref('')

    const saveActiveMainmanuCode = (mainmanuCode) => {
      if (activeMainmanuCode.value == mainmanuCode) {
        activeMainmanuCode.value = ''
      }
      else {
        activeMainmanuCode.value = mainmanuCode
      }

    }

    let selectAllWareHouse = ref(false)

    watch(selectAllWareHouse, (newValue, oldValue) => {
      if (newValue) {
        for (let item of authoList.warehouseAuthorities) {
          item.inAuthority = true
          item.outAuthority = true
          item.visibleOrNot = true
        }
      }
      else {
        for (let item of authoList.warehouseAuthorities) {
          item.inAuthority = false
          item.outAuthority = false
          item.visibleOrNot = false
        }
      }
    })

    let selectAllBp = ref(false)

    watch(selectAllBp, (newValue, oldValue) => {
      if (newValue) {
        for (let item of authoList.businessPartnerAuthorities) {
          item.visibleOrNot = true
        }
      }
      else {
        for (let item of authoList.businessPartnerAuthorities) {
          item.visibleOrNot = false
        }
      }
    })

    const saveWarehouseAutho = () => {

      if (authoList.userConfigLists.seeAllWarehouse && !authoListCopy.seeAllWarehouse) {
        for (let item of authoList.warehouseAuthorities) {
          item.inAuthority = true
          item.outAuthority = true
          item.visibleOrNot = true
        }
      }
      updateWarehouseAuth(authoList.warehouseAuthorities).then(res => {
        if (res != 'pass') {

          ElMessage(res)
          return
        }
        else {
          updateSeeAllWarehouse(rdsInfo.UserId, authoList.userConfigLists.seeAllWarehouse).then(res => {

            ElMessage('更新成功')
            changeUser()
          })
        }
      }).catch(err => {

        ElMessage(err)
      })
    }

    const saveBpAutho = () => {

      if (authoList.userConfigLists.seeAllBusinessPatner && !authoListCopy.seeAllBusinessPatner) {
        for (let item of authoList.businessPartnerAuthorities) {
          item.visibleOrNot = true
        }
      }
      updateBpAuth(authoList.businessPartnerAuthorities).then(res => {
        if (res != 'pass') {

          ElMessage(res)
          return
        }
        else {
          updateSeeAllBp(rdsInfo.UserId, authoList.userConfigLists.seeAllBusinessPatner).then(res => {
            ElMessage('更新成功')
            changeUser()
          })

        }

      }).catch(err => {

        ElMessage(err)
      })
    }

    const saveCostVisibility = () => {
      if (authoList.costVisibilityManges.visibleForCost != authoListCopy.visibleForCost) {
        updateCostVisibility(rdsInfo.UserId, authoList.costVisibilityManges.visibleForCost).then(res => {
          ElMessage('更新成功')
          changeUser()
        })
      }
    }

    const saveFunc = () => {

      updateMainManuAuth(authoList.mainManuManages).then(res => {
        if (res != 'pass') {

          ElMessage(res)
          return
        }
        updateViewAuth(authoList.viewVisibleManages).then(res => {
          updateButtonAuth(authoList.buttonVisibleManages).then(res => {

            ElMessage('更新成功')
            changeUser()
          })
        })
      }).catch(err => {

        ElMessage(err)
      })
    }

    const checkMainManu = (index) => {
      let mainCodeSel = authoList.mainManuManages[index].mainManuCode
      let mainVisi = authoList.mainManuManages[index].visibleOrNot
      let viewList = authoList.viewVisibleManages.filter(x => x.mainManuCode == mainCodeSel)
      let viewCodeList = []
      for (let item of viewList) {
        viewCodeList.push(item.viewCode)
      }
      for (let item1 of viewList) {
        item1.visibleOrNot = mainVisi
      }
      for (let item2 of viewCodeList) {
        let buttonList = authoList.buttonVisibleManages.filter(x => x.viewCode == item2)
        for (let item3 of buttonList) {
          item3.visibleOrNot = mainVisi
        }
      }
    }

    const checkView = (body) => {
      let viewCodeSel = body.viewCode
      let viewVisi = body.visibleOrNot
      let buttonList = authoList.buttonVisibleManages.filter(x => x.viewCode == viewCodeSel)
      for (let item of buttonList) {
        item.visibleOrNot = viewVisi
      }
    }

    return {
      UserFilter, updateAllXlStoreAuth,
      currentShow,
      setFuncModel,
      //#region 用户信息修改
      rdsInfo,
      changeUser,
      saveLoginDate,
      //#endregion
      authoList,
      activeMainmanuCode,
      saveActiveMainmanuCode,
      selectAllWareHouse,
      selectAllBp,
      saveWarehouseAutho,
      saveBpAutho,
      saveCostVisibility,
      saveFunc,
      checkMainManu,
      checkView,
      toBeCopyList, copyId,
      copyAutoOnly,
      addAuto,
      isXl
    }
  }
}
</script>

<style scoped lang="less">
.totalContainer {
  height: 100%;
  width: 100%;
}
.firstContainer {
  height: 10%;
  width: 98%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.secondContainer {
  height: 2%;
  width: 100%;
  background-color: #eeeeee;
}
.thirdContainer {
  height: 1.8rem;
  width: 100%;
  background-color: #eeeeee;
  display: flex;
  justify-content: flex-start;
}
.bigLable {
  font-size: 1rem;
  margin-right: 0.2rem;
}
.AccountChooseBox {
  margin-left: 1rem;
}
.funcModel {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  background-color: #1e87f0;
  height: 100%;
  font-size: 1rem;
  line-height: 1.8rem;
  color: white;
  margin-right: 0.5rem;
  border-radius: 0.2rem;
}

.funcModel:hover {
  cursor: pointer;
  transform: translateY(-1px);
}

.forthContainer {
  height: 85%;
  width: 100%;
}
.loginfuncContainer {
  margin-left: 2rem;
  margin-top: 2rem;
}
.loginLabel {
  margin-right: 2rem;
}
.activeModel {
  background-color: #73a6a3;
}
.loginButton {
  margin-left: 22rem;
  margin-top: 2rem;
}

.warehouseButton {
  margin-top: 2rem;
  margin-left: 35rem;
}

.BpButton {
  margin-top: 2rem;
  margin-left: 24rem;
}
.funcButton {
  margin-top: 2rem;
  margin-left: 70%;
}
.OtherButton {
  margin-top: 2rem;
  margin-left: 20rem;
}

.warehouseIcon {
  font-family: "icomoon";
  margin-right: 0.5rem;
  color: #1e87f0;
}
.UpDownIcon {
  font-family: "icomoon";
  font-size: 1.5rem;
  color: #1e87f0;
  margin-left: 3rem;
}
.titleLayer {
  display: flex;
  margin-left: 1.8rem;
  margin-top: 1.8rem;
  height: 10%;
  line-height: 10%;
  font-size: 1rem;
  transition: all 0.5s;
}
.conetentLayer {
  display: flex;
  margin-left: 1.8rem;
  margin-top: 1.8rem;
  line-height: 10%;
  font-size: 1rem;
  height: 30rem;
  transition: all 0.5s;
}
.buttonlayer {
  height: 3rem;
}

.titleElementBoxFirst {
  width: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contentElementBoxFirst {
  width: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleElementBox {
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contentElementBox {
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollbar-demo-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 3rem;
  margin-top: 0.3rem;
  text-align: center;
  border-radius: 4px;
  background: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
  width: 45rem;
}
.PageFuncBox {
  height: 80%;

  margin-top: 0.6rem;
}
.funcContentBox {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.MainLayer:hover {
  cursor: pointer;
}

.manuTitleLayer {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
  background-color: #dce6f1;
  width: 15rem;
  margin-left: 2rem;
  border-radius: 0.5rem;
}

.viewTitleLayer {
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: flex-start;
  margin-left: 17rem;
  background-color: #ebf1de;
  margin-top: 0.2rem;
  width: 20rem;
  border-radius: 0.5rem;
}

.buttonBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 37rem;
  margin-top: 0.2rem;
  background-color: #fff2cc;
  width: 70rem;
  border-radius: 0.5rem;
}
.manuTitleLayer b {
  font-size: 1.2rem;
  padding-left: 1rem;
  margin-right: 2rem;
}

.buttonContainer b {
  font-size: 1rem;
  padding-left: 1rem;
  margin-right: 0.5rem;
}

.buttonContainer span {
  margin-right: 0.4rem;
}

.viewTitleLayer b {
  display: inline-block;
  width: 8rem;
  margin-left: 2rem;
}
.viewTitleLayer span {
  margin-right: 0.4rem;
}
.fade-mainMenu-enter,
.fade-mainMenu-leave-to {
  transform: translate(0, 100%);
}

.fade-mainMenu-enter-to,
.fade-mainMenu-leave {
  transform: translate(0, 0);
}

.fade-mainMenu-enter-active,
.fade-mainMenu-leave-active {
  transition: all 0.3s;
}
.viewLayerContainer:hover {
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.butionNameB {
  display: inline-block;
  width: 6rem;
}

.selectAllLable {
  margin-left: 5rem;
  margin-right: 2rem;
}
.bigLable2 {
  margin-left: 10rem;
  margin-right: 0.5rem;
}
.firstButton {
  margin-left: 1rem;
}
</style>