<template>
  <PopModel ref="popModelRef">
    <div class="popBody">
      <div class="popBodyLayer">
        <div class="popBodyCon1">导入说明</div>
      </div>
      <div class="IndiLayer">1、请先下载导入模板并按模板维护信息</div>
      <div class="IndiLayer">
        2、导入文件要求是excel格式，文件大小不能超过4M
      </div>
    </div>
    <div class="popfooterforUp">
      <!-- 注意修改下载文档名称 -->
      文件上传
      <a href="#" class="a-upload"><input type="file" @change="fileJudge" />选择导入<span class="FileName">{{ fileName }}</span></a><a class="DownloadFile" href="../../../../static/PoList.xlsx" download="主菜单清单上传模板.xlsx">下载模板</a>
      <div class="uploadbtn" @click="sendUpLoadContent()">开始导入</div>
    </div>
  </PopModel>
</template>

<script>
import PopModel from '@/components/Common/PopModel.vue';
import { inject, provide, ref } from 'vue-demi';
import { readFile } from "@/hooks/tools";
import xlsx from "xlsx";
import { useStore } from "vuex";
export default {
  components: {
    PopModel
  },
  setup(props, { emit }) {
    const store = useStore();
    const tableTitle = ref('批量导入')
    provide('tableTitle', tableTitle)
    let popModelRef = ref(null)
    let searchItemList = inject('searchItemList')

    const openPopUp = () => {
      popModelRef.value.openPopComm()
    }

    const closePopUp = () => {
      popModelRef.value.closePopComm()
    }

    let fileName = ref("");

    let upLoadContent = [];


    const getFileCheckData = () => {
      let targetArr = []
      for (let key in searchItemList) {
        if (searchItemList[key].upLoadShow == true) {
          let nullObj = {}
          nullObj.cname = searchItemList[key].showName
          nullObj.ename = searchItemList[key].fieldsNameForVali
          targetArr.push(nullObj)
        }
      }
      let bArr = store.state.loginInitializeData.positiomList
      for (let i = 0; i < targetArr.length; i++) {
        targetArr[i].po = bArr[i]
      }
      return targetArr
    }

    let fileCheckData = getFileCheckData();

    //f解析上传文件的方法
    let fileJudge = (e) => {
      if (e.target.files[0].name.split(".")[1].indexOf("xls") < 0) {
        alert("请选择Excel文件");
        return false;
      }
      fileName.value = e.target.files[0].name;
      let file = e.target.files[0];
      if (!file) {
        return;
      }
      readFile(file).then((res) => {
        let workbook = xlsx.read(res, { type: "binary" });
        let worksheet = workbook.Sheets[workbook.SheetNames[0]];
        for (let item of fileCheckData) {
          if (worksheet[item.po].w == item.cname) {
            worksheet[item.po].w = item.ename;
          } else {
            alert(
              `模板出现错误,表头中${worksheet[item.po].w}应该为${item.cname}`
            );
            return false;
          }
        }
        let data = xlsx.utils.sheet_to_json(worksheet);
        if (data.length == 0) { alert('表格中无可用数据'); return false; }
        upLoadContent.push.apply(upLoadContent, data);
      });
    };

    //f用于上传解析好的数据
    let sendUpLoadContent = () => {

      new Promise((resolve, reject) => {
        let errMessage = '';
        for (let index in upLoadContent) {

          if (upLoadContent.length < 1 || !upLoadContent.length) {
            errMessage += `文件无内容`; continue;
          }

          if (isNaN(upLoadContent[index].OrderQty) && upLoadContent[index].OrderQty != '') {
            errMessage += `第${parseInt(index) + 2}行采购数量填写非数字`; continue;
          }
          if (upLoadContent[index].UnitPrice) {
            if (isNaN(upLoadContent[index].UnitPrice) && upLoadContent[index].UnitPrice != '') {
              errMessage += `第${parseInt(index) + 2}行采购金额填写非数字`; continue;
            }
          }
          if (upLoadContent[index].CommodityId == '') { errMessage += `第${parseInt(index) + 2}行物料号未填写`; continue; }
          resolve(errMessage)
        }
      }).then(res => {
        if (res != '') {
          throw (res)

        }
        emit('uploadData', upLoadContent)

        closePopUp();
      }).catch(err => {
        closePopUp();
      })
    };
    return {
      popModelRef,
      openPopUp,
      fileJudge,
      fileName,
      sendUpLoadContent,

    }
  }
}
</script>

<style scoped lang="less">
.popBody div[class^="popBodyLayer"] {
  display: flex;
}
.popBodyCon1 {
  width: 6.875rem;
  height: 3.75rem;
  line-height: 3.75rem;
  text-indent: 10px;
}
.IndiLayer {
  height: 2rem;
  line-height: 2rem;
  text-indent: 10px;
}
.popfooterforUp {
  height: 3.75rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 1.25rem;
}
.a-upload {
  margin-left: 0.625rem;
  padding-left: 0.625rem;
  height: 1.75rem;
  width: 18.75rem;
  line-height: 1.5rem;
  position: relative;
  cursor: pointer;
  color: #888;
  background: #fafafa;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

.a-upload input {
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: pointer;
}
.a-upload:hover {
  color: #444;
  background: #eee;
  border-color: #ccc;
  text-decoration: none;
}
.FileName {
  padding-left: 15px;
}
.DownloadFile {
  margin-left: 0.625rem;
  height: 1.75rem;
  line-height: 1.75rem;
  color: #2881c7;
}
.uploadbtn {
  height: 1.75rem;
  width: 5rem;
  margin-left: 6.25rem;
  background-color: #f6f6f6;
  cursor: pointer;
  line-height: 1.75rem;
  text-align: center;
  font-size: 0.875rem;
  color: #000;
  border: 1px solid #dddddd;
}
.uploadbtn:hover {
  background-color: #f08300;
  color: #fff;
}
</style>