<template>
  <div class="totolBox">
    <div class="BodyTitle">
      <div class="BodyTitleText">营业额明细查询</div>
    </div>
    <div>
      <div class="searchContentContainer">
        <div class="searchCommBox">
          <label class="searchLabel">选择公司:</label>
          <div class="searchInput">
            <el-select v-model="companyChoosed" class="m-2" placeholder="请选择" @change="showDetail()">
              <el-option v-for="item in managedCompanysFilter" :key="item.beforeTransfer + 'TurnoverCompanyChoose'" :label="item.afterTransfer" :value="item.beforeTransfer" />
            </el-select>
          </div>
          <label class="searchLabel">选择日期:</label>
          <div class="searchInput">
            <el-date-picker @change="showDetail()" v-model="dateSpanChoose" type="daterange" unlink-panels range-separator="To" start-placeholder="开始日期" end-placeholder="结束日期" :shortcuts="shortcuts" :size="size" />
          </div>
          <div class="amountShow"><span>合计:</span><span class="amountNumber">{{totalAmount}}</span></div>
          <div class="amountShow detailForMi" @mouseenter="showMiCheck()" @mouseleave="hideMiCheck()">期间营业款确认明细
            <div class="MiCheckDetailBox" v-show="miCheckShow">
              <div class="miCheckLineBox" v-for="item in MiCheckList" :key="item.miCheckCode + 'TurnoverAna'">
                <div>{{item.collectAmout ? '确认金额' + item.collectAmout.toFixed(2) + '元' : ''}}</div>
                <div>{{item?.collectDate ? item?.collectDate + '' : ''}}</div>
                <div>{{item?.remark}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="BigTable">
      <div class="TableContentArea">
        <table class="tablehead">
          <tr class="headtr" :style="{ height : trHeight }">
            <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'Pmhead'" :class="{  ShowUpArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == 1, ShowDownArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == -1,}" @click.stop="ChangeSort(value1.fieldsNameForVali)">
              <div :style="{ width: value1.showLen  + 'rem' }">{{ value1.showName }}</div>
            </th>
          </tr>
        </table>
        <div class="tablebodyarea" :style="{ width: tablewidth  + 'rem',height:tbodyHeight}">
          <table class="tablebody">
            <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'Pmhead' + index2">
              <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'" :class="{AlertShow:value2.MiAmount < 0}">
                <div :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onUnmounted, reactive, ref, toRefs, watch } from 'vue-demi'
import { ChangeFirToUp, ChangeYMDTime, copyArray, copyObj, dateData, getDistanceDays, getUUId, insertSearchOrigin, sortUpRemark } from '@/hooks/tools'
import _ from "lodash";
import { changeToViewCode, getRetailPriceById, getAllCommByCommodityId, getAllVmiByCommodityId, transferToName, transferToCode, sendBenchMarkChange, getAllCommPurchasedToday, getDocDetail } from '@/hooks/OpenOrder'
import { useStore } from "vuex";
import { getBenchMarkPrice, getMiCheckRecordBySpan, getMiDetailBySpan } from '@/hooks/getCache';
import { dataTool } from 'echarts';
import { ElMessage } from 'element-plus'
export default {
  components: {
  },
  setup() {

    const store = useStore()

    const managedCompanysFilter = copyArray(store.state.loginInitializeData.filterList.ManagedCompanys)

    let companyChoosed = ref('')

    if (managedCompanysFilter.length > 0) {
      companyChoosed.value = managedCompanysFilter[0].beforeTransfer
    }

    const size = ref('')
    const dateSpanChoose = ref('')

    // setTimeout(() => {
    //   dateSpanChoose.value[0] = new Date()
    //   dateSpanChoose.value[1] = new Date()
    //   showDetail()
    // }, 0);

    const shortcuts = [
      {
        text: '今天',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime())
          return [start, end]
        },
      },
      {
        text: '昨天',
        value: () => {
          const end = new Date()
          const start = new Date()
          end.setTime(end.getTime() - 3600 * 1000 * 24 * 1)
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
          return [start, end]
        },
      },
      {
        text: '过去三天',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 2)
          return [start, end]
        },
      },
      {
        text: '过去一周',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
          return [start, end]
        },
      },
      {
        text: '过去一个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
    ]

    let tableBody = reactive([]);

    let MiCheckList = reactive([]);

    let totalAmount = ref(0)

    let miCheckShow = ref(false)

    const showMiCheck = () => {
      miCheckShow.value = true
    }

    const hideMiCheck = () => {
      miCheckShow.value = false
    }

    const showDetail = () => {
      const startDate = dateSpanChoose.value[0]
      const endDate = dateSpanChoose.value[1]
      const partAId = companyChoosed.value
      totalAmount.value = 0
      let daysSpan = getDistanceDays(startDate, endDate)
      if (daysSpan > 30) {
        ElMessage('时间间隔不能超过30天')
        dateSpanChoose.value[0] = new Date()
        dateSpanChoose.value[1] = new Date()
        showDetail()
        return
      }
      if (!endDate || !startDate) {
        return;
      }
      MiCheckList.splice(0)
      tableBody.splice(0)
      getMiCheckRecordBySpan(partAId, startDate, endDate).then(res=>{
        if(res){
          for(let item of res){
            item.collectDate = ChangeYMDTime(item.collectDate)
            MiCheckList.push(item)
          }
        }
        else{
          let b ={
            remark:'该期间无营业款确认记录'
          }
          MiCheckList.push(b)
        }
      })

      getMiDetailBySpan(partAId, startDate, endDate).then(res => {
        
        if (res?.length > 0) {
          for (let item of res) {
            totalAmount.value += item.capitalAccountBlanceChange
            let docType = ''
            switch (item.sourceDocumentId.substring(0, 2)) {
              case 'RO':
                docType = item.capitalAccountBlanceChange >= 0 ? '零售单' : '修改或删除'
                break;
              case 'SO':
                docType = item.capitalAccountBlanceChange >= 0 ? '销售单' : '修改或删除'
                break;
              case 'OB':
                docType = item.capitalAccountBlanceChange >= 0 ? '订金' : '修改或删除'
                break;
              case 'MS':
                docType = item.capitalAccountBlanceChange >= 0 ? '机器销售' : '修改或删除'
                break;
              default:
                docType = '其他'
                break;
            }
            let b = {
              DocumentId: item.sourceDocumentId,
              Sn: item.sn,
              DocumentDate: item.documentDate,
              DocumentType: docType,
              PartBCompanyId: item.remark,
              MiAmount: item.capitalAccountBlanceChange
            }
            getDocDetail(b.DocumentId).then(res => {
              b.DocumentContent = sortUpRemark(res)
              b.DocumentDate = ChangeYMDTime(b.DocumentDate)
              tableBody.push(b)
            })
          }
          totalAmount.value = totalAmount.value.toFixed(2)
        }
      })
    }

    let selectAllWidth = ref('4rem')
    let trHeight = ref('1.875rem')
    let SnWidth = ref('3.75rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')

    //f计算表格内容总宽度
    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) + parseFloat(selectAllWidth.value) + 1.25 + tableHead.length * 1 + 0.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });

    let tableHead = reactive([
      { fieldsNameForVali: 'DocumentId', showName: '单据名称', showLen: 16 },
      { fieldsNameForVali: 'Sn', showName: '序', showLen: 6 },
      { fieldsNameForVali: 'DocumentDate', showName: '开单日期', showLen: 14 },
      { fieldsNameForVali: 'DocumentType', showName: '单据类型', showLen: 10 },
      { fieldsNameForVali: 'PartBCompanyId', showName: '往来单位', showLen: 16 },
      { fieldsNameForVali: 'MiAmount', showName: '收款金额', showLen: 8 },
      { fieldsNameForVali: 'DocumentContent', showName: '单据内容', showLen: 40 },
    ]);

    let ChangeSort = (fildsname) => {

      searchElement.SortFieldsName = ChangeFirToUp(fildsname)
      searchElement.SortDirection = -searchElement.SortDirection

      tableBody = tableBody.sort(dateData('DocumentDate', searchElement.SortDirection))
      // tableBody.splice(0)
      // for(let item of tableBodyCopy){
      //   tableBody.push(item)
      // }
    };

    const searchElement = reactive({
      SortFieldsName: '',
      SortDirection: 1
    })



    return {
      ChangeSort,
      selectAllWidth,
      trHeight,
      SnWidth,
      tbodyHeight,
      tablewidth,
      tableHead,
      tableBody,
      searchElement,
      managedCompanysFilter,
      companyChoosed,

      size,
      dateSpanChoose,
      shortcuts,
      showDetail,
      totalAmount,
      MiCheckList,
      miCheckShow,
      showMiCheck,
      hideMiCheck
    }
  }
}
</script>

<style scoped lang="less">
@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;

.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.searchLabel {
  line-height: 4.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  font-size: 1rem;
}
.searchCommBox {
  display: flex;
  position: relative;
}

.commInput {
  width: 100%;
}

.searchContentTr {
  display: flex;
  margin-top: 0.2rem;
  width: 100%;
}
.searchContentTr:hover {
  background-color: #f5f5f6;
}
.searchContentTr input {
  border: none;
  outline: none;
  text-align: left;
  background-color: transparent;
  border-radius: 5px;
}

.searchContentTr input:hover {
  cursor: pointer;
}
.searchContentTrLast:hover {
  background-color: #fa2c2c;
  color: #fff;
  cursor: pointer;
}
.searchContentTrLast {
  padding-left: 0.3rem;
}
.searchIndication {
  text-align: center !important;
  color: red;
}
.comminputDown {
  width: 80%;
}
.searchIndication {
  width: 20%;
}
.commNameSearchBox {
  position: relative;
}

.CommodityContainer {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  border: 1px solid #cccccc;
  top: 4.6rem;
  left: 5.5rem;
  width: 40rem;
}
.commodityMangeLayer {
  margin-top: 1rem;
}
.BodyTitle {
  width: 98%;
  margin-top: 0.5rem;
  margin-left: 1%;
}
.BodyTitleText {
  font-size: 1.2rem;
  font-weight: 200;
}

.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 80%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.headtd {
  cursor: pointer;
}
.ShowUpArrow {
  position: relative;
}
.ShowUpArrow::after {
  content: "\ea32";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
  color: #fff;
}
.ShowDownArrow {
  position: relative;
}
.ShowDownArrow::after {
  content: "\ea36";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.bodytr {
  text-align: center;
}
.tdoper,
.bodytr {
  height: 2rem;
}
.totolBox {
  height: 100%;
  width: 100%;
}
.submitNumber {
  border: none;
  background-color: #fff2cc;
}

.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}
.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}
.demo-date-picker .block:last-child {
  border-right: none;
}
.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}
.searchInput {
  margin: auto;
}
.AlertShow {
  background-color: #ffc000;
}
.amountShow {
  margin: auto;
  margin-left: 2rem;
}
.amountNumber {
  color: red;
  font-size: 1rem;
  font-weight: 700;
}
.detailForMi {
  color: #1e87f0;
  font-weight: 700;
  position: relative;
}
.detailForMi:hover{
  cursor: pointer;
}

.MiCheckDetailBox {
  position: absolute;
  width: 50rem;
  z-index: 2;
  background: #fff;
  color:#1e87f0;
  left: -20rem;
  top: 3rem;
}
.MiCheckDetailBox div{
  margin-bottom: .5rem;
  text-indent: 2rem;
  border-bottom: 1px solid #e2e4e7;
}
.miCheckLineBox {
  display: flex;
}
.miCheckLineBox :nth-child(1){
  width: 15rem;
}
.miCheckLineBox :nth-child(2){
  width: 15rem;
}
.miCheckLineBox :nth-child(3){
  width: 20rem;
}
</style>