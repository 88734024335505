<template>
  <TableLoaderShow></TableLoaderShow>
</template>

<script>
import TableLoaderShow from '@/components/Content/MainBodyContent/TableLoaderShow.vue'
import { onMounted, onUnmounted, provide, reactive } from 'vue-demi';
import { useStore } from "vuex";
export default {
  setup() {
    //#region 定义区
    const store = useStore();
    //初始化输入表名称
    const TableName = 'ButtonVisibleManage';
    //根据表名给searchItemList赋值
    let searchItemList = store.state.loginInitializeData.pageNameManage.filter(x => x.tableName == TableName)[0]
    //#endregion

    //#region 数据提供区
    provide('searchItemList', searchItemList)
    //#endregion

    //#region 挂载前加载

    //#endregion

    //#region 失去活跃处理

    //#endregion

    //#region 活跃时处理

    //#endregion

    //#region 开始时处理

    //#endregion

    onUnmounted(() => {

    })
    return {
      searchItemList
    }
  },
  components: {

    TableLoaderShow

  }
}
</script>

<style scoped lang="less">
</style>