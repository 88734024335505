import store from '@/store';
import { ref,reactive,onMounted,provide } from 'vue-demi'

export default function (TableName,PageName) {

  let searchItemList = store.state.loginInitializeData.pageNameManage.filter(x => x.tableName == TableName)[0]

  const listForValidation = store.state.loginInitializeData.listForValidations
  const filterList = store.state.loginInitializeData.filterList
  const positiomList = store.state.loginInitializeData.positiomList
  let fileCheckData = [];
  const tableShowRef = ref(null)
  const searchRef = ref(null)

  //将表格相关数据连接进数据主线
  const fillSearchItemList = () => {
    const list = listForValidation.filter(x => x.tableNameForVali == searchItemList.tableName && x.validateMethod == 'add')
    for (let item of list) {
      searchItemList[item.fieldsNameForVali] = item
      for (let fkey in filterList) {
        if (item.fieldsNameForVali == fkey) {
          searchItemList[item.fieldsNameForVali].Filter = filterList[fkey]
        }
      }
      if (item.addTrans == true) {
        let objFileCheck = {}
        objFileCheck.cname = item.showName
        objFileCheck.ename = item.fieldsNameForVali
        fileCheckData.push(objFileCheck)
      }
      for (let index in fileCheckData) {
        fileCheckData[index].po = positiomList[index]
      }
    }
  }

  const deliverSearchItems = (searchItems) => {
    for (let key in searchItems) {
      for (let key2 in searchItemList) {
        if (key == key2) {
          searchItemList[key2].defaultValue = searchItems[key]
        }
      }
    }
    tableShowRef.value.fillSearchContent()
    tableShowRef.value.turnPageToOne()
    tableShowRef.value.searchList()
  }

  let activeItem = reactive({
    value: {
    },
    index: -1
  })

  const saveActiveItem = (data, index) => {
    activeItem.value = data
    activeItem.index = index
  }

  const clearActive = () => {
    activeItem.value = {}
    activeItem.index = -1
  }

  onMounted(() => {
    if(tableCode == store.state.loginInitializeData.ShowWindow[0]){
      searchRef.value.searchList()
    }
  })

  let tableCode = store.state.loginInitializeData.authList.viewLists.filter(x => x.viewId == PageName)[0].viewCode

  fillSearchItemList();
  //#region 数据提供区
  provide('activeItem', activeItem)
  provide('searchItemList', searchItemList)
  provide('fileCheckData', fileCheckData)

  return {
    searchItemList,
    listForValidation,
    filterList,
    positiomList,
    fileCheckData,
    tableShowRef,
    searchRef,
    fillSearchItemList,
    deliverSearchItems,
    saveActiveItem,
    clearActive,
    activeItem,tableCode
  }
}