import { ref } from "@vue/reactivity";
import { watch } from '@vue/runtime-core';
import { useStore } from "vuex";
import { inject} from 'vue-demi'


export default function(){
  const store = useStore();
  let searchItemList = inject('searchItemList')
  const popModelRef = ref(null)
  const tableShowForPopRef = ref(null)
  const listForValidation = store.state.loginInitializeData.listForValidations
  const filterList = store.state.loginInitializeData.filterList
  const openPopComm = () => {
    popModelRef.value.openPopComm()
  }

  const closePopComm = () => {
    popModelRef.value.closePopComm()
  }

  const searchList = () => {
    setTimeout(() => {
      tableShowForPopRef.value.searchList()
    }, 0)
  }

  //绑定Enter事件
  const enterBp = (e) => {
    if (e.keyCode == 13) {
      searchList()
    }
  }
  const bindBpEnter = () => {
    window.addEventListener('keydown', enterBp)
  }

  const unbindBpEnter = () => {
    window.removeEventListener('keydown', enterBp)
  }
  
  return {store,searchItemList,popModelRef,tableShowForPopRef,listForValidation,filterList,openPopComm,closePopComm,searchList,enterBp,bindBpEnter,unbindBpEnter}
}