<template>
  <div class="inputLoaderContainer">
    <BodyTitle></BodyTitle>
    <InputHead></InputHead>
    <InputBody ref="inputBodyRef"></InputBody>
    <div class="EditTableLine">
      <span>应收:<b>{{receivable}}</b></span> <span>应付:<b>{{payable}}</b></span>
    </div>
    <div class="EditTableLastLine">
      <!-- 维护第七层的内容 -->
      <div class="EditTableFuncButton EditTableFuncButtonAjust" @click="openList('')">保存并付款</div>
    </div>
  </div>
</template>
<script>
import { provide, ref, watch } from 'vue-demi'
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import InputHead from '@/components/Content/MainBodyContent/InputHead.vue'
import InputBody from '@/components/Content/MainBodyContent/InputBody.vue'
import { getDocNo, washDocHead, getLoginData, copyArrayDoc, washBodyCopy, deleteDocCenter, openDocCenter, openMoList, checkDocCondition, clearTableContent, transferToCode, getCount } from '@/hooks/OpenOrder'
import { transferLoginDataOnly } from '@/hooks/loginAffair';
import { toDecimal2 } from '@/hooks/tools'
import InputTable from '@/hooks/InputTable'
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
export default {
  components: {
    BodyTitle,
    InputHead,
    InputBody,
  },
  setup() {
    const store = useStore();
    const RootTableName = 'MoList';
    let { itemListHead, itemListBody, searchItemList } = InputTable(RootTableName)
    searchItemList.docTitleTwo = 'MO'
    searchItemList.TableName = 'MoInput'
    provide('searchItemList', searchItemList)

    let inputBodyRef = ref(null)

    let receivable = ref(toDecimal2(0))

    let payable = ref(toDecimal2(0))

    // const fillEditContent = () => {
    //   let b = store.state.ActiveAccountInfo.partBCompanyId
    //   let c = store.state.ActiveAccountInfo.accounts
    //   if (b && c < 0) {
    //     searchItemList.documentContent.PartBCompanyId = b
    //     setTimeout(() => {
    //       inputBodyRef.value.AddContentBody[0].UnitPrice = toDecimal2(-c)
    //     }, 0)
    //   }
    // }
    // fillEditContent()

    const getDataFromAcc = (value) => {
      searchItemList.documentContent.PartBCompanyId = value.partBCompanyId
      clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
      inputBodyRef.value.AddContentBody[0]['UnitPrice'] = value.shouldPay
    }

    watch(() => store.state.loginInitializeData.ShowWindow[0], () => {
      const code = store.state.loginInitializeData.authList.viewLists.filter(x => x.viewId == 'MoInput')[0].viewCode
      if (code == store.state.loginInitializeData.ShowWindow[0]) {
        refreshCount(transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId'))
      }
    })

    const refreshCount = (companyBCode) => {
      getCount(companyBCode).then(res => {
        if (+res == 0) {
          receivable.value = toDecimal2(0)
          payable.value = toDecimal2(0)
          return
        }
        if (+res > 0) {
          receivable.value = toDecimal2(res)
          payable.value = toDecimal2(0)
          return
        }
        if (+res < 0) {
          receivable.value = toDecimal2(0)
          payable.value = toDecimal2(-res)
          return
        }
      })
    }

    watch(() => searchItemList.documentContent.PartBCompanyId, () => {
      let companyBCode = transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId')
      refreshCount(companyBCode)
    })


    const openList = (typeName) => {

      let errCheck = checkDocCondition(searchItemList.documentContent, itemListHead, inputBodyRef.value.AddContentBody, itemListBody)
      if (errCheck.headErr) {

        ElMessage(errCheck.headErr)
        return
      }
      if (errCheck.bodyErr) {

        ElMessage(errCheck.bodyErr)
        return
      }
      getLoginData().then(res => {
        //刷新初始化数据
        if (res != 0) {
          transferLoginDataOnly(res)
        }
        getDocNo(searchItemList.docTitleTwo).then(res => {
          const moHeadCode = res
          for (let item of inputBodyRef.value.AddContentBody) {
            if (item.UnitPrice) {
              item.SourceDocumentId = moHeadCode
              item.RootSourceDocumentId = moHeadCode
              item.DocumentId = moHeadCode
              item.PartACompanyId = store.state.loginInitializeData.companyId
              item.PartBCompanyId = transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId')
              item.OutStockQty = 1
              item.InStockQty = 1
              item.OrderQty = 1
            }
          }
          openMoList(inputBodyRef.value.AddContentBody, searchItemList.documentContent, moHeadCode).then(res => {
            if (res != 'pass') {
              deleteDocCenter(moHeadCode).then(res => {
                ElMessage(res)
                return false;
              })
            }
            else {
              ElMessage({
                message:'开单成功',
                duration:2000,
                showClose:true
              })
              clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
              refreshCount(transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId'))
            }


          })
        })
      }).catch(err => {

        ElMessage(err.message)
      })
    }
    //#endregion

    return {
      searchItemList,
      inputBodyRef,
      openList,
      getDataFromAcc,
      receivable,
      payable
    }
  }
}
</script>
<style scoped lang="less">
.EditTableLine {
  margin-top: 1rem;
  margin-left: 0.5rem;
}
.EditTableLine b {
  color: red;
  padding-left: 0.5rem;
}

.EditTableLine span {
  margin-right: 1rem;
}
</style>