<template>
  <div class="inputLoaderContainer">
    <BodyTitle></BodyTitle>
    <div class="FrTitle"><span>维修类型</span></div>
    <div class="RepairTypeChoose">
      <div v-for="item in ServiceTypeFilter" :key="item.beforeTransfer +'FrInputStartType'">
        <label class="inputChoose" for="item.beforeTransfer +'FrInputStartTypeLabel'">{{item.afterTransfer}}:</label><input type="radio" name="RepairType" :value="item.beforeTransfer" :id="item.beforeTransfer +'FrInputStartTypeLabel'" v-model="searchItemList.addBodyContent.ServiceType">
      </div>
      <!-- <label class="inputChoose" for="ToStoreRepair">到店维修:</label><input type="radio" name="RepairType" value="101" id="ToStoreRepair" v-model="searchItemList.addBodyContent.ServiceType">
      <label class="inputChoose" for="ToHouseRepair">上门维修:</label><input type="radio" name="RepairType" value="102" id="ToHouseRepair" v-model="searchItemList.addBodyContent.ServiceType">
      <label class="inputChoose" for="PostRepair">邮寄维修:</label><input type="radio" name="RepairType" value="103" id="PostRepair" v-model="searchItemList.addBodyContent.ServiceType">
      <label class="inputChoose" for="areaRepair">同行业务:</label><input type="radio" name="RepairType" value="104" id="areaRepair" v-model="searchItemList.addBodyContent.ServiceType"> -->
    </div>
    <div class="FrTitle"><span>咨询发起</span></div>
    <div class="InputHeadContainer">
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'PartBCompanyId')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="PartBCompanyIdFrInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'PartBCompanyId')[0].fieldsShowName + ':'"></label><input class="InputForChoose" id="PartBCompanyIdFrInput" type="text" v-model="searchItemList.addBodyContent.PartBCompanyId" @blur="checkBpName($event),unbindBpEnter()" @mousedown="bindBpEnter">
        <div class="InputForChooseFunc" @click="openBpPop"></div>
        <BpPopForFrOne :tableTile="searchItemList.docTitleTwo" ref="bpPopRef"></BpPopForFrOne>
      </div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'CustomerSourceId')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="CustomerSourceIdFrInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'CustomerSourceId')[0].fieldsShowName + ':'"></label><select class="InputForChooseShort" id="CustomerSourceIdFrInput" v-model="searchItemList.addBodyContent.CustomerSourceId">
          <option v-for="item5 in cusSourceArr" :key="item5.customerSourceId + 'CSFrInput'" :value="item5.customerSourceId">{{item5.customerSourceName}}</option>
        </select></div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'MachineClass')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="MachineClassFrInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'MachineClass')[0].fieldsShowName + ':'"></label><select class="InputForChooseShort" id="MachineClassFrInput" v-model="searchItemList.addBodyContent.MachineClass">
          <option v-for="item in searchItemList.MachineClass.Filter" :key="item.afterTransfer + 'FrInput'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
        </select></div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'MachineType')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="MachineTypeFrInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'MachineType')[0].fieldsShowName + ':'"></label><input class="InputForChooseLong" id="MachineTypeFrInput" type="text" v-model="searchItemList.addBodyContent.MachineType"></div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'BreakdownCause')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="BreakdownCauseFrInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'BreakdownCause')[0].fieldsShowName + ':'"></label><input class="InputForChooseTooLong" id="BreakdownCauseFrInput" type="text" v-model="searchItemList.addBodyContent.BreakdownCause"></div>

    </div>
    <div class="FrTitle"><span>客户信息</span></div>
    <div class="InputHeadContainer">
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'CustomerName')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="CustomerNameFrInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'CustomerName')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="CustomerNameFrInput" type="text" v-model="searchItemList.addBodyContent.CustomerName"></div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'CustomerContact')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="CustomerContactFrInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'CustomerContact')[0].fieldsShowName + ':'"></label><input class="InputForChooseLong" id="CustomerContactFrInput" type="text" v-model="searchItemList.addBodyContent.CustomerContact"></div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Address')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="AddressFrInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Address')[0].fieldsShowName + ':'"></label><input class="InputForChooseTooLong" id="AddressFrInput" type="text" v-model="searchItemList.addBodyContent.Address"></div>

    </div>
    <div class="FrTitle"><span>补充信息</span></div>
    <div class="InputHeadContainer">
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'DraftQuotation')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="DraftQuotationFrInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'DraftQuotation')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="DraftQuotationFrInput" type="number" v-model="searchItemList.addBodyContent.DraftQuotation"></div>
      <!-- <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'MoneyPrePaid')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="MoneyPrePaidFrInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'MoneyPrePaid')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="MoneyPrePaidFrInput" type="number" v-model="searchItemList.addBodyContent.MoneyPrePaid"></div> -->

      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'LoginPassword')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="LoginPasswordFrInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'LoginPassword')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="LoginPasswordFrInput" type="text" v-model="searchItemList.addBodyContent.LoginPassword"></div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'UniqueCode')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="UniqueCodeFrInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'UniqueCode')[0].fieldsShowName + ':'"></label><input class="InputForChooseLong" id="UniqueCodeFrInput" type="text" v-model="searchItemList.addBodyContent.UniqueCode"></div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'ExpectedDeliveryDate')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="ExpectedDeliveryDateFrInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'ExpectedDeliveryDate')[0].fieldsShowName + ':'"></label><input class="InputForChooseLong" id="ExpectedDeliveryDateFrInput" type="date" v-model="searchItemList.addBodyContent.ExpectedDeliveryDate"></div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Alongings')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="AlongingsFrInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Alongings')[0].fieldsShowName + ':'"></label><input class="InputForChooseLong" id="AlongingsFrInput" type="text" v-model="searchItemList.addBodyContent.Alongings"></div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Remark')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="RemarkFrInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Remark')[0].fieldsShowName + ':'"></label><input class="InputForChooseTooTooLong" id="RemarkFrInput" type="text" v-model="searchItemList.addBodyContent.Remark"></div>
    </div>
    <div class="FrTitle"><span>人员/外协信息</span></div>
    <div class="InputHeadContainer">
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'StaffId')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="StaffIdFrInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'StaffId')[0].fieldsShowName + ':'"></label><input class="InputForChoose" id="StaffIdFrInput" type="text" v-model="searchItemList.addBodyContent.StaffId" @blur="checkStName($event),unbindStEnter()" @mousedown="bindStEnter">
        <div class="InputForChooseFunc" @click="openStPop"></div>
        <StPopForFr ref="stPopRef"></StPopForFr>
      </div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'OutSourceCompanyId')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="OutSourceCompanyIdFrInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'OutSourceCompanyId')[0].fieldsShowName + ':'"></label><input class="InputForChoose" id="OutSourceCompanyIdFrInput" type="text" v-model="searchItemList.addBodyContent.OutSourceCompanyId" @blur="checkBpNameTwo($event),unbindBpEnterTwo()" @mousedown="bindBpEnterTwo">
        <div class="InputForChooseFunc" @click="openBpPopTwo"></div>
        <BpPopForFrTwo ref="bpPopRefTwo"></BpPopForFrTwo>
      </div>
      <div class="InputForChooseContainer">
        <label class="InputForChooseLabel">紧急程度</label>
        <select v-model="emergencyChoose" class="InputForChooseShort">
          <option v-for="item in EmergencyFilter" :key="item.beforeTransfer + 'FrListEmergency'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
        </select>
      </div>

    </div>

    <div class="EditTableLastLine">
      <!-- 维护第七层的内容 -->
      <div class="EditTableFuncButton" :style="{background:'#fbb80d'}" @click="openList('FROS')">登记并外协</div>
      <div class="EditTableFuncButton EditTableFuncButtonAjust" @click="openList('FR')">仅维修登记</div>
    </div>
  </div>
</template>
<script>
import { computed, provide, ref, watch } from 'vue-demi'
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import InputTable from '@/hooks/InputTable'
import BpPopForFrOne from '@/components/Common/GlobalPop/BpPopForFrOne.vue'
import BpPopForFrTwo from '@/components/Common/GlobalPop/BpPopForFrTwo.vue'
import StPopForFr from '@/components/Common/GlobalPop/StPopForFr.vue'
import requestData from "@/hooks/requestData.js";
import {
  checkDocHead, transferToCode, getLoginData, openFrList, addSourceStatistics, getDocNo,
  updateOutSourceCompany, initializeOutSourceAppendInfo
} from '@/hooks/OpenOrder';
import { transferLoginDataOnly } from '@/hooks/loginAffair';
import { copyObj, fillSonContent, getDateTime, getUUId } from '@/hooks/tools'
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
export default {
  components: {
    BodyTitle,
    BpPopForFrOne,
    BpPopForFrTwo, StPopForFr
  },
  setup(props, { emit }) {
    const store = useStore();
    const RootTableName = 'FrList';
    let { searchItemList, listForBody } = InputTable(RootTableName)
    searchItemList.docTitleTwo = 'FR'
    searchItemList.TableName = 'FrInput'
    provide('searchItemList', searchItemList)

    const ServiceTypeFilter = store.state.loginInitializeData.filterList.ServiceType

    const EmergencyFilter = store.state.loginInitializeData.filterList.Emergency

    let emergencyChoose = ref('')

    //#region 供应商模块1
    const bpPopRef = ref(null)

    const openBpPop = () => {
      bpPopRef.value.openPopComm()
    }

    const initializePageElement = () => {
      searchItemList.addBodyContent.PartBCompanyId = '散客'
      searchItemList.addBodyContent.ServiceType = 101
    }

    initializePageElement()

    //绑定Enter事件
    const enterBp = (e) => {
      if (e.keyCode == 13) {
        checkBpName(e)
      }
    }
    const bindBpEnter = () => {
      window.addEventListener('keydown', enterBp)
    }

    const unbindBpEnter = () => {
      window.removeEventListener('keydown', enterBp)
    }

    //f确认往来单位输入的方法
    const checkBpName = (e) => {
      if (e.target.value == '') { return false; }
      let config = {
        method: "get",
        url: "/BusinessPartnerList/GetSingleAuth",
        params: {
          name: e.target.value
        }
      }
      requestData.GetCache(config).then(res => {
        if (res == 'notfound') {
          let sendMsg = searchItemList.addBodyContent.PartBCompanyId
          searchItemList.addBodyContent.PartBCompanyId = ''
          openBpPop()
          bpPopRef.value.sendMsgSolve(sendMsg)
          bpPopRef.value.searchList()
          return 'notfound'
        }
        else {
          return 'found'
        }
      })
    }
    //#endregion

    //#region 供应商模块2
    const bpPopRefTwo = ref(null)

    const openBpPopTwo = () => {
      bpPopRefTwo.value.openPopComm()
    }

    const getDataFromQuotation = (value) => {
      setTimeout(() => {
        searchItemList.addBodyContent.MachineClass = value.commodityFirstClassNo
        searchItemList.addBodyContent.MachineType = value.machineType
        searchItemList.addBodyContent.BreakdownCause = value.breakCause
        searchItemList.addBodyContent.DraftQuotation = value.quotationForCustomer
      }, 0);
    }


    //绑定Enter事件
    const enterBpTwo = (e) => {
      if (e.keyCode == 13) {
        checkBpNameTwo(e)
      }
    }
    const bindBpEnterTwo = () => {
      window.addEventListener('keydown', enterBpTwo)
    }

    const unbindBpEnterTwo = () => {
      window.removeEventListener('keydown', enterBpTwo)
    }

    //f确认往来单位输入的方法
    const checkBpNameTwo = (e) => {
      if (e.target.value == '') { return false; }
      let config = {
        method: "get",
        url: "/BusinessPartnerList/GetSingleAuth",
        params: {
          name: e.target.value
        }
      }
      requestData.GetCache(config).then(res => {
        if (res == 'notfound') {
          let sendMsg = searchItemList.addBodyContent.OutSourceCompanyId
          searchItemList.addBodyContent.OutSourceCompanyId = ''
          openBpPopTwo()
          bpPopRefTwo.value.sendMsgSolve(sendMsg)
          bpPopRefTwo.value.searchList()
          return 'notfound'
        }
        else {
          return 'found'
        }
      })
    }
    //#endregion

    //#region 员工模块
    const stPopRef = ref(null)

    const openStPop = () => {
      stPopRef.value.openPopComm()
    }

    const enterSt = (e) => {
      if (e.keyCode == 13) {
        checkStName(e)
      }
    }
    const bindStEnter = () => {
      window.addEventListener('keydown', enterSt)
    }

    const unbindStEnter = () => {
      window.removeEventListener('keydown', enterSt)
    }


    //f确认往来单位输入的方法
    const checkStName = (e) => {
      if (e.target.value == '') { return false; }
      let config = {
        method: "get",
        url: "/StaffList/GetSingleAuth",
        params: {
          name: e.target.value
        }
      }
      requestData.GetCache(config).then(res => {
        if (res == 'notfound') {
          let sendMsg = searchItemList.addBodyContent.StaffId
          searchItemList.addBodyContent.StaffId = ''
          openStPop()
          stPopRef.value.sendMsgSolve(sendMsg)
          stPopRef.value.searchList()
          return 'notfound'
        }
        else {
          return 'found'
        }
      })
    }

    const cusSourceArr = store.state.loginInitializeData.authList.customerSourceLists
    //#endregion


    //#endregion

    //#region 开单功能模块

    const openList = (typeName) => {

      let err = checkDocHead(searchItemList.addBodyContent, searchItemList.itemList)
      if (err) {

        ElMessage(err)
        return false;
      }
      if (typeName == 'FROS' && searchItemList.addBodyContent.OutSourceCompanyId == '') {

        ElMessage('外协单位未填写')
        return false;
      }
      if (typeName == 'FROS' && searchItemList.addBodyContent.StaffId == '') {

        ElMessage('外协必须确认维修人员')
        return false;
      }
      if ((searchItemList.addBodyContent.DraftQuotation + '').indexOf('e') > -1) {
        ElMessage('报价输入有误')
        return false;
      }
      if(!searchItemList.addBodyContent.LoginPassword){
        ElMessage('密码未填写，如果没有可以填写无')
        return;
      }
      if (typeName == 'FR' && searchItemList.addBodyContent.OutSourceCompanyId != '') {

        ElMessage('仅维修登记请删除外协单位')
        return false;
      }
      let b = confirm('确认登记')
      if (!b) {
        return false;
      }
      getLoginData().then(res => {
        if (res != 0) {
          transferLoginDataOnly(res)
        }
        let bodyCopy1 = copyObj(searchItemList.addBodyContent)
        let oursouceId = transferToCode(bodyCopy1.OutSourceCompanyId, 'PartBCompanyId')
        bodyCopy1.PartBCompanyId = transferToCode(bodyCopy1.PartBCompanyId, 'PartBCompanyId')
        bodyCopy1.StaffId = transferToCode(bodyCopy1.StaffId, 'StaffId')
        bodyCopy1.OutSourceCompanyId = ''
        bodyCopy1.PartACompanyId = store.state.loginInitializeData.companyId
        getDocNo('FR').then(res => {
          const frHeadCode = res
          bodyCopy1.DocumentId = frHeadCode
          openFrList(bodyCopy1).then(res => {
            if (res != 'pass') {
              initializePageElement()
              ElMessage(res)
              return false;
            }
            else {
              if (bodyCopy1.CustomerSourceId != '') {
                let staObj = {
                  DocumentId: frHeadCode,
                  SettleOrNot: false,
                  CustomerSourceId: bodyCopy1.CustomerSourceId,
                  PartACompanyId: store.state.loginInitializeData.companyId
                }
                addSourceStatistics(staObj)
              }
              const storage = window.localStorage;
              const user = store.state.loginInitializeData.userId
              const timeNow = getDateTime()
              let storeListFromWin = []
              if (JSON.parse(storage.getItem('BarStoreList' + user))) {
                storeListFromWin = JSON.parse(storage.getItem('BarStoreList' + user));
              }
              const newId2 = getUUId()
              let addPrint2 = {
                id: 'store' + newId2,
                machineName: bodyCopy1.MachineType,
                docType: '机器送修单',
                companyName: store.state.loginInitializeData.companyNameAbbre,
                docDate: timeNow,
                breakdownCause: bodyCopy1.BreakdownCause,
                remark: `开机密码:${bodyCopy1.LoginPassword}`,
                barcode: frHeadCode,
              }
              storeListFromWin.unshift(addPrint2)
              storage.setItem('BarStoreList' + user, JSON.stringify(storeListFromWin))
              store.commit('updateBarcodeCount')
              if (typeName == 'FROS') {
                updateOutSourceCompany(frHeadCode, oursouceId, '').then(res => {
                  if (emergencyChoose.value == '') {
                    emergencyChoose.value = 1
                  }
                  initializeOutSourceAppendInfo(frHeadCode, emergencyChoose.value, (oursouceId || 0))
                })
              }
              ElMessage({
                message:'开单成功',
                duration:2000,
                showClose:true
              })
              fillSonContent(searchItemList.addBodyContent, listForBody, 'add', 'defaultValue')
              initializePageElement()
              emit('refreshMsg')
            }
          })
        })
      }).catch(err => {

        ElMessage(err.message)
      })
    }
    //#endregion

    //#endregion

    return {
      searchItemList,
      openList,
      bpPopRef,
      openBpPop,

      bpPopRef,
      openBpPop,
      bindBpEnter,
      unbindBpEnter,
      checkBpName,

      bpPopRefTwo,
      openBpPopTwo,
      bindBpEnterTwo,
      unbindBpEnterTwo,
      checkBpNameTwo,

      stPopRef,
      openStPop,
      bindStEnter,
      unbindStEnter,
      checkStName,

      cusSourceArr, ServiceTypeFilter, getDataFromQuotation, emergencyChoose, EmergencyFilter
    }
  }
}
</script>
<style scoped lang="less">
.RepairTypeChoose {
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  width: 95%;
  margin-left: 0.5rem;
  margin-top: 1rem;
  font-size: 1rem;
  display: flex;
}

.InputHeadContainer {
  display: flex;
  margin-top: 0.5rem;
  width: 95%;
  margin-left: 0.5rem;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  flex-wrap: wrap;
}
.InputHeadContainer input {
  margin-bottom: 0.5rem;
  margin-left: 0.2rem;
}

.RepairTypeChoose input {
  margin-left: 0.2rem;
  cursor: pointer;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.inputChoose {
  margin-left: 1rem;
  cursor: pointer;
  line-height: 1.8rem;
}
.InputForChooseContainer {
  display: flex;
  margin-right: 0.625rem;
}
.InputForChooseContainer label {
  padding-right: 0.3125rem;
  line-height: 1.75rem;
}
.importantIndi {
  color: #e83a65;
  font-size: 1rem;
}
.InputForChooseLong {
  height: 1.75rem;
  width: 15.625rem;
  border: 1px solid #c9c9c9;
}
.InputForChooseTooLong {
  height: 1.75rem;
  width: 30.625rem;
  border: 1px solid #c9c9c9;
}
.InputForChooseTooTooLong {
  height: 1.75rem;
  width: 60.625rem;
  border: 1px solid #c9c9c9;
}
.InputForChooseShort {
  height: 1.75rem;
  width: 8.625rem;
  border: 1px solid #c9c9c9;
}
.InputForChoose:focus,
.InputForChooseLong:focus,
.InputForChooseShort:focus,
.InputForChooseTooLong:focus,
.InputForChooseTooTooLong:focus {
  border: 1px solid #ffa004;
}
.InputForChoose {
  height: 1.75rem;
  width: 9rem;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  border-left: 1px solid #c9c9c9;
  border-right: none;
}
.InputForChooseFunc {
  font-family: "icomoon";
  text-align: center;
  line-height: 1.75rem;
  font-size: 1.125rem;
  width: 1.75rem;
  height: 1.75rem;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  border-right: 1px solid #c9c9c9;
}
.InputForChooseFunc:hover {
  cursor: pointer;
}

.FrTitle {
  margin-left: 1rem;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 700;
}
</style>