import store from '@/store';
import {
  reactive
} from 'vue';
import { getNameList} from '@/hooks/OpenOrder'
import { fillSonContent } from '@/hooks/tools'
export default function (RootTableName) {

  const listForValidation = store.state.loginInitializeData.listForValidations
  const filterList = store.state.loginInitializeData.filterList
  const itemList = store.state.loginInitializeData.docTableElementManage.filter(x => x.docTableName == RootTableName)
  const itemListHead = itemList.filter(x => x.fieldsType == 'Head')
  const itemListBody = itemList.filter(x => x.fieldsType == 'Body')
  const documentCenterItems = [];
  let documentContent = reactive({});
  for (let item of itemListHead) {
    documentCenterItems.push(item.fieldsNameForManage)
  }
  const listForDocumentPre = listForValidation.filter(x => x.tableNameForVali == 'DocumentCenter' && x.validateMethod == 'add')

  let listForDocument = {};
  const TableListItems = [];
  for (let item of itemListBody) {
    TableListItems.push(item.fieldsNameForManage)
  }
  let addBodyContent = reactive({});
  let listForBody = {};

  const listForBodyPre = listForValidation.filter(x => x.tableNameForVali == RootTableName && x.validateMethod == 'add')

  //根据表名给searchItemList赋值
  let searchItemList = getNameList(RootTableName)

  const list = listForValidation.filter(x => x.tableNameForVali == RootTableName && x.validateMethod == 'add')
  for (let item of list) {
    searchItemList[item.fieldsNameForVali] = item
    for (let fkey in filterList) {
      if (item.fieldsNameForVali == fkey) {
        searchItemList[item.fieldsNameForVali].Filter = filterList[fkey]
      }
    }
  }
  for (let item of listForDocumentPre) {
    listForDocument[item.fieldsNameForVali] = item
  }


  for (let item of listForBodyPre) {
    listForBody[item.fieldsNameForVali] = item
  }

  fillSonContent(documentContent, listForDocument, 'add', 'defaultValue')
  fillSonContent(addBodyContent, listForBody, 'add', 'defaultValue')

  searchItemList.documentCenterItems = documentCenterItems;
  searchItemList.documentContent = documentContent;
  searchItemList.addBodyContent = addBodyContent;
  searchItemList.TableListItems = TableListItems;
  searchItemList.itemList = itemList;

  return {
    listForValidation,
    filterList,
    itemListHead,
    itemListBody,
    documentCenterItems,
    documentContent,
    listForDocumentPre,
    listForDocument,
    TableListItems,
    addBodyContent,
    listForBodyPre,
    listForBody,
    searchItemList
  }
}