<template>
  <div class="inputLoaderContainer">
    <BodyTitle></BodyTitle>
    <InputHead></InputHead>
    <InputBody ref="inputBodyRef"></InputBody>
    <div class="EditTableLine">
      <span>应收:<b>{{receivable}}</b></span> <span>应付:<b>{{payable}}</b></span>
    </div>
    <div class="EditTableLastLine">
      <!-- 维护第七层的内容 -->
      <div class="EditTableFuncButton EditTableFuncButtonAjust" @click="openList('')">确认并保存</div>
    </div>
  </div>
</template>
<script>
import { computed, provide, ref, watch } from 'vue-demi'
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import InputHead from '@/components/Content/MainBodyContent/InputHead.vue'
import InputBody from '@/components/Content/MainBodyContent/InputBody.vue'
import { getDocNo, getLoginData, checkDocCondition, clearTableContent, transferToCode, getCount, openErList } from '@/hooks/OpenOrder'
import { transferLoginDataOnly } from '@/hooks/loginAffair';
import { toDecimal2 } from '@/hooks/tools'
import InputTable from '@/hooks/InputTable'
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
export default {
  components: {
    BodyTitle,
    InputHead,
    InputBody,
  },
  setup() {
    const store = useStore();
    const RootTableName = 'ErList';
    const tableName = 'ErInput'
    let { itemListHead, itemListBody, searchItemList } = InputTable(RootTableName)
    searchItemList.docTitleTwo = 'ER'
    searchItemList.TableName = tableName
    provide('searchItemList', searchItemList)

    let inputBodyRef = ref(null)
    let receivable = ref(toDecimal2(0))

    let payable = ref(toDecimal2(0))

    const refreshCount = (companyBCode) => {
      getCount(companyBCode).then(res => {
        if (+res == 0) {
          receivable.value = toDecimal2(0)
          payable.value = toDecimal2(0)
          return
        }
        if (+res > 0) {
          receivable.value = toDecimal2(res)
          payable.value = toDecimal2(0)
          return
        }
        if (+res < 0) {
          receivable.value = toDecimal2(0)
          payable.value = toDecimal2(-res)
          return
        }
      })
    }

    watch(() => searchItemList.documentContent.PartBCompanyId, () => {
      let companyBCode = transferToCode(searchItemList.documentContent.PartBCompanyId, 'BusinessPartnerId')
      refreshCount(companyBCode)
    })
    const tableCode = store.state.loginInitializeData.authList.viewLists.find(x => x.viewId == tableName).viewCode

    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (tableCode == store.state.loginInitializeData.ShowWindow[0]) {
          if (searchItemList.documentContent.PartBCompanyId != '') {
            refreshCount(transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId'))
          }
        }
      }
    )

    //#region 开单功能模块

    const openList = (typeName) => {

      let errCheck = checkDocCondition(searchItemList.documentContent, itemListHead, inputBodyRef.value.AddContentBody, itemListBody)
      if (errCheck.headErr) {

        ElMessage(errCheck.headErr)
        return
      }
      if (errCheck.bodyErr) {

        ElMessage(errCheck.bodyErr)
        return
      }
      getLoginData().then(res => {
        //刷新初始化数据
        if (res != 0) {
          transferLoginDataOnly(res)
        }
        getDocNo(searchItemList.docTitleTwo).then(res => {
          const erHeadCode = res
          for (let item of inputBodyRef.value.AddContentBody) {
            if (item.UnitPrice) {
              item.SourceDocumentId = erHeadCode
              item.RootSourceDocumentId = erHeadCode
              item.PartACompanyId = store.state.loginInitializeData.companyId
              item.PartBCompanyId = transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId')
              item.OutStockQty = 1
              item.OrderQty = 1
              item.DocumentId = erHeadCode
              item.Remark += '账单减免优惠'
            }
          }
          openErList(inputBodyRef.value.AddContentBody, searchItemList.documentContent, erHeadCode).then(res => {
            if (res != 'pass') {
              deleteDocCenter(erHeadCode).then(res => {
                ElMessage(res)
                return false;
              })

            }
            else {
              ElMessage({
                message:'开单成功',
                duration:2000,
                showClose:true
              })
              clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
              refreshCount(transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId'))
            }

          })
        })
      }).catch(err => {

        ElMessage(err.message)
      })
    }
    //#endregion

    return {
      searchItemList,
      inputBodyRef,
      openList,

      receivable,
      payable
    }
  }
}
</script>
<style scoped lang="less">
.EditTableLine {
  margin-top: 1rem;
  margin-left: 0.5rem;
}
.EditTableLine b {
  color: red;
  padding-left: 0.5rem;
}

.EditTableLine span {
  margin-right: 1rem;
}
</style>