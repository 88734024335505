<template>
  <div class="inputLoaderContainer">
    <BodyTitle></BodyTitle>
    <InputHead></InputHead>
    <InputBody ref="inputBodyRef" @clearScanIn="clearScanIn"></InputBody>
    <div class="EditTableLine">
      <ComScanIn :scanInValue="scanInValue" @scanIn="scanIn"></ComScanIn>
    </div>
    <div class="EditTableLastLine">
      <!-- 维护第七层的内容 -->
      <div class="EditTableFuncButton" :style="{ background: '#73a6a3' }" @click="openPopUp()">导入订单明细</div>
      <div class="EditTableFuncButton" @click="openList('TtSG')">调拨并发货</div>
      <div class="EditTableFuncButton EditTableFuncButtonAjust" @click="openList('Tt')">仅调货</div>
    </div>
    <UpPop ref="upPopRef" @uploadData="uploadData"></UpPop>
  </div>
</template>
<script>
import { computed, provide, ref, watch } from 'vue-demi'
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import InputHead from '@/components/Content/MainBodyContent/InputHead.vue'
import InputBody from '@/components/Content/MainBodyContent/InputBody.vue'
import UpPop from '@/components/Common/GlobalPop/UpPop.vue'
import { getDocNo, washDocHead, getLoginData, copyArrayDoc, updateWarning, deleteDocCenter, openDocCenter, openTtList, checkDocCondition, checkTtCompany, transferToCode, clearTableContent } from '@/hooks/OpenOrder'
import { transferLoginDataOnly } from '@/hooks/loginAffair';
import ComScanIn from '@/components/Content/FunctionUnit/ComScanIn.vue'
import InputTable from '@/hooks/InputTable'
import { useStore } from "vuex";
import { isRealNum } from '@/hooks/tools'
import { ElMessage } from 'element-plus'
export default {
  components: {
    BodyTitle,
    InputHead,
    InputBody,
    UpPop,
    ComScanIn
  },
  setup(props, { emit }) {
    const store = useStore();
    const RootTableName = 'TtList';
    let { itemListHead, itemListBody, searchItemList } = InputTable(RootTableName)
    searchItemList.docTitleTwo = 'TT'
    searchItemList.TableName = 'TtInput'
    provide('searchItemList', searchItemList)

    let inputBodyRef = ref(null)
    //#endregion

    //#region 扫描组件
    let scanInValue = ref('');

    const scanIn = (res) => {
      inputBodyRef.value.scanInData(res)
    }

    const clearScanIn = () => {
      scanInValue.value = '';
    }

    //#endregion

    //#region 上传模块
    let upPopRef = ref(null)
    const openPopUp = () => {
      upPopRef.value.openPopUp()
    }

    const uploadData = (dataUpload) => {
      inputBodyRef.value.fillUploadData(dataUpload)
    }
    //#endregion

    const openList = (typeName) => {

      let errCheck = checkDocCondition(searchItemList.documentContent, itemListHead, inputBodyRef.value.AddContentBody, itemListBody)

      if (errCheck.headErr) {

        ElMessage(errCheck.headErr)
        return
      }
      if (errCheck.bodyErr) {

        ElMessage(errCheck.bodyErr)
        return
      }
      const partBCompany = transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId')
      const outWarehouseId = transferToCode(searchItemList.documentContent.OutWareHouseId, 'WareHouseId')
      let sendGoodsCheck = false;
      if (typeName == 'Tt') {
        sendGoodsCheck = true
      }

      if (partBCompany >= 20000000) {

        ElMessage('调货单位必须是线上往来单位')
        return;
      }
      checkTtCompany(partBCompany, outWarehouseId).then(res => {
        if (res != 'pass') {

          ElMessage('调入方必须有仓库与调出仓库同源')
          return;
        }
        getLoginData().then(res => {
          //刷新初始化数据
          if (res != 0) {
            transferLoginDataOnly(res)
          }
          getDocNo(searchItemList.docTitleTwo).then(res => {
            const OutWareHouseName = isRealNum(searchItemList.documentContent.OutWareHouseId) ? transferToName(searchItemList.documentContent.OutWareHouseId, 'WareHouseId') : searchItemList.documentContent.OutWareHouseId
            let CommodityNameArr = []
            let docTitle = res

            for (let item of inputBodyRef.value.AddContentBody) {
              if (item.CommodityName) {
                item.PartACompanyId = store.state.loginInitializeData.companyId
                item.DocumentId = docTitle
                item.PartBCompanyId = transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId')
                item.InStockQty = item.OrderQty
                item.OutStockQty = item.OrderQty
                item.PartBCompanyId = partBCompany
              }
              if (!item.VirtualGoods && item.CommodityId) {
                CommodityNameArr.push(item.CommodityId)
              }
            }
            openTtList(inputBodyRef.value.AddContentBody, searchItemList.documentContent, docTitle, sendGoodsCheck).then(res => {
              if (res != "pass") {

                ElMessage(res)
                deleteDocCenter(docTitle).then(res => {
                  return;
                })
              }
              else {
                if (OutWareHouseName == '主仓库') {
                  setTimeout(() => {
                    updateWarning(store.state.loginInitializeData.companyId, CommodityNameArr)
                  }, 0);
                }
                ElMessage({
                  message: '开单成功',
                  duration: 2000,
                  showClose: true
                })
                clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                emit('refreshMsg')
              }

            })
          })
        })
      }).catch(err => {

        ElMessage(err.message)
      })

    }
    //#endregion

    //#endregion

    return {
      searchItemList,
      inputBodyRef,
      scanInValue,
      scanIn,
      clearScanIn,
      openPopUp,
      upPopRef,
      uploadData,
      openList
    }
  }
}
</script>
<style scoped lang="less">
.EditTableFuncButtonAjust {
  margin-right: 45rem;
}
</style>