<template>
  <TableLoaderShow></TableLoaderShow>
</template>

<script>
import TableLoaderShow from '@/components/Content/MainBodyContent/TableLoaderShow.vue'
import { onMounted, onUnmounted, provide, reactive } from 'vue-demi';
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const TableName = 'BrandList';
    let searchItemList = store.state.loginInitializeData.pageNameManage.filter(x=>x.tableName == TableName)[0]
    provide('searchItemList', searchItemList)

    onMounted(() => {
    })
    onUnmounted(() => {

    })
    return {
      searchItemList
    }
  },
  components: {

    TableLoaderShow

  }
}
</script>

<style scoped lang="less">
</style>