<template>
  <div class="MsgContainer" v-show="openInfo">
    <div class="MsgBarTop" :style="{height:rankHeight}">
      <div class="rankingShow" v-show="showRank">

        <div class="lineShow">
          <div class="contentBox2"><span class="hideButton" @click="closeRank()"></span></div>
          <div class="iconBox">店铺</div>
          <div class="funcBox3"></div>
          <div class="iconBox">个人</div>
          <div class="funcBox3"></div>

        </div>
        <!-- <div class="lineShow">
          <div class="contentBox1 smallWord">今日销售</div>
          <div class="contentBox smallWord">{{todaySales != '' ? todaySales + '元' : ''}}</div>
          <div class="funcBox rankWord">
            <span class="rankIconGold" v-show="todayRank == 1"></span>
            <span class="rankIconSilver" v-show="todayRank == 2"></span>
            <span class="rankIconCopper" v-show="todayRank == 3"></span>
            {{todayRank < 4 ? '' : '第' + todayRank + '名'}}
          </div>
          <div class="contentBox smallWord">2元</div>
          <div class="funcBox rankWord">91</div>

        </div> -->

        <div class="lineShow">
          <div class="contentBox1 smallWord">今日</div>
          <div class="contentBox smallWord">{{todayProfitCompany}}</div>

          <el-popover placement="right-start" :width="500" trigger="click">
            <template #reference>
              <div class="funcBox rankWord">
                <span class="rankIconGold" v-show="todayProfitCompanyRank == 1"></span>
                <span class="rankIconSilver" v-show="todayProfitCompanyRank == 2"></span>
                <span class="rankIconCopper" v-show="todayProfitCompanyRank == 3"></span>
                {{todayProfitCompanyRank < 4 ? '' : todayProfitCompanyRank + '名'}}
              </div>
            </template>
            <el-table :data="todayProfitCompanyArr">
              <el-table-column width="50" property="rank" label="排名" />
              <el-table-column width="300" property="companyName" label="店铺" />
              <el-table-column width="100" property="profit" label="利润" />
            </el-table>
          </el-popover>

          <div class="contentBox smallWord">{{todayProfitStaff}}</div>

          <el-popover placement="right-start" :width="400" trigger="click">
            <template #reference>
              <div class="funcBox rankWord">
                <span class="rankIconGold" v-show="todayProfitStaffRank == 1"></span>
                <span class="rankIconSilver" v-show="todayProfitStaffRank == 2"></span>
                <span class="rankIconCopper" v-show="todayProfitStaffRank == 3"></span>
                {{todayProfitStaffRank < 4 ? '' : todayProfitStaffRank + '名'}}
              </div>
            </template>
            <el-table :data="todayProfitStaffArr">
              <el-table-column width="50" property="rank" label="排名" />
              <el-table-column width="200" property="staffName" label="名称" />
              <el-table-column width="100" property="profit" label="利润" />
            </el-table>
          </el-popover>

        </div>

        <!-- <div class="lineShow">
          <div class="contentBox1 smallWord">当月销售</div>
          <div class="contentBox smallWord">{{thisMonthSales != '' ? thisMonthSales + '元' : ''}}</div>
          <div class="funcBox rankWord">
            <span class="rankIconGold" v-show="thisMonthRank == 1"></span>
            <span class="rankIconSilver" v-show="thisMonthRank == 2"></span>
            <span class="rankIconCopper" v-show="thisMonthRank == 3"></span>
            {{thisMonthRank < 4 ? '' : '第' + thisMonthRank + '名'}}
          </div>
          <div class="contentBox smallWord">6元</div>
          <div class="funcBox rankWord">12</div>

        </div> -->

        <div class="lineShow">
          <div class="contentBox1 smallWord">当月</div>
          <div class="contentBox smallWord">{{monthProfitCompany}}</div>

          <el-popover placement="right-start" :width="500" trigger="click">
            <template #reference>
              <div class="funcBox rankWord">
                <span class="rankIconGold" v-show="monthProfitCompanyRank == 1"></span>
                <span class="rankIconSilver" v-show="monthProfitCompanyRank == 2"></span>
                <span class="rankIconCopper" v-show="monthProfitCompanyRank == 3"></span>
                {{monthProfitCompanyRank < 4 ? '' : monthProfitCompanyRank + '名'}}
              </div>
            </template>
            <el-table :data="monthProfitCompanyArr">
              <el-table-column width="50" property="rank" label="排名" />
              <el-table-column width="300" property="companyName" label="店铺" />
              <el-table-column width="100" property="profit" label="利润" />
            </el-table>
          </el-popover>

          <div class="contentBox smallWord">{{monthProfitStaff}}</div>

          <el-popover placement="right-start" :width="400" trigger="click">
            <template #reference>
              <div class="funcBox rankWord">
                <span class="rankIconGold" v-show="monthProfitStaffRank == 1"></span>
                <span class="rankIconSilver" v-show="monthProfitStaffRank == 2"></span>
                <span class="rankIconCopper" v-show="monthProfitStaffRank == 3"></span>
                {{monthProfitStaffRank < 4 ? '' : monthProfitStaffRank + '名'}}
              </div>
            </template>
            <el-table :data="monthProfitStaffArr">
              <el-table-column width="50" property="rank" label="排名" />
              <el-table-column width="200" property="staffName" label="名称" />
              <el-table-column width="100" property="profit" label="利润" />
            </el-table>
          </el-popover>

        </div>

      </div>
      <div class="openRanking" @click="openRank()" v-show="!showRank">
        
      </div>
      <div class="chooseButton">
        <el-switch v-model="value1" class="mb-2" inactive-text="自动刷新">
        </el-switch>
      </div>
      <div class="refreshIcon" @click="updateProfitTimes(),refreshMsgBar()"></div>
      <img src="../../../assets/image/Padding/loading6.gif" class="padding" v-show="messageBarRefreashing" />
    </div>

    <div class="MsgBarTitle">状态监控区</div>
    <div class="MsgBarContent">
      <el-scrollbar class="scorllBox">
        <div v-for="(item,index) in MessageShowArrUp" :class="[{darkShow:item.Qty == 0},{'activeMessageBar':item.Id == activeId}]" :key="item.Id + 'MsgBar'" class="scrollbar-demo-item" @click="goFucView(item.Id)">
          <div class="MsgCon1">
            <div class="StatusCircle" :style="{ backgroundColor: MsgColors[index] }"></div>
          </div>
          <div class="MsgCon2">{{ item.Show }}</div>
          <div class="MsgCon3" :class="[{'ShowBig':item.change},{'greyWord':item.Qty == 0}]"><span>{{ item.Qty }}</span></div>
          <div class="MsgCon4"></div>
        </div>
      </el-scrollbar>

      <!-- <div class="MsgBox" v-for="(item,index) in MessageShowArrUp" :key="item.Id + 'MsgBar'" @click="goFucView(item.Id)">
          <div class="MsgCon1">
            <div class="StatusCircle" :style="{ backgroundColor: MsgColors[index] }"></div>
          </div>
          <div class="MsgCon2">{{ item.Show }}</div>
          <div class="MsgCon3" :class="[{'ShowBig':item.change},{'greyWord':item.Qty == 0}]"><span>{{ item.Qty }}</span></div>
          <div class="MsgCon4"></div>
        </div> -->

    </div>
  </div>
</template>
<script>
import { reactive, ref, toRefs } from "@vue/reactivity";
import requestData from '@/hooks/requestData';
import { onUnmounted, watch } from 'vue-demi';
import { useStore } from "vuex";
import { changeDayToInt, ChangeYMD, copyArray, getFirstDayOfMonth, getLastDayOfMonth, isRealNum, getRank } from '@/hooks/tools';
import { getAllCompanyProfitListByStaff, getProfitReportByDateCache, getSalesReportByDateCache, getStaffProfitPerDayCache } from '@/hooks/getCache';
import { transferToName } from '@/hooks/OpenOrder';
import { ElMessage } from 'element-plus'
export default {
  setup() {
    const store = useStore()
    let openInfo = ref(true);
    const changeOpenInfo = (data) => {
      openInfo.value = data;
    };

    let value1 = ref(true)

    let refreshSeconds = ref(60);

    let MessageShowArr = reactive([
      { Id: "RepairRecordList", Show: "维修机器", Qty: 0, change: false, mainShow: true, authorityShow: true, showWeight: 0 },
      { Id: "OnTheWayListForPurchase", Show: "采购在途", Qty: 0, change: false, mainShow: true, authorityShow: true, showWeight: 0 },
      { Id: "OnTheWayListForSale", Show: "客户订单", Qty: 0, change: false, mainShow: true, authorityShow: true, showWeight: 0 },
      { Id: "MeterialSaleList", Show: "零件出售", Qty: 0, change: false, mainShow: true, authorityShow: true, showWeight: 0 },
      { Id: "MeterialSaleSolveList", Show: "客户出售", Qty: 0, change: false, mainShow: true, authorityShow: true, showWeight: 0 },
      { Id: "MoneyToBePayImmediateList", Show: "等待付款", Qty: 0, change: false, mainShow: true, authorityShow: true, showWeight: 0 },
      { Id: "MachineToBeSolve", Show: "二手库存", Qty: 0, change: false, mainShow: true, authorityShow: true, showWeight: 0 },
      { Id: "ToBeShippedSolve", Show: "等待发货", Qty: 0, change: false, mainShow: true, authorityShow: true, showWeight: 0 },
      { Id: "MachineQueryListSolve", Show: "客户询价", Qty: 0, change: false, mainShow: true, authorityShow: true, showWeight: 0 },
      { Id: "ObTracingList", Show: "客订跟踪", Qty: 0, change: false, mainShow: true, authorityShow: true, showWeight: 0 },
      { Id: "MachineQueryListManage", Show: "机器询价", Qty: 0, change: false, mainShow: true, authorityShow: true, showWeight: 0 },
      { Id: "RecyleOnTheWayList", Show: "回收入库", Qty: 0, change: false, mainShow: true, authorityShow: true, showWeight: 0 },
      { Id: "StOnTheWayList", Show: "调拨入库", Qty: 0, change: false, mainShow: true, authorityShow: true, showWeight: 0 },
      { Id: "AccountsChangeOnTheWayList", Show: "账单确认", Qty: 0, change: false, mainShow: true, authorityShow: true, showWeight: 0 },
    ])

    let MessageShowArrUp = reactive([])

    let viewListAuth = store.state.loginInitializeData.viewListForSearch

    const sortMessageBar = () => {
      MessageShowArrUp.splice(0)
      const MessageShowArrUpcopy = MessageShowArr.sort(function (a, b) { return b.Qty - a.Qty }).sort(function (a, b) { return a.showWeight - b.showWeight })
      for (let item of MessageShowArrUpcopy) {
        if (viewListAuth.find(x => x.viewId == item.Id)) {
          MessageShowArrUp.push(item)
        }
      }
    }

    const goFucView = (code) => {

      const codeNew = store.state.loginInitializeData.authList.viewLists.find(x => x.viewId == code).viewCode

      store.commit('GoFunctionWindowWithCode', codeNew)
    }

    let messageBarRefreashing = ref(false)

    const partAComId = store.state.loginInitializeData.companyId
    const staffId = store.state.loginInitializeData.staffId

    let todayProfitCompany = ref('')

    let todayProfitCompanyRank = ref('')

    let todayProfitStaff = ref('')

    let todayProfitStaffRank = ref('')

    let monthProfitCompany = ref('')

    let monthProfitCompanyRank = ref('')

    let monthProfitStaff = ref('')

    let monthProfitStaffRank = ref('')

    let monthProfitCompanyArr = reactive([])

    let monthProfitStaffArr = reactive([])

    let todayProfitCompanyArr = reactive([])

    let todayProfitStaffArr = reactive([])


    const updateSalesReport = () => {

      const today = new Date()
      today.setHours(0);
      today.setSeconds(0);
      today.setMinutes(0);
      const todayInt = ChangeYMD(today)
      const monthFirstDay = getFirstDayOfMonth()
      const monthLastDay = getLastDayOfMonth()

      getAllCompanyProfitListByStaff(monthFirstDay, monthLastDay).then(res => {

        monthProfitCompanyArr.splice(0)
        monthProfitStaffArr.splice(0)
        todayProfitCompanyArr.splice(0)
        todayProfitStaffArr.splice(0)

        for (let item of res) {
          item.docuDate = item.sortTarget.substring(0, 10)
          if (item.sortTarget.length > 22) {
            item.staffId = item.sortTarget.substring(18, 27)
          }
          else {
            item.staffId = ''
          }

          if (monthProfitStaffArr.find(x => x.staffId == item.staffId)) {
            monthProfitStaffArr.find(x => x.staffId == item.staffId).profit += parseFloat(item.amount)
          }
          else {
            let b = {
              staffId: item.staffId,
              profit: parseFloat(item.amount),
              companyId: item.partACompanyId
            }
            monthProfitStaffArr.push(b)
          }


          if (todayInt == item.docuDate) {
            if (todayProfitStaffArr.find(x => x.staffId == item.staffId)) {
              todayProfitStaffArr.find(x => x.staffId == item.staffId).profit += parseFloat(item.amount)
            }
            else {
              let b = {
                staffId: item.staffId,
                profit: parseFloat(item.amount),
                companyId: item.partACompanyId
              }
              todayProfitStaffArr.push(b)
            }
          }
        }

        const len3 = monthProfitStaffArr.length

        for (let j = len3 - 1; j >= 0; j--) {
          monthProfitStaffArr[j].staffName = transferToName(monthProfitStaffArr[j].staffId, 'GroupStaffs')
          if (isRealNum(monthProfitStaffArr[j].staffName)) {
            monthProfitStaffArr.splice(j, 1)
            continue;
          }
          monthProfitStaffArr[j].staffName += '-' + transferToName(monthProfitStaffArr[j].companyId, 'GroupCompanys')
        }

        const len5 = todayProfitStaffArr.length

        for (let j = len5 - 1; j >= 0; j--) {
          todayProfitStaffArr[j].staffName = transferToName(todayProfitStaffArr[j].staffId, 'GroupStaffs')
          if (isRealNum(todayProfitStaffArr[j].staffName)) {
            todayProfitStaffArr.splice(j, 1)
            continue
          }
          todayProfitStaffArr[j].staffName += '-' + transferToName(todayProfitStaffArr[j].companyId, 'GroupCompanys')
        }


        for (let item of monthProfitStaffArr) {
          if (monthProfitCompanyArr.find(x => x.companyId == item.companyId)) {
            monthProfitCompanyArr.find(x => x.companyId == item.companyId).profit += parseFloat(item.profit)
          }
          else {
            let b = {
              companyId: item.companyId,
              profit: item.profit,
              companyName: transferToName(item.companyId, 'GroupCompanys')
            }
            monthProfitCompanyArr.push(b)
          }
        }

        for (let item of todayProfitStaffArr) {
          if (todayProfitCompanyArr.find(x => x.companyId == item.companyId)) {
            todayProfitCompanyArr.find(x => x.companyId == item.companyId).profit += parseFloat(item.profit)
          }
          else {
            let b = {
              companyId: item.companyId,
              profit: item.profit,
              companyName: transferToName(item.companyId, 'GroupCompanys')
            }
            todayProfitCompanyArr.push(b)
          }
        }

        const len4 = monthProfitCompanyArr.length

        for (let j = len4 - 1; j >= 0; j--) {
          monthProfitCompanyArr[j].companyName = transferToName(monthProfitCompanyArr[j].companyId, 'GroupCompanys')
          if (isRealNum(monthProfitCompanyArr[j].companyName)) {
            monthProfitCompanyArr.splice(j, 1)
          }
        }

        const len6 = todayProfitCompanyArr.length

        for (let j = len6 - 1; j >= 0; j--) {
          todayProfitCompanyArr[j].companyName = transferToName(todayProfitCompanyArr[j].companyId, 'GroupCompanys')
          if (isRealNum(todayProfitCompanyArr[j].companyName)) {
            todayProfitCompanyArr.splice(j, 1)
          }
        }

        monthProfitCompanyArr = monthProfitCompanyArr.sort(function (a, b) { return b.profit - a.profit })

        monthProfitStaffArr = monthProfitStaffArr.sort(function (a, b) { return b.profit - a.profit })

        todayProfitStaffArr = todayProfitStaffArr.sort(function (a, b) { return b.profit - a.profit })

        todayProfitCompanyArr = todayProfitCompanyArr.sort(function (a, b) { return b.profit - a.profit })


        const len7 = monthProfitStaffArr.length

        for (let j = len7 - 1; j >= 0; j--) {
          monthProfitStaffArr[j].profit = monthProfitStaffArr[j].profit.toFixed(0)
          monthProfitStaffArr[j].rank = j + 1
          if (staffId == monthProfitStaffArr[j].staffId) {
            monthProfitStaffRank.value = j + 1
            monthProfitStaff.value = monthProfitStaffArr[j].profit
          }
        }

        if (monthProfitStaff.value == '') {
          monthProfitStaff.value = 0
          monthProfitStaffRank.value = getRank(monthProfitStaffArr, 'profit')
        }

        const len8 = todayProfitStaffArr.length

        const rankRemark = todayProfitStaffRank.value

        const comRankRemark = todayProfitCompanyRank.value

        for (let j = len8 - 1; j >= 0; j--) {

          todayProfitStaffArr[j].profit = todayProfitStaffArr[j].profit.toFixed(0)
          todayProfitStaffArr[j].rank = j + 1

          if (staffId == todayProfitStaffArr[j].staffId) {
            todayProfitStaffRank.value = j + 1
            todayProfitStaff.value = todayProfitStaffArr[j].profit
          }
        }

        if (todayProfitStaff.value == '') {
          todayProfitStaff.value = 0
          todayProfitStaffRank.value = getRank(todayProfitStaffArr, 'profit')
        }

        if (rankRemark != '') {
          if (todayProfitStaffRank.value > rankRemark) {
            ElMessage(`你的个人排名下降了,当前排名第${todayProfitStaffRank.value}名`)
          }
          if (todayProfitStaffRank.value < rankRemark) {
            ElMessage(`你的个人排名上升了,当前排名第${todayProfitStaffRank.value}名`)
          }
        }

        const len9 = monthProfitCompanyArr.length

        for (let j = len9 - 1; j >= 0; j--) {
          monthProfitCompanyArr[j].profit = monthProfitCompanyArr[j].profit.toFixed(0)
          monthProfitCompanyArr[j].rank = j + 1
          if (partAComId == monthProfitCompanyArr[j].companyId) {
            monthProfitCompanyRank.value = j + 1
            monthProfitCompany.value = monthProfitCompanyArr[j].profit
          }
        }
        if (monthProfitCompany.value == '') {
          monthProfitCompany.value = 0
          monthProfitCompanyRank.value = getRank(monthProfitCompanyArr, 'profit')
        }

        const len10 = todayProfitCompanyArr.length

        for (let j = len10 - 1; j >= 0; j--) {
          todayProfitCompanyArr[j].profit = todayProfitCompanyArr[j].profit.toFixed(0)
          todayProfitCompanyArr[j].rank = j + 1
          if (partAComId == todayProfitCompanyArr[j].companyId) {
            todayProfitCompanyRank.value = j + 1
            todayProfitCompany.value = todayProfitCompanyArr[j].profit
          }
        }

        if (todayProfitCompany.value == '') {
          todayProfitCompany.value = 0
          todayProfitCompanyRank.value = getRank(todayProfitCompanyArr, 'profit')
        }

        if (comRankRemark != '') {
          if (todayProfitCompanyRank.value > comRankRemark) {
            ElMessage(`你的店铺排名下降了,当前排名第${todayProfitCompanyRank.value}名`)
          }
          if (todayProfitCompanyRank.value < comRankRemark) {
            ElMessage(`你的店铺排名上升了,当前排名第${todayProfitCompanyRank.value}名`)
          }
        }

        monthProfitCompanyArr.splice(10)
        monthProfitStaffArr.splice(10)
        todayProfitCompanyArr.splice(10)
        todayProfitStaffArr.splice(10)

      })
    }

    updateSalesReport()

    let profitUpdateTimes = 0


    const updateSalesReportReal = () => {
      if (profitUpdateTimes <= 5) {
        profitUpdateTimes++
      }
      else {
        profitUpdateTimes = 0
        updateSalesReport()
      }
    }

    const updateProfitTimes = () => {
      profitUpdateTimes = 6
    }

    let bufferTimes = 0


    const refreshMsgBar = () => {
      if (bufferTimes == 1) {
        return
      }

      bufferTimes = 1

      setTimeout(() => {
        bufferTimes = 0
      }, 5000)

      messageBarRefreashing.value = true

      updateSalesReportReal()
      let config = {
        method: "get",
        url: '/MessageCenter/GetMessage',
      };
      requestData.GetCache(config).then(res => {
        messageBarRefreashing.value = false
        if (MessageShowArr.find(x => x.Id == 'RepairRecordList').Qty != res?.repairRecordList) {
          MessageShowArr.find(x => x.Id == 'RepairRecordList').Qty = res?.repairRecordList
          MessageShowArr.find(x => x.Id == 'RepairRecordList').change = true
          setTimeout(() => {
            MessageShowArr.find(x => x.Id == 'RepairRecordList').change = false
          }, 500)
        }
        if (MessageShowArr.find(x => x.Id == 'OnTheWayListForPurchase').Qty != res?.purchaseOnTheWay) {
          MessageShowArr.find(x => x.Id == 'OnTheWayListForPurchase').Qty = res?.purchaseOnTheWay
          MessageShowArr.find(x => x.Id == 'OnTheWayListForPurchase').change = true
          setTimeout(() => {
            MessageShowArr.find(x => x.Id == 'OnTheWayListForPurchase').change = false
          }, 500)
        }
        if (MessageShowArr.find(x => x.Id == 'OnTheWayListForSale').Qty != res?.customerOrder) {
          MessageShowArr.find(x => x.Id == 'OnTheWayListForSale').Qty = res?.customerOrder
          MessageShowArr.find(x => x.Id == 'OnTheWayListForSale').change = true
          setTimeout(() => {
            MessageShowArr.find(x => x.Id == 'OnTheWayListForSale').change = false
          }, 500)
        }
        if (MessageShowArr.find(x => x.Id == 'MeterialSaleList').Qty != res?.salesOntheWay) {
          MessageShowArr.find(x => x.Id == 'MeterialSaleList').Qty = res?.salesOntheWay
          MessageShowArr.find(x => x.Id == 'MeterialSaleList').change = true
          setTimeout(() => {
            MessageShowArr.find(x => x.Id == 'MeterialSaleList').change = false
          }, 500)
        }
        if (MessageShowArr.find(x => x.Id == 'MeterialSaleSolveList').Qty != res?.customerSalesOrder) {
          MessageShowArr.find(x => x.Id == 'MeterialSaleSolveList').Qty = res?.customerSalesOrder
          MessageShowArr.find(x => x.Id == 'MeterialSaleSolveList').change = true
          setTimeout(() => {
            MessageShowArr.find(x => x.Id == 'MeterialSaleSolveList').change = false
          }, 500)
        }
        if (MessageShowArr.find(x => x.Id == 'MoneyToBePayImmediateList').Qty != res?.moneyToBePay) {
          MessageShowArr.find(x => x.Id == 'MoneyToBePayImmediateList').Qty = res?.moneyToBePay
          MessageShowArr.find(x => x.Id == 'MoneyToBePayImmediateList').change = true
          setTimeout(() => {
            MessageShowArr.find(x => x.Id == 'MoneyToBePayImmediateList').change = false
          }, 500)
        }
        if (MessageShowArr.find(x => x.Id == 'MachineToBeSolve').Qty != res?.machineToBeSolve) {
          MessageShowArr.find(x => x.Id == 'MachineToBeSolve').Qty = res?.machineToBeSolve
          MessageShowArr.find(x => x.Id == 'MachineToBeSolve').change = true
          setTimeout(() => {
            MessageShowArr.find(x => x.Id == 'MachineToBeSolve').change = false
          }, 500)
        }
        if (MessageShowArr.find(x => x.Id == 'ToBeShippedSolve').Qty != res?.tobeShipped) {
          MessageShowArr.find(x => x.Id == 'ToBeShippedSolve').Qty = res?.tobeShipped
          MessageShowArr.find(x => x.Id == 'ToBeShippedSolve').change = true
          setTimeout(() => {
            MessageShowArr.find(x => x.Id == 'ToBeShippedSolve').change = false
          }, 500)
        }
        if (MessageShowArr.find(x => x.Id == 'MachineQueryListSolve').Qty != res?.machineQuery) {
          MessageShowArr.find(x => x.Id == 'MachineQueryListSolve').Qty = res?.machineQuery
          MessageShowArr.find(x => x.Id == 'MachineQueryListSolve').change = true
          setTimeout(() => {
            MessageShowArr.find(x => x.Id == 'MachineQueryListSolve').change = false
          }, 500)
        }
        if (MessageShowArr.find(x => x.Id == 'ObTracingList').Qty != res?.obChasing) {
          MessageShowArr.find(x => x.Id == 'ObTracingList').Qty = res?.obChasing
          MessageShowArr.find(x => x.Id == 'ObTracingList').change = true
          setTimeout(() => {
            MessageShowArr.find(x => x.Id == 'ObTracingList').change = false
          }, 500)
        }
        if (MessageShowArr.find(x => x.Id == 'MachineQueryListManage').Qty != res?.machineQueryListManage) {
          MessageShowArr.find(x => x.Id == 'MachineQueryListManage').Qty = res?.machineQueryListManage
          MessageShowArr.find(x => x.Id == 'MachineQueryListManage').change = true
          setTimeout(() => {
            MessageShowArr.find(x => x.Id == 'MachineQueryListManage').change = false
          }, 500)
        }
        if (MessageShowArr.find(x => x.Id == 'RecyleOnTheWayList').Qty != res?.recyleOnTheWayList) {
          MessageShowArr.find(x => x.Id == 'RecyleOnTheWayList').Qty = res?.recyleOnTheWayList
          MessageShowArr.find(x => x.Id == 'RecyleOnTheWayList').change = true
          setTimeout(() => {
            MessageShowArr.find(x => x.Id == 'RecyleOnTheWayList').change = false
          }, 500)
        }
        if (MessageShowArr.find(x => x.Id == 'StOnTheWayList').Qty != res?.stOnTheWayList) {
          MessageShowArr.find(x => x.Id == 'StOnTheWayList').Qty = res?.stOnTheWayList
          MessageShowArr.find(x => x.Id == 'StOnTheWayList').change = true
          setTimeout(() => {
            MessageShowArr.find(x => x.Id == 'StOnTheWayList').change = false
          }, 500)
        }
        if (MessageShowArr.find(x => x.Id == 'AccountsChangeOnTheWayList').Qty != res?.accountsChangeOnTheWayList) {
          MessageShowArr.find(x => x.Id == 'AccountsChangeOnTheWayList').Qty = res?.accountsChangeOnTheWayList
          MessageShowArr.find(x => x.Id == 'AccountsChangeOnTheWayList').change = true
          setTimeout(() => {
            MessageShowArr.find(x => x.Id == 'AccountsChangeOnTheWayList').change = false
          }, 500)
        }
        sortMessageBar()
        setTimeout(() => {
          sortMessageBar()
        }, 1000)
      }).catch(err => {
        messageBarRefreashing.value = false
      })
    }
    let id;

    const openRefresh = () => {
      if (!id) {
        id = setInterval(refreshMsgBar, refreshSeconds.value * 1000)
      }
    }

    const closeRefresh = () => {
      clearInterval(id)
    }

    openRefresh()

    const MsgColors = reactive(["#f8696b", "#f8766d", "#f98470", "#fa9272", "#fa9f75", "#fbad78", "#fcbb7a", "#fdc87d", "#fdd680", "#fee482", '#f7e984', '#e7e483', '#d6e082', '#c6db81', '#b6d680', '#a5d17f', '#95cd7e', '#84c87d', '#74c37c', '#63be7b']);

    watch(value1, (newValue, oldValue) => {
      if (newValue) {
        openRefresh()
      }
      else {
        closeRefresh()
      }
    })

    onUnmounted(() => {
      closeRefresh()
    })

    let activeId = ref('')

    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        activeId.value = store.state.loginInitializeData.authList.viewLists.find(x => x.viewCode == newValue)?.viewId
      }
    )

    let showRank = ref(true)

    let rankHeight = ref('9rem')

    const closeRank = () => {
      showRank.value = false
      rankHeight.value = '5rem'
    }

    const openRank = () => {
      showRank.value = true
      rankHeight.value = '9rem'
    }



    refreshMsgBar()

    return {
      MsgColors,
      openInfo,
      changeOpenInfo,
      value1,
      refreshMsgBar,
      MessageShowArr, goFucView,
      MessageShowArrUp,
      activeId,
      messageBarRefreashing,
      showRank,
      closeRank,
      rankHeight,
      openRank,
      partAComId,
      todayProfitCompany,
      todayProfitCompanyRank,
      todayProfitStaff,
      todayProfitStaffRank,
      monthProfitCompany,
      monthProfitCompanyRank,
      monthProfitStaff,
      monthProfitStaffRank,
      monthProfitCompanyArr,
      todayProfitCompanyArr,
      monthProfitStaffArr,
      todayProfitStaffArr,
      updateProfitTimes

    };
  },
  components: {},
};
</script>
<style scoped lang="less">
@MsgBarTopHeight: 14rem;
.MsgBarTop {
  width: 100%;
  // background-image: url("~@/assets/image/MsgBar/images/MsgBlueBar_03.png");
  // background-position: 0px 9.5rem;
  // background-size: 18rem 3rem;
  position: relative;
  transition: all 1s;
}

.chooseButton {
  position: absolute;
  right: 0.5rem;
  bottom: 1rem;
}
.refreshIcon {
  position: absolute;
  font-family: "icomoon";
  font-size: 1.5rem;
  color: #ed1c24;
  bottom: 1rem;
  left: 0.5rem;
}
.refreshIcon:hover {
  cursor: pointer;
  transform: translateY(-1px);
}

.MsgBarTitle {
  position: relative;
  height: 2.25rem;
  width: 100%;
  background-image: url(~@/assets/image/MsgBar/gauge.png);
  background-position: 2.5rem 0px;
  text-indent: 6.25rem;
  font-size: 0.88rem;
  line-height: 1.25rem;
}
.MsgBarTitle::after {
  position: absolute;
  content: "";
  font-family: "icomoon";
  right: 1.25rem;
  color: #409eff;
}
.MsgBarContent {
  width: 100%;
  height: calc(100% - 20rem);
  overflow: hidden;
}
.MsgBox {
  display: flex;
  height: 3.75rem;
  line-height: 3.75rem;
  cursor: pointer;
}
.StatusCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 0.63rem;
  height: 0.63rem;
  border-radius: 50%;
}

.MsgCon1 {
  position: relative;
  width: 6.63rem;
  height: 2.75rem;
  line-height: 2.75rem;
}
.MsgCon2 {
  height: 2.75rem;
  line-height: 2.75rem;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
}
.MsgCon4 {
  height: 2.75rem;
  line-height: 2.75rem;
}
.MsgCon3 {
  width: 3.13rem;
  position: relative;
  height: 2.75rem;
  line-height: 2.75rem;
  padding-left: 1.25rem;
  font-weight: 700;
  color: #ff0000;
  cursor: pointer;
}
.greyWord {
  color: #bfbfbf;
}
.MsgCon3 span {
  transition: all 1s;
}

.MsgContainer {
  height: 100%;
  background-color: #fff;
}
.ShowBig {
  font-size: 1.5rem;
}

.scrollbar-demo-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 2.75rem;
  margin: 10px;
  text-align: center;
  border-radius: 4px;
  // background: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
}
.scorllBox {
  height: 100%;
}
.activeMessageBar {
  background-color: #1e87f0;
  color: white;
}

.padding {
  width: 4rem;
  position: absolute;
  left: 2rem;
  bottom: 0.5rem;
}
.darkShow {
  color: #d9d9d9;
}
.lineShow {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  display: flex;
  text-align: center;
}

.iconBox {
  font-family: "icomoon";
  font-size: 0.7rem !important;
  font-weight: 700;
  width: 4rem;
  color: #1e87f0;
}
.contentBox {
  width: 4rem;
}
.contentBox1 {
  width: 4rem;
  font-weight: 700;
  color: #1e87f0 !important;
}
.contentBox2 {
  width: 4rem;
  color: #bfbfbf;
}
.contentBox2:hover {
  cursor: pointer;
}
.funcBox3 {
  font-family: "icomoon";
  width: 3rem;
  color: #1e87f0;
}
.funcBox {
  width: 3rem;
}

.rankWord:hover {
  cursor: pointer;
}
.smallWord {
  font-size: 0.7rem !important;
  color: #000;
}
.rankWord {
  font-size: 0.8rem;
  color: #ff0000;
}
.rankIconGold {
  font-family: "icomoon";
  color: #fed21c;
}
.rankIconSilver {
  font-family: "icomoon";
  color: #e9ecf5;
}
.rankIconCopper {
  font-family: "icomoon";
  color: #b38249;
}
.hideButton {
  font-family: "icomoon";
}
.openRanking {
  position: absolute;
  font-family: "icomoon";
  top: 0.8rem;
  right: 0.8rem;
  color: #1e87f0;
}
.openRanking:hover {
  cursor: pointer;
}
</style>