<template>
  <div class="searchContentContainer">
    <div class="searchItemContainer">
      <label class="searchinputLabel" for="PartBCompanyIdForAccountsChange">往来单位:</label>
      <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'PartBCompanyId'}"><select v-model="searchItems['PartBCompanyId']" class="searchinput" id="PartBCompanyIdForAccountsChange" @focus="setActiveId('PartBCompanyId')" @blur="setActiveId('')" @change="reSearch()">
          <option value="" selected="selected">所有</option>
          <option v-for="item in searchItemList.PartBCompanyId.Filter" :key="item.beforeTransfer + 'AccountsChange'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
        </select></div>
    </div>

    <div class="searchItemContainer">
      <label class="searchinputLabel" for="CommodityNameAccChange">商品名称:</label>
      <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'CommodityName'}"><input v-model="searchItems['CommodityName']" class="searchinput" id="CommodityNameAccChange" type="text" @focus="setActiveId('CommodityName')" @blur="setActiveId('')"></div>
    </div>
    <div class="searchItemContainer">
      <label class="searchinputLabel" for="CommodityIdAccChange">商品编码:</label>
      <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'CommodityId'}"><input v-model="searchItems['CommodityId']" class="searchinput" id="CommodityIdAccChange" type="number" @focus="setActiveId('CommodityId')" @blur="setActiveId('')"></div>
    </div>

    <div class="searchItemContainer">
      <label class="searchinputLabel" for="DocumentDateLAccountsChange">查询日期</label>
      <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateL'}"><input v-model="searchItems['DocumentDateL']" class="searchinput" id="DocumentDateLAccountsChange" type="date" @focus="setActiveId('DocumentDateL')" @blur="setActiveId('')"></div>
    </div>
    <div class="searchItemContainer">
      <label class="searchinputLabel" for="DocumentDateTimeHAccountsChange" v-text="'---'"></label>
      <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateH'}"><input v-model="searchItems['DocumentDateH']" class="searchinput" id="DocumentDateHAccountsChange" type="date" @focus="setActiveId('DocumentDateH')" @blur="setActiveId('')"></div>
    </div>
    <!-- 搜索模块 -->
    <div class="FuncButton" @click="searchList()">搜索</div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, inject, provide, onUnmounted, watch } from 'vue-demi'
import SelectPopModel from '@/components/ComPop/SelectPopModel.vue';
import { fillSonContent, uniqueArrByKey } from '@/hooks/tools'
import { useStore } from "vuex";
import DataSpan from "@/components/Common/ElementUI/DateSpan.vue";
export default {
  setup(props, { emit }) {
    const store = useStore();

    //#region 数据定义区
    let searchItemList = inject('searchItemList')
    let headName = ref(searchItemList.fileModelName)
    let activeInput = ref('');
    let searchItems = reactive({})
    const searchRef = ref(null)
    //#endregion

    //#region 公式定义区
    const setActiveId = (id) => {
      activeInput.value = id
    }
    const openSearchSetting = () => {
      searchRef.value.openPopComm()
    }
    const searchList = () => {
      emit('deliverSearchItems', searchItems)
    }

    const reSearch = () => {
      searchList()
    }
    //#endregion

    //#region 数据提供区
    provide('tableTitle', headName)
    //#endregion

    fillSonContent(searchItems, searchItemList, 'search', 'defaultValue')

    return {
      activeInput,
      setActiveId,
      searchItems,
      searchItemList,
      searchRef,
      openSearchSetting,
      searchList,
      reSearch
    }
  },
  components: {
    SelectPopModel,
    DataSpan
  }
}
</script>

<style scoped lang="less">
@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;
@lableMarginLeft: 0.625rem;
@lableHeight: 2rem;
@inputHeight: 1.5rem;
@mainColor: #4285f4;

.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.searchinputLabel {
  margin-left: @lableMarginLeft;
  font-size: 0.8rem;
  height: @lableHeight;
  line-height: @lableHeight;
}
.searchinput {
  margin-top: calc((@lableHeight - @inputHeight) / 2);
  margin-bottom: calc((@lableHeight - @inputHeight) / 2);
  font-size: 0.9rem;
  border: none;
  outline: none;
  height: @inputHeight;
  line-height: @inputHeight;
}
.ActiveBox {
  border: 1px solid #ffa004 !important;
}

.searchInputBox {
  height: @lableHeight;
  border: 1px solid #c9c9c9;
  margin-left: @lableMarginLeft;
  border-radius: 0.31rem;
}

.searchItemContainer {
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
  display: flex;
}

.FuncButton {
  height: @lableHeight;
  line-height: @lableHeight;
  margin-left: calc(@lableMarginLeft*2);
  width: 4rem;
  background-color: #f6f6f6;
  text-align: center;
  border: 1px solid #c9c9c9;
  cursor: pointer;
  font-weight: 700;
  border-radius: 4px;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}
.FuncButton:hover {
  background-color: #f08300;
  color: #fff;
}

.searchSetting {
  margin-left: calc(@lableMarginLeft*3);
  height: @lableHeight;
  display: flex;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}
.searchSetting:hover {
  cursor: pointer;
  color: @mainColor;
}
.settingIcon {
  font-family: "icomoon";
  font-size: 1.1rem;
  line-height: @lableHeight;
}

.settingText {
  margin-left: 0.3rem;
  font-size: 0.8rem;
  line-height: @lableHeight;
}
.dateInputContainer {
  display: flex;
}
</style>