<template>
  <div class="BigTable">
    <div class="TableContentArea">
      <table class="tablehead">
        <tr class="headtr" :style="{ height : trHeight }">
          <th class="thSN">
            <div :style="{ width: SnWidth }"></div>
          </th>
          <th class="thselectall">
            <div :style="{ width: selectAllWidth }"><input type="checkbox" v-model="selectAll" /></div>
          </th>
          <th>
            <div class="thoper" :style="{ width: operationWidth }">操作</div>
          </th>
          <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'head'" :class="{  ShowUpArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == 1, ShowDownArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == -1,}" @click="ChangeSort(value1.fieldsNameForVali)">
            <div :style="{ width: value1.showLen + 'rem' }">{{ value1.showName }}</div>
          </th>
        </tr>
      </table>
      <div class="tablebodyarea" :style="{ width: tablewidth + 'rem',height:tbodyHeight}">
        <table class="tablebody">
          <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'td' + index2">
            <td>
              <div class="tdSN" :style="{ width: SnWidth }">{{ index2 + 1 + searchElement.LinesPerPage * (searchElement.Page - 1) }}</div>
            </td>
            <!-- 这里需要修改:value的值，注意是小写-->
            <td class="tableselect">
              <div :style="{ width: selectAllWidth }"><input type="checkbox" :value="value2[deleteKey]" v-model="selectedItems" /></div>
            </td>
            <!-- 这里需要修改@click里面的值，注意是小写-->
            <td>
              <div class="tdoper" :style="{ width: operationWidth }">
                <span class="spanedit" v-show="tableCode != 100110 && (value2?.partBCompanyAbbre != '散客') " @click="openPopEdit('edit',index2)">编辑</span>
                <span class="spandele" v-show="tableCode != 100110 && (value2?.partBCompanyAbbre != '散客') " @click="sendDeleteId(value2[deleteKey])">删除</span>
                <span class="spanedit" v-show="tableCode == 100110 && value2.isQty > 0" @click="openFinePop(index2)">扣款</span>
                <span class="spanedit" v-show="headName == '员工管理表' && false" @click="openUpdateCreditRef(index2)">信用</span>
              </div>
            </td>
            <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
              <div class="bodyFont" :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
            </td>
          </tr>
        </table>
      </div>
      <table class="tablefoot">
        <tr class="foottr" :style="{ height : trHeight }">
          <td>
            <div class="footsum" :style="{ width: SnWidth }">合计</div>
          </td>
          <td>
            <div :style="{ width: selectAllWidth }"></div>
          </td>
          <td>
            <div :style="{ width: operationWidth }"></div>
          </td>
          <td v-for="value4 in tableHead" :key="value4.fieldsNameForVali + 'foot'">
            <div :style="{ width: value4.showLen + 'rem' }"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="MainBodyTablePage" :style="{ width: '100%'}">
      <!--页数模块 -->
      <div class="PageCountContainer">
        <div class="PagerSummaryText">({{ totalLines }}条记录,总共{{ totalPage }}页)</div>
        <span class="PagerEveryPageText">每页/条</span>
        <div class="EditBlock">
          <input class="LinesPerPageChooese" type="text" v-model="searchElement.LinesPerPage" />
          <div class="LinesPerPageChooseButton"></div>
        </div>
        <div class="GoFirstPageButton" @click="FirstPage()"></div>
        <div class="GoLastPageButton" @click="DownPage()"></div>
        <span class="pagetextforDi">第</span>
        <div class="EditBlock">
          <input class="PageEdit" type="text" v-model="searchElement.Page" />
          <div class="PageChooseButton"></div>
        </div>
        <span class="pagetextforYe">页</span>
        <div class="GoNextPageButton" @click="UpPage()"></div>
        <div class="GoEndPageButton" @click="EndPage()"></div>
      </div>
    </div>
    <div class="moreFunctionBox">
      <!-- <div class="FuncButton" @click="openPopAdd('add')" v-show="$store.state.loginInitializeData.authList.buttonVisibleManages.find(x=>x.buttonId == searchItemList.tableName + 'Add')?.visibleOrNot">新增</div> -->
      <div class="FuncButton" @click="openPopAdd('add')">新增</div>
      <div class="FuncButton" @click="sendDeleteIds()" v-show="$store.state.loginInitializeData.authList.buttonVisibleManages.find(x=>x.buttonId == searchItemList.tableName + 'DeleteMany')?.visibleOrNot">批量删除</div>
      <div class="FuncButton" @click="openPopUp()" v-show="$store.state.loginInitializeData.authList.buttonVisibleManages.find(x=>x.buttonId == searchItemList.tableName + 'MassUpload')?.visibleOrNot">批量导入</div>
      <div class="FuncButton" @click="downLoadtablebody()" v-show="$store.state.loginInitializeData.authList.buttonVisibleManages.find(x=>x.buttonId == searchItemList.tableName + 'MassDownload')?.visibleOrNot">批量导出</div>
      <div class="FuncButton" @click="openOnLineBp()" v-if="tableCode == 100026 && $store.state.loginInitializeData.authList.buttonVisibleManages.find(x=>x.buttonId == searchItemList.tableName + 'AddOnLineBp')?.visibleOrNot">添加线上合作单位</div>
      <div class="FuncButton" @click="quickAddBpUnderCompany()" v-if="tableCode == 100026">一键添加企业内单位</div>
      <div class="FuncButton" v-if="tableCode == 100026" @click="openPlatformPop()">添加平台</div>
      <!-- <div class="FuncButton" @click="addEsMany()" v-if="tableCode == 100041">初次批量添加</div> -->
    </div>
    <AddEditPopModel ref="AddEditRef" @refresh="refresh"></AddEditPopModel>
    <UploadPopModel ref="UploadRef" @refresh="refresh"></UploadPopModel>
    <PopModel ref="OnLineBp" v-if="tableCode == 100026">
      <label class="onlineLabel" for="openOnLineBpButton">输入邀请码</label><input class="forOnLineInput" type="text" id="openOnLineBpButton" v-model="OnLineCode">
      <div class="FuncButton marginLeftLong" @click="addOnLineBp()">确认添加</div>
    </PopModel>

    <PopModel ref="StaffCreditRef" v-if="headName == '员工管理表'">
      <div>
        <label class="onlineLabel" for="StaffListStaffNameForCredit">员工姓名:</label><input class="forOnLineInput" type="text" id="StaffListStaffNameForCredit" disabled v-model="creditInfoActive.StaffName">
      </div>
      <div>
        <label class="onlineLabel" for="StaffListCreditValueForCredit">信用额度:</label><input class="forOnLineInput" type="number" @blur="checkCreditValue()" id="StaffListCreditValueForCredit" v-model="creditInfoActive.CreditValue">
      </div>
      <div class="FuncButton marginLeftLong" @click="updateCreditValue()">确认更新</div>
    </PopModel>

    <PopModel ref="PlatformRef" v-if="tableCode == 100026">
      <label class="onlineLabel">选择平台:</label><select class="forOnLineInput" v-model="platformChoosed">
        <option v-for="item in platFormFilter" :key="item.beforeTransfer + 'platform'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
      </select>
      <div class="buttonContainer">
        <div class="FuncButton">取消</div>
        <div class="FuncButton" @click="addPlatform()">添加</div>
        <div class="FuncButton" @click="addPlatformForGroup()">企业内全添加</div>
      </div>
    </PopModel>

    <PopModel ref="recycleFineRef">
      <div class="inputBox">
        <label class="popLable">商品名称:</label>
        <el-input class="popInput" v-model="fineData.CommodityName" :disabled="true" type="text" />
      </div>
      <div class="inputBox">
        <label class="popLable">回收属性:</label>
        <el-input class="popInput" v-model="fineData.SalesAttributeNoBefore" :disabled="true" type="text" />
      </div>
      <div class="inputBox">
        <label class="popLable">扣款原因:</label>
        <el-select v-model="fineData.SalesAttributeNoAfter" class="m-2 popInput" placeholder="Select" @change="fineReasonChange($event)">
          <el-option v-for="item in fineData.SalesAttributeArr" :key="item.salesAttributeId +'RecycleOnTheWayFine'" :label="item.salesAttributeName" :value="item.salesAttributeId">
          </el-option>
        </el-select>
      </div>
      <div class="inputBox">
        <label class="popLable">默认说明:</label>
        <el-input class="popInput" v-model="fineData.RemarkDefault" :disabled="true" type="text" />
      </div>
      <div class="inputBox">
        <label class="popLable">已扣金额:</label>
        <el-input class="popInput" :disabled="true" v-model="fineData.beforeFineAmount" type="number" />
      </div>
      <div class="inputBox">
        <label class="popLable">扣款金额:</label>
        <el-input class="popInput" v-model="fineData.FineAmount" type="number" @blur="checkFineAmount()" />
      </div>
      <div class="inputBox">
        <label class="popLable">补充说明:</label>
        <el-input class="popInput" v-model="fineData.AppendRemark" type="text" />
      </div>
      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight">取消</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="fineOnly('')">扣款</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="fineOnly('OS')">扣款并出库</el-button>
      </div>
    </PopModel>
  </div>
</template>

<script>
import { computed, inject, onMounted, onUnmounted, provide, reactive, ref, watch } from 'vue-demi'
import AddEditPopModel from '@/components/ComPop/AddEditPopModel.vue';
import UploadPopModel from '@/components/ComPop/UploadPopModel.vue';
import PopModel from '@/components/Common/PopModel.vue'
import TableShowHook from '@/hooks/TableShowHook'
import requestData from "@/hooks/requestData.js";
import { updateStaffCredit, addPlatformReal, addPlatformForGroupReal, transferToCode, getArrAttriByCoId, transferToName, getBodyInstance, getDocNo, updateRecycleOnTheWayDoneList, getRelatedDocNo, openSoList, openOnTheWayList, openOoList } from '@/hooks/OpenOrder'
import { copyArray, getInventoryByWareHouse } from '@/hooks/tools';
import { ElMessage } from 'element-plus'

export default {
  components: {
    AddEditPopModel,
    UploadPopModel,
    PopModel
  },
  setup(props, { emit }) {
    //#region 数据定义区
    let { store, tableHead, tableBody, SearchContent,
      totalLines, totalPage, searchElement, selectAll, selectedItems, deleteKey, tableCode, headName,
      AddEditRef, UploadRef,
      openPopAdd, fillSearchContent, fillDeleteKey, openPopEdit, refreshTableHeadandregArr, clearTableHeadandregArr,
      clearVueXPage, initializeVueXPageDate, searchList,
      turnPageToOne, UpPage, EndPage, DownPage, FirstPage, ChangeSort, sendDeleteIds, sendDeleteId, downLoadtablebody,
      refresh, openPopUp } = TableShowHook(emit)


    let searchItemList = inject('searchItemList')

    const platFormFilter = copyArray(store.state.loginInitializeData.filterList.PlatFormList)

    let SnWidth = ref('3.75rem')
    let selectAllWidth = ref('2.5rem')
    let operationWidth = ref('11rem')
    let trHeight = ref('1.875rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')
    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) + parseFloat(selectAllWidth.value) + parseFloat(operationWidth.value) + 1.25 + tableHead.length * 1 + 0.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });

    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (tableCode == store.state.loginInitializeData.ShowWindow[0]) {
          initializeVueXPageDate()
          fillSearchContent()
          searchList()
        }
      }
    )

    onUnmounted(() => {

    })

    const closerecycleFineRef = () => {
      recycleFineRef.value.closePopComm()
    }


    let fineData = reactive({
      index: -1,
      CommodityId: '',
      CommodityName: '',
      SalesAttributeNoBefore: '',
      SalesAttributeArr: [],
      SalesAttributeNoAfter: '',
      RecycleOrNot: '',
      beforeFineAmount: 0,
      FineAmount: 0,
      RemarkDefault: '',
      AppendRemark: '',
      VmiCost: 0,
      MaxVmiCost: 0
    })

    const fineOnly = (osChoose) => {
      if (!fineData.SalesAttributeNoAfter) {
        ElMessage('请勾选扣款原因')
        return;
      }
      if (fineData.FineAmount <= 0) {
        ElMessage('扣款金额不能为0或小于0')
        return;
      }
      const body = tableBody[fineData.index]
      const partBId = transferToCode(body.partBCompanyId, 'PartBCompanyId')
      const partAId = body.partACompanyId
      const userId = store.state.loginInitializeData.userId
      if (osChoose == 'OS') {
        if (body.isQty < 1) {
          ElMessage('无会收入库记录，无法出库')
          return;
        }
        const outWareHouseId = transferToCode(body.inWareHouseId, 'WareHouseId')
        const commId = body.commodityId
        const commName = body.commodityName
        getInventoryByWareHouse(commId, outWareHouseId).then(res => {
          if (res < body.isQty) {
            ElMessage('库存不足，无法出库')
            return;
          }
          else {
            getRelatedDocNo(body.documentId + body.sn).then(res => {
              const relatedSoCode = res == '' ? body.documentId : res
              const fineMoney = fineData.FineAmount
              let promiseArr = []
              let soHeadCopy = getBodyInstance('DocumentCenter')
              let soBodyCopy = getBodyInstance('SoList')
              let soBodyArray = []

              let onTheWayCopy = getBodyInstance('OnTheWayList')
              const beforeAttrName = transferToName(fineData.SalesAttributeNoBefore, 'SalesAttributeId')
              const afterAttrName = transferToName(fineData.SalesAttributeNoAfter, 'SalesAttributeId')

              let ooBodyCopy = getBodyInstance('OoList')
              let ooHeadCopy = getBodyInstance('DocumentCenter')
              let ooBodyArray = []

              getDocNo('SO').then(res => {
                const soHeadCode = res
                soHeadCopy.DocumentId = soHeadCode
                soHeadCopy.PartACompanyId = partAId
                soHeadCopy.PartBCompanyId = partBId
                soHeadCopy.UserId = userId
                soHeadCopy.SourceDocumentId = relatedSoCode
                soHeadCopy.Qty = 1
                soHeadCopy.SystemStatusId = 104
                soHeadCopy.RootSourceDocumentId = relatedSoCode

                soBodyCopy.DocumentId = soHeadCode
                soBodyCopy.Sn = 1
                soBodyCopy.PartACompanyId = partAId
                soBodyCopy.PartBCompanyId = partBId
                soBodyCopy.CommodityId = '229999999999999998'
                soBodyCopy.CommodityName = '退货扣款'
                soBodyCopy.VirtualGoods = true
                soBodyCopy.OrderQty = 1
                soBodyCopy.Unit = '次'
                soBodyCopy.UnitPrice = fineMoney
                soBodyCopy.Amount = fineMoney
                soBodyCopy.Remark = `退货扣款，金额为${fineMoney}`
                soBodyCopy.ShipmentQty = 1
                soBodyCopy.ShipmentStatus = 103
                soBodyCopy.SourceDocumentId = relatedSoCode
                soBodyCopy.RootSourceDocumentId = relatedSoCode
                soBodyArray.push(soBodyCopy)
                let soAction = openSoList(soBodyArray, soHeadCopy, soHeadCode, true)
                promiseArr.push(soAction)

                onTheWayCopy.DocumentId = body.documentId
                onTheWayCopy.PartACompanyId = partAId
                onTheWayCopy.PartBCompanyId = partBId
                onTheWayCopy.StaffId = body.staffId
                onTheWayCopy.CommodityName = '退货扣款'
                onTheWayCopy.CommodityId = '229999999999999998'
                onTheWayCopy.Remark = `${body.commodityName}回收属性从${beforeAttrName}变成${afterAttrName},扣款金额为${fineMoney}元，备注:${fineData.AppendRemark}`
                onTheWayCopy.CommodityNameSale = '退货扣款'
                onTheWayCopy.CommodityIdSale = '229999999999999998'
                onTheWayCopy.OrderQty = 1
                onTheWayCopy.UnitPrice = fineMoney
                onTheWayCopy.ResponsedQty = 1
                onTheWayCopy.RefusedQty = 0
                onTheWayCopy.DocumentTypeSale = '扣款单'
                onTheWayCopy.DocumentIdSale = soHeadCode
                onTheWayCopy.SnSale = 1
                onTheWayCopy.VirtualGoods = true
                onTheWayCopy.DistributedOrNot = true
                onTheWayCopy.SupplierToBeSolve = false
                onTheWayCopy.CustomerToBeSolve = true
                onTheWayCopy.StartType = 107
                let onTheWayAction = openOnTheWayList(onTheWayCopy)
                promiseArr.push(onTheWayAction)
                getDocNo('OO').then(res => {
                  const ooHeadCode = res
                  ooHeadCopy.DocumentId = ooHeadCode
                  ooHeadCopy.PartACompanyId = partAId
                  ooHeadCopy.UserId = userId
                  ooHeadCopy.SourceDocumentId = relatedSoCode
                  ooHeadCopy.Qty = 1
                  ooHeadCopy.SystemStatusId = 104
                  ooHeadCopy.OutWareHouseId = outWareHouseId
                  ooHeadCopy.InWareHouseId = outWareHouseId
                  ooHeadCopy.RootSourceDocumentId = relatedSoCode

                  ooBodyCopy.DocumentId = ooHeadCode
                  ooBodyCopy.Sn = 1
                  ooBodyCopy.PartACompanyId = partAId
                  ooBodyCopy.CommodityId = commId
                  ooBodyCopy.CommodityName = commName
                  ooBodyCopy.OrderQty = 1
                  ooBodyCopy.Unit = '次'
                  ooBodyCopy.Remark = `退货出库`
                  ooBodyCopy.SourceDocumentId = relatedSoCode
                  ooBodyCopy.RootSourceDocumentId = relatedSoCode
                  ooBodyCopy.OutStockQty = 1
                  ooBodyCopy.OutStockKeeping = true
                  ooBodyCopy.GoodsProfitKeeping = true
                  ooBodyArray.push(ooBodyCopy)
                  let ooAction = openOoList(ooBodyArray, ooHeadCopy, ooHeadCode)
                  promiseArr.push(ooAction)
                  Promise.all(promiseArr).then(resArr => {
                    const newAttrNo = fineData.SalesAttributeNoAfter
                    const newFineMoney = fineMoney
                    const SolvedUserid = userId
                    const outQty = osChoose == '' ? 0 : tableBody[fineData.index].orderQty
                    updateRecycleOnTheWayDoneList(tableBody[fineData.index].recyleOnTheWayDoneCode, newAttrNo, newFineMoney, SolvedUserid, outQty).then(res => {
                      closerecycleFineRef()
                      ElMessage('扣款成功')
                      searchList()
                      emit('refreshMsg')
                    })
                  })
                })
              })
            })
          }
        })
      }
      else {
        getRelatedDocNo(body.documentId + body.sn).then(res => {
          const relatedSoCode = res == '' ? body.documentId : res
          const fineMoney = fineData.FineAmount
          let promiseArr = []
          let soHeadCopy = getBodyInstance('DocumentCenter')
          let soBodyCopy = getBodyInstance('SoList')
          let soBodyArray = []
          let onTheWayCopy = getBodyInstance('OnTheWayList')
          const beforeAttrName = transferToName(fineData.SalesAttributeNoBefore, 'SalesAttributeId')
          const afterAttrName = transferToName(fineData.SalesAttributeNoAfter, 'SalesAttributeId')
          getDocNo('SO').then(res => {
            soHeadCopy.DocumentId = res
            soHeadCopy.PartACompanyId = partAId
            soHeadCopy.PartBCompanyId = partBId
            soHeadCopy.UserId = userId
            soHeadCopy.SourceDocumentId = relatedSoCode
            soHeadCopy.Qty = 1
            soHeadCopy.SystemStatusId = 104
            soHeadCopy.RootSourceDocumentId = relatedSoCode

            soBodyCopy.DocumentId = res
            soBodyCopy.Sn = 1
            soBodyCopy.PartACompanyId = partAId
            soBodyCopy.PartBCompanyId = partBId
            soBodyCopy.CommodityId = '229999999999999998'
            soBodyCopy.CommodityName = '退货扣款'
            soBodyCopy.VirtualGoods = true
            soBodyCopy.OrderQty = 1
            soBodyCopy.Unit = '次'
            soBodyCopy.UnitPrice = fineMoney
            soBodyCopy.Amount = fineMoney
            soBodyCopy.Remark = `退货扣款，金额为${fineMoney}`
            soBodyCopy.ShipmentQty = 1
            soBodyCopy.ShipmentStatus = 103
            soBodyCopy.SourceDocumentId = relatedSoCode
            soBodyCopy.RootSourceDocumentId = relatedSoCode
            soBodyArray.push(soBodyCopy)
            let soAction = openSoList(soBodyArray, soHeadCopy, res, true)
            promiseArr.push(soAction)

            onTheWayCopy.DocumentId = body.documentId
            onTheWayCopy.PartACompanyId = partAId
            onTheWayCopy.PartBCompanyId = partBId
            onTheWayCopy.StaffId = body.staffId
            onTheWayCopy.CommodityName = '退货扣款'
            onTheWayCopy.CommodityId = '229999999999999998'
            onTheWayCopy.Remark = `${body.commodityName}回收属性从${beforeAttrName}变成${afterAttrName},扣款金额为${fineMoney}元，备注:${fineData.AppendRemark}`
            onTheWayCopy.CommodityNameSale = '退货扣款'
            onTheWayCopy.CommodityIdSale = '229999999999999998'
            onTheWayCopy.OrderQty = 1
            onTheWayCopy.UnitPrice = fineMoney
            onTheWayCopy.ResponsedQty = 1
            onTheWayCopy.RefusedQty = 0
            onTheWayCopy.DocumentTypeSale = '扣款单'
            onTheWayCopy.DocumentIdSale = res
            onTheWayCopy.SnSale = 1
            onTheWayCopy.VirtualGoods = true
            onTheWayCopy.DistributedOrNot = true
            onTheWayCopy.SupplierToBeSolve = false
            onTheWayCopy.CustomerToBeSolve = true
            onTheWayCopy.StartType = 107
            let onTheWayAction = openOnTheWayList(onTheWayCopy)
            promiseArr.push(onTheWayAction)
            Promise.all(promiseArr).then(resArr => {
              const newAttrNo = fineData.SalesAttributeNoAfter
              const newFineMoney = fineMoney
              const SolvedUserid = userId
              const outQty = osChoose == '' ? 0 : tableBody[fineData.index].orderQty
              updateRecycleOnTheWayDoneList(tableBody[fineData.index].recyleOnTheWayDoneCode, newAttrNo, newFineMoney, SolvedUserid, outQty).then(res => {
                closerecycleFineRef()
                ElMessage('扣款成功')
                searchList()
                emit('refreshMsg')
              })
            })
          })
        })
      }
    }

    const recycleFineRef = ref(null)

    const checkFineAmount = () => {
      const maxValue = +(fineData.MaxVmiCost - fineData.VmiCost).toFixed(2)

      if (fineData.FineAmount > maxValue) {
        ElMessage(`扣款不允许超过合约最高扣款值为${maxValue}`)
        fineData.FineAmount = maxValue
      }
      if (fineData.FineAmount < 0) {
        fineData.FineAmount = 0
      }
    }

    const fineReasonChange = (e) => {
      const chooseBody = fineData.SalesAttributeArr.find(x => x.salesAttributeId == e)
      const newAttr = transferToName(fineData.SalesAttributeNoAfter, 'SalesAttributeNo')
      fineData.RecycleOrNot = chooseBody.recycleOrNot
      fineData.FineAmount = chooseBody.vmiCost - fineData.VmiCost > 0 ? (chooseBody.vmiCost - fineData.VmiCost).toFixed(2) : 0

      if (fineData.SalesAttributeNoBefore == newAttr) {
        fineData.RemarkDefault = `回收属性无变化`
      }
      else {
        fineData.RemarkDefault = `回收属性由${fineData.SalesAttributeNoBefore}变为${newAttr}`
      }
    }

    const openFinePop = (index2) => {
      const body = tableBody[index2]
      const partAId = transferToCode(body.partACompanyId, 'OwedCompanyId')
      fineData.index = index2
      fineData.CommodityId = body.commodityId
      fineData.CommodityName = body.commodityName
      fineData.SalesAttributeNoBefore = body.solvedSalesAttributeNo
      fineData.SalesAttributeNoAfter = ''
      fineData.RecycleOrNot = true
      fineData.beforeFineAmount = body.fineMoney
      fineData.FineAmount = 0
      fineData.RemarkDefault = ''
      fineData.AppendRemark = ''
      fineData.VmiCost = 0
      fineData.MaxVmiCost = 0
      let beforeNo = transferToCode(body.solvedSalesAttributeNo, 'SalesAttributeNo')
      fineData.RecycleOrNot = true
      getArrAttriByCoId(body.commodityId, partAId).then(res => {
        fineData.SalesAttributeArr = res

        for (let item of fineData.SalesAttributeArr) {
          if (item.vmiCost > fineData.MaxVmiCost) {
            fineData.MaxVmiCost = item.vmiCost
          }
          if (item.salesAttributeId == beforeNo) {
            item.salesAttributeName = '回收属性不变，其他原因扣款'
            fineData.VmiCost = item.vmiCost
          }
        }

        for (let item of fineData.SalesAttributeArr) {
          switch (item.salesAttributeId) {
            case 10001:
              item.salesAttributeName = '商品无法回收';
              break;
            case 10002:
              item.salesAttributeName = '外屏有破损';
              break;
            case 10003:
              item.salesAttributeName = '内屏破损无法回收';
              break;
            case 10004:
              item.salesAttributeName = '外屏破损，背灯也有瑕疵';
              break;
            case 10005:
              item.salesAttributeName = '外屏有破损，触摸不灵';
              break;
            case 10006:
              item.salesAttributeName = '外屏有破损，有老化';
              break;
            default:
              item.salesAttributeName = '其他原因';
          }
        }
        recycleFineRef.value.openPopComm()
      }).catch(err => {
        ElMessage(err.message)
      })

    }

    const OnLineBp = ref(null)

    const openOnLineBp = () => {
      OnLineBp.value.openPopComm()
    }

    let OnLineCode = ref('')

    const partACom = store.state.loginInitializeData.companyId
    const addOnLineBp = () => {

      const config = {
        method: "get",
        url: "/BusinessPartnerList/AddOnlineBp",
        params: {
          inviteCode: OnLineCode.value,
          partAId: partACom
        }
      };
      requestData.GetData(config).then(res => {
        if (res == "pass") {

          ElMessage('添加成功')
          OnLineBp.value.closePopComm()
          store.commit('refreshShowWindow')
        }
        else {
          ElMessage(res)
        }

      }).catch(err => {

        ElMessage(err)
      })
    }

    const quickAddBpUnderCompany = () => {

      const config = {
        method: "get",
        url: "/BusinessPartnerList/AddBpUnderCompany",
        params: {
          partAId: partACom
        }
      };
      requestData.GetData(config).then(res => {
        if (res == "pass") {

          ElMessage('添加成功')
          store.commit('refreshShowWindow')
        }
        else {
          ElMessage(res)
        }

      }).catch(err => {

        ElMessage(err)
      })
    }

    const StaffCreditRef = ref(null)

    const PlatformRef = ref(null)

    let creditInfoActive = reactive({
      StaffId: '',
      StaffName: '',
      CreditValue: ''
    })

    const checkCreditValue = () => {
      if (creditInfoActive.CreditValue < 0) {
        ElMessage('数值不能小于0')
        creditInfoActive.CreditValue = 0
      }
    }
    let platformChoosed = ref('')



    const addPlatform = () => {
      addPlatformReal(platformChoosed.value).then(res => {
        if (res == 'pass') {
          ElMessage('添加成功')
          PlatformRef.value.closePopComm()
          searchList()
          return
        }
        else {
          ElMessage(res)
          return
        }
      })
    }

    const addPlatformForGroup = () => {
      addPlatformForGroupReal(platformChoosed.value).then(res => {
        if (res == 'pass') {
          ElMessage('添加成功')
          PlatformRef.value.closePopComm()
          searchList()
          return
        }
        else {
          ElMessage(res)
          return
        }
      })
    }



    const openPlatformPop = () => {
      platformChoosed.value = ''
      PlatformRef.value.openPopComm()
    }

    const openUpdateCreditRef = (index) => {
      creditInfoActive.StaffId = tableBody[index].staffId
      creditInfoActive.StaffName = tableBody[index].staffName
      creditInfoActive.CreditValue = tableBody[index].staffCredit
      StaffCreditRef.value.openPopComm()
    }

    const updateCreditValue = () => {
      updateStaffCredit(creditInfoActive.StaffId, creditInfoActive.CreditValue).then(res => {
        if (res == 'pass') {
          ElMessage('更新成功')
          StaffCreditRef.value.closePopComm()
          refresh()
        }
        else {
          ElMessage('更新失败')
        }
      })
    }




    watch(
      () => [searchElement.Page, searchElement.SortFieldsName, searchElement.SortDirection],
      (newValue, oldValue) => {
        if (tableCode == store.state.loginInitializeData.ShowWindow[0]) {
          if (totalPage.value > 0) {
            if (parseInt(newValue[0]) > totalPage.value) {
              // ElMessage('不能超过最大页数');
              store.commit('modifysearchEle', { keyName: 'Page', valueName: totalPage.value })
              return;
            }
          }
          if (parseInt(newValue[0]) < 1) {
            // ElMessage('页数不能小于1');
            store.commit('modifysearchEle', { keyName: 'Page', valueName: 1 })
            return;
          }
        }
      }
    );
    //w用于监控页数，确保客户不乱填
    watch(
      () => searchElement.LinesPerPage,
      (newValue, oldValue) => {
        if (tableCode == store.state.loginInitializeData.ShowWindow[0]) {
          if (newValue > 100) {
            store.commit('modifysearchEle', { keyName: 'LinesPerPage', valueName: 100 })
            ElMessage("每页数量不能超过100");
          }
          if (newValue < 1) {
            store.commit('modifysearchEle', { keyName: 'LinesPerPage', valueName: 1 })
            ElMessage("每页数量不能低于1");
          }
        }
      }
    );


    watch(selectAll, (newValue, oldValue) => {
      if (tableCode == store.state.loginInitializeData.ShowWindow[0]) {
        if (newValue == true) {
          selectedItems.value.splice(0);
          for (let item of tableBody) {
            selectedItems.value.push(item[deleteKey.value]);
          }
        } else {
          selectedItems.value.splice(0);
        }
      }
    });
    //#endregion


    onMounted(() => {
      refreshTableHeadandregArr()
      initializeVueXPageDate()
      fillSearchContent()
      fillDeleteKey()
      searchList()
    })
    onUnmounted(() => {
      clearTableHeadandregArr()
      clearVueXPage()
    })
    return {
      SearchContent, searchItemList,
      fillSearchContent, platformChoosed,
      tableHead, PlatformRef, openPlatformPop, platFormFilter,
      tableBody, addPlatform, addPlatformForGroup, fineData,
      tablewidth, checkFineAmount, fineReasonChange, fineOnly,
      searchList, openFinePop, recycleFineRef,
      totalLines,
      totalPage,
      ChangeSort,
      UpPage,
      EndPage,
      FirstPage,
      DownPage,
      turnPageToOne,
      searchElement,
      selectAll,
      selectedItems,
      deleteKey,
      //尺寸部分
      SnWidth,
      selectAllWidth,
      operationWidth,
      trHeight,
      tbodyHeight,
      sendDeleteId,
      AddEditRef,
      openPopEdit,
      refresh,
      openPopAdd,
      sendDeleteIds,
      UploadRef,
      openPopUp,
      downLoadtablebody,

      OnLineBp,
      openOnLineBp,
      tableCode, OnLineCode, addOnLineBp, quickAddBpUnderCompany, headName, openUpdateCreditRef,
      StaffCreditRef, creditInfoActive, updateCreditValue, checkCreditValue
    }
  }
}
</script>

<style scoped lang="less">
.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 60%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.thSN {
  font-family: "icomoon";
  color: #fff;
  font-size: 1rem;
}
.headtd {
  cursor: pointer;
}
.ShowUpArrow {
  position: relative;
}
.ShowUpArrow::after {
  content: "\ea32";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
  color: #fff;
}
.ShowDownArrow {
  position: relative;
}
.ShowDownArrow::after {
  content: "\ea36";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.tdSN,
.tableselect,
.tdoper,
.footsum,
.bodytr {
  text-align: center;
}
.bodytr {
  height: 2rem;
}

.tablefoot {
  margin-top: -1px;
}

.moreFunctionBox {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.marginLeftLong {
  margin-left: 28rem;
}

.forOnLineInput {
  width: 28rem;
  height: 2rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.onlineLabel {
  margin-left: 2rem;
}
.inputBox {
  display: flex;
}
.popLable {
  margin-top: 0.5rem;
  line-height: 2.5rem;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 0.5rem;
}
.popInput {
  margin-top: 0.5rem;
  width: 28rem;
}
.buttonBox {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  justify-content: flex-end;
}
</style>