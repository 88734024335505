<template>
  <div class="tableLoaderContainer">
    <BodyTitle></BodyTitle>
    <SearchContentForRepairRecordList @deliverSearchItems="deliverSearchItems" ref="searchRef"></SearchContentForRepairRecordList>
    <TableShowForRepairRecordList @goRoList="goRoList" @goObListFromRepairRecord = "goObListFromRepairRecord" @saveActiveItem="saveActiveItem" @clearActive="clearActive" ref="tableShowRef"></TableShowForRepairRecordList>
    <div class="moreFunctionBox">
      <!-- <div class="FuncButton" @click="goInvenChange()">库存变动查询</div> -->
    </div>
  </div>
</template>

<script>
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import SearchContentForRepairRecordList from '@/components/Content/MainBodyContent/SearchContentForRepairRecordList.vue'
import TableShowForRepairRecordList from '@/components/Content/MainBodyContent/TableShowForRepairRecordList.vue';
import { useStore } from "vuex";
import DiyShowTable from '@/hooks/DiyShowTable'
export default {
  setup(props,{emit}) {
    //#region 定义区
    const store = useStore();
    //初始化输入表名称
    const TableName = 'RepairRecordList';

    const PageName = 'RepairRecordList'

    let {tableShowRef,searchRef,deliverSearchItems,activeItem,saveActiveItem,clearActive} = DiyShowTable(TableName,PageName)
    //根据表名给searchItemList赋值

    const goRoList = (value) => {
      emit('goRoLissFromRepairList',value)
    }

    const goObListFromRepairRecord = (value) => {
      emit('goObListFromRepairRecord',value)
    }

    //#endregion

    return {
      deliverSearchItems,
      tableShowRef,
      saveActiveItem,
      activeItem, clearActive,searchRef,goRoList,goObListFromRepairRecord
    }
  },
  components: {
    BodyTitle,
    SearchContentForRepairRecordList,
    TableShowForRepairRecordList
  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

</style>