<template>
  <div class="inputLoaderContainer">
    <BodyTitle></BodyTitle>
    <InputHead ref="obInputHeadRef"></InputHead>
    <InputBody ref="inputBodyRef" @clearScanIn="clearScanIn"></InputBody>
    <div class="EditTableLine">
    </div>
    <div class="EditTableLastLine">
      <!-- 维护第七层的内容 -->
      <!-- <div class="EditTableFuncButton" :style="{background:'#73a6a3'}" @click="openPopUp()">导入订单明细</div> -->
      <div class="EditTableFuncButton EditTableFuncButtonAjust" @click="openList('')">发送预定</div>
    </div>
  </div>
</template>
<script>
import { computed, provide, reactive, ref, watch } from 'vue-demi'
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import InputHead from '@/components/Content/MainBodyContent/InputHead.vue'
import InputBody from '@/components/Content/MainBodyContent/InputBody.vue'
import { getDocNo, getLoginData, checkDocCondition, openObList, transferToCode, clearTableContent, addObTracingObj, openMiList, fillContentWithRes, getVmiCid, transferToName } from '@/hooks/OpenOrder'
import { transferLoginDataOnly } from '@/hooks/loginAffair';
import ComScanIn from '@/components/Content/FunctionUnit/ComScanIn.vue'
import InputTable from '@/hooks/InputTable'
import { useStore } from "vuex";
import requestData from "@/hooks/requestData.js";
import { copyObj, getDateTime, getUUId } from '@/hooks/tools'
import { ElMessage } from 'element-plus'
export default {
  components: {
    BodyTitle,
    InputHead,
    InputBody,
    ComScanIn
  },
  setup(props, { emit }) {
    const store = useStore();
    const RootTableName = 'ObList';
    let { itemListHead, itemListBody, searchItemList } = InputTable(RootTableName)
    searchItemList.docTitleTwo = 'OB'
    searchItemList.TableName = 'ObInput'
    searchItemList.CustomerList = reactive({
      customerName: '',
      customerContact: '',
      customerDeposit: '',
      customerQuotation: ''
    })
    provide('searchItemList', searchItemList)

    let inputBodyRef = ref(null)
    //#endregion

    //#region 扫描组件
    let scanInValue = ref('');

    const scanIn = (res) => {
      inputBodyRef.value.scanInData(res)
    }

    const clearScanIn = () => {
      scanInValue.value = '';
    }

    const obInputHeadRef = ref(null)

    getVmiCid().then(res => {
      if (res) {
        const companyName = store.state.loginInitializeData.companyNameAbbre
        if (companyName.indexOf('小懒') > -1) {
          obInputHeadRef.value.searchItemList.documentContent.PartBCompanyId = transferToName(res, 'PartBCompanyId')
        }
      }
    })

    const getDataFromRepairList = (value) => {
      obInputHeadRef.value.searchItemList.documentContent.StaffId = value.staffId
      obInputHeadRef.value.searchItemList.CustomerList.customerName = value.customerName
      obInputHeadRef.value.searchItemList.CustomerList.customerContact = value.customerContact
      obInputHeadRef.value.searchItemList.CustomerList.customerQuotation = value.draftQuotation
    }

    const getDataFromQuotation = (value) => {
      
      setTimeout(() => {
        clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
        let config = {
          method: "get",
          url: "/CommodityMangeTable/GetSingleById",
          params: {
            id: value.commodityId,
            bpId: ''
          }
        }
        requestData.GetData(config).then(res => {
          if (res == 'notfound') {
          }
          else {
            fillContentWithRes(inputBodyRef.value.AddContentBody, res, 0, searchItemList.docTitleTwo)
            obInputHeadRef.value.searchItemList.CustomerList.customerQuotation = value.quotationForCustomer
          }
        })
      }, 500);
    }

    const getDataFromQuotation2 = (value) => {
      
      setTimeout(() => {
        clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
        let config = {
          method: "get",
          url: "/CommodityMangeTable/GetSingleById",
          params: {
            id: value.stoCommodityId,
            bpId: ''
          }
        }
        requestData.GetData(config).then(res => {
          if (res == 'notfound') {
          }
          else {
            fillContentWithRes(inputBodyRef.value.AddContentBody, res, 0, searchItemList.docTitleTwo)
            obInputHeadRef.value.searchItemList.documentContent.PartBCompanyId = value.friendName
            obInputHeadRef.value.searchItemList.CustomerList.customerQuotation = value.quotationForCustomer
          }
        })
      }, 500);
    }

    //#endregion



    //#region 开单功能模块

    const openList = (typeName) => {

      let errCheck = checkDocCondition(searchItemList.documentContent, itemListHead, inputBodyRef.value.AddContentBody, itemListBody)

      if (errCheck.headErr) {

        ElMessage(errCheck.headErr)
        return
      }
      if (errCheck.bodyErr) {

        ElMessage(errCheck.bodyErr)
        return
      }
      if (transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId') >= 20000000) {

        ElMessage('采购预定供应商不可为自建供应商')
        return;
      }
      for (let index in inputBodyRef.value.AddContentBody) {
        if (inputBodyRef.value.AddContentBody[index].CommodityName != '' && inputBodyRef.value.AddContentBody[index].OrderQty == '') {

          ElMessage(`第${+index + 1}行数量未填写`)
          return;
        }
        if (inputBodyRef.value.AddContentBody[index].CommodityName != '' && inputBodyRef.value.AddContentBody[index].OrderQty <= 0) {

          ElMessage(`第${+index + 1}行数量不可为0或小于0`)
          return;
        }
      }
      getLoginData().then(res => {
        //刷新初始化数据
        if (res != 0) {
          transferLoginDataOnly(res)
        }
        getDocNo(searchItemList.docTitleTwo).then(res => {
          const ObDocTitle = res;
          let commoidMutiple = ''
          for (let item of inputBodyRef.value.AddContentBody) {
            if (item.CommodityName) {
              item.PartACompanyId = store.state.loginInitializeData.companyId
              item.PartBCompanyId = transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId')
              item.DocumentId = res
              item.Remark += ` 开单人:${transferToName(searchItemList.documentContent.StaffId,'StaffId')}`
            }
            if (item.CommodityName.trim() != '') {
              commoidMutiple += (item.CommodityName + '*' + item.OrderQty + '个' + ' ')
            }
          }
          if (commoidMutiple.length > 200) {
            commoidMutiple = commoidMutiple.substring(0, 199)
          }
          searchItemList.documentContent.PartACompanyId = store.state.loginInitializeData.companyId
          searchItemList.documentContent.Remark += `客户姓名:${searchItemList.CustomerList.customerName}，联系方式:${searchItemList.CustomerList.customerContact}`
          openObList(inputBodyRef.value.AddContentBody, searchItemList.documentContent, res).then(res => {

            if (res != 'pass') {
              ElMessage(res)
              return false;
            }
            else {
              let bodyForCycle = []
              const timeNow = getDateTime()
              for (let item4 of inputBodyRef.value.AddContentBody) {
                if (item4.CommodityName) {
                  bodyForCycle.push(copyObj(item4))
                }
              }
              const length2 = bodyForCycle.length
              if (length2 > 0) {
                const storage = window.localStorage;
                const user = store.state.loginInitializeData.userId
                let storeListFromWin = []
                if (JSON.parse(storage.getItem('BarStoreList' + user))) {
                  storeListFromWin = JSON.parse(storage.getItem('BarStoreList' + user));
                }

                for (let k = 0; k < length2; k = k + 2) {
                  const newId2 = getUUId()
                  let addPrint2 = {
                    id: 'store' + newId2,
                    partName: `${bodyForCycle[k].CommodityName}*${bodyForCycle[k].OrderQty}个`,
                    docType: '客户预定单',
                    companyName: store.state.loginInitializeData.companyNameAbbre,
                    docDate: timeNow,
                    remark: `${searchItemList.documentContent.Remark},报价:${searchItemList.CustomerList.customerQuotation},订金:${searchItemList.CustomerList.customerDeposit}`,
                    barcode: ObDocTitle,
                  }
                  if (length2 > k + 1) {
                    addPrint2.partName += `,${bodyForCycle[k + 1].CommodityName}*${bodyForCycle[k + 1].OrderQty}个`
                  }
                  if (k > 1) {
                    addPrint2.remark = searchItemList.documentContent.Remark
                  }
                  storeListFromWin.unshift(addPrint2)
                }
                storage.setItem('BarStoreList' + user, JSON.stringify(storeListFromWin))
                store.commit('updateBarcodeCount')
              }

              const tacingObj = {
                DocumentId: ObDocTitle,
                PartACompanyId: store.state.loginInitializeData.companyId,
                CommodityName: commoidMutiple,
                CustomerName: searchItemList.CustomerList.customerName,
                CustomerContacts: searchItemList.CustomerList.customerContact,
                CustomerDeposit: searchItemList.CustomerList.customerDeposit,
                CustomerQuotation: searchItemList.CustomerList.customerQuotation,
                ArrivalStatus: 101,
                StaffId: transferToCode(searchItemList.documentContent.StaffId, 'StaffId'),
                CallCustomerTimes: 0,
                Remark: searchItemList.documentContent.Remark,
              }
              addObTracingObj(tacingObj).then(res => {
                clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                emit('refreshMsg')
              })
              if (searchItemList.CustomerList.customerDeposit > 0) {
                getDocNo('MI').then(res => {
                  const MiDocHead = {
                    DocumentId: res,
                    PartACompanyId: store.state.loginInitializeData.companyId,
                    PartBCompanyId: transferToCode('散客', 'PartBCompanyId'),
                    StaffId: transferToCode(searchItemList.documentContent.StaffId, 'StaffId'),
                    SourceDocumentId: ObDocTitle,
                    Amount: searchItemList.CustomerList.customerDeposit,
                    Qty: 1,
                    SystemStatusId: 104,
                    Remark: `订金收款,客户姓名:${searchItemList.CustomerList.customerName}，联系方式:${searchItemList.CustomerList.customerContact}`
                  }
                  const MiBody = [{
                    DocumentId: res,
                    Sn: 1,
                    PartACompanyId: store.state.loginInitializeData.companyId,
                    PartBCompanyId: transferToCode('散客', 'PartBCompanyId'),
                    OrderQty: 1,
                    UnitPrice: searchItemList.CustomerList.customerDeposit,
                    Amount: searchItemList.CustomerList.customerDeposit,
                    Remark: '订金收款',
                    SourceDocumentId: ObDocTitle,
                    OutStockQty: 1,
                    InStockQty: 1
                  }]
                  openMiList(MiBody, MiDocHead, res).then(res => {
                    obInputHeadRef.value.searchItemList.CustomerList.customerName = ''
                    obInputHeadRef.value.searchItemList.CustomerList.customerContact = ''
                    obInputHeadRef.value.searchItemList.CustomerList.customerDeposit = ''
                    obInputHeadRef.value.searchItemList.CustomerList.customerQuotation = ''
                    obInputHeadRef.value.searchItemList.documentContent.Remark = ''
                  })
                })
              }
              else {
                obInputHeadRef.value.searchItemList.CustomerList.customerName = ''
                obInputHeadRef.value.searchItemList.CustomerList.customerContact = ''
                obInputHeadRef.value.searchItemList.CustomerList.customerDeposit = ''
                obInputHeadRef.value.searchItemList.CustomerList.customerQuotation = ''
                obInputHeadRef.value.searchItemList.documentContent.Remark = ''
              }
              ElMessage({
                message:'开单成功',
                duration:2000,
                showClose:true
              })
            }
          })
        })
      }).catch(err => {

        ElMessage(err.message)
      })

    }
    //#endregion

    //#endregion

    return {
      searchItemList,
      inputBodyRef,
      scanInValue,
      scanIn,
      clearScanIn,
      openList,
      getDataFromQuotation,
      obInputHeadRef, getDataFromRepairList, getDataFromQuotation2
    }
  }
}
</script>
<style scoped lang="less">
.EditTableFuncButton {
  margin-right: 35rem;
}
</style>