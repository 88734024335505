<template>
  <div class="TableContentAreaEdit" :style="{height:tableHeight}">
    <table class="AddContentHead">
      <tr class="headtrEdit">
        <th class="thSNEdit">
          <div :style="{ width: '3.75rem' }"></div>
        </th>
        <th>
          <div class="thoper" :style="{ width: '3.75rem' }">操作</div>
        </th>
        <th class="headtdEdit" v-if="searchItemList.TableListItems.indexOf('CommodityId') > -1">
          <div :style="{ width: searchItemList['CommodityId'].showLen + 'rem'}">{{searchItemList['CommodityId'].showName}}</div>
        </th>
        <th class="headtdEdit" v-if="searchItemList.TableListItems.indexOf('CommodityName') > -1">
          <div :style="{ width: searchItemList['CommodityName'].showLen + 'rem'}">{{searchItemList['CommodityName'].showName}}</div>
        </th>
        <th class="headtdEdit" v-if="searchItemList.TableListItems.indexOf('SalesAttributeNo') > -1">
          <div :style="{ width: searchItemList['SalesAttributeNo'].showLen + 'rem'}">{{searchItemList['SalesAttributeNo'].showName}}</div>
        </th>
        <!-- <th class="headtdEdit" v-if="searchItemList.TableListItems.indexOf('AccountingSubjectId') > -1">
          <div :style="{ width: searchItemList['AccountingSubjectId'].showLen + 'rem'}">{{searchItemList['AccountingSubjectId'].showName}}</div>
        </th> -->
        <th class="headtdEdit" v-if="searchItemList.TableListItems.indexOf('VirtualGoods') > -1">
          <div :style="{ width: searchItemList['VirtualGoods'].showLen + 'rem'}">{{searchItemList['VirtualGoods'].showName}}</div>
        </th>
        <th class="headtdEdit" v-if="searchItemList.TableListItems.indexOf('OrderQty') > -1">
          <div :style="{ width: searchItemList['OrderQty'].showLen + 'rem'}">{{searchItemList['OrderQty'].showName}}</div>
        </th>
        <th class="headtdEdit" v-if="searchItemList.TableListItems.indexOf('OutStockQty') > -1">
          <div :style="{ width: searchItemList['OutStockQty'].showLen + 'rem'}">{{searchItemList['OutStockQty'].showName}}</div>
        </th>
        <th class="headtdEdit" v-if="searchItemList.TableListItems.indexOf('InStockQty') > -1">
          <div :style="{ width: searchItemList['InStockQty'].showLen + 'rem'}">{{searchItemList['InStockQty'].showName}}</div>
        </th>
        <th class="headtdEdit" v-if="searchItemList.TableListItems.indexOf('Unit') > -1">
          <div :style="{ width: searchItemList['Unit'].showLen + 'rem'}">{{searchItemList['Unit'].showName}}</div>
        </th>
        <th class="headtdEdit" v-if="searchItemList.TableListItems.indexOf('UnitPrice') > -1">
          <div :style="{ width: searchItemList['UnitPrice'].showLen + 'rem'}">{{searchItemList['UnitPrice'].showName}}</div>
        </th>
        <th class="headtdEdit" v-if="searchItemList.TableListItems.indexOf('AccountingSubjectId') > -1">
          <div :style="{ width: searchItemList['AccountingSubjectId'].showLen + 'rem'}">{{searchItemList['AccountingSubjectId'].showName}}</div>
        </th>
        <th class="headtdEdit" v-if="searchItemList.TableListItems.indexOf('Amount') > -1">
          <div :style="{ width: searchItemList['Amount'].showLen + 'rem'}">{{searchItemList['Amount'].showName}}</div>
        </th>
        <th class="headtdEdit" v-if="searchItemList.TableListItems.indexOf('Remark') > -1">
          <div :style="{ width: searchItemList['Remark'].showLen + 'rem'}">{{searchItemList['Remark'].showName}}</div>
        </th>

      </tr>
    </table>
    <div class="tablebodyareaEdit" :style="{ width: tablewidth + 'rem' }">
      <table class="tablebodyEdit">
        <tr :class="{activeTr:index2 == activeIndex}" class="bodytrEdit" v-for="(value2, index2) in AddContentBody" :key="'td' + index2" @mousedown="alterActive(index2)">
          <td>
            <div class="tdSN" :style="{ width: '3.75rem' }">{{ index2 + 1 }}</div>
          </td>
          <td>
            <div class="tdoper" :style="{ width: '3.75rem' }"><span class="spandele" @click="removeLine(index2)">删除</span></div>
          </td>
          <td v-if="searchItemList.TableListItems.indexOf('CommodityId') > -1">
            <div><input type="number" :style="{ width: searchItemList['CommodityId'].showLen + 'rem'}" v-model="AddContentBody[index2]['CommodityId']" disabled="disabled"></div>
          </td>
          <td class="commodityTr" v-if="searchItemList.TableListItems.indexOf('CommodityName') > -1">
            <div @click="setActiveInput(index2,'CommodityName')" @mousedown="saveEnterInValue(AddContentBody[index2]['CommodityName'])" class="tableinputContainer"><input type="text" :style="{ width: searchItemList['CommodityName'].showLen + 'rem' }" :maxlength="40" v-model="AddContentBody[index2]['CommodityName']" :disabled="AddContentBody[index2]['CommodityId'] != '' && searchItemList.docTitleTwo != 'AS' && searchItemList.docTitleTwo != 'OB'" @blur="confirmComName(index2,'CommodityName'),unbindEnter()" @click="confirmOffset($event), bindEnter" @input="insertSearch"></div>
            <div class="CommodityContainer" v-show="index2 == activeInput.activeIndex && searchResult.length != 0" :style="[{top:commTrOffset}]">
              <div class="searchContentTr" v-for="(searchitem,searchIndex) in searchResult" :key="searchitem.commodityId + 'inputBodySearch' + searchItemList.docTitleTwo" @mousedown.prevent="solveSearchResult(searchIndex)"><input v-model="searchitem.commodityName" type="text" :style="[{ width: searchItemList['CommodityName'].showLen + 'rem' },{height:'1.625rem'}]"><input v-if="$store.state.loginInitializeData.useRepairData" type="text" class="searchIndication" v-model="searchitem.show"><input type="text" class="searchIndication" v-model="searchitem.inventoryBalance"></div>
              <div class="searchContentTrLastBox">
                <div class="searchContentTrLast" :style="[{height:'1.625rem'},{lineHeight:'1.625rem'}]" @mousedown.prevent="clearSearchResult()">关闭搜索</div>
                <div v-if="$store.state.loginInitializeData.useRepairData" class="searchContentTrLast2" :style="[{height:'1.625rem'},{lineHeight:'1.625rem'}]" @mousedown.prevent="uploadUnSearchable()">上报-------未搜索到合适内容</div>
              </div>
            </div>
            <!-- <el-scrollbar class="CommodityContainerNew" height="400px" v-show="index2 == activeInput.activeIndex && searchResult.length != 0" :style="[{top:commTrOffset}]">
              <div class="searchContentTr" v-for="(searchitem,searchIndex) in searchResult" :key="searchitem.commodityId + 'inputBodySearch' + searchItemList.docTitleTwo" @mousedown.prevent="solveSearchResult(searchIndex)"><input v-model="searchitem.commodityName" type="text" :style="[{ width: searchItemList['CommodityName'].showLen + 'rem' },{height:'1.625rem'}]"><input type="text" class="searchIndication" v-model="searchitem.show"><input type="text" class="searchIndication" v-model="searchitem.inventoryBalance"></div>
              <div class="searchContentTrLastBox">
                <div class="searchContentTrLast" :style="[{height:'1.625rem'},{lineHeight:'1.625rem'}]" @mousedown.prevent="clearSearchResult()">关闭搜索</div>
                <div class="searchContentTrLast2" :style="[{height:'1.625rem'},{lineHeight:'1.625rem'}]" @mousedown.prevent="uploadUnSearchable()">上报-------未搜索到合适内容</div>
              </div>
            </el-scrollbar> -->
          </td>
          <td v-if="searchItemList.TableListItems.indexOf('SalesAttributeNo') > -1">
            <div><select class="selectInput" :style="{ width: searchItemList['SalesAttributeNo'].showLen + 'rem'}" v-model="AddContentBody[index2]['SalesAttributeNo']" :disabled="AddContentBody[index2]['CommodityName'] == ''" @change="setAttrPrice(index2)">
                <option v-for="item3 in AddContentBody[index2].SalesAttributeNoArr" :key="item3.salesAttributeId + 'RoInput'" :value="item3.salesAttributeId">{{item3.salesAttributeName}}-{{item3.recycleOrNot?'回收':'不回收'}}</option>
              </select></div>
          </td>
          <!-- <td v-if="searchItemList.TableListItems.indexOf('AccountingSubjectId') > -1">
            <div><select :style="{ width: searchItemList['AccountingSubjectId'].showLen + 'rem'}" v-model="AddContentBody[index2]['AccountingSubjectId']">
                <option v-for="item in accoutSelectItems" :key="item.accoutId + searchItemList.docTitleTwo" value="item.accoutId">{{item.accoutName}}</option>
              </select></div>
          </td> -->
          <td v-if="searchItemList.TableListItems.indexOf('VirtualGoods') > -1">
            <div class="checkContainer" :style="{ width: searchItemList['VirtualGoods'].showLen + 'rem'}"><input class="checkbo" type="checkbox" :style="{ width:'1.25rem' }" v-model="AddContentBody[index2]['VirtualGoods']" :disabled="AddContentBody[index2]['CommodityName'] == '' || true" @input="alertVirtual(index2,$event)"></div>
          </td>
          <td v-if="searchItemList.TableListItems.indexOf('OrderQty') > -1">
            <div><input type="number" @input="refreashAmount()" :style="{ width: searchItemList['OrderQty'].showLen + 'rem'}" v-model="AddContentBody[index2]['OrderQty']" :disabled="AddContentBody[index2]['CommodityName'] == ''" @blur="checkQty(index2,$event,'OrderQty')"></div>
          </td>
          <td v-if="searchItemList.TableListItems.indexOf('OutStockQty') > -1">
            <div><input type="number" @input="refreashAmount()" :style="{ width: searchItemList['OutStockQty'].showLen + 'rem'}" v-model="AddContentBody[index2]['OutStockQty']" :disabled="AddContentBody[index2]['CommodityName'] == ''" @blur="checkQty(index2,$event,'OutStockQty')"></div>
          </td>
          <td v-if="searchItemList.TableListItems.indexOf('InStockQty') > -1">
            <div><input type="number" @input="refreashAmount()" :style="{ width: searchItemList['InStockQty'].showLen + 'rem'}" v-model="AddContentBody[index2]['InStockQty']" :disabled="AddContentBody[index2]['CommodityName'] == ''" @blur="checkQty(index2,$event,'InStockQty')"></div>
          </td>
          <td v-if="searchItemList.TableListItems.indexOf('Unit') > -1">
            <div><input type="text" :style="{ width: searchItemList['Unit'].showLen + 'rem'}" v-model="AddContentBody[index2]['Unit']" :disabled="true"></div>
          </td>
          <td v-if="searchItemList.TableListItems.indexOf('UnitPrice') > -1">
            <div><input @input="refreashAmount()" type="number" step="0.01" :style="{ width: searchItemList['UnitPrice'].showLen + 'rem'}" v-model="AddContentBody[index2]['UnitPrice']" :disabled="AddContentBody[index2]['CommodityName'] == ''" @blur="checkPrice(index2,$event)"></div>
          </td>
          <td v-if="searchItemList.TableListItems.indexOf('AccountingSubjectId') > -1">
            <!-- <select :style="{ width: searchItemList['AccountingSubjectId'].showLen + 'rem'}" v-model="AddContentBody[index2]['AccountingSubjectId']" :disabled="AddContentBody[index2]['UnitPrice'] == ''">
              <option v-for="item in AccountingSubjectIdFilter" :key="searchItemList.docTitleTwo + item.beforeTransfer + 'Account'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
            </select> -->
            <el-select :style="[{ width: searchItemList['AccountingSubjectId'].showLen + 'rem'},{height:trHeight},{border:'none'}]" v-model="AddContentBody[index2]['AccountingSubjectId']" class="m-2" :disabled="AddContentBody[index2]['UnitPrice'] == ''" placeholder=" ">
              <el-option v-for="item in AccountingSubjectIdFilter" :key="searchItemList.docTitleTwo + item.beforeTransfer + 'Account'" :label="item.afterTransfer" :value="item.beforeTransfer">
              </el-option>
            </el-select>
          </td>
          <td v-if="searchItemList.TableListItems.indexOf('Amount') > -1">
            <div><input type="number" step="0.01" :style="{ width: searchItemList['Amount'].showLen + 'rem'}" disabled="disabled" v-model="AddContentBody[index2]['Amount']"></div>
          </td>
          <td v-if="searchItemList.TableListItems.indexOf('Remark') > -1">
            <div><input type="text" :maxlength="30" :style="{ width: searchItemList['Remark'].showLen + 'rem'}" v-model="AddContentBody[index2]['Remark']" :disabled="AddContentBody[index2]['CommodityName'] == ''"></div>
          </td>
        </tr>
      </table>
    </div>
    <table class="tablefoot">
      <tr class="foottr">
        <td>
          <div class="footsum" :style="{ width: '3.75rem' }">合计</div>
        </td>
        <td>
          <div :style="{ width: '3.75rem'}"></div>
        </td>
        <td v-if="searchItemList.TableListItems.indexOf('CommodityId') > -1">
          <div :style="{ width: searchItemList['CommodityId'].showLen + 'rem'}"></div>
        </td>
        <td v-if="searchItemList.TableListItems.indexOf('CommodityName') > -1">
          <div :style="{ width: searchItemList['CommodityName'].showLen + 'rem'}"></div>
        </td>
        <td v-if="searchItemList.TableListItems.indexOf('SalesAttributeNo') > -1">
          <div :style="{ width: searchItemList['SalesAttributeNo'].showLen + 'rem'}"></div>
        </td>
        <!-- <td v-if="searchItemList.TableListItems.indexOf('AccountingSubjectId') > -1">
          <div :style="{ width: searchItemList['AccountingSubjectId'].showLen + 'rem'}"></div>
        </td> -->
        <td v-if="searchItemList.TableListItems.indexOf('VirtualGoods') > -1">
          <div :style="{ width: searchItemList['VirtualGoods'].showLen + 'rem'}"></div>
        </td>
        <td v-if="searchItemList.TableListItems.indexOf('OrderQty') > -1">
          <div :style="{ width: searchItemList['OrderQty'].showLen + 'rem'}">{{footDatas.OrderQty}}</div>
        </td>
        <td v-if="searchItemList.TableListItems.indexOf('OutStockQty') > -1">
          <div :style="{ width: searchItemList['OutStockQty'].showLen + 'rem'}">{{footDatas.OutStockQty}}</div>
        </td>
        <td v-if="searchItemList.TableListItems.indexOf('InStockQty') > -1">
          <div :style="{ width: searchItemList['InStockQty'].showLen + 'rem'}">{{footDatas.InStockQty}}</div>
        </td>
        <td v-if="searchItemList.TableListItems.indexOf('Unit') > -1">
          <div :style="{ width: searchItemList['Unit'].showLen + 'rem'}"></div>
        </td>
        <td v-if="searchItemList.TableListItems.indexOf('UnitPrice') > -1">
          <div :style="{ width: searchItemList['UnitPrice'].showLen + 'rem'}">{{footDatas.UnitPrice== 0 ? '':footDatas.UnitPrice}}</div>
        </td>
        <td v-if="searchItemList.TableListItems.indexOf('AccountingSubjectId') > -1">
          <div :style="{ width: searchItemList['AccountingSubjectId'].showLen + 'rem'}"></div>
        </td>
        <td v-if="searchItemList.TableListItems.indexOf('Amount') > -1">
          <div :style="{ width: searchItemList['Amount'].showLen + 'rem'}">{{footDatas.Amount}}</div>
        </td>
        <td v-if="searchItemList.TableListItems.indexOf('Remark') > -1">
          <div :style="{ width: searchItemList['Remark'].showLen + 'rem'}"></div>
        </td>

      </tr>
    </table>
  </div>
</template>

<script>
import { computed, inject, onUnmounted, reactive, ref, watch } from 'vue-demi'
import { copyArray, copyObj, insertSearchOrigin } from '@/hooks/tools';
import { useStore } from "vuex";
import { fillContentWithRes, getVmiCid, getArrAttriByCoId, transferToName, changeToViewCode, checkIsXl } from "@/hooks/OpenOrder.js"
import requestData from "@/hooks/requestData.js";
import { checkCommIds } from "@/hooks/OpenOrder.js";
import _ from "lodash";
import { ElMessage } from 'element-plus'
export default {
  components: {
  },
  setup(props, { emit }) {

    //#region 数据定义区

    const store = useStore()

    let isXl = ref(false)

    checkIsXl(store.state.loginInitializeData.companyId).then(res => {
      if (res == 'pass') {
        isXl.value = true
      }
    })

    let searchItemList = inject('searchItemList')

    let AccountingSubjectIdFilter = copyArray(store.state.loginInitializeData.filterList.AccountingSubjectId)

    const AccList = store.state.loginInitializeData.authList.accountingSubjectsManages


    if (searchItemList.tableName == 'EoList' || searchItemList.tableName == 'IoList' || searchItemList.tableName == 'RbList') {
      const lenForAcc = AccountingSubjectIdFilter?.length
      if (lenForAcc > 0) {
        for (let i = lenForAcc - 1; i >= 0; i--) {
          if (searchItemList.tableName == 'EoList' || searchItemList.tableName == 'RbList') {
            if (!AccList.find(x => x.accountingSubjectId == AccountingSubjectIdFilter[i].beforeTransfer).isExpenseOrNot) {
              AccountingSubjectIdFilter.splice(i, 1)
              continue
            }
          }
          if (searchItemList.tableName == 'IoList') {
            if (AccList.find(x => x.accountingSubjectId == AccountingSubjectIdFilter[i].beforeTransfer).isExpenseOrNot) {
              AccountingSubjectIdFilter.splice(i, 1)
              continue
            }
          }
        }
      }
    }


    let accoutSelectItems = computed(() => {
      let judge = searchItemList.docTitleTwo
      const inList = []
      const outList = []
      switch (judge) {
        case 'PO':
          return inList;
        default:
          return outList;
      }
    })

    let tableHeight = ref('60%')

    if (searchItemList.docTitleTwo == 'RO') {
      tableHeight.value = '40%'
    }

    const setAttrPrice = (index) => {
      if (isXl.value) {
        let b = AddContentBody[index].SalesAttributeNoArr.find(x => x.salesAttributeId == AddContentBody[index].SalesAttributeNo).salesPrice
        AddContentBody[index].UnitPrice = b
        AddContentBody[index].Amount = b * AddContentBody[index].OrderQty
      }
    }

    const AddContentHead = {}

    let AddContentBody = reactive([])

    let AddContentSample = searchItemList.addBodyContent;

    //d点击入输入框先存值用于后面比对
    let enterInValue = ref('');
    let tablewidth = computed(() => {
      let widthAll = 0;
      for (let key in AddContentHead) {
        widthAll += +AddContentHead[key].showLen
      }
      return (
        widthAll + 9.5
      );
    });

    //foot数据
    let footDatas = computed(() => {
      let OrderQtySum = 0;
      let OutStockQtySum = 0;
      let InStockQtySum = 0;
      let AmountSum = 0;
      let UnitPriceSum = 0;
      let footDataPre = {};
      for (let item of AddContentBody) {
        for (let key in item) {
          if (key == 'OrderQty') {
            OrderQtySum += +item.OrderQty
          }
          if (key == 'OutStockQty') {
            OutStockQtySum += +item.OutStockQty
          }
          if (key == 'InStockQty') {
            InStockQtySum += +item.InStockQty
          }
          if (key == 'Amount') {
            AmountSum += +item.Amount
          }
          if (key == 'UnitPrice' && (searchItemList.docTitleTwo == 'MO' || searchItemList.docTitleTwo == 'MI')) {
            UnitPriceSum += +item.UnitPrice
          }
        }
      }
      footDataPre.OrderQty = OrderQtySum
      footDataPre.OutStockQty = OutStockQtySum
      footDataPre.InStockQty = InStockQtySum
      footDataPre.Amount = AmountSum.toFixed(2)
      footDataPre.UnitPrice = UnitPriceSum.toFixed(2)
      return (
        reactive(footDataPre)
      );
    });

    watch(footDatas, () => {
      emit('countValueAfterDiscount', footDatas)
    })
    //d添加活跃行的索引
    let activeIndex = ref(-1);
    let searchResult = reactive([]);


    let commTrOffset = computed(() => {
      return '2rem'
    })

    const clearSearchResult = () => {
      searchResult.splice(0)
    }

    let offsetDistance = ref(0)
    let activeInput = reactive({
      activeIndex: -1,
      activeFields: ''
    })
    //#endregion

    watch(() => activeInput.activeIndex, () => {
      searchResult.splice(0)
    })

    //#region 公式定义区
    const fillAddContentHead = () => {
      for (let item1 of searchItemList.TableListItems) {
        for (let key in searchItemList) {
          if (item1 == key) {
            AddContentHead[item1] = searchItemList[item1]
          }
        }
      }
    }
    const fillAddContentBody = () => {
      for (let i = 0; i < 20; i++) {
        let b = copyObj(AddContentSample)
        AddContentBody.push(b)
      }
    }
    const alterActive = (num) => {
      activeIndex.value = num;
    }
    //f删除一行的方法
    const removeLine = (index) => {
      AddContentBody.splice(index, 1)
      let b = copyObj(AddContentSample)
      AddContentBody.push(b)
    }
    //f存入活跃单元格数据
    const setActiveInput = (index, fields) => {
      activeInput.activeIndex = index;
      activeInput.activeFields = fields;
    }
    const saveEnterInValue = (value) => {
      setTimeout(() => {
        enterInValue.value = value
      }, 200)
    }
    //f确认商品输入的方法
    const confirmComName = (index, fieldsName) => {
      if (searchItemList.docTitleTwo == 'OB' || searchItemList.docTitleTwo == 'AS') {
        if (AddContentBody[index][fieldsName] == '') {
          let d = copyObj(AddContentSample)
          AddContentBody.splice(index, 1, d)
          return;
        }
        return;
      }
      searchResult.splice(0)
      if (AddContentBody[index][fieldsName] == enterInValue.value && AddContentBody[index].OrderQty != '') {
        if (searchItemList.docTitleTwo == 'RO') {
          getVmiCid().then(cid => {
            getArrAttriByCoId(AddContentBody[index].CommodityId, cid).then(arr => {
              for (let item4 of arr) {
                item4.salesAttributeName = transferToName(item4.salesAttributeId, "SalesAttributeId")
              }
              AddContentBody[index].SalesAttributeNoArr = arr
              AddContentBody[index].SalesAttributeNo = arr ? (arr?.find(x => x.salesAttributeId == 10001) ? 10001 : arr[0]?.salesAttributeId) : ''
              if (isXl.value) {
                AddContentBody[index].UnitPrice = arr ? (arr?.find(x => x.salesAttributeId == 10001) ? arr?.find(x => x.salesAttributeId == 10001).salesPrice : arr[0]?.salesPrice) : 0
                AddContentBody[index].Amount = AddContentBody[index].UnitPrice
              }
            })
          })

        }
        return false;
      }
      if (AddContentBody[index][fieldsName] == '') {
        let d = copyObj(AddContentSample)
        AddContentBody.splice(index, 1, d)
        return false;
      }
      const filterA = store.state.loginInitializeData.filterList.BusinessPartnerId;
      let bpId = ''
      if (searchItemList.documentContent.PartBCompanyId != '') {
        bpId = filterA.filter(x => x.afterTransfer == searchItemList.documentContent.PartBCompanyId)[0]?.beforeTransfer
      }
      let config = {
        method: "get",
        url: "/CommodityMangeTable/GetSingle",
        params: {
          name: AddContentBody[index][fieldsName],
          bpId: bpId
        }
      };
      requestData.GetCache(config).then(res => {
        if (res == 'notfound') {
          let b = copyObj(AddContentSample)
          AddContentBody.splice(index, 1, b)
        }
        else {
          for (let a in AddContentBody) {
            if (AddContentBody[a].CommodityId == res.split('|')[0] && a != index) {
              AddContentBody[a].OrderQty++
              let c = copyObj(AddContentSample)
              AddContentBody.splice(index, 1, c)
              return false;
            }
          }
          fillContentWithRes(AddContentBody, res, index, searchItemList.docTitleTwo)
          if (searchItemList.docTitleTwo == 'RO') {


            getVmiCid().then(cid => {
              getArrAttriByCoId(res.split('|')[0], cid).then(arr => {
                for (let item4 of arr) {
                  item4.salesAttributeName = transferToName(item4.salesAttributeId, "SalesAttributeId")
                }
                AddContentBody[index].SalesAttributeNoArr = arr
                AddContentBody[index].SalesAttributeNo = arr ? (arr?.find(x => x.salesAttributeId == 10001) ? 10001 : arr[0]?.salesAttributeId) : ''
                if (isXl.value) {
                  AddContentBody[index].UnitPrice = arr ? (arr?.find(x => x.salesAttributeId == 10001) ? arr?.find(x => x.salesAttributeId == 10001).salesPrice : arr[0]?.salesPrice) : 0
                  AddContentBody[index].Amount = AddContentBody[index].UnitPrice
                }
              })
            })
          }
          if (index == AddContentBody.length - 1) {
            for (let i = 0; i < 5; i++) {
              let b = copyObj(AddContentSample)
              AddContentBody.push(b)
            }
          }
        }
      })
    }
    const enterCheckComName = (e) => {
      if (e.keyCode == 13) {
        confirmComName(activeInput.activeIndex, activeInput.activeFields)
      }
    }
    const bindEnter = () => {
      window.addEventListener('keydown', enterCheckComName)
    }
    const unbindEnter = () => {
      window.removeEventListener('keydown', enterCheckComName)
    }
    const confirmOffset = (e) => {
      offsetDistance.value = e.clientY
    }
    const insertSearch = _.debounce((e) => {

      insertSearchOrigin(e, searchResult)
    }, 500)

    const solveSearchResult = (index) => {
      const filterA = store.state.loginInitializeData.filterList.BusinessPartnerId;
      let bpId = ''
      if (searchItemList.documentContent.PartBCompanyId != '') {
        bpId = filterA.filter(x => x.afterTransfer == searchItemList.documentContent.PartBCompanyId)[0]?.beforeTransfer
      }
      let config = {
        method: "get",
        url: "/CommodityMangeTable/GetSingleById",
        params: {
          id: searchResult[index].commodityId,
          bpId: bpId
        }
      }
      requestData.GetCache(config).then(res => {
        if (res == 'notfound') {
          let b = copyObj(AddContentSample)
          AddContentBody.splice(activeInput.activeIndex, 1, b)
        }
        else {
          for (let a in AddContentBody) {
            if (AddContentBody[a].CommodityId == res.split('|')[0] && a != activeInput.activeIndex) {
              AddContentBody[a].OrderQty++
              AddContentBody[a].Amount = AddContentBody[a].OrderQty * AddContentBody[a].UnitPrice
              let c = copyObj(AddContentSample)
              AddContentBody.splice(activeInput.activeIndex, 1, c)
              activeInput.activeIndex = -1
              return false;
            }
          }
          fillContentWithRes(AddContentBody, res, activeInput.activeIndex, searchItemList.docTitleTwo)

          if (searchItemList.docTitleTwo == 'RO') {
            getVmiCid().then(cid => {
              getArrAttriByCoId(res.split('|')[0], cid).then(arr => {
                for (let item4 of arr) {
                  item4.salesAttributeName = transferToName(item4.salesAttributeId, "SalesAttributeId")
                }
                AddContentBody[activeIndex.value].SalesAttributeNoArr = arr
                AddContentBody[activeIndex.value].SalesAttributeNo = arr ? (arr?.find(x => x.salesAttributeId == 10001) ? 10001 : arr[0]?.salesAttributeId) : ''
                if (isXl.value) {
                  AddContentBody[activeIndex.value].UnitPrice = arr ? (arr?.find(x => x.salesAttributeId == 10001) ? arr?.find(x => x.salesAttributeId == 10001).salesPrice : arr[0]?.salesPrice) : 0
                  AddContentBody[activeIndex.value].Amount = AddContentBody[activeIndex.value].UnitPrice
                }
              })
            })


          }
          if (index == AddContentBody.length - 1) {
            for (let i = 0; i < 5; i++) {
              let b = copyObj(AddContentSample)
              AddContentBody.push(b)
            }
          }
          enterInValue.value = res.split("|")[1]
          activeInput.activeIndex = -1
          searchResult.splice(0)
        }
      })
    }

    const refreashAmount = _.debounce(() => {
      refreashAmountAction()
    }, 0)

    const refreashAmountAction = () => {
      let qty = 0;
      let price = 0;
      if (!AddContentBody[activeIndex.value].OrderQty || AddContentBody[activeIndex.value].OrderQty == '') {
      } else {
        qty = AddContentBody[activeIndex.value].OrderQty
      }
      if (!AddContentBody[activeIndex.value].OutStockQty || AddContentBody[activeIndex.value].OutStockQty == '') {
      } else {
        qty = AddContentBody[activeIndex.value].OutStockQty
      }
      if (!AddContentBody[activeIndex.value].InStockQty || AddContentBody[activeIndex.value].InStockQty == '') {
      } else {
        qty = AddContentBody[activeIndex.value].InStockQty
      }
      if (!AddContentBody[activeIndex.value].UnitPrice || AddContentBody[activeIndex.value].UnitPrice == '') {
      } else {
        price = AddContentBody[activeIndex.value].UnitPrice
      }
      if (AddContentBody[activeIndex.value].CommodityName) {
        AddContentBody[activeIndex.value].Amount = (+qty * +price).toFixed(2)
      }
    }

    let scanInData = (res) => {
      for (let a of AddContentBody) {
        if (a.CommodityId == res.split('|')[0]) {
          a.OrderQty++
          a.Amount = (a.OrderQty * a.UnitPrice).toFixed(2)
          return false;
        }
      }
      for (let index in AddContentBody) {
        if (AddContentBody[index].CommodityName == '') {
          fillContentWithRes(AddContentBody, res, index, searchItemList.docTitleTwo)
          if (searchItemList.docTitleTwo == 'RO') {
            getVmiCid().then(cid => {
              getArrAttriByCoId(res.split('|')[0], cid).then(arr => {
                for (let item4 of arr) {
                  item4.salesAttributeName = transferToName(item4.salesAttributeId, "SalesAttributeId")
                }
                AddContentBody[index].SalesAttributeNoArr = arr
                AddContentBody[index].SalesAttributeNo = arr ? (arr?.find(x => x.salesAttributeId == 10001) ? 10001 : arr[0]?.salesAttributeId) : ''
                if (isXl.value) {
                  AddContentBody[index].UnitPrice = arr ? (arr?.find(x => x.salesAttributeId == 10001) ? arr?.find(x => x.salesAttributeId == 10001).salesPrice : arr[0]?.salesPrice) : 0
                  AddContentBody[index].Amount = AddContentBody[index].UnitPrice
                }
              })
            })
          }
          if (index == AddContentBody.length - 1) {
            for (let i = 0; i < 5; i++) {
              let b = copyObj(AddContentSample)
              AddContentBody.push(b)
            }
          }
          return false;
        }
      }
    }

    const alertVirtual = (index, e) => {
      if (e.target.checked) {
        if (confirm('勾选虚拟物品后将不做出入库处理')) {
          return
        } else {
          setTimeout(() => {
            AddContentBody[index].VirtualGoods = false
          }, 0);

        }
      }
    }

    const fillUploadData = (upLoadContent) => {

      let checkIds = []
      for (let item of upLoadContent) {
        checkIds.push(item['CommodityId'])
      }

      checkCommIds(checkIds).then(res => {
        if (res != 'pass') {
          ElMessage(res)
          return false
        }
        AddContentBody.splice(0)
        let tableRows = (upLoadContent.length + 5) < 20 ? 20 : upLoadContent.length + 5
        for (let i = 0; i < tableRows; i++) {
          let b = copyObj(AddContentSample)
          AddContentBody.push(b)
        }
        const filterA = store.state.loginInitializeData.filterList.BusinessPartnerId;
        let bpId = ''
        if (searchItemList.documentContent.PartBCompanyId != '') {
          bpId = filterA.filter(x => x.afterTransfer == searchItemList.documentContent.PartBCompanyId)[0]?.beforeTransfer
        }
        for (let index in upLoadContent) {
          const config = {
            method: "get",
            url: "/CommodityMangeTable/GetSingleById",
            params: {
              id: upLoadContent[index]['CommodityId'],
              bpId: bpId
            }
          };
          requestData.GetData(config).then(res => {
            AddContentBody[index]['CommodityId'] = upLoadContent[index]['CommodityId']
            AddContentBody[index]['CommodityName'] = res.split('|')[1]
            AddContentBody[index]['VirtualGoods'] = res.split('|')[3] == 'True' ? true : false;
            AddContentBody[index]['OrderQty'] = upLoadContent[index]['OrderQty']
            AddContentBody[index]['Unit'] = res.split('|')[2];
            AddContentBody[index]['Remark'] = upLoadContent[index]['Remark'];
            switch (searchItemList.docTitleTwo) {
              case 'PO':
                AddContentBody[index]['UnitPrice'] = upLoadContent[index]['UnitPrice'] > 0 ? upLoadContent[index]['UnitPrice'] : res.split('|')[4];
                break;
              case 'SO':
                AddContentBody[index]['UnitPrice'] = upLoadContent[index]['UnitPrice'] > 0 ? upLoadContent[index]['UnitPrice'] : res.split('|')[5];
                break;
              case 'RO':
                AddContentBody[index]['UnitPrice'] = upLoadContent[index]['UnitPrice'] > 0 ? upLoadContent[index]['UnitPrice'] : res.split('|')[5];
                break;
              default:
                AddContentBody[index]['UnitPrice'] = upLoadContent[index]['UnitPrice'];
            }
            AddContentBody[index]['Amount'] = (AddContentBody[index]['OrderQty'] * AddContentBody[index]['UnitPrice']).toFixed(2)
          })
        }
      })
    }

    const uploadUnSearchable = () => {
      const contentSearch = AddContentBody[activeIndex.value].CommodityName

      if (!contentSearch) {
        ElMessage('无内容上传')
        return;
      }

      const suggestObj = {
        SuggestionType: '商品缺失',
        SuggestionContent: contentSearch,
        UserId: store.state.loginInitializeData.userId,
        UserName: store.state.loginInitializeData.userName,
      }

      const config = {
        method: "post",
        url: "/CustomerSuggestionList/Add",
        data: suggestObj
      }

      requestData.GetData(config).then(res => {
        if (res != 'pass') {
          ElMessage(res)
        }
        else {
          ElMessage('上传成功,我们将尽快添加')
          AddContentBody[activeIndex.value].CommodityName = ''
          searchResult.splice(0)
        }
      })

    }

    const checkQty = (index, e, fieName) => {
      if (!parseInt(e.target.value)) {
        AddContentBody[index][fieName] = 1
      }
    }

    const checkPrice = (index, e) => {
      if (searchItemList.docTitleTwo == 'MI' ||
        searchItemList.docTitleTwo == 'MO' ||
        searchItemList.docTitleTwo == 'ER' ||
        searchItemList.docTitleTwo == 'ED' ||
        searchItemList.docTitleTwo == 'GR' ||
        searchItemList.docTitleTwo == 'GR' ||
        searchItemList.docTitleTwo == 'EO' ||
        searchItemList.docTitleTwo == 'IO' ||
        searchItemList.docTitleTwo == 'RB') {
        return
      }
      if (!e.target.value) {
        AddContentBody[index].UnitPrice = 0.01
        AddContentBody[index].Amount = AddContentBody[index].UnitPrice * AddContentBody[index].OrderQty
      }
      if (parseFloat(e.target.value) < 0) {
        AddContentBody[index].UnitPrice = 0.01
        AddContentBody[index].Amount = AddContentBody[index].UnitPrice * AddContentBody[index].OrderQty
      }
    }

    const trHeight = ref('2rem')


    //#endregion

    //#region 数据提供区

    //#endregion

    //#region 挂载前加载
    fillAddContentHead()
    fillAddContentBody()

    //#region 上传模块
    //#endregion

    //#endregion
    //#region 监控区

    //#endregion

    //#region 失去活跃处理

    //#endregion

    //#region 活跃时处理

    //#endregion

    //#region 离开时处理
    onUnmounted(() => {

    })
    //#endregion

    return {
      tableHeight, setAttrPrice, AccountingSubjectIdFilter,
      searchItemList, trHeight,
      tablewidth, uploadUnSearchable,
      activeIndex,
      alterActive,
      setActiveInput,
      saveEnterInValue,
      confirmComName,
      unbindEnter,
      bindEnter,
      confirmOffset,
      insertSearch,
      commTrOffset,
      solveSearchResult,
      AddContentBody,
      activeInput,
      searchResult,
      refreashAmount,
      footDatas,
      removeLine,
      scanInData,
      alertVirtual,
      fillUploadData,
      checkQty,
      checkPrice,
      accoutSelectItems,
      clearSearchResult
    }
  }
}
</script>

<style scoped lang="less">
@trHeight: 2rem;
.TableContentAreaEdit {
  position: relative;
  margin-top: 1rem;
  margin-left: 0.5rem;
  overflow: hidden;
  overflow-x: auto;
  width: 98%;
}
.headtrEdit {
  background-color: #1e87f0;
  height: @trHeight;
  top: -1.25rem;
  color: #fff;
}

.thSNEdit {
  font-family: "icomoon";
  color: #fff;
  font-size: 1rem;
}
.headtdEdit {
  cursor: pointer;
}

.tablebodyareaEdit {
  height: calc(100% - 3.75rem);
  overflow: hidden;
  overflow-y: auto;
}
.tablebodyEdit tr:hover {
  background-color: #edf4fe;
}
.tablebodyEdit {
  margin-top: -1px;
}
.bodytrEdit input {
  border: none;
  outline: none;
  height: @trHeight;
  background-color: transparent;
  text-align: center;
}
.bodytrEdit input:focus {
  border: 1px solid #ffa004;
}

.bodytrEdit select {
  border: none;
  outline: none;
  height: @trHeight;
  background-color: transparent;
  text-align: center;
}
.bodytrEdit select:focus {
  border: 1px solid #ffa004;
}
// .bodytrEdit {
//   // height: 1.6rem;
//   // border: 1px solid #ffa004;
// }

.tdSN,
.tableselect,
.tdoper,
.footsum {
  text-align: center;
}
.commodityTr {
  position: relative;
}
.tableinputContainer {
  display: flex;
  position: relative;
}

.CommodityContainer {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  margin-top: 1px;
  border: 1px solid #cccccc;
  max-height: 20rem;
  overflow: scroll;

  // border-left: 2px solid #4e71f2;
  // border-right: 2px solid #4e71f2;
  // border-bottom: 2px solid #4e71f2;
}
// .CommodityContainerNew {
//   position: absolute;
//   background-color: #fff;
//   z-index: 2;
//   margin-top: 1px;
//   border: 1px solid #cccccc;
// }

.searchContentTr {
  display: flex;
}
.searchContentTr:hover {
  background-color: #fff;
  background-color: #f5f5f6;
}
.searchContentTr input {
  text-align: left;
}

.searchContentTr input:hover {
  cursor: pointer;
}
.searchContentTrLast:hover {
  background-color: #fa2c2c;
  color: #fff;
  cursor: pointer;
}
.searchContentTrLast {
  padding-left: 0.3rem;
  width: 62%;
}
.searchContentTrLast2:hover {
  background-color: #fa2c2c;
  color: #fff;
  cursor: pointer;
}
.searchContentTrLast2 {
  padding-left: 0.3rem;
  background-color: #ffc000;
  width: 38%;
}
.searchIndication {
  text-align: center !important;
  color: red;
}
.tablefoot {
  margin-top: -1px;
}
.foottr {
  background-color: #fefbe5;
}
.foottr {
  height: @trHeight;
}
.checkbo {
  cursor: pointer;
}
.foottr div {
  text-align: center;
}

.selectInput {
  border: none;
  // appearance: none;
  height: @trHeight;
  background-color: transparent;
  text-align-last: center;
}
.checkContainer {
  display: flex;
}
.checkbo {
  margin: auto;
}
.searchContentTrLastBox {
  width: 100%;
  display: flex;
}
</style>