<template>
  <div class="tableLoaderContainer">

    <div class="BodyTitle">
      <div class="BodyTitleText">等待回收列表</div>
    </div>

    <div class="searchContentContainer">
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="PartBCompanyIdForRecyleOnTheWay">来源单位:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'PartBCompanyId'}"><select v-model="SearchContent.PartBCompanyId" class="searchinput" id="PartBCompanyIdForRecyleOnTheWay" @focus="setActiveId('PartBCompanyId')" @blur="setActiveId('')" @change="reSearch()">
            <option value="">所有</option>
            <option v-for="item in PartBCompanyIdFilterCopy" :key="item.beforeTransfer + 'RecyleOnTheWay'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
          </select>
        </div>
      </div>

      <div class="searchItemContainer">
        <label class="searchinputLabel" for="SalesAttributeNoForRecyleOnTheWay">回收属性:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'SalesAttributeNo'}"><select v-model="SearchContent.SalesAttributeNo" class="searchinput" id="SalesAttributeNoForRecyleOnTheWay" @focus="setActiveId('SalesAttributeNo')" @blur="setActiveId('')" @change="reSearch(),setDefaultOutWarehouse()">
            <option v-for="item in SalesAttributeNoFilterCopy" :key="item.beforeTransfer + 'RecyleOnTheWay'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
            <option value="">所有</option>
          </select>
        </div>
      </div>

      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DocumentIdRecycleOnTheWay">单据编号:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentId'}"><input v-model="SearchContent.DocumentId" class="searchinput long" id="DocumentIdRecycleOnTheWay" type="text" @focus="setActiveId('DocumentId')" @blur="setActiveId('')"></div>
      </div>

      <div class="searchItemContainer">
        <label class="searchinputLabel" for="CommodityNameRecycleOnTheWay">商品名称:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'CommodityName'}"><input v-model="SearchContent.CommodityName" class="searchinput long" id="CommodityNameRecycleOnTheWay" type="text" @focus="setActiveId('CommodityName')" @blur="setActiveId('')"></div>
      </div>

      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DocumentDateLRecycleOnTheWay">发起日期</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateL'}"><input v-model="SearchContent.DocumentDateL" class="searchinput" id="DocumentDateLRecycleOnTheWay" type="date" @focus="setActiveId('DocumentDateL')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DocumentDateHRecycleOnTheWay" v-text="'---'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateH'}"><input v-model="SearchContent.DocumentDateH" class="searchinput" id="DocumentDateHRecycleOnTheWay" type="date" @focus="setActiveId('DocumentDateH')" @blur="setActiveId('')"></div>
      </div>

      <!-- 搜索模块 -->
      <div class="FuncButton" @click="searchList()">搜索</div>

    </div>

    <div class="BigTable">
      <div class="TableContentArea">
        <table class="tablehead">
          <tr class="headtr" :style="{ height : trHeight }">
            <th class="thSN">
              <div :style="{ width: SnWidth }"></div>
            </th>
            <th class="thselectall">
              <div :style="{ width: selectAllWidth }"><input class="selectAllCheckBox" type="checkbox" v-model="selectAll" /></div>
            </th>
            <th>
              <div class="thoper" :style="{ width: operationWidth }">操作</div>
            </th>

            <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'head'" :class="{  ShowUpArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == 1, ShowDownArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == -1,}" @click.stop="ChangeSort(value1.fieldsNameForVali)">
              <div :style="{ width: value1.showLen  + 'rem' }">{{ value1.showName }}</div>
            </th>
          </tr>
        </table>
        <div class="tablebodyarea" :style="{ width: tablewidth  + 'rem',height:tbodyHeight}">
          <table class="tablebody">
            <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'td' + index2">
              <td>
                <div class="tdSN" :style="{ width: SnWidth }">{{+index2 + 1}}</div>
              </td>
              <td class="tableselect">
                <div :style="{ width: selectAllWidth }"><input type="checkbox" :value="value2['recyleOnTheWayCode']" v-model="selectedItems" /></div>
              </td>
              <td>
                <div class="tdoper" :style="{ width: operationWidth }">
                  <span class="spandele" @click="instockOne(index2)">确认入库</span>
                  <span class="spanedit" v-show="value2.partACompanyId != value2.partBCompanyId" @click="openFinePop(index2)">扣款处理</span>
                  <span class="spanedit" v-show="value2.partACompanyId == value2.partBCompanyId">丢弃处理</span>
                </div>
              </td>
              <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
                <div :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
              </td>
            </tr>
          </table>
        </div>
        <table class="tablefoot">
          <tr class="foottr" :style="{ height : trHeight }">
            <td>
              <div class="footsum" :style="{ width: SnWidth }">合计</div>
            </td>
            <td>
              <div :style="{ width: selectAllWidth }"></div>
            </td>
            <td>
              <div :style="{ width: operationWidth }"></div>
            </td>
            <td v-for="value4 in tableHead" :key="value4.fieldsNameForVali + 'foot'">
              <div :style="{ width: value4.showLen  + 'rem' }"></div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="moreFunctionBox">
      <div class="selectBoxModel"><label for="inwarehouseForRecyleOntheWay">默认入库仓库:</label><select id="inwarehouseForRecyleOntheWay" v-model="defaultInWarehouse">
          <option v-for="item in inWarehouseFilter" :key="item.beforeTransfer + 'RecycleOntheway'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
        </select></div>
      <el-button class="firstButton" type="primary" @click="inStockMany()">批量入库</el-button>
      <el-button class="firstButton" type="primary" @click="goHistory()">查看历史</el-button>
    </div>

    <PopModel ref="recycleRef">
      <div class="inputBox">
        <label class="popLable" for="popCommodityName">商品名称:</label>
        <el-input class="popInput" id="popCommodityName" v-model="fineData.CommodityName" :disabled="true" type="text" />
      </div>
      <div class="inputBox">
        <label class="popLable" for="popAttriBefore">回收属性:</label>
        <el-input class="popInput" id="popAttriBefore" v-model="fineData.SalesAttributeNoBefore" :disabled="true" type="text" />
      </div>
      <div class="inputBox">
        <label class="popLable" for="popAttriAfter">扣款原因:</label>
        <el-select id="popAttriAfter" v-model="fineData.SalesAttributeNoAfter" class="m-2 popInput" placeholder="Select" @change="fineReasonChange($event)">
          <el-option v-for="item in fineData.SalesAttributeArr" :key="item.salesAttributeId +'RecycleOnTheWayFine'" :label="item.salesAttributeName" :value="item.salesAttributeId">
          </el-option>
        </el-select>
      </div>
      <div class="inputBox">
        <label class="popLable" for="popRemarkDefault">默认说明:</label>
        <el-input class="popInput" id="popRemarkDefault" v-model="fineData.RemarkDefault" :disabled="true" type="text" />
      </div>
      <div class="inputBox">
        <label class="popLable" for="popFineAmout">扣款金额:</label>
        <el-input class="popInput" id="popFineAmout" v-model="fineData.FineAmount" type="number" @blur="checkFineAmount()" />
      </div>
      <div class="inputBox">
        <label class="popLable" for="popRemarkAppend">补充说明:</label>
        <el-input class="popInput" id="popRemarkAppend" v-model="fineData.AppendRemark" type="text" />
      </div>
      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight" @click="closeRecyclePop()">取消</el-button>
        <el-button type="primary" size="small" v-show="!fineData.RecycleOrNot" class="mRight lastButton" @click="fineOnly()">扣款不入库</el-button>
        <el-button type="primary" size="small" v-show="fineData.RecycleOrNot" class="mRight lastButton" @click="fineIs()">扣款并入库</el-button>
      </div>
    </PopModel>
  </div>
</template>

<script>
import { computed, provide, reactive, ref, watch } from 'vue-demi';
import { useStore } from "vuex";
import PopModel from '@/components/Common/PopModel.vue'
import {
  ChangeFirToLo, ChangeFirToUp, ChangeYMD, changeArrContentToDate, copyArray
} from '@/hooks/tools';
import requestData from '@/hooks/requestData';
import {
  fillDefaultValue, changeToViewCode, getDocNo, getArrAttriByCoId, deleteRecycleOnTheWay,
  updateWarning, checkSoInWarehouse, getRelatedDocNo, transferToName, openIsList, deleteDocCenter, transferToCode, openGdList, openEdList, getBodyInstance, openSoList, openOnTheWayList
} from '@/hooks/OpenOrder'
import { ElMessage } from 'element-plus'
export default {
  setup(props, { emit }) {
    //#region 定义区
    const store = useStore();

    const filterList = store.state.loginInitializeData.filterList

    const PartBCompanyIdFilter = filterList.PartBCompanyId

    const PartBCompanyIdFilterCopy = copyArray(PartBCompanyIdFilter)

    const SalesAttributeNoFilterCopy = copyArray(filterList.SalesAttributeNo)

    let inWarehouseFilter = copyArray(filterList.WareHouseIdIn)

    let defaultInWarehouse = ref('')

    provide('tableTitle', '扣款处理')



    //初始化输入表名称
    const TableName = 'RecyleOnTheWayList';

    const PageName = 'RecyleOnTheWayList'

    const code = changeToViewCode(PageName)

    //#endregion

    //#region 全选
    let selectAll = ref(false);

    let selectedItems = ref([]);

    watch(selectAll, (newValue, oldValue) => {
      if (code == store.state.loginInitializeData.ShowWindow[0]) {
        if (newValue == true) {
          selectedItems.value.splice(0);

          for (let item of tableBody) {
            selectedItems.value.push(item['recyleOnTheWayCode']);
          }
        } else {
          selectedItems.value.splice(0);
        }
      }
    });

    //#endregion

    let activeInput = ref('');

    const setActiveId = (id) => {
      activeInput.value = id
    }
    let selectAllWidth = ref('2.5rem')
    let trHeight = ref('1.875rem')
    let SnWidth = ref('3.75rem')
    let operationWidth = ref('14rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')

    let SearchContent = reactive({
      RecyleOnTheWayCode: '',
      DocumentId: '',
      Sn: '',
      PartACompanyId: '',
      PartBCompanyId: '',
      StaffId: '',
      DocumentDate: '',
      DocumentType: '',
      CommodityName: '',
      CommodityId: '',
      SalesAttributeNo: '',
      Remark: '',
      OrderQty: '',
      UnitPrice: '',
      NotifyStatus: '',
      StartType: '',

    })
    fillDefaultValue(SearchContent, TableName)

    SearchContent.SalesAttributeNo = 10007

    const setDefaultOutWarehouse = () => {
      const warehouseList = store.state.loginInitializeData.authList.wareHouseLists
      defaultInWarehouse.value = inWarehouseFilter[0].beforeTransfer
      for (let item of inWarehouseFilter) {
        if (transferToName(SearchContent.SalesAttributeNo, 'SalesAttributeNo').indexOf('售后') > -1) {
          if (item.afterTransfer.indexOf('售后') > -1) {
            defaultInWarehouse.value = item.beforeTransfer
          }
        }
        if (transferToName(SearchContent.SalesAttributeNo, 'SalesAttributeNo').indexOf('外') > -1) {
          if (item.afterTransfer.indexOf('加工') > -1) {
            defaultInWarehouse.value = item.beforeTransfer
          }
        }
      }
    }
    setDefaultOutWarehouse()


    //f计算表格内容总宽度
    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) + parseFloat(operationWidth.value) * 2 + parseFloat(selectAllWidth.value) + 1.25 + tableHead.length * 1 + 0.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });

    let tableHead = reactive([
      { fieldsNameForVali: 'documentDate', showLen: 10, showName: '单据日期' },
      { fieldsNameForVali: 'partBCompanyId', showLen: 12.5, showName: '来源公司' },
      { fieldsNameForVali: 'commodityName', showLen: 25, showName: '回收商品名称' },
      { fieldsNameForVali: 'salesAttributeNo', showLen: 10, showName: '销售属性' },
      { fieldsNameForVali: 'orderQty', showLen: 3.75, showName: '数量' },
      { fieldsNameForVali: 'documentId', showLen: 16, showName: '单据Id' },
      { fieldsNameForVali: 'sn', showLen: 3.75, showName: '序' },
      { fieldsNameForVali: 'remark', showLen: 12.5, showName: '备注' },
      // { fieldsNameForVali: 'documentType', showLen: 80, showName: '单据类型' },
      // { fieldsNameForVali: 'commodityId', showLen: 80, showName: '回收商品Id' },
      // { fieldsNameForVali: 'unitPrice', showLen: 80, showName: '回收价值' },
      // { fieldsNameForVali: 'notifyStatus', showLen: 80, showName: '通知状态' },
      // { fieldsNameForVali: 'startType', showLen: 80, showName: '发起类型' },
    ]);

    const searchElement = reactive({
      SortFieldsName: 'DocumentDate',
      SortDirection: 1
    })

    let tableBody = reactive([]);

    let ChangeSort = (fildsname) => {
      searchElement.SortFieldsName = ChangeFirToUp(fildsname)
      searchElement.SortDirection = -searchElement.SortDirection
      searchList()
    };

    const reSearch = () => {
      searchList()
    }

    let searchList = () => {
      let config = {
        method: "post",
        url: '/RecyleOnTheWayList/GetListByMulSearchForRecycle',
        data: Object.assign(SearchContent, searchElement),
      };
      requestData
        .GetData(config)
        .then((res) => {
          selectAll.value = false
          selectedItems.value.splice(0)
          tableBody.splice(0);
          tableBody.push.apply(tableBody, res['recyleOnTheWayListMultiple']);
          changeArrContentToDate(tableBody, PageName)
          const filterList = store.state.loginInitializeData.filterList
          const companyFilter = filterList.OwedCompanyId
          const salesAttriFilter = filterList.SalesAttributeNo
          for (let item of tableBody) {
            for (let key in item) {
              if (key == 'partBCompanyId') {
                item[key] = companyFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'partACompanyId') {
                item[key] = companyFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'salesAttributeNo') {
                item[key] = salesAttriFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
            }
          }
        })
        .catch((err) => {

        });
    };
    searchList()

    const produceOneBody = (index,method) => {
      return new Promise(function (resolve, reject) {
        const partACom = store.state.loginInitializeData.companyId
        const body = tableBody[index]
        getRelatedDocNo(body.documentId + body.sn).then(res => {
          const relatedDocCode = res == '' ? body.documentId : res
          let b = {
            DocumentId: '',
            Sn: '',
            PartACompanyId: partACom,
            CommodityName: body.commodityName,
            CommodityId: body.commodityId,
            OrderQty: body.orderQty,
            Unit: '',
            UnitPrice: body.unitPrice,
            Amount: '',
            Remark: `回收物品收益，回收金额为${body.orderQty * body.unitPrice}`,
            InspectedQty: '',
            InspectionStatus: '',
            SourceDocumentId: '',
            RootSourceDocumentId: relatedDocCode,
            ModifiedTimes: '',
          }
          resolve(b)
        })
      })
    }

    const isMany = (indexs, method = '') => {
      return new Promise(function (resolve, reject) {
        let makeBodyPromiseArr = []
        let CommodityNameArr = []
        for (let index of indexs) {
          const bodyPromise = produceOneBody(index,method)
          makeBodyPromiseArr.push(bodyPromise)
          if (tableBody[index].commodityId && CommodityNameArr.indexOf(tableBody[index].commodityId) <= -1) {
            CommodityNameArr.push(tableBody[index].commodityId)
          }
        }
        Promise.all(makeBodyPromiseArr).then(bodyArr => {
          const partACom = store.state.loginInitializeData.companyId
          const userId = store.state.loginInitializeData.userId
          const bodys = bodyArr
          const inWareHouseName = transferToName(defaultInWarehouse.value, 'WareHouseId')

          let sumprice = bodys.reduce(function (total, currentValue) {
            return total + currentValue.OrderQty;
          }, 0);
          getDocNo('IS').then(res => {
            const isHeadCode = res
            let length1 = bodys.length
            for (let i = 0; i < length1; i++) {
              bodys[i].DocumentId = isHeadCode
              bodys[i].Sn = +i + 1
            }
            let isHead = {
              DocumentId: isHeadCode,
              DocumentTypeName: '',
              DocumentDate: '',
              DocumentDateTime: '',
              Online: '',
              PartACompanyId: partACom,
              UserId: userId,
              StaffId: '',
              SourceDocumentId: '',
              Amount: '',
              Qty: sumprice,
              OutWareHouseId: '',
              InWareHouseId: defaultInWarehouse.value,
              SystemStatusId: '',
              CapitalAccountId: '',
              Remark: '回收入库',
              RootSourceDocumentId: '',
            }
            openIsList(bodys, isHead, isHeadCode).then(res => {
              if (res != 'pass') {
                ElMessage(res)
                deleteDocCenter(isHeadCode).then(res => {
                  return false;
                })
              }
              let deleteArr = []
              for (let index of indexs) {
                const newAttrNo = method == '' ? transferToCode(tableBody[index].salesAttributeNo,'SalesAttributeId') : fineData.SalesAttributeNoAfter
                const newIsQty = tableBody[index].orderQty
                const newFineMoney = method == '' ? 0 : fineData.FineAmount
                const SolvedUserid = userId
                let dele = deleteRecycleOnTheWay(tableBody[index].recyleOnTheWayCode, newAttrNo, newIsQty, newFineMoney, SolvedUserid,defaultInWarehouse.value)
                deleteArr.push(dele)
              }
              Promise.all(deleteArr).then(resArr => {

                if (inWareHouseName == '主仓库') {
                  setTimeout(() => {
                    updateWarning(partACom, CommodityNameArr)
                  }, 0);
                }
                resolve(resArr)
              })
            })
          })
        })
      })
    }

    const instockOne = (index, methods = '') => {
      checkSoInWarehouse(defaultInWarehouse.value).then(res => {
        if (res != 'pass') {
          ElMessage(res);
          return;
        }
        let b = confirm('确认并回收入库')
        if (!b) {
          return;
        }
        let indexs = []
        indexs.push(index)

        isMany(indexs, methods).then(res => {
          searchList()
          emit('refreshMsg')
        })
      }).catch(err => {

        ElMessage(err.message)
      })
    }

    const inStockMany = () => {

      if (selectedItems.value.length == 0) {

        ElMessage('未选择行')
        return;
      }
      checkSoInWarehouse(defaultInWarehouse.value).then(res => {
        if (res != 'pass') {

          ElMessage(res);
          return;
        }
        let b = confirm('确认批量回收入库')
        if (!b) {

          return;
        }

        let arrLength = tableBody.length
        let indexArr = []
        for (let i = 0; i < arrLength; i++) {
          if (selectedItems.value.indexOf(tableBody[i].recyleOnTheWayCode) > -1) {
            indexArr.push(i)
          }
        }
        isMany(indexArr).then(res => {

          searchList()
          emit('refreshMsg')
        }).catch(err => {

          ElMessage(err.message)
        })
      })
    }

    const recycleRef = ref(null)

    const openFinePop = (index2) => {
      const body = tableBody[index2]
      const partAId = transferToCode(body.partACompanyId, 'OwedCompanyId')
      fineData.index = index2
      fineData.CommodityId = body.commodityId
      fineData.CommodityName = body.commodityName
      fineData.SalesAttributeNoBefore = body.salesAttributeNo
      fineData.SalesAttributeNoAfter = ''
      fineData.RecycleOrNot = true
      fineData.FineAmount = 0
      fineData.RemarkDefault = ''
      fineData.AppendRemark = ''
      fineData.VmiCost = 0
      fineData.MaxVmiCost = 0
      let beforeNo = transferToCode(body.salesAttributeNo, 'SalesAttributeNo')
      fineData.RecycleOrNot = true
      getArrAttriByCoId(body.commodityId, partAId).then(res => {
        fineData.SalesAttributeArr = res
        for (let item of fineData.SalesAttributeArr) {
          if (item.salesAttributeId == beforeNo) {
            item.salesAttributeName = '回收属性不变，其他原因扣款'
            fineData.VmiCost = item.vmiCost
          }
          if (item.vmiCost > fineData.MaxVmiCost) {
            fineData.MaxVmiCost = item.vmiCost
          }
        }

        for (let item of fineData.SalesAttributeArr) {
          switch (item.salesAttributeId) {
            case 10001:
              item.salesAttributeName = '商品无法回收';
              break;
            case 10002:
              item.salesAttributeName = '外屏有破损';
              break;
            case 10003:
              item.salesAttributeName = '内屏破损无法回收';
              break;
            case 10004:
              item.salesAttributeName = '外屏破损，背灯也有瑕疵';
              break;
            case 10005:
              item.salesAttributeName = '外屏有破损，触摸不灵';
              break;
            case 10006:
              item.salesAttributeName = '外屏有破损，有老化';
              break;
            default:
              item.salesAttributeName = '其他原因';
          }
        }
        recycleRef.value.openPopComm()
      }).catch(err => {

        ElMessage(err.message)
      })

    }
    const closeRecyclePop = () => {
      // fineData.index = -1
      // fineData.CommodityId = ''
      // fineData.CommodityName = ''
      // fineData.SalesAttributeNoBefore = ''
      // fineData.SalesAttributeArr = []
      // fineData.SalesAttributeNoAfter = ''
      // fineData.RecycleOrNot = true
      // fineData.FineAmount = 0
      // fineData.RemarkDefault = ''
      // fineData.AppendRemark = ''
      // fineData.VmiCost = 0
      // fineData.MaxVmiCost = 0
      recycleRef.value.closePopComm()
    }

    const fineReasonChange = (e) => {
      const chooseBody = fineData.SalesAttributeArr.find(x => x.salesAttributeId == e)
      const newAttr = transferToName(fineData.SalesAttributeNoAfter, 'SalesAttributeNo')
      fineData.RecycleOrNot = chooseBody.recycleOrNot
      fineData.FineAmount = chooseBody.vmiCost - fineData.VmiCost > 0 ? (chooseBody.vmiCost - fineData.VmiCost).toFixed(2) : 0

      if (fineData.SalesAttributeNoBefore == newAttr) {
        fineData.RemarkDefault = `回收属性无变化`
      }
      else {
        fineData.RemarkDefault = `回收属性由${fineData.SalesAttributeNoBefore}变为${newAttr}`
      }
    }

    const checkFineAmount = () => {
      const maxValue = fineData.MaxVmiCost - fineData.VmiCost
      if (fineData.FineAmount > maxValue) {
        ElMessage(`扣款不允许超过合约最高扣款值为${maxValue}`)
        fineData.FineAmount = maxValue
      }
      if (fineData.FineAmount < 0) {
        fineData.FineAmount = 0
      }

    }

    let fineData = reactive({
      index: -1,
      CommodityId: '',
      CommodityName: '',
      SalesAttributeNoBefore: '',
      SalesAttributeArr: [],
      SalesAttributeNoAfter: '',
      RecycleOrNot: '',
      FineAmount: 0,
      RemarkDefault: '',
      AppendRemark: '',
      VmiCost: 0,
      MaxVmiCost: 0
    })

    // const fineIsOld = () => {
    //   if (!fineData.RecycleOrNot) {

    //     ElMessage('该物品无法回收，只能扣款')
    //     return;
    //   }
    //   if (fineData.FineAmount == 0) {
    //     let b = confirm('扣款金额为0，是否继续？')
    //     if (!b) {

    //       return;
    //     }
    //   }
    //   const body = tableBody[fineData.index]
    //   const newInstockPrice = fineData.SalesAttributeArr.find(x => x.salesAttributeId == fineData.SalesAttributeNoAfter).recyclePrice
    //   getRelatedDocNo(body.documentId + body.sn).then(res => {
    //     const relatedSoCode = res
    //     const fineMoney = fineData.FineAmount
    //     const userId = store.state.loginInitializeData.userId
    //     const partBId = transferToCode(body.partBCompanyId, 'PartBCompanyId')
    //     let promiseArr = []
    //     getDocNo('GD').then(res => {
    //       const gdHeadCode = res;
    //       const gdBody = [{
    //         GdCode: '',
    //         DocumentId: gdHeadCode,
    //         Sn: 1,
    //         PartACompanyId: body.partACompanyId,
    //         PartBCompanyId: partBId,
    //         DocumentDateTime: '',
    //         OrderQty: 1,
    //         OutStockQty: 1,
    //         InStockQty: 1,
    //         UnitPrice: fineMoney,
    //         Remark: '回收扣款',
    //         SourceDocumentId: relatedSoCode,
    //         RootSourceDocumentId: relatedSoCode,
    //         ModifiedTimes: '',
    //       }]
    //       const gdHead = {
    //         DocumentId: gdHeadCode,
    //         DocumentTypeName: '',
    //         DocumentDate: '',
    //         DocumentDateTime: '',
    //         Online: '',
    //         PartACompanyId: body.partACompanyId,
    //         PartBCompanyId: partBId,
    //         UserId: userId,
    //         StaffId: '',
    //         SourceDocumentId: '',
    //         Amount: fineMoney,
    //         Qty: 1,
    //         OutWareHouseId: '',
    //         InWareHouseId: '',
    //         SystemStatusId: '',
    //         CapitalAccountId: '',
    //         Remark: '回收扣款',
    //         RootSourceDocumentId: relatedSoCode,
    //       }
    //       let acOne = openGdList(gdBody, gdHead, gdHeadCode)
    //       promiseArr.push(acOne)
    //       getDocNo('ED').then(res => {
    //         const edHeadCode = res
    //         const edBody = [{
    //           EdCode: '',
    //           DocumentId: edHeadCode,
    //           Sn: 1,
    //           PartACompanyId: partBId,
    //           PartBCompanyId: body.partACompanyId,
    //           DocumentDateTime: '',
    //           OrderQty: 1,
    //           OutStockQty: 1,
    //           InStockQty: 1,
    //           UnitPrice: fineMoney,
    //           Remark: '回收扣款',
    //           SourceDocumentId: '',
    //           RootSourceDocumentId: body.documentId,
    //           ModifiedTimes: '',
    //         }]
    //         const edHead = {
    //           DocumentId: edHeadCode,
    //           DocumentTypeName: '',
    //           DocumentDate: '',
    //           DocumentDateTime: '',
    //           Online: '',
    //           PartACompanyId: partBId,
    //           PartBCompanyId: body.partACompanyId,
    //           UserId: '',
    //           StaffId: '',
    //           SourceDocumentId: '',
    //           Amount: fineMoney,
    //           Qty: 1,
    //           OutWareHouseId: '',
    //           InWareHouseId: '',
    //           SystemStatusId: '',
    //           CapitalAccountId: '',
    //           Remark: '回收扣款',
    //           RootSourceDocumentId: body.documentId,
    //         }
    //         let actionTwo = openEdList(edBody, edHead, edHeadCode)
    //         promiseArr.push(actionTwo)
    //         Promise.all(promiseArr).then(resArr => {

    //           tableBody[fineData.index].unitPrice = newInstockPrice
    //           instockOne(fineData.index)
    //           closeRecyclePop()
    //         })
    //       })
    //     })
    //   }).catch(err => {

    //     ElMessage(err.message)
    //   })
    // }

    const fineIs = () => {
      if (!fineData.RecycleOrNot) {

        ElMessage('该物品无法回收，只能扣款')
        return;
      }
      if (!fineData.SalesAttributeNoAfter) {
        ElMessage('请勾选扣款原因')
        return;
      }
      if (fineData.FineAmount == 0) {
        let b = confirm('扣款金额为0,是否继续?')
        if (!b) {

          return;
        }
      }
      const body = tableBody[fineData.index]
      const newInstockPrice = fineData.SalesAttributeArr.find(x => x.salesAttributeId == fineData.SalesAttributeNoAfter).recyclePrice
      getRelatedDocNo(body.documentId + body.sn).then(res => {
        const relatedSoCode = res == '' ? body.documentId : res
        const fineMoney = fineData.FineAmount
        const userId = store.state.loginInitializeData.userId
        const partBId = transferToCode(body.partBCompanyId, 'PartBCompanyId')
        const partAId = transferToCode(body.partACompanyId, 'OwedCompanyId')
        let promiseArr = []
        let soHeadCopy = getBodyInstance('DocumentCenter')
        let soBodyCopy = getBodyInstance('SoList')
        let soBodyArray = []
        let onTheWayCopy = getBodyInstance('OnTheWayList')
        const beforeAttrName = transferToName(fineData.SalesAttributeNoBefore, 'SalesAttributeId')
        const afterAttrName = transferToName(fineData.SalesAttributeNoAfter, 'SalesAttributeId')
        getDocNo('SO').then(res => {
          soHeadCopy.DocumentId = res
          soHeadCopy.PartACompanyId = partAId
          soHeadCopy.PartBCompanyId = partBId
          soHeadCopy.UserId = userId
          soHeadCopy.SourceDocumentId = relatedSoCode
          soHeadCopy.Qty = 1
          soHeadCopy.SystemStatusId = 104
          soHeadCopy.RootSourceDocumentId = relatedSoCode

          soBodyCopy.DocumentId = res
          soBodyCopy.Sn = 1
          soBodyCopy.PartACompanyId = partAId
          soBodyCopy.PartBCompanyId = partBId
          soBodyCopy.CommodityId = '229999999999999998'
          soBodyCopy.CommodityName = '退货扣款'
          soBodyCopy.VirtualGoods = true
          soBodyCopy.OrderQty = 1
          soBodyCopy.Unit = '次'
          soBodyCopy.UnitPrice = fineMoney
          soBodyCopy.Amount = fineMoney
          soBodyCopy.Remark = `退货扣款，金额为${fineMoney}`
          soBodyCopy.ShipmentQty = 1
          soBodyCopy.ShipmentStatus = 103
          soBodyCopy.SourceDocumentId = relatedSoCode
          soBodyCopy.RootSourceDocumentId = relatedSoCode
          soBodyArray.push(soBodyCopy)
          let soAction = openSoList(soBodyArray, soHeadCopy, res, true)
          promiseArr.push(soAction)

          onTheWayCopy.DocumentId = body.documentId
          onTheWayCopy.PartACompanyId = partAId
          onTheWayCopy.PartBCompanyId = partBId
          onTheWayCopy.StaffId = body.staffId
          onTheWayCopy.CommodityName = '退货扣款'
          onTheWayCopy.CommodityId = '229999999999999998'
          onTheWayCopy.Remark = `${body.commodityName}回收属性从${beforeAttrName}变成${afterAttrName},扣款金额为${fineMoney}元，备注:${fineData.AppendRemark}`
          onTheWayCopy.CommodityNameSale = '退货扣款'
          onTheWayCopy.CommodityIdSale = '229999999999999998'
          onTheWayCopy.OrderQty = 1
          onTheWayCopy.UnitPrice = fineMoney
          onTheWayCopy.ResponsedQty = 1
          onTheWayCopy.RefusedQty = 0
          onTheWayCopy.DocumentTypeSale = '扣款单'
          onTheWayCopy.DocumentIdSale = res
          onTheWayCopy.SnSale = 1
          onTheWayCopy.VirtualGoods = true
          onTheWayCopy.DistributedOrNot = true
          onTheWayCopy.SupplierToBeSolve = false
          onTheWayCopy.CustomerToBeSolve = true
          onTheWayCopy.StartType = 107
          onTheWayCopy.DocumentDateSale = new Date()
          let onTheWayAction = openOnTheWayList(onTheWayCopy)
          promiseArr.push(onTheWayAction)
          Promise.all(promiseArr).then(resArr => {
            tableBody[fineData.index].unitPrice = newInstockPrice
            instockOne(fineData.index, 'fine')
            closeRecyclePop()
          })
        })
      })
    }

    const fineOnly = () => {
      if (!fineData.SalesAttributeNoAfter) {
        ElMessage('请勾选扣款原因')
        return;
      }
      if (fineData.RecycleOrNot) {
        ElMessage('该物品需要回收，无法只扣款')
        return;
      }
      if (fineData.FineAmount == 0) {
        let b = confirm('扣款金额为0，是否继续？')
        if (!b) {
          return;
        }
      }
      const body = tableBody[fineData.index]
      
      // const newInstockPrice = fineData.SalesAttributeArr.find(x => x.salesAttributeId == fineData.SalesAttributeNoAfter).recyclePrice
      getRelatedDocNo(body.documentId + body.sn).then(res => {
        const relatedSoCode = res == '' ? body.documentId : res
        const fineMoney = fineData.FineAmount
        const userId = store.state.loginInitializeData.userId
        const partBId = transferToCode(body.partBCompanyId, 'PartBCompanyId')
        const partAId = transferToCode(body.partACompanyId, 'OwedCompanyId')
        let promiseArr = []
        let soHeadCopy = getBodyInstance('DocumentCenter')
        let soBodyCopy = getBodyInstance('SoList')
        let soBodyArray = []
        let onTheWayCopy = getBodyInstance('OnTheWayList')
        const beforeAttrName = transferToName(fineData.SalesAttributeNoBefore, 'SalesAttributeId')
        const afterAttrName = transferToName(fineData.SalesAttributeNoAfter, 'SalesAttributeId')
        getDocNo('SO').then(res => {
          soHeadCopy.DocumentId = res
          soHeadCopy.PartACompanyId = partAId
          soHeadCopy.PartBCompanyId = partBId
          soHeadCopy.UserId = userId
          soHeadCopy.SourceDocumentId = relatedSoCode
          soHeadCopy.Qty = 1
          soHeadCopy.SystemStatusId = 104
          soHeadCopy.RootSourceDocumentId = relatedSoCode

          soBodyCopy.DocumentId = res
          soBodyCopy.Sn = 1
          soBodyCopy.PartACompanyId = partAId
          soBodyCopy.PartBCompanyId = partBId
          soBodyCopy.CommodityId = '229999999999999998'
          soBodyCopy.CommodityName = '退货扣款'
          soBodyCopy.VirtualGoods = true
          soBodyCopy.OrderQty = 1
          soBodyCopy.Unit = '次'
          soBodyCopy.UnitPrice = fineMoney
          soBodyCopy.Amount = fineMoney
          soBodyCopy.Remark = `退货扣款，金额为${fineMoney}`
          soBodyCopy.ShipmentQty = 1
          soBodyCopy.ShipmentStatus = 103
          soBodyCopy.SourceDocumentId = relatedSoCode
          soBodyCopy.RootSourceDocumentId = relatedSoCode
          soBodyArray.push(soBodyCopy)
          let soAction = openSoList(soBodyArray, soHeadCopy, res, true)
          promiseArr.push(soAction)

          onTheWayCopy.DocumentId = body.documentId
          onTheWayCopy.PartACompanyId = partAId
          onTheWayCopy.PartBCompanyId = partBId
          onTheWayCopy.StaffId = body.staffId
          onTheWayCopy.CommodityName = '退货扣款'
          onTheWayCopy.CommodityId = '229999999999999998'
          onTheWayCopy.Remark = `${body.commodityName}回收属性从${beforeAttrName}变成${afterAttrName},扣款金额为${fineMoney}元，备注:${fineData.AppendRemark}`
          onTheWayCopy.CommodityNameSale = '退货扣款'
          onTheWayCopy.CommodityIdSale = '229999999999999998'
          onTheWayCopy.OrderQty = 1
          onTheWayCopy.UnitPrice = fineMoney
          onTheWayCopy.ResponsedQty = 1
          onTheWayCopy.RefusedQty = 0
          onTheWayCopy.DocumentTypeSale = '扣款单'
          onTheWayCopy.DocumentIdSale = res
          onTheWayCopy.SnSale = 1
          onTheWayCopy.VirtualGoods = true
          onTheWayCopy.DistributedOrNot = true
          onTheWayCopy.SupplierToBeSolve = false
          onTheWayCopy.CustomerToBeSolve = true
          onTheWayCopy.StartType = 107
          onTheWayCopy.DocumentDateSale = new Date()
          let onTheWayAction = openOnTheWayList(onTheWayCopy)
          promiseArr.push(onTheWayAction)
          Promise.all(promiseArr).then(resArr => {
            const newAttrNo = fineData.SalesAttributeNoAfter
            const newIsQty = 0
            const newFineMoney = fineMoney
            const SolvedUserid = userId
            deleteRecycleOnTheWay(tableBody[fineData.index].recyleOnTheWayCode, newAttrNo, newIsQty, newFineMoney, SolvedUserid,0).then(res => {
              closeRecyclePop()
              searchList()
              emit('refreshMsg')
            })
          })
        })
      })
    }


    // const fineOnlyOld = () => {
    //   if (fineData.RecycleOrNot) {
    //     ElMessage('该物品需要回收，无法只扣款')
    //     return;
    //   }
    //   if (fineData.FineAmount == 0) {
    //     let b = confirm('扣款金额为0，是否继续？')
    //     if (!b) {
    //       return;
    //     }
    //   }
    //   const body = tableBody[fineData.index]
    //   getRelatedDocNo(body.documentId + body.sn).then(res => {
    //     const relatedSoCode = res
    //     const fineMoney = fineData.FineAmount
    //     const userId = store.state.loginInitializeData.userId
    //     const partBId = transferToCode(body.partBCompanyId, 'PartBCompanyId')
    //     let promiseArr = []
    //     getDocNo('GD').then(res => {
    //       const gdHeadCode = res;
    //       const gdBody = [{
    //         GdCode: '',
    //         DocumentId: gdHeadCode,
    //         Sn: 1,
    //         PartACompanyId: body.partACompanyId,
    //         PartBCompanyId: partBId,
    //         DocumentDateTime: '',
    //         OrderQty: 1,
    //         OutStockQty: 1,
    //         InStockQty: 1,
    //         UnitPrice: fineMoney,
    //         Remark: '回收扣款',
    //         SourceDocumentId: relatedSoCode,
    //         RootSourceDocumentId: relatedSoCode,
    //         ModifiedTimes: '',
    //       }]
    //       const gdHead = {
    //         DocumentId: gdHeadCode,
    //         DocumentTypeName: '',
    //         DocumentDate: '',
    //         DocumentDateTime: '',
    //         Online: '',
    //         PartACompanyId: body.partACompanyId,
    //         PartBCompanyId: partBId,
    //         UserId: userId,
    //         StaffId: '',
    //         SourceDocumentId: '',
    //         Amount: fineMoney,
    //         Qty: 1,
    //         OutStockQty: 1,
    //         InStockQty: 1,
    //         OutWareHouseId: '',
    //         InWareHouseId: '',
    //         SystemStatusId: '',
    //         CapitalAccountId: '',
    //         Remark: '回收扣款',
    //         RootSourceDocumentId: relatedSoCode,
    //       }
    //       let acOne = openGdList(gdBody, gdHead, gdHeadCode)
    //       promiseArr.push(acOne)
    //       getDocNo('ED').then(res => {
    //         const edHeadCode = res
    //         const edBody = [{
    //           EdCode: '',
    //           DocumentId: edHeadCode,
    //           Sn: 1,
    //           PartACompanyId: partBId,
    //           PartBCompanyId: body.partACompanyId,
    //           DocumentDateTime: '',
    //           OrderQty: 1,
    //           UnitPrice: fineMoney,
    //           Remark: '回收扣款',
    //           SourceDocumentId: '',
    //           RootSourceDocumentId: body.documentId,
    //           ModifiedTimes: '',
    //         }]
    //         const edHead = {
    //           DocumentId: edHeadCode,
    //           DocumentTypeName: '',
    //           DocumentDate: '',
    //           DocumentDateTime: '',
    //           Online: '',
    //           PartACompanyId: partBId,
    //           PartBCompanyId: body.partACompanyId,
    //           UserId: '',
    //           StaffId: '',
    //           SourceDocumentId: '',
    //           Amount: fineMoney,
    //           Qty: 1,
    //           OutWareHouseId: '',
    //           InWareHouseId: '',
    //           SystemStatusId: '',
    //           CapitalAccountId: '',
    //           Remark: '回收扣款',
    //           RootSourceDocumentId: body.documentId,
    //         }
    //         let actionTwo = openEdList(edBody, edHead, edHeadCode)
    //         promiseArr.push(actionTwo)
    //         Promise.all(promiseArr).then(resArr => {
    //           deleteRecycleOnTheWay(body.recyleOnTheWayCode).then(res => {
    //             closeRecyclePop()
    //             searchList()
    //             emit('refreshMsg')
    //           })
    //         })
    //       })
    //     })
    //   }).catch(err => {

    //     ElMessage(err.message)
    //   })
    // }

    const goHistory = () => {
      emit('goRecycleOnTheWayHistory')
    }




    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          searchList()
        }
      }
    )
    //#endregion

    return {
      PartBCompanyIdFilterCopy, setActiveId, activeInput,
      trHeight, SnWidth, operationWidth, tableHead, searchList, ChangeSort, tablewidth, tableBody,
      reSearch, searchElement, tbodyHeight, SearchContent, selectAllWidth, openFinePop,
      selectAll, selectedItems, defaultInWarehouse, inWarehouseFilter, instockOne, inStockMany,
      recycleRef, fineData, closeRecyclePop, fineReasonChange, checkFineAmount, fineOnly, fineIs,
      SalesAttributeNoFilterCopy, setDefaultOutWarehouse, goHistory

    }
  },
  components: {
    PopModel
  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;
@lableMarginLeft: 0.625rem;
@lableHeight: 2rem;
@inputHeight: 1.5rem;
@mainColor: #4285f4;

.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.searchinputLabel {
  margin-left: @lableMarginLeft;
  font-size: 0.8rem;
  height: @lableHeight;
  line-height: @lableHeight;
}
.searchinput {
  margin-top: calc((@lableHeight - @inputHeight) / 2);
  margin-bottom: calc((@lableHeight - @inputHeight) / 2);
  font-size: 0.9rem;
  border: none;
  outline: none;
  height: @inputHeight;
  line-height: @inputHeight;
}
.ActiveBox {
  border: 1px solid #ffa004 !important;
}

.searchInputBox {
  height: @lableHeight;
  border: 1px solid #c9c9c9;
  margin-left: @lableMarginLeft;
  border-radius: 0.31rem;
}

.searchItemContainer {
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
  display: flex;
}

.searchSetting {
  margin-left: calc(@lableMarginLeft*3);
  height: @lableHeight;
  display: flex;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}
.searchSetting:hover {
  cursor: pointer;
  color: @mainColor;
}
.settingIcon {
  font-family: "icomoon";
  font-size: 1.1rem;
  line-height: @lableHeight;
}

.settingText {
  margin-left: 0.3rem;
  font-size: 0.8rem;
  line-height: @lableHeight;
}
.dateInputContainer {
  display: flex;
}
.BodyTitle {
  width: 98%;
  margin-top: 0.5rem;
  margin-left: 1%;
}
.BodyTitleText {
  font-size: 1.2rem;
  font-weight: 200;
}
.long {
  width: 20rem;
}

.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 60%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.thSN {
  font-family: "icomoon";
  color: #fff;
  font-size: 16px;
}
.headtd {
  cursor: pointer;
}
.ShowUpArrow {
  position: relative;
}
.ShowUpArrow::after {
  content: "\ea32";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
  color: #fff;
}
.ShowDownArrow {
  position: relative;
}
.ShowDownArrow::after {
  content: "\ea36";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.tdSN,
.tableselect,
.tdoper,
.footsum,
.bodytr {
  text-align: center;
}
.bodytr {
  height: 2rem;
}

.tablefoot {
  margin-top: -1px;
}

.moreFunctionBox {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.selectAllCheckBox {
  cursor: pointer;
}

.firstButton {
  margin-left: 1rem;
}
.tableInput {
  border: none;
  outline: none;
  height: 1.875rem;
  background-color: transparent;
  text-align: center;
}
.tableInput:focus {
  border: 1px solid #ffa004;
}
.inputBox {
  display: flex;
}
.popLable {
  margin-top: 0.5rem;
  line-height: 2.5rem;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 0.5rem;
}
.popInput {
  margin-top: 0.5rem;
  width: 28rem;
}
.buttonBox {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  justify-content: flex-end;
}
.mRight {
  margin-top: 0.1rem;
}
.lastButton {
  margin-right: 0.2rem;
}
</style>