<template>
  <div class="totalContainer">
    <div class="firstContainer" v-show="comLength > 1">
      <div class="AccountChooseBox">
        <label class="bigLable">选择调整对象:</label>
        <el-select v-model="companyChooseObj.companyChoose" class="m-2" placeholder="Select" @change="getCommodityAlertList()">
          <el-option v-for="item in companyFilter" :key="item.beforeTransfer + 'CommoAlert'" :label="item.afterTransfer" :value="item.beforeTransfer">
          </el-option>
        </el-select>
        <label class="bigLable2">选取标准:</label>
        <el-select v-model="companyChooseObj.companyCopyChoose" class="m-2" placeholder="Select">
          <el-option v-for="item in companyFilter" :key="item.beforeTransfer + 'CopyCommoAlert'" :label="item.afterTransfer" :value="item.beforeTransfer">
          </el-option>
        </el-select>
        <el-button class="firstButton" type="primary" plain @click="copyAndCover()">复制并覆盖</el-button>
      </div>
    </div>
    <div class="searchLayer">
      <div class="block2">
        <div class="searchCommBox">
          <label class="searchLabel">增加备货商品:</label>
          <el-input class="searchInput" v-model="inputValue" @input="insertSearch" placeholder="请输入搜索内容" />
          <div class="CommodityContainer" v-show="searchResult.length != 0">
            <div class="searchContentTr" v-for="(searchitem,searchIndex) in searchResult" :key="searchitem.commodityId + 'CommodityAlertManage'" @mousedown.prevent="addToTable(searchIndex)"><input class="comminputDown" v-model="searchitem.commodityName" type="text" :style="[{height:'1.625rem'}]"><input type="text" class="searchIndication" v-model="searchitem.show"></div>
            <div class="searchContentTrLast" :style="[{height:'1.625rem'},{lineHeight:'1.625rem'}]" @mousedown.prevent="clearSearchResult()">关闭搜索</div>
          </div>
        </div>
        <div class="searchCommBox">
          <label class="searchLabel">商品:</label>
          <el-input class="searchInput" v-model="searchCommo" placeholder="请输入搜索内容" />
        </div>
        <el-button class="searchButton" type="primary" @click="getCommodityAlertList()">搜索</el-button>
        <div class="profitBox">
          <span class="totalWords">备货数量:</span><span class="totalProfitNumber">{{totalQty}}个</span>
        </div>
        <div class="profitBox">
          <span class="totalWords">预估备货金额:</span><span class="totalProfitNumber">{{totalAmount}}元</span>
        </div>
      </div>
    </div>
    <div class="BigTable">
      <div class="TableContentArea">
        <table class="tablehead">
          <tr class="headtr" :style="{ height : trHeight }">
            <th class="thSN">
              <div :style="{ width: SnWidth }"></div>
            </th>
            <th class="thselectall">
              <div :style="{ width: selectAllWidth }"><input class="selectAllCheckBox" type="checkbox" v-model="selectAll" /></div>
            </th>
            <th>
              <div class="thoper" :style="{ width: operationWidth }">操作</div>
            </th>
            <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'head'">
              <div :style="{ width: value1.showLen  + 'rem' }">{{ value1.showName }}</div>
            </th>
            <th class="headtd">
              <div :style="{ width: '6rem' }">下限数量</div>
            </th>
            <th class="headtd">
              <div :style="{ width: '6rem' }">上限数量</div>
            </th>
            <th class="headtd">
              <div :style="{ width: '24rem' }">通知对象</div>
            </th>
          </tr>
        </table>
        <div class="tablebodyarea" :style="{ width: tablewidth  + 'rem',height:tbodyHeight}">
          <table class="tablebody">
            <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'tdCommoAlert' + index2">
              <td>
                <div class="tdSN" :style="{ width: SnWidth }">{{+index2 + 1}}</div>
              </td>
              <td class="tableselect">
                <div :style="{ width: selectAllWidth }"><input type="checkbox" :value="value2['commodityId']" v-model="selectedItems" /></div>
              </td>
              <td>
                <div class="tdoper" :style="{ width: operationWidth }">
                  <span class="spandele" @click="deleteOne(index2)">删除</span>
                </div>
              </td>
              <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
                <div :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
              </td>
              <td>
                <div><input :style="{ width: '6rem' }" type="number" step="1" v-model="tableBody[index2].lowQty" @blur="checkDownQty(index2)"></div>
              </td>
              <td>
                <div><input :style="{ width: '6rem' }" type="number" step="1" v-model="tableBody[index2].highQty"></div>
              </td>
              <td>
                <div><select :style="{ width: '24rem' }" v-model="tableBody[index2].companyId">
                    <option v-for="value5 in companyFilter2" :key="value5.beforeTransfer + 'notifySupplier'" :value="value5.beforeTransfer">{{value5.afterTransfer}}</option>
                  </select></div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="moreFunctionBox">
      <el-button type="danger" @click="deleteAll()">删除已勾选</el-button>
      <el-button type="success" @click="recoverToBefore()">恢复至修改前</el-button>
      <el-button type="primary" @click="openSaveRef()">保存</el-button>
    </div>

    <PopModel ref="saveWarningRef" :title="'更新商品预警'">
      <div class="inputBox" v-show="comLength > 1">
        <label class="popLable">同步更新公司:</label>
        <el-select v-model="togetherCompanys" multiple placeholder="Select" style="width: 240px">
          <el-option v-for="item in companyFilter3" :key="item.beforeTransfer + 'Together'" :label="item.afterTransfer" :value="item.beforeTransfer" />
        </el-select>
      </div>
      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight" @click="closePop()">取消</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="saveAllChangesNew()">保存</el-button>
      </div>
    </PopModel>

  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, provide, reactive, ref, watch } from 'vue-demi'
import { useStore } from 'vuex'
import { copyArray, copyObj, insertSearchOrigin, isRealNum } from '@/hooks/tools';
import { changeToViewCode, getAllWarningList, transferToName, getCommodityBodyById } from '@/hooks/OpenOrder'
import _ from "lodash";
import PopModel from '@/components/Common/PopModel.vue'
import requestData from '@/hooks/requestData';
import { ElMessage } from 'element-plus'
export default {
  components: {
    PopModel
  },
  setup() {

    const store = useStore();

    let companyChooseObj = reactive({
      companyChoose: '',
      companyCopyChoose: '',
    })

    let totalQty = computed(() => {
      return tableBody.reduce((x, y) => x + +y.lowQty, 0)
    })

    let totalAmount = computed(() => {
      return tableBody.reduce((x, y) => x + (+y.defaultPurchasePrice * +y.lowQty), 0).toFixed(2)
    })

    let inputValue = ref('')

    let searchCommo = ref('')

    let tableBody = reactive([]);

    provide('tableTitle', '')


    let tableBodyCopy = []

    let togetherCompanys = ref([])

    const saveWarningRef = ref(null)

    const checkDownQty = (index) => {
      if (tableBody[index].highQty <= tableBody[index].lowQty) {
        tableBody[index].highQty = tableBody[index].lowQty + 1
      }
    }

    const companyFilter = copyArray(store.state.loginInitializeData.filterList.ManagedCompanys)

    const companyFilter2 = copyArray(store.state.loginInitializeData.filterList.PartBCompanyId)

    let length2 = companyFilter2.length

    for (let i = length2 - 1; i >= 0; i--) {
      if (+companyFilter2[i].beforeTransfer >= 20000000) {
        companyFilter2.splice(i, 1)
      }
    }

    let companyFilter3 = []

    const openSaveRef = () => {
      if (companyChooseObj.companyChoose == '') {
        ElMessage('未选中调整对象')
        return;
      }
      companyFilter3.splice(0)
      for (let item of companyFilter) {
        if (item.beforeTransfer == companyChooseObj.companyChoose) {
          continue
        }
        else {
          companyFilter3.push(item)
        }
      }
      saveWarningRef.value.openPopComm()
    }

    const closePop = () => {
      companyFilter3.splice(0)
      togetherCompanys.value.splice(0)
      saveWarningRef.value.closePopComm()
    }

    const recoverToBefore = () => {
      tableBody.splice(0)
      for (let item of tableBodyCopy) {
        tableBody.push(item)
      }
    }

    const deleteOne = (index) => {
      tableBody.splice(index, 1)
    }

    for (let item of companyFilter) {
      if (companyFilter2.find(x => x.beforeTransfer == item.beforeTransfer)) {
        continue;
      }
      else {
        companyFilter2.push(item)
      }
    }

    const comLength = ref(companyFilter?.length)

    if (companyFilter.length == 1) {
      companyChooseObj.companyChoose = companyFilter[0].beforeTransfer
    }


    const getCommodityAlertList = () => {
      if (companyChooseObj.companyChoose == '') {
        return;
      }
      else {
        getAllWarningList(companyChooseObj.companyChoose, searchCommo.value).then(res => {
          if (res) {
            tableBody.splice(0)
            tableBodyCopy.splice(0)
            tableBodyCopy = copyArray(res)
            for (let item of res) {
              tableBody.push(item)
            }
          }
        })
      }
    }

    getCommodityAlertList()

    let searchResult = reactive([]);


    const insertSearch = _.debounce((e) => {
      let b = {}
      b.target = {
        value: e
      }

      insertSearchOrigin(b, searchResult)
    }, 500)

    const addToTable = (index) => {
      if (companyChooseObj.companyChoose == '') {
        ElMessage('未选中调整对象')
        clearSearchResult()
        return;
      }
      else {
        const body = searchResult[index]
        if (tableBody.find(x => x.commodityId == body.commodityId)) {
          ElMessage('该物料已经存在')
          clearSearchResult()
          return
        }
        else {
          getCommodityBodyById(body.commodityId).then(res => {
            let companyId = companyChooseObj.companyChoose;
            if (tableBody.length > 0) {
              companyId = tableBody[0].companyId
            }

            let b = {
              commodityId: body.commodityId,
              commodityName: body.commodityName,
              companyId: companyId,
              highQty: 2,
              lowQty: 1,
              partACompanyId: companyChooseObj.companyChoose,
              defaultPurchasePrice: res?.defaultPurchasePrice
            }
            tableBody.unshift(b)
            clearSearchResult()
          })
        }
      }

    }

    const clearSearchResult = () => {
      searchResult.splice(0)
    }

    let selectAllWidth = ref('2.5rem')
    let trHeight = ref('1.875rem')
    let SnWidth = ref('3.75rem')
    let operationWidth = ref('10rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')

    //f计算表格内容总宽度

    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) + parseFloat(operationWidth.value) + parseFloat(selectAllWidth.value) + 37.25 + tableHead.length * 1 + 0.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });

    let tableHead = reactive([
      { fieldsNameForVali: 'commodityName', showLen: 40, showName: '商品名称' },
    ]);

    let selectAll = ref(false);

    let selectedItems = ref([]);

    const deleteAll = () => {
      if (!selectedItems.value.length) {
        ElMessage('未勾选项')
        return
      }
      else {
        let lengt3 = tableBody.length
        for (let i = lengt3 -1;i>=0;i--) {
          if (selectedItems.value.indexOf(tableBody[i].commodityId) > -1) {
            tableBody.splice(i,1)
          }
        }
      }
    }

    const PageName = 'CommodityAlertManage'

    const code = changeToViewCode(PageName)

    watch(selectAll, (newValue, oldValue) => {
      if (code == store.state.loginInitializeData.ShowWindow[0]) {
        if (newValue == true) {
          selectedItems.value.splice(0);
          for (let item of tableBody) {
            selectedItems.value.push(item['commodityId']);
          }
        } else {
          selectedItems.value.splice(0);
        }
      }
    });

    const deleteAction = (body) => {
      return new Promise(function (resolve, reject) {
        const config = {
          method: "post",
          url: "/CommodityWarningList/UploadDelete",
          data: body
        };
        requestData.GetData(config).then(res => {
          resolve(res)
        })
      })
    }


    const addUpdateAction = (body) => {
      return new Promise(function (resolve, reject) {
        const config = {
          method: "post",
          url: "/CommodityWarningList/Upload",
          data: body
        };
        requestData.GetData(config).then(res => {
          resolve(res)
        })
      })
    }

    const saveAllChanges = (tableBody, tableBodyCopy, checkTogether) => {
      let deleteBody = []
      let addUpdateBody = []
      if (!tableBody) {
        tableBody = []
      }
      if (!tableBodyCopy) {
        tableBodyCopy = []
      }
      if (tableBody.length > 0) {
        for (let item of tableBody) {
          const b = tableBodyCopy.find(x => x.commodityId == item.commodityId)
          if (b) {
            if (item.lowQty != b.lowQty || item.highQty != b.highQty || item.companyId != b.companyId) {
              addUpdateBody.push(item)
            }
          }
          else {
            addUpdateBody.push(item)
          }
        }
      }

      if (tableBodyCopy.length > 0) {
        for (let item of tableBodyCopy) {
          const b = tableBody.find(x => x.commodityId == item.commodityId)
          if (b) {
            continue
          }
          else {
            deleteBody.push(item)
          }
        }
      }

      if (addUpdateBody.length == 0 && deleteBody.length == 0) {
        ElMessage('无更新')
        return;
      }

      let addUpdateBodyCopy = []

      let deleteBodyCopy = []

      if (addUpdateBody.length > 0) {
        addUpdateBodyCopy = copyArray(addUpdateBody)
      }

      if (deleteBody.length > 0) {
        deleteBodyCopy = copyArray(deleteBody)
      }

      if (checkTogether) {
        if (togetherCompanys.value.length > 0) {
          for (let togeCompany of togetherCompanys.value) {
            if (togeCompany == companyChooseObj.companyChoose) {
              continue
            }
            else {
              if (addUpdateBody.length > 0) {
                for (let item of addUpdateBodyCopy) {
                  let c = copyObj(item)
                  c.partACompanyId = togeCompany
                  addUpdateBody.push(c)
                }
              }

              if (deleteBody.length > 0) {
                for (let item of deleteBodyCopy) {
                  let d = copyObj(item)
                  d.partACompanyId = togeCompany
                  deleteBody.push(d)
                }
              }
            }
          }
        }
      }

      let job = []
      if (deleteBody.length > 0) {
        const job1 = deleteAction(deleteBody)
        job.push(job1)
      }
      if (addUpdateBody.length > 0) {
        const job2 = addUpdateAction(addUpdateBody)
        job.push(job2)
      }
      Promise.all(job).then(resArr => {
        ElMessage('更新成功')
        closePop()
        getCommodityAlertList()
      })

    }

    const saveAllChangesNew = () => {
      saveAllChanges(tableBody, tableBodyCopy, true)
    }

    const copyAndCover = () => {
      if (companyChooseObj.companyChoose == '') {
        ElMessage('未选择公司')
        return
      }
      if (companyChooseObj.companyCopyChoose == '') {
        ElMessage('未选择复制标准')
        return
      }
      getAllWarningList(companyChooseObj.companyCopyChoose, "").then(res1 => {
        if (!res1) {
          let b = confirm('复制标准没有设置下限，继续将清空复制对象')
          if (!b) {
            return
          }
          else {
            getAllWarningList(companyChooseObj.companyChoose, "").then(res2 => {
              saveAllChanges(res1, res2, false)
            })
          }
        }
        else {
          for (let item2 of res1) {
            item2.partACompanyId = companyChooseObj.companyChoose
          }
          getAllWarningList(companyChooseObj.companyChoose, "").then(res2 => {
            saveAllChanges(res1, res2, false)
          })
        }
      })
    }

    //#endregion

    return {
      companyFilter,
      companyChooseObj,
      comLength,
      inputValue,
      searchResult,
      insertSearch,
      addToTable,
      clearSearchResult,
      selectAllWidth,
      trHeight,
      SnWidth,
      operationWidth,
      tbodyHeight,
      selectAll,
      selectedItems,
      tablewidth,
      tableBody,
      tableHead,
      getCommodityAlertList,
      companyFilter2,
      totalQty,
      totalAmount,
      searchCommo,
      recoverToBefore,
      deleteOne,
      togetherCompanys,
      companyFilter3,
      saveWarningRef,
      openSaveRef,
      checkDownQty,
      closePop,
      saveAllChangesNew,
      copyAndCover,
      deleteAll

    }
  }
}
</script>

<style scoped lang="less">
.totalContainer {
  height: 100%;
  width: 98%;
  margin-left: 1%;
}
.firstContainer {
  height: 10%;
  width: 98%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.AccountChooseBox {
  margin-left: 1rem;
}
.bigLable {
  font-size: 1rem;
  margin-right: 0.2rem;
}
.bigLable2 {
  margin-left: 4rem;
  margin-right: 0.5rem;
}
.firstButton {
  margin-left: 1rem;
}
.searchLayer {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.block2 {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #cccccc;
  padding-left: 2rem;
}
.profitBox {
  line-height: 4.5rem;
  margin-left: 2rem;
}
.totalWords {
  font-size: 1rem;
  font-weight: 400;
  padding-right: 0.3rem;
}
.totalProfitNumber {
  font-size: 0.9rem;
  font-weight: 700;
}
.searchLabel {
  line-height: 4.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  font-size: 1rem;
}
.searchInput {
  width: 20rem;
  margin-top: auto;
  margin-bottom: auto;
}
.CommodityContainer {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  border: 1px solid #cccccc;
  top: 4.2rem;
  left: 7.5rem;
  width: 40rem;
}
.searchContentTr {
  display: flex;
  margin-top: 0.2rem;
  width: 100%;
}
.searchContentTr:hover {
  background-color: #f5f5f6;
}
.searchContentTr input {
  border: none;
  outline: none;
  text-align: left;
  background-color: transparent;
  border-radius: 5px;
}

.searchContentTr input:hover {
  cursor: pointer;
}
.searchContentTrLast:hover {
  background-color: #fa2c2c;
  color: #fff;
  cursor: pointer;
}
.searchContentTrLast {
  padding-left: 0.3rem;
}
.searchIndication {
  text-align: center !important;
  color: red;
}
.comminputDown {
  width: 80%;
}
.bodytr input,
.bodytr select {
  border: none;
  outline: none;
  height: 1.875rem;
  background-color: transparent;
  text-align: center;
}
.bodytr input:focus,
.bodytr select:focus {
  border: 1px solid #ffa004;
}
.searchIndication {
  width: 20%;
}
.searchCommBox {
  display: flex;
  position: relative;
  height: 4.5rem;
}
.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 60%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.thSN {
  font-family: "icomoon";
  color: #fff;
  font-size: 16px;
}
.selectAllCheckBox {
  cursor: pointer;
}
.headtd {
  cursor: pointer;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.tdSN,
.tableselect,
.tdoper,
.footsum,
.bodytr {
  text-align: center;
}
.bodytr {
  height: 2rem;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.searchButton {
  margin-left: 2rem;
  margin-top: auto;
  margin-bottom: auto;
}
.inputBox {
  display: flex;
}
.popLable {
  margin-top: 0.5rem;
  line-height: 2.5rem;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 0.5rem;
}
.buttonBox {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  justify-content: flex-end;
}
.mRight {
  margin-top: 0.1rem;
}
.lastButton {
  margin-right: 0.2rem;
}
</style>