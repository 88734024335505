<template>
  <div class="tableLoaderContainer">
    <BodyTitle></BodyTitle>
    <SearchContent @deliverSearchItems="deliverSearchItems"></SearchContent>
    <TableShow ref="tableShowRef" @perfectTableHead="perfectTableHead" @perfectTableBody="perfectTableBody"></TableShow>
  </div>
</template>
<script>
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import SearchContent from '@/components/Content/MainBodyContent/SearchContent.vue'
import { inject, onUnmounted, provide, ref } from 'vue-demi'
import TableShow from '@/components/Content/MainBodyContent/TableShow.vue';
import { useStore } from "vuex";
export default {
  setup(props,{emit}) {
    //#region 数据定义区
    const store = useStore();
    const listForValidation = store.state.loginInitializeData.listForValidations
    const filterList = store.state.loginInitializeData.filterList
    const positiomList = store.state.loginInitializeData.positiomList
    let searchItemList = inject('searchItemList')
    let fileCheckData = [];
    const tableShowRef = ref(null)
    //#endregion

    //#region 公式定义区
    //将表格相关数据连接进数据主线
    const fillSearchItemList = () => {
      const list = listForValidation.filter(x => x.tableNameForVali == searchItemList.tableName && x.validateMethod == 'add')
      for (let item of list) {
        searchItemList[item.fieldsNameForVali] = item
        for (let fkey in filterList) {
          if (item.fieldsNameForVali == fkey) {
            searchItemList[item.fieldsNameForVali].Filter = filterList[fkey]
          }
        }
        if (item.addTrans == true) {
          let objFileCheck = {}
          objFileCheck.cname = item.showName
          objFileCheck.ename = item.fieldsNameForVali
          fileCheckData.push(objFileCheck)
        }
        for (let index in fileCheckData) {
          fileCheckData[index].po = positiomList[index]
        }
      }
    }

    const deliverSearchItems = (searchItems) => {
      for (let key in searchItems) {
        for (let key2 in searchItemList) {
          if (key == key2) {
            searchItemList[key2].defaultValue = searchItems[key]
          }
        }
      }
      tableShowRef.value.fillSearchContent()
      tableShowRef.value.turnPageToOne()
      tableShowRef.value.searchList()
    }
    //#endregion

    const perfectTableBody = (body) => {
      emit('perfectTableBody',body)
    }

    const perfectTableHead = (head) => {
      emit('perfectTableHead',head)
    }

    //#region 数据提供区
    provide('searchItemList', searchItemList)

    provide('fileCheckData', fileCheckData)
    //#endregion

    //#region 挂载前加载
    fillSearchItemList();
    //#endregion

    //#region 失去活跃处理

    //#endregion

    //#region 活跃时处理

    //#endregion

    //#region 离开时处理
    onUnmounted(() => {

    })
    //#endregion

    return {
      searchItemList,
      deliverSearchItems,
      tableShowRef,
      perfectTableBody,
      perfectTableHead
    }
  },
  components: {
    BodyTitle,
    SearchContent,
    TableShow

  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
</style>