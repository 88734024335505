<template>
  <div class="BigTable">
    <div class="TableContentArea">
      <table class="tablehead">
        <tr class="headtr" :style="{ height : trHeight }">
          <th class="thSN">
            <div :style="{ width: SnWidth }"></div>
          </th>
          <th>
            <div class="thoper" :style="{ width: operationWidth }">操作</div>
          </th>
          <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'head'" :class="{  ShowUpArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == 1, ShowDownArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == -1,}" @click.stop="ChangeSort(value1.fieldsNameForVali)">
            <div :style="{ width: value1.showLen  + 'rem' }">{{ value1.showName }}</div>
          </th>
        </tr>
      </table>
      <div class="tablebodyarea" :style="{ width: tablewidth  + 'rem',height:tbodyHeight}">
        <table class="tablebody">
          <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'td' + index2" @mousedown="saveActiveItem(index2)" :class="{'bodytrActive':activeItem.index == index2}">
            <td>
              <div class="tdSN" :style="{ width: SnWidth }">{{ index2 + 1 + searchElement.LinesPerPage * (searchElement.Page - 1) }}</div>
            </td>
            <td>
              <div class="tdoper" :style="{ width: operationWidth }">
                <span v-if="accountsAction" class="spanedit" @click="goMiInput(value2)" v-show="$store.state.loginInitializeData.authList.buttonVisibleManages.find(x=>x.buttonId == 'AccountsListGoMoneyIn')?.visibleOrNot">去收款</span>
                <span v-if="accountsAction" class="spandele" @click="goMoInput(value2)" v-show="$store.state.loginInitializeData.authList.buttonVisibleManages.find(x=>x.buttonId == 'AccountsListGoMoneyOut')?.visibleOrNot">去付款</span>
                <span v-if="documentCenterAction" class="spanedit" @click="goOpenOrderFromDocCenter(value2)">复制</span>
                <span v-if="documentCenterAction" class="spandele" @click="goEditOrderFromDocCenter(value2)">操作</span>
              </div>
            </td>
            <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
              <div class="bodyFont" :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
            </td>
          </tr>
        </table>
      </div>
      <table class="tablefoot">
        <tr class="foottr" :style="{ height : trHeight }">
          <td>
            <div class="footsum" :style="{ width: SnWidth }">合计</div>
          </td>
          <td>
            <div :style="{ width: operationWidth }"></div>
          </td>
          <td v-for="value4 in tableHead" :key="value4.fieldsNameForVali + 'foot'">
            <div :style="{ width: value4.showLen  + 'rem' }"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="MainBodyTablePage" :style="{ width: '100%'}">
      <!--页数模块 -->
      <div class="PageCountContainer">
        <div class="PagerSummaryText">({{ totalLines }}条记录,总共{{ totalPage }}页)</div>
        <span class="PagerEveryPageText">每页/条</span>
        <div class="EditBlock">
          <input class="LinesPerPageChooese" type="text" v-model="searchElement.LinesPerPage" />
          <div class="LinesPerPageChooseButton"></div>
        </div>
        <div class="GoFirstPageButton" @click="FirstPage()"></div>
        <div class="GoLastPageButton" @click="DownPage()"></div>
        <span class="pagetextforDi">第</span>
        <div class="EditBlock">
          <input class="PageEdit" type="text" v-model="searchElement.Page" />
          <div class="PageChooseButton"></div>
        </div>
        <span class="pagetextforYe">页</span>
        <div class="GoNextPageButton" @click="UpPage()"></div>
        <div class="GoEndPageButton" @click="EndPage()"></div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { computed, inject, onMounted, onUnmounted, provide, reactive, ref, watch } from 'vue-demi'
import { useStore } from "vuex";
import { ChangeFirToLo, ChangeFirToUp, dataFilter, changeArrContentToDate } from '@/hooks/tools';
import requestData from '@/hooks/requestData';
import TableShowHook from '@/hooks/TableShowHook'
import { ElMessage } from 'element-plus'
export default {
  components: {

  },
  setup(props, { emit }) {
    //#region 数据定义区
    let { store, tableHead, tableBody, SearchContent, tableBodyElement,
      totalLines, totalPage, searchElement, tableCode,
      AddEditRef, UploadRef,
      fillSearchContent, refreshTableHeadandregArr, clearTableHeadandregArr,
      clearVueXPage, initializeVueXPageDate, searchList,
      turnPageToOne, UpPage, EndPage, DownPage, FirstPage, ChangeSort } = TableShowHook(emit)


    let SnWidth = ref('3.75rem')
    let selectAllWidth = ref('2.5rem')
    let operationWidth = ref('8rem')
    let trHeight = ref('1.875rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')

    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) + parseFloat(selectAllWidth.value) + parseFloat(operationWidth.value) + 1.25 + tableHead.length * 1 + 0.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });
    //#region 数据定义区

    let activeItem = inject('activeItem')

    let accountsAction = computed(() => {
      return tableBodyElement.tableName == 'AccountsList'
    })

    let documentCenterAction = computed(() => {
      return tableBodyElement.tableName == 'DocumentCenter'
    })

    let InventoryChangeListAction = computed(() => {
      return tableBodyElement.tableName == 'InventoryChangeList'
    })

    const goMiInput = (value) => {
      emit('goMiInputFromAcc', value)
    }

    const goMoInput = (value) => {
      emit('goMoInputFromAcc', value)
    }

    const goOpenOrderFromDocCenter = (value) => {
      if (tableBodyElement.tableName == 'DocumentCenter') {
        emit('goOpenOrderFromDocCenter', value)
      }
    }

    const goEditOrderFromDocCenter = (value) => {
      if (tableBodyElement.tableName == 'DocumentCenter') {
        emit('goEditOrderFromDocCenter', value)
      }
    }


    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (tableCode == store.state.loginInitializeData.ShowWindow[0] && newValue != 100058 && newValue != 100060) {
          initializeVueXPageDate()
          fillSearchContent()
          searchList()
        }
      }
    )
    //#endregion

    //#region 离开时处理
    onUnmounted(() => {

    })
    //#endregion
    const saveActiveItem = (index) => {
      emit('saveActiveItem', tableBody[index], index)
    }

    watch(
      () => [searchElement.Page, searchElement.SortFieldsName, searchElement.SortDirection],
      (newValue, oldValue) => {

        if (tableCode == store.state.loginInitializeData.ShowWindow[0]) {
          if (totalPage.value > 0) {
            if (parseInt(newValue[0]) > totalPage.value) {
              // ElMessage('不能超过最大页数');
              store.commit('modifysearchEle', { keyName: 'Page', valueName: totalPage.value })
              return;
            }
          }
          if (parseInt(newValue[0]) < 1) {
            // ElMessage('页数不能小于1');
            store.commit('modifysearchEle', { keyName: 'Page', valueName: 1 })
            return;
          }
        }
      }
    );
    //w用于监控页数,确保客户不乱填
    watch(
      () => searchElement.LinesPerPage,
      (newValue, oldValue) => {
        if (tableCode == store.state.loginInitializeData.ShowWindow[0]) {
          if (newValue > 100) {
            store.commit('modifysearchEle', { keyName: 'LinesPerPage', valueName: 100 })
            ElMessage("每页数量不能超过100");
          }
          if (newValue < 1) {
            store.commit('modifysearchEle', { keyName: 'LinesPerPage', valueName: 1 })
            ElMessage("每页数量不能低于1");
          }
        }
      }
    );

    //#endregion


    onMounted(() => {
      refreshTableHeadandregArr()
      initializeVueXPageDate()
      fillSearchContent()
    })
    onUnmounted(() => {
      clearTableHeadandregArr()
      clearVueXPage()
    })
    return {
      SearchContent, goMiInput, goMoInput,
      fillSearchContent,
      tableHead,
      tableBody,
      tablewidth,
      searchList,
      totalLines,
      totalPage,
      ChangeSort,
      UpPage,
      EndPage,
      FirstPage,
      DownPage,
      turnPageToOne,
      searchElement,
      //尺寸部分
      SnWidth,
      operationWidth,
      trHeight,
      tbodyHeight,
      AddEditRef,
      UploadRef,
      saveActiveItem,
      activeItem,
      InventoryChangeListAction,
      accountsAction,
      documentCenterAction,
      goOpenOrderFromDocCenter,
      goEditOrderFromDocCenter,
    }
  }
}
</script>

<style scoped lang="less">
@pageHeight: 1.875rem;
.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 60%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.thSN {
  font-family: "icomoon";
  color: #fff;
  font-size: 1rem;
}
.headtd {
  cursor: pointer;
}
.ShowUpArrow {
  position: relative;
}
.ShowUpArrow::after {
  content: "\ea32";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
  color: #fff;
}
.ShowDownArrow {
  position: relative;
}
.ShowDownArrow::after {
  content: "\ea36";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.tdSN,
.tableselect,
.tdoper,
.footsum,
.bodytr {
  text-align: center;
}
.bodytr {
  height: 2rem;
}

.tablefoot {
  margin-top: -1px;
}

.bodytrActive {
  background-color: #fefae3 !important;
}
.bodyFont {
  font-size: 0.9rem;
}
</style>