import store from '@/store';
import requestData from "@/hooks/requestData.js";
import {
  copyObj
} from '@/hooks/tools'
import {
  loginInitializeCheck,
  refreshLoginDate
} from "@/hooks/loginAffair";


const getBrandListCache = (value) => {
  const config = {
    method: "get",
    url: "/ClassToBrandList/GetBrandList",
    params: {
      type: value
    }
  };
  return requestData.GetCache(config)
}

const getMachineList = (machineClass, brandId, series) => {
  const config = {
    method: "get",
    url: "/MachineTypeList/GetMachineListBySeries",
    params: {
      machineClass: machineClass,
      brandId: brandId,
      series: series
    }
  };
  return requestData.GetCache(config)
}

const getSeriesList = (machineClass, brandId) => {
  const config = {
    method: "get",
    url: "/MachineTypeList/GetSeriesList",
    params: {
      machineClass: machineClass,
      brandId: brandId,
    }
  };
  return requestData.GetCache(config)
}

const getBreakList = (machineClass, machineTypeId) => {
  const config = {
    method: "get",
    url: "/QuotationList/GetBreakList",
    params: {
      machineClass: machineClass,
      machineTypeId: machineTypeId,
    }
  };
  return requestData.GetCache(config)
}

const getProfitList = (dateL, dateH) => {
  const config = {
    method: "get",
    url: "/ProfitViewPerDay/GetListByTiemSpan",
    params: {
      dateL: dateL,
      dateH: dateH,
    }
  };
  return requestData.GetData(config)
}

const getProfitListByStaff = (dateL, dateH,companyId = '') => {
  const config = {
    method: "get",
    url: "/ProfitViewPerDayByStaff/GetListByTiemSpan",
    params: {
      dateL: dateL,
      dateH: dateH,
      companyId:companyId
    }
  };
  return requestData.GetData(config)
}

const getAllCompanyProfitListByStaff = (dateL, dateH,companyId = '') => {
  const config = {
    method: "get",
    url: "/ProfitViewPerDayByStaff/GetAllCompanyListByTiemSpan",
    params: {
      dateL: dateL,
      dateH: dateH,
      companyId:companyId
    }
  };
  return requestData.GetCache(config)
}


const getSalesReportByDate = (companyId, dateL, dateH) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetSalesAmountByDate",
    params: {
      companyId: companyId,
      dateL: dateL,
      dateH: dateH
    }
  };
  return requestData.GetData(config)
}

const getSalesReportByDateCache = (companyId, dateL, dateH) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetSalesAmountByDate",
    params: {
      companyId: companyId,
      dateL: dateL,
      dateH: dateH
    }
  };
  return requestData.GetCache(config)
}




const getSalesReportByMonth = (companyId, monthL, monthH) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetSalesAmountByMonth",
    params: {
      companyId: companyId,
      monthL: monthL,
      monthH: monthH
    }
  };
  return requestData.GetData(config)
}

const getSalesReportByYear = (companyId, yearL, yearH) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetSalesAmountByYear",
    params: {
      companyId: companyId,
      yearL: yearL,
      yearH: yearH
    }
  };
  return requestData.GetData(config)
}

const getProfitReportByDate = (companyId, dateL, dateH) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetProfitAmountByDate",
    params: {
      companyId: companyId,
      dateL: dateL,
      dateH: dateH
    }
  };
  return requestData.GetData(config)
}

const getProfitReportByDateCache = (companyId, dateL, dateH) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetProfitAmountByDate",
    params: {
      companyId: companyId,
      dateL: dateL,
      dateH: dateH
    }
  };
  return requestData.GetCache(config)
}

const getProfitReportByMonth = (companyId, monthL, monthH) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetProfitAmountByMonth",
    params: {
      companyId: companyId,
      monthL: monthL,
      monthH: monthH
    }
  };
  return requestData.GetData(config)
}

const getProfitReportByYear = (companyId, yearL, yearH) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetProfitAmountByYear",
    params: {
      companyId: companyId,
      yearL: yearL,
      yearH: yearH
    }
  };
  return requestData.GetData(config)
}


const getSalesTypeReportByDate = (companyId, dateL, dateH) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetSalesTypeAmountByDate",
    params: {
      companyId: companyId,
      dateL: dateL,
      dateH: dateH
    }
  };
  return requestData.GetData(config)
}

const getSalesTypeReportByMonth = (companyId, monthL, monthH) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetSalesTypeAmountByMonth",
    params: {
      companyId: companyId,
      monthL: monthL,
      monthH: monthH
    }
  };
  return requestData.GetData(config)
}

const getSalesTypeReportByYear = (companyId, yearL, yearH) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetSalesTypeAmountByYear",
    params: {
      companyId: companyId,
      yearL: yearL,
      yearH: yearH
    }
  };
  return requestData.GetData(config)
}


const getProfitTypeReportByDate = (companyId, dateL, dateH) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetProfitTypeAmountByDate",
    params: {
      companyId: companyId,
      dateL: dateL,
      dateH: dateH
    }
  };
  return requestData.GetData(config)
}

const getProfitTypeReportByMonth = (companyId, monthL, monthH) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetProfitTypeAmountByMonth",
    params: {
      companyId: companyId,
      monthL: monthL,
      monthH: monthH
    }
  };
  return requestData.GetData(config)
}

const getProfitTypeReportByYear = (companyId, yearL, yearH) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetProfitTypeAmountByYear",
    params: {
      companyId: companyId,
      yearL: yearL,
      yearH: yearH
    }
  };
  return requestData.GetData(config)
}

const getStaffProfitPerDay = (staffId, dateL, dateH) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetStaffProfitByDate",
    params: {
      staffId: staffId,
      dateL: dateL,
      dateH: dateH
    }
  };
  return requestData.GetData(config)
}

const getStaffProfitPerDayCache = (staffId, dateL, dateH) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetStaffProfitByDate",
    params: {
      staffId: staffId,
      dateL: dateL,
      dateH: dateH
    }
  };
  return requestData.GetCache(config)
}

const getWarehouseStorage = (companyId) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetWarehouseStorage",
    params: {
      companyId: companyId,
    }
  };
  return requestData.GetData(config)
}

const getCompanyStorage = (companyId) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetCompanyStorage",
    params: {
      companyId: companyId,
    }
  };
  return requestData.GetData(config)
}

const getCustomerResourceReportByDate = (companyId, dateL, dateH) => {
  const config = {
    method: "get",
    url: "/ReportShow/GetCustomerResourceReportByDate",
    params: {
      companyId: companyId,
      dateL: dateL,
      dateH: dateH
    }
  };
  return requestData.GetData(config)
}

const synchroniseInventoryReal = () => {
  const config = {
    method: "get",
    url: "/InventoryBalanceList/SynchroniseInventoryReal",
  };
  requestData.GetCache(config).then(res => {

  })
}

const getBenchMarkPrice = (benchId) => {
  const config = {
    method: "get",
    url: "/PriceBenchMark/GetBenchMarkPrice",
    params:{
      benchId:benchId
    }
  };
  return requestData.GetCache(config)
}

const getPurchaseHistoryList = (commoId) => {
  const config = {
    method: "get",
    url: "/PriceRecordList/GetPurchaseHistoryList",
    params:{
      commoId:commoId
    }
  };
  return requestData.GetCache(config)
}

const getUnderSubmitPrice = (benchId) => {
  const config = {
    method: "get",
    url: "/BenchMarkChangeSubmit/GetUnderSubmitPrice",
    params:{
      benchId:benchId
    }
  };
  return requestData.GetCache(config)
}

const uploadUnSearchedContent = (commName) => {
  const config = {
    method: "get",
    url: "/CommodityMangeTable/SaveUnSearch",
    params:{
      commName:commName
    }
  };
  requestData.GetCache(config).then(res=>{
    
  })
}

const getMiDetailBySpan = (partAId,startDate,endDate) => {
  const config = {
    method: "get",
    url: "/CapitalAccountBlanceChangeList/GetMiDetailBySpan",
    params:{
      partAId:partAId,
      startDate:startDate,
      endDate:endDate
    }
  };
  return requestData.GetData(config)
}

const getMiCheckRecordBySpan = (partAId,startDate,endDate) => {
  const config = {
    method: "get",
    url: "/MiMoneyCheckRecord/GetMiCheckRecordBySpan",
    params:{
      partAId:partAId,
      startDate:startDate,
      endDate:endDate
    }
  };
  return requestData.GetData(config)
}

const getAllRootMaterialList = () => {
  const config = {
    method: "get",
    url: "/RootMaterialList/GetAllRootMaterialList",
  };
  return requestData.GetData(config)
}

const getAllPipeStatusReal = () => {
  const config = {
    method: "get",
    url: "/AccountingSubjectsManage/GetAllPipeStatus",
  };
  return requestData.GetData(config)
}



export {
  getBrandListCache,getBenchMarkPrice,
  getMachineList,getPurchaseHistoryList,
  getSeriesList,getUnderSubmitPrice,getMiCheckRecordBySpan,
  getBreakList,getMiDetailBySpan,
  getProfitList,uploadUnSearchedContent,
  synchroniseInventoryReal,getAllRootMaterialList,
  getProfitListByStaff,
  getSalesReportByDate,
  getSalesReportByMonth,
  getSalesReportByYear,
  getProfitReportByDate,
  getProfitReportByMonth,
  getProfitReportByYear,
  getSalesTypeReportByDate,
  getSalesTypeReportByMonth,
  getSalesTypeReportByYear,
  getProfitTypeReportByDate,
  getProfitTypeReportByMonth,
  getProfitTypeReportByYear,
  getStaffProfitPerDay,
  getWarehouseStorage,
  getCompanyStorage,getAllPipeStatusReal,
  getCustomerResourceReportByDate,
  getSalesReportByDateCache,
  getProfitReportByDateCache,
  getStaffProfitPerDayCache,
  getAllCompanyProfitListByStaff
}