<template>
  <div class="totalProfitBox">
    <div class="titleBox">经 营 报 表</div>
    <div class="contentBox">
      <div class="typeChooseBox">
        <div class="typeChooseBoxHead">报表选择</div>
        <div class="typeChooseBoxBody">
          <el-scrollbar height="46rem">
            <div v-for="item in chartArr" :key="item.chartName + 'reportName'" class="scrollbar-demo-item" :class="{activeButton:activeKey == item.index}" @click="goPointReport(item.index)">
              <span class="typeChooseIcon icon" :style="{color:item.color}">{{ item.icon }}</span><span class="typeChooseContent">{{ item.chartName }}</span>
            </div>
          </el-scrollbar>
        </div>
        <div class="typeChooseBoxFoot"></div>
      </div>
      <div class="reportTotalBox">
        <div class="reportBar" :style="{'left':leftValue}">
          <div class="block">
            <div class="reportFactorChooseBox" v-if="activeKey == 0">
              <span>公司:</span>
              <el-select v-model="companyChoosed" @change="changeMiCompany()" class="m-2" placeholder="Select" size="small">
                <el-option key="'all' + 'UnCheckAmount'" label="所有" value="" />
                <el-option v-for="item in managedCompanysFilter" :key="item.beforeTransfer + 'UnCheckAmount'" :label="item.afterTransfer" :value="item.beforeTransfer" />
              </el-select>
              <span>待确认营业款:</span>
              <span class="importantShow">{{MiUnCheckAmount}} 元</span>
            </div>
            <div class="reportBox" id="report1" ref="report1" v-if="activeKey == 0">
              <!-- <div class="realReport" id="report1" ref="report1"></div>
              <div class="realReport" id="report2" ref="report2"></div>
              <div class="realReport"></div>
              <div class="realReport"></div> -->
            </div>
            <div class="reportFuncBox" v-if="activeKey == 0" >
              <span>确认金额:</span>
              <el-input-number class="MiInputBox" v-model="collectAmount" :precision="2" :step="0.1" :min="0" :max="100000000" />
              <el-button type="primary" v-show="$store.state.loginInitializeData.authList.buttonVisibleManages.find(x=>x.buttonId == 'DbCheckIncome')?.visibleOrNot" @click="confirmMiAmount('input')" round>确认营业款</el-button>
              <el-button type="success" v-show="$store.state.loginInitializeData.authList.buttonVisibleManages.find(x=>x.buttonId == 'DbCheckIncome')?.visibleOrNot" @click="confirmMiAmount('all')" round>一键确认</el-button>
              <el-button type="success" round @click="goTurnoverDetail()">查看明细</el-button>
            </div>
          </div>
          <div class="block">
            <div class="reportFactorChooseBox" v-if="activeKey == 1">
              <span>公司:</span>
              <el-select v-model="companyChoosed2" class="m-2" placeholder="Select" size="small" @change="updateSalesReport('')">
                <el-option key="'all' + 'UnCheckAmount2'" label="所有" value="" />
                <el-option v-for="item in groupCompanysFilter" :key="item.beforeTransfer + 'UnCheckAmount2'" :label="item.afterTransfer" :value="item.beforeTransfer" />
              </el-select>
              <span>选择范围:</span>
              <el-date-picker @change="updateSalesReport()" v-model="timeSpanForSales" type="daterange" unlink-panels range-separator="To" start-placeholder="开始日期" end-placeholder="结束日期" :shortcuts="shortcuts" />
              <span class="wordButton" :class="{RedColor:typeChoose2 == 'day'}" @click="changeType('day')">按日</span>
              <span class="wordButton" :class="{RedColor:typeChoose2 == 'month'}" @click="changeType('month')">按月</span>
              <span class="wordButton" :class="{RedColor:typeChoose2 == 'year'}" @click="changeType('year')">按年</span>
            </div>
            <div class="reportBox" id="report2" ref="report2" v-if="activeKey == 1">

            </div>
            <div class="reportFuncBox" v-if="activeKey == 1">
              <span>对比公司:</span>
              <el-select v-model="companyContrast2" class="m-2" placeholder="Select" size="small">
                <el-option v-for="item in groupCompanysFilter" :key="item.beforeTransfer + 'CompanyContrast2'" :label="item.afterTransfer" :value="item.beforeTransfer" />
              </el-select>
              <span></span>
              <el-button type="primary" round @click="addContrast2()">添加对比</el-button>
            </div>
          </div>
          <div class="block">
            <div class="reportFactorChooseBox" v-if="activeKey == 2">
              <span>公司:</span>
              <el-select v-model="companyChoosed3" class="m-2" placeholder="Select" size="small" @change="updateProfitReport3('')">
                <el-option key="'all' + 'UnCheckAmount3'" label="所有" value="" />
                <el-option v-for="item in groupCompanysFilter" :key="item.beforeTransfer + 'UnCheckAmount3'" :label="item.afterTransfer" :value="item.beforeTransfer" />
              </el-select>
              <span>选择范围:</span>
              <el-date-picker @change="updateProfitReport3()" v-model="timeSpanForSales3" type="daterange" unlink-panels range-separator="To" start-placeholder="开始日期" end-placeholder="结束日期" :shortcuts="shortcuts3" />
              <span class="wordButton" :class="{RedColor:typeChoose3 == 'day'}" @click="changeType3('day')">按日</span>
              <span class="wordButton" :class="{RedColor:typeChoose3 == 'month'}" @click="changeType3('month')">按月</span>
              <span class="wordButton" :class="{RedColor:typeChoose3 == 'year'}" @click="changeType3('year')">按年</span>
            </div>
            <div class="reportBox" id="report3" ref="report3" v-if="activeKey == 2">

            </div>
            <div class="reportFuncBox" v-if="activeKey == 2">
              <span>对比公司:</span>
              <el-select v-model="companyContrast3" class="m-2" placeholder="Select" size="small">
                <el-option v-for="item in groupCompanysFilter" :key="item.beforeTransfer + 'CompanyContrast3'" :label="item.afterTransfer" :value="item.beforeTransfer" />
              </el-select>
              <span></span>
              <el-button type="primary" round @click="addContrast3()">添加对比</el-button>
            </div>
          </div>

          <div class="block">
            <div class="reportFactorChooseBox" v-if="activeKey == 3">
              <span>公司:</span>
              <el-select v-model="companyChoosed4" class="m-2" placeholder="Select" size="small" @change="updateSalesTypeReport4('')">
                <el-option key="'all' + 'UnCheckAmount4'" label="所有" value="" />
                <el-option v-for="item in groupCompanysFilter" :key="item.beforeTransfer + 'UnCheckAmount4'" :label="item.afterTransfer" :value="item.beforeTransfer" />
              </el-select>
              <span>选择范围:</span>
              <el-date-picker @change="updateSalesTypeReport4()" v-model="timeSpanForSales4" type="daterange" unlink-panels range-separator="To" start-placeholder="开始日期" end-placeholder="结束日期" :shortcuts="shortcuts4" />
              <span class="wordButton" :class="{RedColor:typeChoose4 == 'day'}" @click="changeType4('day')">按日</span>
              <span class="wordButton" :class="{RedColor:typeChoose4 == 'month'}" @click="changeType4('month')">按月</span>
              <span class="wordButton" :class="{RedColor:typeChoose4 == 'year'}" @click="changeType4('year')">按年</span>
            </div>
            <div class="reportBox" id="report4" ref="report4" v-if="activeKey == 3">

            </div>
            <div class="reportFuncBox" v-if="activeKey == 3">

            </div>
          </div>

          <div class="block">
            <div class="reportFactorChooseBox" v-if="activeKey == 4">
              <span>公司:</span>
              <el-select v-model="companyChoosed5" class="m-2" placeholder="Select" size="small" @change="updateSalesTypeReport5('')">
                <el-option key="'all' + 'UnCheckAmount5'" label="所有" value="" />
                <el-option v-for="item in groupCompanysFilter" :key="item.beforeTransfer + 'UnCheckAmount5'" :label="item.afterTransfer" :value="item.beforeTransfer" />
              </el-select>
              <span>选择范围:</span>
              <el-date-picker @change="updateSalesTypeReport5()" v-model="timeSpanForSales5" type="daterange" unlink-panels range-separator="To" start-placeholder="开始日期" end-placeholder="结束日期" :shortcuts="shortcuts5" />
              <span class="wordButton" :class="{RedColor:typeChoose5 == 'day'}" @click="changeType5('day')">按日</span>
              <span class="wordButton" :class="{RedColor:typeChoose5 == 'month'}" @click="changeType5('month')">按月</span>
              <span class="wordButton" :class="{RedColor:typeChoose5 == 'year'}" @click="changeType5('year')">按年</span>
            </div>
            <div class="reportBox" id="report5" ref="report5" v-if="activeKey == 4">
            </div>
            <div class="reportFuncBox" v-if="activeKey == 4">
            </div>
          </div>

          <div class="block">
            <div class="reportFactorChooseBox" v-if="activeKey == 5">
              <span>人员:</span>
              <el-select v-model="staffChoosed6" class="m-2" placeholder="Select" size="small" @change="updateStaffProfitType6()">
                <el-option key="'all' + 'UnCheckAmount6'" label="所有" value="" />
                <el-option v-for="item in groupStaffs" :key="item.beforeTransfer + 'UnCheckAmount6'" :label="item.afterTransfer" :value="item.beforeTransfer" />
              </el-select>
              <span>选择范围:</span>
              <el-date-picker @change="updateStaffProfitType6()" v-model="timeSpanForSales6" type="daterange" unlink-panels range-separator="To" start-placeholder="开始日期" end-placeholder="结束日期" :shortcuts="shortcuts6" />

            </div>
            <div class="reportBox" id="report6" ref="report6" v-if="activeKey == 5">
            </div>
            <div class="reportFuncBox" v-if="activeKey == 5">
            </div>
          </div>

          <div class="block">
            <div class="reportFactorChooseBox" v-if="activeKey == 6">
              <span>公司:</span>
              <el-select v-model="companyChoosed7" class="m-2" placeholder="Select" size="small" @change="updateWarehouseStorage7()">
                <el-option key="'all' + 'UnCheckAmount7'" label="所有" value="" />
                <el-option v-for="item in managedCompanysFilter" :key="item.beforeTransfer + 'UnCheckAmount7'" :label="item.afterTransfer" :value="item.beforeTransfer" />
              </el-select>
            </div>
            <div class="reportBox" id="report7" ref="report7" v-if="activeKey == 6">
            </div>
            <div class="reportFuncBox" v-if="activeKey == 6">
            </div>
          </div>

          <div class="block">
            <div class="reportFactorChooseBox" v-if="activeKey == 7">
              <span>公司:</span>
              <el-select v-model="companyChoosed8" class="m-2" placeholder="Select" size="small" @change="updateWarehouseStorage8()">
                <el-option key="'all' + 'UnCheckAmount8'" label="所有" value="" />
                <el-option v-for="item in managedCompanysFilter" :key="item.beforeTransfer + 'UnCheckAmount8'" :label="item.afterTransfer" :value="item.beforeTransfer" />
              </el-select>
            </div>
            <div class="reportBox" id="report8" ref="report8" v-if="activeKey == 7">
            </div>
            <div class="reportFuncBox" v-if="activeKey == 7">
            </div>
          </div>

          <div class="block">
            <div class="reportFactorChooseBox" v-if="activeKey == 8">

              <span>公司:</span>
              <el-select v-model="companyChoosed9" class="m-2" placeholder="Select" size="small" @change="updateCustomerResourceReport9('')">
                <el-option key="'all' + 'UnCheckAmount9'" label="所有" value="" />
                <el-option v-for="item in groupCompanysFilter" :key="item.beforeTransfer + 'UnCheckAmount9'" :label="item.afterTransfer" :value="item.beforeTransfer" />
              </el-select>
              <span>选择范围:</span>
              <el-date-picker @change="updateCustomerResourceReport9()" v-model="timeSpanForSales9" type="daterange" unlink-panels range-separator="To" start-placeholder="开始日期" end-placeholder="结束日期" :shortcuts="shortcuts9" />

            </div>
            <div class="reportBox" v-if="activeKey == 8">
              <div class="leftReport" id="report9" ref="report9"></div>
              <div class="rightTopReport" id="report10" ref="report10"></div>
              <div class="rightBottomReport" id="report11" ref="report11"></div>

            </div>
            <div class="reportFuncBox" v-if="activeKey == 8">
            </div>
          </div>

        </div>
      </div>
      <div class="otherFunctionBox"></div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, onUnmounted, reactive, ref, toRefs, watch } from 'vue-demi'

import { getMiListUnCheck, transferToName, collectMiMoney, transferToCode, changeToViewCode } from '@/hooks/OpenOrder'

import { useStore } from "vuex";
import { addDate, addMonth, changeDayToInt, copyArray, GetNumberOfDays, isRealNum, randomHex } from '@/hooks/tools';
import {
  getSalesReportByDate, getSalesReportByMonth, getSalesReportByYear,
  getProfitReportByDate, getProfitReportByMonth, getProfitReportByYear,
  getSalesTypeReportByDate, getSalesTypeReportByMonth, getSalesTypeReportByYear,
  getProfitTypeReportByDate, getProfitTypeReportByMonth, getProfitTypeReportByYear,
  getStaffProfitPerDay, getWarehouseStorage, getCompanyStorage, getCustomerResourceReportByDate
} from '@/hooks/getCache';
import { ElMessage } from 'element-plus'

export default {
  setup() {
    const store = useStore();

    const storage = window.localStorage;

    const userId = store.state.loginInitializeData.userId

    const partACompanId = store.state.loginInitializeData.companyId

    let leftValue = ref('0%')

    let actionArr = []


    const chartCounts = 9

    let chartArr = reactive([
      { chartName: '营业款报表', index: 0, clickTimes: 0 },
      { chartName: '销售报表', index: 1, clickTimes: 0 },
      { chartName: '利润报表', index: 2, clickTimes: 0 },
      { chartName: '销售类型分析', index: 3, clickTimes: 0 },
      { chartName: '利润类型分析', index: 4, clickTimes: 0 },
      { chartName: '人员利润排名', index: 5, clickTimes: 0 },
      { chartName: '库存金额报表', index: 6, clickTimes: 0 },
      { chartName: '公司库存报表', index: 7, clickTimes: 0 },
      { chartName: '客源分析', index: 8, clickTimes: 0 },
    ])

    let length11 = chartArr.length

    for (let i = length11 - 1; i >= 0; i--) {
      if (!store.state.loginInitializeData.authList.buttonVisibleManages.find(x => x.buttonName == chartArr[i].chartName)?.visibleOrNot) {
        chartArr.splice(i, 1)
      }
    }


    const iconList = ['', '', '', '', '', '', '', '', '']

    const colorList = ['#555555', '#ed1c24', '#a8b9cc', '#ff0000', '#f16521', '#f24e1e', '#4285f4', '#cc2929', '#555555']

    const reSortChartArr = () => {
      for (let item of chartArr) {
        item.clickTimes = storage.getItem(userId + '-Report-' + item.index) || 0
      }
      chartArr = chartArr.sort(function (a, b) { return b.clickTimes - a.clickTimes })
      for (let i = 0; i < chartArr.length; i++) {
        chartArr[i].icon = iconList[i]
        chartArr[i].color = colorList[i]
      }
    }

    reSortChartArr()

    const goPointReport = (index) => {
      if (storage.getItem(userId + '-Report-' + index)) {
        let num = +storage.getItem(userId + '-Report-' + index)
        num++
        storage.setItem(userId + '-Report-' + index, num)
      }
      else {
        storage.setItem(userId + '-Report-' + index, 1)
      }

      leftValue.value = (0 - (+index * 100)) + '%'

    }

    if (chartArr.length > 0) {
      goPointReport(chartArr[0].index)
    }
    else {
      leftValue.value = '200%'
    }

    const goRight = () => {
      if (0 - (parseInt(leftValue.value) / 100) == chartCounts - 1) {
        leftValue.value = '0%'
      }
      else {
        leftValue.value = (parseInt(leftValue.value) - 100) + '%'
      }
    }
    const goLeft = () => {
      if (parseInt(leftValue.value) == 0) {
        leftValue.value = (0 - ((chartCounts - 1) * 100)) + '%'
      }
      else {
        leftValue.value = (parseInt(leftValue.value) + 100) + '%'
      }
    }

    const { proxy } = getCurrentInstance()


    const managedCompanysFilter = copyArray(store.state.loginInitializeData.filterList.ManagedCompanys)

    const groupCompanysFilter = copyArray(store.state.loginInitializeData.filterList.GroupCompanys)

    const companyName = store.state.loginInitializeData.companyNameAbbre

    if(groupCompanysFilter.length > 0){
      if(companyName.indexOf('小懒-') > -1){
        let length2 = groupCompanysFilter.length
        for(let i = length2 -1;i >= 0;i--){
          if(groupCompanysFilter[i].afterTransfer == '青屠科技' || groupCompanysFilter[i].afterTransfer == '南京小懒数码'){
            groupCompanysFilter.splice(i,1)
          }
        }
      }
    }

    const groupStaffs = copyArray(store.state.loginInitializeData.filterList.GroupStaffs)

    const managedWarehouses = copyArray(store.state.loginInitializeData.filterList.ManagedWarehouses)

    for (let item of groupStaffs) {
      item.companyId = store.state.loginInitializeData.groupStaffs.find(x => x.staffId == item.beforeTransfer)?.companyId
      item.afterTransfer = item.afterTransfer + '-' + groupCompanysFilter.find(x => x.beforeTransfer == item.companyId)?.afterTransfer
    }

    //#region 营业款报表

    let report1 = reactive({
      option: {
        title: {
          text: '待确认营业款'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: []
        },
        series: [
          {
            name: '金额',
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'inside',
              color: 'red'
            },
          }
        ]
      }
    })

    let chart1;

    let companyChoosed = ref('')


    const initialCompany = (companyRef) => {
      if(managedCompanysFilter.length <= 1){
        companyRef.value = +partACompanId
      }
    }

    initialCompany(companyChoosed)


    const code = changeToViewCode('ReportShow')

    const code2 = changeToViewCode('TurnoverAnalysis')


    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code != store.state.loginInitializeData.ShowWindow[0] && code2 != store.state.loginInitializeData.ShowWindow[0]) {
          store.commit('CloseActiveWindow', code)
        }
      }
    )

    const changeMiCompany = () => {
      getMiListUnCheckInReport()
    }

    const initChart1 = () => {

      if (chart1) {
        chart1.dispose()
      }
      chart1 = proxy.$echarts.init(document.getElementById('report1'))
      chart1.setOption(report1.option)
      window.onresize = () => {
        chart1.resize()
      }
    }

    const getMiListUnCheckInReport = (companyId = companyChoosed.value) => {
      report1.option.yAxis.data.splice(0)
      report1.option.series[0].data.splice(0)
      getMiListUnCheck(companyId).then(res => {
        if (res) {
          MiUnCheckAmount.value = +res.reduce((x, y) => x + +y.unCheckAmount, 0).toFixed(2)
          if (!companyId) {
            let temCompanyArr = []
            let temArr = []
            for (let item of res) {
              if (temCompanyArr.indexOf(item.partACompanyId) <= -1) {
                temCompanyArr.push(item.partACompanyId)
                // report1.option.xAxis.data.push(item.partACompanyId)
                const amountValue = res.filter(x => x.partACompanyId == item.partACompanyId).reduce((x, y) => x + +y.unCheckAmount, 0).toFixed(2)
                // report1.option.series.data.push(amountValue)
                let temObj = {}
                temObj.companyId = item.partACompanyId
                temObj.amount = amountValue
                temArr.push(temObj)
              }
            }
            const newArr = temArr.sort(function (a, b) { return a.amount - b.amount })
            const length = newArr.length
            let others = {
              amount: 0
            }
            for (let i = length - 1; i >= 0; i--) {
              if (i > length - 10) {
                report1.option.yAxis.data.unshift(transferToName(newArr[i].companyId, 'ManagedCompanys'))
                report1.option.series[0].data.unshift(newArr[i].amount)
              }
              else {
                others.amount += newArr[i].amount
              }
            }
            if (others.amount) {
              report1.option.yAxis.data.unshift('其他')
              report1.option.series[0].data.unshift(others.amount)
            }

            // for (let item of newArr) {
            //   report1.option.yAxis.data.unshift(transferToName(item.companyId, 'ManagedCompanys'))
            //   report1.option.series[0].data.unshift(item.amount)
            // }
          }
          else {
            const length = res.length
            let others = {
              amount: 0
            }
            for (let i = length - 1; i >= 0; i--) {
              if (i > length - 9) {
                report1.option.yAxis.data.unshift(res[i].miDate)
                report1.option.series[0].data.unshift(+res[i].unCheckAmount.toFixed(2))
              }
              else {
                others.amount += res[i].unCheckAmount
              }
            }
            if (others.amount) {
              report1.option.yAxis.data.unshift('更早之前')
              report1.option.series[0].data.unshift(others.amount)
            }
          }

          initChart1()
        } else {
          MiUnCheckAmount.value = 0
          report1.option.xAxis.data.splice(0)
          report1.option.series.data.splice(0)
        }
      })
    }

    actionArr.push(getMiListUnCheckInReport)



    let MiUnCheckAmount = ref(0)

    let collectAmount = ref(0)

    const uploadMiAmount = (value) => {
      if (!companyChoosed.value) {
        ElMessage('未选择确认对象')
        return;
      }

      if (!MiUnCheckAmount.value) {
        ElMessage('总额为0,无需确认')
        return;
      }

      if (+value <= 0) {
        ElMessage('未填写上交金额')
        return;
      }

      if (+value > +MiUnCheckAmount.value) {
        collectAmount.value = 0
        ElMessage('确认金额不能超过总金额')
        return;
      }


      collectMiMoney(value, companyChoosed.value).then(res => {
        if (res == 'pass') {
          ElMessage('确认成功')
          collectAmount.value = 0
          getMiListUnCheckInReport(companyChoosed.value)
        }
        else {
          ElMessage(res)
        }
      })

    }

    const confirmMiAmount = (tpye) => {
      let b = confirm('确认全部收款？')
      if (!b) {
        return;
      }

      if (tpye == 'input') {
        uploadMiAmount(collectAmount.value)
      }
      else {
        uploadMiAmount(MiUnCheckAmount.value)
      }
    }
    //#endregion

    const goTurnoverDetail = () => {
      let code = changeToViewCode('TurnoverAnalysis')
      store.commit('GoFunctionWindowWithCode', code)
    }

    //#region 销售报表
    let report2 = reactive({
      option: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [

        ]
      }
    })

    let chart2;

    const initChart2 = () => {

      if (chart2) {
        chart2.dispose()
      }
      chart2 = proxy.$echarts.init(document.getElementById('report2'))
      chart2.setOption(report2.option)
      window.onresize = () => {
        chart2.resize()
      }
    }

    let companyChoosed2 = ref('')

    companyChoosed2.value = +partACompanId

    let companyContrast2 = ref('')

    let typeChoose2 = ref('day')

    const changeType = (type) => {
      typeChoose2.value = type
      updateSalesReport(type)
    }

    const timeSpanForSales = ref('')

    const shortcuts = [
      {
        text: '过去一周',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: '过去一个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: '过去3个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
    ]

    const changeToLineChartDate = (arr, dateL, timeSpan, type) => {
      let companyArr = []
      for (let item of arr) {
        if (companyArr.indexOf(item.partACompanyId) <= -1) {
          const companyNameFor = transferToName(item.partACompanyId,'GroupCompanys')
          if(companyName.indexOf('小懒-') > -1 && (companyNameFor == '青屠科技' || companyNameFor == '南京小懒数码')){
            continue;
          }
          companyArr.push(item.partACompanyId)
        }
      }

      let companyNameArr = []

      let dateLCopy;

      if (type == 'day') {
        dateLCopy = new Date(dateL.toString().substring(0, 4) + '/' + dateL.toString().substring(4, 6) + '/' + dateL.toString().substring(6, 8))
      }
      else if (type == 'month') {
        dateLCopy = new Date(dateL.toString().substring(0, 4) + '/' + dateL.toString().substring(4, 6))
      }
      else {
        dateLCopy = dateL
      }

      let dateArr = []

      for (let i = 0; i < timeSpan; i++) {
        if (i == 0) {
          dateArr.push(dateL)
          continue;
        }
        if (type == 'day') {
          dateArr.push(changeDayToInt(addDate(dateLCopy, i), type))
        }
        else if (type == 'month') {
          dateArr.push(changeDayToInt(addMonth(dateLCopy, i), type))
        }
        else {
          dateArr.push(dateLCopy + i)
        }
      }

      let dataArr = []

      for (let i = 0; i < companyArr.length; i++) {
        companyNameArr[i] = transferToName(companyArr[i], 'GroupCompanys')

        // const color = randomHex()

        const b = {
          name: companyNameArr[i],
          type: 'line',
          // itemStyle:{
          //   color : color
          // }
        }
        let date1 = []
        for (let j = 0; j < timeSpan; j++) {
          if (type == 'day') {
            date1[j] = arr.find(x => x.partACompanyId == companyArr[i] && x.salesDay == dateArr[j])?.amount.toFixed(2) || 0
          }
          else if (type == 'month') {
            date1[j] = arr.find(x => x.partACompanyId == companyArr[i] && x.salesMonth == dateArr[j])?.amount.toFixed(2) || 0
          }
          else {
            date1[j] = arr.find(x => x.partACompanyId == companyArr[i] && x.salesYear == dateArr[j])?.amount.toFixed(2) || 0
          }

        }
        b.data = date1
        dataArr.push(b)
      }

      const returnArr = []

      returnArr.push(companyNameArr)
      returnArr.push(dateArr)
      returnArr.push(dataArr)
      return returnArr

    }

    const updateSalesReport = (type = 'day') => {
      if (type == 'day') {
        typeChoose2.value = 'day'
      }
      report2.option.legend.data.splice(0)
      report2.option.xAxis.data.splice(0)
      report2.option.series.splice(0)
      if (typeChoose2.value == 'day') {
        if (!timeSpanForSales.value) {
          const dateH = changeDayToInt(new Date(), typeChoose2.value)
          const dateL = changeDayToInt(addDate(new Date(), -29), typeChoose2.value)
          getSalesReportByDate(companyChoosed2.value, dateL, dateH).then(res => {
            const arrBack = changeToLineChartDate(res, dateL, 30, typeChoose2.value)
            report2.option.legend.data = arrBack[0]
            report2.option.xAxis.data = arrBack[1]
            report2.option.series = arrBack[2]
            initChart2()
          })
        }
        else {
          const days = GetNumberOfDays(timeSpanForSales.value[0], timeSpanForSales.value[1]) + 1
          const dateH = changeDayToInt(timeSpanForSales.value[1], typeChoose2.value)
          const dateL = changeDayToInt(timeSpanForSales.value[0], typeChoose2.value)

          getSalesReportByDate(companyChoosed2.value, dateL, dateH).then(res => {
            const arrBack = changeToLineChartDate(res, dateL, days, typeChoose2.value)
            report2.option.legend.data = arrBack[0]
            report2.option.xAxis.data = arrBack[1]
            report2.option.series = arrBack[2]
            
            initChart2()
          })

        }

      } else if (typeChoose2.value == 'month') {
        const monthH = changeDayToInt(new Date(), typeChoose2.value)
        const monthL = changeDayToInt(addDate(new Date(), -365), typeChoose2.value)

        getSalesReportByMonth(companyChoosed2.value, monthL, monthH).then(res => {
          const arrBack = changeToLineChartDate(res, monthL, 13, typeChoose2.value)
          report2.option.legend.data = arrBack[0]
          report2.option.xAxis.data = arrBack[1]
          report2.option.series = arrBack[2]
          initChart2()
        })
      } else {
        const yearH = changeDayToInt(new Date(), typeChoose2.value)
        const yearL = changeDayToInt(addDate(new Date(), -365 * 3), typeChoose2.value)

        getSalesReportByYear(companyChoosed2.value, yearL, yearH).then(res => {
          const arrBack = changeToLineChartDate(res, yearL, 4, typeChoose2.value)
          report2.option.legend.data = arrBack[0]
          report2.option.xAxis.data = arrBack[1]
          report2.option.series = arrBack[2]
          
          initChart2()
        })
      }
    }

    const addContrast2 = () => {
      if (!companyContrast2.value) {
        ElMessage('未选择项')
        return;
      }
      if (report2.option.legend.data.indexOf(transferToName(companyContrast2.value, 'GroupCompanys')) > -1) {
        ElMessage('对比项已经存在')
        return;
      }

      if (typeChoose2.value == 'day') {
        if (!timeSpanForSales.value) {
          const dateH = changeDayToInt(new Date(), typeChoose2.value)
          const dateL = changeDayToInt(addDate(new Date(), -29), typeChoose2.value)
          getSalesReportByDate(companyContrast2.value, dateL, dateH).then(res => {
            const arrBack = changeToLineChartDate(res, dateL, 30, typeChoose2.value)
            report2.option.legend.data.push(arrBack[0][0])
            report2.option.series.push(arrBack[2][0])
            initChart2()
          })
        }
        else {
          const days = GetNumberOfDays(timeSpanForSales.value[0], timeSpanForSales.value[1]) + 1
          const dateH = changeDayToInt(timeSpanForSales.value[1], typeChoose2.value)
          const dateL = changeDayToInt(timeSpanForSales.value[0], typeChoose2.value)

          getSalesReportByDate(companyContrast2.value, dateL, dateH).then(res => {
            const arrBack = changeToLineChartDate(res, dateL, days, typeChoose2.value)
            report2.option.legend.data.push(arrBack[0][0])
            report2.option.series.push(arrBack[2][0])
            initChart2()
          })

        }

      } else if (typeChoose2.value == 'month') {
        const monthH = changeDayToInt(new Date(), typeChoose2.value)
        const monthL = changeDayToInt(addDate(new Date(), -365), typeChoose2.value)

        getSalesReportByMonth(companyContrast2.value, monthL, monthH).then(res => {
          const arrBack = changeToLineChartDate(res, monthL, 13, typeChoose2.value)
          report2.option.legend.data.push(arrBack[0][0])
          report2.option.series.push(arrBack[2][0])
          initChart2()
        })
      } else {
        const yearH = changeDayToInt(new Date(), typeChoose2.value)
        const yearL = changeDayToInt(addDate(new Date(), -365 * 3), typeChoose2.value)

        getSalesReportByYear(companyContrast2.value, yearL, yearH).then(res => {
          const arrBack = changeToLineChartDate(res, yearL, 4, typeChoose2.value)
          report2.option.legend.data.push(arrBack[0][0])
          report2.option.series.push(arrBack[2][0])
          initChart2()
        })
      }
    }

    actionArr.push(updateSalesReport)

    //#endregion

    let report3 = reactive({
      option: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [

        ]
      }
    })

    let chart3;

    const initChart3 = () => {
      if (chart3) {
        chart3.dispose()
      }
      chart3 = proxy.$echarts.init(document.getElementById('report3'))
      chart3.setOption(report3.option)
      window.onresize = () => {
        chart3.resize()
      }
    }

    let companyChoosed3 = ref('')

    companyChoosed3.value = +partACompanId

    let companyContrast3 = ref('')

    let typeChoose3 = ref('day')

    const changeType3 = (type) => {
      typeChoose3.value = type
      updateProfitReport3(type)
    }
    let timeSpanForSales3 = ref('')

    const shortcuts3 = [
      {
        text: '过去一周',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: '过去一个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: '过去3个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
    ]
    const changeToLineChartDate3 = (arr, dateL, timeSpan, type) => {
      let companyArr = []
      for (let item of arr) {
        if (companyArr.indexOf(item.companyId) <= -1) {
          companyArr.push(item.companyId)
        }
      }

      let companyNameArr = []

      let dateLCopy;

      if (type == 'day') {
        dateLCopy = new Date(dateL.toString().substring(0, 4) + '/' + dateL.toString().substring(4, 6) + '/' + dateL.toString().substring(6, 8))
      }
      else if (type == 'month') {
        dateLCopy = new Date(dateL.toString().substring(0, 4) + '/' + dateL.toString().substring(4, 6))
      }
      else {
        dateLCopy = dateL
      }

      let dateArr = []

      for (let i = 0; i < timeSpan; i++) {
        if (i == 0) {
          dateArr.push(dateL)
          continue;
        }
        if (type == 'day') {
          dateArr.push(changeDayToInt(addDate(dateLCopy, i), type))
        }
        else if (type == 'month') {
          dateArr.push(changeDayToInt(addMonth(dateLCopy, i), type))
        }
        else {
          dateArr.push(dateLCopy + i)
        }
      }

      let dataArr = []

      for (let i = 0; i < companyArr.length; i++) {
        companyNameArr[i] = transferToName(companyArr[i], 'GroupCompanys')

        // const color = randomHex()

        const b = {
          name: companyNameArr[i],
          type: 'line',
          // itemStyle:{
          //   color : color
          // }
        }
        let date1 = []
        for (let j = 0; j < timeSpan; j++) {
          if (type == 'day') {
            date1[j] = arr.find(x => x.companyId == companyArr[i] && x.profitDate == dateArr[j])?.profitAmount.toFixed(2) || 0
          }
          else if (type == 'month') {
            date1[j] = arr.find(x => x.companyId == companyArr[i] && x.profitMonth == dateArr[j])?.profitAmount.toFixed(2) || 0
          }
          else {
            date1[j] = arr.find(x => x.companyId == companyArr[i] && x.profitYear == dateArr[j])?.profitAmount.toFixed(2) || 0
          }

        }
        b.data = date1
        dataArr.push(b)
      }

      const returnArr = []

      returnArr.push(companyNameArr)
      returnArr.push(dateArr)
      returnArr.push(dataArr)
      return returnArr

    }
    const updateProfitReport3 = (type = 'day') => {
      if (type == 'day') {
        typeChoose3.value = 'day'
      }
      report3.option.legend.data.splice(0)
      report3.option.xAxis.data.splice(0)
      report3.option.series.splice(0)
      if (typeChoose3.value == 'day') {
        if (!timeSpanForSales3.value) {
          const dateH = changeDayToInt(new Date(), typeChoose3.value)
          const dateL = changeDayToInt(addDate(new Date(), -29), typeChoose3.value)
          getProfitReportByDate(companyChoosed3.value, dateL, dateH).then(res => {
            const arrBack = changeToLineChartDate3(res, dateL, 30, typeChoose3.value)
            report3.option.legend.data = arrBack[0]
            report3.option.xAxis.data = arrBack[1]
            report3.option.series = arrBack[2]
            initChart3()
          })
        }
        else {
          const days = GetNumberOfDays(timeSpanForSales3.value[0], timeSpanForSales3.value[1]) + 1
          const dateH = changeDayToInt(timeSpanForSales3.value[1], typeChoose3.value)
          const dateL = changeDayToInt(timeSpanForSales3.value[0], typeChoose3.value)

          getProfitReportByDate(companyChoosed3.value, dateL, dateH).then(res => {
            const arrBack = changeToLineChartDate3(res, dateL, days, typeChoose3.value)
            report3.option.legend.data = arrBack[0]
            report3.option.xAxis.data = arrBack[1]
            report3.option.series = arrBack[2]
            initChart3()
          })

        }

      } else if (typeChoose3.value == 'month') {
        const monthH = changeDayToInt(new Date(), typeChoose3.value)
        const monthL = changeDayToInt(addDate(new Date(), -365), typeChoose3.value)

        getProfitReportByMonth(companyChoosed3.value, monthL, monthH).then(res => {
          const arrBack = changeToLineChartDate3(res, monthL, 13, typeChoose3.value)
          report3.option.legend.data = arrBack[0]
          report3.option.xAxis.data = arrBack[1]
          report3.option.series = arrBack[2]
          initChart3()
        })
      } else {
        const yearH = changeDayToInt(new Date(), typeChoose3.value)
        const yearL = changeDayToInt(addDate(new Date(), -365 * 3), typeChoose3.value)

        getProfitReportByYear(companyChoosed3.value, yearL, yearH).then(res => {
          const arrBack = changeToLineChartDate3(res, yearL, 4, typeChoose3.value)
          report3.option.legend.data = arrBack[0]
          report3.option.xAxis.data = arrBack[1]
          report3.option.series = arrBack[2]
          initChart3()
        })
      }
    }
    const addContrast3 = () => {
      if (!companyContrast3.value) {
        ElMessage('未选择项')
        return;
      }
      if (report3.option.legend.data.indexOf(transferToName(companyContrast3.value, 'GroupCompanys')) > -1) {
        ElMessage('对比项已经存在')
        return;
      }

      if (typeChoose3.value == 'day') {
        if (!timeSpanForSales3.value) {
          const dateH = changeDayToInt(new Date(), typeChoose3.value)
          const dateL = changeDayToInt(addDate(new Date(), -29), typeChoose3.value)
          getProfitReportByDate(companyContrast3.value, dateL, dateH).then(res => {

            const arrBack = changeToLineChartDate3(res, dateL, 30, typeChoose3.value)
            report3.option.legend.data.push(arrBack[0][0])
            report3.option.series.push(arrBack[2][0])
            initChart3()
          })
        }
        else {
          const days = GetNumberOfDays(timeSpanForSales.value[0], timeSpanForSales.value[1]) + 1
          const dateH = changeDayToInt(timeSpanForSales.value[1], typeChoose2.value)
          const dateL = changeDayToInt(timeSpanForSales.value[0], typeChoose2.value)

          getProfitReportByDate(companyContrast3.value, dateL, dateH).then(res => {
            const arrBack = changeToLineChartDate3(res, dateL, days, typeChoose3.value)
            report3.option.legend.data.push(arrBack[0][0])
            report3.option.series.push(arrBack[2][0])
            initChart3()
          })

        }

      } else if (typeChoose3.value == 'month') {
        const monthH = changeDayToInt(new Date(), typeChoose3.value)
        const monthL = changeDayToInt(addDate(new Date(), -365), typeChoose3.value)

        getProfitReportByMonth(companyContrast3.value, monthL, monthH).then(res => {
          const arrBack = changeToLineChartDate3(res, monthL, 13, typeChoose3.value)
          report3.option.legend.data.push(arrBack[0][0])
          report3.option.series.push(arrBack[2][0])
          initChart3()
        })
      } else {
        const yearH = changeDayToInt(new Date(), typeChoose3.value)
        const yearL = changeDayToInt(addDate(new Date(), -365 * 3), typeChoose3.value)

        getProfitReportByYear(companyContrast3.value, yearL, yearH).then(res => {
          const arrBack = changeToLineChartDate3(res, yearL, 4, typeChoose3.value)
          report3.option.legend.data.push(arrBack[0][0])
          report3.option.series.push(arrBack[2][0])
          initChart3()
        })
      }
    }
    actionArr.push(updateProfitReport3)


    let report4 = reactive({
      option: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [

        ]
      }
    })

    let chart4;

    const initChart4 = () => {
      if (chart4) {
        chart4.dispose()
      }
      chart4 = proxy.$echarts.init(document.getElementById('report4'))
      chart4.setOption(report4.option)
      window.onresize = () => {
        chart4.resize()
      }
    }

    let companyChoosed4 = ref('')

    companyChoosed4.value = +partACompanId

    let typeChoose4 = ref('day')

    const changeType4 = (type) => {
      typeChoose4.value = type
      updateSalesTypeReport4(type)
    }
    const timeSpanForSales4 = ref('')

    const shortcuts4 = [
      {
        text: '过去一周',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: '过去一个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: '过去3个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
    ]
    const changeToLineChartDate4 = (arr, dateL, timeSpan, type) => {

      let companyArr = []
      for (let item of arr) {
        if (companyArr.indexOf(item.salesType) <= -1) {
          companyArr.push(item.salesType)
        }
      }
      let companyNameArr = []
      let dateLCopy;
      if (type == 'day') {
        dateLCopy = new Date(dateL.toString().substring(0, 4) + '/' + dateL.toString().substring(4, 6) + '/' + dateL.toString().substring(6, 8))
      }
      else if (type == 'month') {
        dateLCopy = new Date(dateL.toString().substring(0, 4) + '/' + dateL.toString().substring(4, 6))
      }
      else {
        dateLCopy = dateL
      }
      let dateArr = []
      for (let i = 0; i < timeSpan; i++) {
        if (i == 0) {
          dateArr.push(dateL)
          continue;
        }
        if (type == 'day') {
          dateArr.push(changeDayToInt(addDate(dateLCopy, i), type))
        }
        else if (type == 'month') {
          dateArr.push(changeDayToInt(addMonth(dateLCopy, i), type))
        }
        else {
          dateArr.push(dateLCopy + i)
        }
      }

      let dataArr = []


      for (let i = 0; i < companyArr.length; i++) {

        if (isRealNum(transferToName(companyArr[i], 'MachineClass')) || !companyArr[i]) {
          continue;
        }
        companyNameArr.push(transferToName(companyArr[i], 'MachineClass') || '其他')
      }



      for (let i = 0; i < companyNameArr.length; i++) {
        const b = {
          name: companyNameArr[i],
          type: 'line',
        }
        let date1 = []
        for (let j = 0; j < timeSpan; j++) {
          if (type == 'day') {
            date1[j] = arr.filter(x => x.salesType == transferToCode(companyNameArr[i], 'MachineClass') && x.salesDay == dateArr[j])?.reduce((x, y) => x + +y.amount, 0).toFixed(2) || 0
          }
          else if (type == 'month') {
            date1[j] = arr.filter(x => x.salesType == transferToCode(companyNameArr[i], 'MachineClass') && x.salesMonth == dateArr[j])?.reduce((x, y) => x + +y.amount, 0).toFixed(2) || 0
          }
          else {
            date1[j] = arr.filter(x => x.salesType == transferToCode(companyNameArr[i], 'MachineClass') && x.salesYear == dateArr[j])?.reduce((x, y) => x + +y.amount, 0).toFixed(2) || 0
          }
        }
        b.data = date1
        dataArr.push(b)
      }

      const returnArr = []

      returnArr.push(companyNameArr)
      returnArr.push(dateArr)
      returnArr.push(dataArr)


      return returnArr

    }
    const updateSalesTypeReport4 = (type = 'day') => {
      if (type == 'day') {
        typeChoose4.value = 'day'
      }
      report4.option.legend.data.splice(0)
      report4.option.xAxis.data.splice(0)
      report4.option.series.splice(0)
      if (typeChoose4.value == 'day') {
        if (!timeSpanForSales4.value) {
          const dateH = changeDayToInt(new Date(), typeChoose4.value)
          const dateL = changeDayToInt(addDate(new Date(), -29), typeChoose4.value)
          getSalesTypeReportByDate(companyChoosed4.value, dateL, dateH).then(res => {
            const arrBack = changeToLineChartDate4(res, dateL, 30, typeChoose4.value)
            report4.option.legend.data = arrBack[0]
            report4.option.xAxis.data = arrBack[1]
            report4.option.series = arrBack[2]
            initChart4()
          })
        }
        else {
          const days = GetNumberOfDays(timeSpanForSales4.value[0], timeSpanForSales4.value[1]) + 1
          const dateH = changeDayToInt(timeSpanForSales4.value[1], typeChoose4.value)
          const dateL = changeDayToInt(timeSpanForSales4.value[0], typeChoose4.value)

          getSalesTypeReportByDate(companyChoosed4.value, dateL, dateH).then(res => {
            const arrBack = changeToLineChartDate4(res, dateL, days, typeChoose4.value)
            report4.option.legend.data = arrBack[0]
            report4.option.xAxis.data = arrBack[1]
            report4.option.series = arrBack[2]
            initChart4()
          })

        }

      } else if (typeChoose4.value == 'month') {
        const monthH = changeDayToInt(new Date(), typeChoose4.value)
        const monthL = changeDayToInt(addDate(new Date(), -365), typeChoose4.value)

        getSalesTypeReportByMonth(companyChoosed4.value, monthL, monthH).then(res => {
          const arrBack = changeToLineChartDate4(res, monthL, 13, typeChoose4.value)
          report4.option.legend.data = arrBack[0]
          report4.option.xAxis.data = arrBack[1]
          report4.option.series = arrBack[2]
          initChart4()
        })
      } else {
        const yearH = changeDayToInt(new Date(), typeChoose4.value)
        const yearL = changeDayToInt(addDate(new Date(), -365 * 3), typeChoose4.value)

        getSalesTypeReportByYear(companyChoosed4.value, yearL, yearH).then(res => {
          const arrBack = changeToLineChartDate4(res, yearL, 4, typeChoose4.value)
          report4.option.legend.data = arrBack[0]
          report4.option.xAxis.data = arrBack[1]
          report4.option.series = arrBack[2]
          initChart4()
        })
      }
    }

    actionArr.push(updateSalesTypeReport4)



    let report5 = reactive({
      option: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [

        ]
      }
    })

    let chart5;

    const initChart5 = () => {
      if (chart5) {
        chart5.dispose()
      }
      chart5 = proxy.$echarts.init(document.getElementById('report5'))
      chart5.setOption(report5.option)
      window.onresize = () => {
        chart5.resize()
      }
    }

    let companyChoosed5 = ref('')
    companyChoosed5.value = +partACompanId

    let typeChoose5 = ref('day')

    const changeType5 = (type) => {
      typeChoose5.value = type
      updateSalesTypeReport5(type)
    }
    const timeSpanForSales5 = ref('')

    const shortcuts5 = [
      {
        text: '过去一周',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: '过去一个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: '过去3个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
    ]
    const changeToLineChartDate5 = (arr, dateL, timeSpan, type) => {

      let companyArr = []
      for (let item of arr) {
        if (companyArr.indexOf(item.profitType) <= -1) {
          companyArr.push(item.profitType)
        }
      }
      let companyNameArr = []
      let dateLCopy;
      if (type == 'day') {
        dateLCopy = new Date(dateL.toString().substring(0, 4) + '/' + dateL.toString().substring(4, 6) + '/' + dateL.toString().substring(6, 8))
      }
      else if (type == 'month') {
        dateLCopy = new Date(dateL.toString().substring(0, 4) + '/' + dateL.toString().substring(4, 6))
      }
      else {
        dateLCopy = dateL
      }
      let dateArr = []
      for (let i = 0; i < timeSpan; i++) {
        if (i == 0) {
          dateArr.push(dateL)
          continue;
        }
        if (type == 'day') {
          dateArr.push(changeDayToInt(addDate(dateLCopy, i), type))
        }
        else if (type == 'month') {
          dateArr.push(changeDayToInt(addMonth(dateLCopy, i), type))
        }
        else {
          dateArr.push(dateLCopy + i)
        }
      }

      let dataArr = []


      for (let i = 0; i < companyArr.length; i++) {

        if (isRealNum(transferToName(companyArr[i], 'MachineClass')) || !companyArr[i]) {
          continue;
        }
        companyNameArr.push(transferToName(companyArr[i], 'MachineClass') || '其他')
      }


      for (let i = 0; i < companyNameArr.length; i++) {
        const b = {
          name: companyNameArr[i],
          type: 'line',
        }
        let date1 = []
        for (let j = 0; j < timeSpan; j++) {
          if (type == 'day') {
            date1[j] = arr.filter(x => x.profitType == transferToCode(companyNameArr[i], 'MachineClass') && x.profitDate == dateArr[j])?.reduce((x, y) => x + +y.profitAmount, 0).toFixed(2) || 0
          }
          else if (type == 'month') {
            date1[j] = arr.filter(x => x.profitType == transferToCode(companyNameArr[i], 'MachineClass') && x.profitMonth == dateArr[j])?.reduce((x, y) => x + +y.profitAmount, 0).toFixed(2) || 0
          }
          else {
            date1[j] = arr.filter(x => x.profitType == transferToCode(companyNameArr[i], 'MachineClass') && x.profitYear == dateArr[j])?.reduce((x, y) => x + +y.profitAmount, 0).toFixed(2) || 0
          }
        }
        b.data = date1
        dataArr.push(b)
      }

      const returnArr = []

      returnArr.push(companyNameArr)
      returnArr.push(dateArr)
      returnArr.push(dataArr)

      return returnArr;

    }
    const updateSalesTypeReport5 = (type = 'day') => {
      if (type == 'day') {
        typeChoose5.value = 'day'
      }
      report5.option.legend.data.splice(0)
      report5.option.xAxis.data.splice(0)
      report5.option.series.splice(0)
      if (typeChoose5.value == 'day') {
        if (!timeSpanForSales5.value) {
          const dateH = changeDayToInt(new Date(), typeChoose5.value)
          const dateL = changeDayToInt(addDate(new Date(), -29), typeChoose5.value)
          getProfitTypeReportByDate(companyChoosed5.value, dateL, dateH).then(res => {
            const arrBack = changeToLineChartDate5(res, dateL, 30, typeChoose5.value)
            report5.option.legend.data = arrBack[0]
            report5.option.xAxis.data = arrBack[1]
            report5.option.series = arrBack[2]
            initChart5()
          })
        }
        else {
          const days = GetNumberOfDays(timeSpanForSales5.value[0], timeSpanForSales5.value[1]) + 1
          const dateH = changeDayToInt(timeSpanForSales5.value[1], typeChoose5.value)
          const dateL = changeDayToInt(timeSpanForSales5.value[0], typeChoose5.value)

          getProfitTypeReportByDate(companyChoosed5.value, dateL, dateH).then(res => {
            const arrBack = changeToLineChartDate5(res, dateL, days, typeChoose5.value)
            report5.option.legend.data = arrBack[0]
            report5.option.xAxis.data = arrBack[1]
            report5.option.series = arrBack[2]
            initChart5()
          })

        }

      } else if (typeChoose5.value == 'month') {
        const monthH = changeDayToInt(new Date(), typeChoose5.value)
        const monthL = changeDayToInt(addDate(new Date(), -365), typeChoose5.value)

        getProfitTypeReportByMonth(companyChoosed5.value, monthL, monthH).then(res => {
          const arrBack = changeToLineChartDate5(res, monthL, 13, typeChoose5.value)
          report5.option.legend.data = arrBack[0]
          report5.option.xAxis.data = arrBack[1]
          report5.option.series = arrBack[2]
          initChart5()
        })
      } else {
        const yearH = changeDayToInt(new Date(), typeChoose5.value)
        const yearL = changeDayToInt(addDate(new Date(), -365 * 3), typeChoose5.value)

        getProfitTypeReportByYear(companyChoosed5.value, yearL, yearH).then(res => {
          const arrBack = changeToLineChartDate5(res, yearL, 4, typeChoose5.value)
          report5.option.legend.data = arrBack[0]
          report5.option.xAxis.data = arrBack[1]
          report5.option.series = arrBack[2]
          initChart5()
        })
      }
    }

    actionArr.push(updateSalesTypeReport5)



    let report6 = reactive({
      option: {
        title: {
          text: '人员利润排行'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: []
        },
        series: [
          {
            name: '利润金额',
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'inside',
              color: 'red'
            },
          }
        ]
      }
    })

    let chart6;

    const initChart6 = () => {
      if (chart6) {
        chart6.dispose()
      }
      chart6 = proxy.$echarts.init(document.getElementById('report6'))
      chart6.setOption(report6.option)
      window.onresize = () => {
        chart6.resize()
      }
    }

    let staffChoosed6 = ref('')

    const timeSpanForSales6 = ref('')

    const shortcuts6 = [
      {
        text: '过去一周',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: '过去一个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: '过去3个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
    ]
    const changeToBarChartDate6 = (arr) => {
      let staffIdArr = []
      for (let item of arr) {
        if (!staffIdArr.find(x => x.staffId == item.staffId)) {
          const b = {
            staffId: item.staffId
          }
          staffIdArr.push(b)
        }
      }
      for (let i = 0; i < staffIdArr.length; i++) {
        staffIdArr[i].amount = arr.filter(x => x.staffId == staffIdArr[i].staffId)?.reduce((x, y) => x + +y.profitAmount, 0)
      }
      staffIdArr = staffIdArr.sort(function (a, b) { return b.amount - a.amount })

      let result1 = []

      let result2 = []

      for (let j = 0; j < staffIdArr.length; j++) {
        if (j < 10) {
          result1.unshift(groupStaffs.find(x => x.beforeTransfer == staffIdArr[j].staffId)?.afterTransfer)
          result2.unshift(staffIdArr[j].amount.toFixed(2))
        }
        if (j == 10) {
          result1.unshift('其他')
          result2.unshift(staffIdArr[j].amount)
        }
        if (j > 10) {
          result2[0] += staffIdArr[j].amount
        }
      }

      if (result2) {
        result2[0] = parseFloat(result2[0]).toFixed(2)
      }

      let result3 = []

      result3.push(result1)
      result3.push(result2)

      return result3;
    }
    const updateStaffProfitType6 = () => {
      report6.option.yAxis.data.splice(0)
      report6.option.series[0].data.splice(0)

      if (!timeSpanForSales6.value) {
        const dateH = changeDayToInt(new Date(), 'day')
        const dateL = changeDayToInt(addDate(new Date(), -29), 'day')
        getStaffProfitPerDay(staffChoosed6.value, dateL, dateH).then(res => {
          const arrBack = changeToBarChartDate6(res)
          report6.option.yAxis.data = arrBack[0]
          report6.option.series[0].data = arrBack[1]
          initChart6()
        })
      }
      else {
        const dateH = changeDayToInt(timeSpanForSales6.value[1], 'day')
        const dateL = changeDayToInt(timeSpanForSales6.value[0], 'day')

        getStaffProfitPerDay(staffChoosed6.value, dateL, dateH).then(res => {
          const arrBack = changeToBarChartDate6(res)
          report6.option.yAxis.data = arrBack[0]
          report6.option.series[0].data = arrBack[1]
          initChart6()
        })
      }
    }

    actionArr.push(updateStaffProfitType6)




    let report7 = reactive({
      option: {
        title: {
          text: '库存金额报表'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: []
        },
        series: [
          {
            name: '库存金额',
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'inside',
              color: 'red'
            },
          }
        ]
      }
    })

    let chart7;

    const initChart7 = () => {
      if (chart7) {
        chart7.dispose()
      }
      chart7 = proxy.$echarts.init(document.getElementById('report7'))
      chart7.setOption(report7.option)
      window.onresize = () => {
        chart7.resize()
      }
    }

    let companyChoosed7 = ref('')

    const updateWarehouseStorage7 = () => {
      report7.option.yAxis.data.splice(0)
      report7.option.series[0].data.splice(0)

      getWarehouseStorage(companyChoosed7.value).then(res => {
        let length = 0;
        if (res) {
          length = res.length
          res = res.sort(function (a, b) { return b.totalStorage - a.totalStorage })
        }

        let result1 = []
        let result2 = []

        for (let j = 0; j < length; j++) {
          res[j].warehouseName = store.state.loginInitializeData.managedWarehouses.find(x => x.wareHouseId == res[j].warehouseId).wareHouseName
          res[j].partACompanyId = store.state.loginInitializeData.managedWarehouses.find(x => x.wareHouseId == res[j].warehouseId).partACompanyId
          res[j].companyName = groupCompanysFilter.find(x => x.beforeTransfer == res[j].partACompanyId).afterTransfer
          res[j].warehouseName = res[j].warehouseName + '-' + res[j].companyName
          if (j < 15) {
            result1.unshift(res[j].warehouseName)
            result2.unshift(res[j].totalStorage.toFixed(2))
          }
          if (j == 15) {
            result1.unshift('其他')
            result2.unshift(res[j].totalStorage)
          }
          if (j > 15) {
            result2[0] += res[j].totalStorage
          }
        }
        if (result2) {
          result2[0] = parseFloat(result2[0]).toFixed(2)
        }

        report7.option.yAxis.data = result1
        report7.option.series[0].data = result2
        initChart7()
      })
    }

    actionArr.push(updateWarehouseStorage7)




    let report8 = reactive({
      option: {
        title: {
          text: '公司库存报表'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: []
        },
        series: [
          {
            name: '公司内所有仓库金额总和',
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'inside',
              color: 'red'
            },
          }
        ]
      }
    })

    let chart8;

    const initChart8 = () => {
      if (chart8) {
        chart8.dispose()
      }
      chart8 = proxy.$echarts.init(document.getElementById('report8'))
      chart8.setOption(report8.option)
      window.onresize = () => {
        chart8.resize()
      }
    }

    let companyChoosed8 = ref('')

    const updateWarehouseStorage8 = () => {
      report8.option.yAxis.data.splice(0)
      report8.option.series[0].data.splice(0)

      getCompanyStorage(companyChoosed8.value).then(res => {
        let length = 0;
        if (res) {
          length = res.length
          res = res.sort(function (a, b) { return b.totalStorage - a.totalStorage })
        }

        let result1 = []
        let result2 = []

        for (let j = 0; j < length; j++) {

          res[j].companyName = groupCompanysFilter.find(x => x.beforeTransfer == res[j].companyId).afterTransfer

          if (j < 15) {
            result1.unshift(res[j].companyName)
            result2.unshift(res[j].totalStorage.toFixed(2))
          }
          if (j == 15) {
            result1.unshift('其他')
            result2.unshift(res[j].totalStorage)
          }
          if (j > 15) {
            result2[0] += res[j].totalStorage
          }
        }
        if (result2) {
          result2[0] = parseFloat(result2[0]).toFixed(2)
        }

        report8.option.yAxis.data = result1
        report8.option.series[0].data = result2
        initChart8()
      })
    }

    actionArr.push(updateWarehouseStorage8)




    let report9 = reactive({
      option: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: '客户来源',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,

              normal: {
                label: {
                  show: true,
                  position: 'inner',
                  //formatter: '{b} : {c} ({d}%)' //带当前图例名 + 百分比
                  formatter: '{d}%' //只要百分比
                },
              }
            },
            label: {
              show: true,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: 'Search Engine' },
              { value: 735, name: 'Direct' },
              { value: 580, name: 'Email' },
              { value: 484, name: 'Union Ads' },
              { value: 300, name: 'Video Ads' }
            ]
          }
        ]
      }
    })

    let chart9;

    const initChart9 = () => {
      if (chart9) {
        chart9.dispose()
      }
      chart9 = proxy.$echarts.init(document.getElementById('report9'))
      chart9.setOption(report9.option)
      window.onresize = () => {
        chart9.resize()
      }
    }

    let report10 = reactive({
      option: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: '客户来源',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
              normal: {
                label: {
                  show: true,
                  position: 'inner',
                  //formatter: '{b} : {c} ({d}%)' //带当前图例名 + 百分比
                  formatter: '{d}%' //只要百分比
                },
              }
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: 'Search Engine' },
              { value: 735, name: 'Direct' },
              { value: 580, name: 'Email' },
              { value: 484, name: 'Union Ads' },
              { value: 300, name: 'Video Ads' }
            ]
          }
        ]
      }
    })

    let chart10;

    const initChart10 = () => {
      if (chart10) {
        chart10.dispose()
      }
      chart10 = proxy.$echarts.init(document.getElementById('report10'))
      chart10.setOption(report10.option)
      window.onresize = () => {
        chart10.resize()
      }
    }


    let report11 = reactive({
      option: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: '客户来源',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
              normal: {
                label: {
                  show: true,
                  position: 'inner',
                  //formatter: '{b} : {c} ({d}%)' //带当前图例名 + 百分比
                  formatter: '{d}%' //只要百分比
                },
              }
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: 'Search Engine' },
              { value: 735, name: 'Direct' },
              { value: 580, name: 'Email' },
              { value: 484, name: 'Union Ads' },
              { value: 300, name: 'Video Ads' }
            ]
          }
        ]
      }
    })

    let chart11;

    const initChart11 = () => {
      if (chart11) {
        chart11.dispose()
      }
      chart11 = proxy.$echarts.init(document.getElementById('report11'))
      chart11.setOption(report11.option)
      window.onresize = () => {
        chart11.resize()
      }
    }


    let companyChoosed9 = ref('')

    const timeSpanForSales9 = ref('')

    const shortcuts9 = [
      {
        text: '过去一周',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: '过去一个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: '过去3个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
    ]



    const updateCustomerResourceReport9 = () => {
      let arrType = []
      let arrLines = []
      let arrAmount = []
      let arrProfit = []
      if (!timeSpanForSales9.value) {
        const dateH = changeDayToInt(new Date(), 'day')
        const dateL = changeDayToInt(addDate(new Date(), -29), 'day')
        getCustomerResourceReportByDate(companyChoosed9.value, dateL, dateH).then(res => {
          for (let item of res) {
            if (!arrType.find(x => x.typeId == item.sourceType)) {
              if (!transferToName(item.sourceType, 'CustomerSourceId') || isRealNum(transferToName(item.sourceType, 'CustomerSourceId'))) {
                continue;
              }
              else {
                let b = {
                  typeId: item.sourceType
                }
                arrType.push(b)
              }
            }
          }

          for (let item1 of arrType) {
            item1.salesAmount = res.filter(x => x.sourceType == item1.typeId)?.reduce((x, y) => x + +y.salesAmount, 0).toFixed(2)
            item1.profitAmount = res.filter(x => x.sourceType == item1.typeId)?.reduce((x, y) => x + +y.profitAmount, 0).toFixed(2)
            item1.lines = res.filter(x => x.sourceType == item1.typeId)?.reduce((x, y) => x + +y.qty, 0)

            item1.customerSourceName = transferToName(item1.typeId, 'CustomerSourceId')
          }
          for (let item2 of arrType) {
            let a1 = {
              value: item2.salesAmount,
              name: item2.customerSourceName
            }
            arrAmount.push(a1)
            let a2 = {
              value: item2.profitAmount,
              name: item2.customerSourceName
            }
            arrProfit.push(a2)
            let a3 = {
              value: item2.lines,
              name: item2.customerSourceName
            }
            arrLines.push(a3)
          }

          report9.option.series[0].data = arrLines
          report10.option.series[0].data = arrProfit
          report11.option.series[0].data = arrAmount
          initChart9()
          initChart10()
          initChart11()

        })
      }
      else {
        const dateH = changeDayToInt(timeSpanForSales9.value[1], 'day')
        const dateL = changeDayToInt(timeSpanForSales9.value[0], 'day')
        getCustomerResourceReportByDate(companyChoosed9.value, dateL, dateH).then(res => {
          for (let item of res) {
            if (!arrType.find(x => x.typeId == item.sourceType)) {
              if (!transferToName(item.sourceType, 'CustomerSourceId') || isRealNum(transferToName(item.sourceType, 'CustomerSourceId'))) {
                continue;
              }
              else {
                let b = {
                  typeId: item.sourceType
                }
                arrType.push(b)
              }

            }
          }

          for (let item1 of arrType) {
            item1.salesAmount = res.filter(x => x.sourceType == item1.typeId)?.reduce((x, y) => x + +y.salesAmount, 0).toFixed(2)
            item1.profitAmount = res.filter(x => x.sourceType == item1.typeId)?.reduce((x, y) => x + +y.profitAmount, 0).toFixed(2)
            item1.lines = res.filter(x => x.sourceType == item1.typeId)?.reduce((x, y) => x + +y.qty, 0)


            item1.customerSourceName = transferToName(item1.typeId, 'CustomerSourceId')
          }
          for (let item2 of arrType) {
            let a1 = {
              value: item2.salesAmount,
              name: item2.customerSourceName
            }
            arrAmount.push(a1)
            let a2 = {
              value: item2.profitAmount,
              name: item2.customerSourceName
            }
            arrProfit.push(a2)
            let a3 = {
              value: item2.lines,
              name: item2.customerSourceName
            }
            arrLines.push(a3)
          }

          report9.option.series[0].data = arrLines
          report10.option.series[0].data = arrProfit
          report11.option.series[0].data = arrAmount

          initChart9()
          initChart10()
          initChart11()

        })
      }

    }

    actionArr.push(updateCustomerResourceReport9)



    let activeKey = ref('')
    watch(leftValue, (newValue, oldValue) => {
      const index = 0 - (parseInt(newValue) / 100)
      activeKey.value = index
      if (actionArr[index]) {
        actionArr[index]();
      }
    }, {
      immediate: true
    })

    onUnmounted(() => {

    })

    return {
      leftValue,
      goRight,
      goLeft,
      groupCompanysFilter,
      groupStaffs,
      goPointReport,
      activeKey,
      chartArr,
      ...toRefs(report1),
      companyChoosed,
      MiUnCheckAmount,
      managedCompanysFilter,
      changeMiCompany,
      collectAmount,
      confirmMiAmount,
      goTurnoverDetail,

      ...toRefs(report2),
      companyChoosed2,
      shortcuts,
      timeSpanForSales,
      changeType,
      updateSalesReport,
      typeChoose2,
      companyContrast2,
      addContrast2,

      ...toRefs(report3),
      companyChoosed3,
      shortcuts3,
      timeSpanForSales3,
      changeType3,
      updateProfitReport3,
      typeChoose3,
      companyContrast3,
      addContrast3,

      ...toRefs(report4),
      companyChoosed4,
      shortcuts4,
      timeSpanForSales4,
      changeType4,
      updateSalesTypeReport4,
      typeChoose4,

      ...toRefs(report5),
      companyChoosed5,
      shortcuts5,
      timeSpanForSales5,
      changeType5,
      updateSalesTypeReport5,
      typeChoose5,

      ...toRefs(report6),
      staffChoosed6,
      shortcuts6,
      timeSpanForSales6,
      updateStaffProfitType6,

      ...toRefs(report7),
      companyChoosed7,
      updateWarehouseStorage7,

      ...toRefs(report8),
      companyChoosed8,
      updateWarehouseStorage8,

      ...toRefs(report9),
      ...toRefs(report10),
      ...toRefs(report11),
      companyChoosed9,
      updateCustomerResourceReport9,
      timeSpanForSales9,
      shortcuts9

    }
  }
}
</script>

<style scoped lang="less">
@titleHight: 3rem;
@leftBoxWidth: 12rem;
@rightBoxWidth: 12rem;

@reportHead: 5rem;
@reportBottom: 5rem;

@chartNumber: 9;

.totalProfitBox {
  width: 99%;
  height: 100%;
}

.titleBox {
  width: 100%;
  background-color: #409eff;
  height: @titleHight;
  line-height: @titleHight;
  font-size: 1.5rem;
  color: #ffffff;
  text-align: center;
}

.contentBox {
  width: 100%;
  height: calc(100% - @titleHight);
  display: flex;
}

.typeChooseBox {
  height: 100%;
  width: @leftBoxWidth;
}
.otherFunctionBox {
  height: 100%;
  width: @rightBoxWidth;
}

.reportTotalBox {
  height: 100%;
  width: calc(100% - @leftBoxWidth - @rightBoxWidth);
  overflow: hidden;
  position: relative;
}
.reportBar {
  height: 100%;
  width: calc(@chartNumber * 100%);
  display: flex;
  position: absolute;
  flex-wrap: nowrap;
  transition: all 0.5s;
}
.block {
  width: calc(100% / @chartNumber);
  height: 100%;
}

.reportFactorChooseBox {
  width: 100%;
  height: @reportHead;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.reportFactorChooseBox span,
.reportFuncBox span {
  margin-right: 1rem;
  margin-left: 1rem;
}

.reportBox {
  width: 100%;
  height: calc(100% - @reportHead - @reportBottom);
  display: flex;
  flex-wrap: wrap;
}
.reportFuncBox {
  width: 100%;
  height: @reportBottom;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.realReport {
  width: 50%;
  height: 50%;
}
.importantShow {
  color: #ff0000;
  font-weight: 700;
}
.MiInputBox {
  margin-right: 1rem;
}
.typeChooseBoxHead {
  height: 5rem;
  width: 100%;
  text-align: center;
  line-height: 5rem;
  background-color: #f5f9fa;
  color: #409eff;
  font-size: 1.5rem;
  font-weight: 700;
}
.typeChooseBoxBody {
  height: calc(100%-6rem);
  width: 100%;
  background-color: #f5f7fa;
}
.typeChooseBoxFoot {
  height: 1rem;
  width: 100%;
  background-color: #409eff;
}
.scrollbar-demo-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 3rem;
  margin: 1rem;
  text-align: center;
  border-radius: 15px;
  background: var(--el-color-primary-light-9);
}
.scrollbar-demo-item:hover {
  cursor: pointer;
  background-color: #aedffe;
}

.typeChooseIcon {
  margin-right: 1rem;
  margin-left: 0.3rem;
}
.typeChooseContent {
  color: var(--el-color-primary);
  font-weight: 700;
}

.demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}

.wordButton {
  font-weight: 700;
  font-size: 1.2rem;
  text-decoration: underline;
}
.wordButton:hover {
  cursor: pointer;
}
.RedColor {
  color: #ed1c24;
}
.activeButton {
  background-color: #aedffe;
}
.leftReport,
.rightTopReport,
.rightBottomReport {
  width: 33.3%;
  height: 100%;
  position: relative;
}
.leftReport ::after {
  content: "成交数量";
  position: absolute;
  bottom: 5rem;
  left: 40%;
  font-size: 1.5rem;
  font-weight: 700;
}
.rightTopReport ::after {
  content: "成交利润";
  position: absolute;
  bottom: 5rem;
  left: 40%;
  font-size: 1.5rem;
  font-weight: 700;
}
.rightBottomReport ::after {
  content: "成交金额";
  position: absolute;
  bottom: 5rem;
  left: 40%;
  font-size: 1.5rem;
  font-weight: 700;
}
</style>