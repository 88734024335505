import store from '@/store';
import requestData from "@/hooks/requestData.js";
import {
  copyObj
} from '@/hooks/tools'
import {
  loginInitializeCheck,
  refreshLoginDate
} from "@/hooks/loginAffair";

const lockObTracing = (codes) => {
  const config = {
    method: "post",
    url: "/ObTracingList/LockOnTheWay",
    data: codes
  };
  return requestData.GetData(config)
}


const unLockObTracing = (codes) => {
  const config = {
    method: "post",
    url: "/ObTracingList/UnLockOnTheWay",
    data: codes
  };
  requestData.GetCache(config).then(res=>{

  })
}

const lockRepairRecord = (codes) => {
  const config = {
    method: "post",
    url: "/RepairRecordList/LockOnTheWay",
    data: codes
  };
  return requestData.GetData(config)
}

const unLockRepairRecord = (codes) => {
  const config = {
    method: "post",
    url: "/RepairRecordList/UnLockOnTheWay",
    data: codes
  };
  requestData.GetCache(config).then(res=>{

  })
}

const lockSalesOneTheWay = (codes) => {
  const config = {
    method: "post",
    url: "/SalesOnTheWayList/LockOnTheWay",
    data: codes
  };
  return requestData.GetData(config)
}

const unLockSalesOneTheWay = (codes) => {
  const config = {
    method: "post",
    url: "/SalesOnTheWayList/UnLockOnTheWay",
    data: codes
  };
  requestData.GetCache(config).then(res=>{

  })
}


const lockOneTheWay = (codes) => {
  const config = {
    method: "post",
    url: "/OnTheWayList/LockOnTheWay",
    data: codes
  };
  return requestData.GetData(config)
}

const unLockOneTheWay = (codes) => {
  const config = {
    method: "post",
    url: "/OnTheWayList/UnLockOnTheWay",
    data: codes
  };
  requestData.GetCache(config).then(res=>{

  })
}

const lockMachineQuery = (codes) => {
  const config = {
    method: "post",
    url: "/MachineQueryList/LockOnTheWay",
    data: codes
  };
  return requestData.GetData(config)
}

const unlockMachineQuery = (codes) => {
  const config = {
    method: "post",
    url: "/MachineQueryList/UnLockOnTheWay",
    data: codes
  };
  requestData.GetCache(config).then(res=>{
    
  })
}

const lockAccoutsChange = (codes) => {
  const config = {
    method: "post",
    url: "/AccountsChangeOnTheWayList/LockOnTheWay",
    data: codes
  };
  return requestData.GetData(config)
}

const unlockAccoutsChange = (codes) => {
  const config = {
    method: "post",
    url: "/AccountsChangeOnTheWayList/UnLockOnTheWay",
    data: codes
  };
  requestData.GetCache(config).then(res=>{
    
  })
}


export {
  lockObTracing,
  unLockObTracing,
  lockRepairRecord,
  unLockRepairRecord,
  lockSalesOneTheWay,
  unLockSalesOneTheWay,
  lockOneTheWay,
  unLockOneTheWay,
  lockMachineQuery,
  unlockMachineQuery,
  lockAccoutsChange,
  unlockAccoutsChange
}