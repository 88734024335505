<template>
  <div class="tableLoaderContainer">
    <div class="BodyTitle">
      <div class="BodyTitleText">待发货清单</div>
    </div>
    <div class="searchContentContainer">
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="PartBCompanyIdForToBeShippedSolve">发货目标:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'PartBCompanyId'}"><select v-model="SearchContent.PartBCompanyId" class="searchinput" id="PartBCompanyIdForToBeShippedSolve" @focus="setActiveId('PartBCompanyId')" @blur="setActiveId('')" @change="reSearch()">
            <option value="" selected="selected">所有</option>
            <option v-for="item in partBFilterCopy" :key="item.beforeTransfer + 'ToBeShippedSolve'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
          </select></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DocumentDateLToBeShippedSolve">下单日期</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateL'}"><input v-model="SearchContent.DocumentDateL" class="searchinput" id="DocumentDateLToBeShippedSolve" type="date" @focus="setActiveId('DocumentDateL')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DocumentDateHToBeShippedSolve" v-text="'---'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateH'}"><input v-model="SearchContent.DocumentDateH" class="searchinput" id="DocumentDateHToBeShippedSolve" type="date" @focus="setActiveId('DocumentDateH')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="CommodityNameToBeShippedSolve">商品名称:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'CommodityName'}"><input v-model="SearchContent.CommodityName" class="searchinput long" id="CommodityNameToBeShippedSolve" type="text" @focus="setActiveId('CommodityName')" @blur="setActiveId('')"></div>
      </div>

      <div class="searchItemContainer">
        <label class="searchinputLabel" for="CommodityIdToBeShippedSolve">商品编码:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'CommodityId'}"><input v-model="SearchContent.CommodityId" class="searchinput" id="CommodityIdToBeShippedSolve" type="text" @focus="setActiveId('CommodityId')" @blur="setActiveId('')"></div>
      </div>

      <!-- 搜索模块 -->
      <div class="FuncButton" @click="searchList()">搜索</div>
    </div>

    <div class="BigTable">
      <div class="TableContentArea">
        <table class="tablehead">
          <tr class="headtr" :style="{ height : trHeight }">
            <th class="thSN">
              <div :style="{ width: SnWidth }"></div>
            </th>
            <th class="thselectall">
              <div :style="{ width: selectAllWidth }"><input class="selectAllCheckBox" type="checkbox" v-model="selectAll" /></div>
            </th>
            <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'head'" :class="{  ShowUpArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == 1, ShowDownArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == -1,}" @click.stop="ChangeSort(value1.fieldsNameForVali)">
              <div :style="{ width: value1.showLen  + 'rem' }">{{ value1.showName }}</div>
            </th>
            <th>
              <div class="thoper" :style="{ width: shipQtyWidth }">发货数量</div>
            </th>
            <th>
              <div class="thoper" :style="{ width: operationWidth }">操作</div>
            </th>
          </tr>
        </table>
        <div class="tablebodyarea" :style="{ width: tablewidth  + 'rem',height:tbodyHeight}">
          <table class="tablebody">
            <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'td' + index2">
              <td>
                <div class="tdSN" :style="{ width: SnWidth }">{{+index2 + 1}}</div>
              </td>
              <td class="tableselect">
                <div :style="{ width: selectAllWidth }"><input type="checkbox" :value="value2['toBeShippedCode']" v-model="selectedItems" /></div>
              </td>
              <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
                <div :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
              </td>
              <td>
                <div><input type="number" :style="{ width: shipQtyWidth}" v-model="tableBody[index2]['shipQty']" class="highInput" @blur="checkQty(index2)"></div>
              </td>
              <td>
                <div class="tdoper" :style="{ width: operationWidth }">
                  <span class="spandele" @click="deleteToBeShipped(index2)">删除</span>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <table class="tablefoot">
          <tr class="foottr" :style="{ height : trHeight }">
            <td>
              <div class="footsum" :style="{ width: SnWidth }">合计</div>
            </td>
            <td>
              <div :style="{ width: selectAllWidth }"></div>
            </td>
            <td v-for="value4 in tableHead" :key="value4.fieldsNameForVali + 'foot'">
              <div :style="{ width: value4.showLen  + 'rem' }"></div>
            </td>
            <td>
              <div :style="{ width: shipQtyWidth }"></div>
            </td>
            <td>
              <div :style="{ width: operationWidth }"></div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="moreFunctionBox">
      <el-button class="FirstButton" type="primary" @click="goPrint()">打印发货单</el-button>
      <el-button class="FirstButton" type="primary" @click="goHistory()">查询历史</el-button>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue-demi';
import { useStore } from "vuex";
import {
  ChangeFirToLo, ChangeFirToUp, copyObj, changeArrContentToDate, copyArray
} from '@/hooks/tools';
import requestData from '@/hooks/requestData';
import { fillDefaultValue, changeToViewCode, deleteToBeShippedRecord } from '@/hooks/OpenOrder'
import { ElMessage } from 'element-plus'
export default {
  setup(props, { emit }) {
    //#region 定义区
    const store = useStore();

    const filterList = store.state.loginInitializeData.filterList

    const partBFilter = filterList.PartBCompanyId

    const partBFilterCopy = copyArray(partBFilter)

    //初始化输入表名称
    const TableName = 'ToBeShippedList';

    const PageName = 'ToBeShippedSolve'

    const code = changeToViewCode(PageName)

    //#endregion

    //#region 全选
    let selectAll = ref(false);

    let selectedItems = ref([]);

    watch(selectAll, (newValue, oldValue) => {
      if (code == store.state.loginInitializeData.ShowWindow[0]) {
        if (newValue == true) {
          selectedItems.value.splice(0);
          for (let item of tableBody) {
            selectedItems.value.push(item['toBeShippedCode']);
          }
        } else {
          selectedItems.value.splice(0);
        }
      }
    });
    //#endregion

    let activeInput = ref('');

    const setActiveId = (id) => {
      activeInput.value = id
    }
    let selectAllWidth = ref('2.5rem')
    let trHeight = ref('1.875rem')
    let SnWidth = ref('3.75rem')
    let operationWidth = ref('6rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')
    let shipQtyWidth = ref('6rem')

    let SearchContent = reactive({
      ToBeShippedCode: '',
      DocumentId: '',
      Sn: '',
      DocumentDateL: '',
      DocumentDateH: '',
      DocumentType: '',
      CommodityName: '',
      CommodityId: '',
      OrderQty: '',
      PartACompanyId: '',
      PartBCompanyId: '',
      UserId: '',
      StaffId: '',
      Remark: '',
    })
    fillDefaultValue(SearchContent, TableName)

    //f计算表格内容总宽度
    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) + parseFloat(operationWidth.value) + parseFloat(shipQtyWidth.value) + parseFloat(selectAllWidth.value) + 1.25 + tableHead.length * 1 + 0.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });

    let tableHead = reactive([
      { fieldsNameForVali: 'partBCompanyId', showLen: 12, showName: '发货目标' },
      { fieldsNameForVali: 'documentDate', showLen: 14, showName: '单据日期' },
      { fieldsNameForVali: 'documentType', showLen: 7, showName: '单据类型' },
      { fieldsNameForVali: 'documentId', showLen: 16, showName: '单据Id' },
      { fieldsNameForVali: 'sn', showLen: 5, showName: '序' },
      // { fieldsNameForVali: 'CommodityId', showLen: 80, showName: '商品Id' },
      // { fieldsNameForVali: 'UserId', showLen: 80, showName: '下单账号' },
      // { fieldsNameForVali: 'staffId', showLen: 80, showName: '下单人' },
      { fieldsNameForVali: 'commodityName', showLen: 30, showName: '商品名称' },
      { fieldsNameForVali: 'remark', showLen: 12.5, showName: '备注' },
      { fieldsNameForVali: 'orderQty', showLen: 7, showName: '订单数量' },

    ]);

    const searchElement = reactive({
      SortFieldsName: 'DocumentDate',
      SortDirection: 1
    })

    let tableBody = reactive([]);

    let ChangeSort = (fildsname) => {
      searchElement.SortFieldsName = ChangeFirToUp(fildsname)
      searchElement.SortDirection = -searchElement.SortDirection
      searchList()
    };

    const reSearch = () => {
      searchList()
    }

    const checkQty = (index) => {

      tableBody[index].shipQty = parseInt(tableBody[index].shipQty)
      if (tableBody[index].shipQty > tableBody[index].orderQty) {
        ElMessage('发货数量不可大于订单数量')
        tableBody[index].shipQty = tableBody[index].orderQty
      }
      if (tableBody[index].shipQty < 0) {
        ElMessage('发货数量不可为负')
        tableBody[index].shipQty = 0
      }
    }

    let searchList = () => {

      let config = {
        method: "post",
        url: '/ToBeShippedList/GetListByMulSearchForShip',
        data: Object.assign(SearchContent, searchElement),
      };
      requestData
        .GetData(config)
        .then((res) => {
          selectAll.value = false
          selectedItems.value.splice(0)
          tableBody.splice(0);
          tableBody.push.apply(tableBody, res['toBeShippedListMultiple']);
          changeArrContentToDate(tableBody, PageName)
          const filterList = store.state.loginInitializeData.filterList
          const companyFilter = filterList.PartBCompanyId
          for (let item of tableBody) {
            for (let key in item) {
              if (key == 'partBCompanyId') {
                item[key] = companyFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
            }
            item.shipQty = item.orderQty
          }

        })
        .catch((err) => {

        });
    };
    searchList()

    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          searchList()
        }
      }
    )

    const goPrint = () => {
      if (!selectedItems.value.length) {
        ElMessage('未选择项')
        return
      }
      let newBody = []
      for (let item of tableBody) {
        if (selectedItems.value.indexOf(item.toBeShippedCode) > -1 && item.shipQty > 0) {
          newBody.push(copyObj(item))
        }
      }
      emit('goPrintForShipFromToBeShipped', newBody)
    }

    const deleteToBeShipped = (index) => {
      deleteToBeShippedRecord(tableBody[index].toBeShippedCode).then(res => {
        if (res == 'pass') {
          emit('refreshMsg')
          searchList()
        }
        else {
          ElMessage('删除失败')
        }

      })
    }

    const comebackFromPrint = () => {
      emit('refreshMsg')
      searchList()
    }

    const goHistory = () => {
      let code = changeToViewCode('ShippedList')
      store.commit('GoFunctionWindowWithCode', code)
    }
    //#endregion

    return {
      partBFilterCopy, setActiveId, activeInput,
      trHeight, SnWidth, operationWidth, tableHead, searchList, ChangeSort, tablewidth, tableBody,
      reSearch, searchElement, tbodyHeight, SearchContent, selectAllWidth,
      selectAll, selectedItems, shipQtyWidth, checkQty, goPrint, deleteToBeShipped,
      comebackFromPrint, goHistory

    }
  },
  components: {
  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;
@lableMarginLeft: 0.625rem;
@lableHeight: 2rem;
@inputHeight: 1.5rem;
@mainColor: #4285f4;

.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.searchinputLabel {
  margin-left: @lableMarginLeft;
  font-size: 0.8rem;
  height: @lableHeight;
  line-height: @lableHeight;
}
.searchinput {
  margin-top: calc((@lableHeight - @inputHeight) / 2);
  margin-bottom: calc((@lableHeight - @inputHeight) / 2);
  font-size: 0.9rem;
  border: none;
  outline: none;
  height: @inputHeight;
  line-height: @inputHeight;
}
.ActiveBox {
  border: 1px solid #ffa004 !important;
}

.searchInputBox {
  height: @lableHeight;
  border: 1px solid #c9c9c9;
  margin-left: @lableMarginLeft;
  border-radius: 0.31rem;
}

.searchItemContainer {
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
  display: flex;
}

.searchSetting {
  margin-left: calc(@lableMarginLeft*3);
  height: @lableHeight;
  display: flex;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}
.searchSetting:hover {
  cursor: pointer;
  color: @mainColor;
}
.settingIcon {
  font-family: "icomoon";
  font-size: 1.1rem;
  line-height: @lableHeight;
}

.settingText {
  margin-left: 0.3rem;
  font-size: 0.8rem;
  line-height: @lableHeight;
}
.dateInputContainer {
  display: flex;
}
.BodyTitle {
  width: 98%;
  margin-top: 0.5rem;
  margin-left: 1%;
}
.BodyTitleText {
  font-size: 1.2rem;
  font-weight: 200;
}
.long {
  width: 20rem;
}

.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 60%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.thSN {
  font-family: "icomoon";
  color: #fff;
  font-size: 16px;
}
.headtd {
  cursor: pointer;
}
.ShowUpArrow {
  position: relative;
}
.ShowUpArrow::after {
  content: "\ea32";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
  color: #fff;
}
.ShowDownArrow {
  position: relative;
}
.ShowDownArrow::after {
  content: "\ea36";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.tdSN,
.tableselect,
.tdoper,
.footsum,
.bodytr {
  text-align: center;
}
.bodytr {
  height: 2rem;
}

.tablefoot {
  margin-top: -1px;
}

.moreFunctionBox {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.selectAllCheckBox {
  cursor: pointer;
}
.bodytr input {
  border: none;
  outline: none;
  height: 1.875rem;
  background-color: transparent;
  text-align: center;
}
.bodytr input:focus {
  border: 1px solid #ffa004;
}
.highInput {
  background-color: #d9e1f2 !important;
}
.FirstButton {
  margin-left: 2rem;
}
</style>