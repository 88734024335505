<template>
  <div class="tableLoaderContainer">
    <div class="BodyTitle">
      <div class="BodyTitleText">商品中心</div>
    </div>

    <div class="searchContentContainer">

      <div>
        <div class="searchItemContainer">
          <label class="searchinputLabel" for="CommodityNameDiy" v-text="'商品名称'"></label>
          <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'CommodityNameDiy'}"><input v-model="SearchContent['CommodityName']" class="searchinput" id="CommodityNameDiy" type="text" @focus="setActiveId('CommodityNameDiy')" @blur="setActiveId('')"></div>
        </div>
      </div>

      <!-- 搜索模块 -->
      <div class="FuncButton" @click="searchList()">搜索</div>
    </div>

    <div class="BigTable">
      <div class="TableContentArea">
        <table class="tablehead">
          <tr class="headtr" :style="{ height : trHeight }">
            <th class="thSN">
              <div :style="{ width: SnWidth }"></div>
            </th>
            <th>
              <div class="thoper" :style="{ width: operationWidth }">操作</div>
            </th>
            <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'head'" :class="{  ShowUpArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == 1, ShowDownArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == -1,}" @click.stop="ChangeSort(value1.fieldsNameForVali)">
              <div :style="{ width: value1.showLen  + 'rem' }">{{ value1.showName }}</div>
            </th>
          </tr>
        </table>
        <div class="tablebodyarea" :style="{ width: tablewidth  + 'rem',height:tbodyHeight}">
          <table class="tablebody">
            <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'td' + index2" @mousedown="saveActiveItem(index2)" :class="{'bodytrActive':activeItem.index == index2}">
              <td>
                <div class="tdSN" :style="{ width: SnWidth }">{{ index2 + 1 + searchElement.LinesPerPage * (searchElement.Page - 1) }}</div>
              </td>
              <td>
                <div class="tdoper" :style="{ width: operationWidth }">
                  <span v-if="$store.state.loginInitializeData.companyId == $store.state.loginInitializeData.headquarters" class="spanedit" @click="setRefType('update'),openUpdateRef(value2)">编辑</span>
                  <span v-if="$store.state.loginInitializeData.companyId == $store.state.loginInitializeData.headquarters" class="spandele" @click="deleteCommodity(value2.commodityId)">冻结</span>

                </div>
              </td>
              <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
                <div class="bodyFont" :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
              </td>
            </tr>
          </table>
        </div>
        <!-- <table class="tablefoot">
          <tr class="foottr" :style="{ height : trHeight }">
            <td>
              <div class="footsum" :style="{ width: SnWidth }">合计</div>
            </td>
            <td>
              <div :style="{ width: operationWidth }"></div>
            </td>
            <td v-for="value4 in tableHead" :key="value4.fieldsNameForVali + 'foot'">
              <div :style="{ width: value4.showLen  + 'rem' }"></div>
            </td>
          </tr>
        </table> -->
      </div>
      <div class="MainBodyTablePage" :style="{ width: '100%'}">
        <!--页数模块 -->
        <div class="PageCountContainer">
          <div class="PagerSummaryText">({{ totalLines }}条记录,总共{{ totalPage }}页)</div>
          <span class="PagerEveryPageText">每页/条</span>
          <div class="EditBlock">
            <input class="LinesPerPageChooese" type="text" v-model="searchElement.LinesPerPage" />
            <div class="LinesPerPageChooseButton"></div>
          </div>
          <div class="GoFirstPageButton" @click="FirstPage()"></div>
          <div class="GoLastPageButton" @click="DownPage()"></div>
          <span class="pagetextforDi">第</span>
          <div class="EditBlock">
            <input class="PageEdit" type="text" v-model="searchElement.Page" />
            <div class="PageChooseButton"></div>
          </div>
          <span class="pagetextforYe">页</span>
          <div class="GoNextPageButton" @click="UpPage()"></div>
          <div class="GoEndPageButton" @click="EndPage()"></div>
        </div>
      </div>
    </div>

    <div class="moreFunctionBox">
      <el-button v-if="$store.state.loginInitializeData.companyId == $store.state.loginInitializeData.headquarters" class="firstButton" type="primary" @click="setRefType('add'),openAddRef()">新增</el-button>
      <el-button v-if="$store.state.loginInitializeData.companyId == $store.state.loginInitializeData.headquarters" class="firstButton" type="primary" @click="openUpLoadRef()">批量导入</el-button>
    </div>

    <BigPopModel ref="addUpdateRef" :title="'商品明细'">
      <div>
        <div>
          <label for="commodityName" :style="[{marginLeft:'.2rem'},{marginTop:'.5rem'}]">商品名称:</label>
          <el-input id="commodityName" :style="[{width:'30rem'},{marginLeft:'.5rem'},{marginTop:'.5rem'}]" v-model="commodityObj.CommodityName" placeholder="请输入商品名称" />
        </div>

        <div>
          <label for="barcode" :style="[{marginLeft:'.2rem'},{marginTop:'.5rem'}]">商品条码:</label>
          <el-input id="barcode" maxlength="20" placeholder="不输入会自动生成" show-word-limit type="text" v-model="commodityObj.Barcode" :style="[{width:'30rem'},{marginLeft:'.5rem'},{marginTop:'.5rem'}]" />
        </div>

        <div>
          <label for="Unit" :style="[{marginLeft:'.2rem'},{marginTop:'.5rem'}]">商品单位:</label>
          <el-input id="Unit" :style="[{width:'12rem'},{marginLeft:'.5rem'},{marginTop:'.5rem'}]" v-model="commodityObj.Unit" placeholder="请输入单位" />

          <label for="IsActualCommodity" :style="[{marginLeft:'1.3rem'},{marginTop:'.5rem'}]">商品属性:</label>
          <!-- <el-input id="IsActualCommodity" :style="[{width:'12rem'},{marginLeft:'.5rem'},{marginTop:'.5rem'}]" v-model="commodityObj.IsActualCommodity" placeholder="Please input" /> -->

          <el-select id="IsActualCommodity" :disabled="refType == 'update'" v-model="commodityObj.IsActualCommodity" class="m-2" size="large" for="IsActualCommodity" :style="[{width:'12rem'},{marginLeft:'.5rem'},{marginTop:'.5rem'}]">
            <el-option key="actualProduct" label="正常商品" :value="1" />
            <el-option key="virtualProduct" label="虚拟商品" :value="0" />
          </el-select>
          <el-tooltip class="box-item" effect="dark" content="虚拟物品不参与出入库,采购或销售都会直接计算成本或利润" placement="right" v-if="commodityObj.IsActualCommodity == 0">
            <el-button class="transparentBorder">?</el-button>
          </el-tooltip>
        </div>

        <div>
          <label for="DefaultPurchasePrice" :style="[{marginLeft:'.2rem'},{marginTop:'.5rem'}]">默认成本:</label>
          <el-input id="DefaultPurchasePrice" :style="[{width:'12rem'},{marginLeft:'.5rem'},{marginTop:'.5rem'}]" v-model="commodityObj.DefaultPurchasePrice" placeholder="请输入默认成本" />

          <label for="DefaultSalePrice" :style="[{marginLeft:'1.3rem'},{marginTop:'.5rem'}]">默认售价:</label>
          <el-input id="DefaultSalePrice" :style="[{width:'12rem'},{marginLeft:'.5rem'},{marginTop:'.5rem'}]" v-model="commodityObj.DefaultSalePrice" placeholder="请输入默认售价" />
        </div>

        <div class="buttonBox">
          <el-button type="info" :style="[{marginTop:'.5rem'},{marginBottom:'.5rem'}]">关闭</el-button>
          <el-button type="success" v-if="refType == 'add'" @click="addCommodity('open')" :style="[{marginTop:'.5rem'},{marginBottom:'.5rem'}]">新增并继续</el-button>
          <el-button type="primary" v-if="refType == 'add'" @click="addCommodity('close')" :style="[{marginRight:'1.3rem'},{marginTop:'.5rem'},{marginBottom:'.5rem'}]">新增并关闭</el-button>
          <el-button type="primary" v-if="refType == 'update'" @click="updateCommodity()" :style="[{marginRight:'1.3rem'},{marginTop:'.5rem'},{marginBottom:'.5rem'}]">修改并关闭</el-button>
        </div>

      </div>

    </BigPopModel>

    <UploadPopModelDiy ref="UploadRef" :fileName="'CommodityUpload'" :downLoadFileName="'商品上传表'" :pageName="'DiyCommodityMangeTable'" @uploadFile="uploadFile"></UploadPopModelDiy>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, provide, reactive, ref } from 'vue-demi'
import requestData from '@/hooks/requestData';
import { ChangeFirToUp, isRealNum } from '@/hooks/tools';
import BigPopModel from '@/components/Common/PopModel.vue'
import { ElMessage } from 'element-plus'
import UploadPopModelDiy from '@/components/ComPop/UploadPopModelDiy.vue';
export default {
  components: {
    BigPopModel,
    UploadPopModelDiy
  },
  setup() {

    provide('tableTitle', '商品明细')

    let SearchContent = reactive({
      CommodityId: '',
      CommodityName: '',
      Barcode: '',
      IsActualCommodityFS: -1,
      Unit: '',
      SecondLevelPartClassId: '',
      SystemStatusId: '',
      Weight: '',
      BrandId: '',
      Remark: '',
      DefaultSalePrice: '',
      DefaultPurchasePrice: '',
      DefaultPircingId: ''
    })

    let activeInput = ref('');

    const setActiveId = (id) => {
      activeInput.value = id
    }

    let SnWidth = ref('3.75rem')
    let selectAllWidth = ref('2.5rem')
    let operationWidth = ref('8rem')
    let trHeight = ref('1.875rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')

    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) + parseFloat(selectAllWidth.value) + parseFloat(operationWidth.value) + 1.25 + tableHead.length * 1 + 0.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });

    let tableHead = reactive([
      // { fieldsNameForVali: 'commodityId', showLen: 12, showName: '商品Id' },
      { fieldsNameForVali: 'commodityName', showLen: 30, showName: '商品名称' },
      { fieldsNameForVali: 'isActualCommodity', showLen: 6, showName: '属性' },
      { fieldsNameForVali: 'unit', showLen: 6, showName: '单位' },
      // { fieldsNameForVali: 'systemStatusId', showLen: 12, showName: '系统状态' },
      // { fieldsNameForVali: 'remark', showLen: 12, showName: '备注' },
      { fieldsNameForVali: 'barcode', showLen: 15, showName: '条码' },
      { fieldsNameForVali: 'defaultSalePrice', showLen: 6, showName: '默认售价' },
      { fieldsNameForVali: 'defaultPurchasePrice', showLen: 6, showName: '默认采购价' },
      // { fieldsNameForVali: 'documentId', showLen: 150, showName: '单据编码' },
      // { fieldsNameForVali: 'sn', showLen: 40, showName: '序' },
    ]);

    const searchElement = reactive({
      SortFieldsName: 'CommodityId',
      SortDirection: -1,
      LinesPerPage: 100,
      Page: 1,
    })


    const FirstPage = () => {
      if (searchElement.Page == 1) {
        return
      }
      else {
        searchElement.Page = 1
        searchList()
      }
    }

    const EndPage = () => {
      if (searchElement.Page == totalPage.value) {
        return
      }
      else {
        searchElement.Page = totalPage.value
        searchList()
      }
    }

    const DownPage = () => {
      if (searchElement.Page <= 1) {
        return
      }
      else {
        searchElement.Page -= 1
        searchList()
      }
    }

    const UpPage = () => {

      if (searchElement.Page >= totalPage.value) {
        return
      }
      else {
        searchElement.Page += 1
        searchList()
      }
    }

    let tableBody = reactive([]);

    let ChangeSort = (fildsname) => {
      searchElement.SortFieldsName = ChangeFirToUp(fildsname)
      searchElement.SortDirection = -searchElement.SortDirection
      searchList()
    };

    let totalLines = ref(0)

    let totalPage = ref(0)

    let selectAll = ref(false);

    let selectedItems = ref([]);

    const searchList = () => {
      let config = {
        method: "post",
        url: '/DiyCommodityMangeTable/GetListByMulSearch',
        data: Object.assign(SearchContent, searchElement),
      };
      requestData
        .GetData(config)
        .then((res) => {
          for (let item of res?.commodityMangeTableMultiple) {
            if (item.isActualCommodity) {
              item.isActualCommodity = ''
            }
            else {
              item.isActualCommodity = '虚拟'
            }
          }
          selectAll.value = false
          selectedItems.value.splice(0)
          totalLines.value = res.lineCounts
          totalPage.value = res.totalPage
          tableBody.splice(0);
          tableBody.push.apply(tableBody, res['commodityMangeTableMultiple']);
        })
        .catch((err) => {
        });
    };
    searchList()

    const addUpdateRef = ref(null)

    let commodityObj = reactive({
      CommodityId: '',
      CommodityName: '',
      Barcode: '',
      IsActualCommodity: 1,
      Unit: '',
      SecondLevelPartClassId: '',
      SystemStatusId: '',
      Weight: '',
      BrandId: '',
      Remark: '',
      DefaultSalePrice: '',
      DefaultPurchasePrice: '',
      DefaultPircingId: ''
    })

    const openAddRef = () => {
      commodityObj.CommodityId = ''
      commodityObj.CommodityName = ''
      commodityObj.Barcode = ''
      commodityObj.IsActualCommodity = 1
      commodityObj.Unit = '件'
      commodityObj.SecondLevelPartClassId = ''
      commodityObj.SystemStatusId = ''
      commodityObj.Weight = ''
      commodityObj.BrandId = ''
      commodityObj.Remark = ''
      commodityObj.DefaultSalePrice = 0
      commodityObj.DefaultPurchasePrice = 0
      commodityObj.DefaultPircingId = ''
      addUpdateRef.value.openPopComm()
    }

    const addCommodity = (type) => {
      commodityObj.IsActualCommodity = commodityObj.IsActualCommodity == 1 ? true : false
      if (commodityObj.Barcode != '') {
        if (!isRealNum(commodityObj.Barcode)) {
          ElMessage(`条码请输入数字`)
          return
        }
        if (commodityObj.Barcode.length <= 5) {
          ElMessage(`条码不可以低于5位数`)
          return
        }
      }

      let config = {
        method: "post",
        url: '/DiyCommodityMangeTable/Add',
        data: commodityObj,
      };
      requestData.GetData(config).then(res => {
        if (res == 'pass') {
          if (type == 'close') {
            ElMessage('添加成功')
            addUpdateRef.value.closePopComm()
            searchList()
          }
          else {
            ElMessage('添加成功')

            commodityObj.CommodityId = ''
            commodityObj.CommodityName = ''
            commodityObj.Barcode = ''
            commodityObj.IsActualCommodity = 1
            commodityObj.Unit = '个'
            commodityObj.SecondLevelPartClassId = ''
            commodityObj.SystemStatusId = ''
            commodityObj.Weight = ''
            commodityObj.BrandId = ''
            commodityObj.Remark = ''
            commodityObj.DefaultSalePrice = 0
            commodityObj.DefaultPurchasePrice = 0
            commodityObj.DefaultPircingId = ''
            searchList()
          }
        }
        else {
          ElMessage(res)
          commodityObj.IsActualCommodity = commodityObj.IsActualCommodity ? 1 : 0
          return
        }
      })
    }

    const updateCommodity = () => {
      commodityObj.IsActualCommodity = commodityObj.IsActualCommodity == 1 ? true : false
      if (commodityObj.Barcode != '') {
        if (!isRealNum(commodityObj.Barcode)) {
          ElMessage(`条码请输入数字`)
          return
        }
        if (commodityObj.Barcode.length <= 5) {
          ElMessage(`条码不可以低于5位数`)
          return
        }
      }

      let config = {
        method: "post",
        url: '/DiyCommodityMangeTable/Update',
        data: commodityObj,
      };
      requestData.GetData(config).then(res => {
        if (res == 'pass') {
          ElMessage('修改成功')
          addUpdateRef.value.closePopComm()
          searchList()
        }
        else {
          ElMessage(res)
          commodityObj.IsActualCommodity = commodityObj.IsActualCommodity ? 1 : 0
          return
        }
      })
    }

    const deleteCommodity = (commId) => {

      const b = confirm('确认是否冻结商品')

      if (!b) {
        return
      }

      let config = {
        method: "delete",
        url: '/DiyCommodityMangeTable/FakeDelete',
        params: {
          id: commId
        }
      };
      requestData.GetData(config).then(res => {
        if (res == 'pass') {
          ElMessage('冻结成功')
          searchList()
        }
        else {
          ElMessage(res)
          return
        }
      })
    }



    let activeItem = reactive({
      index: -1
    })

    const saveActiveItem = (index) => {
      activeItem.index = index
    }

    let refType = ref('')

    const setRefType = (type) => {
      refType.value = type
    }

    const openUpdateRef = (value) => {

      commodityObj.CommodityId = value.commodityId
      commodityObj.CommodityName = value.commodityName
      commodityObj.Barcode = value.barcode
      commodityObj.IsActualCommodity = value.isActualCommodity == '' ? 1 : 0
      commodityObj.Unit = value.unit
      commodityObj.SecondLevelPartClassId = value.secondLevelPartClassId
      commodityObj.SystemStatusId = value.systemStatusId
      commodityObj.Weight = value.weight
      commodityObj.BrandId = value.brandId
      commodityObj.Remark = value.remark
      commodityObj.DefaultSalePrice = value.defaultSalePrice
      commodityObj.DefaultPurchasePrice = value.defaultPurchasePrice
      commodityObj.DefaultPircingId = value.defaultPircingId

      addUpdateRef.value.openPopComm()

    }

    const UploadRef = ref(null)


    const openUpLoadRef = () => {
      UploadRef.value.openPopComm()
    }

    const uploadFile = (dataArr) => {

      let i = dataArr.length
      for (let j = 0; j < i; j++) {
        if (!dataArr[j]?.CommodityName) {
          ElMessage(`第${j + 2}行商品名称未填写`)
          return;
        }
        else {
          if (dataArr[j]?.CommodityName.length > 200) {
            ElMessage(`第${j + 2}行商品名称超过了200个字`)
            return;
          }
        }
        if (!dataArr[j]?.Barcode) {
          dataArr[j].Barcode = ''
        }
        else {
          if (dataArr[j]?.Barcode.length > 20) {
            ElMessage(`第${j + 2}行条码超过了20个数字`)
            return;
          }
          if (!isRealNum(dataArr[j]?.Barcode)) {
            ElMessage(`第${j + 2}行条码非数字`)
            return;
          }
        }
        if (!dataArr[j]?.IsActualCommodity) {
          dataArr[j].IsActualCommodity = true
        }
        else if (dataArr[j]?.IsActualCommodity == '否') {
          dataArr[j].IsActualCommodity = false
        }
        else {
          dataArr[j].IsActualCommodity = true
        }
        if (!dataArr[j]?.Unit) {
          dataArr[j].Unit = '件'
        }
        else {
          if (dataArr[j]?.Unit.length > 5) {
            ElMessage(`第${j + 2}行单位超过了5个字`)
            return;
          }
        }
        if (!dataArr[j]?.DefaultSalePrice) {
          dataArr[j].DefaultSalePrice = 0
        }
        else {
          if (isRealNum(dataArr[j]?.DefaultSalePrice)) {

          }
          else {
            ElMessage(`第${j + 2}行默认售价非数字`)
            return;
          }
        }
        if (!dataArr[j]?.DefaultPurchasePrice) {
          dataArr[j].DefaultPurchasePrice = 0
        }
        else {
          if (isRealNum(dataArr[j]?.DefaultPurchasePrice)) {

          }
          else {
            ElMessage(`第${j + 2}行默认成本非数字`)
            return;
          }
        }

      }

      let config = {
        method: "post",
        url: '/DiyCommodityMangeTable/Upload',
        data: dataArr,
      };

      requestData.GetData(config).then(res => {
        if (res == "pass") {
          ElMessage(`上传成功`)
          UploadRef.value.closePopComm()
          searchList()
        } else {
          ElMessage(res)
          UploadRef.value.closePopComm()
          searchList()
        }
      })

    }


    //#endregion

    return {
      SearchContent,
      activeInput,
      setActiveId,
      tablewidth,
      tbodyHeight,
      trHeight,
      operationWidth,
      selectAllWidth,
      SnWidth,
      searchElement,
      tableBody,
      ChangeSort,
      tableHead,
      totalLines,
      totalPage,
      searchList,
      selectAll,
      selectedItems,
      addUpdateRef,
      openAddRef,
      commodityObj,
      addCommodity,
      saveActiveItem,
      activeItem,
      setRefType,
      refType,
      openUpdateRef,
      updateCommodity,
      deleteCommodity,
      FirstPage,
      DownPage,
      UpPage,
      EndPage,
      openUpLoadRef,
      UploadRef,
      uploadFile

    }
  }
}
</script>

<style scoped lang="less">
@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;
@lableMarginLeft: 0.625rem;
@lableHeight: 2rem;
@inputHeight: 1.5rem;
@mainColor: #4285f4;
.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.BodyTitle {
  width: 98%;
  margin-top: 0.5rem;
  margin-left: 1%;
}
.BodyTitleText {
  font-size: 1.2rem;
  font-weight: 200;
}
.searchItemContainer {
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
  display: flex;
}
.searchinputLabel {
  margin-left: @lableMarginLeft;
  font-size: 0.8rem;
  height: @lableHeight;
  line-height: @lableHeight;
}
.searchInputBox {
  height: @lableHeight;
  border: 1px solid #c9c9c9;
  margin-left: @lableMarginLeft;
  border-radius: 0.31rem;
}
.ActiveBox {
  border: 1px solid #ffa004 !important;
}
.searchinput {
  margin-top: calc((@lableHeight - @inputHeight) / 2);
  margin-bottom: calc((@lableHeight - @inputHeight) / 2);
  font-size: 0.9rem;
  border: none;
  outline: none;
  height: @inputHeight;
  line-height: @inputHeight;
}

@pageHeight: 1.875rem;
.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 60%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.thSN {
  font-family: "icomoon";
  color: #fff;
  font-size: 1rem;
}
.headtd {
  cursor: pointer;
}
.ShowUpArrow {
  position: relative;
}
.ShowUpArrow::after {
  content: "\ea32";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
  color: #fff;
}
.ShowDownArrow {
  position: relative;
}
.ShowDownArrow::after {
  content: "\ea36";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.tdSN,
.tableselect,
.tdoper,
.footsum,
.bodytr {
  text-align: center;
}
.bodytr {
  height: 2rem;
}

.tablefoot {
  margin-top: -1px;
}

.bodytrActive {
  background-color: #fefae3 !important;
}
.bodyFont {
  font-size: 0.9rem;
}
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}

.moreFunctionBox {
  margin-top: 3rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}
.firstButton {
  margin-left: 1rem;
}
.buttonBox {
  display: flex;
  justify-content: flex-end;
}
.transparentBorder {
  border: transparent;
}
</style>