<template>
  <div class="TopAreaContainer">
    <div class="TopLeft">
      <h2>{{$store.state.loginInitializeData.companyNameAbbre}}</h2>
    </div>
    <div class="TopCenter">
      <div class="CenterTop">
        <div class="CenterTopLeft"></div>
        <div class="CenterTopCenter"><input type="text" v-model="searchContent" @input="searchFunctions()" /></div>
        <div class="CenterTopRight">功能搜索</div>
        <div class="searchResult">
          <div class="searchResultItems" v-for="(item,index) in searchResult" @click="goFunction(item.viewCode)" :key="index + 'TopSearchResult'"><span class="iconShow"></span>{{item.viewName}}</div>
          <div class="closeSearch" v-show="searchResult.length" @click="closeSearch()"></div>
        </div>

        <div class="moreFuncBox">
          <el-dropdown>
            <el-button type="primary">
              <span class="moreFuncWord">更多功能</span>
              <span class="icon moreFuncIcon"></span>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <div @click="goQuotation()" v-if="seeQuotation">报价查询</div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div @click="goMachineMarket()" v-if="$store.state.loginInitializeData.authList.viewVisibleManages.find(x=>x.viewCode == 100090)?.visibleOrNot">二手市场</div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div @click="goReport()">经营报表</div>
                </el-dropdown-item>

                <el-dropdown-item v-show="$store.state.loginInitializeData.userId == 100000003 || $store.state.loginInitializeData.userId == 100000022 || $store.state.loginInitializeData.userId == 100000001 || $store.state.loginInitializeData.userId == 100000005">
                  <div @click="goPriceManage()">商品价格管理</div>
                </el-dropdown-item>

                <el-dropdown-item v-if="$store.state.loginInitializeData.companyId == '10000001'">
                  <div @click="synchroniseInventory()">同步库存</div>
                </el-dropdown-item>

                <el-dropdown-item divided>
                  <div @click="refreshPage()">超级刷新</div>
                </el-dropdown-item>

                <el-dropdown-item>
                  <div @click="drawer3 = true" :style="{color:'red'}">投诉建议</div>
                </el-dropdown-item>

              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>

        <!-- <div class="quotationFuncion" v-show="$store.state.loginInitializeData.authList.viewVisibleManages.find(x=>x.viewCode == 100089)?.visibleOrNot" @click="goQuotation()">报价查询</div> -->
        <!-- <div class="quotationFuncion" @click="goQuotation()">报价查询</div>
        <div class="quotationFuncion" @click="goMachineMarket()">二手市场</div>
        <div class="quotationFuncion" @click="goReport()">经营报表</div>
        <div class="quotationFuncion" @click="goPriceManage()" v-show="$store.state.loginInitializeData.userId == 100000003 || $store.state.loginInitializeData.userId == 100000022 || $store.state.loginInitializeData.userId == 100000001 || $store.state.loginInitializeData.userId == 100000005">商品价格管理</div>
        <div class="quotationFuncion" @click="synchroniseInventory()" v-if="$store.state.loginInitializeData.companyId == '10000001'">同步库存</div> -->
        <div class="quotationFuncion barcodeBox" @click="goBarcodePrint()">条码打印<div class="barcodeCount" v-show="$store.state.barcodeCount">{{$store.state.barcodeCount}}</div>
        </div>
        <!-- <div class="quotationFuncion" @click="drawer3 = true">投诉建议</div> -->
      </div>
      <div class="CenterBottom">
        <MainManu></MainManu>
      </div>
    </div>
    <div class="TopRight">
      <!-- <span class="refreshTop">超级刷新</span>
      <img src="~@/assets/image/TopAreaImage/Refresh.png" alt="" @click="refreshPage()" /> -->
      <dl class="ShowUserName" @mouseleave="CloseContent">
        <dt @mouseover="ShowContent()">{{$store.state.loginInitializeData.userName}}</dt>
        <dd class="Usermanu" v-show="isShow && loginData.isMainAccount" @click="openChargePop()">充值</dd>
        <dd class="Usermanu" v-show="isShow" @click="openSetUpLowerCompanyPop()">开设子门店</dd>
        <dd class="Usermanu" v-show="isShow"><a href="https://www.bilibili.com/video/BV1Mg411S7VS/?vd_source=3402c778cf9e5453a5e9ad6ddc1999bb" target="_blank">教学视频</a></dd>
        <dd class="Usermanu" v-show="isShow" @click="openChangePwPop()">修改密码</dd>
        <dd class="Usermanu" v-show="isShow" @click="goLogin()">退出</dd>
      </dl>
    </div>
    <PopModel ref="changePwRef">
      <div class="inputBox">
        <label class="popLable">原密码:</label>
        <el-input v-model="passwordArr.oldOne" class="popInput" type="password" placeholder="请输入旧密码" show-password />
      </div>
      <div class="inputBox">
        <label class="popLable">新密码:</label>
        <el-input v-model="passwordArr.newOne" class="popInput" type="password" placeholder="请输入新密码" show-password />
      </div>
      <div class="inputBox">
        <label class="popLable">再确认:</label>
        <el-input v-model="passwordArr.confirmOne" class="popInput" type="password" placeholder="请再次输入密码" show-password />
      </div>
      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight" @click="closeChangePwPop()">取消</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="changePassWord()">确认修改</el-button>
      </div>
    </PopModel>

    <PopModel :title="'充值区域'" ref="chargeRef" @closeCheckPay="closeCheckPay">
      <div class="popBox">
        <div class="popTitleLine">
          当前状态
        </div>
        <div class="popContentBox">
          <span>系统版本:</span><span class="popTitleText">{{loginData.productTypeName == 'ForCommon' ? '标准版' : '维修定制版'}}</span>
          <span>有效时间:</span><span class="popTitleText">{{loginData.expireDate}}</span>
          <span>登录人数:</span><span class="popTitleText">{{loginData.userLimit}}个</span>

          <!-- <span>其他功能:</span><span class="popTitleText"></span> -->
        </div>
        <div class="popTitleLine">
          产品选择
        </div>
        <div class="popContentBox">
          <el-radio-group v-model="productInfo.productChoose" class="ml-4" :disabled="productInfo.activeDocId != ''">
            <el-radio label="ForCommon" size="large">标准版</el-radio>
            <el-radio label="ForRepair" size="large">维修定制版</el-radio>
          </el-radio-group>

          <el-tooltip class="box-item" placement="right">
            <template #content>
              1.新增维修机器管理<br />
              2.新增二手机回收管理<br />
              3.接入维修行业商品库<br />
            </template>
            <el-button>维修定制版说明</el-button>
          </el-tooltip>
        </div>
        <div class="popTitleLine">
          登录人数
        </div>
        <div class="popContentBox1">
          <el-input-number class="numInput" v-model="productInfo.personLimit" :min="productInfoCopy.personLimit" :max="productInfo.productChoose == '普通版' ? 1 : 100" :disabled="productInfo.activeDocId != ''" />
        </div>
        <div class="popTitleLine">
          购买年限
        </div>
        <div class="popContentBox">
          <el-radio-group v-model="productInfo.yearsToBuy" class="ml-4" :disabled="productInfo.activeDocId != ''">
            <el-radio label="0" size="large">不增加时间</el-radio>
            <el-radio label="1" size="large">1年</el-radio>
            <el-radio label="2" size="large">2年</el-radio>
            <el-radio label="3" size="large">3年</el-radio>
            <el-radio label="4" size="large">4年</el-radio>
            <el-radio label="5" size="large">永久</el-radio>
          </el-radio-group>
        </div>
        <div class="popTitleLine">
          结果显示
        </div>
        <div class="popContentBox">
          <span>付款金额:</span><span class="popTitleText">{{productInfo.moneyToPay}}</span>
          <span>付款后有效时间:</span><span class="popTitleText">{{productInfo.newExpireTime}}</span>
        </div>
        <div class="popTitleLine">
          支付方式
        </div>
        <div class="popContentBox2">
          <div class="alipay" @click="changePayType('alipay')" :class="{selectedPay:productInfo.payType == 'alipay'}"></div>
          <div class="wechatpay" @click="changePayType('wechatpay')" :class="{selectedPay:productInfo.payType == 'wechatpay'}"></div>
        </div>
        <div class="popContentBox3">
          <el-button type="info" @click="closeChargePop()">取消</el-button>
          <el-button type="primary" @click="goChargePop()">充值</el-button>
        </div>
        <div v-show="productInfo.activeDocId" class="quarCodeArea">
          <div>请扫码支付</div>
          <qrcode-vue class="quarCode" :value="productInfo.payCode" :size="256" level="H" />
        </div>
      </div>
    </PopModel>

    <PopModel ref="setUpCompanyPop" :title="'开设子门店'">
      <div class="inputBox">
        <label class="popLable">子店名称:</label>
        <el-input class="popInput" :maxlength="10" type="text" placeholder="请输入子店铺名称" v-model="RegisterViewModelUpdate.CompanyNameAbbre" />
      </div>
      <div class="inputBox">
        <label class="popLable">用户名:</label>
        <el-input class="popInput" :maxlength="10" type="text" placeholder="请输入用户名(推荐管理员)" v-model="RegisterViewModelUpdate.UserName" />
      </div>
      <div class="inputBox">
        <label class="popLable">手机号:</label>
        <el-input class="popInput" type="tel" :maxlength="11" @blur="checkNumber" placeholder="需要输入没有注册过的手机号码" v-model="RegisterViewModelUpdate.PhoneNo" />
      </div>
      <div class="inputBox">
        <label class="popLable">验证码:</label>
        <el-input class="popInput" type="tel" :maxlength="6" placeholder="请输入验证码" v-model="RegisterViewModelUpdate.CheckNo" />
        <div class="floatDiv">
          <span class="getMessageCode" v-show="waitTime == 0" @click="getTeliCode()">获取验证码</span>
          <span class="remarkContainer" v-show="waitTime > 0">{{waitTime}}秒后重新申请</span>
        </div>
      </div>
      <div class="inputBox">
        <label class="popLable">登录密码:</label>
        <el-input class="popInput" type="password" :maxlength="20" placeholder="请输入密码" v-model="RegisterViewModelUpdate.Password" />
      </div>
      <div class="inputBox">
        <label class="popLable">确认密码:</label>
        <el-input class="popInput" type="password" :maxlength="20" placeholder="请再次输入密码" v-model="RegisterViewModelUpdate.PasswordConfirm" />
      </div>
      <div class="inputBox">
        <label class="popLable">充值码:</label>
        <el-input class="popInput" type="text" :maxlength="20" placeholder="无充值码将以普通版登录" />
      </div>
      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight" @click="closeSetUpPop()">取消</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="goSetUpCompany()">注册</el-button>
      </div>
    </PopModel>

    <el-dialog v-model="dialogVisible" title="到期通知" width="30%" :before-close="handleClose">
      <span>{{showMessage}}</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">关闭</el-button>
          <el-button type="primary" @click="gocharge()">去充值</el-button>
        </span>
      </template>
    </el-dialog>

    <el-drawer v-model="drawer3" :direction="direction3" :before-close="handleClose3">
      <template #title>
        <h4>请提出您的建议,我们将尽快优化!!!</h4>
      </template>
      <template #default>
        <div>
          <el-input v-model="textarea" maxlength="200" placeholder="请输入建议" show-word-limit type="textarea" />
        </div>
        <div class="buttonBoxForSugg">
          <el-button type="primary" @click="updateSuggest()">提交</el-button>
        </div>
      </template>
      <!-- <template #footer>
      <div style="flex: auto">
        <el-button @click="cancelClick3">cancel</el-button>
        <el-button type="primary" @click="confirmClick3">confirm</el-button>
      </div>
    </template> -->
    </el-drawer>

  </div>
</template>
<script>
import { computed, provide, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import PopModel from '@/components/Common/PopModel.vue'
import MainManu from '@/components/Content/MainPageContent/MainManu.vue';
import _ from "lodash";
import { refreshLoginConfigData, changeToViewCode, sendChangePw, checkSetUpCondition, checkIsXl } from '@/hooks/OpenOrder'
import requestData from '@/hooks/requestData';
import { synchroniseInventoryReal } from '@/hooks/getCache';
import { changeDayToInt, ChangeYMD, GetNumberOfDays } from '@/hooks/tools';
import QrcodeVue from 'qrcode.vue'
import { ElMessageBox } from 'element-plus'
import { ElMessage } from 'element-plus'
export default {
  setup() {
    const store = useStore();


    let seeQuotation = ref(false)

    checkIsXl(store.state.loginInitializeData.companyId).then(res => {
      if (res == 'pass') {
        seeQuotation.value = true
      }
    })

    let RegisterViewModelUpdate = reactive({ CompanyNameAbbre: '', UserName: '', Password: '', PasswordConfirm: '', RechargeCode: '', PhoneNo: '', CheckNo: '', LowerCompany: true, UpperCompanyId: store.state.loginInitializeData.companyId });

    const loginData = store.state.loginInitializeData
    loginData.expireDate = ChangeYMD(loginData.expireDate)
    const router = useRouter()
    provide('tableTitle', '修改密码')
    const goLogin = () => {
      let b = confirm('确认退出')
      if (b) {
        router.replace({ path: "/login" });
        setTimeout(() => {
          store.commit('clearState')
        }, 1000)
      }
      else {
        return false;
      }
    }

    const changePwRef = ref(null)

    const synchroniseInventory = () => {
      synchroniseInventoryReal()
    }

    const openChangePwPop = () => {
      passwordArr.oldOne = ''
      passwordArr.newOne = ''
      passwordArr.confirmOne = ''
      changePwRef.value.openPopComm()
    }

    const closeChangePwPop = () => {
      changePwRef.value.closePopComm()
    }

    let passwordArr = reactive({
      oldOne: '',
      newOne: '',
      confirmOne: ''
    })

    const changePassWord = () => {

      if (passwordArr.newOne != passwordArr.confirmOne) {

        ElMessage('两次密码输入不一致，请重新输入')
        return;
      }

      sendChangePw(passwordArr.oldOne, passwordArr.newOne, passwordArr.confirmOne).then(res => {
        if (res == 'pass') {

          ElMessage('修改成功')
          changePwRef.value.closePopComm()
        }
        else {

          ElMessage(res)
        }
      }).catch(err => {

        ElMessage(err.message)
      })
    }

    const isShow = ref(false);
    const ShowContent = () => {
      isShow.value = true;
    };
    const CloseContent = () => {
      isShow.value = false;
    };

    const funcAfterRefresh = () => {
      store.commit('refreshShowWindow')
    }

    const refreshPage = () => {
      refreshLoginConfigData(funcAfterRefresh)
    };

    let searchContent = ref('');

    let searchResult = reactive([]);

    const searchFunctions = _.debounce((e) => {
      if (searchContent.value == '') {
        searchResult.splice(0)
        return false;
      }
      searchResult.splice(0)

      let searchResultCopy = store.state.loginInitializeData.viewListForSearch.filter(x => x.viewName?.indexOf(searchContent.value) > -1)
      for (let a of searchResultCopy) {
        let b = {}
        b.viewCode = a.viewCode
        b.viewName = a.viewName
        searchResult.push(b)
      }
    }, 500)
    const goFunction = (code) => {
      store.commit('GoFunctionWindowWithCode', code)
      closeSearch()
    }
    const closeSearch = () => {
      searchContent.value = ''
      searchResult.splice(0)
    }
    const goQuotation = () => {
      const code = changeToViewCode('QuotationForCustomer')
      store.commit('GoFunctionWindowWithCode', code)
    }

    const goMachineMarket = () => {
      const code = changeToViewCode('MachineMarket')
      store.commit('GoFunctionWindowWithCode', code)
    }
    const goReport = () => {
      const code = changeToViewCode('ReportShow')
      store.commit('GoFunctionWindowWithCode', code)
    }

    const goPriceManage = () => {
      const code = changeToViewCode('PriceManage')
      store.commit('GoFunctionWindowWithCode', code)
    }



    const goBarcodePrint = () => {
      const code = changeToViewCode('BanCodePrint')
      store.commit('GoFunctionWindowWithCode', code)
    }

    store.commit('updateBarcodeCount')

    const chargeRef = ref(null)

    let productInfo = reactive({
      productChoose: '',
      yearsToBuy: '0',
      personLimit: 1,
      payType: '',
      moneyToPay: 0,
      newExpireTime: '',
      activeDocId: '',
      payCode: '',
    })

    let productInfoCopy = {
      productChoose: '',
      yearsToBuy: '',
      personLimit: 1,
      payType: '',
      moneyToPay: 0
    }

    const openChargePop = () => {

      productInfo.productChoose = ''
      productInfo.yearsToBuy = '0'
      productInfo.personLimit = 1
      productInfo.payType = ''
      productInfo.moneyToPay = 0
      productInfo.newExpireTime = ''
      productInfo.activeDocId = ''
      productInfo.payCode = ''

      productInfoCopy.productChoose = ''
      productInfoCopy.yearsToBuy = ''
      productInfoCopy.personLimit = 1
      productInfoCopy.payType = ''
      productInfoCopy.moneyToPay = 0

      const config = {
        method: "get",
        url: "/Login/GetCompanyInfo",
        params: {
          companyId: loginData.companyId
        }
      };
      requestData.GetData(config).then(res => {
        productInfo.productChoose = res?.productTypeName
        productInfo.personLimit = res?.userLimit
        productInfoCopy.productChoose = res?.productTypeName
        productInfoCopy.personLimit = res?.userLimit
      })
      chargeRef.value.openPopComm()
    }


    const dialogVisible = ref(false)

    const doneClose = () => {
      dialogVisible.value = false;
    }

    const handleClose = () => {
      ElMessageBox.confirm('确认关闭?')
        .then(() => {
          doneClose()
        })
        .catch(() => {
          // catch error
        })
    }

    let showMessage = ref('')

    const gocharge = () => {
      dialogVisible.value = false
      openChargePop()
    }

    const config = {
      method: "get",
      url: "/Login/GetCompanyInfo",
      params: {
        companyId: loginData.companyId
      }
    };
    requestData.GetData(config).then(res => {
      const b = GetNumberOfDays(new Date(), res.expireDate)
      if (b <= 5) {
        dialogVisible.value = true
        showMessage.value = `${b + 1}天后软件将到期,届时将无法登录,请及时充值！`
      }
    })


    const closeChargePop = () => {
      chargeRef.value.closePopComm()
    }



    const changePayType = (tpye) => {
      if (productInfo.activeDocId != '') {
        return
      }
      productInfo.payType = tpye
    }

    watch(productInfo, (newValue, oldValue) => {
      const config = {
        method: "get",
        url: "/Register/CheckChargeMoney",
        params: {
          companyId: loginData.companyId,
          productChoose: newValue.productChoose,
          yearsToBuy: newValue.yearsToBuy,
          personLimit: newValue.personLimit
        }
      };
      requestData.GetCache(config).then(res => {
        if (res.alertMessage == 'pass') {
          productInfo.moneyToPay = res?.moneyToPay
          productInfo.newExpireTime = ChangeYMD(res?.newExpireTime)
        }
      })
    })

    let checkId;

    const closeCheckPay = () => {
      if (checkId) {
        clearInterval(checkId)
      }
    }

    const goChargePop = () => {
      if (productInfo.moneyToPay <= 0) {
        if (productInfo.productChoose != store.state.loginInitializeData.productTypeName) {
          const ab = confirm('是否变更产品类型')
          if (ab) {
            const config3 = {
              method: "get",
              url: "/Register/ChangeProduct",
              params: {
                companyId: loginData.companyId,
                productChoose: productInfo.productChoose,
              }
            };
            requestData.GetData(config3).then(res => {
              if (res == 'pass') {
                ElMessage('请重新登录')
                goLogin()
                return
              }
              else {
                ElMessage(res)
                return
              }
            })
          }
          else {
            return
          }
        }
        else {
          ElMessage('请确认购买内容')
          return;
        }
      }
      else {
        if (productInfo.payType == '') {
          ElMessage('请选择付款方式')
          return;
        }

        let urlFinal = productInfo.payType == 'wechatpay' ? 'GetPayUrlApi' : 'GetPayUrlAlipay'

        const config = {
          method: "get",
          url: "/Register/" + urlFinal,
          params: {
            companyId: loginData.companyId,
            productChoose: productInfo.productChoose,
            yearsToBuy: productInfo.yearsToBuy,
            personLimit: productInfo.personLimit,
            price: productInfo.moneyToPay
          }
        };
        requestData.GetCache(config).then(res => {
          if (productInfo.payType == 'wechatpay') {
            const resArr = res.split('|')
            if (resArr[0] == 'fail') {
              ElMessage('未知错误,请稍后重试')
              return;
            }
            productInfo.activeDocId = resArr[0]
            productInfo.payCode = resArr[1]

            const config2 = {
              method: "get",
              url: "/Register/GetOrderStatus",
              params: {
                docId: productInfo.activeDocId,
              }
            }

            checkId = setInterval(() => {
              requestData.GetCache(config2).then(res => {
                if (res == 'pass') {
                  closeCheckPay()
                  ElMessage('付款成功,请重新登录')
                  router.replace({ path: "/login" });
                  setTimeout(() => {
                    store.commit('clearState')
                  }, 1000)
                }
              })
            }, 300)
          }
          if (productInfo.payType == 'alipay') {
            document.write(res)
          }
        })
      }
    }

    const setUpCompanyPop = ref(null)


    const openSetUpLowerCompanyPop = () => {
      checkSetUpCondition(store.state.loginInitializeData.userId).then(res => {
        if (res != 'pass') {
          ElMessage(res)
          return
        }
        else {
          setUpCompanyPop.value.openPopComm()
        }
      })
    }

    const closeSetUpPop = () => {
      setUpCompanyPop.value.closePopComm()
    }

    let waitTime = ref(0);

    let idForTime;

    const myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;

    const checkNumber = (e) => {

      if (!myreg.test(e.target.value) && e.target.value != '') {
        ElMessage('手机号码填写有误')
        RegisterViewModelUpdate.PhoneNo = ''
        return;
      }
    }

    const getTeliCode = () => {
      if (!myreg.test(RegisterViewModelUpdate.PhoneNo)) {
        ElMessage('手机号码填写有误')
        return;
      }
      if (waitTime.value > 0) {
        return
      }

      const config = {
        method: "get",
        url: "/Register/GetMessageCode",
        params: {
          telNo: RegisterViewModelUpdate.PhoneNo,
        }
      };
      requestData.GetCache(config).then(res => {
        if (res == 'pass') {
          waitTime.value = 60
          idForTime = setInterval(() => {
            if (waitTime.value == 0) {
              if (idForTime) {
                clearInterval(idForTime)
              }
              return
            }
            waitTime.value--;
          }, 1000)
        }
        else {
          ElMessage(res)
          return
        }
      })
    }

    const goSetUpCompany = () => {

      if (RegisterViewModelUpdate.CompanyNameAbbre == '') {

        ElMessage('公司名称不能为空');
        return false;
      }
      if (RegisterViewModelUpdate.UserName == '') {

        ElMessage('用户名不能为空');
        return false;
      }
      if (RegisterViewModelUpdate.Password == '') {

        ElMessage('密码不能为空');
        return false;
      }
      // if (data.RechargeCode == '') {

      //   ElMessage('验证码不能为空');
      //   return false;
      // }
      if (RegisterViewModelUpdate.Password != RegisterViewModelUpdate.PasswordConfirm) {

        ElMessage('2次密码不一致');
        return false;
      }

      if (RegisterViewModelUpdate.CompanyNameAbbre.length > 10 && RegisterViewModelUpdate.CompanyNameAbbre.length < 2) {

        ElMessage('公司简称需要在2到10个字之间');
        return false;
      }
      if (RegisterViewModelUpdate.UserName.length > 20 && RegisterViewModelUpdate.UserName.length < 2) {

        ElMessage('用户名需要在2到20个字符之间');
        return false;
      }
      if (RegisterViewModelUpdate.Password.length > 20 && RegisterViewModelUpdate.Password.length < 5) {

        ElMessage('密码需要在5到20个字符之间');
        return false;
      }
      // if (data.Password != data.PasswordConfirm) {

      //   ElMessage('2次密码不一致');
      //   return false;
      // }

      let config = {
        method: "post",
        url: "/Register/Add",
        data: RegisterViewModelUpdate,
      };
      requestData
        .GetData(config)
        .then(res => {
          if (res.indexOf('注册成功') > -1) {

            const copyMessage = `店铺名称:${RegisterViewModelUpdate.CompanyNameAbbre}\n用户名:${RegisterViewModelUpdate.UserName}\n密码:${RegisterViewModelUpdate.Password}`

            ElMessage(res + '\n' + copyMessage)

            setUpCompanyPop.value.closePopComm()
          } else {
            ElMessage(res)
          }
        }).catch(err => {
          ElMessage(err);
        })
    }

    const direction3 = ref('ttb')

    let drawer3 = ref(false)

    const handleClose3 = () => {
      ElMessageBox.confirm('确认关闭?')
        .then(() => {
          drawer3.value = false
        })
        .catch(() => {
          // catch error
        })
    }

    let textarea = ref('')

    const updateSuggest = () => {
      if (!textarea.value) {
        ElMessage('请填入内容')
        return;
      }

      const suggestObj = {
        SuggestionType: '优化建议',
        SuggestionContent: textarea.value,
        UserId: store.state.loginInitializeData.userId,
        UserName: store.state.loginInitializeData.userName,
      }

      const config = {
        method: "post",
        url: "/CustomerSuggestionList/Add",
        data: suggestObj
      }

      requestData.GetData(config).then(res => {
        if (res != 'pass') {
          ElMessage(res)
        }
        else {
          ElMessage('上传成功,我们将尽快修改')
          drawer3.value = false
        }
      })
    }

    // const cancelClick3 = () => {
    //   drawer3.value = false
    // }

    // const confirmClick3 = () => {
    //   ElMessageBox.confirm('确认?')
    //     .then(() => {
    //       drawer3.value = false
    //     })
    //     .catch(() => {
    //       // catch error
    //     })
    // }






    return {
      isShow,
      ShowContent, openChangePwPop, changePwRef, chargeRef,
      CloseContent, goBarcodePrint, goPriceManage,
      goLogin, loginData, productInfo,
      refreshPage,
      searchContent, goChargePop,
      searchFunctions, closeCheckPay,
      searchResult, changePayType, productInfoCopy,
      goFunction, synchroniseInventory, openChargePop, closeChargePop,
      closeSearch, goQuotation, goMachineMarket, passwordArr, closeChangePwPop, changePassWord, goReport,
      dialogVisible, handleClose, showMessage, gocharge, openSetUpLowerCompanyPop, setUpCompanyPop,
      waitTime, idForTime, checkNumber, getTeliCode, RegisterViewModelUpdate, closeSetUpPop, goSetUpCompany,
      direction3, drawer3, handleClose3, textarea, updateSuggest, seeQuotation


    };
  },
  components: {
    MainManu,
    PopModel,
    QrcodeVue
  },
};
</script>
<style scoped lang="less">
@mainColor: #409eff;
.TopAreaContainer {
  width: 100%;
  /* background-color: red; */
  /* width: 100%; */
  height: 5.63rem;
}

.TopLeft,
.TopCenter {
  float: left;
}
.TopRight {
  float: right;
}

.TopLeft {
  width: 16rem;
  height: 100%;
  background-image: url("~@/assets/image/TopAreaImage/logo.png");
  background-size: 16% 50%;
  background-position: 1.25rem 1.13rem;
  line-height: 5.63rem;
  text-indent: 5rem;
  color: #fff;
  font-size: 0.8rem;
  word-break: break-all;
}
.TopLeft h2 {
  font-weight: 400;
}

.TopRight {
  position: relative;
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.TopRight img {
  position: absolute;
  top: 2.5rem;
  left: 1.25rem;
}
.refreshTop {
  position: absolute;
  top: 2.7rem;
  left: -4rem;
  color: #fbb80d;
  font-size: 1rem;
}

.TopRight img:hover {
  cursor: pointer;
  transform: translateY(-1px);
}

.ShowUserName {
  position: absolute;
  top: 2.38rem;
  z-index: 2;
}

.ShowUserName dt::after {
  content: "";
  font-family: "icomoon";
  position: absolute;
  right: 0px;
  font-size: 1rem;
  top: 0px;
}
dt,
dd {
  height: 1.88rem;
  text-align: center;
  padding: 0px 1.25rem;
  line-height: 1.88rem;
  cursor: pointer;
}

dd {
  background-color: #fff;
  color: #000;
}

.TopCenter {
  width: calc(100% - 16rem - 15%);
  position: relative;
}

.searchResult {
  position: absolute;
  top: 2.8rem;
  left: 8.01rem;
  z-index: 100;
}
.searchResultItems {
  font-size: 1rem;
  background-color: #f2f2f2;
  margin-top: 0.1rem;
  width: 13.5rem;
  text-indent: 0.5rem;
  height: 1.5rem;
}
.iconShow {
  font-family: "icomoon";
  text-align: center;
  line-height: 1.5rem;
  font-size: 0.5rem;
  padding-right: 0.4rem;
  color: @mainColor;
}
.closeSearch {
  font-family: "icomoon";
  text-align: center;
  line-height: 1.5rem;
  font-size: 1rem;
  color: #fff;
  width: 2rem;
  position: absolute;
  right: 0px;
  margin-top: 0.1rem;
  background-color: #cc0010;
  border-radius: 0.3rem;
}

.searchResultItems:hover,
.closeSearch:hover {
  cursor: pointer;

  transform: translateY(-1px);
}
.searchResultItems:hover {
  background-color: #e1f5fd;
}

.CenterTop {
  display: flex;
  justify-self: start;
  height: 3.38rem;
  align-items: center;
}

.CenterTopLeft {
  position: relative;
  width: 2.88rem;
  height: 2.25rem;
  background-color: #fff;
  margin-left: 5rem;
  border-top-left-radius: 0.63rem;
  border-bottom-left-radius: 0.63rem;
  font-size: 1rem;
}
.CenterTopLeft::after {
  position: absolute;
  content: "";
  font-family: "icomoon";
  top: 0.4rem;
  left: 1.88rem;
  color: #409eff;
}
.CenterTopCenter {
  width: 15rem;
  height: 2.25rem;
  margin-right: -0.63rem;
}
.CenterTopCenter input {
  width: 15rem;
  height: 2.25rem;
  border: none;
  outline: none;
  text-indent: 0.63rem;
}

.CenterTopRight {
  position: relative;
  width: 6rem;
  height: 2.25rem;
  background-color: #ffb30f;
  border-radius: 10px;
  text-align: center;
  line-height: 2.25rem;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
}

// .CenterTopRight::after {
//   position: absolute;
//   content: "";
//   font-family: "icomoon";
//   right: 1.2rem;
//   top: 0.1rem;

// }

.CenterBottom {
  height: 2.25rem;
  display: flex;
  justify-self: start;
}

.Usermanu {
  border-radius: 5px;
  margin-top: 0.1rem;
}

.Usermanu:hover {
  background-color: red;
  color: white;
  transform: translateY(-0.1rem);
}

.quotationFuncion {
  white-space: nowrap;
  margin-left: 2rem;
  font-size: 1rem;
  color: #fff;
  border-bottom: 1px solid #ff0052;
  cursor: pointer;
}
.popInput {
  margin-top: 0.5rem;
  width: 28rem;
  height: 2rem;
}
.popLable {
  display: block;
  margin-top: 0.5rem;
  line-height: 2.5rem;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 0.5rem;
  width: 5rem;
}
.buttonBox {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  justify-content: flex-end;
}
.barcodeBox {
  position: relative;
}

.barcodeCount {
  top: -5px;
  left: 4rem;
  position: absolute;
  background-color: #ed1c24;
  border-radius: 10px;
  padding: 1px;
  font-size: 0.6rem;
}
.popBox {
  width: 100%;
}
.popTitleLine {
  text-indent: 2rem;
  font-weight: 700;
}
.popContentBox {
  display: flex;
  width: 98%;
  margin-left: 1%;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  text-indent: 1rem;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}
.popContentBox1 {
  width: 98%;
  margin-left: 1%;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  // text-indent: 1rem;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}
.popContentBox2 {
  display: flex;
  width: 98%;
  margin-left: 1%;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  text-indent: 1rem;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}
.popTitleText {
  margin-right: 1rem;
  color: #ff0000;
}
.numInput {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
.alipay {
  position: relative;
  height: 4rem;
  width: 7rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  margin-left: 1rem;
  background-image: url("~@/assets/image/pay/alipay.png");
  background-size: 100% 100%;
  opacity: 0.5;
}
.wechatpay {
  position: relative;
  height: 4rem;
  width: 7rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  margin-left: 2rem;
  background-image: url("~@/assets/image/pay/wechatpay.png");
  background-size: 100% 100%;
  opacity: 0.5;
}
.alipay:hover,
.wechatpay:hover {
  cursor: pointer;
}
.selectedPay {
  opacity: 1;
}
.selectedPay::after {
  position: absolute;
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  font-family: "icomoon";
  color: white;
  right: 0rem;
  top: 0rem;
  text-indent: 0.3rem;
}

.popContentBox3 {
  display: flex;
  justify-content: flex-end;
  width: 98%;
  margin-left: 1%;
  text-indent: 1rem;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}
.popContentBox b {
  color: red;
}
.quarCodeArea {
  text-align: center;
}
.box-item {
  border: none;
  margin-left: 0.5rem;
}
.inputBox {
  display: flex;
  position: relative;
  height: 3rem;
}
.getMessageCode:hover {
  cursor: pointer;
}
.getMessageCode {
  color: #409eff;
}
.remarkContainer {
  font-size: 0.8rem;
}
.floatDiv {
  position: absolute;
  left: 20rem;
  top: 0.9rem;
}
.buttonBoxForSugg {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
.moreFuncWord {
  font-size: 1rem;
}
.moreFuncIcon {
  font-size: 1rem;
  margin-left: 0.4rem;
}
.moreFuncBox {
  margin-left: 2rem;
}
</style>