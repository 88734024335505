<template>
  <div class="InputForChooseContainer"><label class="InputForChooseLabel">条码/序列号:</label><input autofocus="autofocus" class="InputForChooseLong" type="text" @keyup.enter="scanIn" v-model="scanInValue" placeholder="快 速 扫 描 区"></div>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue-demi'
import requestData from "@/hooks/requestData.js";
export default {
  props: ['scanInValue'],
  setup(props, { emit }) {
    //d与扫描框绑定的数据
    let scanInValue = ref(props.scanInValue)

    //f扫描方法
    const scanIn = (e) => {
      let config = {
        method: "get",
        url: "/CommodityMangeTable/ScanCheck",
        params: {
          code: e.target.value
        }
      }
      requestData.GetCache(config).then(res => {
        if (e.target.value == '') {
          return false;
        }
        if (res == 'notfound') {
          alert('条码不存在')
          scanInValue.value = ''
        }
        else {
          emit('scanIn', res)
          scanInValue.value = ''
        }
      })
    }

    onUnmounted(() => {

    })
    //#endregion

    return {
      scanIn,
      scanInValue
    }
  }
}
</script>

<style scoped lang="less">
.InputForChooseContainer {
  margin-left: 0.4rem;
}
.InputForChooseLong:focus {
  border-left: 1px solid #ffa004;
  border-right: 1px solid #ffa004;
  border-top: 1px solid #ffa004;
}
.InputForChooseLong {
  border-bottom: 3px solid #0000ff;
  height: 2.5rem;
}

.InputForChooseLabel {
  line-height: 2.5rem;
  font-size: 1.5rem;
  font-weight: 700;
}
</style>