<template>
  <div class="inputLoaderContainer">
    <BodyTitle></BodyTitle>
    <InputHead ref="rrInputHeadRef"></InputHead>
    <InputBody ref="inputBodyRef" @clearScanIn="clearScanIn"></InputBody>
    <div class="EditTableLine">
      <ComScanIn :scanInValue="scanInValue" @scanIn="scanIn"></ComScanIn>
    </div>
    <div class="EditTableLastLine">
      <!-- 维护第七层的内容 -->
      <!-- <div class="EditTableFuncButton" :style="{background:'#73a6a3'}" @click="openPopUp()">导入订单明细</div> -->
      <el-button class="openOrderButton" type="primary" @click="openList('RMIS')" v-show="$store.state.loginInitializeData.authList.buttonVisibleManages.find(x=>x.buttonId == 'RrInputMoAndIs')?.visibleOrNot">退款并入库</el-button>
      <el-button class="openOrderButton" type="primary" @click="openList('RM')">仅退款</el-button>
    </div>
  </div>
</template>
<script>
import { computed, provide, ref, watch } from 'vue-demi'
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import InputHead from '@/components/Content/MainBodyContent/InputHead.vue'
import InputBody from '@/components/Content/MainBodyContent/InputBody.vue'
import { getDocNo, deleteDocCenter, getLoginData, updateWarning, checkDocCondition, transferToCode, openRrList, clearTableContent, getDocType, transferToName, addMoneyToBePaid } from '@/hooks/OpenOrder'
import { transferLoginDataOnly } from '@/hooks/loginAffair';
import ComScanIn from '@/components/Content/FunctionUnit/ComScanIn.vue'
import InputTable from '@/hooks/InputTable'
import { useStore } from "vuex";
import { isRealNum } from '@/hooks/tools'
import { ElMessage } from 'element-plus'
export default {
  components: {
    BodyTitle,
    InputHead,
    InputBody,
    ComScanIn
  },
  setup(props, { emit }) {
    const store = useStore();
    const RootTableName = 'RrList';
    let { itemListHead, itemListBody, searchItemList } = InputTable(RootTableName)
    searchItemList.docTitleTwo = 'RR'
    searchItemList.TableName = 'RRInput'
    provide('searchItemList', searchItemList)

    let inputBodyRef = ref(null)


    //#endregion

    //#region 扫描组件
    let scanInValue = ref('');

    const scanIn = (res) => {
      inputBodyRef.value.scanInData(res)
    }

    const clearScanIn = () => {
      scanInValue.value = '';
    }

    const rrInputHeadRef = ref(null)

    setTimeout(() => {
      rrInputHeadRef.value.searchItemList.documentContent.PartBCompanyId = '散客'
      // rrInputHeadRef.value.searchItemList.documentContent.InWareHouseId = '主仓库'
    }, 0);


    //#endregion

    //#region 开单功能模块


    const openList = (typeName) => {

      let errCheck = checkDocCondition(searchItemList.documentContent, itemListHead, inputBodyRef.value.AddContentBody, itemListBody)
      if (errCheck.headErr) {

        ElMessage(errCheck.headErr)
        return
      }
      if (errCheck.bodyErr) {

        ElMessage(errCheck.bodyErr)
        return
      }
      let stockCheck = typeName == 'RMIS' ? true : false
      let partBCheck = searchItemList.documentContent.PartBCompanyId == '散客' ? true : false
      if (stockCheck) {
        let errMsgForVir = ''
        for (let item of inputBodyRef.value.AddContentBody) {
          if (item.virtualGoods) {
            errMsgForVir += `${item.commodityName}为虚拟物品,不会做入库处理\n`
          }
        }
        if (errMsgForVir != '') {
          ElMessage(errMsgForVir)
        }
      }
      getLoginData().then(res => {
        //刷新初始化数据
        if(res != 0){
          transferLoginDataOnly(res)
        }
        getDocNo(searchItemList.docTitleTwo).then(res => {
          const rrHeadCode = res
          const partBCom = transferToCode(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId')
          const partACom = store.state.loginInitializeData.companyId
          const staffId = transferToCode(searchItemList.documentContent.StaffId, 'StaffId')
          const user = store.state.loginInitializeData.userName
          const InWareHouseName = isRealNum(searchItemList.documentContent.InWareHouseId) ? transferToName(searchItemList.documentContent.InWareHouseId, 'WareHouseId') : searchItemList.documentContent.InWareHouseId
          let CommodityNameArr = []
          let amount = 0;
          for (let item of inputBodyRef.value.AddContentBody) {
            if (item.CommodityName) {
              amount += item.OrderQty * item.UnitPrice
            }
          }
          for (let item of inputBodyRef.value.AddContentBody) {
            if (item.CommodityName) {
              item.PartACompanyId = partACom
              item.PartBCompanyId = partBCom
              item.OutStockQty = item.OrderQty
              item.InStockQty = item.OrderQty
              item.DocumentId = rrHeadCode
              item.RootSourceDocumentId = rrHeadCode
              item.Remark += `销售退款了${item.OrderQty}个${item.CommodityName}`
              if (!item.VirtualGoods && item.CommodityId) {
                CommodityNameArr.push(item.CommodityId)
              }
            }
          }
          openRrList(inputBodyRef.value.AddContentBody, searchItemList.documentContent, rrHeadCode, stockCheck, partBCheck).then(res => {
            if (res != 'pass') {
              ElMessage(res)
              deleteDocCenter(rrHeadCode).then(res => {
                return false;
              })
            }
            else {
              if (stockCheck && InWareHouseName == '主仓库') {
                setTimeout(() => {
                  updateWarning(partACom, CommodityNameArr)
                }, 0);
              }
              if (partBCheck && amount) {
                const body = {
                  DocumentId: rrHeadCode,
                  PartACompanyId: partACom,
                  PartBCompanyId: partBCom,
                  StaffId: staffId,
                  DocumentType: getDocType(rrHeadCode),
                  Amount: amount,
                  Remark: `操作人:${transferToName(staffId, 'StaffId')}开立了一张销售退款单,单号是${rrHeadCode},需要支付${amount}元,操作账号:${user}`
                }
                addMoneyToBePaid(body).then(res => {
                  ElMessage({
                message:'开单成功',
                duration:2000,
                showClose:true
              })
                  clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                  emit('refreshMsg')
                })
              }
              else {
                ElMessage({
                message:'开单成功',
                duration:2000,
                showClose:true
              })
                clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                emit('refreshMsg')
              }
            }
          })
        })
      }).catch(err => {

        ElMessage(err.message)
      })
    }
    //#endregion

    //#endregion

    return {
      searchItemList,
      inputBodyRef,
      scanInValue,
      scanIn,
      clearScanIn,
      openList,
      rrInputHeadRef
    }
  }
}
</script>
<style scoped lang="less">
.EditTableLastLine button {
  margin-right: 1rem;
}
.openOrderButton {
  margin-right: 3rem !important;
}
</style>