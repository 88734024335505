<template>
  <div class="tableLoaderContainer">

    <div class="BodyTitle">
      <div class="BodyTitleText">客户预定跟踪</div>
    </div>

    <div class="searchContentContainer">
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="StartTypeForMaterialSaleList">零件到货:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'StartType'}"><select v-model="SearchContent.ArrivalStatus" class="searchinput" id="StartTypeForMaterialSaleList" @focus="setActiveId('StartType')" @blur="setActiveId('')" @change="reSearch()">
            <option value="">所有</option>
            <option value="101">未到</option>
            <option value="102">部分到货</option>
            <option value="103">已到</option>
          </select></div>
      </div>
      <!-- 搜索模块 -->
      <div class="FuncButton" @click="searchList()">搜索</div>
      <div class="tipBox">
        <div class="tipColortodo"></div>
        <div class="tipWords">待处理</div>
      </div>
      <div class="tipBox">
        <div class="tipColorfinish"></div>
        <div class="tipWords">已通知</div>
      </div>
    </div>

    <div class="BigTable">
      <div class="TableContentArea">
        <table class="tablehead">
          <tr class="headtr" :style="{ height : trHeight }">
            <th class="thSN">
              <div :style="{ width: SnWidth }"></div>
            </th>
            <th class="thselectall">
              <div :style="{ width: selectAllWidth }"><input class="selectAllCheckBox" type="checkbox" v-model="selectAll" /></div>
            </th>
            <th>
              <div class="thoper" :style="{ width: operationWidth }">操作</div>
            </th>
            <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'head'" :class="{  ShowUpArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == 1, ShowDownArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == -1,}" @click.stop="ChangeSort(value1.fieldsNameForVali)">
              <div :style="{ width: value1.showLen  + 'rem' }">{{ value1.showName }}</div>
            </th>
          </tr>
        </table>
        <div class="tablebodyarea" :style="{ width: tablewidth  + 'rem',height:tbodyHeight}">
          <table class="tablebody">
            <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'td' + index2" :class="[{todoTr:value2.arrivalStatus != '未到店' && value2.callCustomerTimes == 0},{finishTr:value2.arrivalStatus != '未到店' && value2.callCustomerTimes > 0}]">
              <td>
                <div class="tdSN" :style="{ width: SnWidth }">{{+index2 + 1}}</div>
              </td>
              <td class="tableselect">
                <div :style="{ width: selectAllWidth }"><input type="checkbox" :value="value2['obTracingCode']" v-model="selectedItems" /></div>
              </td>
              <td>
                <div class="tdoper" :style="{ width: operationWidth }">
                  <span class="spanedit" @click="remindCustomer(index2)">通知客户</span>
                  <span class="spandele" @click="closeChasingNegative(index2)" v-show="value2.customerDeposit > 0">退款关闭</span>
                  <span class="spandele" @click="closeChasingPositive(index2)">完成关闭</span>
                  <span class="spanedit" @click="goRoListFromObTracing(value2)">去开单</span>
                  <span class="spanedit" v-show="value2.arrivalStatus == '未到店'" @click="rebuildBaCode(value2)">标签</span>
                </div>
              </td>
              <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
                <div :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
              </td>
            </tr>
          </table>
        </div>
        <table class="tablefoot">
          <tr class="foottr" :style="{ height : trHeight }">
            <td>
              <div class="footsum" :style="{ width: SnWidth }">合计</div>
            </td>
            <td>
              <div :style="{ width: selectAllWidth }"></div>
            </td>
            <td>
              <div :style="{ width: operationWidth }"></div>
            </td>
            <td v-for="value4 in tableHead" :key="value4.fieldsNameForVali + 'foot'">
              <div :style="{ width: value4.showLen  + 'rem' }"></div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="moreFunctionBox">
      <!-- <div class="FuncButton" @click="goInvenChange()">库存变动查询</div> -->
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue-demi';
import { useStore } from "vuex";
import {
  ChangeFirToLo, ChangeFirToUp, ChangeYMD, changeArrContentToDate, copyArray, getUUId
} from '@/hooks/tools';
import requestData from '@/hooks/requestData';
import { fillDefaultValue, changeToViewCode, addRemindTime, closeObChasing, getDocType, openErList, getDocNo, transferToCode, addMoneyToBePaid, transferToName } from '@/hooks/OpenOrder'
import { lockObTracing, unLockObTracing } from '@/hooks/LockAffair'
import { ElMessage } from 'element-plus'
export default {
  setup(props, { emit }) {
    //#region 定义区
    const store = useStore();

    const filterList = store.state.loginInitializeData.filterList

    const SponserToBeSolveFSFilter = filterList.SponserToBeSolveFS

    //初始化输入表名称
    const TableName = 'ObTracingList';

    const PageName = 'ObTracingList'

    const code = changeToViewCode(PageName)

    //#endregion

    //#region 全选
    let selectAll = ref(false);

    let selectedItems = ref([]);

    watch(selectAll, (newValue, oldValue) => {

      if (code == store.state.loginInitializeData.ShowWindow[0]) {
        if (newValue == true) {
          selectedItems.value.splice(0);
          for (let item of tableBody) {
            selectedItems.value.push(item['obTracingCode']);
          }
        } else {
          selectedItems.value.splice(0);
        }
      }
    });
    //#endregion

    let activeInput = ref('');

    const setActiveId = (id) => {
      activeInput.value = id
    }
    let selectAllWidth = ref('4rem')
    let trHeight = ref('1.875rem')
    let SnWidth = ref('3.75rem')
    let operationWidth = ref('25rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')

    let SearchContent = reactive({
      ObTracingCode: '',
      DocumentId: '',
      PartACompanyId: '',
      DocumentDateTimeH: '',
      DocumentDateTimeL: '',
      CommodityName: '',
      ArrivalStatus: '',
      CustomerName: '',
      CustomerContacts: '',
      CustomerDeposit: '',
      StaffId: '',
      CallCustomerTimes: '',
      CallDate: '',
      Remark: '',
    })
    fillDefaultValue(SearchContent, TableName)

    //f计算表格内容总宽度
    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) + parseFloat(operationWidth.value) + parseFloat(selectAllWidth.value) + 1.25 + tableHead.length * 1 + 0.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });

    let tableHead = reactive([
      // { fieldsNameForVali: 'ObTracingCode', showLen: 80, showName: '预定跟踪码' },
      // { fieldsNameForVali: 'PartACompanyId', showLen: 80, showName: '甲方公司Id' },
      { fieldsNameForVali: 'callCustomerTimes', showLen: 6, showName: '通知次数' },
      { fieldsNameForVali: 'callDate', showLen: 14, showName: '最新通知时间' },
      { fieldsNameForVali: 'commodityName', showLen: 30, showName: '商品名称' },
      { fieldsNameForVali: 'arrivalStatus', showLen: 7, showName: '商品到货' },
      { fieldsNameForVali: 'customerName', showLen: 10, showName: '客户姓名' },
      { fieldsNameForVali: 'customerContacts', showLen: 10, showName: '联系方式' },
      { fieldsNameForVali: 'documentDateTime', showLen: 14, showName: '预定时间' },
      { fieldsNameForVali: 'customerQuotation', showLen: 5, showName: '报价' },
      { fieldsNameForVali: 'customerDeposit', showLen: 5, showName: '订金' },
      { fieldsNameForVali: 'staffId', showLen: 7.5, showName: '负责人' },
      { fieldsNameForVali: 'remark', showLen: 12.5, showName: '备注' },
      { fieldsNameForVali: 'documentId', showLen: 16, showName: '预定单号' },
    ]);

    const searchElement = reactive({
      SortFieldsName: 'DocumentDateTime',
      SortDirection: 1
    })

    let tableBody = reactive([]);

    let ChangeSort = (fildsname) => {
      searchElement.SortFieldsName = ChangeFirToUp(fildsname)
      searchElement.SortDirection = -searchElement.SortDirection
      searchList()
    };

    const remindCustomer = (index) => {

      if (tableBody[index].arrivalStatus == '未到店') {
        let b = confirm('零件未到货,是否通知客户?')
        if (!b) {

          return;
        }
      }
      if (tableBody[index].arrivalStatus != '未到店') {
        let b = confirm('是否通知客户?')
        if (!b) {

          return;
        }
      }
      addRemindTime(tableBody[index].obTracingCode).then(res => {
        if (res == 'pass') {

          searchList()
        }

      }).catch(err => {

        ElMessage(err.message)
      })
    }

    const closeChasingPositive = (index) => {

      let b = confirm('该预定未绑定零售开单,是否提前关闭?')
      if (!b) {

        return;
      }
      let obArr = []
      const obTracingCode = tableBody[index].obTracingCode
      obArr.push(obTracingCode)
      lockObTracing(obArr).then(res => {
        try {
          if (res != 'pass') {

            ElMessage(res)
            return;
          }
          if (tableBody[index].customerDeposit) {
            getDocNo('ER').then(res => {
              const erHeadId = res
              const partBId = transferToCode('散客', 'PartBCompanyId')
              let body = [{
                DocumentId: erHeadId,
                Sn: 1,
                PartACompanyId: tableBody[index].partACompanyId,
                PartBCompanyId: partBId,
                OrderQty: 1,
                OutStockQty: 1,
                UnitPrice: tableBody[index].customerDeposit,
                Remark: '',
                SourceDocumentId: '',
                RootSourceDocumentId: erHeadId,
                ModifiedTimes: 0,
              }]
              let docHead = {
                DocumentId: erHeadId,
                DocumentTypeName: '',
                DocumentDate: '',
                DocumentDateTime: '',
                Online: '',
                PartACompanyId: tableBody[index].partACompanyId,
                PartBCompanyId: partBId,
                UserId: '',
                StaffId: tableBody[index].staffId,
                SourceDocumentId: '',
                Amount: tableBody[index].customerDeposit,
                Qty: 1,
                OutWareHouseId: '',
                InWareHouseId: '',
                SystemStatusId: '',
                CapitalAccountId: '',
                Remark: '',
                RootSourceDocumentId: erHeadId,
              }
              openErList(body, docHead, erHeadId).then(res => {
                closeObChasing(obTracingCode).then(res => {
                  if (res == 'pass') {

                    searchList()
                    emit('refreshMsg')
                  }
                  else {

                    ElMessage(res)
                    searchList()
                    emit('refreshMsg')
                  }
                })
              })
            })
          }
          else {
            closeObChasing(obTracingCode).then(res => {
              if (res == 'pass') {
                searchList()
                emit('refreshMsg')
              }
              else {

                ElMessage(res)
                searchList()
                emit('refreshMsg')
              }
            })
          }

        } catch (error) {


        }

        finally {
          unLockObTracing(obArr)
        }




      }).catch(err => {
        ElMessage(err)
        searchList()
        emit('refreshMsg')
      })
    }

    const closeChasingNegative = (index) => {

      let b = confirm('确定提前关闭并退款？如果无订金将会直接关闭！')
      if (!b) {

        return;
      }
      let obArr = []
      const obTracingCode = tableBody[index].obTracingCode
      obArr.push(obTracingCode)
      lockObTracing(obArr).then(res => {
        try {
          if (res != 'pass') {

            ElMessage(res)
            return;
          }
          if (tableBody[index].customerDeposit) {
            const partBId = transferToCode('散客', 'PartBCompanyId')
            const body = {
              DocumentId: tableBody[index].documentId,
              PartACompanyId: tableBody[index].partACompanyId,
              PartBCompanyId: partBId,
              StaffId: transferToCode(tableBody[index].staffId, 'StaffId'),
              DocumentType: getDocType(tableBody[index].documentId),
              Amount: tableBody[index].customerDeposit,
              Remark: `客户预定退款，操作人:${tableBody[index].staffId}，需要支付${tableBody[index].customerDeposit}元`
            }
            addMoneyToBePaid(body).then(res => {
              closeObChasing(obTracingCode).then(res => {
                if (res == 'pass') {

                  searchList()
                  emit('refreshMsg')
                }
                else {

                  ElMessage(res)
                  searchList()
                  emit('refreshMsg')
                }
              })
            })
          }
          else {
            closeObChasing(obTracingCode).then(res => {
              if (res == 'pass') {

                searchList()
                emit('refreshMsg')
              }
              else {

                ElMessage(res)
                searchList()
                emit('refreshMsg')
              }
            })
          }


        } catch (error) {



        }

        finally {
          unLockObTracing(obArr)


        }



      }).catch(err => {

        ElMessage(err)
        
        searchList()
        emit('refreshMsg')
      })
    }

    const goRoListFromObTracing = (value) => {
      emit('goRoListFromObTracing', value)
    }

    const reSearch = () => {
      searchList()
    }

    const rebuildBaCode = (value) => {

      const storage = window.localStorage;
      const user = store.state.loginInitializeData.userId
      let storeListFromWin = []
      const timeNow = value.documentDateTime

      if (JSON.parse(storage.getItem('BarStoreList' + user))) {
        storeListFromWin = JSON.parse(storage.getItem('BarStoreList' + user));
      }
      const newId2 = getUUId()
      let addPrint2 = {
        id: 'store' + newId2,
        partName: value.commodityName,
        docType: '客户预定单',
        companyName: transferToName(value.partACompanyId, 'OwedCompanyId'),
        docDate: timeNow,
        remark: `报价:${value.customerQuotation},订金:${value.customerDeposit}`,
        barcode: value.documentId,
      }
      storeListFromWin.unshift(addPrint2)
      storage.setItem('BarStoreList' + user, JSON.stringify(storeListFromWin))
      store.commit('updateBarcodeCount')
    }

    let searchList = () => {
      let config = {
        method: "post",
        url: '/ObTracingList/GetListByMulSearchForTacing',
        data: Object.assign(SearchContent, searchElement),
      };
      requestData
        .GetData(config)
        .then((res) => {
          selectAll.value = false
          selectedItems.value.splice(0)
          tableBody.splice(0);
          tableBody.push.apply(tableBody, res['obTracingListMultiple']);
          changeArrContentToDate(tableBody, PageName)
          const filterList = store.state.loginInitializeData.filterList
          const staffFilter = filterList.StaffId
          const ArrivalFilter = filterList.ArrivalStatus
          for (let item of tableBody) {
            for (let key in item) {
              if (key == 'staffId') {
                item[key] = staffFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'arrivalStatus') {
                item[key] = ArrivalFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
            }
          }
        })
        .catch((err) => {

        });
    };
    searchList()


    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          searchList()
        }
      }
    )
    //#endregion

    return {
      SponserToBeSolveFSFilter, setActiveId, activeInput,
      trHeight, SnWidth, operationWidth, tableHead, searchList, ChangeSort, tablewidth, tableBody,
      reSearch, searchElement, tbodyHeight, SearchContent, selectAllWidth, rebuildBaCode,
      selectAll, selectedItems, remindCustomer, closeChasingPositive, closeChasingNegative, goRoListFromObTracing

    }
  },
  components: {
  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;
@lableMarginLeft: 0.625rem;
@lableHeight: 2rem;
@inputHeight: 1.5rem;
@mainColor: #4285f4;

.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.searchinputLabel {
  margin-left: @lableMarginLeft;
  font-size: 0.8rem;
  height: @lableHeight;
  line-height: @lableHeight;
}
.searchinput {
  margin-top: calc((@lableHeight - @inputHeight) / 2);
  margin-bottom: calc((@lableHeight - @inputHeight) / 2);
  font-size: 0.9rem;
  border: none;
  outline: none;
  height: @inputHeight;
  line-height: @inputHeight;
}
.ActiveBox {
  border: 1px solid #ffa004 !important;
}

.searchInputBox {
  height: @lableHeight;
  border: 1px solid #c9c9c9;
  margin-left: @lableMarginLeft;
  border-radius: 0.31rem;
}

.searchItemContainer {
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
  display: flex;
}

.searchSetting {
  margin-left: calc(@lableMarginLeft*3);
  height: @lableHeight;
  display: flex;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}
.searchSetting:hover {
  cursor: pointer;
  color: @mainColor;
}
.settingIcon {
  font-family: "icomoon";
  font-size: 1.1rem;
  line-height: @lableHeight;
}

.settingText {
  margin-left: 0.3rem;
  font-size: 0.8rem;
  line-height: @lableHeight;
}
.dateInputContainer {
  display: flex;
}
.BodyTitle {
  width: 98%;
  margin-top: 0.5rem;
  margin-left: 1%;
}
.BodyTitleText {
  font-size: 1.2rem;
  font-weight: 200;
}
.long {
  width: 20rem;
}

.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 80%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.thSN {
  font-family: "icomoon";
  color: #fff;
  font-size: 16px;
}
.headtd {
  cursor: pointer;
}
.ShowUpArrow {
  position: relative;
}
.ShowUpArrow::after {
  content: "\ea32";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
  color: #fff;
}
.ShowDownArrow {
  position: relative;
}
.ShowDownArrow::after {
  content: "\ea36";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.tdSN,
.tableselect,
.tdoper,
.footsum,
.bodytr {
  text-align: center;
}
.bodytr {
  height: 2rem;
}

.tablefoot {
  margin-top: -1px;
}

.moreFunctionBox {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.tipBox {
  display: flex;
  height: 1.75rem;
  width: 7rem;
  margin-top: 0.625rem;
  margin-right: 0.5rem;
}

.selectAllCheckBox {
  cursor: pointer;
}

.todoTr {
  background-color: #fce4d6 !important;
}
.finishTr {
  background-color: #b1d580 !important;
}

.tipColortodo,
.tipColorfinish {
  height: 1.2rem;
  width: 1.2rem;
  margin-top: 0.3rem;
}
.tipColortodo {
  background-color: #fce4d6;
}
.tipColorfinish {
  background-color: #b1d580;
}
.tipWords {
  height: 1.2rem;
  line-height: 1.2rem;
  margin-top: 0.3rem;
  margin-left: 0.5rem;
}
</style>