<template>
  <div class="inputLoaderContainer">
    <BodyTitle></BodyTitle>
    <div class="FrTitle"><span>回收发起</span></div>
    <div class="InputHeadContainer">
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'PartBCompanyId')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="PartBCompanyIdMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'PartBCompanyId')[0].fieldsShowName + ':'"></label><input class="InputForChoose" id="PartBCompanyIdMpInput" type="text" v-model="searchItemList.addBodyContent.PartBCompanyId" @blur="checkBpName($event),unbindBpEnter()" @mousedown="bindBpEnter">
        <div class="InputForChooseFunc" @click="openBpPop"></div>
        <BpPopForFrOne :tableTile="searchItemList.docTitleTwo" ref="bpPopRef"></BpPopForFrOne>
      </div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'CustomerSourceId')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="CustomerSourceIdMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'CustomerSourceId')[0].fieldsShowName + ':'"></label><select class="InputForChooseShort" id="CustomerSourceIdMpInput" v-model="searchItemList.addBodyContent.CustomerSourceId">
          <option v-for="item5 in cusSourceArr" :key="item5.customerSourceId + 'CSFrInput'" :value="item5.customerSourceId">{{item5.customerSourceName}}</option>
        </select></div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'MachineClass')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="MachineClassMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'MachineClass')[0].fieldsShowName + ':'"></label><select class="InputForChooseShort" id="MachineClassMpInput" v-model="searchItemList.addBodyContent.MachineClass" @change="updateBrands()">
          <option v-for="item in searchItemList.MachineClass.Filter" :key="item.afterTransfer + 'MpInput'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
        </select></div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'MachineType')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="MachineTypeMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'MachineType')[0].fieldsShowName + ':'"></label><input class="InputForChooseLong" id="MachineTypeMpInput" type="text" v-model="searchItemList.addBodyContent.MachineType"></div>
    </div>

    <div class="FrTitle" v-show="machineShow"><span>机器信息</span></div>
    <div class="InputHeadContainer" v-show="machineShow">
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'MachineBrand')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="MachineBrandMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'MachineBrand')[0].fieldsShowName + ':'"></label><select class="InputForChooseShort" id="MachineBrandMpInput" v-model="searchItemList.addBodyContent.MachineBrand">
          <option v-for="item in brandList" :key="item.brandId + 'MpInput'" :value="item.brandId">{{item.brandName}}</option>
        </select></div>

      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'BrandNewOrNot')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="BrandNewOrNotMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'BrandNewOrNot')[0].fieldsShowName + ':'"></label><select class="InputForChooseShort" id="BrandNewOrNotMpInput" v-model="searchItemList.addBodyContent.BrandNewOrNot">
          <option value="false">二手物品</option>
          <option value="true">全新未拆封</option>
        </select></div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Color')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="ColorMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Color')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="ColorMpInput" type="text" v-model="searchItemList.addBodyContent.Color"></div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'ScreenStatus')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="ScreenStatusMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'ScreenStatus')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="ScreenStatusMpInput" type="text" v-model="searchItemList.addBodyContent.ScreenStatus"></div>

      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'OutLookDiscription')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="OutLookDiscriptionMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'OutLookDiscription')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="OutLookDiscriptionMpInput" type="text" v-model="searchItemList.addBodyContent.OutLookDiscription"></div>
      <div class="InputForChooseContainer" v-show="cpuShow"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Cpu')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="CpuMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Cpu')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="CpuMpInput" type="text" v-model="searchItemList.addBodyContent.Cpu"></div>

      <div class="InputForChooseContainer" v-show="gpuShow"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Gpu')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="GpuMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Gpu')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="GpuMpInput" type="text" v-model="searchItemList.addBodyContent.Gpu"></div>

      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Memory')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="MemoryMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Memory')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="MemoryMpInput" type="text" v-model="searchItemList.addBodyContent.Memory"></div>

      <div class="InputForChooseContainer" v-show="gpuShow"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'HardDisk')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="HardDiskMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'HardDisk')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="HardDiskMpInput" type="text" v-model="searchItemList.addBodyContent.HardDisk"></div>

      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Country')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="CountryMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Country')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="CountryMpInput" type="text" v-model="searchItemList.addBodyContent.Country"></div>

      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'UniqueCode')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="UniqueCodeMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'UniqueCode')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="UniqueCodeMpInput" type="text" v-model="searchItemList.addBodyContent.UniqueCode"></div>

      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'WarrantyDaysLeft')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="WarrantyDaysLeftMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'WarrantyDaysLeft')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="WarrantyDaysLeftMpInput" type="number" v-model="searchItemList.addBodyContent.WarrantyDaysLeft"></div>

      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'IdQuitOrNot')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="IdQuitOrNotMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'IdQuitOrNot')[0].fieldsShowName + ':'"></label><select class="InputForChooseShort" id="IdQuitOrNotMpInput" v-model="searchItemList.addBodyContent.IdQuitOrNot">
          <option value="false">不能退出账号</option>
          <option value="true">已退出账号</option>
        </select></div>

      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'RepariHistoryAndRemark')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="RepariHistoryAndRemarkMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'RepariHistoryAndRemark')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="RepariHistoryAndRemarkMpInput" type="text" v-model="searchItemList.addBodyContent.RepariHistoryAndRemark"></div>

    </div>

    <div class="FrTitle" v-show="purchaseShow"><span>采购信息</span></div>
    <div class="InputHeadContainer" v-show="purchaseShow">
      <div class="InputForChooseContainer"><span class="importantIndi">*</span><label class="InputForChooseLabel" for="StaffIdMpInput">回收人:</label><input class="InputForChoose" id="StaffIdMpInput" type="text" @blur="checkStName($event),unbindStEnter()" @mousedown="bindStEnter" v-model="searchItemList.addBodyContent.StaffId">
        <div class="InputForChooseFunc" @click="openStPop"></div>
        <StPopForFr ref="stPopRef"></StPopForFr>
      </div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'UnitPrice')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="UnitPriceMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'UnitPrice')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="UnitPriceMpInput" type="number" v-model="searchItemList.addBodyContent.UnitPrice"></div>

      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Remark')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="RemarkMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'Remark')[0].fieldsShowName + ':'"></label><input class="InputForChooseLong" id="RemarkMpInput" type="text" v-model="searchItemList.addBodyContent.Remark"></div>
    </div>

    <div class="FrTitle" v-show="customerInfoShow"><span>客户信息</span></div>
    <div class="InputHeadContainer" v-show="customerInfoShow">
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'CustomerName')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="CustomerNameMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'CustomerName')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="CustomerNameMpInput" type="text" v-model="searchItemList.addBodyContent.CustomerName"></div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'CustomerContact')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="CustomerContactMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'CustomerContact')[0].fieldsShowName + ':'"></label><input class="InputForChooseShort" id="CustomerContactMpInput" type="text" v-model="searchItemList.addBodyContent.CustomerContact"></div>
      <div class="InputForChooseContainer"><span class="importantIndi" v-show="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'IdentityId')[0].mustMaintain">*</span><label class="InputForChooseLabel" for="IdentityIdMpInput" v-text="searchItemList.itemList.filter(x=>x.fieldsNameForManage == 'IdentityId')[0].fieldsShowName + ':'"></label><input class="InputForChooseLong" id="IdentityIdMpInput" type="text" v-model="searchItemList.addBodyContent.IdentityId"></div>
    </div>

    <div class="EditTableLastLine">
      <!-- 维护第七层的内容 -->
      <div class="EditTableFuncButton" :style="{background:'#fbb80d'}" @click="openList('MPAC')">回收并通知财务付款</div>
      <div class="EditTableFuncButton EditTableFuncButtonAjust" @click="openList('MPMI')" v-show="$store.state.loginInitializeData.authList.buttonVisibleManages.find(x=>x.buttonId == 'MpInputRecycleAndPay')?.visibleOrNot">回收并付款</div>
    </div>
  </div>
</template>
<script>
import { computed, provide, reactive, ref, watch } from 'vue-demi'
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import { getDocNo } from '@/hooks/OpenOrder'
import InputTable from '@/hooks/InputTable'
import BpPopForFrOne from '@/components/Common/GlobalPop/BpPopForFrOne.vue'
import BpPopForFrTwo from '@/components/Common/GlobalPop/BpPopForFrTwo.vue'
import StPopForFr from '@/components/Common/GlobalPop/StPopForFr.vue'
import requestData from "@/hooks/requestData.js";
import { checkDocHead, transferToCode, getLoginData, openMpList, washDocHead, getDocType, addSourceStatistics, openMoList, transferToName, addMoneyToBePaid } from '@/hooks/OpenOrder';
import { transferLoginDataOnly } from '@/hooks/loginAffair';
import { fillSonContent, copyObj } from '@/hooks/tools'
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
export default {
  components: {
    BodyTitle,
    BpPopForFrOne,
    BpPopForFrTwo, StPopForFr
  },
  setup(props, { emit }) {
    const store = useStore();
    const RootTableName = 'MpList';
    let { searchItemList, listForBody } = InputTable(RootTableName)
    searchItemList.docTitleTwo = 'MP'
    searchItemList.TableName = 'MpInput'
    provide('searchItemList', searchItemList)

    const initializePageElement = () => {
      searchItemList.addBodyContent.PartBCompanyId = '散客';
    }

    initializePageElement()

    //#region 控制显示模块
    const machineShow = computed(() => {
      if (searchItemList.addBodyContent.PartBCompanyId != '' && searchItemList.addBodyContent.MachineClass != '') {
        return true
      }
      else {
        return false
      }
    })

    const purchaseShow = computed(() => {
      if (searchItemList.addBodyContent.PartBCompanyId != '' &&
        searchItemList.addBodyContent.MachineClass != '' &&
        searchItemList.addBodyContent.MachineBrand != '' &&
        searchItemList.addBodyContent.BrandNewOrNot != '' &&
        searchItemList.addBodyContent.Color != ''
      ) {
        return true
      }
      else {
        return false
      }
    })

    const customerInfoShow = computed(() => {
      if (searchItemList.addBodyContent.PartBCompanyId != '' &&
        searchItemList.addBodyContent.MachineClass != '' &&
        searchItemList.addBodyContent.MachineBrand != '' &&
        searchItemList.addBodyContent.BrandNewOrNot != '' &&
        searchItemList.addBodyContent.Color != ''
      ) {
        return true
      }
      else {
        return false
      }
    })

    const cpuShow = computed(() => {
      if (searchItemList.addBodyContent.MachineClass == 11 ||
        searchItemList.addBodyContent.MachineClass == 12 ||
        searchItemList.addBodyContent.MachineClass == 14) {
        return true;
      }
      else {
        return false;
      }
    })

    const gpuShow = computed(() => {
      if (searchItemList.addBodyContent.MachineClass == 11 ||
        searchItemList.addBodyContent.MachineClass == 12 ||
        searchItemList.addBodyContent.MachineClass == 14) {
        return true;
      }
      else {
        return false;
      }
    })

    //#endregion

    //#region 供应商模块1
    const bpPopRef = ref(null)

    const openBpPop = () => {
      bpPopRef.value.openPopComm()
    }


    //绑定Enter事件
    const enterBp = (e) => {
      if (e.keyCode == 13) {
        checkBpName(e)
      }
    }
    const bindBpEnter = () => {
      window.addEventListener('keydown', enterBp)
    }

    const unbindBpEnter = () => {
      window.removeEventListener('keydown', enterBp)
    }

    //f确认往来单位输入的方法
    const checkBpName = (e) => {
      if (e.target.value == '') { return false; }
      let config = {
        method: "get",
        url: "/BusinessPartnerList/GetSingleAuth",
        params: {
          name: e.target.value
        }
      }
      requestData.GetCache(config).then(res => {
        if (res == 'notfound') {
          let sendMsg = searchItemList.addBodyContent.PartBCompanyId
          searchItemList.addBodyContent.PartBCompanyId = ''
          openBpPop()
          bpPopRef.value.sendMsgSolve(sendMsg)
          bpPopRef.value.searchList()
          return 'notfound'
        }
        else {
          return 'found'
        }
      })
    }
    //#endregion

    //#region 员工模块
    const stPopRef = ref(null)

    const openStPop = () => {
      stPopRef.value.openPopComm()
    }

    const enterSt = (e) => {
      if (e.keyCode == 13) {
        checkStName(e)
      }
    }
    const bindStEnter = () => {
      window.addEventListener('keydown', enterSt)
    }

    const unbindStEnter = () => {
      window.removeEventListener('keydown', enterSt)
    }


    //f确认往来单位输入的方法
    const checkStName = (e) => {
      if (e.target.value == '') { return false; }
      let config = {
        method: "get",
        url: "/StaffList/GetSingleAuth",
        params: {
          name: e.target.value
        }
      }
      requestData.GetCache(config).then(res => {
        if (res == 'notfound') {
          let sendMsg = searchItemList.addBodyContent.StaffId
          searchItemList.addBodyContent.StaffId = ''
          openStPop()
          stPopRef.value.sendMsgSolve(sendMsg)
          stPopRef.value.searchList()
          return 'notfound'
        }
        else {
          return 'found'
        }
      })
    }

    const cusSourceArr = store.state.loginInitializeData.authList.customerSourceLists
    //#endregion

    //#region 开单功能模块
    let docHeadCode = ''
    const refreshDocNo = () => {
      getDocNo(searchItemList.docTitleTwo).then(res => {
        docHeadCode = res
      })
    }
    refreshDocNo()

    const openList = (typeName) => {
      let err = checkDocHead(searchItemList.addBodyContent, searchItemList.itemList)
      if (err) {
        ElMessage(err)
        return false;
      }
      let docHeadCode1 = docHeadCode
      getLoginData().then(res => {
        if(res != 0){
          transferLoginDataOnly(res)
        }
        let docHeadCopy = {}
        washDocHead(searchItemList.documentContent, docHeadCopy)
        //刷新初始化数据
        searchItemList.addBodyContent.PartBCompanyId = transferToCode(searchItemList.addBodyContent.PartBCompanyId, 'PartBCompanyId')
        searchItemList.addBodyContent.StaffId = transferToCode(searchItemList.addBodyContent.StaffId, 'StaffId')
        searchItemList.addBodyContent.DocumentId = docHeadCode1
        openMpList(searchItemList.addBodyContent).then(res => {
          if (res != 'pass') {
            ElMessage(res)
            return false;
          }
          else {
            const machineType = searchItemList.addBodyContent.MachineType
            const unitPrice = +searchItemList.addBodyContent.UnitPrice
            const partbCompany = searchItemList.addBodyContent.PartBCompanyId
            const partaCompany = store.state.loginInitializeData.companyId
            const staff = searchItemList.addBodyContent.StaffId
            const user = store.state.loginInitializeData.userName
            docHeadCopy.Amount = searchItemList.addBodyContent.UnitPrice
            docHeadCopy.DocumentId = docHeadCode1
            docHeadCopy.PartBCompanyId = partbCompany
            docHeadCopy.Qty = 1
            docHeadCopy.Remark = searchItemList.addBodyContent.Remark
            docHeadCopy.StaffId = staff
            docHeadCopy.DocumentTypeName = getDocType(docHeadCode1)
            const config = {
              method: "post",
              url: "/DocumentCenter/Add",
              data: docHeadCopy,
            };
            requestData.GetCache(config)
            if (typeName == 'MPMI' && unitPrice > 0) {
              getDocNo('MO').then(res => {
                let body = [{
                  DocumentId: res,
                  Sn: 1,
                  PartACompanyId: partaCompany,
                  PartBCompanyId: partbCompany,
                  OrderQty: 1,
                  UnitPrice: unitPrice,
                  Amount: unitPrice,
                  Remark: `自动开单-回收了${machineType}`,
                  SourceDocumentId: docHeadCode1,
                  InStockQty: 1,
                  OutStockQty: 1,
                }]
                let moHead = copyObj(docHeadCopy)
                moHead.DocumentId = res
                moHead.DocumentTypeName = getDocType(res)
                moHead.PartACompanyId = partaCompany
                moHead.SourceDocumentId = docHeadCode1
                moHead.Remark = `自动开单-回收了${machineType}`
                moHead.OutWareHouseId = ''
                moHead.CapitalAccountId = '主账户'
                openMoList(body, moHead, res)
              })
            }
            if (typeName == 'MPAC' && unitPrice > 0) {
              const body = {
                DocumentId: docHeadCode1,
                PartACompanyId: partaCompany,
                PartBCompanyId: partbCompany,
                StaffId: staff,
                DocumentType: getDocType(docHeadCode1),
                Amount: unitPrice,
                Remark: `操作人:${transferToName(staff, 'StaffId')}回收了一台${machineType}，需要支付${unitPrice}元,操作账号:${user}`
              }
              addMoneyToBePaid(body).then(res => {
                emit('refreshMsg')
              })
            }
            if (searchItemList.addBodyContent.CustomerSourceId != '') {
              let staObj = {
                DocumentId: docHeadCode1,
                SettleOrNot: true,
                SettleDocumentId: docHeadCode1,
                TransferredLines: 1,
                Amount: unitPrice,
                CustomerSourceId: searchItemList.addBodyContent.CustomerSourceId,
                PartACompanyId: store.state.loginInitializeData.companyId
              }
              addSourceStatistics(staObj)
            }

            refreshDocNo()
            ElMessage({
                message:'开单成功',
                duration:2000,
                showClose:true
              })
            fillSonContent(searchItemList.addBodyContent, listForBody, 'add', 'defaultValue')
            searchItemList.addBodyContent.StaffId = ''
            emit('refreshMsg')
          }
        })
      }).catch(err => {

        ElMessage(res)
        return false;
      })
    }
    //#endregion

    const classToBrand = store.state.loginInitializeData.authList.classToBrandLists

    const brands = store.state.loginInitializeData.authList.brandLists

    let brandList = reactive([])

    const updateBrands = () => {
      brandList.splice(0)
      const brandsDraft = classToBrand.filter(x => x.commodityFirstClassNo == searchItemList.addBodyContent.MachineClass)
      for (let item of brandsDraft) {
        let obj = {
          brandId: item.brandId,
          brandName: brands.find(x => x.brandId == item.brandId).brandName
        }
        brandList.push(obj)
      }
    }


    //#endregion

    return {
      searchItemList,
      openList,
      bpPopRef,
      openBpPop,

      bpPopRef,
      openBpPop,
      bindBpEnter,
      unbindBpEnter,
      checkBpName,


      stPopRef,
      openStPop,
      bindStEnter,
      unbindStEnter,
      checkStName,

      cusSourceArr,
      machineShow,
      purchaseShow,
      customerInfoShow,
      cpuShow, gpuShow,

      brandList, updateBrands
    }
  }
}
</script>
<style scoped lang="less">
.RepairTypeChoose {
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  width: 95%;
  margin-left: 0.5rem;
  margin-top: 1rem;
  font-size: 1rem;
  display: flex;
}

.InputHeadContainer {
  display: flex;
  margin-top: 0.5rem;
  width: 95%;
  margin-left: 0.5rem;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  flex-wrap: wrap;
}
.InputHeadContainer input {
  margin-bottom: 0.5rem;
  margin-left: 0.2rem;
}

.RepairTypeChoose input {
  margin-left: 0.2rem;
  cursor: pointer;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.inputChoose {
  margin-left: 1rem;
  cursor: pointer;
}
.InputForChooseContainer {
  display: flex;
  margin-right: 0.625rem;
}
.InputForChooseContainer label {
  padding-right: 0.3125rem;
  line-height: 1.75rem;
}
.importantIndi {
  color: #e83a65;
  font-size: 1rem;
}
.InputForChooseLong {
  height: 1.75rem;
  width: 15.625rem;
  border: 1px solid #c9c9c9;
}
.InputForChooseTooLong {
  height: 1.75rem;
  width: 30.625rem;
  border: 1px solid #c9c9c9;
}
.InputForChooseTooTooLong {
  height: 1.75rem;
  width: 60.625rem;
  border: 1px solid #c9c9c9;
}
.InputForChooseShort {
  height: 1.75rem;
  width: 8.625rem;
  border: 1px solid #c9c9c9;
}
.InputForChoose:focus,
.InputForChooseLong:focus,
.InputForChooseShort:focus,
.InputForChooseTooLong:focus,
.InputForChooseTooTooLong:focus {
  border: 1px solid #ffa004;
}
.InputForChoose {
  height: 1.75rem;
  width: 9rem;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  border-left: 1px solid #c9c9c9;
  border-right: none;
}
.InputForChooseFunc {
  font-family: "icomoon";
  text-align: center;
  line-height: 1.75rem;
  font-size: 1.125rem;
  width: 1.75rem;
  height: 1.75rem;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  border-right: 1px solid #c9c9c9;
}
.InputForChooseFunc:hover {
  cursor: pointer;
}

.FrTitle {
  margin-left: 1rem;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 700;
}
</style>