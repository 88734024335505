<template>
  <div class="BodyTitle">
    <div class="BodyTitleText">{{title}}</div>
  </div>
</template>

<script>
import { inject, ref } from 'vue-demi'
export default {
setup(){
  
  let searchItemList = inject('searchItemList')
  
  let title = searchItemList.tableCName

  return{
    title
  }
},


}
</script>

<style scoped lang="less">
.BodyTitle{
  width: 98%;
  margin-top: 0.5rem;
  margin-left: 1%;
}
.BodyTitleText {
  font-size: 1.2rem;
  font-weight: 200;
}
</style>