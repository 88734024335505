<template>
  <div class="totalContainer">
    <div class="FunctionChoose">
      <div class="FunctionChooseButton">公司信息</div>
    </div>
    <div class="bigContainer">
      <div class="CompanyInfoContainer">
        <div><label for="">公司名称:</label><input v-model="companyInfo.CompanyNameAbbre" class="InputLong" type="text"></div>
        <div><label for="">联系人员:</label><input v-model="companyInfo.ContactPeople" class="InputLong" type="text"></div>
        <div><label for="">联系方式:</label><input v-model="companyInfo.PhoneNumber" class="InputLong" type="text"></div>
        <div><label for="">邀请串码:</label><input v-model="companyInfo.InviteCode" disabled="disabled" class="InputLong" type="text"></div>
        <div><label for="">数据接入:</label>
          <el-checkbox v-model="companyInfo.UseRepairData" label="手机电脑维修数据库" size="large" />
        </div>
        <div class="changeCode" @click="getInviteCode">变更邀请码</div>
      </div>
    </div>
    <div class="buttonContainer">
      <div class="saveButton" @click="saveCompanyInfo">保存</div>
    </div>
  </div>
</template>

<script>
import requestData from "@/hooks/requestData.js";
import { reactive, ref } from 'vue-demi';
import { copyObj } from '@/hooks/tools'
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
import { useRouter } from "vue-router";
export default {
  setup() {
    const store = useStore();
    let companyInfo = reactive({
      CompanyId: '',
      CompanyNameAbbre: '',
      ContactPeople: '',
      PhoneNumber: '',
      InviteCode: '',
      UseRepairData: false
    })

    const router = useRouter()

    companyInfo.UseRepairData = store.state.loginInitializeData.useRepairData

    let companyTobeSend = {}

    const config = {
      method: "get",
      url: "/CompanyUserManage/GetSingleInfo"
    };

    requestData.GetData(config).then(res => {
      companyTobeSend = copyObj(res)
      companyInfo.CompanyNameAbbre = res?.companyNameAbbre
      companyInfo.ContactPeople = res?.contactPeople
      companyInfo.PhoneNumber = !!res?.phoneNumber.trim() ? res?.phoneNumber : ''
      companyInfo.InviteCode = res?.inviteCode
      companyInfo.CompanyId = res?.companyId
    })


    const getInviteCode = () => {

      const config = {
        method: "get",
        url: "/CompanyUserManage/GetInviteCode"
      };
      requestData.GetData(config).then(res => {
        companyInfo.InviteCode = res

        ElMessage('保存后方可生效，其他商铺只能根据最新的邀请码添加您')
      }).catch(err => {

        ElMessage(err)
      })
    }

    const goLogin = () => {
      router.replace({ path: "/login" });
      setTimeout(() => {
        store.commit('clearState')
      }, 1000)
    }




    const saveCompanyInfo = () => {

      companyTobeSend.companyNameAbbre = companyInfo.CompanyNameAbbre
      companyTobeSend.contactPeople = companyInfo.ContactPeople
      companyTobeSend.phoneNumber = companyInfo.PhoneNumber
      companyTobeSend.InviteCode = companyInfo.InviteCode
      companyTobeSend.useRepairData = companyInfo.UseRepairData

      const config = {
        method: "post",
        url: "/CompanyUserManage/Update",
        data: companyTobeSend
      };

      requestData.GetData(config).then(res => {
        if (res = 'pass') {
          ElMessage('更新成功')
          if (companyInfo.UseRepairData == store.state.loginInitializeData.useRepairData) {
            store.commit('refreshShowWindow')
          }
          else {
            ElMessage('数据引用有修改，需要重新登录')
            goLogin()
          }
        }
        else {
          ElMessage('更新失败')
        }
      }).catch(err => {
        ElMessage(err)
      })
    }

    return {
      companyInfo,
      getInviteCode,
      saveCompanyInfo
    }
  }
}
</script>

<style scoped lang="less">
@marLeft: 1rem;
.totalContainer {
  height: 100%;
  width: 100%;
}
.FunctionChoose {
  margin-left: @marLeft;
  padding-top: 1rem;
  height: 2rem;
}

.bigContainer {
  border: 1px solid #dddddd;
  margin-left: @marLeft;
  margin-top: 0.4rem;
  width: 98%;
  height: 80%;
}

.CompanyInfoContainer {
  width: 40rem;
  margin-left: 5rem;
  margin-top: 5rem;
  font-size: 0.8rem;
  display: flex;
  flex-wrap: wrap;
}
.CompanyInfoContainer input {
  margin-top: 0.5rem;
}
.CompanyInfoContainer label {
  margin-left: 1rem;
}
.InputLong {
  height: 1.7rem;
  width: 24.6rem;
  margin-left: 0.2rem;
}
.InputShort {
  height: 1.7rem;
  width: 10rem;
  margin-left: 0.2rem;
}

.FunctionChooseButton {
  border: 1px solid #dddddd;
  font-size: 0.9rem;
  height: 1.5rem;
  width: 5rem;
  line-height: 1.5rem;
  text-align: center;
}
.FunctionChooseButton:hover {
  background-color: #f08300;
  cursor: pointer;
  color: #ffffff;
}

.changeCode {
  background-color: #fbb80d;
  border-radius: 5px;
  color: #fff;
  width: 7.5rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  margin-left: 1.25rem;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 700;
  margin-left: 20rem;
  margin-top: 2rem;
}
.changeCode:hover {
  transform: translate(0px, -2px) scale(1.03);
}

.saveButton {
  background-color: #1e87f0;
  border-radius: 5px;
  color: #fff;
  width: 7.5rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 700;
  margin-right: 2rem;
  margin-top: 1rem;
}

.saveButton:hover {
  transform: translate(0px, -2px) scale(1.03);
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}
</style>