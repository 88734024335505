<template>
  <div>
    <div>
      <el-button @click="getAllPipeStatus()">搜索</el-button>
    </div>
    <div>
      <div v-for="(item,index) in pipeStatus" :key="index + 'PipeStatus'">
        <div>{{keyArray[index]}}</div>
        <div v-for="(item2,index2) in item[keyArray[index]]" :key="index2 + 'PipeStatus'" class="displayBox">
          <div>{{item2}}</div>
          <button @click="closePipeStatus(keyArray[index],item2)">关闭</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, reactive } from 'vue-demi'
import { getAllPipeStatusReal } from '@/hooks/getCache'
import requestData from '@/hooks/requestData'
export default {
  setup() {
    let pipeStatus = reactive([])

    let keyArray = reactive([])

    const getAllPipeStatus = () => {
      getAllPipeStatusReal().then(res => {
        pipeStatus.splice(0)
        keyArray.splice(0)
        for (let key in res) {
          let b = {}
          b[key] = res[key]
          pipeStatus.push(b)
          keyArray.push(key)
        }
      })
    }

    const closePipeStatus = (keyValue, value) => {

      const config = {
        method: "get",
        url: "/AccountingSubjectsManage/ClosePipeStatus",
        params: {
          keyValue: keyValue,
          value: value,
        }
      };
      
      requestData.GetData(config).then(res=>{
      })
    }


    onUnmounted(() => {

    })
    //#endregion

    return {
      getAllPipeStatus,
      pipeStatus,
      keyArray,
      closePipeStatus
    }
  }
}
</script>

<style scoped lang="less">
.displayBox {
  display: flex;
}
</style>