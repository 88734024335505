<template>
  <div class="printBigBox">
    <div id="test" class="printBox">
      <div class="printSmallBox" v-for="item in shipArr" :key="item.customerId + 'shipPrint'">
        <div class="title">发货单</div>
        <div>
          <table class="tablex">
            <tr>
              <td colspan="3">发货单位:</td>
              <td colspan="15">{{item.companyName}}</td>
              <td colspan="3">打印时间:</td>
              <td colspan="15">{{item.timeShipped}}</td>
            </tr>
            <tr>
              <td colspan="3">收货单位:</td>
              <td colspan="15">{{item.customerId}}</td>
              <td colspan="3">联系方式:</td>
              <td colspan="15"><input class="inputForRemark" :class="{'plainShow':item.contact}" type="text" v-model="item.contact"></td>
            </tr>
            <tr>
              <td colspan="3">地址:</td>
              <td colspan="15"><input class="inputForRemark" :class="{'plainShow':item.address}" type="text" v-model="item.address"></td>
              <td colspan="3">备注:</td>
              <td colspan="15"><input class="inputForRemark" type="text"></td>
            </tr>
            <tr>
              <td colspan="1">序号</td>
              <td colspan="2">发货方式</td>
              <td colspan="5">单据日期</td>
              <td colspan="12">商品名称</td>
              <td colspan="2">数量</td>
              <td colspan="2" v-show="priceShow">单价</td>
              <td colspan="3" v-show="priceShow">总金额</td>
              <td :colspan="priceShow ? 2 : 3">仓库确认</td>
              <td :colspan="priceShow ? 7 : 11">备注</td>
            </tr>
            <tr class="showTableTr" v-for="(item1,index) in item.commArr" :class="{'spcialShow':item1.shipQty > 1}" :key="item1.commodityName + item.customerId + index">
              <td colspan="1">{{+index + 1}}</td>
              <td colspan="2">{{item1.documentType}}</td>
              <td colspan="5">{{item1.documentDate}}</td>
              <td colspan="12">{{item1.commodityName}}</td>
              <td colspan="2">{{item1.shipQty}}</td>
              <td colspan="2" v-show="priceShow">{{item1.unitPrice}}</td>
              <td colspan="3" v-show="priceShow">{{((item1.shipQty || 0) * (item1.unitPrice || 0)) || '*'}}</td>
              <td :colspan="priceShow ? 2 : 3"></td>
              <td :colspan="priceShow ? 7 : 11">{{item1.remark}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <el-button class="bottomFirstButton" type="primary" size="small" v-print="printObj">打印发货单</el-button>
    <el-button class="bottomSecondButton" type="success" size="small" @click="showPrice()">显示/隐藏价格</el-button>
  </div>
</template>

<script>
import { onMounted, onUnmounted, reactive, ref, toRefs } from 'vue-demi'
import { useStore } from "vuex";
import { getDateTime } from '@/hooks/tools'
import { getShippedPrice, getShipTargetInfo, deleteToBeShippedRecord, updateToBeShippedRecord } from '@/hooks/OpenOrder'
export default {
  setup(props, { emit }) {
    const store = useStore();
    const userId1 = store.state.loginInitializeData.userId
    const data = reactive({
      // 浏览器打印的对象
      printObj: {
        id: 'test',
        // 这个方法会监听打印界面的操作
        // beforeOpenCallback(vue) {
        //   console.log('打开之前')
        // },
        // openCallback(vue) {
        //   console.log('执行了打印')
        // },
        closeCallback(vue) {
          const b = confirm('打印成功并更新待发货清单')
          if (b) {
            let promiseArr = []
            for (let item of shipArr) {
              for (let item1 of item.commArr) {
                if (item1.orderQty == item1.shipQty) {
                  let a = deleteToBeShippedRecord(item1.toBeShippedCode,userId1)
                  promiseArr.push(a)
                }
                else {
                  const leftQty = item1.orderQty - item1.shipQty
                  let b = updateToBeShippedRecord(item1.toBeShippedCode, leftQty,userId1)
                  promiseArr.push(b)
                }
              }
            }
            Promise.all(promiseArr).then(resArr => {
              emit('goToBeShippedSolveFromPrint')
            })
          }
        }
      },
    });

    let shipArr = reactive([])

    const getDataFromToBeShippedSolve = (value) => {
      shipArr.splice(0)
      for (let item of value) {
        item.unitPrice = '*'
        if (shipArr.find(x => x.customerId == item.partBCompanyId)) {
          shipArr.find(x => x.customerId == item.partBCompanyId).commArr.push(item)
        }
        else {
          const b = {
            customerId: item.partBCompanyId,
            commArr: []
          }
          b.commArr.push(item)
          shipArr.push(b)
        }
      }

      for (let item of shipArr) {
        for (let item1 of item.commArr) {
          item1.documentTypeNumber = 4
          if (item1.documentType == '调货单') {
            item1.documentTypeNumber = 1
          }
          if (item1.documentType == '销售单') {
            item1.documentTypeNumber = 2
          }
          if (item1.documentType == '维修单') {
            item1.documentTypeNumber = 3
          }

        }
      }

      for (let item of shipArr) {
        item.commArr = item.commArr.sort(function (a, b) { return a.documentTypeNumber - b.documentTypeNumber })
      }


      const partACom = store.state.loginInitializeData.companyNameAbbre

      const timeNow = getDateTime('')

      for (let item2 of shipArr) {
        item2.companyName = partACom
        item2.timeShipped = timeNow
        getShipTargetInfo(item2.customerId).then(res => {
          item2.contact = res.split('|')[0] + (res.split('|')[0] ? (res.split('|')[1] ? '---' : '') : '') + res.split('|')[1]
          item2.address = res.split('|')[2]
        })
      }
    }

    let priceShow = ref(false)

    const showPrice = () => {
      if (!priceShow.value) {
        for (let item of shipArr) {
          for (let item1 of item.commArr) {
            if (item1.documentType == '销售单') {
              getShippedPrice(item1.toBeShippedCode).then(res => {
                item1.unitPrice = res
              })
            }
          }
        }
        priceShow.value = true
      }
      else {
        priceShow.value = false
      }
    }

    return {
      ...toRefs(data), getDataFromToBeShippedSolve, shipArr, priceShow, showPrice
    }
  }
}
</script>

<style scoped lang="less">
.printBigBox {
  width: 100%;
  height: 100%;
}
.printBox {
  width: 100%;
  height: 80%;
  overflow: hidden;
  overflow-y: auto;
}
.printSmallBox {
  width: 90%;
  margin: auto;
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 1px dashed #1e1e1e;
}
.printSmallBox table {
  width: 100%;
}

.printSmallBox td {
  text-align: center;
}
.title {
  text-align: center;
  font-weight: 900;
  font-size: 2rem;
}
.bottomFirstButton {
  margin-top: 1rem;
  margin-left: 6%;
}
.bottomSecondButton {
  margin-top: 1rem;
  margin-left: 1rem;
}
.spcialShow {
  background-color: #e1f5fd !important;
}
.inputForRemark {
  border: none;
  width: 100%;
  height: 100%;
  line-height: 100%;
  background-color: #e1f5fd;
  text-align: center;
  font-size: 0.9rem;
}
.plainShow {
  background-color: transparent;
}
.showTableTr:nth-child(even) {
  background: #f2f2f2;
}

.tablex {
  border: 1px solid black;
  border-collapse: collapse;
  width: 80%;
  text-align: center;
}
.tablex th,
.tablex td {
  border: 1px solid black;
  padding: 5px;
}
</style>