<template>
  <div class="tableLoaderContainer">
    <BodyTitle></BodyTitle>
    <SearchContentForAccountsList @deliverSearchItems="deliverSearchItems" ref="searchRef"></SearchContentForAccountsList>
    <TableShowForDiy @saveActiveItem="saveActiveItem" @clearActive="clearActive" ref="tableShowRef" @perfectTableBody="perfectTableBody" @perfectTableHead="perfectTableHead" @goMiInputFromAcc="goMiInputFromAcc" @goMoInputFromAcc="goMoInputFromAcc"></TableShowForDiy>
    <div class="moreFunctionBox">
      <div class="FuncButton" @click="goAccoutsChange()">明细账目查询</div>
    </div>
  </div>
</template>

<script>
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import SearchContentForAccountsList from '@/components/Content/MainBodyContent/SearchContentForAccountsList.vue'
import TableShowForDiy from '@/components/Content/MainBodyContent/TableShowForDiy.vue';
import { useStore } from "vuex";
import DiyShowTable from '@/hooks/DiyShowTable'
import {toDecimal2} from '@/hooks/tools'
import { onUnmounted } from 'vue-demi';
import { ElMessage } from 'element-plus'
export default {
  setup(props,{emit}) {
    //#region 定义区
    const store = useStore();
    //初始化输入表名称
    const TableName = 'AccountsList';

    const PageName = 'AccountsList'

    let { tableShowRef, searchRef, deliverSearchItems, activeItem, saveActiveItem, clearActive } = DiyShowTable(TableName,PageName)
    //根据表名给searchItemList赋值

    const perfectTableHead = (head) => {
      if (head.length <= 2) {
        let b1 = {
          fieldsNameForVali: 'shouldReceive',
          showLen: 12,
          showName: '应收',
        }
        head.push(b1)
        let b2 = {
          fieldsNameForVali: 'shouldPay',
          showLen: 12,
          showName: '应付',
        }
        head.push(b2)
      }
    }

    const perfectTableBody = (body) => {
      for(let item of body){
        item.shouldReceive = item.accounts > 0 ? toDecimal2(item.accounts) : toDecimal2(0)
        item.shouldPay = item.accounts < 0 ? toDecimal2(-item.accounts) : toDecimal2(0)
        item.accounts = toDecimal2(item.accounts)
      }
    }


    const goAccoutsChange = () => {
      if(activeItem.index <= -1){
        ElMessage('未选择行')
        return
      }
      emit('goAccoutsChangeFromAcc',activeItem)
    }

    const goMiInputFromAcc = (value) =>{
      emit('goMiInputFromAcc',value)
    }

    const goMoInputFromAcc = (value) => {
      emit('goMoInputFromAcc',value)
    }


    //#endregion


    return {
      deliverSearchItems,
      tableShowRef,
      saveActiveItem,
      activeItem, clearActive, goAccoutsChange, searchRef,
      perfectTableHead, perfectTableBody,goMiInputFromAcc,goMoInputFromAcc
    }
  },
  components: {
    BodyTitle,
    SearchContentForAccountsList,
    TableShowForDiy
  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

</style>