<template>
  <div class="inputLoaderContainer">
    <BodyTitle></BodyTitle>
    <InputHead></InputHead>
    <InputBody ref="inputBodyRef" @clearScanIn="clearScanIn"></InputBody>
    <div class="EditTableLine">
      
    </div>
    <div class="EditTableLastLine">
      <!-- 维护第七层的内容 -->
      <!-- <div class="EditTableFuncButton" :style="{background:'#73a6a3'}" @click="openPopUp()">导入订单明细</div> -->
      <div class="EditTableFuncButton EditTableFuncButtonAjust" @click="openList('')">确认并收款</div>
    </div>
  </div>
</template>
<script>
import { computed, provide, ref, watch } from 'vue-demi'
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import InputHead from '@/components/Content/MainBodyContent/InputHead.vue'
import InputBody from '@/components/Content/MainBodyContent/InputBody.vue'
import { getDocNo, deleteDocCenter, getLoginData, openIoList, checkDocCondition, transferToCode, openMiList, clearTableContent, transferToName } from '@/hooks/OpenOrder'
import { transferLoginDataOnly } from '@/hooks/loginAffair';
import ComScanIn from '@/components/Content/FunctionUnit/ComScanIn.vue'
import InputTable from '@/hooks/InputTable'
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
export default {
  components: {
    BodyTitle,
    InputHead,
    InputBody,
    ComScanIn
  },
  setup(props, { emit }) {
    const store = useStore();
    const RootTableName = 'IoList';
    let { itemListHead, itemListBody, searchItemList } = InputTable(RootTableName)
    searchItemList.docTitleTwo = 'IO'
    searchItemList.TableName = 'IoInput'
    provide('searchItemList', searchItemList)

    let inputBodyRef = ref(null)
    //#endregion

    //#region 扫描组件
    let scanInValue = ref('');

    const scanIn = (res) => {
      inputBodyRef.value.scanInData(res)
    }

    const clearScanIn = () => {
      scanInValue.value = '';
    }

    //#endregion

    //#region 开单功能模块


    const openList = (typeName) => {

      let errCheck = checkDocCondition(searchItemList.documentContent, itemListHead, inputBodyRef.value.AddContentBody, itemListBody)
      if (errCheck.headErr) {

        ElMessage(errCheck.headErr)
        return
      }
      if (errCheck.bodyErr) {

        ElMessage(errCheck.bodyErr)
        return
      }
      for (let item of inputBodyRef.value.AddContentBody) {
        if (item.UnitPrice && !item.AccountingSubjectId) {

          ElMessage('收入类型未填写')
          return;
        }
      }
      getLoginData().then(res => {
        //刷新初始化数据
        if(res != 0){
          transferLoginDataOnly(res)
        }
        getDocNo(searchItemList.docTitleTwo).then(res => {
          
          const ioHeadCode = res
          const partBId = transferToCode('其他', 'PartBCompanyId')
          let totalAmount = 0
          for (let item of inputBodyRef.value.AddContentBody) {
            if (item.UnitPrice) {
              totalAmount += item.UnitPrice
            }
          }
          for (let item of inputBodyRef.value.AddContentBody) {
            if (item.UnitPrice) {
              item.SourceDocumentId = ''
              item.RootSourceDocumentId = ioHeadCode
              item.PartACompanyId = store.state.loginInitializeData.companyId
              item.PartBCompanyId = partBId
              item.OutStockQty = 1
              item.InStockQty = 1
              item.OrderQty = 1
              item.DocumentId = ioHeadCode
              item.Remark = ((item.Remark ? item.Remark + '-' : '') + `,收入类型为${transferToName(item.AccountingSubjectId, 'AccountingSubjectId')}`)
            }
          }
          openIoList(inputBodyRef.value.AddContentBody, searchItemList.documentContent, res).then(res => {
            if (res != 'pass') {
              deleteDocCenter(ioHeadCode).then(res => {
                ElMessage(res)
                return false;
              })
            }
            else {
              getDocNo('MI').then(res => {
                const miHeadCode = res
                const miBody = [{
                  MiCode: '',
                  DocumentId: miHeadCode,
                  Sn: 1,
                  PartACompanyId: store.state.loginInitializeData.companyId,
                  PartBCompanyId: partBId,
                  DocumentDateTime: '',
                  OrderQty: 1,
                  OutStockQty: 1,
                  InStockQty: 1,
                  Unit: '',
                  UnitPrice: totalAmount,
                  Amount: totalAmount,
                  Remark: '自动收款',
                  SourceDocumentId: ioHeadCode,
                  RootSourceDocumentId: '',
                  ModifiedTimes: '',
                }]
                const miHead = {
                  DocumentId: miHeadCode,
                  DocumentTypeName: '',
                  DocumentDate: '',
                  DocumentDateTime: '',
                  Online: '',
                  PartACompanyId: store.state.loginInitializeData.companyId,
                  PartBCompanyId: partBId,
                  UserId: '',
                  StaffId: '',
                  SourceDocumentId: '',
                  Amount: totalAmount,
                  Qty: 1,
                  OutWareHouseId: '',
                  InWareHouseId: '',
                  SystemStatusId: '',
                  CapitalAccountId: '',
                  Remark: '',
                  RootSourceDocumentId: '',
                }
                openMiList(miBody, miHead, miHeadCode).then(res => {
                  ElMessage({
                message:'开单成功',
                duration:2000,
                showClose:true
              })
                  clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
                })
              })
            }

          })
        })
      }).catch(err => {

        ElMessage(err.message)
      })
    }
    //#endregion

    //#endregion

    return {
      searchItemList,
      inputBodyRef,
      scanInValue,
      scanIn,
      clearScanIn,
      openList
    }
  }
}
</script>
<style scoped lang="less">
</style>