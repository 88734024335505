<template>
  <div class="FuncBarContainerout">
    <div class="FuncBarContainerin" v-for="(item1, key1) in mainManuForShow" :key="item1.mainManuCode + 'MainManu'" :class="[{ FunctionBarCol: key1 !== activecol }]">
      <div class="FunctionBarhead" @mouseenter.stop="funcout(key1)" @click.stop="funcoutfirst(key1)" :class="{highLight:item1.viewLists.find(y=>y.viewCode == $store.state.loginInitializeData.ShowWindow[0])}">
        {{ item1.mainManuName }}
      </div>
      <div :class="{'FunctionBarItem':true}" v-for="(item2, key2) in item1.viewLists" :key="item2.viewCode + 'MainManu'" @click="GoFunction(key1, key2)">
        {{ item2.viewName }}
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { computed, watch } from "@vue/runtime-core";
export default {
  setup() {
    const store = useStore();

    let mainManuForShow = store.state.loginInitializeData.mainManuForShow

    let activecol = ref(-1);
    const funcout = (num) => {
      if (activecol.value >= 0) {
        activecol.value = num;
      }
    };
    const funcin = () => {
      activecol.value = -1;
    };
    const funcoutfirst = (num) => {

      activecol.value = num;
    };
    const GoFunction = (key1, key2) => {

      let keys = [key1, key2];
      store.commit("GoFunctionWindow", keys);
    };
    const closeFunc = () => {
      activecol.value = -1;
    };
    watch(activecol, (newValue, oldValue) => {
      if (newValue > -1) {
        window.addEventListener("click", closeFunc);
      } else {
        window.removeEventListener("click", closeFunc);
      }
    });
    return {
      activecol,
      funcout,
      funcin,
      funcoutfirst,
      GoFunction,
      mainManuForShow,
    };
  },
  components: {
  },
};
</script>
<style scoped lang="less">
.FuncBarContainerout {
  display: flex;
  width: 100%;
  height: 100%;
}

.FuncBarContainerin {
  width: 8.925rem;
  margin-right: -2.5rem;
  position: relative;
  z-index: 1;
  font-size: 1rem;
  font-weight: 600;
}

.FuncBarContainerin:hover {
  cursor: pointer;
}

.FunctionBarCol {
  overflow: hidden;
}

.FunctionBarhead {
  position: relative;
  width: 5.375rem;
  line-height: 2.25rem;
  color: #fff;
  padding-left: 0.625rem;
}

.FunctionBarhead::after {
  position: absolute;
  right: 0px;
  top: 0.1rem;
  color: #fff;
  font-family: "icomoon";
  content: "";
  font-size: 1.875rem;
}

.FunctionBarhead:hover {
  background-color: #d7e7f4;
  color: #2881c7;
}

.FunctionBarhead:hover::after {
  color: #2881c7;
}

.FunctionBarItem {
  height: 2.25rem;
  /* padding-left: 30px;
  padding-right:0px; */
  text-align: center;
  font-size: 0.875rem;
  line-height: 2.25rem;
  background-color: #fafafa;
  color: #000;
  box-shadow: 10px 10px 5px #888888;
}
.FunctionBarItem:hover {
  background-color: #2196f3;
  color: #fff;
  z-index: 99;
}

.highLight {
  background-color: #bdd7ee;
  color: #2881c7;
}
</style>