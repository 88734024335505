<template>
  <div class="tableLoaderContainer">
    <div class="BodyTitle">
      <div class="BodyTitleText">客户出售管理</div>
    </div>
    <div class="searchContentContainer">
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="StartTypeForMeterialSaleSolveList">发起类型:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'StartType'}"><select v-model="SearchContent.StartType" class="searchinput" id="StartTypeForMeterialSaleSolveList" @focus="setActiveId('StartType')" @blur="setActiveId('')" @change="reSearch()">
            <option value="">所有</option>
            <option value="101">零件出售</option>
          </select></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="BuyerToBeSolveFSForMeterialSaleSolveList">处理情况:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'BuyerToBeSolveFS'}"><select v-model="SearchContent.BuyerToBeSolveFS" class="searchinput" id="BuyerToBeSolveFSForMeterialSaleSolveList" @focus="setActiveId('BuyerToBeSolveFS')" @blur="setActiveId('')" @change="reSearch()">
            <option v-for="item in BuyerToBeSolveFSFilter" :key="item.beforeTransfer + 'MeterialSaleSolveList'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
          </select></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="PartBCompanyIdForMeterialSaleSolveList">客户:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'PartBCompanyId'}"><select v-model="SearchContent.PartBCompanyId" class="searchinput" id="PartBCompanyIdForMeterialSaleSolveList" @focus="setActiveId('PartBCompanyId')" @blur="setActiveId('')" @change="reSearch()">
            <option value="" selected="selected">所有</option>
            <option v-for="item in partBFilterCopy" :key="item.beforeTransfer + 'SalesOnTheWay1'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
          </select></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DocumentDateLMeterialSaleSolveList">发起日期</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateL'}"><input v-model="SearchContent.DocumentDateL" class="searchinput" id="DocumentDateLMeterialSaleSolveList" type="date" @focus="setActiveId('DocumentDateL')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DocumentDateHMeterialSaleSolveList" v-text="'---'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateH'}"><input v-model="SearchContent.DocumentDateH" class="searchinput" id="DocumentDateHMeterialSaleSolveList" type="date" @focus="setActiveId('DocumentDateH')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="CommodityNameMeterialSaleSolveList">商品名称:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'CommodityName'}"><input v-model="SearchContent.CommodityName" class="searchinput long" id="CommodityNameMeterialSaleSolveList" type="text" @focus="setActiveId('CommodityName')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="CommodityIdMeterialSaleSolveList">商品编码:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'CommodityId'}"><input v-model="SearchContent.CommodityId" class="searchinput" id="CommodityIdMeterialSaleSolveList" type="text" @focus="setActiveId('CommodityId')" @blur="setActiveId('')"></div>
      </div>
      <!-- 搜索模块 -->
      <div class="FuncButton" @click="searchList()">搜索</div>
      <div class="tipBox">
        <div class="tipColortodo"></div>
        <div class="tipWords">待处理</div>
      </div>
      <div class="tipBox">
        <div class="tipColorfinish"></div>
        <div class="tipWords">无需处理</div>
      </div>
    </div>

    <div class="BigTable">
      <div class="TableContentArea">
        <table class="tablehead">
          <tr class="headtr" :style="{ height : trHeight }">
            <th class="thSN">
              <div :style="{ width: SnWidth }"></div>
            </th>
            <th class="thselectall">
              <div :style="{ width: selectAllWidth }"><input class="selectAllCheckBox" type="checkbox" v-model="selectAll" /></div>
            </th>
            <th>
              <div class="thoper" :style="{ width: commodityWidth }">采购商品</div>
            </th>

            <th class="headtdEdit">
              <div :style="{ width: virtualWidth}">虚拟物品</div>
            </th>
            <th class="headtdEdit">
              <div :style="{ width: saleQtyWidth}">采购数量</div>
            </th>
            <th class="headtdEdit">
              <div :style="{ width: priceWidth}">采购价格</div>
            </th>

            <th>
              <div class="thoper" :style="{ width: operationWidth }">操作</div>
            </th>
            <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'head'" :class="{  ShowUpArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == 1, ShowDownArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == -1,}" @click.stop="ChangeSort(value1.fieldsNameForVali)">
              <div :style="{ width: value1.showLen  + 'rem' }">{{ value1.showName }}</div>
            </th>
          </tr>
        </table>
        <div class="tablebodyarea" :style="{ width: tablewidth  + 'rem',height:tbodyHeight}">
          <table class="tablebody">
            <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'td' + index2" :class="[{todoLine:value2.buyerToBeSolve},{nottodoLine:!value2.buyerToBeSolve}]">
              <td>
                <div class="tdSN" :style="{ width: SnWidth }">{{+index2 + 1}}</div>
              </td>
              <td class="tableselect">
                <div :style="{ width: selectAllWidth }"><input type="checkbox" :value="value2['salesOnTheWayCode']" v-model="selectedItems" /></div>
              </td>
              <td class="commodityTr">
                <div class="tableinputContainer" @click="setActiveInput(index2,'commodityNameBuy')" @mousedown="saveEnterInValue(tableBody[index2]['commodityNameBuy'])"><input type="text" :class="{'highInput':tableBody[index2]['commodityId'].trim() == '' && tableBody[index2]['buyerToBeSolve']}" :disabled="!tableBody[index2]['buyerToBeSolve']" :style="{ width: commodityWidth }" v-model="tableBody[index2]['commodityNameBuy']" @click="confirmOffset($event), bindEnter" @input="insertSearch" @blur="confirmComName(index2,'commodityNameBuy'),unbindEnter()" placeholder="请输入物料名称"></div>
                <div class="CommodityContainer" v-show="index2 == activeInput.activeIndex && searchResult.length != 0" :style="[{top:commTrOffset},{left:'0rem'}]">
                  <div class="searchContentTr" v-for="(searchitem,searchIndex) in searchResult" @mousedown.stop="solveSearchResult(searchIndex)" :key="searchitem + 'MeterialSaleSolveList'"><input v-model="searchitem.commodityName" type="text" :style="[{ width: commodityWidth },{height:'1.625rem'}]"><input type="text" class="searchIndication" v-model="searchitem.show"><input type="text" class="searchIndication" v-model="searchitem.inventoryBalance"></div>
                  <div class="searchContentTrLast" :style="[{height:'1.625rem'},{lineHeight:'1.625rem'}]" @mousedown.prevent="clearSearchResult()">关闭搜索</div>
                </div>
              </td>
              <td>
                <div class="checkContainer" :style="{ width: virtualWidth}"><input class="checkbo" type="checkbox" :style="{ width:'1.25rem' }" v-model="tableBody[index2]['virtualGoods']" disabled="disabled"></div>
              </td>
              <td>
                <div><input type="number" :style="{ width: saleQtyWidth}" v-model="tableBody[index2]['buyQty']" :class="{'highInput':tableBody[index2]['commodityNameBuy'].trim() != '' && tableBody[index2]['buyerToBeSolve']}" :disabled="tableBody[index2]['commodityNameBuy'] == '' || !tableBody[index2]['buyerToBeSolve']" @blur="checkQty(index2,$event,'buyQty')"></div>
              </td>
              <td>
                <div><input type="number" step="0.01" :style="{ width: priceWidth}" v-model="tableBody[index2]['unitPrice']" :class="{'highInput':tableBody[index2]['commodityNameBuy'].trim() != '' && tableBody[index2]['buyerToBeSolve']}" :disabled="tableBody[index2]['commodityNameBuy'] == ''  || !tableBody[index2]['buyerToBeSolve'] || priceLock[value2.salesOnTheWayCode]" @blur="checkPrice(index2,$event)"></div>
              </td>
              <td>
                <div class="tdoper" :style="{ width: operationWidth }">
                  <span class="spantt" @click="actionPoOne(index2)" v-show="tableBody[index2]['buyerToBeSolve']">采购</span>
                  <span class="spancancel" v-show="tableBody[index2]['buyerToBeSolve']" @click="openRejectPop(index2)">拒绝</span>
                </div>
              </td>
              <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
                <div :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
              </td>
            </tr>
          </table>
        </div>
        <table class="tablefoot">
          <tr class="foottr" :style="{ height : trHeight }">
            <td>
              <div class="footsum" :style="{ width: SnWidth }">合计</div>
            </td>
            <td>
              <div :style="{ width: selectAllWidth }"></div>
            </td>
            <td>
              <div :style="{ width: commodityWidth }"></div>
            </td>
            <td>
              <div :style="{ width: virtualWidth }"></div>
            </td>
            <td>
              <div :style="{ width: saleQtyWidth }"></div>
            </td>
            <td>
              <div :style="{ width: priceWidth }"></div>
            </td>
            <td>
              <div :style="{ width: operationWidth }"></div>
            </td>
            <td v-for="value4 in tableHead" :key="value4.fieldsNameForVali + 'foot'">
              <div :style="{ width: value4.showLen  + 'rem' }"></div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="moreFunctionBox">
      <div class="selectBoxModel"><label for="inwarehouseForMeterialSaleSolveList">入库仓库:</label><select id="inwarehouseForMeterialSaleSolveList" v-model="defaultInWarehouse">
          <option v-for="item in warehouseFilter" :key="item.beforeTransfer + 'MeterialSaleSolveList'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
        </select></div>
      <el-button type="primary" @click="goHistory()">查看历史</el-button>
    </div>

    <PopModel ref="rejectRemarkRef">
      <div class="inputBox">
        <label class="popLable" for="rejectRemarkInMeterialSaleSolveList">拒绝理由:</label>
        <el-input v-model="activeRejectRemark" class="popInput" id="rejectRemarkInMeterialSaleSolveList" placeholder="请输入拒绝理由" />
      </div>
      <div class="buttonBox">
        <el-button type="info" size="small" class="mRight" @click="closeRefusePop()">取消</el-button>
        <el-button type="primary" size="small" class="mRight lastButton" @click="actionRefuseOne()">确认拒绝</el-button>
      </div>
    </PopModel>
  </div>
</template>

<script>
import { computed, provide, reactive, ref, watch } from 'vue-demi';
import { useStore } from "vuex";
import {
  ChangeFirToLo, ChangeFirToUp, ChangeYMD, changeArrContentToDate, insertSearchOrigin, copyArray
} from '@/hooks/tools';
import _ from "lodash";
import requestData from '@/hooks/requestData';
import {
  fillDefaultValue, changeToViewCode, rejectSaleApply, openPoList, transferToCode, transferToName,
  getDocNo, deleteDocCenter, updatePoIn, updateWarning, getVmiCost
} from '@/hooks/OpenOrder'
import { lockSalesOneTheWay, unLockSalesOneTheWay } from '@/hooks/LockAffair';
import PopModel from '@/components/Common/PopModel.vue'
import { ElMessage } from 'element-plus'
export default {
  setup(props, { emit }) {
    //#region 定义区
    const store = useStore();

    const filterList = store.state.loginInitializeData.filterList

    const partBFilter = filterList.PartBCompanyId

    const partBFilterCopy = copyArray(partBFilter)

    const BuyerToBeSolveFSFilter = filterList.BuyerToBeSolveFS

    const warehouseFilter = copyArray(filterList.WareHouseIdIn)

    //初始化输入表名称
    const TableName = 'SalesOnTheWayList';

    const PageName = 'MeterialSaleSolveList'

    const code = changeToViewCode(PageName)

    //#endregion

    //#region 全选
    let selectAll = ref(false);

    let selectedItems = ref([]);

    watch(selectAll, (newValue, oldValue) => {

      if (code == store.state.loginInitializeData.ShowWindow[0]) {
        if (newValue == true) {
          selectedItems.value.splice(0);
          for (let item of tableBody) {
            selectedItems.value.push(item['SalesOnTheWayCode']);
          }
        } else {
          selectedItems.value.splice(0);
        }
      }
    });
    //#endregion

    //#region 输入模块
    //f存入活跃单元格数据
    let activeInput = reactive({
      activeIndex: -1,
      activeFields: ''
    })
    let enterInValue = ref('');

    const saveEnterInValue = (value) => {
      setTimeout(() => {
        enterInValue.value = value
      }, 200)
    }
    //#endregion

    let defaultInWarehouse = ref('')

    const setDefaultInWarehouse = () => {
      const warehouseList = store.state.loginInitializeData.authList.wareHouseLists
      defaultInWarehouse.value = warehouseFilter[0].beforeTransfer
      for (let item of warehouseFilter) {
        if (warehouseList.find(x => x.wareHouseId == item.beforeTransfer).defaultWarehouse) {
          defaultInWarehouse.value = item.beforeTransfer
        }
      }
    }

    setDefaultInWarehouse()

    watch(() => activeInput.activeIndex, () => {
      searchResult.splice(0)
    })
    const setActiveInput = (index, fields) => {
      activeInput.activeIndex = index;
      activeInput.activeFields = fields;
    }
    let searchResult = reactive([]);
    let stockBalanceCache = new Map();
    let offsetDistance = ref(0)
    const confirmOffset = (e) => {
      offsetDistance.value = e.clientY
    }
    const insertSearch = _.debounce((e) => {
      insertSearchOrigin(e, searchResult, stockBalanceCache)
    }, 500)

    let commTrOffset = computed(() => {
      if (780 - offsetDistance.value < searchResult.length * 26) {
        return -searchResult.length * 26 - 1 + 'px'
      }
      else {
        return '32px'
      }
    })

    const clearSearchResult = () => {
      searchResult.splice(0)
    }

    let activeRejectIndex = ref(-1)

    let activeRejectRemark = ref('')

    const rejectRemarkRef = ref(null)

    provide('tableTitle', '')


    const closeRefusePop = () => {
      activeRejectRemark.value = ''
      rejectRemarkRef.value.closePopComm()
    }

    const openRejectPop = (index) => {
      activeRejectIndex.value = index
      rejectRemarkRef.value.openPopComm()
    }

    const clearVailCommodityContent = (index) => {
      tableBody[index].buyQty = ''
      tableBody[index].commodityNameBuy = ''
      tableBody[index].CommodityIdBuy = ''
      tableBody[index].virtualGoods = ''
      tableBody[index].unitPrice = ''
    }

    const solveSearchResult = (index) => {
      const filterA = store.state.loginInitializeData.filterList.BusinessPartnerId;
      let bpId = ''
      if (tableBody[activeInput.activeIndex].partBCompanyId != '') {
        bpId = filterA.filter(x => x.afterTransfer == tableBody[activeInput.activeIndex].partBCompanyId)[0]?.beforeTransfer
      }
      let config = {
        method: "get",
        url: "/CommodityMangeTable/GetSingleById",
        params: {
          id: searchResult[index].commodityId,
          bpId: bpId
        }
      }
      clearVailCommodityContent(activeInput.activeIndex)

      requestData.GetCache(config).then(res => {
        if (res == 'notfound') {

        }
        else {
          tableBody[activeInput.activeIndex].buyQty = 1
          tableBody[activeInput.activeIndex].commodityNameBuy = res.split("|")[1]
          tableBody[activeInput.activeIndex].commodityIdBuy = res.split("|")[0]
          tableBody[activeInput.activeIndex].virtualGoods = res.split("|")[3] != 'True' ? true : false
          tableBody[activeInput.activeIndex].unitPrice = res.split("|")[5]
          enterInValue.value = res.split("|")[1]
          activeInput.activeIndex = -1
          searchResult.splice(0)
        }
      })
    }

    const enterCheckComName = (e) => {
      if (e.keyCode == 13) {
        confirmComName(activeInput.activeIndex, activeInput.activeFields)
      }
    }
    const bindEnter = () => {
      window.addEventListener('keydown', enterCheckComName)
    }
    const unbindEnter = () => {
      window.removeEventListener('keydown', enterCheckComName)
    }

    const confirmComName = (index, fieldsName) => {
      searchResult.splice(0)
      if (tableBody[index][fieldsName] == enterInValue.value && tableBody[index].buyQty != '') { return false; }
      if (tableBody[index][fieldsName] == '') {
        clearVailCommodityContent(index)
        return false;
      }
      const filterA = store.state.loginInitializeData.filterList.BusinessPartnerId;
      let bpId = ''
      if (tableBody[index].partBCompanyId != '') {
        bpId = filterA.filter(x => x.afterTransfer == tableBody[index].partBCompanyId)[0]?.beforeTransfer
      }
      let config = {
        method: "get",
        url: "/CommodityMangeTable/GetSingle",
        params: {
          name: tableBody[index][fieldsName],
          bpId: bpId
        }
      };
      requestData.GetCache(config).then(res => {
        if (res == 'notfound') {
          clearVailCommodityContent(index)
        }
        else {
          tableBody[index].buyQty = 1
          tableBody[index].commodityNameBuy = res.split("|")[1]
          tableBody[index].commodityIdBuy = res.split("|")[0]
          tableBody[index].virtualGoods = res.split("|")[3] != 'True' ? true : false
          tableBody[index].unitPrice = res.split("|")[5]
          activeInput.activeIndex = -1
          searchResult.splice(0)
        }
      })
    }

    const checkQty = (index, e, fieName) => {
      if (e.target.value < 0) {
        tableBody[index][fieName] = ''
      }
      if (e.target.value > tableBody[index].orderQty) {
        ElMessage('采购数量不能大于销售数量')
        tableBody[index].buyQty = tableBody[index].orderQty
      }
    }

    const checkPrice = (index, e) => {
      if (e.target.value < 0) {
        tableBody[index].unitPrice = ''
      }
    }

    //#endregion
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          searchList()
        }
      }
    )


    const setActiveId = (id) => {
      activeInput.value = id
    }
    let selectAllWidth = ref('2.5rem')
    let trHeight = ref('1.875rem')
    let SnWidth = ref('3.75rem')
    let operationWidth = ref('8rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')
    let commodityWidth = ref('25rem')
    let virtualWidth = ref('5rem')
    let saleQtyWidth = ref('5rem')
    let priceWidth = ref('5rem')

    let SearchContent = reactive({
      SalesOnTheWayCode: '',
      DocumentId: '',
      Sn: '',
      PartACompanyId: '',
      PartBCompanyId: '',
      StaffId: '',
      DocumentDateH: '',
      DocumentDateL: '',
      DocumentType: '',
      CommodityName: '',
      CommodityId: '',
      Remark: '',
      CommodityNameBuy: '',
      CommodityIdBuy: '',
      OrderQty: '',
      UnitPrice: '',
      NotifyStatus: '',
      RefusedQty: '',
      ResponsedQty: '',
      DocumentTypeBuy: '',
      DocumentIdBuy: '',
      SnBuy: '',
      DocumentDateBuyL: '',
      DocumentDateBuyH: '',
      VirtualGoodsFS: '',
      ResponseStatus: '',
      DistributedOrNotFS: '',
      BuyerToBeSolveFS: '',
      SponserToBeSolveFS: '',
      StartType: '',
    })
    fillDefaultValue(SearchContent, TableName)

    const initializeSearch = () => {
      SearchContent.BuyerToBeSolveFS = 1
    }

    initializeSearch()

    //f计算表格内容总宽度
    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) +
        parseFloat(commodityWidth.value) +
        parseFloat(virtualWidth.value) +
        parseFloat(saleQtyWidth.value) +
        parseFloat(priceWidth.value) +
        parseFloat(operationWidth.value) +
        parseFloat(selectAllWidth.value) + 1.25 + tableHead.length * 1 + 0.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });

    let tableHead = reactive([
      { fieldsNameForVali: 'commodityName', showLen: 25, showName: '销售商品名称' },
      { fieldsNameForVali: 'orderQty', showLen: 3.75, showName: '数量' },
      { fieldsNameForVali: 'partBCompanyId', showLen: 9.375, showName: '发起方' },
      { fieldsNameForVali: 'startType', showLen: 8, showName: '发起类型' },
      { fieldsNameForVali: 'documentDate', showLen: 8, showName: '发起日期' },
      { fieldsNameForVali: 'remark', showLen: 18.75, showName: '备注' },
      // { fieldsNameForVali: 'documentId', showLen: 150, showName: '单据编码' },
      // { fieldsNameForVali: 'sn', showLen: 40, showName: '序' },
    ]);

    const searchElement = reactive({
      SortFieldsName: 'DocumentDate',
      SortDirection: 1
    })

    let tableBody = reactive([]);

    let ChangeSort = (fildsname) => {
      searchElement.SortFieldsName = ChangeFirToUp(fildsname)
      searchElement.SortDirection = -searchElement.SortDirection
      searchList()
    };

    const reSearch = () => {
      searchList()
    }

    let priceLock = reactive({})


    let searchList = () => {
      let config = {
        method: "post",
        url: '/SalesOnTheWayList/GetListByMulSearchForBuyer',
        data: Object.assign(SearchContent, searchElement),
      };
      requestData
        .GetData(config)
        .then((res) => {

          selectAll.value = false
          selectedItems.value.splice(0)
          tableBody.splice(0);
          tableBody.push.apply(tableBody, res['salesOnTheWayListMultiple']);
          changeArrContentToDate(tableBody, PageName)
          const filterList = store.state.loginInitializeData.filterList
          const companyFilter = filterList.PartBCompanyId
          const startTypFilter = filterList.StartType
          for (let item of tableBody) {
            for (let key in item) {
              if (key == 'partBCompanyId') {
                item[key] = companyFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
              if (key == 'startType') {
                item[key] = startTypFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
            }
            item.buyQty = ''
            if (item.buyerToBeSolve) {
              item.orderQty = item.orderQty - item.responsedQty - item.refusedQty
            }
            if (item.commodityIdBuy.trim()) {
              item.buyQty = item.orderQty
            }
            if (item.responsedQty > 0) {
              priceLock[item.salesOnTheWayCode] = true
            }
            getVmiCost(store.state.loginInitializeData.companyId, item.commodityId).then(res => {
              if (res > 0) {
                item.unitPrice = res
              }
            })
          }

        })
        .catch((err) => {


        });
    };
    searchList()


    const poMany = (indexs) => {
      return new Promise(function (resolve, reject) {
        const partACom = store.state.loginInitializeData.companyId
        const partBCom = transferToCode(tableBody[indexs[0]].partBCompanyId, "PartBCompanyId")
        const user = store.state.loginInitializeData.userName
        const userId = store.state.loginInitializeData.userId
        let inWareHouseId = defaultInWarehouse.value;
        const inWareHouseName = transferToName(inWareHouseId, 'WareHouseId')
        let CommodityNameArr = []
        getDocNo('PO').then(res => {
          const poHeadCode = res
          let poBody = []
          const indexLength = indexs.length
          for (let i = 0; i < indexLength; i++) {
            if (poBody.filter(x => x.CommodityId == tableBody[indexs[i]].commodityIdBuy)?.length > 0) {
              poBody.find(x => x.CommodityId == tableBody[indexs[i]].commodityIdBuy).OrderQty += tableBody[indexs[i]].buyQty
              poBody.find(x => x.CommodityId == tableBody[indexs[i]].commodityIdBuy).InStockQty += tableBody[indexs[i]].buyQty
              continue;
            }
            let b = {
              DocumentId: poHeadCode,
              Sn: +i + 1,
              PartACompanyId: partACom,
              PartBCompanyId: partBCom,
              DocumentDateTime: '',
              CommodityName: tableBody[indexs[i]].commodityNameBuy,
              CommodityId: tableBody[indexs[i]].commodityIdBuy,
              VirtualGoods: tableBody[indexs[i]].virtualGoods,
              OrderQty: tableBody[indexs[i]].buyQty,
              InStockQty: tableBody[indexs[i]].buyQty,
              Unit: '',
              UnitPrice: tableBody[indexs[i]].unitPrice,
              Amount: tableBody[indexs[i]].buyQty * tableBody[indexs[i]].unitPrice,
              Remark: tableBody[indexs[i]].virtualGoods ? `${tableBody[indexs[i]].commodityName}` : '',
              NotifyStatus: '',
              RefusedQty: '',
              ResponsedQty: '',
              ResponseStatus: '',
              InStockQty: tableBody[indexs[i]].buyQty,
              InStockStatus: 103,
              InspectedQty: '',
              InspectionStatus: '',
              InvoiceReceiveAmount: '',
              InvoiceReceivedStatus: '',
              MoneyPayedAmount: '',
              MoneyPayedStatus: '',
              SourceDocumentId: '',
              RootSourceDocumentId: poHeadCode,
              ModifiedTimes: '',
            }
            if (b.CommodityId && !b.VirtualGoods && CommodityNameArr.indexOf(b.CommodityId) <= -1) {
              CommodityNameArr.push(b.CommodityId)
            }
            poBody.push(b)
          }
          let poHead = {
            DocumentId: poHeadCode,
            DocumentTypeName: '',
            DocumentDate: '',
            DocumentDateTime: '',
            Online: '',
            PartACompanyId: partACom,
            PartBCompanyId: partBCom,
            UserId: userId,
            StaffId: '',
            SourceDocumentId: '',
            Amount: '',
            Qty: '',
            OutWareHouseId: inWareHouseId,
            InWareHouseId: defaultInWarehouse.value,
            SystemStatusId: 104,
            CapitalAccountId: '',
            Remark: '',
            RootSourceDocumentId: '',
          }
          openPoList(poBody, poHead, poHeadCode, false).then(res => {
            if (res != 'pass') {
              const errmessage = res
              deleteDocCenter(poHeadCode).then(res => {
                resolve(errmessage)
              })
            }
            else {
              let updateArr = []
              for (let i = 0; i < indexLength; i++) {
                let c = updatePoIn(tableBody[indexs[i]].salesOnTheWayCode, tableBody[indexs[i]].buyQty, poHeadCode, tableBody[indexs[i]].commodityIdBuy)
                updateArr.push(c)
              }
              Promise.all(updateArr).then(resArr => {
                if (inWareHouseName == '主仓库') {
                  setTimeout(() => {
                    updateWarning(partACom, CommodityNameArr)
                  }, 0);
                }
                resolve(resArr)
              })
            }
          })
        })
      })
    }

    const refuseOne = (index, remark) => {
      return new Promise(function (resolve, reject) {
        let onObj = tableBody[index];
        let qty = onObj.buyQty || onObj.orderQty
        remark = '拒绝原因:' + remark
        rejectSaleApply(onObj.salesOnTheWayCode, qty, remark).then(res => {
          resolve(res)
        })
      })
    }



    const actionPoOne = (index) => {
      let onObj = tableBody[index];
      if (!onObj.buyerToBeSolve) {

        ElMessage('此条无需处理')
        return false;
      }
      if (!onObj.buyQty) {

        ElMessage('请输入采购数量')
        return false;
      }
      if (onObj.buyQty > onObj.orderQty) {

        ElMessage('采购数量不允许超过客户出售数量')
        return false;
      }
      if (onObj.unitPrice <= 0) {
        let a = confirm('采购金额为0,请确认是否继续')
        if (a == false) {
          return false;
        }
      }
      let codes = []
      codes.push(tableBody[index].salesOnTheWayCode)
      lockSalesOneTheWay(codes).then(res => {
        try {
          if (res != 'pass') {
            ElMessage(res)
            searchList()
            return;
          }
          let indexs = []
          indexs.push(index)
          poMany(indexs).then(res => {
            if (res != 'pass') {
              searchList()
              ElMessage(`采购开单失败,原因是${res}`)
              return false;
            }
            else {
              searchList()
              emit('refreshMsg')
            }
          })


        } catch (error) {



        }
        finally {
          unLockSalesOneTheWay(codes)
        }
      }).catch(err => {

        searchList()
      })
    }

    const actionRefuseOne = () => {
      let b = confirm('确认拒绝，拒绝后无法恢复')
      if (!b) {
        return false;
      }
      let codes = []
      codes.push(tableBody[activeRejectIndex.value].salesOnTheWayCode)
      lockSalesOneTheWay(codes).then(res => {
        try {
          if (res != 'pass') {
            activeRejectRemark.value = ''
            rejectRemarkRef.value.closePopComm()
            ElMessage(res)
            searchList()
            return;
          }
          refuseOne(activeRejectIndex.value, activeRejectRemark.value).then(res => {
            activeRejectRemark.value = ''
            rejectRemarkRef.value.closePopComm()
            searchList()
            emit('refreshMsg')
          })

        } catch (error) {



        }

        finally {
          unLockSalesOneTheWay(codes)


        }



      }).catch(err => {
        
        searchList()
      })
    }

    const goHistory = () => {
      emit('goSalesOnTheWayHistory', 'buy')
    }

    return {
      partBFilterCopy, BuyerToBeSolveFSFilter, setActiveId,
      trHeight, SnWidth, operationWidth, tableHead, searchList, ChangeSort, tablewidth, tableBody,
      reSearch, searchElement, tbodyHeight, SearchContent, selectAllWidth,
      selectAll, selectedItems, commodityWidth,
      insertSearch, searchResult, stockBalanceCache, activeInput, setActiveInput, confirmOffset, offsetDistance,
      clearSearchResult, commTrOffset, solveSearchResult, saveEnterInValue, bindEnter, unbindEnter, confirmComName,
      virtualWidth, checkQty, saleQtyWidth, priceWidth, checkPrice, actionPoOne, actionRefuseOne, warehouseFilter, defaultInWarehouse,
      priceLock, closeRefusePop, rejectRemarkRef, activeRejectRemark, openRejectPop, goHistory


    }
  },
  components: {
    PopModel
  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;
@lableMarginLeft: 0.625rem;
@lableHeight: 2rem;
@inputHeight: 1.5rem;
@mainColor: #4285f4;

.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.searchinputLabel {
  margin-left: @lableMarginLeft;
  font-size: 0.8rem;
  height: @lableHeight;
  line-height: @lableHeight;
}
.searchinput {
  margin-top: calc((@lableHeight - @inputHeight) / 2);
  margin-bottom: calc((@lableHeight - @inputHeight) / 2);
  font-size: 0.9rem;
  border: none;
  outline: none;
  height: @inputHeight;
  line-height: @inputHeight;
}
.ActiveBox {
  border: 1px solid #ffa004 !important;
}

.searchInputBox {
  height: @lableHeight;
  border: 1px solid #c9c9c9;
  margin-left: @lableMarginLeft;
  border-radius: 0.31rem;
}

.searchItemContainer {
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
  display: flex;
}

.searchSetting {
  margin-left: calc(@lableMarginLeft*3);
  height: @lableHeight;
  display: flex;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}
.searchSetting:hover {
  cursor: pointer;
  color: @mainColor;
}
.settingIcon {
  font-family: "icomoon";
  font-size: 1.1rem;
  line-height: @lableHeight;
}

.settingText {
  margin-left: 0.3rem;
  font-size: 0.8rem;
  line-height: @lableHeight;
}
.dateInputContainer {
  display: flex;
}
.BodyTitle {
  width: 98%;
  margin-top: 0.5rem;
  margin-left: 1%;
}
.BodyTitleText {
  font-size: 1.2rem;
  font-weight: 200;
}
.long {
  width: 20rem;
}

.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 60%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.thSN {
  font-family: "icomoon";
  color: #fff;
  font-size: 16px;
}
.headtd {
  cursor: pointer;
}
.ShowUpArrow {
  position: relative;
}
.ShowUpArrow::after {
  content: "\ea32";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
  color: #fff;
}
.ShowDownArrow {
  position: relative;
}
.ShowDownArrow::after {
  content: "\ea36";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.tdSN,
.tableselect,
.tdoper,
.footsum,
.bodytr {
  text-align: center;
}
.bodytr {
  height: 2rem;
}

.tablefoot {
  margin-top: -1px;
}

.moreFunctionBox {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.selectAllCheckBox {
  cursor: pointer;
}
.commodityTr {
  position: relative;
}
.tableinputContainer {
  display: flex;
  position: relative;
}
.bodytr input {
  border: none;
  outline: none;
  height: 1.875rem;
  background-color: transparent;
  text-align: center;
}
.bodytr input:focus {
  border: 1px solid #ffa004;
}
.CommodityContainer {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  margin-top: 1px;
  border: 1px solid #cccccc;

  // border-left: 2px solid #4e71f2;
  // border-right: 2px solid #4e71f2;
  // border-bottom: 2px solid #4e71f2;
}
.searchContentTr {
  display: flex;
}
.searchContentTr:hover {
  background-color: #fff;
  background-color: #f5f5f6;
}
.searchContentTr input {
  text-align: left;
}

.searchContentTr input:hover {
  cursor: pointer;
}
.searchContentTrLast:hover {
  background-color: #fa2c2c;
  color: #fff;
  cursor: pointer;
}
.searchContentTrLast {
  padding-left: 0.3rem;
  text-align: left;
}
.checkbo {
  margin-left: 0.2rem;
  cursor: pointer;
}
.headtdEdit {
  cursor: pointer;
}
.highInput {
  background-color: #d9e1f2 !important;
}
.firstButton {
  margin-left: 1rem;
}

.todoLine {
  background-color: #fce4d6 !important;
}

.nottodoLine {
  background-color: #a6a6a6 !important;
}

.tipColortodo,
.tipColorfinish {
  height: 1.2rem;
  width: 1.2rem;
  margin-top: 0.3rem;
}
.tipColortodo {
  background-color: #fce4d6;
}
.tipColorfinish {
  background-color: #a6a6a6;
}
.tipWords {
  height: 1.2rem;
  line-height: 1.2rem;
  margin-top: 0.3rem;
  margin-left: 0.5rem;
}
.tipBox {
  display: flex;
  height: 1.75rem;
  width: 8rem;
  margin-top: 0.625rem;
  margin-left: 0.8rem;
}

.spancancel,
.spantt {
  display: inline-block;
  height: 1.25rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  line-height: 1.25rem;
  font-size: 0.5rem;
  margin-left: 0.5rem;
}
.spantt {
  background-color: #409eff;
}
.spancancel {
  background-color: #fa2c2c;
}
.inputBox {
  display: flex;
}
.popLable {
  margin-top: 0.5rem;
  line-height: 2.5rem;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 0.5rem;
}
.popInput {
  margin-top: 0.5rem;
  width: 28rem;
}
.mRight {
  margin-top: 0.1rem;
}
.lastButton {
  margin-right: 0.2rem;
}
.buttonBox {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  justify-content: flex-end;
}
.selectBoxModel {
  margin-right: 2rem;
}
</style>