<template>
  <PopModel ref="popModelRef">
    <div class="PopBody">

      <div class="bigContainer" v-for="item in addInputListTex" :key="item.fieldsNameForVali +item.tableNameForVali + 'add'">
        <div class="searchItemContainer" v-if="searchItemList[item.fieldsNameForVali] && searchItemList[item.fieldsNameForVali].addShow && item.tableNameForVali == searchItemList.tableName && openType == 'add'">
          <label class="searchinputLabel" :for="item.fieldsNameForVali + 'add'" v-text="searchItemList[item.fieldsNameForVali].showName + ':'"></label>
          <div class="searchInputBox" :class="{'ActiveBox':activeInput == item.fieldsNameForVali}"><input v-model="vmodelContent[item.fieldsNameForVali]" class="searchinput" :id="item.fieldsNameForVali + 'add'" type="text" @focus="setActiveId(item.fieldsNameForVali)" @blur="setActiveId('')"></div>
        </div>
      </div>
      <div class="bigContainer" v-for="item in addInputListSel" :key="item.fieldsNameForVali +item.tableNameForVali + 'add'">
        <div class="searchItemContainer" v-if="searchItemList[item.fieldsNameForVali] && searchItemList[item.fieldsNameForVali].addShow  && item.tableNameForVali == searchItemList.tableName && openType == 'add'">
          <label class="searchinputLabel" :for="item.fieldsNameForVali + 'add'" v-text="searchItemList[item.fieldsNameForVali].showName + ':'"></label>
          <div class="searchInputBox" :class="{'ActiveBox':activeInput == item.fieldsNameForVali}"><select v-model="vmodelContent[item.fieldsNameForVali]" class="searchinput" :id="item.fieldsNameForVali + 'add'" @focus="setActiveId(item.fieldsNameForVali)" @blur="setActiveId('')">
              <option v-for="item1 in searchItemList[item.fieldsNameForVali].Filter" :value="item1.beforeTransfer" :key="item.fieldsNameForVali + item1.beforeTransfer">{{item1.afterTransfer}}</option>
            </select></div>
        </div>
      </div>
      <div class="bigContainer" v-for="item in addInputListNum" :key="item.fieldsNameForVali +item.tableNameForVali + 'add'">
        <div class="searchItemContainer" v-if="searchItemList[item.fieldsNameForVali] && searchItemList[item.fieldsNameForVali].addShow && item.tableNameForVali == searchItemList.tableName && openType == 'add'">
          <label class="searchinputLabel" :for="item.fieldsNameForVali + 'add'" v-text="searchItemList[item.fieldsNameForVali].showName + ':'"></label>
          <div class="searchInputBox" :class="{'ActiveBox':activeInput == item.fieldsNameForVali}"><input v-model="vmodelContent[item.fieldsNameForVali]" class="searchinput" :id="item.fieldsNameForVali + 'add'" type="number" @focus="setActiveId(item.fieldsNameForVali)" @blur="setActiveId('')"></div>
        </div>
      </div>

      <div class="bigContainer" v-for="item in addInputListDat" :key="item.fieldsNameForVali +item.tableNameForVali + 'add'">
        <div class="searchItemContainer" v-if="searchItemList[item.fieldsNameForVali] && searchItemList[item.fieldsNameForVali].addShow && item.tableNameForVali == searchItemList.tableName && openType == 'add'">
          <label class="searchinputLabel" :for="item.fieldsNameForVali + 'add'" v-text="searchItemList[item.fieldsNameForVali].showName + ':'"></label>
          <div class="searchInputBox" :class="{'ActiveBox':activeInput == item.fieldsNameForVali}"><input v-model="vmodelContent[item.fieldsNameForVali]" class="searchinput" :id="item.fieldsNameForVali + 'add'" type="date" @focus="setActiveId(item.fieldsNameForVali)" @blur="setActiveId('')"></div>
        </div>
      </div>

      <div class="bigContainer" v-for="item in editInputListTex" :key="item.fieldsNameForVali +item.tableNameForVali + 'edit'">
        <div class="searchItemContainer" v-if="searchItemList[item.fieldsNameForVali] && searchItemList[item.fieldsNameForVali].editShow && item.tableNameForVali == searchItemList.tableName && openType == 'edit'">
          <label class="searchinputLabel" :for="item.fieldsNameForVali + 'edit'" v-text="searchItemList[item.fieldsNameForVali].showName + ':'"></label>
          <div class="searchInputBox" :class="{'ActiveBox':activeInput == item.fieldsNameForVali}"><input v-model="vmodelContent[item.fieldsNameForVali]" class="searchinput" :id="item.fieldsNameForVali + 'edit'" type="text" @focus="setActiveId(item.fieldsNameForVali)" @blur="setActiveId('')"></div>
        </div>
      </div>
      <div class="bigContainer" v-for="item in editInputListSel" :key="item.fieldsNameForVali +item.tableNameForVali + 'edit'">
        <div class="searchItemContainer" v-if="searchItemList[item.fieldsNameForVali] && searchItemList[item.fieldsNameForVali].editShow  && item.tableNameForVali == searchItemList.tableName && openType == 'edit'">
          <label class="searchinputLabel" :for="item.fieldsNameForVali + 'edit'" v-text="searchItemList[item.fieldsNameForVali].showName + ':'"></label>
          <div class="searchInputBox" :class="{'ActiveBox':activeInput == item.fieldsNameForVali}"><select v-model="vmodelContent[item.fieldsNameForVali]" class="searchinput" :id="item.fieldsNameForVali + 'edit'" @focus="setActiveId(item.fieldsNameForVali)" @blur="setActiveId('')">
              <option v-for="item1 in searchItemList[item.fieldsNameForVali].Filter" :value="item1.beforeTransfer" :key="item.fieldsNameForVali + item1.beforeTransfer">{{item1.afterTransfer}}</option>
            </select></div>
        </div>
      </div>
      <div class="bigContainer" v-for="item in editInputListNum" :key="item.fieldsNameForVali +item.tableNameForVali + 'edit'">
        <div class="searchItemContainer" v-if="searchItemList[item.fieldsNameForVali] && searchItemList[item.fieldsNameForVali].editShow && item.tableNameForVali == searchItemList.tableName && openType == 'edit'">
          <label class="searchinputLabel" :for="item.fieldsNameForVali + 'edit'" v-text="searchItemList[item.fieldsNameForVali].showName + ':'"></label>
          <div class="searchInputBox" :class="{'ActiveBox':activeInput == item.fieldsNameForVali}"><input v-model="vmodelContent[item.fieldsNameForVali]" class="searchinput" :id="item.fieldsNameForVali + 'edit'" type="number" @focus="setActiveId(item.fieldsNameForVali)" @blur="setActiveId('')"></div>
        </div>
      </div>

      <div class="bigContainer" v-for="item in editInputListDat" :key="item.fieldsNameForVali +item.tableNameForVali + 'edit'">
        <div class="searchItemContainer" v-if="searchItemList[item.fieldsNameForVali] && searchItemList[item.fieldsNameForVali].editShow && item.tableNameForVali == searchItemList.tableName && openType == 'edit'">
          <label class="searchinputLabel" :for="item.fieldsNameForVali + 'edit'" v-text="searchItemList[item.fieldsNameForVali].showName + ':'"></label>
          <div class="searchInputBox" :class="{'ActiveBox':activeInput == item.fieldsNameForVali}"><input v-model="vmodelContent[item.fieldsNameForVali]" class="searchinput" :id="item.fieldsNameForVali + 'edit'" type="date" @focus="setActiveId(item.fieldsNameForVali)" @blur="setActiveId('')"></div>
        </div>
      </div>

      <!-- <div class="searchItemContainer" v-if="searchItemList.SystemStatusId[openType + 'Show']">
        <label class="searchinputLabel" for="SystemStatusIdAE" v-text="searchItemList.SystemStatusId.showName + ':'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'SystemStatusId'}"><select v-model="vmodelContent.SystemStatusId" class="searchinput" id="SystemStatusIdAE" @focus="setActiveId('SystemStatusId')" @blur="setActiveId('')">
            <option v-for="item in searchItemList.SystemStatusId.Filter" :value="item.from" :key="'SystemStatusId' + item.from">{{item.to}}</option>
          </select></div>
      </div> -->
      <!-- <div class="searchItemContainer" v-if="searchItemList.AccountingSubjectId[openType + 'Show']">
        <label class="searchinputLabel" for="AccountingSubjectIdAE" v-text="searchItemList.AccountingSubjectId.showName + ':'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'AccountingSubjectId'}"><input v-model="vmodelContent.AccountingSubjectId" class="searchinput" id="AccountingSubjectIdAE" type="text" @focus="setActiveId('AccountingSubjectId')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer" v-if="searchItemList.AccountingSubjectName[openType + 'Show']">
        <label class="searchinputLabel" for="AccountingSubjectNameAE" v-text="searchItemList.AccountingSubjectName.showName + ':'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'AccountingSubjectName'}"><input v-model="vmodelContent.AccountingSubjectName" class="searchinput" id="AccountingSubjectNameAE" type="text" @focus="setActiveId('AccountingSubjectName')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer" v-if="searchItemList.IsExpenseOrNot[openType + 'Show']">
        <label class="searchinputLabel" for="IsExpenseOrNotAE" v-text="searchItemList.IsExpenseOrNot.showName + ':'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'IsExpenseOrNot'}"><select v-model="vmodelContent.IsExpenseOrNot" class="searchinput" id="IsExpenseOrNotAE" @focus="setActiveId('IsExpenseOrNot')" @blur="setActiveId('')">
            <option v-for="item in searchItemList.IsExpenseOrNot.Filter" :value="item.from" :key="'IsExpenseOrNot' + item.from">{{item.to}}</option>
          </select></div>
      </div>
      <div class="searchItemContainer" v-if="searchItemList.Weight[openType + 'Show']">
        <label class="searchinputLabel" for="WeightAE" v-text="searchItemList.Weight.showName + ':'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'Weight'}"><input v-model="vmodelContent.Weight" class="searchinput" id="WeightAE" type="text" @focus="setActiveId('Weight')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer" v-if="searchItemList.Remark[openType + 'Show']">
        <label class="searchinputLabel" for="RemarkAE" v-text="searchItemList.Remark.showName + ':'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'Remark'}"><input v-model="vmodelContent.Remark" class="searchinput" id="RemarkAE" type="text" @focus="setActiveId('Remark')" @blur="setActiveId('')"></div>
      </div> -->
    </div>
    <div class="popfooterforBp">
      <el-button type="warning" @click="closePopComm">关闭</el-button>
      <el-button type="primary" v-if="openType == 'edit'" @click="SendEditContent()">修改并关闭</el-button>
      <el-button type="success" v-if="openType == 'add'" @click="SendAddContent()">新增不关闭</el-button>
      <el-button type="primary" v-if="openType == 'add'" @click="SendAddContent(),closePopComm()">新增并关闭</el-button>
    </div>
  </PopModel>
</template>

<script>
import { inject, onMounted, onUnmounted, reactive, ref, toRefs } from 'vue-demi'
import { fillSonContent, ChangeFirToLo, dataFilterConverse, clearObj } from '@/hooks/tools'
import requestData from '@/hooks/requestData';
import PopModel from '@/components/Common/PopModel.vue';
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
export default {
  components: {
    PopModel
  },
  setup(props, { emit }) {
    const store = useStore();
    let activeInput = ref('');
    const setActiveId = (id) => {
      activeInput.value = id
    }
    let searchItemList = inject('searchItemList')

    

    let addInputListTex = reactive(store.state.loginInitializeData.listForValidations.filter(x => x.validateMethod == 'add' && x.addTrans == true && x.addEditInputType == 'tex'));
    let addInputListNum = reactive(store.state.loginInitializeData.listForValidations.filter(x => x.validateMethod == 'add' && x.addTrans == true && x.addEditInputType == 'num'));
    let addInputListSel = reactive(store.state.loginInitializeData.listForValidations.filter(x => x.validateMethod == 'add' && x.addTrans == true && x.addEditInputType == 'sel'));
    let addInputListDat = reactive(store.state.loginInitializeData.listForValidations.filter(x => x.validateMethod == 'add' && x.addTrans == true && x.addEditInputType == 'dat'));

    let editInputListTex = reactive(store.state.loginInitializeData.listForValidations.filter(x => x.validateMethod == 'add' && x.editTrans == true && x.addEditInputType == 'tex'));
    let editInputListNum = reactive(store.state.loginInitializeData.listForValidations.filter(x => x.validateMethod == 'add' && x.editTrans == true && x.addEditInputType == 'num'));
    let editInputListSel = reactive(store.state.loginInitializeData.listForValidations.filter(x => x.validateMethod == 'add' && x.editTrans == true && x.addEditInputType == 'sel'));
    let editInputListDat = reactive(store.state.loginInitializeData.listForValidations.filter(x => x.validateMethod == 'add' && x.editTrans == true && x.addEditInputType == 'dat'));

    const popModelRef = ref(null);

    const openPopComm = () => {
      popModelRef.value.openPopComm()
    }

    const closePopComm = () => {
      popModelRef.value.closePopComm()
    }

    let openType = ref('');


    let vmodelContent = reactive({})

    const fillEditContent = (method, content) => {
      openType.value = method;
      fillSonContent(vmodelContent, searchItemList, openType.value)
      for (let key in content) {
        for (let key1 in vmodelContent) {
          if (ChangeFirToLo(key) == ChangeFirToLo(key1)) {
            vmodelContent[key1] = content[key]
          }
        }
      }
      dataFilterConverse(vmodelContent, searchItemList)
      openPopComm()
    }
    const refreshAddContent = () => {
      for (let key in searchItemList) {
        if (searchItemList[key].addTrans == true) {
          vmodelContent[key] = searchItemList[key].defaultValue
        }
      }
    }

    const fillAddContent = (method) => {
      openType.value = method;
      refreshAddContent()
      openPopComm()
    }

    const SendEditContent = () => {
      
      let config = {
        method: "post",
        url: '/' + searchItemList.tableName + '/Update',
        data: vmodelContent,
      };
      requestData.GetData(config).then((res) => {
        if (res == "pass") {
          
          emit('refresh')
          closePopComm()
        } else {
          
          ElMessage(res);
        }
      }).catch(err=>{
        
        ElMessage(err)
      })
    };

    //f发送新增方法
    let SendAddContent = () => {
      
      if (searchItemList.tableName == 'StaffList') {
        if (vmodelContent.IsAllowLoginIn) {
          if (!vmodelContent.Password) {
            
            ElMessage('允许登录必须填写密码')
            return;
          }
        }
      }
      let config = {
        method: "post",
        url: '/' + searchItemList.tableName + '/Add',
        data: vmodelContent,
      };
      requestData.GetData(config).then((res) => {
        if (res == "pass") {
          refreshAddContent()
          
          emit('refresh')
        } else {
          
          ElMessage(res);
        }
      }).catch(err=>{
        
        ElMessage(err)
      })
    };

    const clearStatus = () => {
      activeInput.value = '';
      openType.value = '';
      clearObj(vmodelContent)
    }

    onMounted(() => {

    })
    onUnmounted(() => {
      clearStatus()
    })
    return {
      fillEditContent,
      setActiveId,
      activeInput,
      vmodelContent,
      searchItemList,
      openType,
      SendEditContent,
      fillAddContent,
      SendAddContent,
      popModelRef,
      openPopComm,
      closePopComm,
      addInputListTex,
      addInputListNum,
      addInputListSel,
      addInputListDat,
      editInputListTex,
      editInputListNum,
      editInputListSel,
      editInputListDat,
    }
  }
}
</script>

<style scoped lang="less">
@lableMarginLeft: 0.625rem;
@lableHeight: 2rem;
@smallToBigGap: 0.6rem;
@inputHeight: 1.5rem;
@popWidth: 38rem;

.PopBody {
  width: @popWidth;
  display: flex;
  flex-wrap: wrap;
}

.searchItemContainer {
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
  display: flex;
  width: calc(@popWidth / 2);
  justify-content: space-between;
}

.ActiveBox {
  border: 1px solid #ffa004 !important;
}
.popfooterforBp {
  display: flex;
  margin: 1rem;
  align-items: center;
  justify-content: flex-end;
}

.searchinputLabel {
  font-size: 0.8rem;
  height: @lableHeight;
  line-height: @lableHeight;
  margin-left: @lableMarginLeft;
}
.searchInputBox {
  height: @lableHeight;
  border: 1px solid #c9c9c9;
  border-radius: 0.31rem;
  width: 60%;
  margin-right: @lableMarginLeft;
}
.searchinput {
  margin-top: calc((@lableHeight - @inputHeight) / 2);
  margin-bottom: calc((@lableHeight - @inputHeight) / 2);
  font-size: 0.9rem;
  border: none;
  outline: none;
  height: @inputHeight;
  line-height: @inputHeight;
  width: 100%;
}
</style>