<template>
  <div class="tableLoaderContainer">
    <BodyTitle></BodyTitle>
    <SearchContentForAccountsChange @deliverSearchItems="deliverSearchItems" ref="searchRef"></SearchContentForAccountsChange>
    <TableShowForDiy @saveActiveItem="saveActiveItem" @clearActive="clearActive" ref="tableShowRef"></TableShowForDiy>
    <div class="moreFunctionBox">
      <el-button class="funbutton" type="primary" @click="downloadAccountsChange()">账单下载</el-button>
    </div>
  </div>
</template>

<script>
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import SearchContentForAccountsChange from '@/components/Content/MainBodyContent/SearchContentForAccountsChange.vue'
import TableShowForDiy from '@/components/Content/MainBodyContent/TableShowForDiy.vue';
import { useStore } from "vuex";
import DiyShowTable from '@/hooks/DiyShowTable'
import { changeToViewCode, transferToCode, transferToName } from '@/hooks/OpenOrder';
import { watch } from 'vue-demi';
import requestData from '@/hooks/requestData';
import { ChangeYMD } from '@/hooks/tools';
import xlsx from "xlsx";
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
  setup() {
    //#region 定义区
    const store = useStore();
    //初始化输入表名称
    const TableName = 'AccountsChangeList';

    const PageName = 'AccountsChangeList'

    let { tableShowRef, searchRef, deliverSearchItems, activeItem, saveActiveItem, clearActive } = DiyShowTable(TableName, PageName)
    //根据表名给searchItemList赋值

    const getDataFromAcc = (value) => {
      searchRef.value.searchItems['PartBCompanyId'] = transferToCode(value.value.partBCompanyId, 'PartBCompanyId')
      setTimeout(() => {
        searchRef.value.searchList()
      }, 100);
    }
    //#endregion

    const downloadAccountsChange = () => {

      const parbName = transferToName(searchRef.value.searchItems.PartBCompanyId, 'PartBCompanyId')

      if (!parbName) {
        ElMessage(`未选择往来单位`)
        return;
      }

      let dateLString = ''
      let dateHString = ''

      if (searchRef.value.searchItems.DocumentDateL == '') {
        dateLString = '最开始'
      }
      else {
        dateLString = searchRef.value.searchItems.DocumentDateL
      }

      if (searchRef.value.searchItems.DocumentDateH == '') {
        dateHString = ChangeYMD(Date.now())
      }
      else {
        dateHString = searchRef.value.searchItems.DocumentDateH
      }


      ElMessageBox.confirm(
        `是否下载从${dateLString}至${dateHString}期间${parbName}的账单`,
        '提示',
        {
          confirmButtonText: '是',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        let config = {
          method: "post",
          url: '/AccountsChangeList/GetListByMulSearchForBill',
          data: Object.assign(searchRef.value.searchItems),
        };
        requestData.GetData(config).then(res => {

          if (!res.accountsChangeListMultiple) {
            ElMessage(`无账单下载`)

            return;
          }

          let objArr = []

          for (let item of res.accountsChangeListMultiple) {
            let temObj = {}
            temObj.单据日期 = ChangeYMD(item.documentDate)
            temObj.单据类型 = item.documentType
            temObj.单据编号 = item.documentId
            temObj.金额 = (item.qty * item.unitPrice).toFixed(2)
            temObj.单据描述 = item.remark
            temObj.变动后金额 = item.accountsAfterChange
            objArr.push(temObj)
          }

          let sheet = xlsx.utils.json_to_sheet(objArr);
          let book = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(book, sheet, "sheet1");
          xlsx.writeFile(book, `账单-${parbName}(${dateLString}-->${dateHString}).xlsx`);
        })

      }).catch(() => {

      })

    }

    const code = changeToViewCode('AccountsChangeList')
    



    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          store.commit('modifysearchEle', {
            keyName: 'LinesPerPage',
            valueName: 100
          })
          store.commit('modifysearchEle', {
            keyName: 'Page',
            valueName: 1
          })
          store.commit('modifysearchEle', {
            keyName: 'SortFieldsName',
            valueName: ''
          })
          store.commit('modifysearchEle', {
            keyName: 'SortDirection',
            valueName: -1
          })
        }
      }
    )




    return {
      deliverSearchItems,
      tableShowRef,
      saveActiveItem,
      activeItem, clearActive, searchRef, getDataFromAcc,
      downloadAccountsChange
    }
  },
  components: {
    BodyTitle,
    SearchContentForAccountsChange,
    TableShowForDiy
  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}
.funbutton {
  margin-top: 1rem;
  margin-left: 1rem;
}
</style>