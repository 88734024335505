<template>
  <div class="ActiveBarBigContainer">
    <div class="activeGoLeft"><span class="dead" v-show="leftValue.leftQty == 0"></span><span class="alive" @click="activeBarGoLeft()" v-show="leftValue.leftQty != 0"></span></div>
    <div class="ActiveBarBoard">
      <div class="ActiveBarContainer" :style="{'left':leftValue.leftWidth + 'rem'}">
        <div class="ActiveBarContentItem" v-for="(item, key) in $store.state.loginInitializeData.ActiveWindow" :key="item+'ActiveBar'" :class="{'ActiveBarContentItemActive':$store.state.loginInitializeData.ActiveWindow[key] == $store.state.loginInitializeData.ShowWindow[0]}" @mouseenter="saveHoverKey(key)" @mouseleave="saveHoverKey(-1)">
          <div class="ActiveBarContentItemLeft" :style="{'width':+$store.state.loginInitializeData.authList.viewLists.find(x=>x.viewCode == item).viewName.length + 1.5 + 'rem'}" @click.stop="ChangeShowWindow(key)">{{ $store.state.loginInitializeData.authList.viewLists.find(x=>x.viewCode == item).viewName }}</div>
          <div class="ActiveBarDelete" @click.stop="CloseActiveWindow(key)"><span v-show="key == hoverKey"></span></div>
        </div>
      </div>
    </div>
    <div class="activeGoRight"><span class="dead" v-show="leftValue.leftQty + 2 > $store.state.loginInitializeData.ActiveWindow.length"></span><span class="alive" @click="activeBarGoRight()" v-show="leftValue.leftQty + 2 <= $store.state.loginInitializeData.ActiveWindow.length"></span></div>
    <div class="activeFuncButtonContainer">
      <div class="activeFuncButton" v-show="!functionShow" @click.stop="changeFunctionShow('open')"></div>
      <div class="activeFuncButtonClose" v-show="functionShow" @click="changeFunctionShow('close')"></div>
      <div class="activeFuncBox" v-show="functionShow">
        <div class="activeFuncItem" @click="refreshThisPage(),changeFunctionShow('close')"><span class="iconShow"></span>刷新当前页</div>
        <div class="activeFuncItem" @click="deleteThisPage(),changeFunctionShow('close')"><span class="iconShow"></span>关闭当前页</div>
        <div class="activeFuncItem" @click="deleteOtherPages(),changeFunctionShow('close')"><span class="iconShow"></span>关闭其他页</div>
        <div class="activeFuncItem" @click="deleteAllPages(),changeFunctionShow('close')"><span class="iconShow"></span>关闭所有页</div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, watch } from 'vue-demi';
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const CloseActiveWindow = (key) => {
      store.commit("DeleteActiveWindow", key);
      if (key == store.state.loginInitializeData.ActiveWindow.length &&
        leftValue.leftQty == key
      ) {
        activeBarGoLeft()
      }
    };
    const ChangeShowWindow = (key) => {
      store.commit("AlterShowWindow", key);
    };
    let functionShow = ref(false);
    const closeFunc = () => {
      functionShow.value = false
    }
    const changeFunctionShow = (demand) => {
      if (demand == 'change') {
        functionShow.value = !functionShow.value
      }
      if (demand == 'close') {
        functionShow.value = false
      }
      if (demand == 'open') {
        functionShow.value = true
      }
    }
    let leftValue = reactive({
      leftWidth: 0,
      leftQty: 0
    })
    const checkWidth = (a) => {
      return store.state.loginInitializeData.authList.viewLists.find(x => x.viewCode == store.state.loginInitializeData.ActiveWindow[leftValue.leftQty - 1 + a]).viewName.length + 3.7
    }

    const activeBarGoLeft = () => {
      if (leftValue.leftQty == 0) {
        return false;
      }
      leftValue.leftWidth += checkWidth(0);
      leftValue.leftQty -= 1;

    }
    const activeBarGoRight = () => {
      if (leftValue.leftQty + 2 > store.state.loginInitializeData.ActiveWindow.length) {
        return false;
      }
      leftValue.leftWidth -= checkWidth(1);
      leftValue.leftQty += 1;
    }

    const findShowKey = () => {
      let key = -1
      for (let a in store.state.loginInitializeData.ActiveWindow) {
        if (store.state.loginInitializeData.ActiveWindow[a] == store.state.loginInitializeData.ShowWindow[0]) {
          key = a
        }
      }
      return key;
    }

    const refreshThisPage = () => {
      let key = findShowKey()
      store.commit('refreshShowWindowReal', key)
    }

    const deleteThisPage = () => {
      let key = findShowKey()
      CloseActiveWindow(key)
    }


    const deleteOtherPages = () => {
      store.commit('equalizeShowAndActive')
      leftValue.leftWidth = 0
      leftValue.leftQty = 0
    }

    const deleteAllPages = () => {
      store.commit('clearActive')
      leftValue.leftWidth = 0
      leftValue.leftQty = 0
    }

    let hoverKey = ref(-1)

    const saveHoverKey = (value) => {
      hoverKey.value = value
    } 

    watch(functionShow, (newValue, oldValue) => {
      if (newValue == true) {
        window.addEventListener("click", closeFunc);
      } else {
        window.removeEventListener("click", closeFunc);
      }
    });



    watch(store.state.loginInitializeData.ShowWindow, (val, old) => {
      let index = store.state.loginInitializeData.ActiveWindow.indexOf(val[0])

      if (index <= leftValue.leftQty) {
        let b = leftValue.leftQty - index
        for (let a = 0; a < b; a++) {
          activeBarGoLeft()
        }
      }
    })
    return {
      CloseActiveWindow,
      ChangeShowWindow,
      functionShow,
      changeFunctionShow,
      leftValue,
      activeBarGoLeft,
      activeBarGoRight,
      refreshThisPage,
      deleteThisPage,
      deleteOtherPages,
      deleteAllPages,hoverKey,saveHoverKey
    };
  },
  components: {},
};
</script>
<style scoped lang="less">
@mainColor: #4285f4;
@marginTopRem: 0.3rem;
@marginLeftRem: 0.2rem;
@contentHeight: calc(100% - @marginTopRem * 2);

.ActiveBarBigContainer {
  width: 100%;
  height: 100%;
  display: flex;
}

.activeGoLeft,
.activeGoRight {
  margin-top: @marginTopRem;
  margin-right: @marginLeftRem;
  height: @contentHeight;
  width: 2rem;
  font-family: "icomoon";
  font-size: 1.5rem;
  text-align: center;
  border: 1px solid #dedede;
  background-color: #fff;
  line-height: calc(2.5rem - @marginTopRem * 2);
}
.activeGoLeft {
  margin-left: @marginLeftRem;
}

.activeFuncButton {
  font-family: "icomoon";
  font-size: 1rem;
  text-align: center;
  line-height: calc(2.5rem - @marginTopRem * 2);
  color: @mainColor;
}

.activeFuncButtonClose {
  font-family: "icomoon";
  font-size: 1rem;
  text-align: center;
  line-height: calc(2.5rem - @marginTopRem * 2);
  color: #cc0010;
}

.iconShow {
  font-family: "icomoon";
  text-align: center;
  line-height: calc(2.5rem - @marginTopRem * 2);
  font-size: 0.5rem;
  padding-right: 0.4rem;
  color: @mainColor;
}

.activeFuncButtonContainer {
  position: relative;
  margin-top: @marginTopRem;
  margin-right: @marginLeftRem;
  width: 2rem;
  height: @contentHeight;
  line-height: calc(2.5rem - @marginTopRem * 2);
  background-color: #fff;
}
.alive {
  color: @mainColor;
}

.ActiveBarBoard {
  margin-top: @marginTopRem;
  margin-right: @marginLeftRem;
  height: @contentHeight;
  line-height: calc(2.5rem - @marginTopRem * 2);
  overflow: hidden;
  width: calc(100% - @marginLeftRem * 5 - 6rem);
  position: relative;
}

.ActiveBarContainer {
  position: absolute;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  transition: all 0.5s;
}
.ActiveBarContentItem {
  position: relative;
  display: flex;
  font-size: 1rem;
  line-height: calc(2.5rem - @marginTopRem * 2);
  height: 100%;
  width: auto;
  border: 1px solid #dedede;
  margin-right: @marginLeftRem;
  background-color: #fff;
}

.ActiveBarContentItem:hover {
  background-color: #e1f5fd;
  cursor: pointer;
  color: @mainColor !important;
}

// .ActiveBarContentItem:hover::after {
//   position: absolute;
//   right: 10px;
//   content: "";
//   font-family: "icomoon";
//   font-size: 1rem;
// }

.ActiveBarDelete {
  width: 1.8rem;
  font-family: "icomoon";
  font-size: 1rem;
  // background-color: transparent;
  // background-color: red;
  padding-left: .4rem;
  padding-right: .4rem;
}

.ActiveBarContentItemActive {
  background-color: #e1f5fd;
}

.activeFuncBox {
  position: absolute;
  width: 8rem;
  text-align: center;
  left: -8rem;
  z-index: 99;
}

.activeFuncItem {
  font-size: 1rem;
  background-color: #fff;
  margin-top: 0.1rem;
}

.activeFuncButton:hover,
.alive:hover,
.activeFuncItem,
.activeFuncButtonClose {
  cursor: pointer;
}
.activeFuncItem:hover {
  transform: translateY(-1px);
}
.activeFuncButton:hover,
.activeFuncButtonClose:hover {
  transform: translateY(2px);
}

.alive:hover {
  font-size: 1.6rem;
}

.ActiveBarContentItemLeft {
  // background-color: blue;
  padding-left: .5rem;
  padding-right: .1rem;
}
</style>