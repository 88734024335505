<template>
  <div class="inputLoaderContainer">
    <BodyTitle></BodyTitle>
    <!-- <InputHead></InputHead> -->
    <span class="secondTitle" v-if="repairRecord.code || obRecord.code">维修/预定信息:</span>
    <div class="InputHeadContainer" v-if="repairRecord.code || obRecord.code">
      <div class="InputForChooseContainer" v-show="repairRecord.data.customerName"><label class="InputForChooseLabel">客户姓名：</label><input v-model="repairRecord.data.customerName" class="InputForChooseShort" type="text" disabled="disabled"></div>
      <div class="InputForChooseContainer" v-show="repairRecord.data.customerContact"><label class="InputForChooseLabel">联系方式：</label><input v-model="repairRecord.data.customerContact" class="InputForChooseLong" type="text" disabled="disabled"></div>
      <div class="InputForChooseContainer" v-show="repairRecord.data.machineType"><label class="InputForChooseLabel">机型：</label><input v-model="repairRecord.data.machineType" class="InputForChooseLong" type="text" disabled="disabled"></div>
      <div class="InputForChooseContainer" v-show="repairRecord.data.breakdownCause"><label class="InputForChooseLabel">故障：</label><input v-model="repairRecord.data.breakdownCause" class="InputForChooseTooLong" type="text" disabled="disabled"></div>
      <div class="InputForChooseContainer" v-show="repairRecord.data.draftQuotation"><label class="InputForChooseLabel">维修报价：</label><input v-model="repairRecord.data.draftQuotation" class="InputForChooseShort" type="number" disabled="disabled"></div>
      <div class="InputForChooseContainer" v-show="obRecord.data.commodityName"><label class="InputForChooseLabel">预定物品：</label><input v-model="obRecord.data.commodityName" class="InputForChooseTooLong" type="text" disabled="disabled"></div>
      <div class="InputForChooseContainer" v-show="obRecord.data.customerQuotation"><label class="InputForChooseLabel">报价：</label><input v-model="obRecord.data.customerQuotation" class="InputForChooseShort" type="number" disabled="disabled"></div>
      <div class="InputForChooseContainer" v-show="obRecord.data.customerDeposit"><label class="InputForChooseLabel">订金：</label><input v-model="obRecord.data.customerDeposit" class="InputForChooseShort" type="number" disabled="disabled"></div>
      <div class="closeRepairConnection" @click="closeRepairConnect">取消关联</div>
    </div>
    <div class="InputHeadContainer">
      <div class="InputForChooseContainer"><span class="importantIndi">*</span><label class="InputForChooseLabel" for="PartBCompanyIdRoInput1">客户:</label><select disabled="disabled" class="InputForChoose RightBorder" id="PartBCompanyIdRoInput1" v-model="searchItemList.documentContent.PartBCompanyId">
          <option v-for="itema2 in bussinessList" :key="itema2.beforeTransfer + 'RoInputBussiness'" :value="itema2.beforeTransfer">{{itema2.afterTransfer}}</option>
        </select>
      </div>
      <div class="InputForChooseContainer"><span class="importantIndi">*</span><label class="InputForChooseLabel" for="OutWareHouseIdRoInput1">出库仓库：</label><select disabled="disabled" class="InputForChoose RightBorder" id="OutWareHouseIdRoInput1" v-model="searchItemList.documentContent.OutWareHouseId">
          <option v-for="itema1 in outwareHouseList" :key="itema1.beforeTransfer + 'RoInputOutWarehouse'" :value="itema1.beforeTransfer">{{itema1.afterTransfer}}</option>
        </select>
      </div>
      <div class="InputForChooseContainer"><span class="importantIndi">*</span><label class="InputForChooseLabel" for="StaffIdRoInput1">工程师：</label><select class="InputForChoose RightBorder" id="StaffIdRoInput1" v-model="searchItemList.documentContent.StaffId">
          <option v-for="itema3 in staffList" :key="itema3.beforeTransfer + 'RoInputOutStaff'" :value="itema3.beforeTransfer">{{itema3.afterTransfer}}</option>
        </select>
      </div>
      <div class="InputForChooseContainer" v-show="customerSourceArr.length > 0"><label class="InputForChooseLabel" for="customerSource">客户来源:</label><select v-model="customerSource" class="InputForChoose RightBorder" id="customerSource">
          <option v-for="item in customerSourceArr" :key="item.customerSourceId + 'RoInputForCuSource'" :value="item.customerSourceId">{{item.customerSourceName}}</option>
        </select></div>

      <!-- <div class="InputForChooseContainer"><label class="InputForChooseLabel">维修工程师：</label><input class="InputForChoose" v-model="repairStaff" type="text" @blur="checkStName($event),unbindStEnter()" @mousedown="bindStEnter">
        <div class="InputForChooseFunc" @click="openStPop"></div>
        <StPop ref="stPopRef" :useMain="'RO'" @getValue="getValue"></StPop>
      </div> -->
      <div class="InputForChooseContainer"><label class="InputForChooseLabel">备注：</label><input class="InputForChooseTooLong" type="text" v-model="searchItemList.documentContent.Remark"></div>
    </div>
    <div class="EditTableLine">
      <ComScanIn ref="comScanInRef" :scanInValue="scanInValue" @scanIn="scanIn"></ComScanIn>
      <div class="retailSet" @click="openRetailSet">零售配置</div>
      <div class="carryRepair" @click="openRrList()">加载维修单</div>
      <div class="carryRepair" @click="openObList()">加载客户预定</div>
      <!-- <div class="InputForChooseContainer"><label class="InputForChooseLabel" for="discountAmountRoList">优惠金额/订金:</label><input class="InputForChooseshort" id="discountAmountRoList" type="number" v-model.lazy="dicountValue" @blur="checkDiscount"></div>
      <div v-if="platFormFilter.length > 0" class="InputForChooseContainer"><label class="InputForChooseLabel" for="platformChooseRoList">平台选择:</label><select class="InputForChoose RightBorder" id="platformChooseRoList" v-model="platformData.platformChoose">
          <option v-for="itema3 in platFormFilter" :key="itema3.beforeTransfer + 'RoInputPlatformChoose'" :value="itema3.beforeTransfer">{{itema3.afterTransfer}}</option>
        </select></div>
      <div v-if="platFormFilter.length > 0" class="InputForChooseContainer"><label class="InputForChooseLabel" for="platformMiRoList">平台收款:</label><input class="InputForChooseshort" id="platformMiRoList" type="number" v-model="platformData.platformMiAmount"></div>
      <div v-if="platFormFilter.length > 0" class="InputForChooseContainer"><label class="InputForChooseLabel" for="platformVoucherRoList">券码:</label><input class="InputForChooseshort" id="platformVoucherRoList" type="text" v-model="platformData.voucherCode"></div>
      <span class="ForthLineText">实付金额:<b> {{valueAfterDiscount}}</b></span> -->
    </div>
    <InputBody ref="inputBodyRef" @countValueAfterDiscount="countValueAfterDiscount" @clearScanIn="clearScanIn"></InputBody>
    <!-- <div class="EditTableLine">
      <ComScanIn ref="comScanInRef" :scanInValue="scanInValue" @scanIn="scanIn"></ComScanIn>
      <div class="retailSet" @click="openRetailSet">零售配置</div>
      <div class="carryRepair" @click="openRrList()">加载维修单</div>
      <div class="carryRepair" @click="openObList()">加载客户预定</div>
      <div class="InputForChooseContainer"><label class="InputForChooseLabel" for="discountAmountRoList">优惠金额/订金:</label><input class="InputForChooseshort" id="discountAmountRoList" type="number" v-model.lazy="dicountValue" @blur="checkDiscount"></div>
      <div v-if="platFormFilter.length > 0" class="InputForChooseContainer"><label class="InputForChooseLabel" for="platformChooseRoList">平台选择:</label><select class="InputForChoose RightBorder" id="platformChooseRoList" v-model="platformData.platformChoose">
          <option v-for="itema3 in platFormFilter" :key="itema3.beforeTransfer + 'RoInputPlatformChoose'" :value="itema3.beforeTransfer">{{itema3.afterTransfer}}</option>
        </select></div>
      <div v-if="platFormFilter.length > 0" class="InputForChooseContainer"><label class="InputForChooseLabel" for="platformMiRoList">平台收款:</label><input class="InputForChooseshort" id="platformMiRoList" type="number" v-model="platformData.platformMiAmount"></div>
      <div v-if="platFormFilter.length > 0" class="InputForChooseContainer"><label class="InputForChooseLabel" for="platformVoucherRoList">券码:</label><input class="InputForChooseshort" id="platformVoucherRoList" type="text" v-model="platformData.voucherCode"></div>
      <span class="ForthLineText">实付金额:<b> {{valueAfterDiscount}}</b></span>
    </div> -->
    <div class="EditTableLine marTop1rem">
      <!-- <ComScanIn ref="comScanInRef" :scanInValue="scanInValue" @scanIn="scanIn"></ComScanIn>
      <div class="retailSet" @click="openRetailSet">零售配置</div>
      <div class="carryRepair" @click="openRrList()">加载维修单</div>
      <div class="carryRepair" @click="openObList()">加载客户预定</div> -->
      <div class="InputForChooseContainer"><label class="InputForChooseLabel" for="discountAmountRoList">优惠金额/订金:</label><input class="InputForChooseshort" id="discountAmountRoList" type="number" v-model.lazy="dicountValue" @blur="checkDiscount" :disabled="discountChangePermit"></div>
      <div v-if="platFormFilter.length > 0" class="InputForChooseContainer"><label class="InputForChooseLabel" for="platformChooseRoList">平台选择:</label><select class="InputForChoose RightBorder" id="platformChooseRoList" v-model="platformData.platformChoose">
          <option v-for="itema3 in platFormFilter" :key="itema3.beforeTransfer + 'RoInputPlatformChoose'" :value="itema3.beforeTransfer">{{itema3.afterTransfer}}</option>
        </select></div>
      <div v-if="platFormFilter.length > 0" class="InputForChooseContainer"><label class="InputForChooseLabel" for="platformMiRoList">平台收款:</label><input class="InputForChooseshort" id="platformMiRoList" type="number" v-model.lazy="platformData.platformMiAmount" @blur="checkPlatMi()"></div>
      <div v-if="platFormFilter.length > 0" class="InputForChooseContainer"><label class="InputForChooseLabel" for="platformVoucherRoList">券码:</label><input class="InputForChooselong" id="platformVoucherRoList" type="text" v-model="platformData.voucherCode"></div>
      <div v-if="platFormFilter.length > 0" class="InputForChooseContainer icon clearPlat" @click="clearPlatInfo()"></div>
      <span class="ForthLineText marLeft2rem">本次实付金额:<b> {{valueAfterDiscount}}</b></span>

    </div>
    <div class="EditTableLastLine">
      <!-- 维护第七层的内容 -->
      <div class="EditTableFuncButton" :style="{background:'#fbb80d'}" @click="openList('RO')" v-show="searchItemList.documentContent.PartBCompanyId != retailCustomerCode">开单并记账</div>
      <div class="EditTableFuncButton EditTableFuncButtonAjust" @click="openList('ROMI')" v-show="MiButtonShow">开单并收款</div>
    </div>
    <PopModel ref="retailSetPop">
      <div class="PopBody"></div>
      <div class="popLine"><label for="retialWarehouse">出库仓库：</label><select v-model="retailConfigData.OutWareHouseId" class="popSelect" id="retialWarehouse">
          <option v-for="itema1 in outwareHouseList" :key="itema1.beforeTransfer + 'RoInputOutWarehouse'" :value="itema1.beforeTransfer">{{itema1.afterTransfer}}</option>
        </select></div>
      <div class="popLine"><label for="retialBuyer">购买单位：</label><select v-model="retailConfigData.PartBCompanyId" class="popSelect" id="retialBuyer">
          <option v-for="itema2 in bussinessList" :key="itema2.beforeTransfer + 'RoInputBussiness'" :value="itema2.beforeTransfer">{{itema2.afterTransfer}}</option>
        </select></div>
      <div class="popLine"><label for="retialStaff">营业人员：</label><select v-model="retailConfigData.StaffId" class="popSelect" id="retialStaff">
          <option v-for="itema3 in staffList" :key="itema3.beforeTransfer + 'RoInputOutStaff'" :value="itema3.beforeTransfer">{{itema3.afterTransfer}}</option>
        </select></div>
      <div class="popfooterforBp">
        <el-button type="warning" @click="closeRetailSet">关闭</el-button>
        <el-button type="primary" @click="updateRetailInfo(),closeRetailSet()">保存</el-button>
      </div>
    </PopModel>
    <RrPop ref="rrPopRef" @receveRepairRecord="receveRepairRecord"></RrPop>
    <ObPop ref="obPopRef" @receveObRecord="receveObRecord"></ObPop>
  </div>
</template>
<script>
import { computed, provide, reactive, ref, watch } from 'vue-demi'
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import InputHead from '@/components/Content/MainBodyContent/InputHead.vue'
import InputBody from '@/components/Content/MainBodyContent/InputBody.vue'
import {
  getDocNo, openOnTheWayList, getLoginData, copyArrayDoc, getFrStaff, combineProfit, getFrAddress,
  deleteDocCenter, closeObChasing, openRoList, checkDocConditionPormise, clearTableContent, copyOrder,
  refreshLoginConfigData, updateRetailConfig, openGrList, openMiList, transferToCode, openErList, getSourceFrcode,
  closeRepairPromise, updateCustomerSourceStaticPromise, addSourceStatisticsPromise, openStaffKpiStatisticsPromise, addRecycleOnTheWay, updateWarning, transferToName, getCommodityIdsByObId, getBodyInstance, openGdList, openEdList
} from '@/hooks/OpenOrder'
import { transferLoginDataOnly } from '@/hooks/loginAffair';
import ComScanIn from '@/components/Content/FunctionUnit/ComScanIn.vue'
import InputTable from '@/hooks/InputTable'
import { useStore } from "vuex";
import PopModel from '@/components/Common/PopModel.vue';
import RrPop from '@/components/Common/GlobalPop/RrPop.vue';
import ObPop from '@/components/Common/GlobalPop/ObPop.vue';
import StPop from '@/components/Common/GlobalPop/StPop.vue';
import requestData from '@/hooks/requestData';
import { copyArray, copyObj, getDateTime, getUUId, isRealNum, MiForMeiTuan } from '@/hooks/tools'
import { lockObTracing, unLockObTracing, lockRepairRecord, unLockRepairRecord } from '@/hooks/LockAffair'
import { getAllRootMaterialList } from '@/hooks/getCache'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  components: {
    BodyTitle,
    InputHead,
    InputBody,
    ComScanIn,
    PopModel, RrPop, StPop, ObPop
  },
  setup(props, { emit }) {
    const store = useStore()
    const RootTableName = 'RoList';
    let { itemListHead, itemListBody, searchItemList } = InputTable(RootTableName)
    searchItemList.docTitleTwo = 'RO'
    provide('searchItemList', searchItemList)
    searchItemList.TableName = 'RoInput'
    const tableTitle = `${store.state.loginInitializeData.userName}-零售配置`
    provide('tableTitle', tableTitle)
    const customerSourceArr = copyArray(store.state.loginInitializeData.authList.customerSourceLists)

    const platFormFilter = copyArray(store.state.loginInitializeData.filterList.PlatFormList)

    const partBFilter = copyArray(store.state.loginInitializeData.filterList.PartBCompanyId)

    let lengthForPlatFormList = platFormFilter.length

    if (lengthForPlatFormList > 0) {
      for (let k = lengthForPlatFormList - 1; k >= 0; k--) {
        if (!partBFilter.find(x => x.beforeTransfer == platFormFilter[k].beforeTransfer)) {
          platFormFilter.splice(k, 1)
        }
      }
    }

    const retailCustomerCode = ref(transferToCode('散客', 'PartBCompanyId'))

    const MiButtonShow = computed(() => {
      let b = transferToName(searchItemList.documentContent.PartBCompanyId, 'PartBCompanyId')
      let a = store.state.loginInitializeData.companyNameAbbre
      if ((b.indexOf('小懒') > -1 || b.indexOf('青屠') > -1) && (a.indexOf('小懒') > -1 || a.indexOf('青屠') > -1)) {
        return false;
      }
      else {
        return true;
      }
    })

    const retailSetPop = ref(null)

    const rrPopRef = ref(null)

    const obPopRef = ref(null)

    let rootMaterialList = []

    getAllRootMaterialList().then(res => {
      rootMaterialList = res
    })

    const openRrList = () => {
      rrPopRef.value.openPopComm()
    }

    const openRetailSet = () => {
      retailSetPop.value.openPopComm()
    }

    const openObList = () => {
      obPopRef.value.openPopComm()
    }

    const closeRetailSet = () => {
      fillConfig()
      retailSetPop.value.closePopComm()
    }

    let repairRecord = reactive({
      code: '',
      data: {}
    })

    let obRecord = reactive({
      code: '',
      data: {}
    })

    let discountChangePermit = ref(false)

    const receveObRecord = (data) => {
      obRecord.code = data.obTracingCode
      obRecord.data = data
      searchItemList.documentContent.StaffId = repairRecord.code ? searchItemList.documentContent.StaffId : (data.staffId ? transferToCode(data.staffId, 'StaffId') : searchItemList.documentContent.StaffId)
      dicountValue.value = data.customerDeposit
      if (parseFloat(dicountValue.value) > 0) {
        discountChangePermit.value = true
      }
      getCommodityIdsByObId(data.documentId).then(idArr => {
        if (!idArr?.length) {
          return
        }
        else {
          for (let item of idArr) {
            if (inputBodyRef.value.AddContentBody.find(x => x.CommodityId == item.substring(0, 18))) {
              continue;
            }
            let b = {
              target: {
                value: item
              }
            }
            comScanInRef.value.scanIn(b)
          }
        }
      })
    }

    const comScanInRef = ref(null)

    const getDataFromRepairList = (value) => {
      receveRepairRecord(value)
    }

    const getDataFromObTracing = (value) => {
      receveObRecord(value)
    }

    const receveRepairRecord = (data) => {
      repairRecord.code = data.repairRecordCode
      repairRecord.data = data
      searchItemList.documentContent.StaffId = data.staffId ? transferToCode(data.staffId, 'StaffId') : searchItemList.documentContent.StaffId
      searchItemList.documentContent.PartBCompanyId = transferToCode(data.partBCompanyId, 'PartBCompanyId')
      let c = transferToCode(data.machineClass, 'MachineClass') + '999999999999999911'
      let num = true
      for (let item of inputBodyRef.value.AddContentBody) {
        if (item.CommodityId == c.substring(0, 18)) {
          num = false
        }
      }
      if (num) {
        let b = {
          target: {
            value: c
          }
        }
        comScanInRef.value.scanIn(b)
        setTimeout(() => {
          for (let item of inputBodyRef.value.AddContentBody) {
            if (item.CommodityId && item.CommodityId == c.substring(0, 18)) {
              item.UnitPrice = data.draftQuotation
              item.Amount = item.UnitPrice
            }
          }
        }, 500);
      }

      const config = {
        method: "get",
        url: "/FrList/GetCustomSource",
        params: {
          id: data.documentId
        }
      };
      requestData.GetCache(config).then(res => {
        customerSource.value = res
      })
    }

    const getDataFromQuotation = (value) => {
      if (value[0].commodityId) {
        let b = {
          target: {
            value: value[0].commodityId + '11'
          }
        }
        comScanInRef.value.scanIn(b)
      }
      setTimeout(() => {
        for (let item of inputBodyRef.value.AddContentBody) {
          if (item.CommodityId == value[0].commodityId) {
            item.SalesAttributeNo = value[0].salesAttributeId
            item.UnitPrice = value[0].quotationForCustomer
            item.Amount = item.UnitPrice * item.OrderQty
          }
        }
      }, 1000);
    }

    const closeRepairConnect = () => {
      obRecord.code = ''
      obRecord.data = {}
      repairRecord.code = '';
      repairRecord.data = {};
      customerSource.value = ''
      dicountValue.value = 0
      discountChangePermit.value = false
      fillDocContent()
      clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
    }

    let customerSource = ref('')


    // //#region 员工模块
    // let repairStaff = ref('')

    // const getValue = (value) => {
    //   repairStaff.value = value
    // }

    // const stPopRef = ref(null)

    // const openStPop = () => {
    //   stPopRef.value.openPopComm()
    // }

    // const enterSt = (e) => {
    //   if (e.keyCode == 13) {
    //     checkStName(e)
    //   }
    // }
    // const bindStEnter = () => {
    //   window.addEventListener('keydown', enterSt)
    // }

    // const unbindStEnter = () => {
    //   window.removeEventListener('keydown', enterSt)
    // }

    // const checkStName = (e) => {
    //   if (e.target.value == '') { return false; }
    //   let config = {
    //     method: "get",
    //     url: "/StaffList/GetSingleAuth",
    //     params: {
    //       name: e.target.value
    //     }
    //   }
    //   requestData.GetCache(config).then(res => {
    //     if (res == 'notfound') {
    //       let sendMsg = repairStaff.value
    //       repairStaff.value = ''
    //       openStPop()
    //       stPopRef.value.sendMsgSolve(sendMsg)
    //       stPopRef.value.searchList()
    //       return 'notfound'
    //     }
    //     else {
    //       return 'found'
    //     }
    //   })
    // }
    // //#endregion


    let retailConfigData = reactive({
      OutWareHouseId: '',
      PartBCompanyId: '',
      StaffId: ''
    })

    const bussinessList = store.state.loginInitializeData.filterList.BusinessPartnerId
    const outwareHouseList = store.state.loginInitializeData.filterList.OutWareHouseId
    let staffList = copyArray(store.state.loginInitializeData.filterList.StaffId)

    for (let item of staffList) {
      const length1 = staffList.length
      const userName = store.state.loginInitializeData.userName
      if (userName != '管理员') {
        for (let i = length1 - 1; i >= 0; i--) {
          if (staffList[i].afterTransfer == '管理员') {
            staffList.splice(i)
          }
        }
      }
    }

    const retailConfig = store.state.loginInitializeData.retailConfigurationManage

    const fillConfig = () => {
      retailConfigData.OutWareHouseId = retailConfig.wareHouseId
      retailConfigData.PartBCompanyId = retailConfig.purchaseUnit
      retailConfigData.StaffId = retailConfig.staffId
    }

    const fillDocContent = () => {
      searchItemList.documentContent.OutWareHouseId = retailConfig.wareHouseId
      searchItemList.documentContent.PartBCompanyId = retailConfig.purchaseUnit
      searchItemList.documentContent.StaffId = retailConfig.staffId
    }

    fillConfig()
    fillDocContent()

    const updateRetailInfo = () => {
      if (
        retailConfigData.OutWareHouseId != retailConfig.wareHouseId ||
        retailConfigData.PartBCompanyId != retailConfig.purchaseUnit ||
        retailConfigData.StaffId != retailConfig.staffId
      ) {
        if (confirm('更改配置将刷新页面，重新营业')) {
          let b = {
            StaffId: retailConfigData.StaffId,
            WareHouseId: retailConfigData.OutWareHouseId,
            PurchaseUnit: retailConfigData.PartBCompanyId
          }
          updateRetailConfig(b).then(res => {
            if (res != 'pass') {
              ElMessage(res)
            }
            else {
              ElMessage('更新成功，即将重新营业')
              const funcAfterRefresh = () => {
                store.commit('refreshShowWindow')
              }
              refreshLoginConfigData(funcAfterRefresh)
            }

          })
        }
      }
    }

    //#region 采购优惠组件
    let inputBodyRef = ref(null)
    const countValueAfterDiscount = (value) => {
      valueReceived.value = value.value.Amount
    }
    let dicountValue = ref(0)

    let valueReceived = ref(0)



    let valueAfterDiscount = computed(() => {
      return (valueReceived.value - (dicountValue.value || 0) - (platformData.platformMiAmount || 0)).toFixed(2) + '元'
    })
    //#endregion

    //#region 扫描组件
    let scanInValue = ref('');

    const scanIn = (res) => {
      inputBodyRef.value.scanInData(res)
    }

    const clearScanIn = () => {
      scanInValue.value = '';
    }
    const checkDiscount = () => {
      if (dicountValue.value > valueReceived.value || dicountValue.value < 0) {
        ElMessage('优惠金额不能大于订单金额或为负数')
        dicountValue.value = 0
      }
      else {
        valueAfterDiscount = valueReceived.value - (dicountValue.value || 0) - (platformData.platformMiAmount || 0) + '元'
      }
    }
    //#endregion

    const getRecycleCompanyId = (outWareId) => {
      const warehouseList = store.state.loginInitializeData.authList.wareHouseLists
      let companyIdResult = ''
      if (warehouseList.find(x => x.wareHouseId == outWareId).owedCompanyId) {
        companyIdResult = warehouseList.find(x => x.wareHouseId == outWareId).owedCompanyId
      }
      else {
        companyIdResult = store.state.loginInitializeData.companyId
      }
      return companyIdResult;
    }

    const goShipRepairMachine = (repairRecord) => {
      return new Promise(function (resolve, reject) {
        let body = getBodyInstance('ToBeShippedList')
        body.DocumentId = repairRecord.documentId
        body.Sn = 1
        body.DocumentType = '维修单'
        body.DocumentDate = repairRecord.orderAcceptedDate
        body.CommodityName = repairRecord.machineType + '-' + repairRecord.breakdownCause
        body.OrderQty = 1
        body.PartACompanyId = repairRecord.partACompanyId
        body.PartBCompanyId = transferToCode(repairRecord.partBCompanyId, 'PartBCompanyId')
        body.UserId = transferToCode(repairRecord.userId, 'UserId')
        body.StaffId = transferToCode(repairRecord.staffId, 'StaffId')
        body.Remark = repairRecord.remark
        if (repairRecord.partBCompanyId == '散客' && repairRecord.serviceType == '邮寄维修') {
          getFrAddress(repairRecord.documentId).then(res => {
            body.Remark = `${repairRecord.customerName}-${repairRecord.customerContact}-${res}`
            const config = {
              method: "post",
              url: "/ToBeShippedList/Add",
              data: body
            };
            requestData.GetData(config).then(res => {
              resolve(res)
            })

          })
        }
        else {
          const config = {
            method: "post",
            url: "/ToBeShippedList/Add",
            data: body
          };
          requestData.GetData(config).then(res => {
            resolve(res)
          })
        }
      })
    }

    //#region 开单功能模块
    // let docHeadCode = ''
    // const refreshDocNo = () => {
    //   getDocNo(searchItemList.docTitleTwo).then(res => {
    //     docHeadCode = res
    //   })
    // }
    // refreshDocNo()

    const openErListInRo = (body, staffId, DocHead) => {
      return new Promise(function (resolve, reject) {
        getDocNo('ER').then(res => {
          const erHeadId = res
          const partBId = transferToCode('散客', 'PartBCompanyId')
          let erbody = [{
            DocumentId: erHeadId,
            Sn: 1,
            PartACompanyId: body.partACompanyId,
            PartBCompanyId: partBId,
            OrderQty: 1,
            OutStockQty: 1,
            StaffId: staffId,
            UnitPrice: body.customerDeposit,
            Remark: `订金收入:${body.customerDeposit}`,
            SourceDocumentId: '',
            RootSourceDocumentId: DocHead,
            ModifiedTimes: 0,
          }]
          let erdocHead = {
            DocumentId: erHeadId,
            DocumentTypeName: '',
            DocumentDate: '',
            DocumentDateTime: '',
            Online: '',
            PartACompanyId: body.partACompanyId,
            PartBCompanyId: partBId,
            UserId: '',
            StaffId: staffId,
            SourceDocumentId: '',
            Amount: body.customerDeposit,
            Qty: 1,
            OutWareHouseId: '',
            InWareHouseId: '',
            SystemStatusId: 105,
            CapitalAccountId: '',
            Remark: '',
            RootSourceDocumentId: DocHead,
          }
          openErList(erbody, erdocHead, erHeadId).then(res => {
            resolve(res)
          })
        })
      })
    }

    const openGrListInRo = (docHeadCopy, discount, roHeadCode, staffIdCopy, platMi) => {
      return new Promise(function (resolve, reject) {
        getDocNo('GR').then(res => {
          let grHeadCode = res
          let docHeadCopy2 = copyObj(docHeadCopy)
          let grHead = {
            DocumentId: grHeadCode,
            DocumentTypeName: '',
            DocumentDate: '',
            DocumentDateTime: '',
            Online: '',
            PartACompanyId: store.state.loginInitializeData.companyId,
            PartBCompanyId: docHeadCopy2.PartBCompanyId,
            UserId: '',
            StaffId: staffIdCopy,
            SourceDocumentId: '',
            Amount: discount,
            Qty: 1,
            OutWareHouseId: '',
            InWareHouseId: '',
            SystemStatusId: 105,
            CapitalAccountId: '',
            Remark: '销售折扣',
            RootSourceDocumentId: roHeadCode,
          }
          let grBody = [{
            DocumentId: grHeadCode,
            Sn: 1,
            PartACompanyId: store.state.loginInitializeData.companyId,
            PartBCompanyId: docHeadCopy2.PartBCompanyId,
            OrderQty: 1,
            InStockQty: 1,
            OutStockQty: 1,
            UnitPrice: discount,
            Remark: `${obRecord.data?.customerDeposit ? '订金:' + obRecord.data?.customerDeposit + '元' : ''}` + `${platMi ? '平台收款:' + platMi + '元' : ''}` + `${(discount - (obRecord.data?.customerDeposit || 0) - platMi) ? '优惠了:' + (discount - (obRecord.data?.customerDeposit || 0) - platMi) + '元' : ''}`,
            SourceDocumentId: docHeadCopy.DocumentId,
            RootSourceDocumentId: roHeadCode
          }]
          openGrList(grBody, grHead, grHeadCode).then(res => {
            resolve(res)
          })
        })
      })
    }

    const openMiListInRo = (body, discount, roAmount, docHead) => {
      return new Promise(function (resolve, reject) {
        getDocNo('MI').then(res => {
          const miHeadCode = res
          let miBody = [{
            DocumentId: miHeadCode,
            Sn: 1,
            PartACompanyId: store.state.loginInitializeData.companyId,
            PartBCompanyId: body.PartBCompanyId,
            OrderQty: 1,
            InStockQty: 1,
            OutStockQty: 1,
            UnitPrice: roAmount - (discount || 0),
            Amount: roAmount - (discount || 0),
            Remark: '自动开单',
            SourceDocumentId: docHead,
            RootSourceDocumentId: docHead,
            SystemStatusId: 101
          }]
          let miHead = {
            DocumentId: miHeadCode,
            DocumentTypeName: '',
            DocumentDate: '',
            DocumentDateTime: '',
            Online: '',
            PartACompanyId: store.state.loginInitializeData.companyId,
            PartBCompanyId: body.PartBCompanyId,
            UserId: '',
            StaffId: body.StaffId,
            SourceDocumentId: '',
            Amount: roAmount - (discount || 0),
            Qty: 1,
            OutWareHouseId: '',
            InWareHouseId: '',
            SystemStatusId: 105,
            CapitalAccountId: '',
            Remark: '',
            RootSourceDocumentId: docHead,
          }
          openMiList(miBody, miHead, miHeadCode).then(res => {
            resolve(res)
          })
        })
      })
    }

    const openPurOnTheWay = (body, docHead, discount, amount, repairRecord) => {
      return new Promise(function (resolve, reject) {
        let remark1 = ""
        let dateNow = new Date();
        const length1 = body.length;
        for (let i = 0; i <= length1 - 1; i++) {
          if (i == 0) {
            remark1 += `${body[i].OrderQty}个${body[i].CommodityName}:${body[i].UnitPrice}元`
          }
          else {
            remark1 += `-${body[i].OrderQty}个${body[i].CommodityName}:${body[i].UnitPrice}元`
          }
        }
        if (discount) {
          remark1 += `-优惠金额为${discount}`
        }
        let comJudge = +body[0]?.CommodityId.substring(0, 2)
        let commId = ''
        let commName = ''
        switch (comJudge) {
          case 10:
            commId = '109999999999999999';
            commName = '手机维修费';
            break;
          case 11:
            commId = '119999999999999999';
            commName = '平板电脑维修费';
            break;
          case 12:
            commId = '129999999999999999';
            commName = '笔记本电脑维修费';
            break;
          case 13:
            commId = '139999999999999999';
            commName = '电子手表维修费';
            break;
          case 14:
            commId = '149999999999999999';
            commName = '台式电脑维修费';
            break;
          case 21:
            commId = '219999999999999999';
            commName = '其他维修费';
            break;
          default:
            commId = '229999999999999999';
            commName = '虚拟商品';
            break;
        }
        if (repairRecord?.documentId) {
          getSourceFrcode(repairRecord?.documentId).then(res => {
            const docId = res
            getFrStaff(docId).then(res => {
              let onTheWayModel = {
                OnTheWayCode: '',
                DocumentId: docId,
                Sn: 1,
                PartACompanyId: store.state.loginInitializeData.companyId,
                PartBCompanyId: docHead.PartBCompanyId,
                StaffId: res,
                DocumentDate: '',
                DocumentType: '',
                CommodityName: `维修${repairRecord.machineType}产生的费用:`,
                CommodityId: '',
                Remark: remark1,
                CommodityNameSale: commName,
                CommodityIdSale: commId,
                OrderQty: 1,
                UnitPrice: amount - discount,
                NotifyStatus: '',
                RefusedQty: '',
                ResponsedQty: 1,
                DocumentTypeSale: '销售单',
                DocumentIdSale: body[0]?.DocumentId,
                SnSale: 1,
                DocumentDateSale: '',
                VirtualGoods: true,
                ResponseStatus: '',
                DistributedOrNot: true,
                SupplierToBeSolve: false,
                CustomerToBeSolve: true,
                StartType: 106,
                DocumentDateSale: dateNow
              }
              openOnTheWayList(onTheWayModel).then(res => {
                resolve(res)
              })
            })
          })
        }
        else {
          let onTheWayModel = {
            OnTheWayCode: '',
            DocumentId: '',
            Sn: '',
            PartACompanyId: store.state.loginInitializeData.companyId,
            PartBCompanyId: docHead.PartBCompanyId,
            StaffId: '',
            DocumentDate: '',
            DocumentType: '',
            CommodityName: '',
            CommodityId: '',
            Remark: remark1,
            CommodityNameSale: commName,
            CommodityIdSale: commId,
            OrderQty: 1,
            UnitPrice: amount - discount,
            NotifyStatus: '',
            RefusedQty: '',
            ResponsedQty: 1,
            DocumentTypeSale: '销售单',
            DocumentIdSale: body[0]?.DocumentId,
            SnSale: 1,
            DocumentDateSale: '',
            VirtualGoods: true,
            ResponseStatus: '',
            DistributedOrNot: true,
            SupplierToBeSolve: false,
            CustomerToBeSolve: true,
            StartType: 106,
          }
          openOnTheWayList(onTheWayModel).then(res => {
            resolve(res)
          })
        }
      })
    }

    const storage = window.localStorage;

    const user = store.state.loginInitializeData.userId



    const copyOrderFromDocCenter = (value) => {
      clearTableContent(inputBodyRef.value.AddContentBody, searchItemList.addBodyContent)
      copyOrder(value, searchItemList.documentContent, inputBodyRef.value.AddContentBody, searchItemList.addBodyContent).then(res => {
      })
    }

    let platformData = reactive({
      platformChoose: '',
      platformMiAmount: '',
      voucherCode: ''
    })

    const clearPlatInfo = () => {
      platformData.platformChoose = ''
      platformData.platformMiAmount = ''
      platformData.voucherCode = ''
    }

    const openPlatCount = (body, platformData, docHeadCode) => {
      return new Promise(function (resolve, reject) {
        const partAId = store.state.loginInitializeData.companyId
        const userId = store.state.loginInitializeData.userId
        const platName = transferToName(platformData.platformChoose, 'PartBCompanyId')

        let platFormMi = platformData.platformMiAmount
        if (platName.indexOf('美团') > -1) {
          platFormMi = MiForMeiTuan(platformData.platformMiAmount)
        }
        const serviceFee = +(platformData.platformMiAmount - platFormMi).toFixed(2)
        let docHeadLocal = getBodyInstance('DocumentCenter')
        let docHeadPlat = getBodyInstance('DocumentCenter')
        let docBodyLocal = getBodyInstance('GdList')
        let docBodyPlat = getBodyInstance('EdList')
        getDocNo('GD').then(res => {
          const gdCode = res
          docHeadLocal.DocumentId = gdCode
          docHeadLocal.PartACompanyId = partAId
          docHeadLocal.PartBCompanyId = platformData.platformChoose
          docHeadLocal.UserId = userId
          docHeadLocal.StaffId = body.StaffId
          docHeadLocal.SourceDocumentId = docHeadCode
          docHeadLocal.Amount = platFormMi
          docHeadLocal.Qty = 1
          docHeadLocal.SystemStatusId = 104
          docHeadLocal.Remark = `${platName}收款,扣除服务费${serviceFee}元后，金额为:${platFormMi}元,券码为:${platformData.voucherCode}`
          docHeadLocal.RootSourceDocumentId = docHeadCode

          docBodyLocal.DocumentId = gdCode
          docBodyLocal.Sn = 1
          docBodyLocal.PartACompanyId = partAId
          docBodyLocal.PartBCompanyId = platformData.platformChoose
          docBodyLocal.OrderQty = 1
          docBodyLocal.InStockQty = 1
          docBodyLocal.OutStockQty = 1
          docBodyLocal.UnitPrice = platFormMi
          docBodyLocal.Remark = `${platName}收款,扣除服务费${serviceFee}元后，金额为:${platFormMi}元,券码为:${platformData.voucherCode}`
          docBodyLocal.SourceDocumentId = docHeadCode
          docBodyLocal.RootSourceDocumentId = docHeadCode

          let docBodyArr = []
          docBodyArr.push(docBodyLocal)

          openGdList(docBodyArr, docHeadLocal, gdCode).then(res => {
            if (res != 'pass') {
              resolve(res)
            }
            else {
              getDocNo('ED').then(res => {
                const edCode = res
                docHeadPlat.DocumentId = edCode
                docHeadPlat.PartACompanyId = platformData.platformChoose
                docHeadPlat.PartBCompanyId = partAId
                docHeadPlat.SourceDocumentId = edCode
                docHeadPlat.Amount = platformData.platformMiAmount
                docHeadPlat.Qty = 1
                docHeadPlat.StaffId = 999999999
                docHeadPlat.SystemStatusId = 104
                docHeadPlat.Remark = `${platName}收款,扣除服务费${serviceFee}元后，金额为:${platFormMi}元,券码为:${platformData.voucherCode}`
                docHeadPlat.RootSourceDocumentId = edCode

                docBodyPlat.DocumentId = edCode
                docBodyPlat.Sn = 1
                docBodyPlat.PartACompanyId = platformData.platformChoose
                docBodyPlat.PartBCompanyId = partAId
                docBodyPlat.OrderQty = 1
                docBodyPlat.InStockQty = 1
                docBodyPlat.OutStockQty = 1
                docBodyPlat.UnitPrice = platformData.platformMiAmount
                docBodyPlat.Remark = `${platName}收款,扣除服务费${serviceFee}元后，金额为:${platFormMi}元,券码为:${platformData.voucherCode}`
                docBodyPlat.SourceDocumentId = edCode
                docBodyPlat.RootSourceDocumentId = edCode

                let docBodyArrEd = []
                docBodyArrEd.push(docBodyPlat)
                openEdList(docBodyArrEd, docHeadPlat, edCode).then(res => {
                  resolve('pass')
                })
              })
            }
          }).catch(err => {
            resolve(err)
          })
        })
      })
    }

    const checkPlatMi = () => {
      if (platformData.platformMiAmount > valueReceived.value || platformData.platformMiAmount < 0) {
        ElMessage('平台收款金额不能大于订单金额或为负数')
        platformData.platformMiAmount = 0
      }
      else {
        valueAfterDiscount = valueReceived.value - (dicountValue.value || 0) - (platformData.platformMiAmount || 0) + '元'
      }
    }

    const openList = (typeName) => {
      for (let index in inputBodyRef.value.AddContentBody) {
        if (inputBodyRef.value.AddContentBody[index].CommodityName != '' && !inputBodyRef.value.AddContentBody[index].SalesAttributeNo) {
          ElMessage(`第${+index + 1}行中${inputBodyRef.value.AddContentBody[index].CommodityName}的销售属性未填写`)
          return
        }
      }
      if (!searchItemList.documentContent.StaffId) {
        ElMessage("工程师未填写")
        return
      }
      if (platformData.platformChoose != '' || platformData.platformMiAmount != '' || platformData.voucherCode != '') {
        if (platformData.platformChoose == '') {
          ElMessage('请选择平台')
          return;
        }
        if (platformData.platformMiAmount == '' || platformData.platformMiAmount == 0) {
          ElMessage('请输入平台收款金额')
          return;
        }
        if (platformData.voucherCode == '') {
          ElMessage('请输入券码')
          return;
        }
      }


      let errCheckArr = []
      let contentCheck = checkDocConditionPormise(searchItemList.documentContent, itemListHead, inputBodyRef.value.AddContentBody, itemListBody)
      errCheckArr.push(contentCheck)
      const obRecodeCodeCopy = obRecord.code

      const repairRecordCopy = repairRecord.code

      if (obRecodeCodeCopy) {
        let obArr = []
        obArr.push(obRecodeCodeCopy)
        const obCheck = lockObTracing(obArr)
        errCheckArr.push(obCheck)
      }
      if (repairRecordCopy) {
        let repairArr = []
        repairArr.push(repairRecordCopy)
        const repairCheck = lockRepairRecord(repairArr)
        errCheckArr.push(repairCheck)
      }
      Promise.all(errCheckArr).then(errArr => {
        for (let item of errArr) {
          if (item != 'pass') {
            if (obRecodeCodeCopy) {
              let obArr1 = []
              obArr1.push(obRecodeCodeCopy)
              unLockObTracing(obArr1)
            }
            if (repairRecordCopy) {
              let repairArr = []
              repairArr.push(repairRecordCopy)
              unLockRepairRecord(repairArr)
            }
            ElMessage(item)
            return;
          }
        }
        getLoginData().then(res => {
          if (res != 0) {
            transferLoginDataOnly(res)
          }
          getDocNo(searchItemList.docTitleTwo).then(res => {
            const docHeadCodeCopy = res
            const outWareHouseIdCopy = searchItemList.documentContent.OutWareHouseId
            const staffIdCopy = searchItemList.documentContent.StaffId
            const partBCom = searchItemList.documentContent.PartBCompanyId
            const partAName = store.state.loginInitializeData.companyNameAbbre
            const timeNow = getDateTime()
            let discount = dicountValue.value || 0;
            const platMi = platformData.platformMiAmount || 0;
            let commodityArr = []
            let roAmount = 0
            for (let item of inputBodyRef.value.AddContentBody) {
              if (item.CommodityName) {
                item.PartACompanyId = store.state.loginInitializeData.companyId
                item.PartBCompanyId = partBCom
                item.InStockQty = item.OrderQty
                item.DocumentId = docHeadCodeCopy
                item.RootSourceDocumentId = docHeadCodeCopy
                item.SourceDocumentId = repairRecord.code == '' ? docHeadCodeCopy : repairRecord.data.documentId
                item.Remark += `零售了${item.OrderQty}个${item.CommodityName},金额是${item.OrderQty * item.UnitPrice}`
                roAmount += item.OrderQty * item.UnitPrice
              }
              if (item.CommodityId && !item.VirtualGoods) {
                commodityArr.push(item.CommodityId)
              }
            }
            roAmount = +roAmount.toFixed(2)
            if (roAmount < (discount + platMi)) {
              if (obRecodeCodeCopy) {
                let obArr1 = []
                obArr1.push(obRecodeCodeCopy)
                unLockObTracing(obArr1)
              }
              if (repairRecordCopy) {
                let repairArr = []
                repairArr.push(repairRecordCopy)
                unLockRepairRecord(repairArr)
              }
              ElMessage("实收金额不能为负")
              return
            }
            let contentBodyCopy = copyArrayDoc(inputBodyRef.value.AddContentBody, 'CommodityName')
            const OutWareHouseName = isRealNum(searchItemList.documentContent.OutWareHouseId) ? transferToName(searchItemList.documentContent.OutWareHouseId, 'WareHouseId') : searchItemList.documentContent.OutWareHouseId
            openRoList(inputBodyRef.value.AddContentBody, searchItemList.documentContent, docHeadCodeCopy).then(res => {
              if (res != 'pass') {
                const errRes = res
                deleteDocCenter(docHeadCodeCopy).then(res => {
                  if (obRecodeCodeCopy) {
                    let obArr1 = []
                    obArr1.push(obRecodeCodeCopy)
                    unLockObTracing(obArr1)
                  }
                  if (repairRecordCopy) {
                    let repairArr = []
                    repairArr.push(repairRecordCopy)
                    unLockRepairRecord(repairArr)
                  }

                  if (errRes.indexOf('库存不足') > -1) {
                    ElMessageBox.confirm(
                      errRes + '\n' + '是否转去库存异动表查询?',
                      '提示',
                      {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning',
                      }
                    ).then(() => {
                      emit('goInventoryChangeList', errRes.split('库存不足')[0])
                    }).catch(() => {

                    })
                    return false;
                  }
                  else {
                    ElMessage(errRes)
                    return false;
                  }
                })
              }
              else {
                let otherExcuteArr = []
                if (obRecord.data?.customerDeposit) {
                  let erJob = openErListInRo(obRecord.data, staffIdCopy, docHeadCodeCopy, staffIdCopy)
                  otherExcuteArr.push(erJob)
                }
                if (obRecord.code) {
                  let closeObJob = closeObChasing(obRecord.code)
                  otherExcuteArr.push(closeObJob)
                  let combineProfitObJob = combineProfit(obRecord.data.documentId, docHeadCodeCopy)
                  otherExcuteArr.push(combineProfitObJob)
                }
                if (partBCom < 20000000) {
                  const totalDiscount3 = discount + platMi
                  let PurchaseOnTheWayJob = openPurOnTheWay(contentBodyCopy, searchItemList.documentContent, totalDiscount3, roAmount, repairRecord.data)
                  otherExcuteArr.push(PurchaseOnTheWayJob)
                }
                if (discount + platMi > 0) {
                  const totalDiscount = discount + platMi
                  let grJob = openGrListInRo(searchItemList.documentContent, totalDiscount, docHeadCodeCopy, staffIdCopy, platMi)
                  otherExcuteArr.push(grJob)
                }
                if (typeName == 'ROMI') {
                  if (roAmount - (discount + platMi) > 0) {
                    const totalDiscount2 = discount + platMi
                    let miJob = openMiListInRo(searchItemList.documentContent, totalDiscount2, roAmount, docHeadCodeCopy)
                    otherExcuteArr.push(miJob)
                  }
                }
                if (platMi > 0) {
                  let platMiJob = openPlatCount(searchItemList.documentContent, platformData, docHeadCodeCopy)
                  otherExcuteArr.push(platMiJob)
                }
                let cout1 = 0;
                for (let item of contentBodyCopy) {
                  if (item.SalesAttributeNoArr.find(x => x.salesAttributeId == item.SalesAttributeNo)?.recycleOrNot) {
                    cout1++
                  }
                }
                // if (cout1 > 0) {
                const recycleCompanyId = getRecycleCompanyId(outWareHouseIdCopy)
                const length1 = contentBodyCopy.length;
                for (let i = 0; i < length1; i++) {
                  const recycleCost = contentBodyCopy[i].SalesAttributeNoArr.find(x => x.salesAttributeId == contentBodyCopy[i].SalesAttributeNo).recyclePrice
                  const partAId = contentBodyCopy[i].PartACompanyId
                  const commoName = contentBodyCopy[i].CommodityName
                  const commoId = contentBodyCopy[i].CommodityId
                  const salesId = contentBodyCopy[i].SalesAttributeNo
                  const salesAttrName = transferToName(salesId, 'SalesAttributeId')
                  const rooNumber = commoId.substring(8, 12)
                  const rootBody = rootMaterialList.find(x => x.rootMaterialCode == rooNumber)
                  let warrantyRemarkOrgin = rootBody?.defaultWarrentyRemark
                  let warrantyDaysOrgin = rootBody?.defaultWarrentyDays
                  if(commoId.substring(0,3) == '999'){
                    warrantyRemarkOrgin = ''
                    warrantyDaysOrgin = 0
                  }

                  const warrantyNumber = 'warranty' + partAId + rooNumber
                  const warrantyRemarkNumber = 'warrantyRemark' + partAId + rooNumber
                  const warrantyDays = storage.getItem(warrantyNumber) || `${warrantyDaysOrgin}天`
                  const warrentyRemarksOrigin = storage.getItem(warrantyRemarkNumber) || `${warrantyRemarkOrgin}`

                  let storeListFromWin = []
                  if (JSON.parse(storage.getItem('BarStoreList' + user))) {
                    storeListFromWin = JSON.parse(storage.getItem('BarStoreList' + user));
                  }
                  const newId2 = getUUId()
                  let addPrint2 = {
                    id: 'store' + newId2,
                    partName: commoName,
                    docType: '质保单',
                    companyName: partAName,
                    commodityId: commoId,
                    docDate: timeNow,
                    salesPrice: contentBodyCopy[i].UnitPrice,
                    remark: `销售属性:${salesAttrName}`,
                    barcode: docHeadCodeCopy,
                    warranty: warrantyDays,
                    warrantyRemark: warrentyRemarksOrigin
                  }
                  storeListFromWin.unshift(addPrint2)

                  if (contentBodyCopy[i].SalesAttributeNoArr.find(x => x.salesAttributeId == contentBodyCopy[i].SalesAttributeNo).recycleOrNot) {
                    let count2 = contentBodyCopy[i].OrderQty
                    for (let j = 0; j < count2; j++) {
                      let b = {
                        DocumentId: docHeadCodeCopy,
                        Sn: +i + 1,
                        PartACompanyId: recycleCompanyId,
                        PartBCompanyId: partAId,
                        StaffId: staffIdCopy,
                        DocumentDate: '',
                        DocumentType: '零件回收单',
                        CommodityName: commoName,
                        CommodityId: commoId,
                        SalesAttributeNo: salesId,
                        Remark: `回收入库 ${count2}-${+j + 1}`,
                        OrderQty: 1,
                        UnitPrice: recycleCost,
                        NotifyStatus: '',
                        StartType: 105,
                      }
                      let toDo = addRecycleOnTheWay(b)
                      otherExcuteArr.push(toDo)
                      const newId = getUUId()
                      let addPrint = {
                        id: 'store' + newId,
                        partName: commoName,
                        docType: '回收单',
                        companyName: partAName,
                        docDate: timeNow,
                        salesPrice: '',
                        remark: `回收入库 ${count2}-${+j + 1},回收属性:${salesAttrName}`,
                        barcode: docHeadCodeCopy
                      }
                      storeListFromWin.unshift(addPrint)

                    }
                  }
                  storage.setItem('BarStoreList' + user, JSON.stringify(storeListFromWin))
                  store.commit('updateBarcodeCount')
                }
                // }

                if (repairRecord.code != '') {
                  const repairRecordCopy = copyObj(repairRecord.data)
                  if ((repairRecordCopy.partBCompanyId == '散客' && repairRecordCopy.serviceType == '邮寄维修') || repairRecordCopy.partBCompanyId != '散客') {
                    let goShipRepairMachineObj = goShipRepairMachine(repairRecordCopy)
                    otherExcuteArr.push(goShipRepairMachineObj)
                  }
                  let closeRepairObj = closeRepairPromise(repairRecord.code)
                  otherExcuteArr.push(closeRepairObj)
                  let updateCustomerJob = updateCustomerSourceStaticPromise(repairRecord.data.documentId, docHeadCodeCopy, contentBodyCopy)
                  otherExcuteArr.push(updateCustomerJob)
                  let combineProfitFrJob = combineProfit(repairRecord.data.documentId, docHeadCodeCopy)
                  otherExcuteArr.push(combineProfitFrJob)
                }
                if (repairRecord.code == '' && customerSource.value != '') {
                  let count1 = 0;
                  for (let item of contentBodyCopy) {
                    if (item.CommodityName != '') {
                      count1++
                    }
                  }
                  let staObj = {
                    DocumentId: docHeadCodeCopy,
                    SettleOrNot: true,
                    SettleDocumentId: docHeadCodeCopy,
                    TransferredLines: count1,
                    Amount: roAmount,
                    CustomerSourceId: customerSource.value,
                    PartACompanyId: store.state.loginInitializeData.companyId
                  }
                  let addSourceJob = addSourceStatisticsPromise(staObj)
                  otherExcuteArr.push(addSourceJob)
                }
                let kpiArr = []
                for (let item of contentBodyCopy) {
                  if (item.CommodityName != '') {
                    let kpiObj = {
                      DocumentId: docHeadCodeCopy,
                      PartACompanyId: store.state.loginInitializeData.companyId,
                      CommodityName: item.CommodityName,
                      CommodityId: item.CommodityId,
                      OrderQty: item.OrderQty,
                      UnitPrice: item.UnitPrice,
                      SalesAttributeNo: item.SalesAttributeNo,
                      StaffIdRepair: staffIdCopy,
                      StaffIdSale: searchItemList.documentContent.StaffId,
                      Remark: `此订单优惠了${dicountValue.value - (obRecord.data?.customerDeposit || 0)}元钱`
                    }
                    kpiArr.push(kpiObj)
                  }
                }
                let staffKpiJob = openStaffKpiStatisticsPromise(kpiArr)
                otherExcuteArr.push(staffKpiJob)
                Promise.all(otherExcuteArr).then(res => {
                  if (obRecodeCodeCopy) {
                    let obArr1 = []
                    obArr1.push(obRecodeCodeCopy)
                    unLockObTracing(obArr1)
                  }
                  if (repairRecordCopy) {
                    let repairArr = []
                    repairArr.push(repairRecordCopy)
                    unLockRepairRecord(repairArr)
                  }
                  if (OutWareHouseName == '主仓库') {
                    setTimeout(() => {
                      updateWarning(store.state.loginInitializeData.companyId, commodityArr)
                    }, 0);
                  }
                  closeRepairConnect()
                  emit('refreshMsg')
                  fillDocContent()
                  searchItemList.documentContent.Remark = ''
                  platformData.platformChoose = ''
                  platformData.platformMiAmount = ''
                  platformData.voucherCode = ''
                  ElMessage({
                message:'开单成功',
                duration:2000,
                showClose:true
              })
                })
              }
            })
          })
        })
      }).catch(err => {

        if (obRecodeCodeCopy) {
          let obArr1 = []
          obArr1.push(obRecodeCodeCopy)
          unLockObTracing(obArr1)
        }
        if (repairRecordCopy) {
          let repairArr = []
          repairArr.push(repairRecordCopy)
          unLockRepairRecord(repairArr)
        }
      })
    }


    //#endregion

    //#endregion

    return {
      searchItemList, comScanInRef, getDataFromObTracing, getDataFromQuotation,
      dicountValue, platFormFilter, platformData,
      valueAfterDiscount, clearPlatInfo,
      inputBodyRef,
      countValueAfterDiscount,
      scanInValue, checkPlatMi,
      scanIn,
      clearScanIn,
      openList, obRecord,
      customerSourceArr, PopModel, retailSetPop, openRetailSet, closeRetailSet, updateRetailInfo,
      bussinessList, outwareHouseList, staffList, rrPopRef, openRrList, receveRepairRecord, repairRecord,
      closeRepairConnect, checkDiscount, retailConfigData,
      openObList, obPopRef, MiButtonShow,
      receveObRecord, discountChangePermit,
      customerSource, getDataFromRepairList, retailCustomerCode, copyOrderFromDocCenter
    }
  }
}
</script>
<style scoped lang="less">
.InputForChooseshort:focus {
  border: 1px solid #ffa004;
}
.importantIndi {
  color: #e83a65;
  font-size: 1rem;
}

.retailSet,
.carryRepair {
  border: 1px solid #dddddd;
  width: fit-content;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  margin-top: 0.5rem;
  margin-left: 1rem;
}
.retailSet:hover,
.carryRepair:hover,
.closeRepairConnection:hover {
  background-color: #f08300;
  color: #ffffef;
  cursor: pointer;
}
.retailSet {
  background-color: #f6f6f6;
  margin-top: 1rem;
}
.carryRepair {
  background-color: #ffc85d;
  margin-top: 1rem;
}
.closeRepairConnection {
  border: 1px solid #dddddd;
  width: 6rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  margin-left: 1rem;
  margin-top: 0.43rem;
  background-color: #f6f6f6;
}

.PopBody {
  width: 38rem;
}
.popfooterforBp {
  display: flex;
  margin: 1rem;
  align-items: center;
  justify-content: flex-end;
}
.popLine {
  margin-top: 0.5rem;
  margin-left: 1rem;
}
.popSelect {
  width: 12rem;
  height: 2rem;
}

.InputHeadContainer {
  display: flex;
  margin-top: 0.5rem;
  width: 95%;
  margin-left: 0.5rem;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  flex-wrap: wrap;
}
.InputHeadContainer input {
  margin-bottom: 0.5rem;
  margin-left: 0.2rem;
}
.InputForChooseContainer {
  display: flex;
  margin-right: 0.625rem;
  margin-left: 0.4rem;
}
.EditTableLine {
  flex-wrap: wrap;
}

.InputForChooseContainer label {
  padding-right: 0.3125rem;
  line-height: 1.75rem;
}
.InputForChooseLong {
  height: 1.75rem;
  width: 15.625rem;
  border: 1px solid #c9c9c9;
}
.InputForChooseTooLong {
  height: 1.75rem;
  width: 30.625rem;
  border: 1px solid #c9c9c9;
}
.InputForChooseTooTooLong {
  height: 1.75rem;
  width: 60.625rem;
  border: 1px solid #c9c9c9;
}
.InputForChooseShort {
  height: 1.75rem;
  width: 8.625rem;
  border: 1px solid #c9c9c9;
}
.InputForChoose:focus,
.InputForChooseLong:focus,
.InputForChooseShort:focus,
.InputForChooseTooLong:focus,
.InputForChooseTooTooLong:focus {
  border: 1px solid #ffa004;
}
.InputForChoose {
  height: 1.75rem;
  width: 9rem;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  border-left: 1px solid #c9c9c9;
  border-right: none;
}

.RightBorder {
  border-right: 1px solid #c9c9c9;
}
.InputForChooseFunc {
  font-family: "icomoon";
  text-align: center;
  line-height: 1.75rem;
  font-size: 1.125rem;
  width: 1.75rem;
  height: 1.75rem;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  border-right: 1px solid #c9c9c9;
}
.InputForChooseFunc:hover {
  cursor: pointer;
}
.secondTitle {
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: 200;
  color: #1e87f0;
}
b {
  color: red;
}
.EditTableFuncButton {
  margin-right: 15rem;
}
.marTop1rem {
  margin-top: 1rem;
  margin-left: 1rem;
}
.marLeft2rem {
  margin-left: 2rem;
}
.clearPlat:hover {
  cursor: pointer;
}
</style>