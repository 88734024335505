<template>
  <div class="totalContainer">

    <div class="searchLayer">
      <div class="block2">
        <div class="searchCommBox">
          <SelectIncludeSearch :defaultValue="defaultWare" :selectList="staffFilter" :pageName="'InventoryInactiveList'" :inputName="'仓库'" :placeHolder="'请选择目标仓库'" :chooseAll="true" @getChooseResult="getChoosedWarehouse"></SelectIncludeSearch>
        </div>
        <div class="searchCommBox">
          <label class="searchLabel">呆滞天数:</label>
          <el-input class="searchInput" type="number" @blur="checkQty" v-model="deadDays" placeholder="请输入呆滞天数的下限" />
        </div>
        <el-button class="searchButton" type="primary" @click="searchDeadMaterials()">搜索</el-button>
        <div class="profitBox">
          <span class="totalWords">呆滞数量:</span><span class="totalProfitNumber">{{totalQty}}个</span>
        </div>
        <div class="profitBox">
          <span class="totalWords">呆滞金额:</span><span class="totalProfitNumber">{{totalAmount}}元</span>
        </div>
      </div>
    </div>

    <div class="BigTable">
      <div class="TableContentArea">
        <table class="tablehead">
          <tr class="headtr" :style="{ height : trHeight }">
            <th class="thSN">
              <div :style="{ width: SnWidth }"></div>
            </th>
            <!-- <th class="thselectall">
              <div :style="{ width: selectAllWidth }"><input class="selectAllCheckBox" type="checkbox" v-model="selectAll" /></div>
            </th> -->
            <th>
              <div class="thoper" :style="{ width: operationWidth }">操作</div>
            </th>
            <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'head'">
              <div :style="{ width: value1.showLen  + 'rem' }">{{ value1.showName }}</div>
            </th>
          </tr>
        </table>
        <div class="tablebodyarea" :style="{ width: tablewidth  + 'rem',height:tbodyHeight}">
          <table class="tablebody">
            <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'InvenInactive' + index2">
              <td>
                <div class="tdSN" :style="{ width: SnWidth }">{{+index2 + 1}}</div>
              </td>
              <!-- <td class="tableselect">
                <div :style="{ width: selectAllWidth }"><input type="checkbox" :value="value2['wareHouseId'] + value2['commodityId']" v-model="selectedItems" /></div>
              </td> -->
              <td>
                <div class="tdoper" :style="{ width: operationWidth }">
                  <!-- <span class="spandele" v-show="partAComId == value2.companyId">出库</span> -->
                </div>
              </td>
              <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
                <div :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="moreFunctionBox">
      <!-- <el-button type="primary" @click="openSaveRef()">保存</el-button> -->
    </div>

  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, provide, reactive, ref, watch } from 'vue-demi'
import { useStore } from 'vuex'
import { addDate, changeDayToInt, copyArray, copyObj, GetNumberOfDays, insertSearchOrigin, isRealNum } from '@/hooks/tools';
import { changeToViewCode, getAllWarningList, transferToName, getCommodityBodyById } from '@/hooks/OpenOrder'
import _ from "lodash";
import requestData from '@/hooks/requestData';
import SelectIncludeSearch from '@/components/Common/ElementUI/SelectIncludeSearch.vue'
export default {
  components: {
    SelectIncludeSearch
  },
  setup() {

    const store = useStore();

    const partAComId = store.state.loginInitializeData.companyId

    let defaultWare = ref('')

    let chooseWarehouse = ''

    const setDefaultWarehouse = () => {
      const warehouseList = store.state.loginInitializeData.authList.wareHouseLists
      for(let item of warehouseList){
        if(item.partACompanyId == partAComId && item.defaultWarehouse){
          defaultWare.value = item.wareHouseId
          chooseWarehouse = item.wareHouseId
        }
      }
    }

    setDefaultWarehouse()

    let deadDays = ref(100)

    let selectAllWidth = ref('2.5rem')
    let trHeight = ref('1.875rem')
    let SnWidth = ref('3.75rem')
    let operationWidth = ref('10rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')

    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) + parseFloat(operationWidth.value) + parseFloat(selectAllWidth.value) + 1 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });

    let selectAll = ref(false);

    watch(selectAll, (newValue, oldValue) => {
      if (code == store.state.loginInitializeData.ShowWindow[0]) {
        if (newValue == true) {
          selectedItems.value.splice(0);
          for (let item of tableBody) {
            selectedItems.value.push(item['wareHouseId'] + item['commodityId']);
          }
        } else {
          selectedItems.value.splice(0);
        }
      }
    });

    let selectedItems = ref([]);

    let tableBody = reactive([]);

    let tableHead = reactive([
      { fieldsNameForVali: 'warehouseName', showLen: 15, showName: '仓库名称' },
      { fieldsNameForVali: 'commodityName', showLen: 40, showName: '商品名称' },
      { fieldsNameForVali: 'inventoryBalance', showLen: 6, showName: '库存数量' },
      { fieldsNameForVali: 'inventoryCost', showLen: 6, showName: '单个成本' },
      { fieldsNameForVali: 'deadDays', showLen: 6, showName: '呆滞天数' },
    ]);

    let totalQty = computed(() => {
      return tableBody.reduce((x, y) => x + +y.inventoryBalance, 0)
    })

    let totalAmount = computed(() => {
      return tableBody.reduce((x, y) => x + (+y.inventoryCost * +y.inventoryBalance), 0).toFixed(2)
    })

    const staffFilter = copyArray(store.state.loginInitializeData.filterList.allWarehousePossibles)
    

    const searchDeadMaterials = () => {
      const dayNow = changeDayToInt(addDate(new Date(), -deadDays.value - 1), 'day')
      const config = {
        method: "get",
        url: "/ReportShow/GetInventoryInactiveList",
        params: {
          warehouseId: chooseWarehouse,
          DeadDateH: dayNow
        }
      };
      requestData.GetData(config).then(res => {
        if (res) {
          // console.log(res)
          // const dat = '' + res[0].documentDate
          // console.log(dat)
          // console.log(dat.substring(0,4) &&)
          tableBody.splice(0)

          res = res.sort(function (a, b) { return a.documentDate - b.documentDate })

          for (let item of res) {
            const dat = '' + item.documentDate
            item.deadDays = GetNumberOfDays(new Date(dat.substring(0, 4) + '-' + dat.substring(4, 6) + '-' + dat.substring(6, 8)), new Date())
            item.warehouseName = transferToName(item.wareHouseId, 'allWarehousePossibles')
            tableBody.push(item)
          }

        }
      })
    }

    searchDeadMaterials()


    const getChoosedWarehouse = (warehouseId) => {
      chooseWarehouse = warehouseId
    }

    const checkQty = () => {
      if(deadDays.value < 0){
        deadDays.value = 0
      }
      else{
        deadDays.value = Math.trunc(deadDays.value)
      }
    }

    //#endregion

    return {
      deadDays,
      searchDeadMaterials,
      getChoosedWarehouse,
      staffFilter,
      tableBody,
      selectAllWidth,
      trHeight,
      SnWidth,
      operationWidth,
      tbodyHeight,
      tablewidth,
      selectAll,
      selectedItems,
      tableHead,
      totalQty,
      totalAmount,
      partAComId,
      defaultWare,
      checkQty

    }
  }
}
</script>

<style scoped lang="less">
.totalContainer {
  height: 100%;
  width: 98%;
  margin-left: 1%;
}
.firstContainer {
  height: 10%;
  width: 98%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.AccountChooseBox {
  margin-left: 1rem;
}
.bigLable {
  font-size: 1rem;
  margin-right: 0.2rem;
}
.bigLable2 {
  margin-left: 4rem;
  margin-right: 0.5rem;
}
.firstButton {
  margin-left: 1rem;
}
.searchLayer {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.block2 {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #cccccc;
  padding-left: 2rem;
}
.profitBox {
  line-height: 4.5rem;
  margin-left: 2rem;
}
.totalWords {
  font-size: 1rem;
  font-weight: 400;
  padding-right: 0.3rem;
}
.totalProfitNumber {
  font-size: 0.9rem;
  font-weight: 700;
}
.searchLabel {
  line-height: 4.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  font-size: 1rem;
}
.searchInput {
  width: 8rem;
  margin-top: auto;
  margin-bottom: auto;
}
.CommodityContainer {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  border: 1px solid #cccccc;
  top: 4.2rem;
  left: 7.5rem;
  width: 40rem;
}
.searchContentTr {
  display: flex;
  margin-top: 0.2rem;
  width: 100%;
}
.searchContentTr:hover {
  background-color: #f5f5f6;
}
.searchContentTr input {
  border: none;
  outline: none;
  text-align: left;
  background-color: transparent;
  border-radius: 5px;
}

.searchContentTr input:hover {
  cursor: pointer;
}
.searchContentTrLast:hover {
  background-color: #fa2c2c;
  color: #fff;
  cursor: pointer;
}
.searchContentTrLast {
  padding-left: 0.3rem;
}
.searchIndication {
  text-align: center !important;
  color: red;
}
.comminputDown {
  width: 80%;
}
.bodytr input,
.bodytr select {
  border: none;
  outline: none;
  height: 1.875rem;
  background-color: transparent;
  text-align: center;
}
.bodytr input:focus,
.bodytr select:focus {
  border: 1px solid #ffa004;
}
.searchIndication {
  width: 20%;
}
.searchCommBox {
  display: flex;
  position: relative;
  height: 4.5rem;
}
.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 60%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.thSN {
  font-family: "icomoon";
  color: #fff;
  font-size: 16px;
}
.selectAllCheckBox {
  cursor: pointer;
}
.headtd {
  cursor: pointer;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.tdSN,
.tableselect,
.tdoper,
.footsum,
.bodytr {
  text-align: center;
}
.bodytr {
  height: 2rem;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 2rem;
}
.searchButton {
  margin-left: 2rem;
  margin-top: auto;
  margin-bottom: auto;
}
.inputBox {
  display: flex;
}
.popLable {
  margin-top: 0.5rem;
  line-height: 2.5rem;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 0.5rem;
}
.buttonBox {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  justify-content: flex-end;
}
.mRight {
  margin-top: 0.1rem;
}
.lastButton {
  margin-right: 0.2rem;
}
</style>