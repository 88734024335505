<template>
  <div class="tableLoaderContainer">
    <BodyTitle></BodyTitle>
    <SearchContentForIvenBal @deliverSearchItems="deliverSearchItems" ref="searchRef"></SearchContentForIvenBal>
    <TableShowForDiy @saveActiveItem="saveActiveItem" @clearActive="clearActive" ref="tableShowRef" @perfectTableBody="perfectTableBody" @perfectTableHead="perfectTableHead"></TableShowForDiy>
    <div class="moreFunctionBox">
      <div class="FuncButton" @click="goInvenChange()">库存变动查询</div>
    </div>
  </div>
</template>

<script>
import BodyTitle from '@/components/Content/MainBodyContent/BodyTitle.vue'
import SearchContentForIvenBal from '@/components/Content/MainBodyContent/SearchContentForIvenBal.vue'
import TableShowForDiy from '@/components/Content/MainBodyContent/TableShowForDiy.vue';
import { useStore } from "vuex";
import DiyShowTable from '@/hooks/DiyShowTable'
import { getOnTheWayQty } from '@/hooks/OpenOrder'
import { ElMessage } from 'element-plus'
export default {
  setup(props, { emit }) {
    //#region 定义区
    const store = useStore();
    //初始化输入表名称
    const TableName = 'InventoryBalanceList';

    const PageName = 'InventoryBalanceList'

    let { tableShowRef, searchRef, deliverSearchItems, activeItem, saveActiveItem, clearActive } = DiyShowTable(TableName, PageName)
    //根据表名给searchItemList赋值


    const goInvenChange = () => {
      if (activeItem.index <= -1) {
        ElMessage('未选择行')
        return;
      }
      emit('goInventoryChangeFromInvBal', activeItem)
      // store.commit('saveActiveInvInfo', activeItem)

      // const code = changeToViewCode('InventoryChangeList')
      // store.commit('GoFunctionWindowWithCode', code)
    }

    //#endregion

    const perfectTableHead = (head) => {
      if (!head.find(x=>x.fieldsNameForVali == 'onTheWayQty')) {
        let b1 = {
          fieldsNameForVali: 'onTheWayQty',
          showLen: 6,
          showName: '在途',
        }
        let qty = 0
        if(head){
          qty = head.length
        }
        for(let i = qty - 1;i>=0;i--){
          if(head[i].fieldsNameForVali == 'inventoryBalance'){
            head.splice(i + 1,0,b1)
          }
        }
      }
    }

    const perfectTableBody = (body) => {
      for (let item of body) {
        getOnTheWayQty(item.commodityId).then(res=>{
          item.onTheWayQty = res
        })
      }
    }


    return {
      deliverSearchItems,
      tableShowRef,
      saveActiveItem,
      activeItem, clearActive, goInvenChange, searchRef,
      perfectTableHead, perfectTableBody
    }
  },
  components: {
    BodyTitle,
    SearchContentForIvenBal,
    TableShowForDiy
  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}
</style>