<template>
  <div class="totolBox">
    <div class="BodyTitle">
      <div class="BodyTitleText">价格调整</div>
    </div>
    <div>
      <div class="searchContentContainer">
        <div class="searchCommBox">
          <label class="searchLabel" for="PriceManageCommName">商品名称:</label>
          <el-input class="searchInput" id="PriceManageCommName" v-model="inputValue" @input="insertSearch" placeholder="请输入搜索内容" />
          <div class="CommodityContainer" v-show="searchResult.length != 0">
            <div class="searchContentTr" v-for="(searchitem,searchIndex) in searchResult" :key="searchitem.commodityId + 'priceManage'" @mousedown.prevent="goManage(searchIndex)"><input class="comminputDown" v-model="searchitem.commodityName" type="text" :style="[{height:'1.625rem'}]"><input type="text" class="searchIndication" v-model="searchitem.show"></div>
            <div class="searchContentTrLast" :style="[{height:'1.625rem'},{lineHeight:'1.625rem'}]" @mousedown.prevent="clearSearchResult()">关闭搜索</div>
          </div>

          <label class="searchLabel" for="PriceManageCommName">查询日期:</label>
          <div class="searchInput1">
            <el-date-picker v-model="dateChoose" type="date" placeholder="选择日期" :size="''" />
          </div>

          <el-button type="primary" @click="searchTodayAndSort()" round size="small" :style="[{height:'2rem'},{margin:'auto'},{marginLeft:'2rem'}]">查看当天采购物料</el-button>
        </div>
      </div>
    </div>
    <div class="BigTable">
      <div class="TableContentArea">
        <table class="tablehead">
          <tr class="headtr" :style="{ height : trHeight }">
            <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'Pmhead'" :class="{  ShowUpArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == 1, ShowDownArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == -1,}" @click.stop="ChangeSort(value1.fieldsNameForVali)">
              <div :style="{ width: value1.showLen  + 'rem' }">{{ value1.showName }}</div>
            </th>
            <th>
              <div class="thoper" :style="{ width: SnWidth }">修改值</div>
            </th>
            <th>
              <div class="thoper" :style="{ width: operationWidth }">操作</div>
            </th>
          </tr>
        </table>
        <div class="tablebodyarea" :style="{ width: tablewidth  + 'rem',height:tbodyHeight}">
          <table class="tablebody">
            <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'Pmhead' + index2">
              <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
                <div :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
              </td>
              <td>
                <div class="tdoper" :style="{ width: SnWidth }">
                  <input class="submitNumber" type="number" :style="[{ width: SnWidth },{ height : trHeight }]" v-model="tableBody[index2].toBeSubmitValue">
                </div>
              </td>
              <td>
                <div class="tdoper" :style="{ width: operationWidth }">
                  <span class="spanedit" @click="submitChange(value2)">修改定价基准</span>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onUnmounted, reactive, ref, toRefs, watch } from 'vue-demi'
import { ChangeYMD, copyArray, copyObj, getUUId, insertSearchOrigin } from '@/hooks/tools'
import _ from "lodash";
import { changeToViewCode, getRetailPriceById, getAllCommByCommodityId, getAllVmiByCommodityId, transferToName, transferToCode, sendBenchMarkChange, getAllCommPurchasedToday, getAllVmiByCommArr } from '@/hooks/OpenOrder'
import { useStore } from "vuex";
import { getBenchMarkPrice, getPurchaseHistoryList, getUnderSubmitPrice } from '@/hooks/getCache';
import { ElMessage } from 'element-plus'
export default {
  components: {
  },
  setup() {

    const store = useStore()

    const user = store.state.loginInitializeData.userId

    let inputValue = ref('')

    let searchResult = reactive([]);

    let dateChoose = ref('')

    const insertSearch = _.debounce((e) => {
      let b = {}
      b.target = {
        value: e
      }
      insertSearchOrigin(b, searchResult)
    }, 500)

    let activeCommId = ''

    const goManage = (index) => {
      activeCommId = searchResult[index].commodityId
      clearSearchResult()
      searchAndSort(activeCommId)
    }

    const clearSearchResult = () => {
      searchResult.splice(0)
    }


    let selectAllWidth = ref('4rem')
    let trHeight = ref('1.875rem')
    let SnWidth = ref('3.75rem')
    let operationWidth = ref('9rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')

    //f计算表格内容总宽度
    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) + parseFloat(operationWidth.value) + parseFloat(selectAllWidth.value) + 1.25 + tableHead.length * 1 + 0.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });

    let tableHead = reactive([
      { fieldsNameForVali: 'commodityName', showName: '商品名称', showLen: 24 },
      { fieldsNameForVali: 'salesAttriName', showName: '销售属性', showLen: 12 },
      { fieldsNameForVali: 'customerQuote', showName: '建议零售价', showLen: 8 },
      { fieldsNameForVali: 'wholeSalePrice', showName: '批发价格', showLen: 8 },
      { fieldsNameForVali: 'benchMarkPrice', showName: '定价基准', showLen: 8 },
      { fieldsNameForVali: 'purchasePrice', showName: '最近采购价格', showLen: 8 },
      { fieldsNameForVali: 'Vendor', showName: '供应商', showLen: 14 },
      { fieldsNameForVali: 'deviateValue', showName: '偏移值', showLen: 5 },
      { fieldsNameForVali: 'submitValue', showName: '已提交', showLen: 5 },
    ]);

    let ChangeSort = (fildsname) => {
      searchElement.SortFieldsName = ChangeFirToUp(fildsname)
      searchElement.SortDirection = -searchElement.SortDirection
      searchList()
    };
    const searchElement = reactive({
      SortFieldsName: '',
      SortDirection: 1
    })
    let commArr = reactive([])

    let tableBody = reactive([]);

    let tableBodyCopy = []

    const submitChange = (body) => {
      if (body.toBeSubmitValue == '' || body.toBeSubmitValue <= 0) {
        ElMessage('未填写新基准价格')
        return
      }
      else {
        let b = {
          DefaultPircingId: commArr.find(x => x.commodityId == body.commodityId).defaultPircingId + transferToCode(body.salesAttriName, 'SalesAttributeId') + '10000004',
          OldPrice: body.benchMarkPrice,
          PurchasePrice: body.purchasePrice,
          NewPrice: body.toBeSubmitValue,
          UserId: store.state.loginInitializeData.userId,
          Remark: '',
        }
        sendBenchMarkChange(b).then(res => {
          if (res == 'pass' && activeCommId != '') {
            searchAndSort(activeCommId)
          }
          if (res == 'pass' && activeCommId == '') {
            searchTodayAndSort()
          }
        })
      }
    }



    const searchAndSort = (commodityId) => {
      tableBody.splice(0)
      tableBodyCopy.splice(0)
      commArr.splice(0)
      getAllCommByCommodityId(commodityId).then(res => {
        if (res.length > 0) {
          for (let item of res) {
            let objNew = {};
            for (let vl in item) {
              objNew[vl] = item[vl]
            }
            commArr.push(objNew)
          }
          getAllVmiByCommodityId(commodityId).then(res => {
            for (let item of res) {
              let b = {}
              b.commodityName = commArr.find(x => x.commodityId == item.commodityId).commodityName
              b.commodityId = item.commodityId
              b.salesAttriName = transferToName(item.salesAttributeId, 'SalesAttributeId')
              b.customerQuote = item.salesPrice
              b.wholeSalePrice = item.vmiCost.toFixed(2)
              b.toBeSubmitValue = ''

              if (b.salesAttriName == '普通零售') {
                getBenchMarkPrice(commArr.find(x => x.commodityId == item.commodityId).defaultPircingId).then(res => {
                  if (res) {
                    b.benchMarkPrice = res.price
                    getPurchaseHistoryList(item.commodityId).then(res => {
                      if (res) {
                        b.purchasePrice = res.latestInPrice
                        b.Vendor = transferToName(res.partBCompanyId, 'BusinessPartnerId')

                        if (b.benchMarkPrice && b.purchasePrice) {
                          b.deviateValue = (((b.benchMarkPrice - b.purchasePrice) / b.purchasePrice) * 100).toFixed(0) + '%'
                        }
                        b.submitValue = ''
                        const priceCode = commArr.find(x => x.commodityId == item.commodityId).defaultPircingId + item.salesAttributeId
                        getUnderSubmitPrice(priceCode).then(res => {
                          if (res) {
                            b.submitValue = res.newPrice
                          }
                          else {
                            b.submitValue = ''
                          }
                          tableBody.unshift(b)
                        })

                      }
                    })
                  }
                })

              }
              else if (b.salesAttriName == '售后') {
                b.benchMarkPrice = 0
                b.purchasePrice = ''
                b.Vendor = ''
                b.deviateValue = ''
                b.submitValue = ''
                const priceCode = commArr.find(x => x.commodityId == item.commodityId).defaultPircingId + item.salesAttributeId
                getUnderSubmitPrice(priceCode).then(res => {
                  if (res) {
                    b.submitValue = res.newPrice
                  }
                  else {
                    b.submitValue = ''
                  }
                  tableBody.push(b)
                })
              }
              else {
                b.benchMarkPrice = (b.wholeSalePrice - 20 >= 0 ? b.wholeSalePrice - 20 : 0).toFixed(2)
                b.purchasePrice = ''
                b.Vendor = ''
                b.deviateValue = ''
                b.submitValue = ''
                const priceCode = commArr.find(x => x.commodityId == item.commodityId).defaultPircingId + item.salesAttributeId
                getUnderSubmitPrice(priceCode).then(res => {
                  if (res) {
                    b.submitValue = res.newPrice
                  }
                  else {
                    b.submitValue = ''
                  }
                  tableBody.push(b)
                })
              }
            }
          })
        }
      })
    }


    const searchTodayAndSort = () => {

      if(!dateChoose.value){
        dateChoose.value = new Date()
      }
      tableBody.splice(0)
      tableBodyCopy.splice(0)
      commArr.splice(0)
      activeCommId = ''

      getAllCommPurchasedToday(ChangeYMD(dateChoose.value)).then(res => {
        if (res.length > 0) {
          for (let item of res) {
            let objNew = {};
            for (let vl in item) {
              objNew[vl] = item[vl]
            }
            commArr.push(objNew)
          }
          getAllVmiByCommArr(commArr).then(res => {
            for (let item of res) {
              let b = {}
              b.commodityName = commArr.find(x => x.commodityId == item.commodityId).commodityName
              b.commodityId = item.commodityId
              b.salesAttriName = transferToName(item.salesAttributeId, 'SalesAttributeId')
              b.customerQuote = item.salesPrice
              b.wholeSalePrice = item.vmiCost.toFixed(2)
              b.toBeSubmitValue = ''

              if (b.salesAttriName == '普通零售') {
                getBenchMarkPrice(commArr.find(x => x.commodityId == item.commodityId).defaultPircingId).then(res => {
                  if (res) {
                    b.benchMarkPrice = res.price
                    getPurchaseHistoryList(item.commodityId).then(res => {
                      if (res) {
                        b.purchasePrice = res.latestInPrice
                        b.Vendor = transferToName(res.partBCompanyId, 'BusinessPartnerId')

                        if (b.benchMarkPrice && b.purchasePrice) {
                          b.deviateValue = (((b.benchMarkPrice - b.purchasePrice) / b.purchasePrice) * 100).toFixed(0) + '%'
                        }
                        b.submitValue = ''
                        const priceCode = commArr.find(x => x.commodityId == item.commodityId).defaultPircingId + item.salesAttributeId
                        getUnderSubmitPrice(priceCode).then(res => {
                          if (res) {
                            b.submitValue = res.newPrice
                          }
                          else {
                            b.submitValue = ''
                          }
                          tableBody.unshift(b)
                        })

                      }
                    })
                  }
                })
              }
            }
          })
        }
      })
    }




    return {
      inputValue,
      insertSearch,
      searchResult,
      goManage,
      clearSearchResult,
      ChangeSort,
      selectAllWidth,
      trHeight,
      SnWidth,
      operationWidth,
      tbodyHeight,
      tablewidth,
      tableHead,
      tableBody,
      searchElement,
      submitChange,
      searchTodayAndSort,
      dateChoose
    }
  }
}
</script>

<style scoped lang="less">
@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;

.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.searchLabel {
  line-height: 4.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  font-size: 1rem;
}
.searchCommBox {
  display: flex;
  position: relative;
}

.searchInput {
  width: 20rem;
  margin-top: auto;
  margin-bottom: auto;
}

.searchInput1 {
  margin-top: auto;
  margin-bottom: auto;
}

.commInput {
  width: 100%;
}

.searchContentTr {
  display: flex;
  margin-top: 0.2rem;
  width: 100%;
}
.searchContentTr:hover {
  background-color: #f5f5f6;
}
.searchContentTr input {
  border: none;
  outline: none;
  text-align: left;
  background-color: transparent;
  border-radius: 5px;
}

.searchContentTr input:hover {
  cursor: pointer;
}
.searchContentTrLast:hover {
  background-color: #fa2c2c;
  color: #fff;
  cursor: pointer;
}
.searchContentTrLast {
  padding-left: 0.3rem;
}
.searchIndication {
  text-align: center !important;
  color: red;
}
.comminputDown {
  width: 80%;
}
.searchIndication {
  width: 20%;
}
.commNameSearchBox {
  position: relative;
}

.CommodityContainer {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  border: 1px solid #cccccc;
  top: 4.6rem;
  left: 5.5rem;
  width: 40rem;
}
.commodityMangeLayer {
  margin-top: 1rem;
}
.BodyTitle {
  width: 98%;
  margin-top: 0.5rem;
  margin-left: 1%;
}
.BodyTitleText {
  font-size: 1.2rem;
  font-weight: 200;
}

.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 80%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.headtd {
  cursor: pointer;
}
.ShowUpArrow {
  position: relative;
}
.ShowUpArrow::after {
  content: "\ea32";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
  color: #fff;
}
.ShowDownArrow {
  position: relative;
}
.ShowDownArrow::after {
  content: "\ea36";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.bodytr {
  text-align: center;
}
.tdoper,
.bodytr {
  height: 2rem;
}

.bodytr td:nth-child(5) {
  background-color: #ffe699 !important;
}
.totolBox {
  height: 100%;
  width: 100%;
}
.submitNumber {
  border: none;
  background-color: #fff2cc;
}
</style>