<template>
  <div class="tableLoaderContainer">

    <div class="BodyTitle">
      <div class="BodyTitleText">紧急待付款</div>
    </div>

    <div class="searchContentContainer">
      <div class="searchItemContainer" v-show="managedCompanysFilter.length > 1">
        <label class="searchinputLabel">公司名称:</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'PartACompanyId'}"><select v-model="SearchContent.PartACompanyId" class="searchinput" @focus="setActiveId('PartACompanyId')" @blur="setActiveId('')" @change="reSearch()">
            <option value="">所有</option>
            <option v-for="item in managedCompanysFilter" :key="item.beforeTransfer + 'UnCheckAmount'" :value="item.beforeTransfer">{{item.afterTransfer}}</option>
          </select></div>
      </div>

      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DocumentDateLMoneyToBePayImmediateList">发起日期</label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateL'}"><input v-model="SearchContent.DocumentDateL" class="searchinput" id="DocumentDateLMoneyToBePayImmediateList" type="date" @focus="setActiveId('DocumentDateL')" @blur="setActiveId('')"></div>
      </div>
      <div class="searchItemContainer">
        <label class="searchinputLabel" for="DocumentDateHMoneyToBePayImmediateList" v-text="'---'"></label>
        <div class="searchInputBox" :class="{'ActiveBox':activeInput == 'DocumentDateH'}"><input v-model="SearchContent.DocumentDateH" class="searchinput" id="DocumentDateHMoneyToBePayImmediateList" type="date" @focus="setActiveId('DocumentDateH')" @blur="setActiveId('')"></div>
      </div>

      <!-- 搜索模块 -->
      <div class="FuncButton" @click="searchList()">搜索</div>
    </div>

    <div class="BigTable">
      <div class="TableContentArea">
        <table class="tablehead">
          <tr class="headtr" :style="{ height : trHeight }">
            <th class="thSN">
              <div :style="{ width: SnWidth }"></div>
            </th>
            <th class="thselectall">
              <div :style="{ width: selectAllWidth }"><input class="selectAllCheckBox" type="checkbox" v-model="selectAll" /></div>
            </th>
            <th class="headtd" v-for="value1 in tableHead" :key="value1.fieldsNameForVali + 'head'" :class="{  ShowUpArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == 1, ShowDownArrow: searchElement.SortFieldsName.toLowerCase() ==  value1.fieldsNameForVali.toLowerCase() && searchElement.SortDirection == -1,}" @click.stop="ChangeSort(value1.fieldsNameForVali)">
              <div :style="{ width: value1.showLen  + 'rem' }">{{ value1.showName }}</div>
            </th>
            <th>
              <div class="thoper" :style="{ width: operationWidth }">操作</div>
            </th>
          </tr>
        </table>
        <div class="tablebodyarea" :style="{ width: tablewidth  + 'rem',height:tbodyHeight}">
          <table class="tablebody">
            <tr class="bodytr" v-for="(value2, index2) in tableBody" :key="'td' + index2">
              <td>
                <div class="tdSN" :style="{ width: SnWidth }">{{+index2 + 1}}</div>
              </td>
              <td class="tableselect">
                <div :style="{ width: selectAllWidth }"><input type="checkbox" :value="value2['moneyToBePayImmediateCode']" v-model="selectedItems" /></div>
              </td>
              <td v-for="value3 in tableHead" :key="value3.fieldsNameForVali + 'td'">
                <div :style="{ width: value3.showLen  + 'rem' }">{{ value2[value3.fieldsNameForVali] }}</div>
              </td>
              <td>
                <div class="tdoper" :style="{ width: operationWidth }">
                  <span class="spandele" @click="payMoney(value2)">确认付款</span>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <table class="tablefoot">
          <tr class="foottr" :style="{ height : trHeight }">
            <td>
              <div class="footsum" :style="{ width: SnWidth }">合计</div>
            </td>
            <td>
              <div :style="{ width: selectAllWidth }"></div>
            </td>
            <td v-for="value4 in tableHead" :key="value4.fieldsNameForVali + 'foot'">
              <div :style="{ width: value4.showLen  + 'rem' }">{{value4.totalAmount}}</div>
            </td>
            <td>
              <div :style="{ width: operationWidth }"></div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="moreFunctionBox">
      <!-- <div class="FuncButton" @click="goInvenChange()">库存变动查询</div> -->
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue-demi';
import { useStore } from "vuex";
import {
  ChangeFirToLo, ChangeFirToUp, ChangeYMD, changeArrContentToDate, isRealNum, copyArray
} from '@/hooks/tools';
import requestData from '@/hooks/requestData';
import { fillDefaultValue, changeToViewCode, getDocType, getDocNo, openMoList, transferToCode,checkTobePaid } from '@/hooks/OpenOrder'
import { ElMessage } from 'element-plus'
export default {
  setup(props, { emit }) {
    //#region 定义区
    const store = useStore();

    const filterList = store.state.loginInitializeData.filterList

    //初始化输入表名称
    const TableName = 'MoneyToBePayImmediateList';

    const PageName = 'MoneyToBePayImmediateList'

    const code = changeToViewCode(PageName)

    const managedCompanysFilter = copyArray(store.state.loginInitializeData.filterList.ManagedCompanys)


    //#endregion

    //#region 全选
    let selectAll = ref(false);

    let selectedItems = ref([]);

    watch(selectAll, (newValue, oldValue) => {

      if (code == store.state.loginInitializeData.ShowWindow[0]) {
        if (newValue == true) {
          selectedItems.value.splice(0);
          for (let item of tableBody) {
            selectedItems.value.push(item['moneyToBePayImmediateCode']);
          }
        } else {
          selectedItems.value.splice(0);
        }
      }
    });

    const payMoney = (value) => {
      const b = confirm('确认付款')
      if (!b) {

        return false;
      }
      checkTobePaid(value.documentId).then(res => {
        if (res != 'pass') {
          ElMessage(res)
          return
        }
        else {
          const partAId = transferToCode(value.partACompanyId, 'ManagedCompanys')
          const partBId = value.partBCompanyId
          getDocNo('MO').then(res => {
            let body = [{
              DocumentId: res,
              Sn: 1,
              PartACompanyId: partAId,
              PartBCompanyId: partBId,
              OrderQty: 1,
              UnitPrice: value.amount,
              Amount: value.amount,
              Remark: `财务快捷付款`,
              SourceDocumentId: value.documentId,
              InStockQty: 1,
              OutStockQty: 1,
            }]
            let moHead = {}
            moHead.DocumentId = res
            moHead.DocumentTypeName = getDocType(res)
            moHead.PartACompanyId = partAId
            moHead.PartBCompanyId = partBId,
              moHead.SourceDocumentId = value.documentId
            moHead.Remark = `财务快捷付款`
            moHead.CapitalAccountId = '主账户'

            openMoList(body, moHead, res).then(res => {
              if (res != 'pass') {
                ElMessage('开单失败')
                return false;
              }
              const config = {
                method: "delete",
                url: "/MoneyToBePayImmediateList/PayRightNow",
                params: {
                  id: value.moneyToBePayImmediateCode
                }
              };
              requestData.GetCache(config).then(res => {
                searchList()
                emit('refreshMsg')
              })
            })
          }).catch(err => {

            ElMessage(err.message)
          })
        }
      })
    }
    //#endregion

    let activeInput = ref('');

    const setActiveId = (id) => {
      activeInput.value = id
    }
    let selectAllWidth = ref('2.5rem')
    let trHeight = ref('1.875rem')
    let SnWidth = ref('3.75rem')
    let operationWidth = ref('10rem')
    let tbodyHeight = ref('calc(100% - 3.75rem)')

    let SearchContent = reactive({
      MoneyToBePayImmediateCode: '',
      DocumentId: '',
      PartACompanyId: '',
      PartBCompanyId: '',
      StaffId: '',
      DocumentType: '',
      Remark: '',
      Amount: '',
      DocumentDateH: '',
      DocumentDateL: ''
    })
    fillDefaultValue(SearchContent, TableName)

    //f计算表格内容总宽度
    let tablewidth = computed(() => {
      return (
        parseFloat(SnWidth.value) + parseFloat(operationWidth.value) + parseFloat(selectAllWidth.value) + 1.25 + tableHead.length * 1 + 0.25 +
        tableHead.reduce((ac, cu) => {
          return ac + cu.showLen;
        }, 0)
      );
    });

    let tableHead = reactive([
      { fieldsNameForVali: 'partACompanyId', showLen: 10, showName: '公司名称' },
      { fieldsNameForVali: 'documentType', showLen: 7, showName: '单据类型' },
      { fieldsNameForVali: 'documentId', showLen: 16, showName: '单据编码' },
      { fieldsNameForVali: 'documentDate', showLen: 13, showName: '发起日期' },
      // { fieldsNameForVali: 'partBCompanyId', showLen: 9.375, showName: '采购方' },
      // { fieldsNameForVali: 'staffId', showLen: 7.5, showName: '发起人' },
      { fieldsNameForVali: 'amount', showLen: 7.5, showName: '金额' },
      { fieldsNameForVali: 'remark', showLen: 31.25, showName: '发起原因' },
    ]);

    const searchElement = reactive({
      SortFieldsName: 'DocumentDate',
      SortDirection: 1
    })

    let tableBody = reactive([]);

    let ChangeSort = (fildsname) => {
      searchElement.SortFieldsName = ChangeFirToUp(fildsname)
      searchElement.SortDirection = -searchElement.SortDirection
      searchList()
    };

    const reSearch = () => {
      searchList()
    }

    let searchList = () => {

      let config = {
        method: "post",
        url: '/MoneyToBePayImmediateList/GetListByMulSearchForPay',
        data: Object.assign(SearchContent, searchElement),
      };
      requestData
        .GetData(config)
        .then((res) => {
          selectAll.value = false
          selectedItems.value.splice(0)
          tableBody.splice(0);
          tableBody.push.apply(tableBody, res['moneyToBePayImmediateListMultiple']);
          changeArrContentToDate(tableBody, PageName)
          const filterList = store.state.loginInitializeData.filterList
          // const companyFilter = filterList.PartBCompanyId
          // const staffFilter = filterList.StaffId
          const managedCompanyFilter = filterList.ManagedCompanys
          for (let item of tableBody) {
            for (let key in item) {
              // if (key == 'partBCompanyId') {
              //   item[key] = companyFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              // }
              // if (key == 'staffId') {
              //   item[key] = staffFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              // }
              if (key == 'amount') {
                item[key] = +item[key].toFixed(2)
              }
              if (key == 'partACompanyId') {
                item[key] = managedCompanyFilter.find(x => x.beforeTransfer == item[key])?.afterTransfer
              }
            }
          }

          tableHead.find(x => x.fieldsNameForVali == 'amount').totalAmount = (tableBody.reduce((ac, cu) => { return ac + parseFloat(cu.amount)}, 0)).toFixed(2) || ''

        })
        .catch((err) => {


        });
    };
    searchList()

    //#region 活跃时处理
    watch(() => store.state.loginInitializeData.ShowWindow[0],
      (newValue, oldValue) => {
        if (code == store.state.loginInitializeData.ShowWindow[0]) {
          searchList()
        }
      }
    )
    //#endregion

    return {
      setActiveId, activeInput,
      trHeight, SnWidth, operationWidth, tableHead, searchList, ChangeSort, tablewidth, tableBody,
      reSearch, searchElement, tbodyHeight, SearchContent, selectAllWidth,
      selectAll, selectedItems, payMoney, managedCompanysFilter

    }
  },
  components: {
  }
}
</script>

<style scoped lang="less">
.tableLoaderContainer {
  width: 100%;
  height: 100%;
}
.moreFunctionBox {
  margin-top: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

@bigContainerMarginTop: 0.5rem;
@smallToBigGap: 0.6rem;
@lableMarginLeft: 0.625rem;
@lableHeight: 2rem;
@inputHeight: 1.5rem;
@mainColor: #4285f4;

.searchContentContainer {
  width: 98%;
  margin-top: @bigContainerMarginTop;
  margin-left: 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.searchinputLabel {
  margin-left: @lableMarginLeft;
  font-size: 0.8rem;
  height: @lableHeight;
  line-height: @lableHeight;
}
.searchinput {
  margin-top: calc((@lableHeight - @inputHeight) / 2);
  margin-bottom: calc((@lableHeight - @inputHeight) / 2);
  font-size: 0.9rem;
  border: none;
  outline: none;
  height: @inputHeight;
  line-height: @inputHeight;
}
.ActiveBox {
  border: 1px solid #ffa004 !important;
}

.searchInputBox {
  height: @lableHeight;
  border: 1px solid #c9c9c9;
  margin-left: @lableMarginLeft;
  border-radius: 0.31rem;
}

.searchItemContainer {
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
  display: flex;
}

.searchSetting {
  margin-left: calc(@lableMarginLeft*3);
  height: @lableHeight;
  display: flex;
  margin-top: @smallToBigGap;
  margin-bottom: @smallToBigGap;
}
.searchSetting:hover {
  cursor: pointer;
  color: @mainColor;
}
.settingIcon {
  font-family: "icomoon";
  font-size: 1.1rem;
  line-height: @lableHeight;
}

.settingText {
  margin-left: 0.3rem;
  font-size: 0.8rem;
  line-height: @lableHeight;
}
.dateInputContainer {
  display: flex;
}
.BodyTitle {
  width: 98%;
  margin-top: 0.5rem;
  margin-left: 1%;
}
.BodyTitleText {
  font-size: 1.2rem;
  font-weight: 200;
}
.long {
  width: 20rem;
}

.BigTable {
  width: 98%;
  margin-left: 1%;
  height: 60%;
  margin-top: 1rem;
}
.TableContentArea {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.headtr {
  background-color: #1e87f0;
  // top: -20px;
  color: #fff;
}
.thSN {
  font-family: "icomoon";
  color: #fff;
  font-size: 16px;
}
.headtd {
  cursor: pointer;
}
.ShowUpArrow {
  position: relative;
}
.ShowUpArrow::after {
  content: "\ea32";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
  color: #fff;
}
.ShowDownArrow {
  position: relative;
}
.ShowDownArrow::after {
  content: "\ea36";
  font-family: "icomoon";
  position: absolute;
  font-size: 1rem;
  right: 0px;
  top: 0.2rem;
}
.tablebodyarea {
  overflow: hidden;
  overflow-y: auto;
}
.tablebody {
  margin-top: -1px;
}
.tablebody tr:nth-child(even) {
  background-color: #fafafa;
}
.tablebody tr:hover {
  background-color: #edf4fe;
}
.tdSN,
.tableselect,
.tdoper,
.footsum,
.bodytr {
  text-align: center;
}
.bodytr {
  height: 2rem;
}

.tablefoot {
  margin-top: -1px;
}

.moreFunctionBox {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.selectAllCheckBox {
  cursor: pointer;
}
.foottr {
  text-align: center;
}
</style>